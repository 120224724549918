// VideoControls.tsx

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import PauseIcon from '@mui/icons-material/Pause';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SettingsIcon from '@mui/icons-material/Settings';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {
  Autocomplete,
  Box,
  IconButton,
  Slider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useRef, useState } from 'react';

interface QualityOption {
  label: string;
  value: number;
}

interface VideoControlsProps {
  isPlaying: boolean;
  togglePlayPause: () => void;
  isMuted: boolean;
  toggleMute: () => void;
  volume: number;
  handleVolumeChange: (event: Event, newValue: number | number[]) => void;
  currentTime: number;
  duration: number;
  isSeeking: boolean;
  seekValue: number;
  handleSeek: (event: Event, newValue: number | number[]) => void;
  handleSeekCommitted: (event: React.SyntheticEvent, newValue: number | number[]) => void;
  isFullscreen: boolean;
  toggleFullscreen: () => void;
  isPiP: boolean;
  togglePiP: () => void;
  qualityOptions: QualityOption[];
  currentQuality: QualityOption | null;
  handleQualityChange: (event: React.SyntheticEvent, newValue: QualityOption | null) => void;
  formatTime: (seconds: number) => string;
  showControls: boolean;
}

const VideoControls: React.FC<VideoControlsProps> = ({
  isPlaying,
  togglePlayPause,
  isMuted,
  toggleMute,
  volume,
  handleVolumeChange,
  currentTime,
  duration,
  isSeeking,
  seekValue,
  handleSeek,
  handleSeekCommitted,
  isFullscreen,
  toggleFullscreen,
  isPiP,
  togglePiP,
  qualityOptions,
  currentQuality,
  handleQualityChange,
  formatTime,
  showControls,
}) => {
  // State to track hovered time
  const [hoveredTime, setHoveredTime] = useState<number | null>(null);
  const [hoverPosition, setHoverPosition] = useState<{ left: number; top: number } | null>(null);
  const sliderRef = useRef<HTMLDivElement>(null);

  // Handler for mouse move over the slider
  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!sliderRef.current) return;

    const rect = sliderRef.current.getBoundingClientRect();
    const mouseX = event.clientX - rect.left; // X position within the slider
    const percentage = mouseX / rect.width;
    const clampedPercentage = Math.min(Math.max(percentage, 0), 1);
    const calculatedTime = clampedPercentage * duration;

    setHoveredTime(calculatedTime);
    setHoverPosition({ left: mouseX, top: rect.top });
  };

  // Handler for mouse leave from the slider
  const handleMouseLeave = () => {
    setHoveredTime(null);
    setHoverPosition(null);
  };

  return (
    <Box
      className='controls'
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        background: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
        color: 'white',
        px: 2,
        py: 1,
        display: 'flex',
        flexDirection: 'column',
        transition: 'opacity 0.3s ease-in-out',
        opacity: showControls ? 1 : 0,
        pointerEvents: showControls ? 'auto' : 'none',
      }}
    >
      {/* Seek Bar with Hover Tooltip */}
      <Box
        ref={sliderRef}
        sx={{ position: 'relative' }}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <Slider
          value={isSeeking ? seekValue : currentTime}
          max={duration}
          valueLabelDisplay='auto'
          onChange={handleSeek}
          onChangeCommitted={handleSeekCommitted}
          aria-labelledby='seek-bar'
          sx={{
            color: 'primary.main',
            mb: -1,
            '& .MuiSlider-thumb': {
              display: 'none', // Hide the thumb
            },
          }}
          step={1} // Move in whole seconds
          min={0}
          valueLabelFormat={formatTime} // Format the value label to display mm:ss
          disableSwap
        />

        {/* Hover Time Tooltip */}
        {hoveredTime !== null && (
          <Box
            sx={{
              position: 'absolute',
              left: `${(hoveredTime / duration) * 100}%`,
              top: -30, // Adjust as needed
              transform: 'translateX(-50%)',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              padding: '2px 6px',
              borderRadius: '4px',
              pointerEvents: 'none',
              whiteSpace: 'nowrap',
              fontSize: '12px',
            }}
          >
            {formatTime(hoveredTime)}
          </Box>
        )}
      </Box>

      <Box display='flex' alignItems='center' justifyContent='space-between'>
        {/* Left Controls */}
        <Stack direction='row' spacing={2} alignItems='center'>
          <Tooltip title={isPlaying ? 'Pause' : 'Play'}>
            <IconButton onClick={togglePlayPause} color='inherit' size='small'>
              {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title={isMuted || volume === 0 ? 'Unmute' : 'Mute'}>
            <IconButton onClick={toggleMute} color='inherit' size='small'>
              {isMuted || volume === 0 ? <VolumeOffIcon /> : <VolumeUpIcon />}
            </IconButton>
          </Tooltip>
          <Slider
            value={volume}
            onChange={handleVolumeChange}
            aria-labelledby='volume-slider'
            sx={{
              width: 100,
              color: 'primary.main',
              mr: 2,
            }}
            min={0}
            max={100}
            step={1} // Move in whole numbers
            valueLabelDisplay='auto'
            valueLabelFormat={value => `${value}%`} // Display volume percentage
          />
          <Typography variant='body2'>
            {formatTime(isSeeking ? seekValue : currentTime)} / {formatTime(duration)}
          </Typography>
        </Stack>

        {/* Right Controls */}
        <Stack direction='row' spacing={1} alignItems='center'>
          <Box sx={{ position: 'relative', width: 120 }}>
            <Autocomplete
              value={currentQuality}
              onChange={handleQualityChange}
              options={qualityOptions}
              renderInput={params => (
                <TextField
                  {...params}
                  size='small'
                  label={<SettingsIcon fontSize='small' />}
                  InputProps={{
                    ...params.InputProps,
                    style: { whiteSpace: 'nowrap' }, // Prevent label truncation
                  }}
                  sx={{
                    '& .MuiInputBase-input': {
                      whiteSpace: 'nowrap', // Ensure input text doesn't wrap
                    },
                  }}
                />
              )}
              renderOption={(props, option: QualityOption) => (
                <li {...props} style={{ whiteSpace: 'nowrap' }}>
                  {option?.label}
                </li>
              )}
              disableClearable
              popupIcon={null} // Remove default dropdown icon
              sx={{
                minWidth: 100,
                '& .MuiAutocomplete-inputRoot': {
                  paddingRight: '0 !important', // Remove extra padding
                },
              }}
              disablePortal // Ensure dropdown is rendered inside fullscreen container
            />
          </Box>

          <Tooltip title={isPiP ? 'Exit PiP' : 'Picture in Picture'}>
            <IconButton onClick={togglePiP} color='inherit' size='small'>
              {isPiP ? <PictureInPictureAltIcon /> : <PictureInPictureIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title={isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}>
            <IconButton onClick={toggleFullscreen} color='inherit' size='small'>
              {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Tooltip>
        </Stack>
      </Box>
    </Box>
  );
};

export default VideoControls;
