import { GridColDef } from '@mui/x-data-grid-premium';
import SiloDataGrid from '../../utils/components/SiloDataGrid';
import { DecedentNameCell } from './DecedentNameCell';
import { DashboardDataGridProps } from './useDashboardDataGridAPI.hook';
import { format } from 'date-fns';

export function OpenXrayRequestsDataGrid({ data, isLoading }: DashboardDataGridProps) {
  const columns: GridColDef[] = [
    {
      field: 'caseid',
      headerName: 'CaseID',
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'decedentname',
      headerName: 'Decedent',
      width: 140,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: params => <DecedentNameCell {...params} />,
    },
    {
      field: 'createdOn',
      headerName: 'Requested On',
      width: 120,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      type: 'dateTime',
      valueGetter: value => {
        return value != null ? new Date(value) : null;
      },
      valueFormatter: value => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
      },
    },
  ];

  return (
    <SiloDataGrid
      loading={isLoading}
      listresults={data}
      columnsInput={columns}
      idcolumn='xRayRequestSeq'
      gridheight={200}
      gridcolor='#e74c3c'
      title='Open X-Ray Requests'
      tooltip='These are open cases which have pending x-ray requests'
      casecount
      rowsperpage={50}
      filterModel={undefined}
      columnsVisibility={undefined}
    />
  );
}
