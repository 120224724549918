import React, { useContext, useEffect, useState } from 'react';

import { getAdminData, deleteData, activeData } from '../../../../../services/utility-api';

import {
  Box,
  Button,
  Grid,
  Typography,
  Divider,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Popper,
  IconButton,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MainCard from '../../../../../components/AdministrationModules/MainCard';
import StatusTable from './StatusTable';
import PrerequisiteTable from './PrerequisiteTable';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
// import { buisinessActivityValue } from '../../../../redux/store/admin/buisinessActivitySlice';
import { saveAdminData } from '../../../../../services/utility-api';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/system';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { GlobalContext } from '../../../../../Context/GlobalContext';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Breadcrumb from '../../../../../components/AdministrationModules/Breadcrumb';

const PopperStyledComponent = styled(Popper)(({ theme }) => ({
  border: `1px solid ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const id = '00000000-0000-0000-0000-000000000000';

const AddEditActivity = () => {
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const activityPermissions = permissions?.['Activity'];
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activityStatus, setActivityStatus] = useState(location?.state?.activityStatus || {});

  useEffect(() => {
    setActivityStatus(location?.state?.activityStatus || {});
  }, [location.state]);

  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const lastPartUrl = location.pathname.split('/')[location.pathname.split('/').length - 1];
  const isAddPage = lastPartUrl == 'add-activity';

  const [dropdownopen, setDropdownopen] = useState(false);

  const [notificationTemplates, setNotificationTemplates] = useState({});
  const [notificationNameMapping, setNotificationNameMapping] = useState({});
  const getNotificationTemplates = async () => {
    const response = await getAdminData('notificationTemplate');
    const data = response.data.data;
    const tempNotificationTemplates = {};
    const tempNotificationNameMapping = {};
    data.map(row => {
      tempNotificationTemplates[row.notificationTemplateSeq] = row;
      tempNotificationNameMapping[row.notificationTemplateName] = row;
    });

    setNotificationTemplates(tempNotificationTemplates);
    setNotificationNameMapping(tempNotificationNameMapping);
  };

  const [checkAll, setCheckAll] = useState(false);
  const checkAllChange = (event, handleChange) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      let newdata = [...Object.keys(county).map(countySeq => county[countySeq]['countyName'])];
      delete newdata[0];
      handleChange({
        target: { value: newdata, name: 'activityOnNotificationTemplate' },
      });
    } else {
      handleChange({
        target: { value: [], name: 'activityOnNotificationTemplate' },
      });
    }
  };

  const [uniqueStatusValues, setUniqueStatusValues] = useState([]);
  const [uniquePrerequisiteValues, setUniquePrerequisiteValues] = useState([]);
  let tempUniqueStatusValues = [];
  let tempUniquePrerequisiteValues = [];

  useEffect(() => {
    getNotificationTemplates();
    if (!isAddPage && !Object.keys(activityStatus).length) navigate('/sysadmin/businessrule');
    else if (!isAddPage) {
      let editObj = activityStatus;

      editObj.activityOnStatusValue.forEach(row => {
        if (
          !tempUniqueStatusValues.filter(status => status.statusTypeSeq == row.statusTypeSeq).length
        ) {
          tempUniqueStatusValues.push(row);
        }
      });
      editObj.activityPrerequisite.forEach(row => {
        if (
          !tempUniquePrerequisiteValues.filter(status => status.statusTypeSeq == row.statusTypeSeq)
            .length
        ) {
          tempUniquePrerequisiteValues.push(row);
        }
      });

      setUniqueStatusValues(tempUniqueStatusValues);
      setUniquePrerequisiteValues(tempUniquePrerequisiteValues);
    }
  }, []);

  const [departments, setDepartments] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [statusObjs, setStatusObjs] = useState([]);
  const [statusValueObjs, setStatusValueObjs] = useState([]);
  const [prerequisitesObjs, setPrerequisitesObjs] = useState([]);

  const getDepartments = async () => {
    try {
      const response = await getAdminData('assignmentType');
      setDepartments(response.data.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getStatuses = async () => {
    try {
      const response = await getAdminData('statusType');
      const data = response.data.data;
      const tempStatusValueObjs = {};
      data.forEach(row => {
        tempStatusValueObjs[row['statusTypeSeq']] = row['statusValue'];
      });

      setStatuses(response.data.data);
      setStatusValueObjs(tempStatusValueObjs);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getDepartments();
    getStatuses();
  }, []);

  const onSave = async values => {
    const postValues = {
      ...values,
    };
    delete postValues.prerequisites;
    delete postValues.notification;
    delete postValues.isPartInQC;

    const aNTActive =
      postValues.activityOnNotificationTemplate?.map(row => {
        return {
          activityOnNotificationTemplateSeq: !isAddPage
            ? activityStatus?.activityOnNotificationTemplate?.filter(
                tempRow =>
                  tempRow.notificationTemplateSeq ==
                  notificationNameMapping[row].notificationTemplateSeq
              ).length
              ? activityStatus?.activityOnNotificationTemplate?.filter(
                  tempRow =>
                    tempRow.notificationTemplateSeq ==
                    notificationNameMapping[row].notificationTemplateSeq
                )[0].activityOnNotificationTemplateSeq
              : id
            : id,
          activitySeq: postValues.activitySeq,
          notificationTemplateSeq: notificationNameMapping[row].notificationTemplateSeq,
          status: 'Active',
        };
      }) || [];

    const activeSeqs = aNTActive.map(row => row.activityOnNotificationTemplateSeq);
    const aNTInActive =
      activityStatus?.activityOnNotificationTemplate
        ?.filter(row => !activeSeqs.includes(row.activityOnNotificationTemplateSeq))
        .map(row => {
          return {
            ...row,
            status: 'InActive',
          };
        }) || [];

    try {
      const response = await saveAdminData({
        values: {
          ...postValues,
          activitySeq: postValues['activitySeq'] || id,
          activityOnNotificationTemplate: [...aNTInActive, ...aNTActive],
        },
        tableName: 'activity',
      });

      setDialogContents(response.message);
      setOpenApiDialogBox(true);
      if (response.status === 0) {
        setTimeout(() => {
          navigate('/sysadmin/businessrule');
        }, [1500]);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const MENU_OBJECT = {
    Home: '/home',
    Activity: '/sysadmin/businessrule',
    'Add Edit Business Rule': '',
  };

  return Object.keys(notificationTemplates).length ? (
    <>
      <Grid container spacing={2} sx={{ mt: '10px', padding: '16px' }}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {/* <div className='container my-2 py-2'> */}
              <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
              <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0, mb: 3 }} />
              {/* <MainCard> */}
              <Formik
                initialValues={
                  !isAddPage
                    ? {
                        ...activityStatus,
                        prerequisites: activityStatus?.activityPrerequisite?.length,
                        activityOnStatusValue: uniqueStatusValues,
                        activityPrerequisite: uniquePrerequisiteValues,
                        activityOnNotificationTemplate:
                          activityStatus?.activityOnNotificationTemplate?.map(
                            row =>
                              notificationTemplates[row.notificationTemplateSeq]
                                .notificationTemplateName
                          ),
                        notification: activityStatus?.activityOnNotificationTemplate?.length
                          ? true
                          : false,
                      }
                    : {
                        activitySeq: id,
                        activityName: '',
                        activityDesc: '',
                        assignmentTypeSeq: '',
                        isManuallyFormed: false,
                        showInCaseOverview: false,
                        isNoteRequired: false,
                        maxAllotedTaskTime: 0,
                        status: 'Active',
                        activityCategorySeq: '00000000-0000-0000-0000-000000000000',
                        notShowInStatusManagement: false,
                        operationModeType: '',
                        activityOnStatusValue: [],
                        activityPrerequisite: [],
                        activityOnNotificationTemplate: [],
                      }
                }
                validationSchema={Yup.object().shape({
                  assignmentTypeSeq: Yup.string().required('Department is required').nullable(),
                  activityName: Yup.string().required('Activity name is required').nullable(),
                  activityOnStatusValue: Yup.array().of(
                    Yup.object().shape({
                      statusTypeSeq: Yup.string().required('Status type is required'),
                      statusValueCode: Yup.string().required('Status value is required'),
                    })
                  ),
                  activityPrerequisite: Yup.array().of(
                    Yup.object().shape({
                      statusTypeSeq: Yup.string().required('Status type is required'),
                      statusValueCode: Yup.string().required('Status value is required'),
                    })
                  ),
                  activityOnNotificationTemplate: Yup.array().when('notification', notification => {
                    if (notification[0]) {
                      return Yup.array()
                        .required('Template name is required')
                        .min(1, 'Template name is required');
                    }
                  }),
                })}
                onSubmit={onSave}
              >
                {({
                  errors,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  handleChange,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      {console.log('values', values, statuses, statusValueObjs)}
                      <Grid container spacing={2}>
                        <Grid item xs={10} md={6} mb={3} mt={3}>
                          <Typography variant='h5'>
                            <strong>{!isAddPage ? 'Modify ' : 'Enter New '} Activity</strong>
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'end',
                          }}
                        >
                          <Box sx={{ mb: 4 }}>
                            <Button
                              variant='contained'
                              onClick={() => {
                                navigate('/sysadmin/businessrule/add-activity', {
                                  state: {
                                    activityStatus: {},
                                  },
                                });
                                window.location.reload();
                                setActivityStatus({});
                                handleChange({
                                  target: {
                                    activitySeq: id,
                                    activityName: '',
                                    activityDesc: '',
                                    assignmentTypeSeq: '',
                                    isManuallyFormed: false,
                                    showInCaseOverview: false,
                                    isNoteRequired: false,
                                    maxAllotedTaskTime: 0,
                                    status: 'Active',
                                    activityCategorySeq: '00000000-0000-0000-0000-000000000000',
                                    notShowInStatusManagement: false,
                                    operationModeType: '',
                                    activityOnStatusValue: [],
                                    activityPrerequisite: [],
                                    activityOnNotificationTemplate: [],
                                  },
                                });
                              }}
                              disabled={!activityPermissions?.add}
                            >
                              Add Activity
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item sm={12} xs={12} md={6} lg={6}>
                          <FormControl fullWidth focused required>
                            <InputLabel htmlFor='assignmentTypeSeq' id='assignmentTypeSeq'>
                              Department
                            </InputLabel>
                            <Select
                              onChange={handleChange}
                              variant='outlined'
                              label='Department'
                              focused
                              displayEmpty
                              size='large'
                              name={`assignmentTypeSeq`}
                              value={values?.assignmentTypeSeq || ''}
                            >
                              {departments.map(row => {
                                return (
                                  <MenuItem
                                    key={row.assignmentTypeSeq}
                                    value={row.assignmentTypeSeq}
                                  >
                                    {row.assignmentTypeName}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            <ErrorMessage
                              name='assignmentTypeSeq'
                              render={msg => <FormHelperText error>{msg}</FormHelperText>}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item sm={12} xs={12} md={6} lg={6}>
                          <FormControl fullWidth required component='fieldset' variant='standard'>
                            <TextField
                              required
                              focused
                              id='outlined-basic'
                              label='Activity'
                              variant='outlined'
                              name='activityName'
                              value={values ? values['activityName'] : ''}
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              name={'activityName'}
                              render={msg => <FormHelperText error>{msg}</FormHelperText>}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} mt={2}>
                        <Grid item xs={12}>
                          <FormControl fullWidth component='fieldset' variant='standard'>
                            <TextField
                              focused
                              id='outlined-basic'
                              label='Activity Description'
                              variant='outlined'
                              name='activityDesc'
                              value={values ? values['activityDesc'] : ''}
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              name={'activityDesc'}
                              render={msg => <FormHelperText error>{msg}</FormHelperText>}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                      <Grid container spacing={2} mt={2}>
                        <Grid item xs={10} mb={3} mt={3}>
                          <Typography variant='h6'>
                            <strong>Regular</strong>
                          </Typography>
                        </Grid>

                        <Grid item sm={6} xs={12} md={6} lg={4}>
                          <FormGroup>
                            <FormControlLabel
                              name={'isManuallyFormed'}
                              checked={values?.isManuallyFormed?.toString().toLowerCase() == 'true'}
                              control={
                                <Checkbox
                                  onClick={e => {
                                    handleChange({
                                      target: {
                                        name: 'isManuallyFormed',
                                        value: !(
                                          values?.isManuallyFormed?.toString().toLowerCase() ==
                                          'true'
                                        ),
                                      },
                                    });
                                  }}
                                />
                              }
                              label='Allow Manual Override?'
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item sm={6} xs={12} md={6} lg={4}>
                          <FormGroup>
                            <FormControlLabel
                              name={'showInCaseOverview'}
                              checked={
                                values?.showInCaseOverview?.toString().toLowerCase() == 'true'
                              }
                              control={
                                <Checkbox
                                  onClick={e => {
                                    handleChange({
                                      target: {
                                        name: 'showInCaseOverview',
                                        value: !(
                                          values?.showInCaseOverview?.toString().toLowerCase() ==
                                          'true'
                                        ),
                                      },
                                    });
                                  }}
                                />
                              }
                              label='Show in Case Overview?'
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item sm={6} xs={12} md={6} lg={4}>
                          <FormGroup>
                            <FormControlLabel
                              name={'isNoteRequired'}
                              checked={values?.isNoteRequired?.toString().toLowerCase() == 'true'}
                              control={
                                <Checkbox
                                  onClick={e => {
                                    handleChange({
                                      target: {
                                        name: 'isNoteRequired',
                                        value: !(
                                          values?.isNoteRequired?.toString().toLowerCase() == 'true'
                                        ),
                                      },
                                    });
                                  }}
                                />
                              }
                              label='Note Required?'
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item sm={6} xs={12} md={6} lg={4}>
                          <FormGroup>
                            <FormControlLabel
                              name={'notShowInStatusManagement'}
                              checked={
                                values?.notShowInStatusManagement?.toString().toLowerCase() ==
                                'true'
                              }
                              control={
                                <Checkbox
                                  onClick={e => {
                                    handleChange({
                                      target: {
                                        name: 'notShowInStatusManagement',
                                        value: !(
                                          values?.notShowInStatusManagement
                                            ?.toString()
                                            .toLowerCase() == 'true'
                                        ),
                                      },
                                    });
                                  }}
                                />
                              }
                              label=' Hide this Activity in Status Management and Mobile Application '
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item sm={6} xs={12} md={6} lg={4}>
                          <FormGroup>
                            <FormControlLabel
                              name={'notification'}
                              checked={values?.notification?.toString().toLowerCase() == 'true'}
                              control={
                                <Checkbox
                                  onClick={e => {
                                    if (values?.notification) {
                                      handleChange({
                                        target: {
                                          value: [],
                                          name: 'activityOnNotificationTemplate',
                                        },
                                      });
                                      setCheckAll(false);
                                    }
                                    handleChange({
                                      target: {
                                        name: 'notification',
                                        value: !(
                                          values?.notification?.toString().toLowerCase() == 'true'
                                        ),
                                      },
                                    });
                                  }}
                                />
                              }
                              label='Notification'
                            />
                          </FormGroup>
                        </Grid>
                        {values?.notification && Object.keys(notificationTemplates).length ? (
                          <>
                            <Grid item xs={12}>
                              <Autocomplete
                                multiple
                                limitTags={9}
                                id='notification-names'
                                options={
                                  Object.keys(notificationTemplates).map(
                                    notificationTemplateSeq =>
                                      notificationTemplates[notificationTemplateSeq][
                                        'notificationTemplateName'
                                      ]
                                  ) || []
                                }
                                value={values?.activityOnNotificationTemplate}
                                open={dropdownopen}
                                name='activityOnNotificationTemplate'
                                onChange={(event, newValue, reason) => {
                                  if (reason === 'selectOption') {
                                    handleChange({
                                      target: {
                                        value: newValue,
                                        name: 'activityOnNotificationTemplate',
                                      },
                                    });
                                  } else if (reason === 'removeOption') {
                                    setCheckAll(false);
                                    handleChange({
                                      target: {
                                        value: newValue,
                                        name: 'activityOnNotificationTemplate',
                                      },
                                    });
                                  } else if (reason === 'clear') {
                                    handleChange({
                                      target: {
                                        value: [],
                                        name: 'activityOnNotificationTemplate',
                                      },
                                    });
                                    setCheckAll(false);
                                  }
                                }}
                                onClose={(e, reason) => {
                                  setDropdownopen(false);
                                }}
                                onOpen={() => {
                                  setDropdownopen(true);
                                }}
                                PopperComponent={param => (
                                  <PopperStyledComponent {...param}>
                                    <Box {...param} />
                                    <Divider />
                                  </PopperStyledComponent>
                                )}
                                getOptionLabel={option => option?.toString() || ''}
                                renderOption={(props, option, { selected }) => (
                                  <>
                                    <li {...props}>
                                      {option === 'Select All' ? (
                                        <Checkbox
                                          checked={checkAll}
                                          onChange={e => checkAllChange(e, handleChange)}
                                          id='check-all'
                                          sx={{ marginRight: '8px' }}
                                          onMouseDown={e => e.preventDefault()}
                                        />
                                      ) : (
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selected || checkAll}
                                        />
                                      )}
                                      {option}
                                    </li>
                                  </>
                                )}
                                style={{ width: '100%' }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label='Template Name'
                                    placeholder='Select Template Name'
                                  />
                                )}
                              />
                              <ErrorMessage
                                name='activityOnNotificationTemplate'
                                render={msg => <FormHelperText error>{msg}</FormHelperText>}
                              />
                            </Grid>
                          </>
                        ) : null}
                      </Grid>
                      <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                      <StatusTable
                        values={values}
                        handleChange={handleChange}
                        statuses={statuses}
                        statusValueObjs={statusValueObjs}
                      />
                      <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                      <Grid container spacing={2} mt={2}>
                        <Grid item>
                          <FormGroup>
                            <FormControlLabel
                              name={'prerequisites'}
                              checked={values?.prerequisites}
                              control={
                                <Checkbox
                                  onClick={e => {
                                    if (values?.prerequisites) {
                                      handleChange({
                                        target: {
                                          name: 'activityPrerequisite',
                                          value: [],
                                        },
                                      });
                                    }
                                    handleChange({
                                      target: {
                                        name: 'prerequisites',
                                        value: !values?.prerequisites,
                                      },
                                    });
                                  }}
                                />
                              }
                              label='Prerequisites'
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>

                      {values?.prerequisites ? (
                        <>
                          <PrerequisiteTable
                            values={values}
                            handleChange={handleChange}
                            statuses={statuses}
                            statusValueObjs={statusValueObjs}
                          />
                        </>
                      ) : null}

                      <Grid container spacing={2} mt={2}>
                        <Grid item xs={12}>
                          <Box>
                            <Button
                              variant='contained'
                              sx={{ marginTop: '7px' }}
                              onClick={handleSubmit}
                              disabled={!activityPermissions?.edit}
                            >
                              {!isAddPage ? 'Update' : 'Save'}
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
              {/* </MainCard> */}
              {/* </div> */}
              <div className='modal'>
                <Dialog
                  open={openApiDialogBox}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setOpenApiDialogBox(false)}
                  aria-describedby='alert-dialog-slide-description'
                >
                  <DialogTitle></DialogTitle>
                  <DialogContent>
                    <DialogContentText id='alert-dialog-slide-description'>
                      {dialogContents}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <IconButton onClick={() => setOpenApiDialogBox(false)}>
                      <CloseIcon />
                    </IconButton>
                  </DialogActions>
                </Dialog>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

export default AddEditActivity;
