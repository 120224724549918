import React from 'react';
import ControlComponents from '../../views/SysAdmin/SysAdminModules/UserAdminModules/ControlComponents';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  OCSME_CERTIFICATION,
  PHYSICIAN_CERTIFICATION_PATHOLOGY,
} from '../../views/CMSAdmin/Menu/constants';

const PhysicianCertification = ({
  pathology,
  mdLicenseNumber,
  ocsmeCertification,
  expiryDate,
  ocsmeExpiryDate,
  ...rest
}) => {
  const path = window.location.pathname.includes('yourprofile');
  return (
    <Box
      sx={{
        flexGrow: 1,
        // border: '1px solid grey',
        // borderRadius: '12px',
        // padding: '8px 0px 12px',
        marginTop: '16px',
      }}
    >
      <h5 style={{ marginTop: '8px' }}>Pathologist Certification</h5>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12} md={12} sm={12}>
          <ControlComponents
            control='multiSelect'
            name={pathology}
            label='Pathology'
            options={PHYSICIAN_CERTIFICATION_PATHOLOGY}
            type='select'
            module='Pathology'
            disabled={path}
          />
        </Grid>
        <Grid item lg={6} xs={12} md={12} sm={12}>
          <ControlComponents
            control='date'
            name={expiryDate}
            label='Expiry Date'
          />
        </Grid>
        <Grid item lg={6} xs={12} md={12} sm={12}>
          <ControlComponents
            control='input'
            name={mdLicenseNumber}
            label='MD License #'
            type='input'
          />
        </Grid>
        <Grid item lg={6} xs={12} md={12} sm={12}>
          <ControlComponents
            control='multiSelect'
            name={ocsmeCertification}
            label='OCSME Certification'
            options={OCSME_CERTIFICATION}
            type='select'
            module='OCSME'
            disabled={path}
          />
        </Grid>
        <Grid item lg={6} xs={12} md={12} sm={12}>
          <ControlComponents
            control='date'
            name={ocsmeExpiryDate}
            label='Expiry Date'
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PhysicianCertification;
