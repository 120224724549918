import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Autocomplete,
  TextField,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { debounce } from 'lodash';
import { useAuth } from '../../utils/auth/AuthService';
import { LoadingButton } from '@mui/lab';

interface CaseViewGroupDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setGroupStatus: React.Dispatch<React.SetStateAction<'success' | 'failure' | undefined>>;
  caseSeq: string;
}

interface GroupCaseOption {
  caseSeq: string;
  caseID: string;
  firstName: string;
  lastName: string;
}

const matchCount = 20;

export default function CaseViewGroupDialog({
  open,
  setOpen,
  setGroupStatus,
  caseSeq,
}: CaseViewGroupDialogProps) {
  const [caseOptions, setCaseOptions] = useState<GroupCaseOption[]>([]);
  const [groupCaseOption, setGroupCaseOption] = useState<GroupCaseOption | undefined>(undefined);
  const [loadingCases, setLoadingCases] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const { user } = useAuth();
  const { REACT_APP_API_URL } = process.env;

  const fetchCaseOptions = debounce(async (caseIdOrLastName: string) => {
    if (caseIdOrLastName.includes('--') || caseIdOrLastName === '') {
      return;
    }

    const caseIdRegex =
      /^(\d{1,2})(?:(?<=\d{2})(-\d{0,2}){0,1})?(?:(?<=\d{2}-\d{2})(-\d{0,4}){0,1})?$/;

    var caseID = null;
    var lastName = null;

    if (caseIdRegex.test(caseIdOrLastName)) {
      caseID = caseIdOrLastName;
    } else {
      lastName = caseIdOrLastName;
    }

    setLoadingCases(true);

    await fetch(
      `${REACT_APP_API_URL}GetCaseMatchesFromCaseIdOrLastName?count=${matchCount}&caseID=${
        caseID ?? ''
      }&lastName=${lastName ?? ''}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      }
    )
      .then(res => {
        return res.json();
      })
      .then(data => {
        setCaseOptions(data);
        setLoadingCases(false);
      })
      .catch(e => {});

    setLoadingCases(false);
  }, 300); // 300ms debounce time

  const handleChange = (event, value) => {
    setGroupCaseOption(value);
  };

  const handleGroupCase = async () => {
    setLoadingSubmit(true);

    // Fetch group case api here
    let formData = new FormData();
    formData.append('caseSeq', caseSeq);
    formData.append('groupCaseSeq', groupCaseOption?.caseSeq);

    await fetch(REACT_APP_API_URL + 'updatecasegroup', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setGroupStatus('success');
        setLoadingSubmit(false);
      })
      .catch(e => {
        setGroupStatus('failure');
        alert(e);
      });

    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          console.log('Form Submitted');
          setOpen(false);
        },
      }}
    >
      <DialogTitle>Search for a Case to Group</DialogTitle>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <DialogContentText>
          Please enter a case number or last name to search for a case, then choose the case you
          want to group.
        </DialogContentText>
        <br />
        <Stack direction='row' spacing={2}>
          <Autocomplete
            freeSolo
            options={caseOptions}
            renderOption={(props, option) => (
              <li {...props} key={option.caseID}>
                <Stack>
                  <Typography variant='body1' fontWeight={500}>
                    {option.caseID}
                  </Typography>
                  <Typography variant='caption' sx={{ color: 'gray' }}>
                    {option.lastName}, {option.firstName}
                  </Typography>
                </Stack>
              </li>
            )}
            loading={loadingCases}
            getOptionLabel={o =>
              (o as GroupCaseOption).caseID +
              ' -- ' +
              (o as GroupCaseOption).lastName +
              ', ' +
              (o as GroupCaseOption).firstName
            }
            onInputChange={(event, value) => {
              fetchCaseOptions(value);
            }}
            onChange={handleChange}
            renderInput={params => (
              <TextField
                {...params}
                autoFocus
                id='caseidorlastname'
                label='CaseID or Last Name'
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    paddingRight: '0px !important',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    '& .MuiAutocomplete-endAdornment': {
                      position: 'relative',
                    },
                  },
                  endAdornment: (
                    <Stack direction='row' alignItems='center' justifyContent='center' spacing={2}>
                      {loadingCases ? <CircularProgress color='inherit' size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </Stack>
                  ),
                }}
              />
            )}
            fullWidth
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color='error'>
          Cancel
        </Button>
        <LoadingButton loading={loadingSubmit} onClick={handleGroupCase}>
          <span>Group</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
