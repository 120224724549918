import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useAuth } from '../../utils/auth/AuthService';
import dimensions from '../../utils/dimensions';
import { UserAvatar } from './UserAvatar';
import { useUnsavedChanges } from '../../Context/UnsavedChangesContext';
import { useState } from 'react';
import UnsavedChangesDialog from '../../components/UnsavedChangesDialog';

export default function UserMenuDrawerItem({
  path = '',
  authorizedRoles = null,
  avatarIcon = <></>,
  userAvatar = false,
  primaryText,
  secondaryText = '',
  divider = false,
  handleCloseUserMenu,
  closeButton = false,
  handleUserLogOut = null,
  stillButton = false,
  onClick = null,
}) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const theme = useTheme();
  const { hasUnsavedChanges, resetUnsavedChanges } = useUnsavedChanges();
  const [unsavedDialogOpen, setUnsavedDialogOpen] = useState<boolean>(false);
  const [resolveDialog, setResolveDialog] = useState<((value: boolean) => void) | null>(null); 

  const checkForUnsavedChanges = (): Promise<boolean> => {
    if (!hasUnsavedChanges) return Promise.resolve(true);

    setUnsavedDialogOpen(true);

    return new Promise(resolve => {
      setResolveDialog(() => resolve);
    });
  };

  // const checkForUnsavedChanges = (): boolean => {
  //   if (hasUnsavedChanges) {
  //     const confirmLeave = window.confirm(
  //       'You have unsaved changes. Are you sure you want to leave?'
  //     );
  //     if (confirmLeave) {
  //       resetUnsavedChanges();
  //       return true;  
  //     }
  //     return false;  
  //   }
  //   return true; 
  // };

  const handleUnsavedDialogLeave = () => {
    resetUnsavedChanges();
    setUnsavedDialogOpen(false);
    if (resolveDialog) resolveDialog(true);
  };

  const handleUnsavedDialogCancel = () => {
    setUnsavedDialogOpen(false);
    if (resolveDialog) resolveDialog(false);
  };

  if (authorizedRoles == null) {
    return (
      <>
        {divider && <Divider sx={{ m: 1, opacity: 0.8 }} />}
        <ListItem
          key={path != '' ? path.substring(1) : primaryText}
          secondaryAction={
            closeButton && (
              <IconButton edge='end' onClick={handleCloseUserMenu}>
                <CloseIcon />
              </IconButton>
            )
          }
        >
          {path ? (
            <ListItemButton
              onClick={async (e) => {
                const canProceed = await checkForUnsavedChanges();
                if (!canProceed) return;
                if (typeof onClick === 'function') {
                  onClick(e);
                } else if (handleUserLogOut != null) {
                  handleUserLogOut();
                } else {
                  handleCloseUserMenu();
                  navigate(path);
                }
              }}
            >
              <ListItemAvatar>
                {userAvatar ? (
                  <UserAvatar
                    userseq={user?.userSeq ?? ''}
                    initials={`${user?.userFirstName?.charAt(0)}${user?.userLastName?.charAt(0)}`}
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                      backgroundColor: theme.palette.text.secondary,
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                      backgroundColor: theme.palette.text.secondary,
                    }}
                  >
                    {avatarIcon}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={primaryText}
                secondary={secondaryText}
                primaryTypographyProps={{
                  fontWeight: 500,
                }}
                secondaryTypographyProps={{
                  fontWeight: 400,
                }}
              />
            </ListItemButton>
          ) : stillButton ? (
            <ListItemButton
              onClick={async (e) => {
                const canProceed = await checkForUnsavedChanges();
                if (!canProceed) return;
                if (typeof onClick === 'function') {
                  onClick(e);
                } else if (handleUserLogOut != null) {
                  handleUserLogOut();
                }
              }}
            >
              <ListItemAvatar>
                {userAvatar ? (
                  <UserAvatar
                    userseq={user?.userSeq ?? ''}
                    initials={`${user?.userFirstName?.charAt(0)}${user?.userLastName?.charAt(0)}`}
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                      backgroundColor: theme.palette.text.secondary,
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                      backgroundColor: theme.palette.text.secondary,
                    }}
                  >
                    {avatarIcon}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={primaryText}
                secondary={secondaryText}
                primaryTypographyProps={{
                  fontWeight: 500,
                }}
                secondaryTypographyProps={{
                  fontWeight: 400,
                }}
              />
            </ListItemButton>
          ) : (
            <>
              <ListItemAvatar>
                {userAvatar ? (
                  <UserAvatar
                    userseq={user?.userSeq ?? ''}
                    initials={`${user?.userFirstName?.charAt(0)}${user?.userLastName?.charAt(0)}`}
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                      backgroundColor: theme.palette.text.secondary,
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                      backgroundColor: theme.palette.text.secondary,
                    }}
                  >
                    {avatarIcon}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={primaryText}
                secondary={secondaryText}
                primaryTypographyProps={{
                  fontWeight: 500,
                }}
                secondaryTypographyProps={{
                  fontWeight: 400,
                }}
              />
            </>
          )}
        </ListItem>
        
        <UnsavedChangesDialog open={unsavedDialogOpen} onLeave={handleUnsavedDialogLeave} onCancel={handleUnsavedDialogCancel} />
      </>
    );
  } else {
    return user?.roleCheck(authorizedRoles) ? (
      <>
        {divider && <Divider sx={{ m: 1, opacity: 0.8 }} />}
        <ListItem
          key={path != '' ? path.substring(1) : primaryText}
          secondaryAction={
            closeButton && (
              <IconButton edge='end' onClick={handleCloseUserMenu}>
                <CloseIcon />
              </IconButton>
            )
          }
        >
          {path ? (
            <ListItemButton
              onClick={async (e) => {
                const canProceed = await checkForUnsavedChanges();
                if (!canProceed) return;
                if (typeof onClick === 'function') {
                  onClick(e);
                } else {
                  if (handleUserLogOut != null) {
                    handleUserLogOut();
                  } else {
                    handleCloseUserMenu();
                    navigate(path);
                  }
                }
              }}
            >
              <ListItemAvatar>
                {userAvatar ? (
                  <UserAvatar
                    userseq={user?.userSeq ?? ''}
                    initials={`${user?.userFirstName?.charAt(0)}${user?.userLastName?.charAt(0)}`}
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                      backgroundColor: theme.palette.text.secondary,
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                      backgroundColor: theme.palette.text.secondary,
                    }}
                  >
                    {avatarIcon}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={primaryText}
                secondary={secondaryText}
                primaryTypographyProps={{
                  fontWeight: 500,
                }}
                secondaryTypographyProps={{
                  fontWeight: 400,
                }}
              />
            </ListItemButton>
          ) : stillButton ? (
            <ListItemButton
              onClick={async (e) => {
                const canProceed = await checkForUnsavedChanges();
                if (!canProceed) return;
                if (typeof onClick === 'function') {
                  onClick(e);
                } else {
                  if (handleUserLogOut != null) {
                    handleUserLogOut();
                  }
                }
              }}
            >
              <ListItemAvatar>
                {userAvatar ? (
                  <UserAvatar
                    userseq={user?.userSeq ?? ''}
                    initials={`${user?.userFirstName?.charAt(0)}${user?.userLastName?.charAt(0)}`}
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                      backgroundColor: theme.palette.text.secondary,
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                      backgroundColor: theme.palette.text.secondary,
                    }}
                  >
                    {avatarIcon}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={primaryText}
                secondary={secondaryText}
                primaryTypographyProps={{
                  fontWeight: 500,
                }}
                secondaryTypographyProps={{
                  fontWeight: 400,
                }}
              />
            </ListItemButton>
          ) : (
            <>
              <ListItemAvatar>
                {userAvatar ? (
                  <UserAvatar
                    userseq={user?.userSeq ?? ''}
                    initials={`${user?.userFirstName?.charAt(0)}${user?.userLastName?.charAt(0)}`}
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                      backgroundColor: theme.palette.text.secondary,
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                      backgroundColor: theme.palette.text.secondary,
                    }}
                  >
                    {avatarIcon}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={primaryText}
                secondary={secondaryText}
                primaryTypographyProps={{
                  fontWeight: 500,
                }}
                secondaryTypographyProps={{
                  fontWeight: 400,
                }}
              />
            </>
          )}
        </ListItem>
        
        <UnsavedChangesDialog open={unsavedDialogOpen} onLeave={handleUnsavedDialogLeave} onCancel={handleUnsavedDialogCancel} />
      </>
    ) : (
      <></>
    );
  }
}
