import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import ControlComponents from './ControlComponents';
import { Box, Button, FormControl, Grid, Typography } from '@mui/material';
import {
  fetchFormattedDate,
  fetchLoginHistory,
  findDayDifference,
} from '../../../../services/utility-api';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  DataGridPremium,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import MUIRichTextEditor from 'mui-rte';
import axios from 'axios';
import { GlobalContext } from '../../../../Context/GlobalContext';
import authHeader from '../../../../services/auth-header';
import { useNavigate } from 'react-router';
import Chips from '../../../../components/AdministrationModules/Chips';
import * as Yup from 'yup';
import ApiNotification from '../../../../components/DialogBox';
import UserHeaderContainer from './UserHeaderContainer';
import { useAuth } from '../../../../utils/auth/AuthService.tsx';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ActionList } from '../../../../components/CaseView/CaseViewDateTimeField';

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = gridPageSelector(apiRef);
  const pageCount = gridPageCountSelector(apiRef);

  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={props2 => (
        <PaginationItem {...props2} sx={{ fontFamily: 'DataGrid, sans-serif' }} disableRipple />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{ mr: 3 }}
    />
  );
}

const Notes = ({ userSeq }) => {
  const { user } = useAuth();
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const userPermissions = permissions?.['Users'];
  const { profileInformation } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [notes, setNotes] = useState('');
  const [rows, setRows] = useState([]);
  const [modal, setModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [message, setMessage] = useState('');
  const [apiResponse, setApiResponse] = useState('');
  const [startDate, setStartDate] = useState(null);
  let today = dayjs();
  const [startDatecall, setStartDatecall] = useState(today);
  const initialValues = {
    userName: user?.userFirstName + ' ' + user?.userLastName,
  };

  const columns = [
    {
      field: 'supplementalNote',
      // headerClassName: 'super-app-theme--header',
      headerName: 'Notes',
      type: 'text',
      minWidth: 200,
      align: 'left',
      flex: 5,
    },
    {
      field: 'name',
      // headerClassName: 'super-app-theme--header',
      headerName: 'User Name',
      renderCell: () => {
        return <span>{user?.userFirstName + ' ' + user?.userLastName}</span>;
      },
      type: 'text',
      minWidth: 200,
      align: 'left',
      flex: 5,
    },
    {
      field: 'supplementalDateTime',
      // headerClassName: 'super-app-theme--header',
      headerName: 'Date Time',
      type: 'text',
      minWidth: 200,
      align: 'left',
      flex: 5,
    },
  ];

  const handleEndDateChange2 = date => {
    setStartDatecall(date);
  };
  const fetchNotes = async () => {
    const getNotes = await axios.get(
      `${process.env.REACT_APP_API_URL}GetUserNote?EdituserSeq=${userSeq}&LogedInUserSeq=${user?.userSeq}`,
      { headers: authHeader() }
    );
    const dataGridUpdate = getNotes.data.data?.map((row, index) => ({
      id: `${index}`,
      supplementalNote: `${row.supplementalNote}`,
      name: `${row.name}`,
      supplementalDateTime: `${new Date(row.supplementalDateTime).toLocaleString()}`,
    }));
    setRows(dataGridUpdate);
  };

  useEffect(() => {
    fetchNotes();
    if (profileInformation.length === 0) {
      navigate('/sysadmin');
    }
  }, []);

  const onSubmit = async values => {
    console.log('values123', values);
    if (!notes) {
      setModal(true);
      setMessage('Please add notes');
    } else if (!startDatecall) {
      setModal(true);
      setMessage('Please enter date');
    } else {
      const getTime = startDatecall?.$d?.getTime();
      const getUpdateDate = getTime + 55 * 60 * 60 * 100;
      const getStartDate = new Date(getUpdateDate);
      const NotesData = {
        supplementalNote: notes,
        name: `${user?.userLastName} ${user?.userFirstName}`,
        supplementalDateTime: `${getStartDate?.toISOString()}`,
        editUserId: userSeq,
        logedInUserId: user?.userSeq,
      };
      const response = await axios.post(`${process.env.REACT_APP_API_URL}SaveUserNote`, NotesData, {
        headers: authHeader(),
      });

      if (response.data.status === 0) {
        const getNotes = await axios.get(
          `${process.env.REACT_APP_API_URL}GetUserNote?EdituserSeq=${userSeq}&LogedInUserSeq=${user?.userSeq}`,
          { headers: authHeader() }
        );
        const dataGridUpdate = getNotes.data.data?.map((row, index) => ({
          id: `${index}`,
          supplementalNote: `${row.supplementalNote}`,
          name: `${row.name}`,
          supplementalDateTime: `${new Date(row.supplementalDateTime).toLocaleString()}`,
        }));
        setRows(dataGridUpdate);
        setAddModal(true);
        setApiResponse(response.data.message);
      }
    }
  };

  const handleNotes = notes => {
    setNotes(notes.getCurrentContent().getPlainText());
  };

  const myTheme = createTheme({
    palette: {
      background: {
        default: 'red',
      },
    },
    typography: {
      fontSize: 16,
    },
  });

  // const validationSchema = Yup.object({
  //   date: Yup.string().required('Date is required'),
  // });

  return (
    <>
      <div className='container-fluid my-2 py-2'>
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {formik => (
            <>
              {console.log('formik', formik)}
              {/* <UserHeaderContainer />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  background: 'black',
                  padding: '16px',
                  borderRadius: '32px',
                  margin: '10px 0px',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item lg={3} xs={6} md={6} sm={6}>
                    <Chips title='User Profile' location='/edit-user' />
                  </Grid>
                  <>
                    <Grid item lg={3} xs={6} md={6} sm={6}>
                      <Chips title='Login History' location='/login-history' />
                    </Grid>
                    <Grid item lg={3} xs={6} md={6} sm={6}>
                      <Chips title='Documents' location='/documents' />
                    </Grid>
                    <Grid item lg={3} xs={6} md={6} sm={6}>
                      <Chips title='Notes' location='/notes' />
                    </Grid>
                  </>
                </Grid>
              </Box> */}
              <Form>
                <Box sx={{ paddingTop: '10px' }}>
                  <Grid container spacing={2}>
                    <Grid item lg={5} xs={12} md={12} sm={12}>
                      <ControlComponents
                        control='input'
                        name='userName'
                        label='User Name'
                        disabled={true}
                      />
                    </Grid>
                    <Grid item lg={5} xs={12} md={12} sm={12}>
                      {/* <ControlComponents control='date' name='date' label='Date' /> */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          sx={{
                            minWidth: '100%',
                          }}
                          // key={datekey}
                          label='Date'
                          value={dayjs(startDatecall)}
                          onChange={handleEndDateChange2}
                          inputVariant='outlined'
                          dateFormat='MM/dd/yyyy'
                          clearable
                           // disableFuture
                          // maxDate={endDatecall}
                          timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                          slots={{
                            actionBar: ActionList,
                          }}
                          ampm={false}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item lg={2} xs={12} md={12} sm={12} sx={{ margin: 'auto 0' }}>
                      <Button variant='contained' type='submit' disabled={!userPermissions?.edit}>
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
              <Grid item lg={12} xs={12} md={12} sm={12} mt={3}>
                <FormControl
                  error
                  required
                  component='fieldset'
                  variant='standard'
                  style={{
                    width: '100%',
                    border: '1px solid rgb(221, 221, 221)',
                    padding: '10px 15px 10px',
                    minHeight: '250px',
                  }}
                >
                  <Typography variant='h5'>Enter Note</Typography>
                  <div>
                    <ThemeProvider theme={myTheme}>
                      <MUIRichTextEditor
                        sx={{
                          border: '1px solid #444',
                          width: '100%',
                          height: '100px',
                        }}
                        label='Type something here...'
                        inlineToolbar={true}
                        onChange={handleNotes}
                      />
                    </ThemeProvider>
                  </div>
                </FormControl>
              </Grid>
              <Box sx={{ paddingTop: '10px' }}>
                <DataGridPremium
                  getRowId={row => row.id}
                  rows={rows}
                  columns={columns}
                  columnHeaderHeight={50}
                  rowHeight={30}
                  pageSize={50}
                  scrollbarSize={5}
                  pagination
                  slots={{
                    pagination: CustomPagination,
                  }}
                  sx={{
                    marginTop: '10px',
                    width: '100%',
                    height: 450,
                    borderRadius: '6px',
                    backgroundColor: 'white',
                    // boxShadow: '0px 0px 10px #ddd',
                    marginBottom: '30px',
                  }}
                />
              </Box>
            </>
          )}
        </Formik>
      </div>
      {modal ? (
        <ApiNotification
          openApiDialogBox={modal}
          closeDialogBox={() => setModal(false)}
          dialogContents={message}
        />
      ) : null}
      {addModal ? (
        <ApiNotification
          openApiDialogBox={addModal}
          closeDialogBox={() => setAddModal(false)}
          dialogContents={apiResponse}
        />
      ) : null}
    </>
  );
};

export default Notes;
