import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-premium';
import React from 'react';
import { useNavigate } from 'react-router';
import SiloDataGrid from '../../utils/components/SiloDataGrid';
import { DecedentNameCell } from './DecedentNameCell';
import { DashboardDataGridProps } from './useDashboardDataGridAPI.hook';
import { format } from 'date-fns';

export function UnidentifiedCasesDataGrid({ data, isLoading }: DashboardDataGridProps) {
  const columns: GridColDef[] = [
    {
      field: 'caseid',
      headerName: 'CaseID',
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'decedentname',
      headerName: 'Decedent',
      width: 140,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: params => <DecedentNameCell {...params} />,
    },
    {
      field: 'menotified',
      headerName: 'Reported',
      width: 120,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      type: 'dateTime',
      valueGetter: value => {
        return value != null ? new Date(value) : null;
      },
      valueFormatter: value => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
      },
    },
    {
      field: 'examphysician',
      headerName: 'Pathologist',
      width: 120,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'investigator',
      headerName: 'MLDI',
      width: 120,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  return (
    <SiloDataGrid
      loading={isLoading}
      listresults={data}
      columnsInput={columns}
      idcolumn='caseid'
      gridheight={200}
      gridcolor='#e74c3c'
      title='Unidentified Cases'
      tooltip='These are Unidentified Cases'
      casecount
      rowsperpage={50}
      filterModel={undefined}
      columnsVisibility={undefined}
    />
  );
}
