import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  Paper,
  Slide,
  Stack,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function InvestigatorCaseActivityReportView() {
  const { user } = useAuth();
  const [redirect, setredirect] = useState(null);
  const [loading, setloading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [year, setYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [currentUser, setcurrentUser] = useState(false);
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [selectedJdxs, setSelectedJdxs] = useState([]);
  const [requiredAlertVisible, setRequiredAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const theme = useTheme();

  const columns = [
    {
      field: 'ID',
      headerName: 'ID',
      width: 120,
      hide: true,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'investigator',
      headerName: 'Investigator',
      width: 170,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'acceptedTotal',
      headerName: 'Accepted',
      width: 110,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'releaseTotal',
      headerName: 'Release',
      width: 100,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'facilitatedReleaseTotal',
      headerName: 'Facilitated Release',
      width: 130,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'transferTotal',
      headerName: 'Transfer',
      width: 100,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'storageOnlyTotal',
      headerName: 'Storage',
      width: 100,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'allActionsTotal',
      headerName: 'Total',
      width: 100,
      editable: false,
      headerAlign: 'left',
    },
  ];

  const { REACT_APP_API_URL } = process.env;

  const handleJdxChange = (event, data) => {
    setSelectedJdxs(data);
  };

  const handleStartDateChange = event => {
    if (event.target.value != '') {
      setStartDate(event.target.value);
    } else {
      setStartDate('');
    }
  };

  const handleEndDateChange = event => {
    if (event.target.value != '') {
      setEndDate(event.target.value);
    } else {
      setEndDate('');
    }
  };

  const handleYearChange = (event, data) => {
    setYear(data);
  };

  const dismissDateAlert = () => {
    setRequiredAlertVisible(false);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  const clearAll = () => {
    setStartDate('');
    setEndDate('');
    setYear(null);
    setSelectedJdxs([]);
    setResultsVisible(false);
  };

  //gather search params from form and FETCH/POST to API
  const handleSubmit = async event => {
    event.preventDefault();
    if (user === null) return;

    if (year == null) {
      let startDateArr = startDate.split('-');
      let endDateArr = endDate.split('-');

      let startDateValue = new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]);
      let endDateValue = new Date(endDateArr[0], endDateArr[1] - 1, endDateArr[2]);

      if (endDateValue - startDateValue > 31536000000) {
        setRequiredAlertVisible(true);
        return;
      }
    }

    if (selectedJdxs.length == 0) {
      setJdxAlertVisible(true);
      return;
    }

    let formData = new FormData();

    setloading(true);
    setResultsVisible(true);

    formData.append('SEARCHFROMDATE', startDate);
    formData.append('SEARCHTODATE', endDate);
    formData.append('CASEYEAR', year);
    formData.append('SELECTEDJDXLIST', JSON.stringify(selectedJdxs));

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'getinvestigatorcaseactivityreport', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setlistresults(data);
          setloading(false);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    await setloading(false);
  };

  useEffect(() => {
    if (user === null) return;
    const fetchYear = async () => {
      try {
        const currentYear = new Date().getFullYear();
        setYear(currentYear);
        setYearOptions([...Array(16).keys()].map(i => i + (currentYear - 15)).reverse());
      } catch (e) {
        alert(e);
      }
    };

    fetchYear();
    setAuthorizedJdxs(user.jdxAccessList);
    setSelectedJdxs(user.jdxAccessList);
  }, [user]);

  return (
    // <div className='me-response-time' style={{ padding: 20 }}>
    //   {/* {breadcrumbReportNav("Investigator Case Activity")} */}
    //   <BreadcrumbReportNav currentPage='Investigator Case Activity' />
    //   <Card style={{ marginBottom: '1rem' }} className='card-main'>
    //     <CardHeader className='header-txt'>
    //       Investigator Case Activity
    //       <Tooltip
    //         style={{ float: 'right' }}
    //         title='This Report Tracks the investigator case activity '
    //         placement='right-end'
    //       >
    //         <InfoIcon />
    //       </Tooltip>
    //     </CardHeader>

    //     <Alert color='info' isOpen={requiredAlertVisible} toggle={dismissDateAlert}>
    //       Date range must be within 1 year or year dropdown must be set.
    //     </Alert>

    //     <Alert color='info' isOpen={jdxAlertVisible} toggle={dismissJdxAlert}>
    //       Please select at Least 1 Jdx.
    //     </Alert>

    //     <div>
    //       <CardBody>
    //         <Row>
    //           <Col className='md=12'>
    //             <Autocomplete
    //               multiple
    //               id='selectedJdxs'
    //               options={authorizedJdxs}
    //               value={selectedJdxs}
    //               disableCloseOnSelect
    //               limitTags={4}
    //               size='small'
    //               getOptionLabel={option => option.jdxName}
    //               onChange={(event, data) => handleJdxChange(event, data)}
    //               renderOption={(props, option, { selected }) => (
    //                 <li {...props}>
    //                   <Checkbox
    //                     icon={<MdCheckBoxOutlineBlank />}
    //                     checkedIcon={<MdOutlineCheckBox />}
    //                     style={{ marginRight: 8 }}
    //                     checked={selected}
    //                   />
    //                   {option.jdxName}
    //                 </li>
    //               )}
    //               style={{ width: '100%', marginTop: 15 }}
    //               renderInput={params => (
    //                 <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
    //               )}
    //               ListboxComponent={'ul'}
    //             />
    //           </Col>
    //         </Row>

    //         <Row>
    //           <Col className='me-col' md='4'>
    //             <Autocomplete
    //               id='year'
    //               options={yearOptions}
    //               value={year}
    //               size='small'
    //               getOptionLabel={option => option.toString()}
    //               onChange={(event, data) => handleYearChange(event, data)}
    //               renderOption={(props, option, { selected }) => (
    //                 <li {...props}>
    //                   <Checkbox
    //                     icon={<MdCheckBoxOutlineBlank />}
    //                     checkedIcon={<MdOutlineCheckBox />}
    //                     style={{ marginRight: 8 }}
    //                     checked={selected}
    //                   />
    //                   {option.toString()}
    //                 </li>
    //               )}
    //               style={{ width: '100%' }}
    //               renderInput={params => <TextField {...params} label='Year' placeholder='Year' />}
    //               ListboxComponent={'ul'}
    //             />
    //           </Col>

    //           <Col className='me-col' md='4'>
    //             <TextField
    //               id='searchfromdate'
    //               name='searchfromdate'
    //               label='Call Taken From'
    //               type='date'
    //               size='small'
    //               value={startDate}
    //               onChange={handleStartDateChange}
    //               disabled={year}
    //               style={{ width: '100%' }}
    //               InputLabelProps={{
    //                 shrink: true,
    //               }}
    //             />
    //           </Col>

    //           <Col className='me-col' md='4'>
    //             <TextField
    //               id='searchtodate'
    //               name='searchtodate'
    //               label='Call Taken To'
    //               type='date'
    //               size='small'
    //               value={endDate}
    //               onChange={handleEndDateChange}
    //               disabled={year}
    //               style={{ width: '100%' }}
    //               InputLabelProps={{
    //                 shrink: true,
    //               }}
    //             />
    //           </Col>
    //         </Row>
    //       </CardBody>
    //       <br />

    //       <Button
    //         type='submit'
    //         color='primary'
    //         style={{ width: 120, marginLeft: 18, float: 'right' }}
    //         onClick={handleSubmit}
    //       >
    //         Calculate
    //       </Button>

    //       <Button
    //         color='secondary'
    //         style={{ width: 120, marginLeft: 18, float: 'right' }}
    //         onClick={clearAll}
    //       >
    //         Clear
    //       </Button>
    //       <hr />
    //       <br />

    //       <Collapse isOpen={resultsVisible}>
    //         <ReportDataGrid
    //           loading={loading}
    //           listresults={listresults}
    //           idcolumn='id'
    //           columnsInput={columns}
    //           title='Investigator Case Activity'
    //           chatcontent={
    //             <Paper
    //               elevation={3}
    //               sx={{
    //                 p: '10px',
    //                 px: '20px',
    //                 borderRadius: '20px',
    //                 width: 'max-content',
    //               }}
    //             >
    //               <div style={{ textAlign: 'center' }}>
    //                 I've organized the investigator activity by case type below, you can export the
    //                 list to csv format.
    //               </div>
    //             </Paper>
    //           }
    //         />
    //       </Collapse>
    //     </div>
    //   </Card>
    // </div>

    <Card
      raised
      sx={{
        my: 3,
        mx: 'auto',
        width: { xs: '95%', lg: '75%' },
      }}
    >
      <CardHeader
        title='Investigator Case Activity'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
        action={
          <Tooltip
            sx={{ color: theme.palette.text.primary }}
            title='This report tracks the investigator case activity'
            placement='right-end'
          >
            <InfoIcon />
          </Tooltip>
        }
      />

      <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

      <CardContent>
        <Slide
          direction='right'
          in={requiredAlertVisible}
          mountOnEnter
          unmountOnExit
          sx={{ mb: 2 }}
        >
          <Alert severity='warning' onClose={dismissDateAlert}>
            Call taken range must be within 1 year or year dropdown must be set.
          </Alert>
        </Slide>

        <Slide direction='right' in={jdxAlertVisible} mountOnEnter unmountOnExit sx={{ mb: 2 }}>
          <Alert severity='warning' onClose={dismissJdxAlert}>
            Please select at least 1 jurisdiction.
          </Alert>
        </Slide>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id='selectedJdxs'
              options={authorizedJdxs}
              value={selectedJdxs}
              disableCloseOnSelect
              limitTags={4}
              fullWidth
              size='small'
              getOptionLabel={option => option.jdxName}
              onChange={(event, data) => handleJdxChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.jdxName}
                </li>
              )}
              renderInput={params => (
                <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
              )}
              ListboxComponent={'ul'}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Autocomplete
              id='year'
              options={yearOptions}
              value={year}
              size='small'
              getOptionLabel={option => option.toString()}
              onChange={(event, data) => handleYearChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.toString()}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => <TextField {...params} label='Year' placeholder='Year' />}
              ListboxComponent={'ul'}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id='searchfromdate'
              name='searchfromdate'
              label='Call Taken From'
              type='date'
              size='small'
              value={startDate}
              onChange={handleStartDateChange}
              disabled={year}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id='searchtodate'
              name='searchtodate'
              label='Call Taken To'
              type='date'
              size='small'
              value={endDate}
              onChange={handleEndDateChange}
              disabled={year}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 2, opacity: 1 }} />

        <Stack
          direction='row'
          sx={{
            width: '100%',
            justifyContent: 'right',
          }}
          spacing={2}
        >
          <Button type='submit' variant='contained' color='primary' onClick={handleSubmit}>
            Search
          </Button>

          <Button color='secondary' variant='outlined' onClick={clearAll}>
            Clear
          </Button>
        </Stack>

        <Collapse in={resultsVisible} sx={{ mt: resultsVisible ? 4 : 0 }}>
          <ReportDataGrid
            loading={loading}
            listresults={listresults}
            idcolumn='id'
            columnsInput={columns}
            title='Investigator Case Activity'
            casecount
            chatcontent={
              <Paper
                elevation={3}
                sx={{
                  p: '10px',
                  px: '20px',
                  borderRadius: '20px',
                  width: 'max-content',
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  I've organized the investigator activity by case type below, you can export the
                  list to csv format.
                </div>
              </Paper>
            }
          />
        </Collapse>
      </CardContent>
    </Card>
  );
}
