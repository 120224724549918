import { Grid, Box, Button, Divider } from '@mui/material';
import {
  DataGridPremium,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import React from 'react';
import { useEffect, useState } from 'react';
import MainCard from '../../../../components/AdministrationModules/MainCard';
import Filter from './Filter';
import AddEdit from './AddEdit';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';
import ApiNotification from '../../../../components/DialogBox';
import DeleteApiNotification from '../../../../components/AdministrationModules/DeleteApiNotification';
import {
  deleteData,
  activeData,
  getAdminData,
  getAllCounty,
} from '../../../../services/utility-api';

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = gridPageSelector(apiRef);
  const pageCount = gridPageCountSelector(apiRef);

  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => (
        <PaginationItem
          {...props2}
          sx={{ fontFamily: 'DataGrid, sans-serif' }}
          disableRipple
        />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{ mr: 3 }}
    />
  );
}

const LiveryServices = () => {
  const referenceMasterTableName = 'liveryServices';
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const liveryServicesPermissions = permissions?.['Livery Services'];
  const [isAddEditOn, setIsAddEditOn] = useState();
  const [editObj, setEditObj] = useState({});
  const [maxOrder, setMaxOrder] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsToShow, setRowsToShow] = useState([]);
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const [openActiveInActiveDialogBox, setOpenActiveInActiveDialogBox] =
    useState(false);
  const [activeInactiveClickData, setActiveInactiveClickData] = useState({});
  const [statusFilter, setStatusFilter] = useState('Active');
  const [nameFilter, setNameFilter] = useState('');
  const [countyFilter, setCountyFilter] = useState('');

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = gridPageSelector(apiRef);
    const pageCount = gridPageCountSelector(apiRef);

    return (
      <Pagination
        color='primary'
        variant='outlined'
        shape='rounded'
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => (
          <PaginationItem
            {...props2}
            sx={{ fontFamily: 'DataGrid, sans-serif' }}
            disableRipple
          />
        )}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
        sx={{ mr: 3 }}
      />
    );
  }

  const [columns, setColumns] = useState([
    {
      field: 'liveryName',
      headerName: 'Livery Name',
      // headerClassName: 'super-app-theme--header',
      minWidth: 400,
      align: 'left',
      flex: 20,
      renderCell: (cellValues) => {
        return <div>{cellValues?.row?.livery[0]?.liveryName || ''}</div>;
      },
    },
  ]);
  const [county, setCounty] = useState([]);

  const changeActiveInActiveClick = async () => {
    try {
      if (activeInactiveClickData.status === 'Active') {
        const responseMessage = await deleteData({
          seq: activeInactiveClickData.seq,
          tableName: referenceMasterTableName,
        });
        setDialogContents(responseMessage);
      } else {
        const responseMessage = await activeData({
          seq: activeInactiveClickData.seq,
          tableName: referenceMasterTableName,
        });
        setDialogContents(responseMessage);
      }
      setOpenActiveInActiveDialogBox(false);
      setOpenApiDialogBox(true);
      await getAllData();
    } catch (error) {
      console.log('error', error);
    }
  };

  const getAllCountyData = async () => {
    const countyResponse = await getAllCounty();
    setCounty(countyResponse);
  };

  useEffect(() => {
    if (Object.keys(county).length) {
      let tempColumns = [
        {
          field: 'liveryName',
          headerName: 'Livery Name',
          // headerClassName: 'super-app-theme--header',
          minWidth: 400,
          align: 'left',
          flex: 20,
          renderCell: (cellValues) => {
            return <div>{cellValues?.row?.liveryName || ''}</div>;
          },
        },
      ];

      tempColumns.push({
        field: 'status',
        headerName: 'Status',
        width: 100,
        // headerClassName: 'super-app-theme--header',
        renderCell: (cellValues) => {
          const handleClick = async (row) => {
            setOpenActiveInActiveDialogBox(true);
            const newIconData =
              row['livery'][0]['status'] === 'Active' ? 'locked' : 'done';
            setActiveInactiveClickData({
              icon: newIconData,
              seq: row['livery'][0]['liverySeq'],
              status: row['livery'][0]['status'],
            });
          };

          return Number(cellValues.row.order) >= 90000 ? (
            <></>
          ) : (
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleClick(cellValues.row);
              }}
            >
              {cellValues.row['livery'][0]['status'] === 'Active' ? (
                <LockIcon />
              ) : (
                <DoneIcon />
              )}
            </Button>
          );
        },
      });
      setColumns(tempColumns);
    }
  }, [county]);

  const [isLoading, setIsLoading] = useState(true);
  const getAllData = async () => {
    setIsLoading(true);
    const response = await getAdminData('liveryservices');
    const data = response.data.data.map((row) => {
      const newObj = {};
      Object.keys(row).map((key) => {
        newObj[key] = row[key];
      });
      newObj.liveryOnCountySeqs = newObj.liveryOnCounty.map((county) => {
        return county.countySeq;
      });
      newObj.liveryName = newObj['livery'][0]['liveryName'];
      newObj.id = newObj['livery'][0]['liverySeq'];
      return newObj;
    });
    let maxOrder = 0;
    for (let i = 0; i < data.length; i++) {
      if (Number(data[i].order) > maxOrder && Number(data[i].order) < 90000)
        maxOrder = Number(data[i].order);
    }
    setMaxOrder(maxOrder);
    setRows(data);
    let newRows = data;
    if (statusFilter.toLowerCase() != 'all') {
      newRows = newRows.filter(
        (row) =>
          row['livery'][0]['status'].toLowerCase() == statusFilter.toLowerCase()
      );
    }
    if (nameFilter) {
      newRows = newRows.filter((row) =>
        row['livery'][0]['liveryName']
          .toLowerCase()
          .includes(nameFilter.toLowerCase())
      );
    }
    if (countyFilter) {
      newRows = newRows.filter((row) =>
        row.liveryOnCountySeqs.includes(countyFilter)
      );
    }
    setRowsToShow(newRows);
    setIsLoading(false);
  };
  const editRow = (params) => {
    if (liveryServicesPermissions?.edit) {
      if (params?.row?.livery[0]?.status.toLowerCase() === 'active') {
        setIsAddEditOn(false);
        setTimeout(() => {
          setEditObj(params.row);
          setIsAddEditOn(true);
        }, 200);
      }
    }
  };
  useEffect(() => {
    getAllCountyData();
    getAllData();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            {/* <MainCard> */}
              <Box style={{ tr: 'hover', cursor: 'pointer' }}>
                <Filter
                  rows={rows}
                  setRowsToShow={setRowsToShow}
                  county={county}
                  setStatusFilter={setStatusFilter}
                  setNameFilter={setNameFilter}
                  setCountyFilter={setCountyFilter}
                />

                <DataGridPremium
                  columnHeaderHeight={50}
                  scrollbarSize={5}
                  pagination
                  slots={{
                    pagination: CustomPagination,
                  }}
                  // sx={{
                  //   width: '100%',
                  //   height: 450,
                  //   borderRadius: '6px',
                  //   backgroundColor: 'white',
                  //   // boxShadow: '0px 0px 10px #ddd',
                  // }}
                  initialState={{
                    sorting: {
                      sortModel: [
                        {
                          field: 'liveryName',
                          sort: 'asc',
                        },
                      ],
                    },
                  }}
                  rowHeight={35}
                  rows={rowsToShow}
                  columns={columns} //[...columns, { field: 'status', sortable: false }]
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  disableSelectionOnClick={false}
                  onRowClick={editRow}
                  loading={isLoading}
                />
              </Box>
              <Box mb={2}>
                <Button
                  variant='contained'
                  sx={{ marginTop: '7px' }}
                  onClick={() => {
                    setIsAddEditOn(false);
                    setTimeout(() => {
                      setEditObj({});
                      setIsAddEditOn(true);
                    }, 200);
                  }}
                  disabled={!liveryServicesPermissions?.add}
                >
                  Add Livery Service
                </Button>
              </Box>
              <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
              {isAddEditOn && editObj && (
                <AddEdit
                  editObj={editObj}
                  getAllData={getAllData}
                  allRow={rows}
                  county={county}
                  setIsAddEditOn={setIsAddEditOn}
                  liveryServicesPermissions={liveryServicesPermissions}
                />
              )}
            {/* </MainCard> */}
            <DeleteApiNotification
              openActiveInActiveDialogBox={openActiveInActiveDialogBox}
              setOpenActiveInActiveDialogBox={setOpenActiveInActiveDialogBox}
              referenceMasterTableName={referenceMasterTableName}
              changeActiveInActiveClick={changeActiveInActiveClick}
              activeInactiveClickData={activeInactiveClickData}
            />

            <ApiNotification
              openApiDialogBox={openApiDialogBox}
              setOpenApiDialogBox={setOpenApiDialogBox}
              dialogContents={dialogContents}
              closeDialogBox={() => { setOpenApiDialogBox(false) }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default LiveryServices;
