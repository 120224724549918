import { HubConnectionBuilder } from '@microsoft/signalr';
const REACT_APP_API_URL = process.env;

const startConnection = () => {
  const connection = new HubConnectionBuilder()
    .withUrl('http://localhost:5058/yourHub') // Update the URL to match your server
    .withAutomaticReconnect()
    .build();

  connection
    .start()
    .then(() => {
      console.log('SignalR Connected');
    })
    .catch(err => console.error(err));

  return connection;
};

const registerReceiveMessageHandler = (connection, onReceiveMessage) => {
  connection.on('ReceiveMessage', (user, message) => {
    onReceiveMessage({ user, message });
  });
};

export { startConnection, registerReceiveMessageHandler };
