import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField,useMediaQuery, Typography, Grid, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ReqDocNotesDialog = ({ open, onClose, onSave, selectedRow }) => {
  const [notes, setNotes] = useState(selectedRow?.notes || '');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLaptop = useMediaQuery(theme.breakpoints.up('md'));

  const handleSave = () => {
    onSave(notes);
    onClose();
  };

  return (
    <Box component={'div'}>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            minWidth: isMobile ? '95%' : '60%',
            margin: '0 auto',
          },
        }}
      >
        <Typography
          variant='subtitle1'
          color='gray'
          textTransform='uppercase'
          style={{ margin: '1rem' }}
        >
          <strong>Add/Edit Notes</strong>
        </Typography>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>
                <strong>Item Requested: {selectedRow?.itemRequested?.optionName || ''}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='Notes'
                name='notes'
                type='text'
                variant='outlined'
                disabled={!selectedRow?.isEditing}
                multiline
                value={notes}
                onChange={e => setNotes(e.target.value)}
                fullWidth
                InputProps={{
                  inputProps: {
                    minRows: 3,
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ margin: '1rem' }}>
          {selectedRow?.isEditing && (
            <Button onClick={handleSave} variant='contained'>
              Save
            </Button>
          )}
          <Button onClick={onClose} color='error'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ReqDocNotesDialog;