import { Box, Drawer, Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import dimensions from '../../utils/dimensions';
import { useAuth } from '../auth/AuthService';

export default function CustomDrawer({
  drawerContents,
  currentTab,
  setCurrentTab,
  menuOpen,
  setMenuOpen,
  setTabContent = () => {},
}) {
  const theme = useTheme();
  const { user } = useAuth();

  const handleTabChange = (event, newTab) => {
    if (event.target.textContent === 'User Admin') {
      setTabContent('Users');
    } else if (event.target.textContent === 'Roles Admin') {
      setTabContent('Roles');
    } else {
      setTabContent(event.target.textContent);
    }
    setCurrentTab(newTab);
  };

  return (
    <Box
      sx={{
        height: '100%',
        // Use theme background color
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          width: 'max-content',
          height: '100%',
          display: { xs: 'none', md: 'block' },
        }}
      >
        <Tabs
          orientation='vertical'
          value={currentTab}
          onChange={handleTabChange}
          textColor='primary'
          aria-label='Vertical tabs'
          variant='scrollable'
          sx={{
            borderRight: 1,
            borderColor: 'divider',
            height: '100%',
            '& .MuiTabs-indicator': {
              backgroundColor:
                theme.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
            },
          }}
        >
          {drawerContents.map((content, index) => {
            if (content.authorizedRoles != null) {
              return (
                user?.roleCheck(content.authorizedRoles) && (
                  <Tab
                    key={index + '-' + content.text}
                    icon={content.icon}
                    iconPosition='start'
                    disabled={content.disabled}
                    label={content.text}
                    sx={{
                      width: '100%',
                      justifyContent: 'left',
                      fontSize: '0.775rem', // Adjust font size
                      color: theme.palette.text.primary, // Use theme text color
                      '&.Mui-selected': {
                        color: theme.palette.primary.main,
                      },
                    }}
                  />
                )
              );
            } else {
              return (
                <Tab
                  key={index + '-' + content.text}
                  icon={content.icon}
                  iconPosition='start'
                  disabled={content.disabled}
                  label={content.text}
                  sx={{
                    width: '100%',
                    justifyContent: 'left',
                    fontSize: '0.775rem', // Adjust font size
                    color: theme.palette.text.primary, // Use theme text color
                    '&.Mui-selected': {
                      color: theme.palette.primary.main,
                    },
                  }}
                />
              );
            }
          })}
        </Tabs>
      </Box>

      <Drawer
        variant='temporary'
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 'max-content',
            position: 'relative',
            top: dimensions.app_bar_height,
            height: `calc(100% - ${dimensions.app_bar_height})`,
            borderRadius: 1.5,
            backgroundColor: theme.palette.background.default, // Use theme background color
          },
          zIndex: theme.zIndex.drawer + 500,
        }}
      >
        <Tabs
          orientation='vertical'
          variant='scrollable'
          value={currentTab}
          onChange={handleTabChange}
          aria-label='Vertical tabs example'
          sx={{
            borderRight: 1,
            borderColor: 'divider',
            '& .MuiTabs-indicator': {
              backgroundColor:
                theme.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
            },
          }}
        >
          {drawerContents.map((content, index) => (
            <Tab
              key={index + '-' + content.text}
              icon={content.icon}
              iconPosition='start'
              disabled={content.disabled}
              label={content.text}
              sx={{
                width: '100%',
                justifyContent: 'left',
                fontSize: '0.775rem', // Adjust font size
                color: theme.palette.text.primary, // Use theme text color
                '&.Mui-selected': {
                  color: theme.palette.primary.main,
                },
              }}
              onClick={() => setMenuOpen(false)}
            />
          ))}
        </Tabs>
      </Drawer>
    </Box>
  );
}
