import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Stack,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import axios from 'axios';
import UploadRes from './UploadRes';
import MainCard from '../../../../components/AdministrationModules/MainCard';
import authHeader from '../../../../services/auth-header';
import { saveAdminData } from '../../../../services/utility-api';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import {
  DataGridPremium,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { useAuth } from '../../../../utils/auth/AuthService.tsx';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = gridPageSelector(apiRef);
  const pageCount = gridPageCountSelector(apiRef);
  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => (
        <PaginationItem
          {...props2}
          sx={{ fontFamily: 'DataGrid, sans-serif' }}
          disableRipple
        />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{ mr: 3 }}
    />
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const UploadResources = () => {
  const id = '00000000-0000-0000-0000-000000000000';
  const [setTouchedDep, setSetTouchedDep] = useState();
  const [department, setDepartment] = useState([]);
  const [dataGrids, setDataGrids] = useState([]);

  const [openapi, setOpenapi] = useState(false);
  const [deptdata, setDeptdata] = useState([]);
  const [departmentValue, setDepartmentValue] = useState();
  const [dialogContents, setDialogContents] = useState();

  const [open, setOpen] = useState(false);
  const [icondata, setIcondtata] = useState('');
  const { user } = useAuth();
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const loggedInUserSeq = user?.userSeq;

  const [hyperlinkName, setHyperlinkName] = useState('');

  const [hyperlinkUrl, setHyperlinkUrl] = useState('');

  const [row, setRow] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const filterDepartment =
      deptdata?.filter(
        (department) => department.photoTypeName === setTouchedDep
      ) ?? [];
    const filterData =
      dataGrids?.filter(
        (resource) =>
          resource?.photo?.assignmentTypeSeq ===
          filterDepartment?.[0]?.photoTypeSeq
      ) ?? {};
    setDataGrids(filterData);
  }, [setTouchedDep]);

  const Close = () => setOpen(false);

  const handleSavedata = async () => {
    const apiData = await saveAdminData({
      values: {
        appAction: {
          appActionSeq: id,
          name: hyperlinkName,
          description: 'All',
          location: hyperlinkUrl,
          imageLocation: '~/Images/Admin/Admin.gif',
          isIncidentRequired: false,
          showForDisasterIncident: false,
          showForRegularIncident: true,
          isActive: true,
          isMenu: true,
          isCaseSpecificInfo: false,
          isBreadcrumbItem: true,
          appActionOrder: 3062,
          actionGroup: 0,
          parentActionGroup: 0,
        },
        appActionOnAppAction: {
          parentAppActionSeq: id,
          childAppActionSeq: id,
          status: 'Active',
        },
        resourceFilesOnAppAction: {
          resourceFilesOnAppActionSeq: id,
          resourceFilesSeq: id,
          appActionSeq: id,
          status: 'Active',
        },
        photo: {
          PhotoSeq: id,
          PhotoInfoSeq: id,
          PhotoTypeSeq: id,
          PhotoTypeIndex: '',
          OtherPhotoType: '',
          PhotoLabel: '',
          PhotoNotes: '',
          PhotoMimeType: '',
          UserSeq: '06A60228-062F-46C9-9E0A-56899D1185B3',
          DateTime: '2023-05-10 14:39:29.750',
          EntityName: hyperlinkName,
          PhotoGUID: id,
          Orientation: '',
          CaseSeq: '',
          PageCount: '0',
          IsDeepZoomCreated: false,
          OriginalFileName: hyperlinkUrl,
          OriginalFileSize: 0,
          Rotation: 0,
          IsUploadedByMLI: false,
          IsResourceFile: true,
          IsHyperlink: true,
          AssignmentTypeSeq: departmentValue?.value ?? id,
          IsUserCertificate: false,
          UserCertificateTypeSeq: id,
          UserInfoSeq: id,
          status: 'Active',
        },
      },
      tableName: 'UploadHyperlink',
    });

    getHyperlink();
    setDepartmentValue('');
    setHyperlinkName('');
    setHyperlinkUrl('');

    if (apiData?.status == 0) {
      setOpenapi(true);
      setDialogContents(apiData?.message);
    }
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columns = [
    {
      field: 'name',
      headerClassName: 'super-app-theme--header',
      headerName: 'Web Link Title',
      type: 'text',
      minWidth: 100,
      align: 'left',
      flex: 5,
    },
    {
      field: 'location',
      headerClassName: 'super-app-theme--header',
      headerName: 'URL',
      type: 'link',
      minWidth: 200,
      align: 'left',
      flex: 5,
      renderCell: ({ row }) => {
        if (!row?.location?.includes('http')) {
          return (
            <a href={`http://${row?.location}` ?? ''} target='_blank'>
              {row?.location}
            </a>
          );
        } else {
          return (
            <a href={row?.location ?? ''} target='_blank'>
              {row?.location}
            </a>
          );
        }
      },
    },

    {
      field: 'Status',
      headerClassName: 'super-app-theme--header',
      renderCell: (cellValues) => {
        const handleClick = (event) => {
          event.stopPropagation();
          setOpen(true);
          setRow(cellValues.row);
          const newIconData =
            cellValues.row.status === 'Active' ? 'locked' : 'done';
          const selectedRows = [cellValues.row];
          setIcondtata({
            icon: newIconData,
            appActionSeq: selectedRows[0].id,
            status: cellValues.row.status,
          });
        };

        return (
          <Button
            onClick={handleClick}
            disabled={!permissions?.['Upload Resources']?.edit}
          >
            {cellValues.row.status === 'Active' ? (
              <Tooltip title='Active'>
                <LockIcon />
              </Tooltip>
            ) : (
              <Tooltip title='InActive'>
                <DoneIcon />
              </Tooltip>
            )}
          </Button>
        );
      },
    },
  ];

  const rows =
    dataGrids?.length >= 1 &&
    dataGrids?.map((row) => ({
      id: `${row.appAction.appActionSeq ?? Math.random()}`,
      name: `${row.appAction.name}`,
      location: `${row.appAction.location}`,
      status: `${row?.appActionOnAppAction?.status}`,
    }));

  const getDepartment = async (para) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}GetAllDepartment?LogedInUserSeq=${loggedInUserSeq}&type=Hyperlink`,
      {
        headers: authHeader(),
      }
    );
    setDeptdata(response.data.data);
    setDepartment(
      response.data.data?.map(({ photoTypeName, photoTypeSeq }) => {
        return { photoTypeName, photoTypeSeq };
      })
    );
    setIsLoading(false);
    return;
  };

  const getHyperlink = async () => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}GetMasterData?tableName=Uploadresources`,
      {
        headers: authHeader(),
      }
    );
    const filterResponse =
      response?.data?.data?.filter(
        (resource) => resource?.photo?.isHyperlink
      ) ?? [];
    setDataGrids(filterResponse);
    setIsLoading(false);
    return;
  };

  useEffect(() => {
    getDepartment();
    getHyperlink();
  }, []);

  const handleOkClick = async () => {
    setIsLoading(true);
    if (icondata.status === 'Inactive') {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}ActiveData?seq=${row?.id}&tableName=Uploadresources`,
          '',
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          getHyperlink();
          setOpenapi(true);
          setDialogContents(response.data.message);
        })
        .catch((error) => {
          console.log('error', error);
        });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}InactivateMasterData?seq=${row?.id}&tableName=Uploadresources`,
          '',
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          getHyperlink();
          setOpenapi(true);
          setDialogContents(response.data.message);
        })
        .catch((error) => {
          console.log('error', error);
        });
    }

    setOpen(false);
    setIsLoading(false);
  };

  const checkExisting = () => {
    if (dataGrids) {
      return dataGrids.some(
        (link) =>
          link?.appAction?.name.toLowerCase() ===
            hyperlinkName?.toLowerCase() ||
          link?.appAction.location.toLowerCase() === hyperlinkUrl.toLowerCase()
      );
    }
  };

  const saveUnique = () => {
    if (!isValidUrl) {
      setErrorMessage('Please Enter valid URL');
    } else if (
      !checkExisting() &&
      hyperlinkUrl.length !== 0 &&
      hyperlinkName.length !== 0
    ) {
      handleSavedata();
    } else if (
      checkExisting() &&
      hyperlinkUrl.length !== 0 &&
      hyperlinkName.length !== 0
    ) {
      setOpenapi(true);
      setDialogContents('Either Web Link Title or Web Link URL is a Duplicate');
    } else if (hyperlinkUrl.length == 0 || hyperlinkName.length == 0) {
      setOpenapi(true);
      setDialogContents('Hyperlink name or url can not be empty');
    }
  };

  const handleHyperlinkNameChange = (event) => {
    setHyperlinkName(event.target.value);
  };

  const [isValidUrl, setIsValidUrl] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const handleHyperlinkUrlChange = (event) => {
    const inputUrl = event.target.value;
    setHyperlinkUrl(inputUrl);

    const urlPattern =
      /^(?:(?:(?:https?|ftp):\/\/)|(?:www\.))[^\s/$.?#].[^\s]*$/i;
    const isValid = urlPattern.test(inputUrl);

    setIsValidUrl(isValid);

    if (!isValid) {
      setErrorMessage('Please Enter valid URL');
    } else {
      setErrorMessage('');
    }
  };

  // if (isLoading) {
  //     return <Loader />;
  // } else if (!permissions?.['Upload Resources']?.view) {
  //     return <AccessDenied />;
  // } else {
  return (
    <div>
      
    <Stack>
    <Typography variant='h5' component='h1'>
      <b>Upload Resources</b> 
    </Typography>
    <Typography variant='subtitle1' color='gray'>
    Edit General Files/PDFs & Links to be made available to users
            </Typography>
  </Stack>
  <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
<br />
    
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab label=' Files' {...a11yProps(0)} />
           
            <Tab label=' Web Links' {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <UploadRes />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box
            sx={{
              flexGrow: 1,
              fontSize: '12px',
              fontFamily: 'Roboto , Helvetica , Arial , sans-serif !important',
            }}
          >
            <Grid container spacing={2}>
              <Grid item lg={4} xs={12} md={12} sm={12}>
                <FormControl fullWidth sx={{ minWidth: '100%' }}>
                  <InputLabel id='demo-simple-select-label'>
                    Department
                  </InputLabel>
                  <Select
                    value={departmentValue}
                    onChange={(e) => setDepartmentValue(e.target.value)}
                    label='Department'
                  >
                    {department?.map((dept) => {
                      return (
                        <MenuItem
                          value={dept.photoTypeSeq}
                          name={dept.photoTypeName}
                        >
                          {dept.photoTypeName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item lg={3} xs={12} md={12} sm={12}>
                <FormControl
                  error
                  component='fieldset'
                  variant='standard'
                  sx={{ minWidth: '100%' }}
                >
                  <TextField
                    id='outlined-basic'
                    label='Web Link Title'
                    variant='outlined'
                    required
                    value={hyperlinkName}
                    onChange={handleHyperlinkNameChange}
                  />
                </FormControl>
              </Grid>

              <Grid item lg={3} xs={12} md={12} sm={12}>
                <FormControl
                  error
                  component='fieldset'
                  variant='standard'
                  sx={{ minWidth: '100%' }}
                >
                  <TextField
                    id='outlined-basic'
                    label='Web Link URL'
                    variant='outlined'
                    required
                    value={hyperlinkUrl}
                    onChange={handleHyperlinkUrlChange}
                    error={!isValidUrl}
                    helperText={errorMessage}
                  />
                </FormControl>
              </Grid>
              <Grid mt={1} item lg={2} xs={12} md={12} sm={12}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  onClick={saveUnique}
                  disabled={!permissions?.['Upload Resources']?.add}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            <br />
          </Box>
          <Box
            sx={{
              width: '100%',

              '& .super-app-theme--header': {
                backgroundColor: '#2196f3',
              },
            }}
          >
            <DataGridPremium
              getRowId={(row) => row?.id}
              rows={rows}
              columns={columns}
              columnHeaderHeight={50}
              rowHeight={30}
              pageSize={50}
              scrollbarSize={5}
              pagination
              slots={{
                pagination: CustomPagination,
              }}
              sx={{
                width: '100%',
                height: 450,
                border: 'none',
                borderRadius: '6px',
                boxShadow: '0px 0px 10px #ddd',
                backgroundColor: 'white',
                mt: 2,
              }}
            />
          </Box>
          <div className='modal'>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={Close}
              aria-describedby='alert-dialog-slide-description'
            >
              <DialogTitle></DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-slide-description'>
                  Are you sure you want to{' '}
                  {icondata?.status === 'Active' ? 'InActive ' : 'Active'} this
                  Web Link?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={Close}>No</Button>
                <Button onClick={handleOkClick}>Yes</Button>
              </DialogActions>
            </Dialog>
          </div>
          <div className='modal'>
            <Dialog
              open={openapi}
              TransitionComponent={Transition}
              keepMounted
              onClose={Close}
              aria-describedby='alert-dialog-slide-description'
            >
              <DialogTitle></DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-slide-description'>
                  {dialogContents}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <IconButton onClick={() => setOpenapi(false)}>
                  <CloseIcon />
                </IconButton>
              </DialogActions>
            </Dialog>
          </div>
        </TabPanel>
    
    </div>
  );
  // }
};

export default UploadResources;
