import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { GRID_DATE_COL_DEF, useGridApiContext } from '@mui/x-data-grid-premium';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import locale from 'date-fns/locale/en-US';
import { ActionList } from '../../../components/CaseView/CaseViewDateTimeField';

function buildApplyDateFilterFn(filterItem, compareFn, showTime = false) {
  if (!filterItem.value) {
    return null;
  }

  // Make a copy of the date to not reset the hours in the original object
  const filterValueCopy = new Date(filterItem.value);
  filterValueCopy.setHours(0, 0, 0, 0);

  const filterValueMs = filterValueCopy.getTime();

  return ({ value }) => {
    if (!value) {
      return false;
    }

    // Make a copy of the date to not reset the hours in the original object
    const dateCopy = new Date(value);
    dateCopy.setHours(showTime ? value.getHours() : 0, showTime ? value.getMinutes() : 0, 0, 0);
    const cellValueMs = dateCopy.getTime();

    return compareFn(cellValueMs, filterValueMs);
  };
}

function getDateFilterOperators(showTime = false) {
  return [
    {
      value: 'is',
      getApplyFilterFn: filterItem => {
        return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 === value2, showTime);
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: 'not',
      getApplyFilterFn: filterItem => {
        return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 !== value2, showTime);
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: 'after',
      getApplyFilterFn: filterItem => {
        return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 > value2, showTime);
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: 'onOrAfter',
      getApplyFilterFn: filterItem => {
        return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 >= value2, showTime);
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: 'before',
      getApplyFilterFn: filterItem => {
        return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 < value2, showTime);
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: 'onOrBefore',
      getApplyFilterFn: filterItem => {
        return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 <= value2, showTime);
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: 'isEmpty',
      getApplyFilterFn: () => {
        return ({ value }) => {
          return value == null;
        };
      },
      requiresFilterValue: false,
    },
    {
      value: 'isNotEmpty',
      getApplyFilterFn: () => {
        return ({ value }) => {
          return value != null;
        };
      },
      requiresFilterValue: false,
    },
  ];
}

const dateAdapter = new AdapterDateFns({ locale });

const GridEditDateInput = styled(InputBase)({
  fontSize: 'inherit',
  padding: '0 9px',
});

function WrappedGridEditDateInput(props) {
  const { InputProps, ...other } = props;
  return <GridEditDateInput fullWidth {...InputProps} {...other} />;
}

function GridEditDateCell({ id, field, value }) {
  const apiRef = useGridApiContext();

  const handleChange = newValue => {
    if (!apiRef.current) return;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <DatePicker
      value={value}
      onChange={handleChange}
      slots={{ textField: WrappedGridEditDateInput, actionBar: ActionList }}
      // disableFuture
    />
  );
}

function GridFilterDateInput(props) {
  const { item, showTime, applyValue, apiRef } = props;

  const handleFilterChange = newValue => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <DatePicker
      value={item.value || null}
      label={apiRef.current.getLocaleText('filterPanelInputLabel')}
      slotProps={{
        textField: {
          variant: 'standard',
        },
        inputAdornment: {
          sx: {
            '& .MuiButtonBase-root': {
              marginRight: -1,
            },
          },
        },
      }}
      onChange={handleFilterChange}
    />
  );
}

/**
 * `dateTime` column
 */
export const dateColumnType = {
  ...GRID_DATE_COL_DEF,
  renderEditCell: params => {
    return <GridEditDateCell {...params} />;
  },
  filterOperators: getDateFilterOperators(true),
  valueFormatter: value => {
    if (!value) {
      return null;
    }
    if (typeof value === 'string') {
      return value;
    }
    return dateAdapter.format(value, 'keyboardDate');
  },
  minWidth: 150,
};
