export const sortOptionsAlphabetically = <T>(options: T[], property: keyof T): T[] => {
  return options.sort((a, b) => {
    const aValue = a[property];
    const bValue = b[property];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return aValue.localeCompare(bValue);
    }
    return 0;
  });
};
