import React, { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Grid, Box, Button, IconButton } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';
import Tooltip from '@mui/material/Tooltip';
import MainCard from '../../../../components/AdministrationModules/MainCard';
import ReferenceMasterTableFilter from './ReferenceMasterTableFilter';
import axios from 'axios';
import AddEdit from './AddEdit';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import {
  DataGridPremium,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { deleteData, activeData } from '../../../../services/utility-api';
import authHeader from '../../../../services/auth-header';
import AccessDenied from '../../../../components/AdministrationModules/AccessDenied';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = gridPageSelector(apiRef);
  const pageCount = gridPageCountSelector(apiRef);
  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => (
        <PaginationItem
          {...props2}
          sx={{ fontFamily: 'DataGrid, sans-serif' }}
          disableRipple
        />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{ mr: 3 }}
    />
  );
}

const ReportHeaderDetailsView = () => {
  const referenceMasterTableName = 'ReportHeaderDetails';
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const reportHeaderDetailsPermissions = permissions?.['Report Header Details'];
  const [isAddEditOn, setIsAddEditOn] = useState();
  const [editObj, setEditObj] = useState({});
  const [maxOrder, setMaxOrder] = useState(0);
  const [isCode, setIsCode] = useState(false);
  const [isCounty, setIsCounty] = useState(false);
  const [openActiveInActiveDialogBox, setOpenActiveInActiveDialogBox] =
    useState(false);
  const [activeInactiveClickData, setActiveInactiveClickData] = useState({});
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const [activeInActiveFilter, setActiveInActiveFilter] = useState('Active');

  const [columns, setColumns] = useState([
    {
      field: 'reportHeaderMasterName',
      headerName: referenceMasterTableName
        .split('')
        .map((alpha) => {
          if (alpha <= 'Z') return ' ' + alpha;
          return alpha;
        })
        .join('')
        .trim(),
      // headerClassName: 'super-app-theme--header',
      minWidth: 200,
      align: 'left',
      flex: 5,
    },
    {
      field: 'reportHeaderDetailsOrder',
      headerName: 'List Order',
      // headerClassName: 'super-app-theme--header',
      minWidth: 200,
      align: 'left',
      flex: 5,
    },
  ]);
  const [rows, setRows] = useState([]);
  const [rowsToShow, setRowsToShow] = useState([]);
  const [countyNames, setCountyNames] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const getAllData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}GetMasterData?tableName=${referenceMasterTableName}`,
        {
          headers: authHeader(),
        }
      );

      const countyResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}GetAllCounty`
      );
      const tempCountyNames = {};
      countyResponse?.data?.data?.forEach((county) => {
        tempCountyNames[county.countySeq] = {
          countySeq: county.countySeq,
          countyName: county.countyName,
          code: county.code,
          order: county.order,
        };
        if (county.hasOwnProperty('children')) {
          county.children.forEach((county) => {
            tempCountyNames[county.countySeq] = {
              countySeq: county.countySeq,
              countyName: county.countyName,
              code: county.code,
              order: county.order,
            };
          });
        }
      });
      setCountyNames(tempCountyNames);

      const data = response?.data?.data?.map((row) => {
        const newObj = {};
        Object.keys(row).map((key) => {
          newObj[key] = row[key];
        });
        newObj.id =
          newObj[
            referenceMasterTableName[0].toLowerCase() +
              referenceMasterTableName.slice(1) +
              'Seq'
          ];
        return newObj;
      });

      let isStatusColumnAdded = false;
      columns?.forEach((column) => {
        if (column.field == 'status') {
          isStatusColumnAdded = true;
        }
      });
      if (!isStatusColumnAdded) {
        setColumns((prev) => [
          ...prev,
          {
            field: 'status',
            headerName: 'Status',
            // headerClassName: 'super-app-theme--header',
            renderCell: (cellValues) => {
              const handleClick = async (row) => {
                setOpenActiveInActiveDialogBox(true);
                const newIconData = row.status === 'Active' ? 'locked' : 'done';
                setActiveInactiveClickData({
                  icon: newIconData,
                  seq: row[
                    referenceMasterTableName[0].toLowerCase() +
                      referenceMasterTableName.slice(1) +
                      'Seq'
                  ],
                  status: row.status,
                });
              };

              return Number(cellValues.row.order) > 90000 ? (
                <></>
              ) : (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick(cellValues.row);
                  }}
                >
                  {cellValues.row.status === 'Active' ? (
                    <Tooltip title='Inactive'>
                      <LockIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title='Active'>
                      <DoneIcon />
                    </Tooltip>
                  )}
                </Button>
              );
            },
          },
        ]);
      }

      let maxOrder = 0;
      for (let i = 0; i < data.length; i++) {
        if (Number(data[i].order) > maxOrder && Number(data[i].order) < 90000)
          maxOrder = Number(data[i].order);
      }
      setMaxOrder(maxOrder);
      setRows(data);
      setRowsToShow(
        data.filter(
          (row) => row['livery'][0]['status'].toLowerCase() == 'active'
        )
      );
    } catch (error) {
      console.log('error', error);
    }
    setIsLoading(false);
  };

  const editRow = (params) => {
    if (reportHeaderDetailsPermissions?.edit) {
      if (params?.row?.status.toLowerCase() === 'active') {
        setIsAddEditOn(false);
        setTimeout(() => {
          setEditObj(params.row);
          setIsAddEditOn(true);
        }, 200);
      }
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const changeActiveInActiveClick = async () => {
    try {
      if (activeInactiveClickData.status === 'Active') {
        const responseMessage = await deleteData({
          seq: activeInactiveClickData.seq,
          tableName: referenceMasterTableName,
        }); //.unwrap();
        setDialogContents(responseMessage);
      } else {
        const responseMessage = await activeData({
          seq: activeInactiveClickData.seq,
          tableName: referenceMasterTableName,
        });
        setDialogContents(responseMessage);
      }
      setOpenActiveInActiveDialogBox(false);
      setOpenApiDialogBox(true);
      await getAllData();
    } catch (error) {
      console.log('error', error);
    }
  };

  if (!reportHeaderDetailsPermissions?.view) {
    return (
      <>
        <AccessDenied />
      </>
    );
  } else {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              {/* <MainCard> */}
              <Box
                // sx={{
                //   width: '100%',
                //   '& .super-app-theme--header': {
                //     backgroundColor: '#2196f3',
                //   },
                // }}
                style={{ tr: 'hover', cursor: 'pointer' }}
              >
                <ReferenceMasterTableFilter
                  rows={rows}
                  isCounty={isCounty}
                  countyNames={countyNames}
                  setRowsToShow={setRowsToShow}
                  activeInActiveFilter={activeInActiveFilter}
                  setActiveInActiveFilter={setActiveInActiveFilter}
                />
                {
                    <DataGridPremium
                      getRowId={(row) => row.id}
                      rows={rowsToShow}
                      columns={columns}
                      columnHeaderHeight={50}
                      rowHeight={30}
                      pageSize={50}
                      scrollbarSize={5}
                      pagination
                      onRowClick={editRow}
                      slots={{
                        pagination: CustomPagination,
                      }}
                      // sx={{
                      //   width: '100%',
                      //   height: 450,
                      //   border: 'none',
                      //   borderRadius: '6px',
                      //   boxShadow: '0px 0px 10px #ddd',
                      //   backgroundColor: 'white',
                      //   mt: 2,
                      // }}
                    />
                  }
                </Box>
                {isAddEditOn && editObj && (
                  <Box>
                    <AddEdit
                      editObj={editObj}
                      isCounty={isCounty}
                      isCode={isCode}
                      referenceMasterTableName={referenceMasterTableName}
                      maxOrder={maxOrder}
                      setIsAddEditOn={setIsAddEditOn}
                      getAllData={getAllData}
                      countyNames={countyNames}
                      reportHeaderDetailsPermissions={
                        reportHeaderDetailsPermissions
                      }
                    />
                  </Box>
                )}

                <div className='modal'>
                  <Dialog
                    open={openActiveInActiveDialogBox}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpenActiveInActiveDialogBox(false)}
                    aria-describedby='alert-dialog-slide-description'
                  >
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                      <DialogContentText id='alert-dialog-slide-description'>
                        Are you sure you want to{' '}
                        {activeInactiveClickData?.status === 'Active'
                          ? 'InActive '
                          : 'Active'}{' '}
                        this report header details?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => setOpenActiveInActiveDialogBox(false)}
                      >
                        No
                      </Button>
                      <Button onClick={changeActiveInActiveClick}>Yes</Button>
                    </DialogActions>
                  </Dialog>
                </div>

                <div className='modal'>
                  <Dialog
                    open={openApiDialogBox}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpenApiDialogBox(false)}
                    aria-describedby='alert-dialog-slide-description'
                  >
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                      <DialogContentText id='alert-dialog-slide-description'>
                        {dialogContents}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <IconButton onClick={() => setOpenApiDialogBox(false)}>
                        <CloseIcon />
                      </IconButton>
                    </DialogActions>
                  </Dialog>
                </div>
              {/* </MainCard> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

export default ReportHeaderDetailsView;
