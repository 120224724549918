export const Livery = {
  Constants: {
    LiveryOptions: {
      Livery_Not_Required: 'B013DF78-CC9E-4D94-B348-69BDE12934B5',
      Investigator_To_Transport_Body: '0D6DD5E8-A673-490E-81D6-1ADFE15D1ED1',
      Livery_To_Transport_Body: 'E1E62391-C8F8-4674-A4BE-0CE1A4C62C93',
      Law_Enforcement_To_Transport_Body: '9015EDD5-9B6E-44D9-9A15-7E4872DC6916',
      ME_To_Body_Location: 'ME To Body Location',
    },
    LiveryItemsRequested: {
      Other: 'D4FAA853-8C1F-41B0-AFCF-12FD25094ECE',
      Xrays: '17501E53-D1CD-4828-ABE6-8E124B0AD35D',
      Clothing: '17501E53-D1CD-4828-ABE6-8E124B0AD35D',
      Blood: '17501E53-D1CD-4828-ABE6-8E124B0AD35D',
      Charts: '17501E53-D1CD-4828-ABE6-8E124B0AD35D',
    },
    NoLiveryReason: {
      Hospital: '30F50C1C-DF6A-4357-959E-73712B8AD97E',
      Other: 'D3C9D89A-9844-46CA-BBEA-ABD201444458',
    },
  },
};
