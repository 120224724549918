import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  Paper,
  Slide,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useNavigate } from 'react-router';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

function averageTATInfo(
  acceptedAverage,
  facilitatedAverage,
  releaseAverage,
  storageAverage,
  transferAverage
) {
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={4} sx={{ borderRadius: 20, p: 2 }}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant='body1'>
                <b>Average Response Time: </b>
                (Tracking Investigations and Scene/Livery Response times)
              </Typography>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6} lg={4} xxl='auto'>
          <Card>
            <CardContent sx={{ textAlign: 'center' }}>
              <Typography variant='body1'>
                <b>Accepted Cases</b>
              </Typography>
              <Typography variant='caption'>
                <i>ME notified to Morgue Check-in</i>
              </Typography>
              <Typography variant='body2'>{acceptedAverage}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={4} xxl='auto'>
          <Card>
            <CardContent sx={{ textAlign: 'center' }}>
              <Typography variant='body1'>
                <b>Facilitated Releases</b>
              </Typography>
              <Typography variant='caption'>
                <i>ME notified to Departed Scene</i>
              </Typography>
              <Typography variant='body2'>{facilitatedAverage}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={4} xxl='auto'>
          <Card>
            <CardContent sx={{ textAlign: 'center' }}>
              <Typography variant='body1'>
                <b>Releases</b>
              </Typography>
              <Typography variant='caption'>
                <i>ME notified to Release Confirmed</i>
              </Typography>
              <Typography variant='body2'>{releaseAverage}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} xxl='auto'>
          <Card>
            <CardContent sx={{ textAlign: 'center' }}>
              <Typography variant='body1'>
                <b>Storage Only</b>
              </Typography>
              <Typography variant='caption'>
                <i>ME notified to Morgue Check-in</i>
              </Typography>
              <Typography variant='body2'>{storageAverage}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={6} xxl='auto'>
          <Card>
            <CardContent sx={{ textAlign: 'center' }}>
              <Typography variant='body1'>
                <b>Transferred Cases</b>
              </Typography>
              <Typography variant='caption'>
                <i>ME notified to Transfer Accepted</i>
              </Typography>
              <Typography variant='body2'>{transferAverage}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function CaseResponseTime() {
  const { user } = useAuth();
  const [loading, setloading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const [acceptedAverage, setAcceptedAverage] = useState('');
  const [facilitatedAverage, setFacilitatedAverage] = useState('');
  const [releaseAverage, setReleaseAverage] = useState('');
  const [storageAverage, setStorageAverage] = useState('');
  const [transferAverage, setTransferAverage] = useState('');
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [selectedJdxs, setSelectedJdxs] = useState([]);
  const [MEAction, setMEAction] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [year, setYear] = useState(null);
  const [requiredAlertVisible, setRequiredAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [yearOptions, setYearOptions] = useState([]);
  const [MEActionOptions, setMEActionOptions] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();

  const makePretty = ns => {
    var prettyTime = '';

    if (ns >= 86400) {
      var numDays = Math.floor(ns / 86400);
      prettyTime += numDays + (numDays == 1 ? ' Day ' : ' Days ');
      ns %= 86400;
    }
    if (ns >= 3600) {
      var numHours = Math.floor(ns / 3600);
      prettyTime += numHours + (numHours == 1 ? ' Hour ' : ' Hours ');
      ns %= 3600;
    }
    if (ns >= 60) {
      var numMinutes = Math.floor(ns / 60);
      prettyTime += numMinutes + (numMinutes == 1 ? ' Minute ' : ' Minutes ');
      ns %= 60;
    }
    if (ns > 0) {
      var numSeconds = ns;
      prettyTime += numSeconds + (numSeconds == 1 ? ' Second ' : ' Seconds ');
    }

    if (prettyTime == '') {
      prettyTime = '---';
    }

    return prettyTime;
  };

  const calculateAcceptedAverage = data => {
    var count = 0;
    var numSeconds = 0;

    for (const x of data) {
      if (x.meaction == 'Accepted') {
        numSeconds += +x.morguecheckintimeseconds;
        count++;
      }
    }

    setAcceptedAverage(makePretty(Math.round(numSeconds / count)));
  };

  const calculateFacilitatedAverage = data => {
    var count = 0;
    var numSeconds = 0;

    for (const x of data) {
      if (x.meaction == 'Facilitated Release') {
        numSeconds += +x.departedscenetimeseconds;
        count++;
      }
    }

    setFacilitatedAverage(makePretty(Math.round(numSeconds / count)));
  };

  const calculateReleaseAverage = data => {
    var count = 0;
    var numSeconds = 0;

    for (const x of data) {
      if (x.meaction == 'Case Release') {
        numSeconds += +x.releaseconfirmedtimeseconds;
        count++;
      }
    }

    setReleaseAverage(makePretty(Math.round(numSeconds / count)));
  };

  const calculateStorageAverage = data => {
    var count = 0;
    var numSeconds = 0;

    for (const x of data) {
      if (x.meaction == 'Storage Only') {
        numSeconds += +x.morguecheckintimeseconds;
        count++;
      }
    }

    setStorageAverage(makePretty(Math.round(numSeconds / count)));
  };

  const calculateTransferAverage = data => {
    var count = 0;
    var numSeconds = 0;

    for (const x of data) {
      if (x.meaction == 'Transfer') {
        numSeconds += +x.transferacceptedtimeseconds;
        count++;
      }
    }

    setTransferAverage(makePretty(Math.round(numSeconds / count)));
  };

  const calculateAverages = data => {
    calculateAcceptedAverage(data);
    calculateFacilitatedAverage(data);
    calculateReleaseAverage(data);
    calculateStorageAverage(data);
    calculateTransferAverage(data);
  };

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const columns = [
    {
      field: 'caseid',
      headerName: 'Case ID',
      width: 105,
      editable: false,
      renderCell: params => {
        return (
          <NavLink
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
            }}
          >
            <b>{params.formattedValue}</b>
          </NavLink>
        );
      },
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'lastname',
      headerName: 'Last Name',
      minWidth: 120,
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'pronounced',
      headerName: 'Pronounced',
      minWidth: 170,
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'calltaken',
      headerName: 'Call Taken',
      minWidth: 170,
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'meaction',
      headerName: 'ME Action',
      minWidth: 145,
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'morguecheckin',
      headerName: 'Morgue Check In',
      minWidth: 170,
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'departedscene',
      headerName: 'Departed Scene',
      minWidth: 170,
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'releaseconfirmed',
      headerName: 'Release Confirmed',
      minWidth: 170,
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'transferaccepted',
      headerName: 'Transfer Accepted',
      minWidth: 170,
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'morguecheckintime',
      headerName: 'Morgue Check In Time',
      minWidth: 300,
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'departedscenetime',
      headerName: 'Departed Scene Time',
      minWidth: 300,
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'releaseconfirmedtime',
      headerName: 'Release Confirmed Time',
      minWidth: 300,
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'transferacceptedtime',
      headerName: 'Transfer Accepted Time',
      minWidth: 300,
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
  ];

  const { REACT_APP_API_URL } = process.env;

  const handleJdxChange = (event, data) => {
    setSelectedJdxs(data);
  };

  const handleMEActionChange = (event, data) => {
    setMEAction(data);
  };

  const handleStartDateChange = event => {
    if (event.target.value != '') {
      setStartDate(event.target.value);
    } else {
      setStartDate('');
    }
  };

  const handleEndDateChange = event => {
    if (event.target.value != '') {
      setEndDate(event.target.value);
    } else {
      setEndDate('');
    }
  };

  const handleYearChange = (event, data) => {
    setYear(data);
  };

  const dismissDateAlert = () => {
    setRequiredAlertVisible(false);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  const clearAll = () => {
    setStartDate('');
    setEndDate('');
    setYear('');
    setSelectedJdxs([]);
    setMEAction([]);
    setResultsVisible(false);
  };

  //gather search params from form and FETCH/POST to API
  const handleSubmit = async event => {
    event.preventDefault();

    if (year == '' && startDate == '' && endDate == '') {
      setRequiredAlertVisible(true);
      return;
    } else if (startDate != '' && endDate != '') {
      let startDateArr = startDate.split('-');
      let endDateArr = endDate.split('-');

      let startDateValue = new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]);
      let endDateValue = new Date(endDateArr[0], endDateArr[1] - 1, endDateArr[2]);

      if (endDateValue - startDateValue > 31536000000) {
        setRequiredAlertVisible(true);
        return;
      }
    }

    if (selectedJdxs.length == 0) {
      setJdxAlertVisible(true);
      return;
    }

    let formData = new FormData();

    setloading(true);
    setResultsVisible(true);

    formData.append('SEARCHFROMDATE', startDate);
    formData.append('SEARCHTODATE', endDate);
    formData.append('CASEYEAR', year);
    formData.append('SELECTEDJDXLIST', JSON.stringify(selectedJdxs));
    formData.append('MEACTION', JSON.stringify(MEAction));

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'MEResponseTime', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setlistresults(data);
          setloading(false);
          calculateAverages(data);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    setloading(false);
  };

  useEffect(() => {
    if (user === null) return;

    const fetchYear = async () => {
      try {
        const currentYear = new Date().getFullYear();
        setYear(currentYear);
        setYearOptions([...Array(16).keys()].map(i => i + (currentYear - 15)).reverse());
      } catch (e) {
        alert(e);
      }
    };

    const getMeActionOptions = async () => {
      await fetch(process.env.REACT_APP_API_URL + 'getmeactionoptions', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => {
          if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setMEActionOptions(data);
        })
        .catch(e => {
          alert(e);
        });
    };

    fetchYear();
    getMeActionOptions();

    setAuthorizedJdxs(user.jdxAccessList);
    setSelectedJdxs(user.jdxAccessList);
  }, [user]);

  return (
    <Card
      raised
      sx={{
        my: 3,
        mx: 'auto',
        width: { xs: '95%', lg: '75%' },
      }}
    >
      <CardHeader
        title='Case Average Response Time'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
        action={
          <Tooltip
            sx={{ color: theme.palette.text.primary }}
            title='This Report Measures how long (on Average) after call are made that a case is acted upon (categorized by Disposition/ME Action)'
            placement='right-end'
          >
            <InfoIcon />
          </Tooltip>
        }
      />

      <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

      <CardContent>
        <Slide
          direction='right'
          in={requiredAlertVisible}
          mountOnEnter
          unmountOnExit
          sx={{ mb: 2 }}
        >
          <Alert severity='warning' onClose={dismissDateAlert}>
            Call taken range must be within 1 year or year dropdown must be set.
          </Alert>
        </Slide>

        <Slide direction='right' in={jdxAlertVisible} mountOnEnter unmountOnExit sx={{ mb: 2 }}>
          <Alert severity='warning' onClose={dismissJdxAlert}>
            Please select at least 1 jurisdiction.
          </Alert>
        </Slide>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              id='selectedjdxs'
              size='small'
              options={authorizedJdxs}
              value={selectedJdxs}
              limitTags={4}
              disableCloseOnSelect
              getOptionLabel={option => option.jdxName}
              onChange={(event, data) => handleJdxChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.jdxName}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              id='meaction'
              size='small'
              options={MEActionOptions.filter(o => o.isActive !== false)}
              value={MEAction}
              disableCloseOnSelect
              getOptionLabel={option => option.optionName}
              onChange={(event, data) => handleMEActionChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.optionName}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField {...params} label='ME Action' placeholder='ME Action' />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Autocomplete
              id='year'
              options={yearOptions}
              value={year}
              size='small'
              getOptionLabel={option => option.toString()}
              onChange={(event, data) => handleYearChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.toString()}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => <TextField {...params} label='Year' placeholder='Year' />}
              ListboxComponent={'ul'}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id='searchfromdate'
              name='searchfromdate'
              label='Exam Start From'
              type='date'
              size='small'
              value={startDate}
              onChange={handleStartDateChange}
              disabled={year}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id='searchtodate'
              name='searchtodate'
              label='Exam Start To'
              type='date'
              size='small'
              value={endDate}
              onChange={handleEndDateChange}
              disabled={year}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 2, opacity: 1 }} />

        <Stack
          direction='row'
          sx={{
            width: '100%',
            justifyContent: 'right',
          }}
          spacing={2}
        >
          <Button type='submit' variant='contained' color='primary' onClick={handleSubmit}>
            Calculate
          </Button>

          <Button color='secondary' variant='outlined' onClick={clearAll}>
            Clear
          </Button>
        </Stack>

        <Collapse in={resultsVisible} sx={{ mt: resultsVisible ? 4 : 0 }}>
          <ReportDataGrid
            loading={loading}
            listresults={listresults}
            idcolumn='caseid'
            columnsInput={columns}
            title='Case Response Time'
            casecount
            chatcontent={averageTATInfo(
              acceptedAverage,
              facilitatedAverage,
              releaseAverage,
              storageAverage,
              transferAverage
            )}
          />
        </Collapse>
      </CardContent>
    </Card>
  );
}
