import GroupIcon from '@mui/icons-material/Group';
import WarningIcon from '@mui/icons-material/Warning';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  DataGridPremium,
  GridToolbarContainer,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../../../utils/auth/AuthService';
import CustomHeader from '../../../utils/components/CustomHeader';
import CustomPagination from '../../../utils/components/CustomPagination';
import LockOpenIcon from '@mui/icons-material/LockOpen';

export default function AllLockedCases() {
  const apiRef = useGridApiRef();
  const [loadingData, setLoadingData] = useState(false);
  const [loadingUnlock, setLoadingUnlock] = useState(false);
  const [loadingUnlockAll, setLoadingUnlockAll] = useState(false);
  const [loadingUnlockStale, setLoadingUnlockStale] = useState(false);
  const [unlockAllConfirmText, setUnlockAllConfirmText] = useState('');
  const [unlockStaleConfirmText, setUnlockStaleConfirmText] = useState('');
  const [listResults, setListResults] = useState([]);
  const [isGroupingEnabled, setIsGroupingEnabled] = useState(false);
  const [unlockAllDialogOpen, setUnlockAllDialogOpen] = useState(false);
  const [unlockStaleDialogOpen, setUnlockStaleDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const theme = useTheme();
  const { REACT_APP_API_URL } = process.env;
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0,
  });

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['groupingCaseID'],
      },
    },
  });

  const handleCaseClick = (event, data) => {
    event.stopPropagation();
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const handleUnlock = async row => {
    setLoadingUnlock(true);
    var caseID = null;
    if (row.caseSeq == null) {
      const symbols = Object.getOwnPropertySymbols(row);
      const symbolKey2 = symbols.find(sym => sym.toString() === 'Symbol(mui.id_autogenerated)');

      if (symbolKey2) {
        caseID = row[symbolKey2].substring(row[symbolKey2].length - 10);
      }
    }

    await fetch(REACT_APP_API_URL + 'deletecasemodulelocks', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user?.token,
      },
      body:
        caseID == null
          ? !isGroupingEnabled
            ? JSON.stringify({ LockObjectSeq: row.caseSeq })
            : JSON.stringify({ LockObjectSeq: row.caseSeq, ModuleName: row.moduleName })
          : JSON.stringify({ CaseID: caseID }),
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occurred');
        }
        return res.text();
      })
      .then(data => {
        fetchData();
      })
      .catch(e => {
        console.error(e);
      });

    setLoadingUnlock(false);
  };

  const openUnlockAllDialog = () => {
    setUnlockAllDialogOpen(true);
  };

  const openUnlockStaleDialog = () => {
    setUnlockStaleDialogOpen(true);
  };

  const handleUnlockAll = async () => {
    setLoadingUnlockAll(true);

    await fetch(REACT_APP_API_URL + 'DeleteAllCaseModuleLocks', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user?.token,
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occurred');
        }
      })
      .catch(e => {
        console.error(e);
      });

    setUnlockAllDialogOpen(false);
    setLoadingUnlockAll(false);
    fetchData();
  };

  const handleUnlockStale = async () => {
    setLoadingUnlockStale(true);

    await fetch(REACT_APP_API_URL + 'DeleteStaleCaseModuleLocks', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user?.token,
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occurred');
        }
      })
      .catch(e => {
        console.error(e);
      });

    setUnlockStaleDialogOpen(false);
    setLoadingUnlockStale(false);
    fetchData();
  };

  const fetchData = async () => {
    setLoadingData(true);
    try {
      const response = await fetch(REACT_APP_API_URL + 'GetAllLockedCases', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 401) {
        throw new Error('You are unauthorized to use this tool');
      } else if (response.status >= 400) {
        throw new Error('An error occurred');
      } else if (response.status === 204) {
        setListResults([]);
      } else {
        const data = await response.json();

        console.log(data);

        setListResults(data ?? []);
      }
    } catch (error) {
      console.error(error);
      setListResults([]);
    } finally {
      setLoadingData(false);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    if (user && user.token) {
      fetchData();
    }
  }, [REACT_APP_API_URL, user]);

  useEffect(() => {
    if (isGroupingEnabled) {
      setPaginationModel({ pageSize: 4, page: 0 });
    } else {
      setPaginationModel({ pageSize: 15, page: 0 });
    }
  }, [isGroupingEnabled]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ justifyContent: 'right', p: 1.5 }}>
        <Typography variant='h6' sx={{ mr: 'auto', ml: 1 }}>
          {Object.values(
            listResults?.reduce((acc, curr) => {
              if (!acc[curr.caseID]) {
                acc[curr.caseID] = { ...curr };
              }
              return acc;
            }, {})
          )?.length ?? 0}{' '}
          Cases Locked
        </Typography>
        <Button onClick={() => setIsGroupingEnabled(!isGroupingEnabled)} startIcon={<GroupIcon />}>
          Toggle Grouping
        </Button>

        <Button color='secondary' onClick={openUnlockStaleDialog} startIcon={<LockOpenIcon />}>
          Unlock Stale
        </Button>

        <Button color='warning' onClick={openUnlockAllDialog} startIcon={<WarningIcon />}>
          Unlock All
        </Button>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      field: 'groupingCaseID',
      headerName: '',
      editable: false,
      flex: 1,
      minWidth: 80,
      renderCell: params => {
        return (
          <Button
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
            }}
          >
            {params.formattedValue}
          </Button>
        );
      },
    },
    {
      field: 'caseID',
      headerName: 'Case ID',
      editable: false,
      flex: 1,
      minWidth: 80,
      renderCell: params => {
        return (
          <Button
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
            }}
          >
            {params.formattedValue}
          </Button>
        );
      },
    },
    {
      field: 'moduleName',
      headerName: 'Module Name',
      flex: 2,
      minWidth: 160,
      editable: false,
      hide: !isGroupingEnabled, // Hide moduleName column when grouping is off
    },
    {
      field: 'lockedBy',
      headerName: 'Locked By',
      flex: 2,
      minWidth: 160,
      editable: false,
    },
    {
      field: 'userName',
      headerName: 'User Name',
      flex: 1,
      minWidth: 100,
      editable: false,
    },
    {
      field: 'primaryEmail',
      headerName: 'Email',
      flex: 3,
      minWidth: 180,
      editable: false,
    },
    {
      field: 'lockedDateTime',
      headerName: 'Locked Date/Time',
      type: 'dateTime',
      valueGetter: value => value && new Date(value),
      valueFormatter: value => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
      },
      flex: 2,
      minWidth: 120,
      editable: false,
    },
    {
      field: 'caseSeq',
      headerName: 'Case Sequence',
      flex: 3,
      minWidth: 230,
      editable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: params => {
        return (
          <LoadingButton
            loading={loadingUnlock}
            variant='contained'
            color='secondary'
            onClick={() => handleUnlock(params.row)}
          >
            {isGroupingEnabled
              ? params.row.caseSeq == null
                ? 'Unlock Case'
                : 'Unlock Module'
              : 'Unlock Case'}
          </LoadingButton>
        );
      },
      flex: 1,
      minWidth: 130,
    },
  ];

  return (
    <Box>
      <CustomHeader
        title='All Locked Cases'
        description='Manage Cases that are currently locked, and unlock them if necessary.'
        showMenuButton={false}
      />

      <Grid container spacing={2}>
        <Grid item xs>
          <DataGridPremium
            apiRef={apiRef}
            density='compact'
            columns={columns}
            rows={
              isGroupingEnabled
                ? listResults
                : Object.values(
                    listResults.reduce((acc, curr) => {
                      if (!acc[curr.caseID]) {
                        acc[curr.caseID] = { ...curr };
                      }
                      return acc;
                    }, {})
                  )
            }
            loading={loadingData}
            getRowId={row => row.recordLockSeq}
            autoHeight
            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            disableRowGrouping={!isGroupingEnabled}
            initialState={initialState}
            defaultGroupingExpansionDepth={-1}
            slots={{
              toolbar: CustomToolbar,
              pagination: CustomPagination,
            }}
          />
        </Grid>
      </Grid>

      <Dialog open={unlockAllDialogOpen} onClose={() => setUnlockAllDialogOpen(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent sx={{ overflow: 'hidden' }}>
          <DialogContentText>
            Are you sure you want to unlock all cases? Doing so will allow all cases to be edited
            regardless of who might be editing them currently. To unlock all, type 'Unlock all
            cases' below, then click Unlock All.
          </DialogContentText>
        </DialogContent>

        <DialogContent sx={{ pt: 0 }}>
          <TextField
            placeholder='Unlock all cases'
            value={unlockAllConfirmText}
            onChange={event => setUnlockAllConfirmText(event.target.value)}
            fullWidth
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setUnlockAllDialogOpen(false)}>Cancel</Button>
          <LoadingButton
            color='warning'
            loading={loadingUnlockAll}
            onClick={handleUnlockAll}
            disabled={unlockAllConfirmText !== 'Unlock all cases'}
          >
            <span>Unlock All</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog open={unlockStaleDialogOpen} onClose={() => setUnlockStaleDialogOpen(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent sx={{ overflow: 'hidden' }}>
          <DialogContentText>
            Are you sure you want to unlock all cases older than 24 hours? Doing so will allow all
            affected cases to be edited regardless of who might be editing them currently. To unlock
            all stale cases, type 'Unlock all stale cases' below, then click Unlock.
          </DialogContentText>
        </DialogContent>

        <DialogContent sx={{ pt: 0 }}>
          <TextField
            placeholder='Unlock all stale cases'
            value={unlockStaleConfirmText}
            onChange={event => setUnlockStaleConfirmText(event.target.value)}
            fullWidth
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setUnlockStaleDialogOpen(false)}>Cancel</Button>
          <LoadingButton
            color='warning'
            loading={loadingUnlockStale}
            onClick={handleUnlockStale}
            disabled={unlockStaleConfirmText !== 'Unlock all stale cases'}
          >
            <span>Unlock</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
