import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  Paper,
  Slide,
  Stack,
  TextField,
  Tooltip,
  useTheme,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

function getAvgPercentCompleted(results) {
  var totalNumCases = 0;
  var totalNumCompleted = 0;

  for (var row of results) {
    totalNumCases += parseInt(row.total);
    totalNumCompleted += parseInt(row.ontime);
  }

  return (totalNumCompleted / totalNumCases) * 100;
}

export default function METurnaroundTimeView() {
  const { user } = useAuth();
  const [loading, setloading] = useState(false);
  const [results, setResults] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [yearOptions, setYearOptions] = useState([]);
  const [year, setYear] = useState(null);
  const [threshold, setThreshold] = useState(60);
  const [currentUser, setcurrentUser] = useState(false);
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [selectedJdxs, setSelectedJdxs] = useState([]);
  const [requiredAlertVisible, setRequiredAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [searchDays, setSearchDays] = useState(0);
  const [avgPercentCompleted, setAvgPercentCompleted] = useState(0);
  const theme = useTheme();

  const columns = [
    {
      field: 'me',
      headerName: 'Medical Examiner',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'ontime',
      headerName: '# of Cases on Time',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'total',
      headerName: 'Total # of Cases',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'percent',
      headerName: '% Completed on Time',
      flex: 1,
      minWidth: 80,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
  ];

  const { REACT_APP_API_URL } = process.env;

  const handleJdxChange = (event, data) => {
    setSelectedJdxs(data);
  };

  const handleStartDateChange = event => {
    if (event.target.value != '') {
      setStartDate(event.target.value);
    } else {
      setStartDate('');
    }
  };

  const handleEndDateChange = event => {
    if (event.target.value != '') {
      setEndDate(event.target.value);
    } else {
      setEndDate('');
    }
  };

  const handleYearChange = (event, data) => {
    setYear(data);
  };

  const handleThresholdChange = event => {
    setThreshold(event.target.value);
  };

  const dismissDateAlert = () => {
    setRequiredAlertVisible(false);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  //Update to clear all available search parameters
  const clearAll = () => {
    setStartDate('');
    setEndDate('');
    setThreshold(60);
    setYear(null);
    setSelectedJdxs([]);
    setResultsVisible(false);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (user == null) return;

    if (year == null && startDate == '' && endDate == '') {
      setRequiredAlertVisible(true);
      return;
    } else if (year == null && startDate != '' && endDate != '') {
      let startDateArr = startDate.split('-');
      let endDateArr = endDate.split('-');

      let startDateValue = new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]);
      let endDateValue = new Date(endDateArr[0], endDateArr[1] - 1, endDateArr[2]);

      if (endDateValue - startDateValue > 31536000000) {
        setRequiredAlertVisible(true);
        return;
      }
    }

    if (selectedJdxs.length == 0) {
      setJdxAlertVisible(true);
      return;
    }

    let formData = new FormData();

    setloading(true);
    setResultsVisible(true);

    formData.append(
      'JDXLIST',
      selectedJdxs != [] ? JSON.stringify(selectedJdxs) : JSON.stringify(authorizedJdxs)
    );
    formData.append('THRESHOLD', threshold);
    formData.append('YEAR', year);
    formData.append('STARTDATE', year ? null : startDate);
    formData.append('ENDDATE', year ? null : endDate);

    setSearchDays(threshold);

    //Update fetch uri to appropriate api call
    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'METurnaroundTime', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setResults(data);
          setAvgPercentCompleted(getAvgPercentCompleted(data));
          setloading(false);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    setloading(false);
  };

  useEffect(() => {
    if (user === null) return;

    const fetchYear = async () => {
      try {
        const currentYear = new Date().getFullYear();
        setYear(currentYear);
        setYearOptions([...Array(16).keys()].map(i => i + (currentYear - 15)).reverse());
      } catch (e) {
        alert(e);
      }
    };

    fetchYear();

    setAuthorizedJdxs(user.jdxAccessList);
    setSelectedJdxs(user.jdxAccessList);
  }, [user]);

  return (
      <Card
        raised
        sx={{
          my: 3,
          mx: 'auto',
          width: { xs: '95%', lg: '75%' },
        }}
      >
        <CardHeader
        title='ME Turnaround Time'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
        action={
          <Tooltip
            sx={{ color: theme.palette.text.primary }}
            title='This report gives a percentage of cases completed within a given number of days for all medical examiners of the chosen jurisdictions.'
            placement='right-end'
          >
            <InfoIcon />
          </Tooltip>
        }
      />

      <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

      <CardContent>
        <Slide
          direction='right'
          in={requiredAlertVisible}
          mountOnEnter
          unmountOnExit
          sx={{ mb: 2 }}
        >
          <Alert severity='warning' onClose={dismissDateAlert}>
            Call taken range must be within 1 year or year dropdown must be set.
          </Alert>
        </Slide>

        <Slide direction='right' in={jdxAlertVisible} mountOnEnter unmountOnExit sx={{ mb: 2 }}>
          <Alert severity='warning' onClose={dismissJdxAlert}>
            Please select at least 1 jurisdiction.
          </Alert>
        </Slide>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id='selectedJdxs'
              //options={authorizedJdxs}
              options={authorizedJdxs.sort((a, b) =>
                a.jdxName.localeCompare(b.jdxName)
              )}
              value={selectedJdxs}
              disableCloseOnSelect
              limitTags={4}
              fullWidth
              size='small'
              getOptionLabel={option => option.jdxName}
              onChange={(event, data) => handleJdxChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.jdxName}
                </li>
              )}
              renderInput={params => (
                <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
              )}
              ListboxComponent={'ul'}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Autocomplete
              id='year'
              options={yearOptions}
              value={year}
              size='small'
              getOptionLabel={option => option.toString()}
              onChange={(event, data) => handleYearChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.toString()}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => <TextField {...params} label='Year' placeholder='Year' />}
              ListboxComponent={'ul'}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id='searchfromdate'
              name='searchfromdate'
              label='Exam Start From'
              type='date'
              size='small'
              value={startDate}
              onChange={handleStartDateChange}
              disabled={year}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id='searchtodate'
              name='searchtodate'
              label='Exam Start To'
              type='date'
              size='small'
              value={endDate}
              onChange={handleEndDateChange}
              disabled={year}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
             <Typography sx={{ m: 1 }}>Cases Complete Within</Typography>
                <TextField
                  id='threshold'
                  name='threshold'
                  label='# of days'
                  type='number'
                  size='small'
                  value={threshold}
                  onChange={handleThresholdChange}
                />
                <Typography sx={{ m: 1 }}>Days</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2, opacity: 1 }} />

        <Stack
          direction='row'
          sx={{
            width: '100%',
            justifyContent: 'right',
          }}
          spacing={2}
        >
          <Button type='submit' variant='contained' color='primary' onClick={handleSubmit}>
            Calculate
          </Button>

          <Button color='secondary' variant='outlined' onClick={clearAll}>
            Clear
          </Button>
        </Stack>

        <Collapse in={resultsVisible} sx={{ mt: resultsVisible ? 4 : 0 }}>
            <ReportDataGrid
              loading={loading}
              listresults={results}
              columnsInput={columns}
              idcolumn='me'
              title='ME Turnaround Time'
              chatcontent={
                <Paper
                  elevation={3}
                  sx={{
                    p: '10px',
                    px: '20px',
                    borderRadius: '20px',
                    width: 'max-content',
                  }}
                >
                  <Typography sx={{ textAlign: 'center' }}>
                    The percent of all cases completed within <b>{searchDays}</b> days is{' '}
                    <b>{avgPercentCompleted.toFixed(2)}%</b>
                    &nbsp; I've listed the detailed case records below, you can export the list to
                    csv format.
                  </Typography>
                </Paper>
              }
            />
          </Collapse>
      </CardContent>
      </Card>
  );
}
