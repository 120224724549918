import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import ControlComponents from './ControlComponents';
import { Box, Button, Grid } from '@mui/material';
import {
  fetchFormattedDate,
  fetchLoginHistory,
  findDayDifference,
  calculateDateDifference,
} from '../../../../services/utility-api';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import {
  DataGridPremium,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { GlobalContext } from '../../../../Context/GlobalContext';
import { useLocation, useNavigate, useParams } from 'react-router';
import Chips from '../../../../components/AdministrationModules/Chips';
import * as Yup from 'yup';
import UserHeaderContainer from './UserHeaderContainer';
import { useAuth } from '../../../../utils/auth/AuthService.tsx';
import dayjs from 'dayjs';
function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = gridPageSelector(apiRef);
  const pageCount = gridPageCountSelector(apiRef);

  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => (
        <PaginationItem
          {...props2}
          sx={{ fontFamily: 'DataGrid, sans-serif' }}
          disableRipple
        />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{ mr: 3 }}
    />
  );
}

const LoginHistory = ({userSeq}) => {
  // const { user } = useAuth();
  const { profileInformation } = useContext(GlobalContext);
  const [loginHistoryData, setLoginHistoryData] = useState([]);
  const navigate = useNavigate();
  const today = dayjs();
  const oneMonthAgo = dayjs().subtract(1, 'month');

  const initialValues = {
    startDate: oneMonthAgo,
    endDate: today,
  };

  const columns = [
    {
      field: 'userIPAddress',
      // headerClassName: 'super-app-theme--header',
      headerName: 'User IP Address',
      type: 'text',
      width: 100,
      align: 'left',
      flex: 5,
    },
    {
      field: 'loginDateTime',
      // headerClassName: 'super-app-theme--header',
      headerName: '	Log In Date Time',
      type: 'text',
      width: 200,
      align: 'left',
      flex: 5,
    },
    {
      field: 'logOutDateTime',
      // headerClassName: 'super-app-theme--header',
      headerName: '	Log Out Date Time',
      type: 'text',
      width: 200,
      align: 'left',
      flex: 5,
    },
  ];
  const onSubmit = (values) => {
    const dayDifference = findDayDifference(values?.startDate, values?.endDate);
    const formattedDate = fetchFormattedDate(values?.startDate);
    fetchLoginHistory(userSeq, dayDifference, formattedDate).then(
      (history) => setLoginHistoryData(history)
    );
  };

  useEffect(() => {
    if (profileInformation.length === 0) {
      navigate('/sysadmin');
    }
  }, []);

  const validationSchema = Yup.object({
    startDate: Yup.string().required('Start Date is required'),
    endDate: Yup.string().required('End Date is required'),
  });

  const date = new Date();

  return (
    <div className='container-fluid my-2 py-2'>
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ form, values }) => {
        return (
          <>
            {/* {!window.location.search.includes('your-profile') ? (
              <UserHeaderContainer />
            ) : null} */}
            <Form>
              <Box>
                <Grid container spacing={2}>
                  <Grid item lg={5} xs={12} md={12} sm={12}>
                    <ControlComponents
                      control='date'
                      name='startDate'
                      label='Start Date*'
                    />
                  </Grid>
                  <Grid item lg={5} xs={12} md={12} sm={12}>
                    <ControlComponents
                      control='date'
                      name='endDate'
                      label='End Date*'
                    />
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    xs={12}
                    md={12}
                    sm={12}
                    sx={{ margin: 'auto 0' }}
                  >
                    <Button fullWidth variant='contained' size="large" type='submit'>
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
            <Box sx={{ paddingTop: '10px' }}>
              <DataGridPremium
                getRowId={(row) => row.loginDateTime}
                rows={loginHistoryData}
                columns={columns}
                columnHeaderHeight={50}
                rowHeight={30}
                pageSize={50}
                scrollbarSize={5}
                pagination
                slots={{
                  pagination: CustomPagination,
                }}
                sx={{
                  marginTop: '10px',
                  width: '100%',
                  height: 450,
                  // border: 'none',
                  borderRadius: '6px',
                  // backgroundColor: 'white',
                  // boxShadow: '0px 0px 10px #ddd',
                }}
              />
            </Box>
          </>
        );
      }}
    </Formik>
    </div>
  );
};

export default LoginHistory;
