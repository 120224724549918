import { EmailSharp, PhoneSharp } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../../../utils/auth/AuthService';
import { useTwoFactorHelper } from '../../../utils/auth/functions/useTwoFactorHelper.hook';
import { TwoFactorMethods } from '../../../utils/auth/reducers/authReducer';
import { TwoFactorError } from './TwoFactorError';

export function TwoFactorMethodSelector() {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<TwoFactorMethods>('email');
  const { hasTwoFactorError, getErrorMessage, userHasEmail, userHasPhone } = useTwoFactorHelper();

  const navigate = useNavigate();

  const handleSendCode = async () => {
    if (selected === 'email') {
      auth.two_factor.sendEmail();
    } else if (selected === 'sms') {
      auth.two_factor.sendSMS();
    }
  };

  useEffect(() => {
    if (auth.two_factor.email_state.state === 'awaiting response') {
      setLoading(true);
    } else if (auth.two_factor.email_state.state === 'code sent to user') {
      navigate('/login/2fa/email');
    } else if (auth.two_factor.email_state.state === 'error') {
      setLoading(false);
    }
  }, [navigate, auth.two_factor.email_state.state]);

  useEffect(() => {
    if (auth.two_factor.sms_state.state === 'awaiting response') {
      setLoading(true);
    } else if (auth.two_factor.sms_state.state === 'code sent to user') {
      navigate('/login/2fa/sms');
    } else if (auth.two_factor.sms_state.state === 'error') {
      setLoading(false);
    }
  }, [navigate, auth.two_factor.sms_state.state]);

  const awaitingResponse =
    auth.two_factor.email_state.state !== 'error' || auth.two_factor.sms_state.state !== 'error';

  return (
    <>
      <Stack display='flex' flexDirection='column' alignItems='center' py={2}>
        <Typography variant='h5' fontWeight='bold'>
          Verification required
        </Typography>
        <Typography variant='body2' sx={{ opacity: '0.6' }}>
          To continue, you must verify your account login
        </Typography>
        <TwoFactorError />
      </Stack>
      <Box component='div' py={1.5}>
        <List>
          {userHasEmail && (
            <MethodListItem method='email' selected={selected} setSelected={setSelected} />
          )}
          {userHasPhone && (
            <MethodListItem method='sms' selected={selected} setSelected={setSelected} />
          )}
        </List>
      </Box>

      {awaitingResponse && !hasTwoFactorError && (
        <Button
          disabled={loading}
          startIcon={loading && <CircularProgress sx={{ color: 'grey.200' }} size={20} />}
          sx={{
            bgcolor: 'black',
            color: 'white',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.8)', // slightly darker shade on hover
            },
          }}
          variant='contained'
          fullWidth
          type='submit'
          onClick={() => handleSendCode()}
        >
          {loading ? 'Sending code' : 'Send code'}
        </Button>
      )}
      {hasTwoFactorError && (
        <Stack>
          <Button
            disabled={loading}
            startIcon={loading && <CircularProgress sx={{ color: 'grey.200' }} size={20} />}
            sx={{
              bgcolor: 'black',
              color: 'white',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.8)', // slightly darker shade on hover
              },
            }}
            variant='contained'
            fullWidth
            type='submit'
            onClick={() => handleSendCode()}
          >
            {loading ? 'Sending code' : 'Try again'}
          </Button>
          <Divider sx={{ my: 1.5 }}>
            <Typography variant='body2' color='text.secondary'>
              OR
            </Typography>
          </Divider>
          <Button
            disabled={loading}
            startIcon={loading && <CircularProgress sx={{ color: 'grey.200' }} size={20} />}
            sx={{
              borderColor: 'text.secondary',
              color: 'text.secondary',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.8)', // slightly darker shade on hover
              },
            }}
            variant='outlined'
            fullWidth
            type='submit'
            onClick={() => auth.logout()}
          >
            Sign out
          </Button>
        </Stack>
      )}
    </>
  );
}

function MethodListItem({
  method,
  selected,
  setSelected,
}: {
  method: TwoFactorMethods;
  selected: string;
  setSelected: (value: React.SetStateAction<TwoFactorMethods>) => void;
}) {
  const auth = useAuth();
  const primaryEmail = auth.user?.primaryEmail;
  const phoneNumber = auth.user?.primaryMobileNo;
  return (
    <ListItem
      secondaryAction={
        <IconButton edge='start' aria-label='comments'>
          {method === 'email' && <EmailSharp />}
          {method === 'sms' && <PhoneSharp />}
        </IconButton>
      }
      disablePadding
    >
      <ListItemButton selected={selected === method} onClick={e => setSelected(method)} dense>
        <ListItemText id={method}>
          <Stack component='div'>
            {method === 'email' && (
              <Typography variant='body2' sx={{ opacity: '0.6' }} fontWeight='bold'>
                {primaryEmail}
              </Typography>
            )}
            {method === 'sms' && (
              <Typography variant='body2' sx={{ opacity: '0.6' }} fontWeight='bold'>
                {phoneNumber}
              </Typography>
            )}
            <Typography variant='body2' sx={{ opacity: '0.6' }}>
              {method === 'email' && <>Email</>}
              {method === 'sms' && <>SMS</>}
            </Typography>
          </Stack>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}
