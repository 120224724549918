import { hasJurisdiction } from '../utils/functions/hasJurisdiction';
import { hasRole } from '../utils/functions/roleCheck';
import { Jurisdiction } from './Jurisdiction.interface';
import { Role } from './Role.interface';
import { SpecialRoleCharacteristics } from './SpecialRoleCharacteristics.interface';

export class User {
  readonly userName!: string;
  readonly userSeq!: string;
  readonly reportAccessList!: Report[];
  readonly primaryMobileNo?: string | null;
  readonly primaryEmail?: string | null;
  readonly userFirstName!: string;
  readonly userLastName!: string;
  readonly jdxAccessList!: Jurisdiction[];
  readonly roleAccessList!: Role[];
  readonly specialRoleCharacteristics!: SpecialRoleCharacteristics[];
  readonly enable_2FA_bypass!: boolean;
  readonly isDarkMode?: boolean | null;
  readonly loggedinWithExistingIP?: string | null;

  get token(): string {
    return localStorage.getItem('access_token') || '';
  }

  set token(value: string) {
    localStorage.setItem('access_token', value);
  }

  get authToken(): string | null {
    return localStorage.getItem('auth_token');
  }
  set authToken(value: string | null) {
    if (value === null) {
      localStorage.removeItem('auth_token');
    } else {
      localStorage.setItem('auth_token', value);
    }
  }

  get accessToken(): string | null {
    return localStorage.getItem('access_token');
  }
  set accessToken(value: string | null) {
    if (value === null) {
      localStorage.removeItem('access_token');
    } else {
      localStorage.setItem('access_token', value);
    }
  }

  get refreshToken(): string | null {
    return localStorage.getItem('refresh_token');
  }
  set refreshToken(value: string | null) {
    if (value === null) {
      localStorage.removeItem('refresh_token');
    } else {
      localStorage.setItem('refresh_token', value);
    }
  }

  public roleCheck(roleSeqOrRoleCodeOrRoleNameList: string[]): boolean {
    return this.roleAccessList.some(
      role =>
        roleSeqOrRoleCodeOrRoleNameList.includes(role.roleSeq?.toLowerCase()) ||
        roleSeqOrRoleCodeOrRoleNameList.includes(role.roleSeq?.toUpperCase()) ||
        roleSeqOrRoleCodeOrRoleNameList.includes(role.roleCode?.toLowerCase()) ||
        roleSeqOrRoleCodeOrRoleNameList.includes(role.roleCode?.toUpperCase()) ||
        roleSeqOrRoleCodeOrRoleNameList.includes(role.roleName?.toLowerCase()) ||
        roleSeqOrRoleCodeOrRoleNameList.includes(role.roleName?.toUpperCase())
    );
  }

  public hasJurisdiction = async (jurisdiction: string) => {
    return hasJurisdiction(this, jurisdiction);
  };
  public hasRole = async (role: string) => hasRole(this, role);

  constructor(data: Partial<User> | null, options: { [key: string]: any } = {}) {
    Object.assign(this, data);
    Object.keys(options).forEach(key => {
      if (key in this) {
        (this as any)[key] = options[key];
      }
    });
  }
}
