export const MEAction = {
  Constants: {
    Disposition: {
      Accepted: '08737538-FE47-450A-AA2A-5D37E95066EA',
      Release: '98147AAB-5A4E-45D5-82AE-3B535490DD04',
      StorageOnly: 'FBCD2E47-F946-4968-A978-97F4361C7EB5',
      Transfer: 'FA7AB1B8-93BA-4080-8EAC-470CF39C4324',
      Facilitated_Release: '04E71EEB-2D4A-4F6E-9CB4-2D600CFC71B5',
      MAID: '4EA69FE1-FA79-4616-A904-A97567488822',
      Record_Review: '3D66C680-603B-46C7-8375-BDB8B4E0470E',
    },
    DispositionType: {
      Admitted_To_Morgue: 'DAED0D9C-891A-45DE-8496-29E0DC71350A',
      Record_Review_Only: '1994898A-1289-42BF-871A-3629ECD3637E',
      Facilitated_External_Exam: '7F58D4CB-C3C7-44AF-9CB5-AB7244A4D639',
    },
    TransferReason: {
      Other: '372873FE-0757-4F92-A1DC-975401E137E2',
      Originated_In_Transferred_To_Jurisdiction: '1D9EEF67-4E82-4C06-A1B9-E931CC327F06',
    },
    MAiD: {
      Prescription: {},
      NotificationType: {
        Other: '6CB2CFB9-9B8C-48ED-BB08-24334E455B6E',
      },
      EducationLevel: {
        Other: '42158F38-9F57-48CF-B5D0-FCF377BFDF43',
      },
      UnderlyingIllness: {
        Other: '42158F37-9F57-48CF-B5D0-FCF377BFDF42',
      },
    },
  },
};
