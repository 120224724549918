import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
    Typography,
    OutlinedInput,
    FormHelperText,
    Alert,
    InputAdornment,
    IconButton,
    Link,
    Select,
    MenuItem,
    useMediaQuery,
    InputLabel
} from '@mui/material';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import SearchIcon from '@mui/icons-material/Search';
// import AnimateButton from 'ui-component/extended/AnimateButton';
import FormControl from '@mui/material/FormControl';

const Filter = ({ rows, setRowsToShow }) => {
    const [activeInput, setActiveInput] = useState('Active');

    const onFilter = () => {
        if (activeInput === 'all') {
            setRowsToShow(rows);
        } else {
            setRowsToShow(() => {
                return rows.filter((row) => row.status === activeInput);
            });
        }
    };

    useEffect(() => {
        onFilter();
    }, [activeInput]);

    return (
        <>
            <Formik
                initialValues={{
                    isActive: 'Active'
                }}
                validationSchema={Yup.object().shape({
                    isActive: Yup.string().nullable()
                })}
                onSubmit={onFilter}
            >
                {({ errors, handleBlur, handleSubmit, isSubmitting, touched, values, handleChange }) => (
                    <>
                        <form>
                        <Grid container spacing={2} pb={1}>
                            <Grid item lg={6} md={6} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="isActive"
                                        onChange={(e) => {
                                            setActiveInput(e.target.value);
                                        }}
                                        value={activeInput}
                                        sx={{ display: 'inline' }}                                    >
                                        {' '}
                                        <FormControlLabel value="Active" control={<Radio />} label="Active Only" />{' '}
                                        <FormControlLabel value="Inactive" control={<Radio />} label="Inactive Only" />{' '}
                                        <FormControlLabel value="all" control={<Radio />} label="All" />{' '}
                                    </RadioGroup>

                                    <Box sx={{ marginLeft: 2, display: 'inline-block' }}></Box>
                                </Grid>
                            </Grid>
                        </form>
                    </>
                )}
            </Formik>
        </>
    );
};
export default Filter;
