import React from 'react'
import InputComponent from '../../../../components/AdministrationModules/InputComponent';
import SelectComponent from '../../../../components/AdministrationModules/SelectComponent';
import MultiSelectComponent from '../../../../components/AdministrationModules/MultiSelectComponent';
import DatePickerComponent from '../../../../components/AdministrationModules/DatePickerComponent';

const ControlComponents = ({control, ...rest}) => {
    switch(control) { 
        case 'input': return <InputComponent {...rest}/>
        case 'select': return <SelectComponent {...rest}/>
        case 'multiSelect': return <MultiSelectComponent {...rest}/>
        case 'date': return <DatePickerComponent {...rest}/>
        case 'radio': 
        case 'checkbox':
        default: return null
    }
}

export default ControlComponents
