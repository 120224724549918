import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  FormGroup,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Container } from '@mui/system';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router';
import { getAdminData, saveAdminData } from '../../../../../../services/utility-api';
import InputLabel from '@mui/material/InputLabel';
import ContactData from '../../../../../../components/AdministrationModules/ContactData/ContactView';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { referenceTypeFieldLengthChecks } from '../../referenceTypeFieldLengthChecks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const AddEdit = ({
  editObj,
  isCode,
  isCounty,
  referenceMasterTableName,
  maxOrder,
  setIsAddEditOn,
  getAllData,
  countyNames,
  requestedProcedureListMapping,
  allRow,
  referencePermissions
}) => {
  const id = '00000000-0000-0000-0000-000000000000';
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);

  const getRequestedProcedureList = async () => {
    const response = await getAdminData('RequestedProcedureList');
  };

  useEffect(() => { }, [editObj]);

  const onSave = async (values) => {
    // make api call to save add data
    let contacts = [];
    if (values?.contactItem)
      values?.contactItem.forEach((item, index) => {
        if (item.contactItemDetails) {
          contacts.push(item);
        }
      });
    if (editObj?.contactItem)
      editObj.contactItem.map((contact) => {
        if (
          contact.contactItemSeq != id &&
          contacts.filter(
            (item) => item.contactItemSeq == contact.contactItemSeq
          ).length == 0
        ) {
          contacts.push({
            ...contact,
            isActive: false,
            status: 'InActive',
          });
        }
      });
    try {

      let personData = {
        contactItem: contacts,
        address: values?.address ?? null,
        caseWorksheetSeq: values?.caseWorksheetSeq ?? id,
        fdnylicenseNo: values?.fdnylicenseNo ?? '',
        licenseNo: values?.licenseNo ?? '',
        personContactInfoSeq: values?.personContactInfoSeq ?? id,
        personFirstName: values?.personFirstName ?? '',
        personGenderCode: values?.personGenderCode ?? '',
        personLastName: values?.personLastName ?? '',
        personMaidenName: values?.personMaidenName ?? '',
        personMaidenName: values?.personMaidenName ?? '',
        personOtherRace: values?.personOtherRace ?? '',
        personRaceSeq: values?.personRaceSeq ?? id,
        personSalutationSeq: values?.personSalutationSeq ?? id,
        personSeq: values?.personSeq ?? id,
        personSuffix: values?.personSuffix ?? '',
        personTitle: values?.personTitle ?? ''
      };
      const response = await saveAdminData({
        values: {
          ...values,
          [referenceMasterTableName[0].toLowerCase() +
            referenceMasterTableName.slice(1) +
            'Seq']:
            values[
            referenceMasterTableName[0].toLowerCase() +
            referenceMasterTableName.slice(1) +
            'Seq'
            ] || id,
          contactItem: contacts,
          person: personData,
          address: values?.address ?? null,
          caseWorksheetSeq: values?.caseWorksheetSeq ?? id,
          contactItem: contacts,
          fdnylicenseNo: values?.fdnylicenseNo ?? '',
          licenseNo: values?.licenseNo ?? '',
          personContactInfoSeq: values?.personContactInfoSeq ?? id,
          personFirstName: values?.personFirstName ?? '',
          personGenderCode: values?.personGenderCode ?? '',
          personLastName: values?.personLastName ?? '',
          personMaidenName: values?.personMaidenName ?? '',
          personMaidenName: values?.personMaidenName ?? '',
          personOtherRace: values?.personOtherRace ?? '',
          personRaceSeq: values?.personRaceSeq ?? id,
          personSalutationSeq: values?.personSalutationSeq ?? id,
          personSeq: values?.personSeq ?? id,
          personSuffix: values?.personSuffix ?? '',
          personTitle: values?.personTitle ?? ''
        },
        tableName: referenceMasterTableName,
      });
      if (response.status == 0) {
        setTimeout(() => {
          setIsAddEditOn(false);
          getAllData();
        }, 1700);
      }
      setDialogContents(response.message);
      setOpenApiDialogBox(true);

    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={10} mb={2} mt={2}>
          <Typography variant='h5'>
            <strong>
              {Object.keys(editObj).length ? 'Modify ' : 'Enter New '}{' '}
              {referenceMasterTableName
                .split('')
                .map((alpha) => {
                  if (alpha <= 'Z') return ' ' + alpha;
                  return alpha;
                })
                .join('')
                .trim()}
            </strong>
          </Typography>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          ...editObj,

          [referenceMasterTableName[0].toLowerCase() +
            referenceMasterTableName.slice(1) +
            'Order']: editObj[
              referenceMasterTableName[0].toLowerCase() +
              referenceMasterTableName.slice(1) +
              'Order'
            ]
              ?
              Number(
                editObj[
                referenceMasterTableName[0].toLowerCase() +
                referenceMasterTableName.slice(1) +
                'Order'
                ]
              )
              : maxOrder + 10,
        }}
        validationSchema={Yup.object().shape({
          personLastName: Yup.string()
            .required(`Last name is required`)
            .max(40, 'Last name length can not be more than 40')
            .nullable(),
          requestedProcedureListSeq: Yup.string()
            .required('Requested Procedure Name is Required')
            .nullable(),
          [referenceMasterTableName[0].toLowerCase() +
            referenceMasterTableName.slice(1) +
            'Order']: Yup.number()
              .required('List Order is Required')
              .max(99999, 'List Order can not be more than 99999')
              .nullable(),
          countySeq: isCounty
            ? Yup.string().required('Jurisdiction is Required').nullable()
            : Yup.string().nullable(),
          contactItem: Yup.array()
            .of(
              Yup.object().shape({
                contactItemTypeSeq: Yup.string().required(
                  'Contact type is required'
                ),
                contactItemDetails: Yup.string().required(
                  'Contact Details is required'
                ),
              })
            )
            .min(1, 'Contact is a required field'),
        })}
        onSubmit={onSave}
      >
        {({
          errors,
          handleBlur,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          handleChange,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {isCounty && (
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth
                      required focused sx={{ minWidth: '88%' }}>
                      <InputLabel htmlFor='countySeq' id='countySeq'>
                        Jurisdiction
                      </InputLabel>
                      <Select
                        onChange={handleChange}
                        variant='outlined'
                        label='Jurisdiction'
                        focused
                        displayEmpty
                        size='small'
                        name={`countySeq`}
                        value={values?.countySeq || ''}
                      >
                        {Object.keys(countyNames).map((item, z) => {
                          return (
                            <MenuItem
                              key={countyNames[item].countySeq}
                              value={countyNames[item].countySeq}
                              sx={{
                                marginLeft:
                                  countyNames[item].countyName ==
                                    'New Jersey' ||
                                    countyNames[item].countyName ==
                                    'NJ State Case' ||
                                    countyNames[item].countyName == 'MAiD Case'
                                    ? ''
                                    : '15px',
                              }}
                            >
                              {countyNames[item].countyName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <p style={{ color: '#D32F2F', fontSize: '0.75rem' }}>
                        {errors?.countySeq}
                      </p>
                      {/* <ErrorMessage
                        name={'countySeq'}
                        render={(msg) => (
                          <FormHelperText error>{msg}</FormHelperText>
                        )}
                      /> */}
                    </FormControl>
                  </Grid>
                )}

                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormControl fullWidth
                    required focused sx={{ minWidth: '88%' }}>
                    <InputLabel
                      htmlFor='requestedProcedureListSeq'
                      id='requestedProcedureListSeq'
                    >
                      Requested Procedure Name
                    </InputLabel>
                    <Select
                      onChange={handleChange}
                      variant='outlined'
                      label='Requested Procedure Name'
                      focused
                      displayEmpty
                      size='small'
                      name={`requestedProcedureListSeq`}
                      value={values?.requestedProcedureListSeq || ''}
                    >
                      {Object.keys(requestedProcedureListMapping).map(
                        (item, z) => {
                          return (
                            <MenuItem
                              key={
                                requestedProcedureListMapping[item]
                                  .requestedProcedureListSeq
                              }
                              value={
                                requestedProcedureListMapping[item]
                                  .requestedProcedureListSeq
                              }
                            >
                              {
                                requestedProcedureListMapping[item]
                                  .requestedProcedureListName
                              }
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                    <p style={{ color: '#D32F2F', fontSize: '0.75rem' }}>
                      {errors?.requestedProcedureListSeq}
                    </p>
                    {/* <ErrorMessage
                      name={'requestedProcedureListSeq'}
                      render={(msg) => (
                        <FormHelperText error>{msg}</FormHelperText>
                      )}
                    /> */}
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    error
                    required
                    component='fieldset'
                    variant='standard'
                  >
                    <TextField
                      required
                      focused
                      id='outlined-basic'
                      defaultValue={''}
                      label='Last Name'
                      variant='outlined'
                      name='personLastName'
                      onInput={(e) => {
                        e.target.value = e.target.value.slice(0, 40);
                      }}
                      value={values['personLastName']}
                      onChange={handleChange}
                    />
                    <p style={{ color: '#D32F2F', fontSize: '0.75rem' }}>
                      {errors?.personLastName}
                    </p>
                    {/* <ErrorMessage
                      name={'personLastName'}
                      render={(msg) => (
                        <FormHelperText error>{msg}</FormHelperText>
                      )}
                    /> */}
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    error
                    component='fieldset'
                    variant='standard'
                  >
                    <TextField
                      focused
                      id='outlined-basic'
                      defaultValue={''}
                      label='Suffix'
                      variant='outlined'
                      name='personSuffix'
                      onInput={(e) => {
                        e.target.value = e.target.value.slice(0, 5);
                      }}
                      value={values['personSuffix']}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name={'personSuffix'}
                      render={(msg) => (
                        <FormHelperText error>{msg}</FormHelperText>
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    error
                    component='fieldset'
                    variant='standard'
                  >
                    <TextField
                      focused
                      id='outlined-basic'
                      defaultValue={''}
                      label='First Name'
                      variant='outlined'
                      name='personFirstName'
                      onInput={(e) => {
                        e.target.value = e.target.value.slice(0, 30);
                      }}
                      value={values['personFirstName']}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    error
                    component='fieldset'
                    variant='standard'
                  >
                    <TextField
                      focused
                      id='outlined-basic'
                      defaultValue={''}
                      label='Middle Name'
                      variant='outlined'
                      name='personMiddleName'
                      onInput={(e) => {
                        e.target.value = e.target.value.slice(0, 15);
                      }}
                      value={values['personMiddleName']}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name={'personMiddleName'}
                      render={(msg) => (
                        <FormHelperText error>{msg}</FormHelperText>
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    error
                    required
                    component='fieldset'
                    variant='standard'
                  >
                    <TextField
                      required
                      focused
                      id='outlined-basic'
                      defaultValue={''}
                      label='List Order'
                      variant='outlined'
                      name={
                        referenceMasterTableName[0].toLowerCase() +
                        referenceMasterTableName.slice(1) +
                        'Order'
                      }
                      type='number'
                      value={
                        values[
                        referenceMasterTableName[0].toLowerCase() +
                        referenceMasterTableName.slice(1) +
                        'Order'
                        ]
                      }
                      onChange={handleChange}
                      disabled={
                        values[
                        referenceMasterTableName[0].toLowerCase() +
                        referenceMasterTableName.slice(1) +
                        'Name'
                        ] == 'Other' ||
                        values[
                        referenceMasterTableName[0].toLowerCase() +
                        referenceMasterTableName.slice(1) +
                        'Name'
                        ] == 'Unknown'
                      }
                    />
                    <ErrorMessage
                      name={
                        referenceMasterTableName[0].toLowerCase() +
                        referenceMasterTableName.slice(1) +
                        'Order'
                      }
                      render={(msg) => (
                        <FormHelperText error>{msg}</FormHelperText>
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={12}>
                  <FormControl
                    fullWidth
                    error
                    required
                    component='fieldset'
                    variant='standard'
                    sx={{ paddingTop: '0px' }}
                  >
                    <ContactData
                      contactItem={values.contactItem || []}
                      handleChange={(data) =>
                        handleChange({
                          target: {
                            value: data,
                            name: 'contactItem',
                          },
                        })
                      }
                    />
                    {typeof errors.contactItem == 'string' && (
                      <ErrorMessage
                        name='contactItem'
                        render={(msg) => (
                          <FormHelperText error>{msg}</FormHelperText>
                        )}
                      />
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Box>
                    <Button
                      variant='contained'
                      sx={{ marginTop: '7px' }}
                      onClick={handleSubmit}
                      disabled={!referencePermissions?.add}
                    >
                      {Object.keys(editObj) > 1 ? 'Update' : 'Save'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>

      <div className='modal'>
        <Dialog
          open={openApiDialogBox}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenApiDialogBox(false)}
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              {dialogContents}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <IconButton onClick={() => setOpenApiDialogBox(false)}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default AddEdit;
