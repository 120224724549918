import React from 'react';
import MainCard from './MainCard';

const AccessDenied = () => {
  return (
    <MainCard>
      <h4 style={{ color: 'red' }}>Access Denied</h4>
    </MainCard>
  );
};

export default AccessDenied;
