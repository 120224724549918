import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { FormControlLabel, Grid, TextField, Select, MenuItem, InputLabel } from '@mui/material';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';

const Filter = ({ rows, setRowsToShow, county, setStatusFilter, setNameFilter, setCountyFilter }) => {
    const onFilter = (values) => {
        setStatusFilter(values?.isActive);
        setNameFilter(values.labName);
        setCountyFilter(values.county);
        let newRows = rows;
        if (values.labName) {
            newRows = newRows.filter((row) => row['labName'].toLowerCase().includes(values.labName.toLowerCase()));
        }

        if (values.county) {
            newRows = newRows.filter((row) => row.labOnCountySeqs.includes(values.county));
        }
        if (values?.isActive == 'all') setRowsToShow(newRows);
        else if (values?.isActive == 'Active') setRowsToShow(newRows.filter((row) => row['status'].toLowerCase() == 'active'));
        else setRowsToShow(newRows.filter((row) => row['status'].toLowerCase() != 'active'));
    };

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <>
            <Formik
                initialValues={{
                    isActive: 'Active'
                }}
                validationSchema={Yup.object().shape({
                    isActive: Yup.string().nullable()
                })}
                onSubmit={onFilter}
            >
                {({ errors, handleBlur, handleSubmit, isSubmitting, touched, values, handleChange }) => (
                    <>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} pb={1}>
                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="labName"
                                            label="Lab Name"
                                            variant="outlined"
                                            name="labName"
                                            defaultValue=""
                                            margin="normal"
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleSubmit();
                                            }}
                                            value={values.labName}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item lg={3} md={3} sm={6} xs={12} sx={{ mt: 2 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="countyLabel">Jurisdiction</InputLabel>
                                        <Select
                                            sx={{ minWidth: '120px' }}
                                            labelId="countyLabel"
                                            id="countySelect"
                                            name="county"
                                            value={values.county}
                                            label="Jurisdiction"
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleSubmit();
                                            }}
                                        >
                                            <MenuItem value={''} key={'all'}>
                                                --Select One--
                                            </MenuItem>
                                            {Object.keys(county).map((row) => {
                                                return (
                                                    <MenuItem value={county[row].countySeq} key={county[row].countySeq}>
                                                        {county[row].countyName}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
                                    <RadioGroup
                                        sx={{ display: 'inline' }}
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="isActive"
                                        value={values.isActive}
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleSubmit();
                                        }}
                                        defaultValue="Active"
                                    >
                                        {' '}
                                        <FormControlLabel value="Active" control={<Radio />} label="Active Only" />{' '}
                                        <FormControlLabel value="Inactive" control={<Radio />} label="Inactive Only" />{' '}
                                        <FormControlLabel value="all" control={<Radio />} label="All" />{' '}
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </form>
                    </>
                )}
            </Formik>
        </>
    );
};
export default Filter;
