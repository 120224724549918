import { Person } from '../../types/Person.interface';
import { Role } from '../../types/Role.interface';

const { REACT_APP_API_URL } = process.env;

export interface CaseAssignedUser {
  userSeq: string;
  userPrimaryEmail: string | null;
  userPrimaryPhoneNumber: string | null;
  role: Role;
  person: Person;
}

export interface CaseAssignedList {
  caseSeq: string;
  assignedPathologist: CaseAssignedUser | null;
  assignedInvestigator: CaseAssignedUser | null;
  assignedMorgueTechs: CaseAssignedUser[];
  administrativeAssistants: CaseAssignedUser[];
}

async function getCaseAssignmentList(
  acccessToken: string,
  caseid: string
): Promise<CaseAssignedList> {
  const url = `${REACT_APP_API_URL}getCaseAssignedList/${caseid}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${acccessToken}`,
    },
  });

  return await response.json();
}

async function assignPathologist(
  acccessToken: string,
  caseId: string,
  pathologistUserSeq: string | null
): Promise<{ message: string; data: CaseAssignedUser | null }> {
  const url = `${REACT_APP_API_URL}case/assignPathologist`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${acccessToken}`,
    },
    body: JSON.stringify({
      caseId,
      pathologistUserSeq,
    }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'Failed to assign pathologist');
  }

  return await response.json();
}

async function assignInvestigator(
  acccessToken: string,
  caseId: string,
  investigatorUserSeq: string | null
): Promise<{ message: string }> {
  const url = `${REACT_APP_API_URL}case/assignInvestigator`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${acccessToken}`,
    },
    body: JSON.stringify({
      caseId,
      investigatorUserSeq,
    }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'Failed to assign investigator');
  }

  return await response.json();
}

async function assignMorgueTechs(
  accessToken: string,
  caseId: string,
  morgueTechUserSeqs: string[]
): Promise<CaseAssignedUser[]> {
  const url = `${REACT_APP_API_URL}cases/${caseId}/morgue-techs`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      morgueTechUserSeqs,
    }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'Failed to assign morgue techs');
  }

  return await response.json();
}

async function getAssignedMorgueTechs(
  accessToken: string,
  caseId: string
): Promise<CaseAssignedUser[]> {
  const url = `${REACT_APP_API_URL}cases/${caseId}/morgue-techs`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || `Failed to get morgue techs for case ${caseId}`);
  }

  return await response.json();
}

async function assignAdministrativeAssistants(
  accessToken: string,
  caseId: string,
  administrativeAssistantUserSeqs: string[]
): Promise<CaseAssignedUser[]> {
  const url = `${REACT_APP_API_URL}cases/${caseId}/administrative-assistants`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      administrativeAssistantUserSeqs,
    }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'Failed to assign administrative assistants');
  }

  return await response.json();
}

export const CaseAssignmentManagerAPI = {
  getCaseAssignmentList,
  getAssignedMorgueTechs,
  assignPathologist,
  assignInvestigator,
  assignMorgueTechs,
  assignAdministrativeAssistants,
};

// export function useCaseAssignmentManagerAPI(caseId: string) {
//   const auth = useAuth();

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState<Error | null>(null);

//   const fetchCaseAssignmentList = async (accessToken: string, caseId: string) => {
//     try {
//       setError(null);
//       setLoading(true);
//       const list = await CaseAssignmentManagerAPI.getCaseAssignmentList(accessToken, caseId);
//       formik.setValues(list);
//     } catch (error) {
//       // @ts-ignore
//       if (error instanceof Error) {
//         setError(error);
//       }
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchCaseAssignmentList(auth.user?.accessToken!, caseId);
//   }, [caseId]);

//   const pathologistOptions = useOptions('pathologists');
//   const morgueTechOptions = useOptions('morgue techs');

//   const formik = useFormik<CaseAssignedList>({
//     initialValues: {
//       caseSeq: '',
//       assignedPathologist: null,
//       assignedInvestigator: null,
//       assignedMorgueTechs: [],
//       administrativeAssistants: [],
//     },
//     onSubmit: async values => {
//       try {
//         if (values.assignedPathologist !== formik.initialValues.assignedPathologist) {
//           await CaseAssignmentManagerAPI.assignPathologist(
//             auth.user?.accessToken!,
//             caseId,
//             values.assignedPathologist?.userSeq || null
//           );
//         }

//         if (
//           values.assignedMorgueTechs.map(tech => tech.userSeq).join(',') !==
//           formik.initialValues.assignedMorgueTechs.map(tech => tech.userSeq).join(',')
//         ) {
//           await CaseAssignmentManagerAPI.assignMorgueTechs(
//             auth.user?.accessToken!,
//             caseId,
//             values.assignedMorgueTechs.map(tech => tech.userSeq)
//           );
//         }

//         // Add other API calls for assignedInvestigator and assignedAdministrativeAssistants if needed
//       } catch (error) {
//         console.error(error);
//       }
//     },
//   });

//   return {
//     pathologist: {
//       loading: pathologistOptions.loading || loading,
//       saving: formik.isSubmitting,
//       errors: formik.errors.assignedPathologist || [],
//       stage: (pathologistUserSeq: string | null) =>
//         formik.setFieldValue('assignedPathologist', pathologistUserSeq),
//       data: formik.values.assignedPathologist,
//       options: pathologistOptions.options,
//     },
//     mourgeTechs: {
//       loading: morgueTechOptions.loading || loading,
//       saving: formik.isSubmitting,
//       errors: formik.errors.assignedMorgueTechs || [],
//       stage: (morgueTechUserSeqs: string[]) =>
//         formik.setFieldValue(
//           'assignedMorgueTechs',
//           morgueTechUserSeqs.map(seq => ({ userSeq: seq }))
//         ),
//       data: formik.values.assignedMorgueTechs,
//       options: morgueTechOptions.options,
//     },
//     save: formik.submitForm,
//   };
// }

/**
 * resource/
 *  ...states
 *  ...actions
 */
