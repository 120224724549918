import { DeleteRounded, DriveFolderUploadRounded, RefreshRounded } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { useLocation } from 'react-router';
import { useAuth } from '../utils/auth/AuthService';

const { REACT_APP_API_URL } = process.env;

const ACCEPTED_FILE_TYPES = {
  'image/*': ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.webp'],
  'application/pdf': ['.pdf'],
};

const ALLOWED_FILE_TYPES_TEXT = 'Allowed file types: PNG, JPG, JPEG, GIF, BMP, WEBP, PDF';
const MAX_TOTAL_SIZE = 10 * 1024 * 1024; // 10MB in bytes

interface FeedbackDialogProps {
  open: boolean;
  onClose: () => void;
  dialogProps?: DialogProps;
}

export function FeedbackDialog({ open, onClose, dialogProps }: FeedbackDialogProps) {
  const auth = useAuth();
  const location = useLocation();
  const [body, setBody] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [sending, setSending] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    onClose();
  };

  const getTotalSize = (files: File[]): number => {
    return files.reduce((total, file) => total + file.size, 0);
  };

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
      const newTotalSize = getTotalSize([...attachments, ...acceptedFiles]);

      if (newTotalSize > MAX_TOTAL_SIZE) {
        setError('Total file size exceeds 10MB limit. Please remove some files.');
        return;
      }

      setAttachments(prev => [...prev, ...acceptedFiles]);

      if (fileRejections.length > 0) {
        setError('Some files were rejected. Only images and PDFs are allowed.');
      }
    },
    [attachments]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ACCEPTED_FILE_TYPES,
  });

  const removeAttachment = (index: number) => {
    setAttachments(attachments.filter((_, i) => i !== index));
    setError(null); // Clear error when removing files
  };

  const resetForm = () => {
    setBody('');
    setAttachments([]);
    setSending(false);
    setError(null);
  };

  const sendEmail = async () => {
    const formData = new FormData();
    formData.append('Subject', `Feedback from ${auth.user?.userName}`);
    formData.append('Body', body);
    formData.append('ReactRouterLocation', JSON.stringify(location));

    attachments.forEach(file => {
      formData.append('Attachments', file);
    });

    try {
      setSending(true);
      setError(null);
      const response = await fetch(`${REACT_APP_API_URL}sendfeedbackemail`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.user.accessToken}`,
        },
        body: formData,
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.errorMessage || 'An error occurred while sending feedback');
      }

      if (result.successfulSends === 1) {
        setFeedbackSent(true);
        resetForm();
        onClose();
      } else if (result.successfulSends === 0 && result.errorMessage) {
        throw new Error(result.errorMessage + ' Please try again later');
      } else {
        throw new Error('Failed to send feedback. Please try again.');
      }
    } catch (e) {
      setError(e instanceof Error ? e.message : 'An unknown error occurred');
      onClose();
    } finally {
      setSending(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        maxWidth='md'
        fullWidth
        onClose={handleClose}
        {...dialogProps}
      >
        <DialogTitle>Send feedback</DialogTitle>
        <DialogContent>
          <Box component='form' sx={{ width: '100%' }}>
            <InputLabel htmlFor='feedback-body'>What's going on?</InputLabel>
            <TextField
              id='feedback-body'
              placeholder='Example: When I try and save a case, nothing happens. CaseID: 12-345-6781'
              value={body}
              onChange={e => setBody(e.target.value)}
              multiline
              rows={6}
              fullWidth
              margin='normal'
              variant='outlined'
            />
            <Box sx={{ mt: 2 }}>
              <div
                {...getRootProps()}
                style={{
                  border: isDragActive
                    ? `2px dashed ${theme.palette.primary.main}`
                    : '2px dashed #cccccc',
                  borderRadius: '4px',
                  padding: '20px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease-in-out',
                  boxShadow: isDragActive ? `0 0 10px ${theme.palette.primary.main}` : 'none',
                  backgroundColor: isDragActive ? 'rgba(63, 81, 181, 0.05)' : 'transparent',
                }}
              >
                <DriveFolderUploadRounded
                  fontSize='large'
                  sx={{
                    color: isDragActive ? theme.palette.primary.main : '#aaa',
                    height: 50,
                    width: 50,
                    marginBottom: '1rem',
                    transition: 'color 0.3s ease-in-out',
                  }}
                />
                <input {...getInputProps()} />
                <Typography
                  variant='body1'
                  sx={{
                    color: isDragActive ? theme.palette.primary.main : 'inherit',
                    fontWeight: isDragActive ? 'bold' : 'normal',
                  }}
                >
                  {isDragActive
                    ? 'Drop here to include in your feedback'
                    : 'Drag & drop images or PDFs here, or click to select files'}
                </Typography>
                <Typography variant='body2' color='textSecondary' sx={{ mt: 1 }}>
                  {ALLOWED_FILE_TYPES_TEXT}
                </Typography>
                <Typography variant='body2' color='textSecondary' sx={{ mt: 1 }}>
                  Max total size: 10MB
                </Typography>
              </div>
            </Box>

            {attachments.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant='subtitle1' color='error' fontWeight='bold'>
                  Ensure all personally identifiable information is redacted from your attachments
                  or screenshots
                </Typography>
                <Typography variant='subtitle1'>
                  Attachments (Total size: {(getTotalSize(attachments) / 1024 / 1024).toFixed(2)}{' '}
                  MB):
                </Typography>
                <List>
                  {attachments.map((file, index) => (
                    <ListItem
                      key={index}
                      secondaryAction={
                        <IconButton
                          edge='end'
                          aria-label='delete'
                          onClick={() => removeAttachment(index)}
                        >
                          <DeleteRounded />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={file.name}
                        secondary={`${(file.size / 1024 / 1024).toFixed(2)} MB`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', px: 2, pb: 2 }}>
          <Button
            onClick={resetForm}
            variant='text'
            color='inherit'
            size='small'
            startIcon={<RefreshRounded />}
          >
            Reset
          </Button>
          <Box>
            <Button
              onClick={handleClose}
              variant='outlined'
              color='error'
              size='large'
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              onClick={sendEmail}
              variant='contained'
              color='primary'
              size='large'
              disabled={sending || body.length <= 0 || getTotalSize(attachments) > MAX_TOTAL_SIZE}
            >
              {sending ? 'Sending...' : 'Send'}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={feedbackSent}
        autoHideDuration={5000}
        onClose={() => setFeedbackSent(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert variant='filled' severity='success'>
          <AlertTitle>Thank you for your feedback</AlertTitle>
          It will be reviewed soon
        </Alert>
      </Snackbar>

      <Snackbar
        open={error !== null}
        autoHideDuration={5000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert variant='filled' severity='error'>
          <AlertTitle>Error while sending feedback</AlertTitle>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
}
