function getTopNavBarHeight() {
  const height = window.innerHeight;
  return height / (12 + height / 4.25);
}

const dimensions = {
  app_bar_height: `${getTopNavBarHeight()}rem`,
  drawer_width: 'max-content',
  avatar_size: 32,
};

export default dimensions;
