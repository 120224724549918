import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  FormHelperText,
  TextField,
  IconButton,
} from '@mui/material';
import { Container } from '@mui/system';
import * as Yup from 'yup';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router';
import { saveAdminData } from '../../../../../../services/utility-api';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import { referenceTypeFieldLengthChecks } from '../../referenceTypeFieldLengthChecks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const AddEdit = ({
  editObj,
  isCode,
  isCounty,
  referenceMasterTableName,
  maxOrder,
  setIsAddEditOn,
  getAllData,
  countyNames,
  referencePermissions
}) => {
  const navigate = useNavigate();
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  useEffect(() => { }, [editObj]);

  const initialValues = {
    ...editObj,
    itemName: editObj?.itemName || '',
    itemOrder: editObj?.itemOrder
      ? Number(editObj.itemOrder)
      : maxOrder + 10,
    isNotShownInMorgue: editObj?.isNotShownInMorgue ?? false
  };
  const validationSchema = Yup.object({
    itemOrder: Yup.number().required('List Order is Required').nullable(),

    itemName: Yup.string()
      .required('Item Name is required')
      .max(
        referenceTypeFieldLengthChecks[`itemName`],
        `Item name can not be more than ${referenceTypeFieldLengthChecks['itemName']}`
      )
      .nullable(),
    itemOrder: Yup.number()
      .required('Item Order is Required')
      .max(99999, 'Item Order can not be more than 99999')
      .nullable(),
  });
  const onSubmit = (values) => {
    onSave(values);
  };

  const onSave = async (values) => {
    const id = '00000000-0000-0000-0000-000000000000';

    try {
      const response = await saveAdminData({
        values: {
          ...values,
          isNotShownInMorgue: values.isNotShownInMorgue,
          [referenceMasterTableName[0].toLowerCase() +
            referenceMasterTableName.slice(1) +
            'Seq']:
            values[
            referenceMasterTableName[0].toLowerCase() +
            referenceMasterTableName.slice(1) +
            'Seq'
            ] || id,
        },
        tableName: referenceMasterTableName,
      });
      if (response.status == 0) {
        setTimeout(() => {
          setIsAddEditOn(false);
          getAllData();
        }, 1700);
      }
      setDialogContents(response.message);
      setOpenApiDialogBox(true);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      {' '}
      <Grid item xs={12} mb={2}></Grid>
      <Grid elevation={3} spacing={2} sx={{ padding: '16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={10} mb={2} mt={2}>
            <Typography variant='h5'>
              <strong>
                {Object.keys(editObj).length ? 'Modify ' : 'Enter New '} Item
              </strong>
            </Typography>
          </Grid>
        </Grid>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            handleChange,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12} md={6} lg={6}>
                        <FormControl
                          error
                          required
                          component='fieldset'
                          variant='standard'
                        >
                          <TextField
                            required
                            focused
                            id='outlined-basic'
                            defaultValue={''}
                            label={
                              referenceMasterTableName
                                .split('')
                                .map((alpha) => {
                                  if (alpha <= 'Z') return ' ' + alpha;
                                  return alpha;
                                })
                                .join('')
                                .trim() + ' Name'
                            }
                            variant='outlined'
                            name={
                              referenceMasterTableName[0].toLowerCase() +
                              referenceMasterTableName.slice(1) +
                              'Name'
                            }
                            value={
                              values[
                              referenceMasterTableName[0].toLowerCase() +
                              referenceMasterTableName.slice(1) +
                              'Name'
                              ]
                            }
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name={
                              referenceMasterTableName[0].toLowerCase() +
                              referenceMasterTableName.slice(1) +
                              'Name'
                            }
                            render={(msg) => (
                              <FormHelperText error>{msg}</FormHelperText>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={6} xs={12} md={6} lg={6}>
                        <FormControl
                          error
                          required
                          component='fieldset'
                          variant='standard'
                        >
                          <TextField
                            required
                            focused
                            id='outlined-basic'
                            defaultValue={''}
                            label={
                              'List Order'
                            }
                            variant='outlined'
                            name={
                              referenceMasterTableName[0].toLowerCase() +
                              referenceMasterTableName.slice(1) +
                              'Order'
                            }
                            value={
                              values[
                              referenceMasterTableName[0].toLowerCase() +
                              referenceMasterTableName.slice(1) +
                              'Order'
                              ]
                            }
                            onChange={handleChange}
                            disabled={
                              values[
                              referenceMasterTableName[0].toLowerCase() +
                              referenceMasterTableName.slice(1) +
                              'Name'
                              ] == 'Other' ||
                              values[
                              referenceMasterTableName[0].toLowerCase() +
                              referenceMasterTableName.slice(1) +
                              'Name'
                              ] == 'Unknown'
                            }
                          />
                          <ErrorMessage
                            name={
                              referenceMasterTableName[0].toLowerCase() +
                              referenceMasterTableName.slice(1) +
                              'Order'
                            }
                            render={(msg) => (
                              <FormHelperText error>{msg}</FormHelperText>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={6} xs={12} md={6} lg={6}>
                        <FormGroup>
                          <FormControlLabel
                            name={'isNotShownInMorgue'}
                            checked={values.isNotShownInMorgue}
                            control={<Checkbox />}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            label='Is Not Shown In Morgue'
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Button
                            type='submit'
                            variant='contained'
                            sx={{ minWidth: '125px', marginTop: '7px' }}
                            onClick={handleSubmit}
                            disabled={!referencePermissions?.add}
                          >
                            {Object.keys(editObj) > 1 ? 'Update' : 'Save'}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
        <div className='modal'>
          <Dialog
            open={openApiDialogBox}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenApiDialogBox(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                {dialogContents}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <IconButton onClick={() => setOpenApiDialogBox(false)}>
                <CloseIcon />
              </IconButton>
            </DialogActions>
          </Dialog>
        </div>
      </Grid>
    </>
  );
};
export default AddEdit;
