import { Box, Button, Divider, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../../utils/auth/AuthService';
import { DecedentNameCell } from './DecedentNameCell';
import SiloDataGrid from '../../utils/components/SiloDataGrid';
import { format } from "date-fns";
import { GridColDef } from '@mui/x-data-grid-premium';
import { DashboardDataGridProps } from './useDashboardDataGridAPI.hook';

export function AvailableFileRequestsDataGrid({ data, isLoading }: DashboardDataGridProps) {
  const navigate = useNavigate();
  const [caseResults, setCaseResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const columns: GridColDef[] = [
    {
      field: 'caseid',
      headerName: 'CaseID',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      renderCell: params => {
        return (
          <Button
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
            }}
          >
            <b> {params.formattedValue} </b>
          </Button>
        );
      },
    },
    {
      field: 'decedentname',
      headerName: 'Decedent Name',
      width: 140,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      renderCell: params => <DecedentNameCell {...params} />,
    },
    {
      field: 'requestedDocumentName',
      headerName: 'File Requested',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
    {
      field: 'requested',
      headerName: 'Requested On',
      width: 115,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
    {
      field: 'reportType',
      headerName: 'Report Type (uploaded)',
      width: 220,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
    {
      field: 'reportUploaded',
      headerName: 'File Name',
      width: 220,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
  ];

  return (
    <SiloDataGrid
      loading={isLoading}
      listresults={data}
      columnsInput={columns}
      idcolumn='availableFileRequestSeq'
      gridheight={200}
      gridcolor='#e74c3c'
      title='Available File Requests'
      tooltip='This represents files that have been uploaded but not yet sent out to the requestor (no date sent). The case must also be closed in order for it to appear in this silo.'
      casecount
      rowsperpage={50}
      filterModel={undefined}
      columnsVisibility={undefined}
    />
  );
}
