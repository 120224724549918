import { GridColDef, GridTreeNodeWithRender } from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router';
import SiloDataGrid from '../../utils/components/SiloDataGrid';
import { useDashboard } from '../../views/Dashboard/DashboardContext';
import { DecedentNameCell } from './DecedentNameCell';

export interface Dashboard_AssignedDailyExamResponse {
  caseid: string;
  decedentname: string;
  examtype: string;
  examphysician: string;
  investigator: string;
}

export const AssignedDailyExamsDataGrid = ({
  data,
  isLoading = true,
}: {
  data: any[];
  isLoading: boolean;
}) => {
  const navigate = useNavigate();

  const { preferences, reloadSiloData } = useDashboard();
  const [reloading, setReloading] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        setReloading(true);
        await reloadSiloData('TODAYS_EXAMS');
      } finally {
        setReloading(false);
      }
    }, preferences.refreshSeconds * 1000);

    return () => clearInterval(intervalId);
  }, [reloadSiloData]);

  const handleCaseClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
  ) => {
    event.stopPropagation();
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const columns: GridColDef[] = [
    {
      field: 'caseid',
      headerName: 'CaseID',
      width: 90,
      editable: false,
      align: 'left',
      headerAlign: 'left',

      renderCell: params => {
        return (
          <Button
            color='primary'
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              handleCaseClick(event, params);
            }}
          >
            <b> {params.formattedValue} </b>
          </Button>
        );
      },
    },
    {
      field: 'decedentname',
      headerName: 'Decedent',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: params => <DecedentNameCell {...params} />,
    },
    {
      field: 'examtype',
      headerName: 'Exam Type',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'examphysician',
      headerName: 'Pathologist',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'investigator',
      headerName: 'MLDI',
      width: 140,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  return (
    <SiloDataGrid
      loading={isLoading}
      listresults={data ?? []}
      columnsInput={columns}
      idcolumn='caseid'
      gridheight={200}
      gridcolor='#9b59b6'
      title='Assigned Exams'
      tooltip='This represents the accepted/admitted Cases in your jurisdiction that have been assigned an Exam and a Pathologist, once the Exam has been completed it will no longer appear in this list '
      casecount
      rowsperpage={50}
      filterModel={undefined}
      columnsVisibility={undefined}
      headerClickUrl='/dailyexamlist' // new parameter for header click URL
      reloading={reloading}
      refreshSeconds={preferences.refreshSeconds}
    />
  );
};
