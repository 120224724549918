import { Box, Button, Grid } from '@mui/material';
import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import Chips from '../../../components/AdministrationModules/Chips';
import SecurityQuestionTab from '../../../components/AdministrationModules/SecurityQuestionComponent/SecurityQuestionTab';
import ApiNotification from '../../../components/DialogBox';
import authHeader from '../../../services/auth-header';
import { useAuth } from '../../../utils/auth/AuthService';

const YourSecurityQuestionView = () => {
  const guid = '00000000-0000-0000-0000-000000000000';
  const auth = useAuth();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [securityQuestionError, setSecurityQuestionError] = useState({});
  const [openApiDialogBox, setOpenApiDialogBox] = useState();
  const [dialogContents, setDialogContents] = useState();

  const populateSecurityQuestion = () => {
    let i = 0;
    let arr = [];
    while (i < 3) {
      arr.push({
        securityQuestion: '',
        securityQuestionAns: '',
        securityQuestionSeq: '',
        userToSecurityQuestionSeq: guid,
      });
      i++;
    }
    return arr;
  };

  const [securityQuestionsMap, setSecurityQuestionMap] = useState(populateSecurityQuestion());

  const initialValues = {
    securityQuestion: populateSecurityQuestion(),
  };

  const closeDialogBox = () => {
    setOpenApiDialogBox(false);
    if (user?.securityQuestionCount === 0) {
      localStorage.setItem(
        'user',
        JSON.stringify({
          ...user,
          securityQuestionCount: 3,
          isSecurityquestionSet: 'True',
        })
      );
      auth.authDispatch({ type: 'SET_AUTHENTICATED', payload: true });
      navigate('/');
    }
  };

  const isSecurityValidation = () => {
    const flattenSecurityStructure = Object.entries(securityQuestionsMap).map(
      ([key, value], index) => value?.securityQuestionSeq
    );
    return flattenSecurityStructure.some(function (item, idx) {
      return flattenSecurityStructure.indexOf(item) != idx;
    });
  };

  const isSecurityFiled = () => {
    const flattenSecurityStructure = Object.entries(securityQuestionsMap).map(
      ([key, value], index) => value?.securityQuestionSeq
    );
    return flattenSecurityStructure.some(item => item === '');
  };

  const [securityError, setSecurityError] = useState(false);

  var checkSecurityQuestionAns = values =>
    values?.securityQuestion?.some(item => item?.securityQuestionAns === '');

  const onSubmit = async (values, errors) => {
    if (!isSecurityFiled() && !isSecurityValidation() && !checkSecurityQuestionAns(values)) {
      const payload = {
        userSeq: user?.userSeq ?? guid,
        securityQuestion: values.securityQuestion,
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}SaveUserSecurityOuestion`,
          payload,
          { headers: authHeader() }
        );
        setOpenApiDialogBox(true);
        setDialogContents(response.data.message);
      } catch (error) {
        console.log('error', error);
      }
    } else {
      setSecurityError(true);
    }
  };

  const validationSchema = Yup.object({
    securityQuestion: Yup.array().of(
      Yup.object().shape({
        securityQuestionSeq: Yup.string().required('Security Question is required'),
        securityQuestionAns: Yup.string().required('Answer is required'),
      })
    ),
  });

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {formik => {
        return (
          <div style={{ margin: '75px 40px 0px 40px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                background: 'black',
                padding: '16px',
                borderRadius: '32px',
                margin: '10px 0px',
              }}
            >
              <Grid container spacing={2}>
                <Grid item lg={3} xs={6} md={6} sm={6}>
                  <Chips title='Security Question' location='/securityquestion' />
                </Grid>
              </Grid>
            </Box>
            <Form className='AddUser'>
              <SecurityQuestionTab
                securityQuestion={formik?.values?.securityQuestion || []}
                handleChange={data =>
                  formik?.handleChange({
                    target: {
                      value: data,
                      name: 'securityQuestion',
                    },
                  })
                }
                setSecurityQuestionError={err => setSecurityQuestionError(err)}
                setSecurityQuestionMap={setSecurityQuestionMap}
              />
              <Button
                variant='contained'
                style={{ float: 'right', margin: '20px 0px' }}
                onClick={() => onSubmit(formik?.values, formik?.errors)}
              >
                Update
              </Button>
              {openApiDialogBox ? (
                <ApiNotification
                  openApiDialogBox={openApiDialogBox}
                  closeDialogBox={closeDialogBox}
                  dialogContents={dialogContents || ''}
                />
              ) : null}
              {securityError ? (
                <ApiNotification
                  openApiDialogBox={securityError}
                  closeDialogBox={() => setSecurityError(false)}
                  dialogContents={
                    isSecurityFiled()
                      ? 'Please fill all three security questions and answers.'
                      : 'Duplicate security questions are not allowed.'
                  }
                />
              ) : null}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default YourSecurityQuestionView;
