// src/components/UserFeedbackView.tsx
import RefreshIcon from '@mui/icons-material/Refresh';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  AlertTitle,
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Snackbar,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../../../utils/auth/AuthService';
import CustomHeader from '../../../../utils/components/CustomHeader';

const { REACT_APP_API_URL } = process.env;

type FeedbackRecord = {
  feedbackSeq: string;
  feedbackSubject: string;
  feedbackBody: string;
  feedbackSubmittedOnDateTime: string;
  submittedByUserName: string;
  submittedByUserEmail: string;
  submittedByPersonFirstName: string;
  submittedByPersonLastName: string;
  isOpen: boolean;
  isActive: boolean;
  replySent: boolean;
  replySentSubject: string | null;
  replySentBody: string | null;
  replySentOnDateTime: string | null;
  replySentByUserName: string | null;
  replySentByPersonFirstName: string | null;
  replySentByPersonLastName: string | null;
};

type FilterStatus = 'all' | 'open' | 'closed';

type DraftReply = {
  feedbackSeq: string;
  subject: string;
  body: string;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
));

export function UserFeedbackView() {
  const auth = useAuth();
  const theme = useTheme();
  const [loadingResolve, setLoadingResolve] = useState<{ [key: string]: boolean }>({});

  const [feedbackLoading, setFeedbackLoading] = useState(true);
  const [feedbackRecords, setFeedbackRecords] = useState<FeedbackRecord[]>([]);
  const [filterStatus, setFilterStatus] = useState<FilterStatus>('open');
  const [replyStatus, setReplyStatus] = useState<'all' | 'replied' | 'unreplied'>('all');

  const [replyDialogOpen, setReplyDialogOpen] = useState(false);
  const [replyTo, setReplyTo] = useState<FeedbackRecord | null>(null);
  const [replySubject, setReplySubject] = useState('');
  const [replyBody, setReplyBody] = useState('');
  const [sendingReply, setSendingReply] = useState(false);

  const [supportEmailAddress, setSupportEmailAddress] = useState('');
  const [draftReplies, setDraftReplies] = useState<DraftReply[]>([]);

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'info',
  });

  const showSnackbar = (message: string, severity: 'success' | 'info' | 'warning' | 'error') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const fetchFeedback = async () => {
    setFeedbackLoading(true);
    try {
      const response = await fetch(`${REACT_APP_API_URL}getuserfeedback`, {
        headers: {
          Authorization: `Bearer ${auth.user.accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch feedback');
      }
      const feedback = await response.json();
      setFeedbackRecords(feedback);
    } catch (err) {
      console.error(err);
      showSnackbar('Failed to fetch feedback', 'error');
    } finally {
      setFeedbackLoading(false);
    }
  };

  useEffect(() => {
    fetchFeedback();
    fetchSupportEmailAddress();
  }, []);

  const handleStatusChange = async (feedbackSeq: string, isOpen: boolean) => {
    setLoadingResolve(prev => ({ ...prev, [feedbackSeq]: true }));
    try {
      const response = await fetch(`${REACT_APP_API_URL}updatefeedbackstatus/${feedbackSeq}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.user.accessToken}`,
        },
        body: JSON.stringify(!isOpen),
      });

      if (!response.ok) {
        throw new Error('Failed to update feedback status');
      }

      const updatedFeedback = await response.json();

      setFeedbackRecords(prevRecords =>
        prevRecords.map(record =>
          record.feedbackSeq === feedbackSeq
            ? { ...record, isOpen: updatedFeedback.isOpen }
            : record
        )
      );
      showSnackbar('Feedback status updated successfully', 'success');
    } catch (err) {
      console.error(err);
      showSnackbar('Failed to update feedback status', 'error');
    } finally {
      setLoadingResolve(prev => ({ ...prev, [feedbackSeq]: false }));
    }
  };

  const handleReplyClick = (feedback: FeedbackRecord) => {
    setReplyTo(feedback);
    const existingDraft = draftReplies.find(draft => draft.feedbackSeq === feedback.feedbackSeq);
    if (existingDraft) {
      setReplySubject(existingDraft.subject);
      setReplyBody(existingDraft.body);
    } else {
      setReplySubject(`Re: ${feedback.feedbackSubject}`);
      setReplyBody(`
------------------------------
${feedback.feedbackBody}`);
    }
    setReplyDialogOpen(true);
  };

  const handleReplyClose = () => {
    setReplyDialogOpen(false);
    setReplyTo(null);
    setReplySubject('');
    setReplyBody('');
  };

  const handleReplySend = async () => {
    if (!replyTo) return;

    const confirmSend = window.confirm('Are you sure you want to send this reply?');
    if (!confirmSend) return;

    setSendingReply(true);
    try {
      const response = await fetch(`${REACT_APP_API_URL}senduserfeedbackreply`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.user.accessToken}`,
        },
        body: JSON.stringify({
          feedbackSeq: replyTo.feedbackSeq,
          replyingToEmailAddress: replyTo.submittedByUserEmail,
          subject: replySubject,
          body: replyBody,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send reply');
      }

      setDraftReplies(prevDrafts =>
        prevDrafts.filter(draft => draft.feedbackSeq !== replyTo.feedbackSeq)
      );

      handleReplyClose();
      showSnackbar('Reply sent successfully', 'success');
      await fetchFeedback();
    } catch (err) {
      console.error(err);
      showSnackbar('Failed to send reply', 'error');
    } finally {
      setSendingReply(false);
    }
  };

  const fetchSupportEmailAddress = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}getsupportemailaddress`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.user.accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch support email address');
      }
      const email = await response.text();
      setSupportEmailAddress(email);
    } catch (err) {
      console.error(err);
      showSnackbar('Failed to fetch support email address', 'error');
    }
  };

  const filteredFeedbackRecords = useMemo(() => {
    let filtered = feedbackRecords;

    // Filter by feedback status
    switch (filterStatus) {
      case 'open':
        filtered = filtered.filter(record => record.isOpen);
        break;
      case 'closed':
        filtered = filtered.filter(record => !record.isOpen);
        break;
      case 'all':
      default:
        break;
    }

    // Filter by reply status
    switch (replyStatus) {
      case 'replied':
        filtered = filtered.filter(record => record.replySent);
        break;
      case 'unreplied':
        filtered = filtered.filter(record => !record.replySent);
        break;
      case 'all':
      default:
        break;
    }

    return filtered;
  }, [feedbackRecords, filterStatus, replyStatus]);

  return (
    <Box>
      <CustomHeader
        title='User Feedback'
        description='View and manage user feedback submissions.'
        showMenuButton={false}
      />

      <Box sx={{ p: 2 }}>
        {/* Filtering Controls */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            mb: 2,
            flexWrap: 'wrap',
          }}
        >
          <Typography variant='h6'>
            {filteredFeedbackRecords.length} Feedback Item
            {filteredFeedbackRecords.length !== 1 ? 's' : ''}
          </Typography>
          <ToggleButtonGroup
            value={filterStatus}
            exclusive
            onChange={(event, newFilter) => {
              if (newFilter !== null) {
                setFilterStatus(newFilter);
              }
            }}
            aria-label='feedback status filter'
            size='small'
          >
            <ToggleButton value='all' aria-label='show all'>
              All
            </ToggleButton>
            <ToggleButton value='open' aria-label='show open'>
              Open
            </ToggleButton>
            <ToggleButton value='closed' aria-label='show closed'>
              Closed
            </ToggleButton>
          </ToggleButtonGroup>
          <ToggleButtonGroup
            value={replyStatus}
            exclusive
            onChange={(event, newFilter) => {
              if (newFilter !== null) {
                setReplyStatus(newFilter);
              }
            }}
            aria-label='reply status filter'
            size='small'
          >
            <ToggleButton value='all' aria-label='show all replies'>
              All Replies
            </ToggleButton>
            <ToggleButton value='replied' aria-label='show replied'>
              Replied
            </ToggleButton>
            <ToggleButton value='unreplied' aria-label='show unreplied'>
              Unreplied
            </ToggleButton>
          </ToggleButtonGroup>
          <Button onClick={fetchFeedback} startIcon={<RefreshIcon />}>
            Refresh
          </Button>
        </Box>

        {/* Feedback Items */}
        {feedbackLoading ? (
          <Typography>Loading feedback...</Typography>
        ) : (
          <Stack spacing={1}>
            {filteredFeedbackRecords.map((record, index) => (
              <React.Fragment key={record.feedbackSeq}>
                <FeedbackItem
                  feedback={record}
                  onReply={handleReplyClick}
                  onChangeStatus={handleStatusChange}
                  loadingResolve={loadingResolve}
                />
                {index < filteredFeedbackRecords.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </Stack>
        )}
      </Box>

      {/* Reply Dialog */}
      <Dialog open={replyDialogOpen} onClose={handleReplyClose} maxWidth='md' fullWidth>
        <DialogTitle>
          {replyTo?.replySent ? 'Previous Reply Exists' : 'Reply to Feedback'}
        </DialogTitle>
        <DialogContent>
          {replyTo?.replySent && (
            <Alert severity='warning' sx={{ mb: 2 }}>
              <AlertTitle>Previous Reply Found</AlertTitle>A reply was already sent on{' '}
              {replyTo.replySentOnDateTime
                ? format(new Date(replyTo.replySentOnDateTime), 'MM/dd/yyyy, HH:mm')
                : 'N/A'}{' '}
              by {replyTo.replySentByPersonFirstName} {replyTo.replySentByPersonLastName}
            </Alert>
          )}
          <TextField
            margin='dense'
            label='From'
            type='email'
            fullWidth
            variant='standard'
            value='ocsme.support@doh.nj.gov'
            disabled
          />
          <TextField
            margin='dense'
            label='To'
            type='email'
            fullWidth
            variant='standard'
            value={replyTo?.submittedByUserEmail || ''}
            disabled
          />
          <TextField
            margin='dense'
            label='BCC'
            type='email'
            fullWidth
            variant='standard'
            value={auth.user.primaryEmail}
            disabled
          />
          <TextField
            margin='dense'
            label='Subject'
            type='text'
            fullWidth
            variant='filled'
            value={replySubject}
            onChange={e => setReplySubject(e.target.value)}
          />
          <TextField
            margin='dense'
            label='Body'
            multiline
            minRows={4}
            rows={12}
            fullWidth
            variant='filled'
            value={replyBody}
            onChange={e => setReplyBody(e.target.value)}
            InputProps={{
              style: {
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReplyClose}>Cancel</Button>
          <LoadingButton
            onClick={handleReplySend}
            loading={sendingReply}
            variant='contained'
            color='primary'
          >
            Send Reply
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Snackbar Notifications */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

function FeedbackItem({
  feedback,
  onReply,
  onChangeStatus,
  loadingResolve,
}: {
  feedback: FeedbackRecord;
  onReply: (feedback: FeedbackRecord) => void;
  onChangeStatus: (feedbackSeq: string, isOpen: boolean) => void;
  loadingResolve: { [key: string]: boolean };
}) {
  const theme = useTheme();

  return (
    <Box
      component='div'
      sx={{
        mb: 2,
        maxWidth: '600px',
        margin: '0 auto',
        backgroundColor: 'transparent',
      }}
    >
      <CardContent>
        {/* Actions at the Top */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mb: 2,
            flexWrap: 'wrap',
            gap: 1,
          }}
        >
          <LoadingButton
            loading={loadingResolve[feedback.feedbackSeq] || false}
            variant='contained'
            color={feedback.isOpen ? 'success' : 'warning'}
            onClick={() => onChangeStatus(feedback.feedbackSeq, feedback.isOpen)}
          >
            {feedback.isOpen ? 'Close' : 'Reopen'}
          </LoadingButton>
          <Button variant='outlined' onClick={() => onReply(feedback)}>
            {feedback.replySent ? 'View Reply' : 'Reply'}
          </Button>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* User Feedback Message */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              mb: 2,
            }}
          >
            <Paper
              elevation={0}
              sx={{
                bgcolor:
                  theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
                borderRadius: '16px',
                p: 2,
                maxWidth: '80%',
                position: 'relative',
              }}
            >
              <Typography variant='body1' sx={{ whiteSpace: 'pre-wrap', color: 'inherit' }}>
                {feedback.feedbackBody}
              </Typography>
            </Paper>
            <Typography variant='body2' color='textSecondary' sx={{ mt: 0.5 }}>
              {feedback.submittedByPersonFirstName} {feedback.submittedByPersonLastName} •{' '}
              {format(new Date(feedback.feedbackSubmittedOnDateTime), 'MM/dd/yyyy, HH:mm')}
            </Typography>
          </Box>

          {/* Admin Reply Message */}
          {feedback.replySent && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <Box
                sx={{
                  bgcolor:
                    theme.palette.mode === 'dark'
                      ? theme.palette.primary.dark
                      : theme.palette.primary.light,
                  color: theme.palette.getContrastText(
                    theme.palette.mode === 'dark'
                      ? theme.palette.primary.dark
                      : theme.palette.primary.light
                  ),
                  borderRadius: 2,
                  p: 2,
                  maxWidth: '80%',
                }}
              >
                <Typography variant='body1' sx={{ whiteSpace: 'pre-wrap', color: 'inherit' }}>
                  {trimReplyContent(feedback.replySentBody || '')}
                </Typography>
              </Box>
              <Typography variant='body2' color='textSecondary' sx={{ mt: 0.5 }}>
                {feedback.replySentByPersonFirstName} {feedback.replySentByPersonLastName} •{' '}
                {feedback.replySentOnDateTime
                  ? format(new Date(feedback.replySentOnDateTime), 'MM/dd/yyyy, HH:mm')
                  : 'N/A'}
              </Typography>
            </Box>
          )}
        </Box>
      </CardContent>
    </Box>
  );
}

const trimReplyContent = (content: string): string => {
  const separatorIndex = content.indexOf('------------------------------');
  return separatorIndex !== -1 ? content.substring(0, separatorIndex).trim() : content.trim();
};