import * as url from '../URL_HELPERS';

const userString = localStorage.getItem('user');
const user = userString ? JSON.parse(userString) : null;

const { REACT_APP_API_URL } = process.env;

export async function getStateOptionsApi() {
  return fetch(REACT_APP_API_URL + url.GET_STATE_OPTIONS, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + user.token,
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      console.log(e);
    });
}
