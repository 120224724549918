import React, { useState } from 'react';

import {
  Button,
  Grid,
  Typography,
  Divider,
  ListItem,
  List,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { ErrorMessage } from 'formik';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const id = '00000000-0000-0000-0000-000000000000';

const StatusTable = ({ values, handleChange, statuses, statusValueObjs }) => {
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const [openDeleteStatusDialogBox, setOpenDeleteStatusDialogBox] =
    useState(false);
  const [deleteStatusClickData, setDeleteStatusClickData] = useState(() => {
    setOpenDeleteStatusDialogBox(false);
  });

  return (
    <>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <Typography variant='h5' mt={0}>
            Set Statuses
          </Typography>
        </Grid>
        <Grid item xs={12} mt={-1}>
          <Button
            onClick={() =>
              handleChange({
                target: {
                  name: 'activityOnStatusValue',
                  value: [
                    ...values.activityOnStatusValue,
                    {
                      activityOnStatusValueSeq: id,
                      activitySeq: values.activitySeq,
                      statusTypeSeq: '',
                      statusTypeName: '',
                      statusValueCode: '',
                      statusValueDesc: '',
                      autoAssign: false,
                      status: 'Active',
                    },
                  ],
                },
              })
            }
            variant='contained'
            startIcon={<AddCircleIcon />}
          >
            Add Status
          </Button>
        </Grid>
        {values?.activityOnStatusValue &&
        values?.activityOnStatusValue.length ? (
          <Grid item xs={12}>
            <List className='border border-bottom-0' sx={{ bgcolor: '#fff', py: '0px', color: '#000' }}>
              <ListItem>
                <Grid container spacing={2}>
                  <Grid
                    xs={4}
                    item
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      left: '-16px',
                    }}
                  >
                    <Typography variant='h6' mt={0} sx={{ color: '#000' }}>
                      Status Type
                    </Typography>
                    {/* <Divider sx={{ position: 'absolute', right: '-10px' }}>
                      |
                    </Divider> */}
                  </Grid>
                  <Grid
                    xs={4}
                    item
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      left: '-16px',
                    }}
                  >
                    <Typography variant='h6' mt={0} sx={{ color: '#000' }}>
                      Status Value
                    </Typography>
                    {/* <Divider sx={{ position: 'absolute', right: '-10px' }}>
                      |
                    </Divider> */}
                  </Grid>
                  <Grid
                    xs={3}
                    item
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      // left: '-16px',
                    }}
                  >
                    <Typography variant='h6' mt={0} sx={{ color: '#000' }}>
                      Assignable?
                    </Typography>
                    {/* <Divider sx={{ position: 'absolute', right: '-10px' }}>
                      |
                    </Divider> */}
                  </Grid>
                  <Grid
                    xs={1}
                    item
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      left: '-16px',
                    }}
                  ></Grid>
                </Grid>
              </ListItem>
            </List>

            <List sx={{ paddingTop: '0px' }} className='statusTable border pb-0'>
              {values?.activityOnStatusValue &&
                values?.activityOnStatusValue.map((item, index) => (
                  <ListItem
                    key={index}
                    id={index}
                    sx={{
                      py: '0px',
                      marginBottom: '0px',
                      backgroundColor: '#fff',
                      display: item.status == 'Active' ? '' : 'none',
                    }}
                  >
                    <Grid className='mt-0' container spacing={2}>
                      <Grid
                        className='my-0 pb-3'
                        xs={4}
                        item
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative',
                          left: '-16px',
                        }}
                      >
                        <FormControl variant='standard' sx={{ width: '90%' }}>
                          <Select
                            focused
                            displayEmpty
                            id={`activityOnStatusValue.${index}.statusTypeSeq`}
                            variant='outlined'
                            name={`activityOnStatusValue.${index}.statusTypeSeq`}
                            margin='normal'
                            size='large'
                            value={
                              values
                                ? values['activityOnStatusValue'][index][
                                    'statusTypeSeq'
                                  ]
                                : ''
                            }
                            onChange={(e) => {
                              handleChange({
                                target: {
                                  name: e.target.name,
                                  value: e.target.value,
                                },
                              });
                              handleChange({
                                target: {
                                  name: `activityOnStatusValue.${index}.statusTypeName`,
                                  value: statuses.filter(
                                    (row) => row.statusTypeSeq == e.target.value
                                  )[0]['statusTypeName'],
                                },
                              });
                            }}
                          >
                            {statuses.map((row) => {
                              return (
                                <MenuItem
                                  key={row.statusTypeSeq}
                                  value={row.statusTypeSeq}
                                >
                                  {row.statusTypeName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <ErrorMessage
                            name={`activityOnStatusValue.${index}.statusTypeSeq`}
                            render={(msg) => (
                              <FormHelperText error>{msg}</FormHelperText>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        className='my-0 pb-3'
                        xs={4}
                        item
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative',
                          left: '-16px',
                          margin: '5px 0',
                        }}
                      >
                        <FormControl variant='standard' sx={{ width: '90%' }}>
                          <Select
                            focused
                            displayEmpty
                            id={`activityOnStatusValue.${index}.statusValueCode`}
                            variant='outlined'
                            name={`activityOnStatusValue.${index}.statusValueCode`}
                            margin='normal'
                            size='large'
                            value={
                              values
                                ? values['activityOnStatusValue'][index][
                                    'statusValueCode'
                                  ]
                                : ''
                            }
                            onChange={(e) => {
                              handleChange({
                                target: {
                                  name: e.target.name,
                                  value: e.target.value,
                                },
                              });
                              handleChange({
                                target: {
                                  name: `activityOnStatusValue.${index}.statusValueDesc`,
                                  value: statusValueObjs[
                                    values['activityOnStatusValue'][index][
                                      'statusTypeSeq'
                                    ]
                                  ].filter(
                                    (row) =>
                                      row.statusValueCode == e.target.value
                                  )[0]
                                    ? statusValueObjs[
                                        values['activityOnStatusValue'][index][
                                          'statusTypeSeq'
                                        ]
                                      ].filter(
                                        (row) =>
                                          row.statusValueCode == e.target.value
                                      )[0]['statusValueDesc']
                                    : '',
                                },
                              });
                            }}
                            style={{ backgroundColor: '#fff' }}
                          >
                            {values['activityOnStatusValue'][index][
                              'statusTypeSeq'
                            ] &&
                              statusValueObjs[
                                values['activityOnStatusValue'][index][
                                  'statusTypeSeq'
                                ]
                              ] &&
                              statusValueObjs[
                                values['activityOnStatusValue'][index][
                                  'statusTypeSeq'
                                ]
                              ].map((row) => {
                                return (
                                  <MenuItem
                                    key={row.statusValueCode}
                                    value={row.statusValueCode}
                                  >
                                    {row.statusValueDesc}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <ErrorMessage
                            name={`activityOnStatusValue.${index}.statusValueCode`}
                            render={(msg) => (
                              <FormHelperText error>{msg}</FormHelperText>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        className='my-0 pb-3'
                        xs={2}
                        item
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative',
                          margin: '5px 0',
                        }}
                      >
                        <FormGroup>
                          <FormControlLabel
                            id={`activityOnStatusValue.${index}.autoAssign`}
                            name={`activityOnStatusValue.${index}.autoAssign`}
                            checked={
                              values?.activityOnStatusValue?.[index]
                                ?.autoAssign ?? false
                            }
                            control={<Checkbox />}
                            onChange={(e) => {
                              handleChange({
                                target: {
                                  name: `activityOnStatusValue.${index}.autoAssign`,
                                  value: e.target.checked,
                                },
                              });
                            }}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid
                        // className='my-0 pb-3'
                        xs={2}
                        item
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative',
                          paddingLeft: '16px'
                        }}
                      >
                        <Button
                          onClick={() => {
                            const deleteFunc = () => {
                              handleChange({
                                target: {
                                  name: 'activityOnStatusValue',
                                  value:
                                    item.activityOnStatusValueSeq == id
                                      ? values.activityOnStatusValue.filter(
                                          (_, ind) => ind != index
                                        )
                                      : values.activityOnStatusValue.map(
                                          (item, ind) => {
                                            if (ind == index) {
                                              return {
                                                ...item,
                                                status: 'InActive',
                                              };
                                            }
                                            return item;
                                          }
                                        ),
                                },
                              });
                              setOpenDeleteStatusDialogBox(false);
                            };
                            setOpenDeleteStatusDialogBox(true);
                            setDeleteStatusClickData(() => deleteFunc);
                            return;
                          }}
                          aria-label='delete'
                          size='large'
                          id={`row_id_${index}`}
                          style={{ backgroundColor: '#2196f3' }}
                        >
                          <DeleteIcon
                            fontSize='inherit'
                            sx={{ color: '#fff' }}
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
            </List>
          </Grid>
        ) : null}
        <div className='modal'>
          <Dialog
            open={openDeleteStatusDialogBox}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenDeleteStatusDialogBox(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                Are you sure you want to remove this status?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDeleteStatusDialogBox(false)}>
                No
              </Button>
              <Button onClick={deleteStatusClickData}>Yes</Button>
            </DialogActions>
          </Dialog>
        </div>
      </Grid>
    </>
  );
};

export default StatusTable;
