import React, { useEffect, useState } from 'react';
import { ErrorMessage, Formik, Field } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
  OutlinedInput,
  FormHelperText,
  Alert,
  InputAdornment,
  IconButton,
  Link,
  Select,
  MenuItem,
  useMediaQuery,
  InputLabel,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import SearchIcon from '@mui/icons-material/Search';
// import AnimateButton from 'ui-component/extended/AnimateButton';
import FormControl from '@mui/material/FormControl';

const ReferenceTableFilter = ({
  rows,
  isCounty,
  countyNames,
  setRowsToShow,
  activeInActiveFilter,
  setActiveInActiveFilter,
}) => {
  const [countyValue, setCountyValue] = useState('');
  const onFilter = () => {
    let newRows = rows;
    if (countyValue) {
      newRows = newRows.filter((row) => row.countySeq == countyValue.value);
    }
    if (activeInActiveFilter == 'all') setRowsToShow(newRows);
    else if (activeInActiveFilter == 'Active')
      setRowsToShow(
        newRows.filter((row) => row.status.toLowerCase() == 'active')
      );
    else
      setRowsToShow(
        newRows.filter((row) => row.status.toLowerCase() != 'active')
      );
  };

  useEffect(() => {
    onFilter();
  }, [rows, activeInActiveFilter, countyValue]);

  const FormikAutocomplete = ({ textFieldProps, ...props }) => {
    return (
      <Autocomplete
        {...props}
        onChange={(e, v) => {
          setCountyValue(v);
        }}
        value={countyValue?.label || ''}
        getOptionSelected={(item, current) => item.value === current.value}
        renderInput={(props) => (
          <TextField {...props} label='Choose a Jurisdiction' />
        )}
      />
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {isCounty && (
              <Grid item lg={3} md={12} sm={12} xs={12}>
                <Grid container spacing={3}>
                  <Grid item sm={6} xs={12} md={6} lg={12}>
                    <FormControl
                      error
                      required
                      component='fieldset'
                      variant='standard'
                    >
                      <TextField
                        name='county'
                        style={{ width: '250px' }}
                        component={FormikAutocomplete}
                        label='Jurisdiction'
                        options={Object.keys(countyNames).map((countyName) => ({
                          label: countyNames[countyName].countyName,
                          value: countyNames[countyName].countySeq,
                          code: countyNames[countyName].code,
                        }))}
                        textFieldProps={{
                          variant: 'outlined',
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='isActive'
                onChange={(e) => setActiveInActiveFilter(e.target.value)}
                value={activeInActiveFilter}
                sx={{ marginLeft: 1 }}
              >
                {' '}
                <FormControlLabel
                  value='Active'
                  control={<Radio />}
                  label='Active Only'
                />{' '}
                <FormControlLabel
                  value='Inactive'
                  control={<Radio />}
                  label='Inactive Only'
                />{' '}
                <FormControlLabel value='all' control={<Radio />} label='All' />{' '}
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ReferenceTableFilter;
