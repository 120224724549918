import {
  GET_MARITAL_STATUS_OPTION,
  GET_MARITAL_STATUS_OPTION_SUCCESS,
  GET_MARITAL_STATUS_OPTION_FAIL,
  GET_STATE_OPTION,
  GET_STATE_OPTION_SUCCESS,
  GET_STATE_OPTION_FAIL,
  UPDATE_DEMOGRAPHICS,
  UPDATE_DEMOGRAPHICS_SUCCESS,
  UPDATE_DEMOGRAPHICS_FAIL,
} from './actionTypes';
import store from '../index';

export const getMaritalStatusOptions = () => ({
  type: GET_MARITAL_STATUS_OPTION,
});

export const getMaritalStatusOptionsSuccess = (data) => ({
  type: GET_MARITAL_STATUS_OPTION_SUCCESS,
  data: data,
});

export const getMaritalStatusOptionsFail = (error) => ({
  type: GET_MARITAL_STATUS_OPTION_FAIL,
});

export const getStateOptions = () => ({
  type: GET_STATE_OPTION,
});

export const getStateOptionsSuccess = (data) => ({
  type: GET_STATE_OPTION_SUCCESS,
  data: data,
});

export const getStateOptionsFail = (error) => ({
  type: GET_STATE_OPTION_FAIL,
});

export const updateDemographics = (formData) => ({
  type: UPDATE_DEMOGRAPHICS,
  payload: formData,
});

export const updateDemographicsSuccess = (data) => ({
  type: UPDATE_DEMOGRAPHICS_SUCCESS,
  data: data,
});

export const updateDemographicsFail = (error) => ({
  type: UPDATE_DEMOGRAPHICS_FAIL,
  payload: error,
});

export const updateDemographicsAction = (payload) =>
  store.dispatch(updateDemographics(payload));
export const getMaritalStatusOptionsAction = () =>
  store.dispatch(getMaritalStatusOptions());
export const getStateOptionsAction = () => store.dispatch(getStateOptions());
