import React, { useEffect, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { User } from '../../types/User';
import { useAuthRevalidation } from './functions/useAuthRevalidation.hook';
import { useFetchWithRevalidation } from './functions/useFetchWithRevalidation';
import { useLogin } from './functions/useLogin.hook';
import { useResetPassword } from './functions/useResetPassword.hook';
import { useSendCodeEmail } from './functions/useSendCodeEmail.hook';
import { useSendCodeSMS } from './functions/useSendCodeSMS.hook';
import { useSysadminPermissionCheck_TEMP } from './functions/useSysadminPermissionCheck_temp.hook';
import {
  AuthAction,
  AuthLoginState,
  AuthState,
  AuthTwoFactorState,
  authReducer,
  initialAuthState,
} from './reducers/authReducer';
import { UserAction, UserState, initialUserState, userReducer } from './reducers/userReducer';

const { REACT_APP_API_URL } = process.env;

// Type to retrieve functions returned by useResetPassword
type ResetPasswordFunctions = ReturnType<typeof useResetPassword>;

// Define the shape of the authentication context.
export interface AuthContextProps extends ResetPasswordFunctions {
  login: ({ username, password }: { username: string; password: string }) => Promise<User | null>;
  logout: () => void;
  user: User | null;
  ready: boolean;
  isAuthenticated: boolean;
  two_factor: {
    selected_method: 'none' | 'email' | 'sms';
    sendEmail: () => void;
    sendSMS: () => void;
    verifyEmail: ({ code }: { code: string }) => Promise<any>;
    verifySMS: ({ code }: { code: string }) => Promise<any>;
    email_state: AuthTwoFactorState;
    sms_state: AuthTwoFactorState;
    passed: boolean;
    is_verifying_code: boolean;
  };
  login_state: AuthLoginState;
  authState: AuthState;
  authDispatch: React.Dispatch<AuthAction>;
  userState: UserState;
  userDispatch: React.Dispatch<UserAction>;
}

// Create a context for the authentication data.
const AuthContext = React.createContext<AuthContextProps | undefined>(undefined);

// Provider component to provide authentication context to child components.
export const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const navigate = useNavigate();
  // Setting up reducers for authentication and user.
  const [authState, authDispatch] = useReducer(authReducer, initialAuthState);
  const [userState, userDispatch] = useReducer(userReducer, initialUserState);

  // Use the revalidation hook to ensure the user's authentication token is still valid.
  useAuthRevalidation(authDispatch, userDispatch, navigate);

  // Set up the login functionality using the custom hook.
  const login = useLogin(authDispatch, userDispatch, userState, navigate);

  // Define logout functionality.
  const logout = () => {
    authDispatch({ type: 'RESET_AUTH' });
    authDispatch({
      type: 'SET_LOGOUT_MESSAGE_STATE',
      payload: {
        displayAlert: true,
        shouldDismiss: true,
        dismissAfterSeconds: 5,
        message: 'You have been successfully logged out',
      },
    });
    userDispatch({ type: 'CLEAR_USER' });
    navigate('/login');
  };

  // Set up two-factor authentication functionalities for email.
  const { sendEmail, verifyEmail } = useSendCodeEmail(
    authDispatch,
    authState,
    userDispatch,
    userState,
    navigate
  );

  // Set up two-factor authentication functionalities for SMS.
  const { sendSMS, verifySMS } = useSendCodeSMS(
    authDispatch,
    authState,
    userDispatch,
    userState,
    navigate
  );

  // Set up password reset functionalities
  const { requestPasswordResetCode, verifyPasswordResetCode, changePassword, forceResetPassword } =
    useResetPassword(authDispatch, authState, userDispatch, userState, navigate);

  // Temp: Check user sysadmin permissions
  useSysadminPermissionCheck_TEMP(userState.user);
  useFetchWithRevalidation(userState, userDispatch, authDispatch);

  const location = useLocation();
  useEffect(() => {
    if (authState.isAuthenticated && authState.ready) {
      if (location.pathname.includes('login')) {
        logout();
      }
    }
  }, [location.pathname, authState.isAuthenticated, authState.ready]);

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        requestPasswordResetCode,
        verifyPasswordResetCode,
        changePassword,
        forceResetPassword,
        user: userState.user,
        ready: authState.ready,
        two_factor: {
          selected_method: authState.two_factor.selected_method,
          sendEmail,
          sendSMS,
          verifyEmail,
          verifySMS,
          email_state: authState.two_factor.email_state,
          sms_state: authState.two_factor.sms_state,
          passed: authState.two_factor.passed,
          is_verifying_code: authState.two_factor.passed, // Note: this seems like a potential error. Should it be a different flag?
        },
        isAuthenticated: authState.isAuthenticated,
        login_state: authState.login_state,
        authState,
        authDispatch,
        userState,
        userDispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the authentication context.
export const useAuth = () => {
  const context = React.useContext(AuthContext);
  // If the hook is used outside of the Provider's scope, throw an error.
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// Custom hook to grab the current user from the authentication context
export const useUser = () => {
  const context = React.useContext(AuthContext);
  // If the hook is used outside of the Provider's scope, throw an error.
  if (context === undefined || context.user === null || context.user === undefined) {
    throw new Error('useUser must be used within an AuthProvider');
  }
  return context.user;
};
