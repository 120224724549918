import {
  GET_MARITAL_STATUS_OPTION,
  GET_MARITAL_STATUS_OPTION_SUCCESS,
  GET_MARITAL_STATUS_OPTION_FAIL,
  GET_STATE_OPTION,
  GET_STATE_OPTION_SUCCESS,
  GET_STATE_OPTION_FAIL,
  UPDATE_DEMOGRAPHICS,
  UPDATE_DEMOGRAPHICS_SUCCESS,
  UPDATE_DEMOGRAPHICS_FAIL,
} from './actionTypes';

const initialState = {
  demographics: null,
  maritalStatus: null,
  states: null,
  loadingDemographics: false,
  error: {
    message: '',
  },
};

const demographicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MARITAL_STATUS_OPTION:
      state = { ...state, loadingDemographics: true };
      break;
    case GET_MARITAL_STATUS_OPTION_SUCCESS:
      state = {
        ...state,
        maritalStatus: action.data,
        loadingDemographics: false,
      };
      break;
    case GET_MARITAL_STATUS_OPTION_FAIL:
      state = {
        ...state,
        error: {
          message: 'Error',
        },
        loadingDemographics: false,
      };
      break;
    case GET_STATE_OPTION:
      state = { ...state, loadingDemographics: true };
      break;
    case GET_STATE_OPTION_SUCCESS:
      state = { ...state, states: action.data, loadingDemographics: false };
      break;
    case GET_STATE_OPTION_FAIL:
      state = {
        ...state,
        error: {
          message: 'Error',
        },
        loadingDemographics: false,
      };
      break;
    case UPDATE_DEMOGRAPHICS:
      state = {
        ...state,
        loadingDemographics: true,
      };
      break;
    case UPDATE_DEMOGRAPHICS_SUCCESS:
      state = {
        ...state,
        demographics: action.data,
        loadingDemographics: false,
        error: null,
      };
      break;
    case UPDATE_DEMOGRAPHICS_FAIL:
      state = {
        ...state,
        demographics: null,
        loadingDemographics: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default demographicsReducer;
