import { HubConnectionState } from '@microsoft/signalr';
import AddIcon from '@mui/icons-material/Add';
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { createSvgIcon } from '@mui/material/utils';
import { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import CommonAlert from '../../components/CommonAlert';
import { useAuth } from '../../utils/auth/AuthService';
import { useNotifications } from '../../utils/components/AppBar/Notifications/NotificationsContext';
import CustomGallery from '../../utils/components/CustomGallery';
import MLPhotoDropzone from '../../utils/components/MLPhotoDropzone';
import { Module_Name, Photo_Types } from '../../utils/constants/constants';
import { GuidEmptySeq } from '../../utils/constants/GuidEmptySeq';
import { XrayRequestFormView } from '../../views/Case/CaseViewModules/MortuaryModules/XrayRequestFormView';

const CasePhotos = ({ caseSeq, caseNumber, fullScreen = false }) => {
  const notifications = useNotifications();
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [originalImage, setOriginalImage] = useState([]);
  const [xRayMidsizePhotos, setXRayMidsizePhotos] = useState([]);
  const [sceneMidsizePhotos, setSceneMidsizePhotos] = useState([]);
  const [autopsyEEMidsizePhotos, setAutopsyEEMidsizePhotos] = useState([]);
  const [otherMidsizePhotos, setOtherMidsizePhotos] = useState([]);
  const [xRayThumbnailPhotos, setXRayThumbnailPhotos] = useState([]);
  const [otherThumbnailPhotos, setOtherThumbnailPhotos] = useState([]);
  const [sceneThumbnailPhotos, setSceneThumbnailPhotos] = useState([]);
  const [autopsyEEThumbnailPhotos, setAutopsyEEThumbnailPhotos] = useState([]);
  const [selectedImageType, setSelectedImageType] = useState('thumbnails');
  const { user } = useAuth();
  const { REACT_APP_API_URL } = process.env;
  const [open, setOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loadingXRayPhotos, setLoadingXRayPhotos] = useState(false);
  const [loadingScenePhotos, setLoadingScenePhotos] = useState(false);
  const [loadingAutopsyEEPhotos, setLoadingAutopsyEEPhotos] = useState(false);
  const [loadingOtherPhotos, setLoadingOtherPhotos] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [midsizeImageDialogOpen, setMidsizeImageDialogOpen] = useState(false);
  const ExportIcon = createSvgIcon(
    <path d='M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z' />,
    'SaveAlt'
  );
  const [showOPErrorAlert, setShowOPErrorAlert] = useState(null);
  const [showXPErrorAlert, setShowXPErrorAlert] = useState(null);
  const [showSPErrorAlert, setShowSPErrorAlert] = useState(null);
  const [showAEPErrorAlert, setShowAEPErrorAlert] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const [downloading, setDownloading] = useState(false);
  const appTheme = useTheme();
  const isMobile = useMediaQuery(appTheme.breakpoints.down('sm'));
  const isLaptop = useMediaQuery(appTheme.breakpoints.up('md'));
  const [unblurAll, setUnblurAll] = useState(false); // State to control unblurring of all images

  const [downloadStatus, setDownloadStatus] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleImageVoided = (photoSeq, success, message) => {
    if (success) {
      // Update the thumbnails and midsize photos for the current module
      if (moduleName === Module_Name.Medical_Imaging) {
        setXRayThumbnailPhotos(prev => prev.filter(photo => photo.photoSeq !== photoSeq));
        setXRayMidsizePhotos(prev => prev.filter(photo => photo.photoSeq !== photoSeq));
      } else if (moduleName === Module_Name.Scene) {
        setSceneThumbnailPhotos(prev => prev.filter(photo => photo.photoSeq !== photoSeq));
        setSceneMidsizePhotos(prev => prev.filter(photo => photo.photoSeq !== photoSeq));
      } else if (moduleName === Module_Name.Autopsy_EE) {
        setAutopsyEEThumbnailPhotos(prev => prev.filter(photo => photo.photoSeq !== photoSeq));
        setAutopsyEEMidsizePhotos(prev => prev.filter(photo => photo.photoSeq !== photoSeq));
      } else {
        setOtherThumbnailPhotos(prev => prev.filter(photo => photo.photoSeq !== photoSeq));
        setOtherMidsizePhotos(prev => prev.filter(photo => photo.photoSeq !== photoSeq));
      }
      setSnackbarSeverity('success');
    } else {
      setSnackbarSeverity('error');
    }
    setSnackbarMessage(message);
    setSnackbarOpen(true);
    setMidsizeImageDialogOpen(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  //df:  crashing Windows production on Medical Images view
  // useEffect(() => {
  //   console.log('Case Download Images Event ' + notifications.notificationsConnection.state);

  //   notifications.notificationsConnection.on('Case Download Images Event', status => {
  //     console.log('Case Download Images Event ' + status);
  //     setDownloadStatus(status);
  //   });
  // }, [notifications?.notificationsConnection]);

  let photoTypeSeqValues = [];

  const getModuleName = tabIndex => {
    switch (tabIndex) {
      case 0:
        return Module_Name.Scene;
      case 1:
        return Module_Name.Medical_Imaging;
      case 2:
        return Module_Name.Autopsy_EE;
      case 3:
        return Module_Name.Other_Photos;
      default:
        return ''; // Default case
    }
  };

  const moduleName = getModuleName(selectedTab);

  if (moduleName === Module_Name.Medical_Imaging) {
    photoTypeSeqValues = Photo_Types.Medical_Imaging;
  } else if (moduleName === Module_Name.Scene) {
    photoTypeSeqValues = Photo_Types.Scene;
  } else if (moduleName === Module_Name.Autopsy_EE) {
    photoTypeSeqValues = Photo_Types.Autopsy_EE;
  }

  const handleCheckboxChange = photoSeq => {
    const newSelectedImages = [...selectedImages];
    newSelectedImages[photoSeq] = !newSelectedImages[photoSeq];
    setSelectedImages(newSelectedImages);
    setSelectAll(newSelectedImages.every(Boolean));
  };

  const handleSelectAllChange = () => {
    setSelectAll(prevSelectAll => !prevSelectAll);

    if (thumbnailPhotos?.length > 0) {
      const newSelectedImages = thumbnailPhotos?.map(() => !selectAll);
      setSelectedImages(newSelectedImages);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleUnblurChange = event => {
    setUnblurAll(event.target.checked);
  };

  const fetchThumbnailPhotos = async source => {
    let formData = new FormData();
    formData.append('CaseSeq', caseSeq);
    formData.append('UserSeq', user.userSeq);

    if (source === Module_Name.Medical_Imaging) {
      Photo_Types.Medical_Imaging.forEach((value, index) => {
        formData.append('PhotoTypeSeq', value);
      });
    } else if (source === Module_Name.Scene) {
      Photo_Types.Scene.forEach((value, index) => {
        formData.append('PhotoTypeSeq', value);
      });
    } else if (source === Module_Name.Autopsy_EE) {
      Photo_Types.Autopsy_EE.forEach((value, index) => {
        formData.append('PhotoTypeSeq', value);
      });
    }

    try {
      const response = await fetch(REACT_APP_API_URL + 'casephotos/retrieve/thumbnails', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message);
      }

      return data;
    } catch (error) {
      throw new Error('An error occurred while fetching thumbnail images: ' + error);
    }
  };

  const fetchMidsizePhotos = async () => {
    let formData = new FormData();

    formData.append('CaseSeq', caseSeq);
    formData.append('UserSeq', user.userSeq);
    photoTypeSeqValues.forEach((value, index) => {
      formData.append('PhotoTypeSeq', value);
    });

    try {
      const response = await fetch(REACT_APP_API_URL + 'casephotos/retrieve/midsize', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }

      return data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const fetchOriginalImages = async () => {
    let formData = new FormData();
    formData.append('PhotoSeq', photoSeq);
    formData.append('UserSeq', user.userSeq);

    await fetch(REACT_APP_API_URL + 'casephotos/retrieve/original', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setOriginalImage(data);
      })
      .catch(e => {
        alert(e);
      });
  };

  useEffect(() => {
    setShowOPErrorAlert(null);
    setShowXPErrorAlert(null);
    setShowSPErrorAlert(null);
    setShowAEPErrorAlert(null);

    //get X-Ray Photos
    setLoadingXRayPhotos(true);
    fetchThumbnailPhotos(Module_Name.Medical_Imaging)
      .then(data => {
        setXRayThumbnailPhotos(data);
        setLoadingXRayPhotos(false);
      })
      .catch(error => {
        setLoadingXRayPhotos(false);
        console.error(error);
        setShowXPErrorAlert({
          type: 'error',
          message: 'An error occured while fetching xray photo thumbnails',
        });
      });

    //get Scene Photos
    setLoadingScenePhotos(true);
    fetchThumbnailPhotos(Module_Name.Scene)
      .then(data => {
        setSceneThumbnailPhotos(data);
        setLoadingScenePhotos(false);
      })
      .catch(error => {
        setLoadingScenePhotos(false);
        console.error(error);
        setShowSPErrorAlert({
          type: 'error',
          message: 'An error occured while fetching scene photo thumbnails',
        });
      });

    //get Exam Photos
    setLoadingAutopsyEEPhotos(true);
    fetchThumbnailPhotos(Module_Name.Autopsy_EE)
      .then(data => {
        setAutopsyEEThumbnailPhotos(data);
        setLoadingAutopsyEEPhotos(false);
      })
      .catch(error => {
        setLoadingAutopsyEEPhotos(false);
        console.error(error);
        setShowAEPErrorAlert({
          type: 'error',
          message: 'An error occured while fetching autopsy and external exam photo thumbnails',
        });
      });

    //get all other Photos
    setLoadingOtherPhotos(true);
    fetchThumbnailPhotos()
      .then(data => {
        setOtherThumbnailPhotos(data);
        setLoadingOtherPhotos(false);
      })
      .catch(error => {
        setLoadingOtherPhotos(false);
        console.error(error);
        setShowOPErrorAlert({
          type: 'error',
          message: 'An error occured while fetching other photo thumbnails',
        });
      });
  }, []);

  useEffect(() => {
    if (
      moduleName === Module_Name.Medical_Imaging &&
      (!xRayMidsizePhotos || xRayMidsizePhotos?.length === 0)
    ) {
      fetchMidsizePhotos()
        .then(data => {
          setXRayMidsizePhotos(data);
        })
        .catch(error => {
          console.error('Error fetching xray midsize photos:', error);
        });
    } else if (
      moduleName === Module_Name.Scene &&
      (!sceneMidsizePhotos || sceneMidsizePhotos?.length === 0)
    ) {
      fetchMidsizePhotos()
        .then(data => {
          setSceneMidsizePhotos(data);
        })
        .catch(error => {
          console.error('Error fetching scene midsize photos:', error);
        });
    } else if (
      moduleName === Module_Name.Autopsy_EE &&
      (!autopsyEEMidsizePhotos || autopsyEEMidsizePhotos?.length === 0)
    ) {
      fetchMidsizePhotos()
        .then(data => {
          setAutopsyEEMidsizePhotos(data);
        })
        .catch(error => {
          console.error('Error fetching autopsy and external exam midsize photos:', error);
        });
    } else if (!otherMidsizePhotos || otherMidsizePhotos?.length === 0) {
      fetchMidsizePhotos()
        .then(data => {
          setOtherMidsizePhotos(data);
        })
        .catch(error => {
          console.error('Error fetching other midsize photos:', error);
        });
    }
  }, [moduleName]);

  const handleThumbnailImageClick = async (index, photoSeq) => {
    const selectedIndex = galleryImages?.findIndex(
      image => image?.photoSeq?.toLowerCase() === photoSeq?.toLowerCase()
    );
    if (selectedIndex !== -1) {
      setSelectedImageIndex(selectedIndex);
    }
    setSelectedImageType('midsize');
    setMidsizeImageDialogOpen(true);
  };

  const handleSuccessfulUpload = () => {
    //On new uploads, it would be better to retrieve only newly uploaded data instead of refresh. will analyze on this, for now it will refresh and get everything.

    if (moduleName === Module_Name.Medical_Imaging) {
      setLoadingXRayPhotos(true);
      fetchThumbnailPhotos(moduleName)
        .then(data => {
          setLoadingXRayPhotos(false);
          setXRayThumbnailPhotos(data);
        })
        .catch(error => {
          setLoadingXRayPhotos(false);
          console.error('Error fetching xray thumbnail photos:', error);
          setShowXPErrorAlert({
            type: 'error',
            message: 'An error occured while fetching xray photo thumbnails',
          });
        });

      fetchMidsizePhotos()
        .then(data => {
          setXRayMidsizePhotos(data);
        })
        .catch(error => {
          console.error('Error fetching xray midsize photos:', error);
        });
    } else if (moduleName === Module_Name.Scene) {
      setLoadingScenePhotos(true);
      fetchThumbnailPhotos(moduleName)
        .then(data => {
          setLoadingScenePhotos(false);
          setSceneThumbnailPhotos(data);
        })
        .catch(error => {
          setLoadingScenePhotos(false);
          console.error('Error fetching scene thumbnail photos:', error);
          setShowSPErrorAlert({
            type: 'error',
            message: 'An error occured while fetching scene photo thumbnails',
          });
        });

      fetchMidsizePhotos()
        .then(data => {
          setSceneMidsizePhotos(data);
        })
        .catch(error => {
          console.error('Error fetching scene midsize photos:', error);
        });
    } else if (moduleName === Module_Name.Autopsy_EE) {
      setLoadingAutopsyEEPhotos(true);
      fetchThumbnailPhotos(moduleName)
        .then(data => {
          setLoadingAutopsyEEPhotos(false);
          setAutopsyEEThumbnailPhotos(data);
        })
        .catch(error => {
          setLoadingAutopsyEEPhotos(false);
          console.error('Error fetching autopsy and external exam thumbnail photos:', error);
          setShowAEPErrorAlert({
            type: 'error',
            message: 'An error occured while fetching autopsy and external exam photo thumbnails',
          });
        });

      fetchMidsizePhotos()
        .then(data => {
          setAutopsyEEMidsizePhotos(data);
        })
        .catch(error => {
          console.error('Error fetching autopsy and external exam midsize photos:', error);
        });
    } else {
      setLoadingOtherPhotos(true);
      fetchThumbnailPhotos()
        .then(data => {
          setLoadingOtherPhotos(false);
          setOtherThumbnailPhotos(data);
        })
        .catch(error => {
          setLoadingOtherPhotos(false);
          console.error('Error fetching other thumbnail photos:', error);
          setShowOPErrorAlert({
            type: 'error',
            message: 'An error occured while fetching other photo thumbnails',
          });
        });

      fetchMidsizePhotos()
        .then(data => {
          setOtherMidsizePhotos(data);
        })
        .catch(error => {
          console.error('Error fetching other midsize photos:', error);
        });
    }
  };

  const handleDeletePhoto = () => {
    setShowConfirmation(true);
    setItemToDelete('');
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const handleCloseMidsizeImageDialog = () => {
    setMidsizeImageDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    setShowConfirmation(false);
    // let formData = new FormData();
    // formData.append('PHOTOSEQ', itemToDelete.photoSeq);
    // formData.append('CASENO', caseinfo.caseSummary.caseNumber);

    // fetch(REACT_APP_API_URL + 'filedelete', {
    //   method: 'POST',
    //   headers: {
    //     Authorization: 'Bearer ' + user.token,
    //   },
    //   body: formData,
    // })
    //   .then((res) => {
    //     if (res.status == 401) {
    //       throw new Error('You are unauthorized to use this tool');
    //     } else if (res.status >= 400) {
    //       throw new Error('An error occured');
    //     }
    //     return res.json();
    //   })
    //   .then((data) => {
    //     fetchDocuments();
    //   })
    //   .catch((e) => {
    //     alert(e);
    //   });
  };

  const downloadPhotos = async () => {
    setDownloading(true);

    let formData = new FormData();
    formData.append('CaseSeq', caseSeq);
    formData.append('UserSeq', user.userSeq);
    photoTypeSeqValues.forEach((value, index) => {
      formData.append('PhotoTypeSeq', value);
    });

    try {
      const response = await fetch(REACT_APP_API_URL + 'casephotos/retrieve/original', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('An error occurred while downloading the photos');
      }

      const blob = await response.blob();
      let fileName;
      const contentType = response.headers.get('Content-Type');
      if (contentType === 'application/zip') {
        if (moduleName === Module_Name.Medical_Imaging) {
          fileName = 'X-RAYS';
        } else if (moduleName === Module_Name.Scene) {
          fileName = 'SCENE PHOTOS';
        } else if (moduleName === Module_Name.Autopsy_EE) {
          fileName = 'EXAM PHOTOS';
        } else if (moduleName === Module_Name.Other_Photos) {
          fileName = 'OTHER PHOTOS';
        } else {
          fileName = 'CASE PHOTOS';
        }
        saveAs(blob, `${fileName}_${caseNumber}.zip`);
      } else {
        //it's a single image, save it with the original file name
        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename\*=\S*''([^;]*)/);
          if (filenameMatch && filenameMatch[1]) {
            fileName = decodeURIComponent(filenameMatch[1]);
          }
        }
        saveAs(blob, fileName ?? `CASE PHOTO_${caseNumber}`);
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setDownloading(false);
    }
  };

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType });
  };

  const ConfirmationModal = () => {
    return (
      <Dialog
        open={showConfirmation}
        onClose={handleCancelDelete}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>{'Confirm Void File'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to void this file?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDelete} autoFocus>
            Yes
          </Button>
          <Button autoFocus onClick={handleCancelDelete}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  let thumbnailPhotos;
  if (moduleName === Module_Name.Medical_Imaging) {
    thumbnailPhotos = xRayThumbnailPhotos;
  } else if (moduleName === Module_Name.Scene) {
    thumbnailPhotos = sceneThumbnailPhotos;
  } else if (moduleName === Module_Name.Autopsy_EE) {
    thumbnailPhotos = autopsyEEThumbnailPhotos;
  } else {
    thumbnailPhotos = otherThumbnailPhotos;
  }

  let midsizeImageMap;
  if (moduleName === Module_Name.Medical_Imaging) {
    midsizeImageMap = xRayMidsizePhotos?.reduce((map, midsize) => {
      map[midsize.photoSeq] = `data:image/jpeg;base64,${midsize.imageString}`;
      return map;
    }, {});
  } else if (moduleName === Module_Name.Scene) {
    midsizeImageMap = sceneMidsizePhotos?.reduce((map, midsize) => {
      map[midsize.photoSeq] = `data:image/jpeg;base64,${midsize.imageString}`;
      return map;
    }, {});
  } else if (moduleName === Module_Name.Autopsy_EE) {
    midsizeImageMap = autopsyEEMidsizePhotos?.reduce((map, midsize) => {
      map[midsize.photoSeq] = `data:image/jpeg;base64,${midsize.imageString}`;
      return map;
    }, {});
  } else {
    midsizeImageMap = otherMidsizePhotos?.reduce((map, midsize) => {
      map[midsize.photoSeq] = `data:image/jpeg;base64,${midsize.imageString}`;
      return map;
    }, {});
  }

  const getModifiedPhotoLabel = (photoLabel, index) => {
    const sequenceNumber = String(index + 1).padStart(3, '0');
    return photoLabel.length >= 3
      ? photoLabel.slice(0, -3) + sequenceNumber
      : photoLabel + sequenceNumber;
  };

  // Create an array of objects with midsize and thumbnail URLs
  const galleryImages = thumbnailPhotos?.map((thumbnail, index) => {
    const modifiedPhotoLabel = getModifiedPhotoLabel(thumbnail.photoLabel, index);

    return {
      photoSeq: thumbnail.photoSeq,
      midsize: midsizeImageMap[thumbnail.photoSeq],
      thumbnail: `data:image/jpeg;base64,${thumbnail.imageString}`,
      photoLabel: modifiedPhotoLabel,
      originalFileName: thumbnail.originalFileName,
      uploadedByUserSeq:
        sceneMidsizePhotos.find(
          p => p.photoSeq?.toLowerCase() === thumbnail?.photoSeq?.toLowerCase()
        )?.uploadedByUserSeq || GuidEmptySeq,
      midsizeClass: 'midsize-image',
    };
  });

  const MidsizeImageModal = ({ selectedImageIndex }) => {
    return (
      <Dialog
        open={midsizeImageDialogOpen}
        onClose={handleCloseMidsizeImageDialog}
        fullWidth
        PaperProps={{
          style: {
            width: isMobile ? '90%' : isLaptop ? '70%' : '60%',
            height: isMobile || isLaptop ? '80%' : '70%',
            maxWidth: '100%',
            maxHeight: '100%',
            margin: '0 auto',
            backgroundColor: '#333', // Dark gray background color
          },
        }}
      >
        <DialogContent>
          <CustomGallery
            images={galleryImages}
            selectedImageIndex={selectedImageIndex}
            onImageVoided={handleImageVoided}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMidsizeImageDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1001,
                backgroundColor: appTheme.palette.background.default,
              }}
              aria-label='case photos tabs'
              variant='scrollable'
              scrollButtons='auto'
            >
              <Tab label='Scene' />
              <Tab label='X-RAYS' />
              <Tab label='Exam Photos' />
              <Tab label='Other' />
            </Tabs>
          </Grid>

          {(() => {
            switch (moduleName) {
              case Module_Name.Medical_Imaging:
                return (
                  showXPErrorAlert && (
                    <Grid item xs={12}>
                      <CommonAlert
                        severity={showXPErrorAlert.type}
                        message={showXPErrorAlert.message}
                        resetKey={showXPErrorAlert}
                      />
                    </Grid>
                  )
                );
              case Module_Name.Scene:
                return (
                  showSPErrorAlert && (
                    <Grid item xs={12}>
                      <CommonAlert
                        severity={showSPErrorAlert.type}
                        message={showSPErrorAlert.message}
                        resetKey={showSPErrorAlert}
                      />
                    </Grid>
                  )
                );
              case Module_Name.Autopsy_EE:
                return (
                  showAEPErrorAlert && (
                    <Grid item xs={12}>
                      <CommonAlert
                        severity={showAEPErrorAlert.type}
                        message={showAEPErrorAlert.message}
                        resetKey={showAEPErrorAlert}
                      />
                    </Grid>
                  )
                );
              default:
                return (
                  showOPErrorAlert && (
                    <Grid item xs={12}>
                      <CommonAlert
                        severity={showOPErrorAlert.type}
                        message={showOPErrorAlert.message}
                        resetKey={showOPErrorAlert}
                      />
                    </Grid>
                  )
                );
            }
          })()}

          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              {downloading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <TailSpin
                    color='#00BFFF'
                    height={20}
                    width={20}
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                  />
                  <Stack>
                    <i style={{ color: '#00BFFF', paddingLeft: 10 }}>
                      I'm preparing the download for you, this may take a bit of time....
                    </i>
                    {notifications.notificationsConnection.state ===
                      HubConnectionState.Connected && (
                      <i style={{ color: '#00BFFF', paddingLeft: 10 }}>{downloadStatus}</i>
                    )}
                  </Stack>
                </div>
              ) : (
                <Button
                  color='primary'
                  startIcon={<ExportIcon />}
                  onClick={() => downloadPhotos()}
                  disabled={!thumbnailPhotos || thumbnailPhotos.length === 0}
                >
                  Download
                </Button>
              )}
              {/* <Button color='primary' startIcon={<DeleteIcon />} onClick={() => setOpen(true)}>
                      Void Selection
                    </Button> */}

              <Button color='primary' startIcon={<AddIcon />} onClick={() => setOpen(true)}>
                Upload
              </Button>
              <FormControlLabel
                control={
                  <Checkbox checked={unblurAll} onChange={handleUnblurChange} color='primary' />
                }
                label={
                  <Typography variant='body2'>Unblur</Typography> // Use the Typography component
                }
              />
            </div>
          </Grid>
        </Grid>
      </div>

      <div
        style={{ marginTop: '1rem', maxHeight: fullScreen ? '1000px' : '500px', overflowY: 'auto' }}
      >
        {/* 
        <FormControlLabel
          label='Select All'
          control={
            <Checkbox
              id='selectAll'
              name='selectAll'
              checked={selectAll}
              onChange={handleSelectAllChange}                   
            />
          }
        />
        */}

        {moduleName === Module_Name.Scene && loadingScenePhotos && (
          // <TailSpin color='#00BFFF' height={20} width={20} />
          <div style={{ display: 'flex', alignItems: 'center' }}>Loading...</div>
        )}

        {moduleName === Module_Name.Medical_Imaging && loadingXRayPhotos && (
          // <TailSpin color='#00BFFF' height={20} width={20} />
          <div style={{ display: 'flex', alignItems: 'center' }}>Loading...</div>
        )}

        {moduleName === Module_Name.Autopsy_EE && loadingAutopsyEEPhotos && (
          // <TailSpin color='#00BFFF' height={20} width={20} />
          <div style={{ display: 'flex', alignItems: 'center' }}>Loading...</div>
        )}

        {moduleName === Module_Name.Other_Photos && loadingOtherPhotos && (
          // <TailSpin color='#00BFFF' height={20} width={20} />
          <div style={{ display: 'flex', alignItems: 'center' }}>Loading...</div>
        )}

        {!(moduleName === Module_Name.Medical_Imaging && loadingXRayPhotos) &&
          !(moduleName === Module_Name.Scene && loadingScenePhotos) &&
          !(moduleName === Module_Name.Autopsy_EE && loadingAutopsyEEPhotos) &&
          !(moduleName === Module_Name.Other_Photos && loadingOtherPhotos) && (
            <Grid container spacing={2}>
              {thumbnailPhotos && thumbnailPhotos.length > 0 ? (
                thumbnailPhotos?.map((image, index) => (
                  <Grid item key={index} xs={3} sm={2} md={2} lg={1}>
                    {/* Adjusted grid item sizes */}
                    <Card
                      style={{
                        position: 'relative',
                        width: '100%',
                        paddingBottom: '100%',
                        cursor: 'pointer',
                      }}
                    >
                      <CardMedia
                        component='img'
                        image={`data:image/jpeg;base64,${image.imageString}`}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          filter:
                            unblurAll || moduleName === Module_Name.Medical_Imaging
                              ? 'none'
                              : 'blur(0.5rem)',
                        }}
                        onClick={() => handleThumbnailImageClick(index, image.photoSeq)}
                      />

                      <CardContent
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '5px 2px 2px 2px',
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        }}
                      >
                        {/*
                        <Typography 
                          variant="caption" 
                          style={{ fontSize: '0.75rem' }} // Customize the font size here
                        >
                          {image.photoLabel}
                        </Typography>
                        */}
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <div style={{ textAlign: 'left', marginLeft: '30px' }}>{``}</div>
                </Grid>
              )}
            </Grid>
          )}
      </div>

      {moduleName === Module_Name.Medical_Imaging && (
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={12}>
            <XrayRequestFormView caseSeq={caseSeq} />
          </Grid>
        </Grid>
      )}

      <ConfirmationModal />
      <MidsizeImageModal selectedImageIndex={selectedImageIndex} />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Grid item xs={12}>
        <MLPhotoDropzone
          isOpen={open}
          setIsOpen={setOpen}
          documentTypeRequired={true}
          caseNumber={caseNumber}
          caseSeq={caseSeq}
          onSuccessUpload={handleSuccessfulUpload}
          moduleName={moduleName}
        />
      </Grid>
    </div>
  );
};

export default CasePhotos;
