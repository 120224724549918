import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Box, TextField, Grid, Button, Checkbox, FormControlLabel, Tooltip, Divider } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import LockIcon from '@mui/icons-material/Lock';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import { Formik, Field } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import DoneIcon from '@mui/icons-material/Done';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { IconButton } from '@mui/material';
import authHeader from '../../../../services/auth-header';
import { GlobalContext } from '../../../../Context/GlobalContext';
import AccessDenied from '../../../../components/AdministrationModules/AccessDenied';
import Breadcrumb from '../../../../components/AdministrationModules/Breadcrumb';
// import { getDeleteStatus, getActiveStatus, notificationsValue } from '../../../../redux/store/admin/notificationTempSlice';
// import AccessDenied from 'ui-component/AccessDenied';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const Formikdisable = ({ textFieldProps, ...props }) => {
  const [setTouched, setSetTouched] = useState('');

  return (
    <Autocomplete
      {...props}
      onChange={(value, index) => setSetTouched(index)}
      getOptionSelected={'E-Mail'}
      disabled
      renderInput={props => <TextField {...props} label='E-Mail' />}
    />
  );
};

const NotificationTemplateView = () => {
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const notificationPermissions = permissions?.['Notification Template'];
  var navigate = useNavigate();
  const { sendNotificationTemplate, notificationDetails } = useContext(GlobalContext);
  const [selectedRows, setSelectedRows] = useState();
  const [skillsnew, setSkillsnew] = useState([]);
  // const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [icondata, setIcondtata] = useState('');
  const [open, setOpen] = useState(false);
  const [openapi, setOpenapi] = useState(false);
  const [checked, setChecked] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [rootData, setRootData] = useState([]);
  const [county, setCounty] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [nodePermissions, setNodePermissions] = useState({});

  const columns = [
    {
      field: 'deliveryTypeName',
      headerName: 'Delivery Type',
      // headerClassName: 'super-app-theme--header',
      minWidth: 200,
      align: 'left',
      flex: 5,
    },
    {
      field: 'notificationTemplateName',
      headerName: 'Template Name',
      // headerClassName: 'super-app-theme--header',
      minWidth: 200,
      align: 'left',
      flex: 5,
    },
    {
      field: 'subject',
      headerName: 'Subject',
      // headerClassName: 'super-app-theme--header',
      minWidth: 150,
      align: 'left',
      flex: 5,
    },
    {
      field: 'body',
      headerName: 'Template',
      // headerClassName: 'super-app-theme--header',
      minWidth: 150,
      align: 'left',
      flex: 5,
    },
    {
      field: 'countyName',
      headerName: 'Jurisdiction',
      // headerClassName: 'super-app-theme--header',
      minWidth: 150,
      align: 'left',
      flex: 5,
    },
    {
      field: 'status',
      headerName: 'Status',
      // headerClassName: 'super-app-theme--header',
      renderCell: cellValues => {
        const handleClick = event => {
          event.stopPropagation();
          setOpen(true);
          const newIconData = cellValues.row.status === 'Active' ? 'locked' : 'done';
          const selectedRows = [cellValues.row];
          setIcondtata({
            icon: newIconData,
            roleSeq: selectedRows[0].id,
            status: cellValues.row.status,
          });
        };
        return (
          <Button onClick={handleClick}>
            {cellValues.row.status === 'Active' ? (
              <Tooltip title='Active'>
                <LockIcon />
              </Tooltip>
            ) : (
              <Tooltip title='InActive'>
                <DoneIcon />
              </Tooltip>
            )}
          </Button>
        );
      },
    },
  ];

  const Close = () => setOpen(false);
  const searchInTable = () => {
    let filteredRow = rootData;
    if (!county && !searchQuery) {
      setNotificationData(filteredRow);
      return;
    }

    filteredRow = rootData
      ?.filter(row => {
        if (!county) {
          return row;
        }
        if (county?.label !== 'New Jersey') {
          return row?.countyName?.toLowerCase() == county?.label?.toLowerCase();
        } else {
          return row;
        }
      })
      ?.filter(row => {
        if (searchQuery && !checked) {
          const searchedData =
            row?.notificationTemplateName?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
            row?.subject?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
            row?.body?.toLowerCase().includes(searchQuery?.toLowerCase());
          return searchedData;
        } else if (searchQuery && checked) {
          const searchedData = row?.subject?.toLowerCase().includes(searchQuery?.toLowerCase());
          return searchedData;
        } else {
          return row;
        }
      });
    setNotificationData(filteredRow);
  };

  useEffect(() => {
    searchInTable();
  }, [searchQuery, county]);

  const changeStatus = async () => {
    setOpen(false);
    if (icondata.status === 'Inactive') {
      await axios.post(
        `${process.env.REACT_APP_API_URL}ActivateMasterData?seq=${icondata?.roleSeq}&tableName=notificationtemplate`,
        null,
        { headers: authHeader() }
      );
      getNotificationTemp();
      setOpenapi(true); // Open the dialog
    } else {
      await axios.post(
        `${process.env.REACT_APP_API_URL}InactivateMasterData?seq=${icondata?.roleSeq}&tableName=notificationtemplate`,
        null,
        { headers: authHeader() }
      );
      getNotificationTemp();
      setOpenapi(true); // Open the dialog
    }
  };

  const getNotificationTemp = async para => {
    // setIsLoading(true);
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}GetMasterData?tableName=notificationtemplate`,
      {
        headers: authHeader(),
      }
    );
    const delivery = await axios.get(
      `${process.env.REACT_APP_API_URL}GetMasterData?tableName=notificationdeliverytype`,
      { headers: authHeader() }
    );

    let updateNotification = [];

    for (const notificationTemp of res.data.data) {
      for (const deliverySeq of delivery.data.data) {
        if (
          notificationTemp?.notificationDeliveryTypeSeq === deliverySeq?.notificationDeliveryTypeSeq
        ) {
          updateNotification.push({
            ...notificationTemp,
            deliveryTypeName: deliverySeq.notificationDeliveryTypeName,
          });
        }
      }
    }

    getCounty(updateNotification);
    setIsLoading(false);
  };

  const getCounty = async updateNotification => {
    setIsLoading(true);
    const response = await axios.get(`${process.env.REACT_APP_API_URL}GetAllCounty`, {
      headers: authHeader(),
    });
    let newData = [];
    response.data.data.map(data => {
      newData.push({ label: data.countyName, value: data.countySeq });
      data.children.map(children =>
        newData.push({ label: children.countyName, value: children.countySeq })
      );
    });
    let updatedNotificationTemplate = [];
    for (var notificationTemp of updateNotification) {
      for (const countySequence of newData) {
        if (notificationTemp?.countySeq === countySequence?.value) {
          notificationTemp = {
            ...notificationTemp,
            countyName: countySequence.label,
            id: notificationTemp?.notificationTemplateSeq,
          };
          updatedNotificationTemplate.push(notificationTemp);
        } else if (notificationTemp?.countySeq === '00000000-0000-0000-0000-000000000000') {
          notificationTemp = {
            ...notificationTemp,
            countyName: '',
            id: notificationTemp?.notificationTemplateSeq,
          };
          updatedNotificationTemplate.push(notificationTemp);
        }
      }
    }
    const filtered = updatedNotificationTemplate.filter(
      (obj, index) => updatedNotificationTemplate.findIndex(item => item.id === obj.id) === index
    );
    setSkillsnew(newData);
    setNotificationData(filtered);
    setRootData(filtered);
    setIsLoading(false);
  };

  useEffect(() => {
    getNotificationTemp();
  }, []);

  useEffect(() => {
    if (selectedRows) {
      sendNotificationTemplate(selectedRows);
      navigate('/sysadmin/editnotification');
    }
  }, [selectedRows]);

  const handleNewTemplate = () => {
    sendNotificationTemplate([]);
    navigate('/sysadmin/editnotification');
  };

  const checkStatus = name => {
    const selectedUser = notificationData?.filter(
      notification => notification?.notificationTemplateSeq === name
    );
    return selectedUser?.[0]?.status.toLowerCase() === 'inactive';
  };

  const MENU_OBJECT = {
    Home: '/sysadmin',
    'Notificaton Template': 'sysadmin/notificationtemplate',
  };

  if (!notificationPermissions?.view) {
    return <AccessDenied />;
  } else {
    return (
      // <div className='container my-5 py-5'>
      <Grid container spacing={2} sx={{ mt: '10px', padding: '16px' }}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {' '}
              <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
              <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0, mb: 3 }} />
              <Formik>
                {/* <MainCard> */}
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2} pb={1}>
                    <Grid item lg={3} xs={12} md={12} sm={12}>
                      <FormControl
                        error
                        required
                        component='fieldset'
                        variant='standard'
                        sx={{ minWidth: '100%' }}
                      >
                        <label>Delivery Type</label>
                        <Field
                          name='skillsnew'
                          component={Formikdisable}
                          label='Delivery Type'
                          id='combo-box-demo'
                          disabled
                          textFieldProps={{
                            variant: 'outlined',
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item lg={3} xs={12} md={12} sm={12}>
                      <FormControl
                        error
                        required
                        component='fieldset'
                        variant='standard'
                        sx={{ marginTop: '8%', minWidth: '100%' }}
                      >
                        <Autocomplete
                          options={skillsnew}
                          onChange={(skills, index) => {
                            setCounty(index);
                          }}
                          getOptionLabel={skills => skills.label}
                          renderInput={params => (
                            <>
                              <TextField {...params} label='Choose a Jurisdiction' />
                            </>
                          )}
                          renderOption={(props, option, { selected }) => (
                            <>
                              <li
                                {...props}
                                style={{
                                  marginLeft:
                                    option.label == 'New Jersey' ||
                                    option.label == 'NJ State Case' ||
                                    option.label == 'MAiD Case'
                                      ? ''
                                      : '15px',
                                }}
                              >
                                {option.label}
                              </li>
                            </>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={4} xs={12} md={12} sm={12}>
                      <FormControl
                        error
                        required
                        component='fieldset'
                        variant='standard'
                        sx={{ marginTop: '6%', minWidth: '100%' }}
                      >
                        <TextField
                          label='Search'
                          variant='outlined'
                          value={searchQuery}
                          onChange={e => setSearchQuery(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={2} xs={2} md={2} sm={2}>
                      <FormControl sx={{ marginTop: '15%' }}>
                        <FormControlLabel
                          value='end'
                          control={
                            <Checkbox
                              checked={checked}
                              onChange={e => setChecked(e.target.checked)}
                              id='check-all'
                            />
                          }
                          label='Subject Only'
                          labelPlacement='end'
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                {/* </MainCard> */}
              </Formik>
              {/* <MainCard sx={{ marginTop: '-50px !important' }}> */}
              <Box
                // sx={{
                //   width: '100%',
                //   '& .super-app-theme--header': {
                //     backgroundColor: '#2196f3',
                //   },
                // }}
                style={{ tr: 'hover', cursor: 'pointer' }}
              >
                {notificationData ? (
                  <DataGrid
                    rowHeight={35}
                    rows={notificationData}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick={false}
                    onRowSelectionModelChange={ids => {
                      if (
                        notificationPermissions?.edit &&
                        !checkStatus(ids?.[0]) &&
                        ids?.length !== 0
                      ) {
                        const selectedIDs = new Set(ids);
                        const selectedRows = notificationData?.filter(row =>
                          selectedIDs.has(row.notificationTemplateSeq)
                        );
                        setSelectedRows(selectedRows);
                      }
                    }}
                    // sx={{
                    //   m: 2,
                    //   marginLeft: '0px',
                    //   fontSize: '14px',
                    //   fontFamily: 'Roboto , Helvetica , Arial , sans-serif !important',
                    //   height: 500,
                    //   width: '100%',
                    //   borderRadius: '6px',
                    //   backgroundColor: 'white',
                    //   // boxShadow: '0px 0px 10px #ddd',
                    //   direction: 'row',
                    //   overflow: 'hidden',
                    // }}
                  />
                ) : (
                  ''
                )}
                <Box sx={{ float: 'right', marginBottom: '56px' }}>
                  <Button onClick={handleNewTemplate} variant='contained'>
                    Add Template
                  </Button>
                </Box>
                <div className='modal'>
                  <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={Close}
                    aria-describedby='alert-dialog-slide-description'
                  >
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                      <DialogContentText id='alert-dialog-slide-description'>
                        Are you sure you want to{' '}
                        {icondata?.status === 'Active' ? 'InActive ' : 'Active'} this Template?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={Close}>No</Button>
                      <Button onClick={changeStatus}>Yes</Button>
                    </DialogActions>
                  </Dialog>
                </div>
                <div className='modal'>
                  <Dialog
                    open={openapi}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={Close}
                    aria-describedby='alert-dialog-slide-description'
                  >
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                      <DialogContentText id='alert-dialog-slide-description'>
                        {icondata.status === 'Inactive'
                          ? 'Data has been Activated Successfully,'
                          : 'Data has been InActivated Successfully,'}
                        ,
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <IconButton onClick={() => setOpenapi(false)}>
                        <CloseIcon onClick={Close} />
                      </IconButton>
                    </DialogActions>
                  </Dialog>
                </div>
              </Box>
              {/* </MainCard> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      // </div>
    );
  }
};

export default NotificationTemplateView;
