import { Box, Divider, Skeleton, Stack } from '@mui/material';

export default function LMSCaseLoading() {
  return (
    <Box sx={{ p: 3, width: '100%', height: '100%' }}>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Stack direction='row' spacing={2}>
          <Stack sx={{ mr: 'auto' }}>
            <Skeleton variant='text' width='18rem' sx={{ fontSize: '1.5rem' }} />
            <Skeleton variant='text' width='8rem' />
            <Skeleton variant='text' width='10rem' />
          </Stack>
          <Skeleton variant='circular' width='3rem' height='3rem' />
          <Skeleton variant='circular' width='3rem' height='3rem' />
          <Skeleton variant='circular' width='3rem' height='3rem' />
          <Skeleton variant='circular' width='3rem' height='3rem' />
        </Stack>
      </Box>
      <Box
        sx={{
          display: { xs: 'block', sm: 'none' },
        }}
      >
        <Stack
          spacing={2}
          sx={{
            display: { xs: 'block', sm: 'none' },
          }}
        >
          <Stack
            sx={{
              alignItems: 'center',
            }}
          >
            <Skeleton variant='text' width='14rem' sx={{ fontSize: '1.5rem' }} />
            <Skeleton variant='text' width='8rem' />
            <Skeleton variant='text' width='10rem' />
          </Stack>

          <Stack
            direction='row'
            spacing={2}
            sx={{
              justifyContent: 'center',
            }}
          >
            <Skeleton variant='circular' width='2.5rem' height='2.5rem' />
            <Skeleton variant='circular' width='2.5rem' height='2.5rem' />
            <Skeleton variant='circular' width='2.5rem' height='2.5rem' />
            <Skeleton variant='circular' width='2.5rem' height='2.5rem' />
          </Stack>
        </Stack>
      </Box>

      <Divider sx={{ borderColor: '#555', my: 3 }} />

      <Stack direction='row' spacing={2}>
        <Skeleton variant='rounded' width='15rem' height='10rem' />
        <Skeleton variant='rounded' width='100%' height='10rem' />
      </Stack>

      <Skeleton variant='text' sx={{ fontSize: '2rem', mt: 4 }} />
      <Skeleton variant='rounded' width='100%' height='50%' />
    </Box>
  );
}
