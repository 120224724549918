import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CustomHeader from '../../../../utils/components/CustomHeader';
import DashboardCard from './DashboardCard';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeItem2 } from '@mui/x-tree-view/TreeItem2';

const NUMBER_OF_CARDS = 12; // Change this constant to control the number of cards

const SAMPLE_TREE_VIEW_ITEMS = [
  {
    id: 'grid',
    label: 'Data Grid',
    children: [
      { id: 'grid-community', label: '@mui/x-data-grid' },
      { id: 'grid-pro', label: '@mui/x-data-grid-pro' },
      { id: 'grid-premium', label: '@mui/x-data-grid-premium' },
    ],
  },
  {
    id: 'pickers',
    label: 'Date and Time Pickers',
    children: [
      { id: 'pickers-community', label: '@mui/x-date-pickers' },
      { id: 'pickers-pro', label: '@mui/x-date-pickers-pro' },
    ],
  },
  {
    id: 'charts',
    label: 'Charts',
    children: [{ id: 'charts-community', label: '@mui/x-charts' }],
  },
  {
    id: 'tree-view',
    label: 'Tree View',
    children: [{ id: 'tree-view-community', label: '@mui/x-tree-view' }],
  },
];

export default function ServerMonitoringDashboard() {
  return (
    <Box>
      <CustomHeader
        title='Server Monitoring Dashboard'
        description='Example page to show what a dashboard for server diagnostics might look like.  This page only shows to systems admins and is here as a development placeholder.'
        showMenuButton={false}
      />
      <Grid container spacing={3} justifyContent='space-evenly'>
        <Grid xs={12}>
          <RichTreeView items={SAMPLE_TREE_VIEW_ITEMS} slots={{ item: TreeItem2 }} />
        </Grid>
        {Array.from({ length: NUMBER_OF_CARDS }).map((_, index) => (
          <Grid key={index} xs='auto'>
            <DashboardCard />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
