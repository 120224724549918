import React from 'react';
import {
  Skeleton,
  Card,
  CardContent,
  Stack,
  Typography,
  CardActions,
  Grid,
  Button,
  IconButton,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const DailyCaseCardSkeleton = () => {
  return (
    <React.Fragment>
      <Card sx={{ minWidth: 275, my: 1 }}>
        <CardContent>
          <Stack direction='row' spacing={1}>
            <Skeleton variant='text' width={40} />
            <Skeleton variant='circular' width={20} height={20} />
            <Skeleton variant='circular' width={20} height={20} />
          </Stack>

          <Skeleton variant='text' height={40} sx={{ my: 1 }} />
          <Skeleton variant='rectangular' height={56} sx={{ my: 1 }} />
          <Skeleton variant='rectangular' height={56} sx={{ my: 1 }} />
          <Skeleton variant='rectangular' height={56} sx={{ my: 1 }} />
        </CardContent>
        <CardActions>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Skeleton variant='rectangular' width={60} height={30} />
            </Grid>
            <Grid item>
              <IconButton size='small' sx={{ mr: 1.5 }}>
                <KeyboardArrowDownIcon />
              </IconButton>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </React.Fragment>
  );
};
