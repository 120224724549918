console.log('Hello World!');

export const validateDateTimes = (startDateTime?: string | null, endDateTime?: string | null): boolean => {
    if (startDateTime && endDateTime) {
      const startDate = new Date(startDateTime);
      const endDate = new Date(endDateTime);

      return endDate < startDate;
    }
    return false;
  };