import React from 'react'
import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@mui/material'

const Breadcrumb = ({ MENU_OBJECT }) => {
    return (
        <Breadcrumbs separator='/' aria-label='breadcrumb' sx={{ m: 1, mt: 0 }}>
            {
                Object.entries(MENU_OBJECT).map(([key, value], index) => {
                    if (index === Object.keys(MENU_OBJECT).length - 1) {
                        return (<Typography color='text.primary'>
                            <b>{key}</b>
                        </Typography>)
                    }
                    return <RouterLink
                        style={{ textDecoration: 'none' }}
                        color='text.primary'
                        to={value}
                        onClick={() => {
                            localStorage.setItem('currentSysAdminTab', key === 'Reference Data' ? 'Reference Data' : '');
                        }}
                    >
                        {key}
                    </RouterLink>
                })}
        </Breadcrumbs >
    )
}

export default Breadcrumb