import { Button } from '@mui/material';

import { GridColDef } from '@mui/x-data-grid-premium';

import SiloDataGrid from '../../utils/components/SiloDataGrid';
import { DecedentNameCell } from './DecedentNameCell';
import { DashboardDataGridProps } from './useDashboardDataGridAPI.hook';

export function OpenIdentificationsDataGrid({ data, isLoading }: DashboardDataGridProps) {
  const columns: GridColDef[] = [
    {
      field: 'caseid',
      headerName: 'CaseID',
      width: 120,
      editable: false,
      align: 'left',
      headerAlign: 'left',

      renderCell: params => {
        return (
          <Button
            color='primary'
            onClick={() => {
              // handleCaseClick(event, params);
            }}
          >
            <b> {params.formattedValue} </b>
          </Button>
        );
      },
    },
    {
      field: 'decedentname',
      headerName: 'Decedent',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: params => <DecedentNameCell {...params} />,
    },

    {
      field: 'investigator',
      headerName: 'MLDI',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  return (
    <SiloDataGrid
      loading={isLoading}
      listresults={data}
      columnsInput={columns}
      idcolumn='caseid'
      gridheight={200}
      gridcolor='#CF9FFF'
      title='Open Identifications'
      tooltip='This grid represents cases that are waiting for Investigator info on the Identification of the Decedent'
      casecount
      rowsperpage={50}
      filterModel={undefined}
      columnsVisibility={undefined}
    />
  );
}
