import React, { useEffect } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import { Tab } from '@mui/material';
import { Container } from '@mui/system';
import { useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Contact from './Contact';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import authHeader from '../../../services/auth-header';

const { REACT_APP_API_URL } = process.env;

const id = '00000000-0000-0000-0000-000000000000';

const ContactData = ({ contactItem = [], handleChange }) => {
  const [selectedTab, setSelectedTab] = React.useState(1);
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));

  const removeContactTab = (contactIndex) => {
    const tempPanels = contactItem.filter(
      (tab, index) => index != contactIndex
    );
    handleChange(tempPanels);
    setSelectedTab(1);
  };
  const handleContactSelectionChange = (e, index) => {
    const tempPanels = JSON.parse(JSON.stringify(contactItem));
    tempPanels[index][e.target.name] = e.target.value;
    handleChange(tempPanels);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const createNewTab = () => {
    let makeTab = true;
    contactItem?.forEach((panel) => {
      if (!panel.contactItemTypeSeq || !panel.contactItemDetails) {
        makeTab = false;
      }
    });
    if (!makeTab) {
      // show dialog box that first fill mandatory fields of all tabs to add new contact
      return;
    }
    handleChange([
      ...contactItem,
      {
        contactItemSeq: id,
        contactItemTypeSeq: '',
        contactItemDetails: '',
        contactOrder: '1',
        isActive: true,
        contactItemIsPrimary: false,
        status: 'Active',
      },
    ]);
    setSelectedTab(contactItem.length + 1);
  };

  const [contactTypeNames, setContactTypeNames] = useState({});

  const getContactTypes = () => {
    const tempContactTypeNames = {};
    axios
      .get(REACT_APP_API_URL + 'GetContactItemType', {
        headers: authHeader(),
      })
      .then((response) => {
        response.data.data.forEach((contactType) => {
          tempContactTypeNames[contactType.contactItemTypeSeq] = contactType;
        });
        setContactTypeNames(tempContactTypeNames);
      });
  };

  useEffect(() => {
    getContactTypes();
  }, []);

  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: 'red' },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderRadius: '10px',
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={false} style={{ padding: 0 }}>
        <Button
          onClick={createNewTab}
          startIcon={<AddCircleIcon />}
          variant='contained'
          sx={{ my: 0, backgroundColor: 'rgb(2, 136, 209)' }}
        >
          Add Contact
        </Button>
        <TabContext value={selectedTab}>
          <TabList onChange={handleTabChange} sx={{ minWidth: '30px' }}>
            {contactItem?.map((tab, index) => (
              <Tab
                label={index + 1}
                value={index + 1}
                key={index + 1}
                sx={{ minWidth: '30px' }}
              />
            ))}
          </TabList>

          {Object.keys(contactTypeNames).length
            ? contactItem?.map((panel, index) => (
                <TabPanel
                  label={index + 1}
                  value={index + 1}
                  key={panel?.value}
                  sx={{ paddingLeft: '0px', paddingBottom: '0px', paddingRight: '0px' }}
                >
                  <Contact
                    removeContactTab={removeContactTab}
                    handleContactSelectionChange={handleContactSelectionChange}
                    panel={panel}
                    index={index}
                    panels={contactItem}
                    contactTypeNames={contactTypeNames}
                  />
                </TabPanel>
              ))
            : null}
        </TabContext>
      </Container>
    </ThemeProvider>
  );
};

export default ContactData;
