import { InputAdornment } from '@mui/material';
import dayjs from 'dayjs';
import { FieldMetaProps, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { useOptionsAPI } from '../../utils/api/useOptions.hook';
import { CaseViewOptionsAutocomplete } from '../../utils/components/CaseViewOptionsAutocomplete';
import { FormikTextField } from '../../utils/components/FormikTextField';
import { AgeUnits, calculateAgeBirthToDeath } from '../../utils/functions/calculateAgeBirthToDeath';
import { Option } from '../../views/Case/CaseViewModules/MortuaryModules/XrayRequestForm/xrayrequestform.constants';

export function CaseViewAge() {
  const formik = useFormikContext<any>();
  const { options: ageUnitOptions } = useOptionsAPI('getageunitoptions');

  const dateOfBirthMeta: FieldMetaProps<any> = formik.getFieldMeta('caseDemographics.dateOfBirth');
  const dateOfBirth = dayjs(dateOfBirthMeta.value);

  const dateOfPronouncementMeta: FieldMetaProps<any> = formik.getFieldMeta(
    'caseDemographics.pronouncer.pronounciationDateTime'
  );
  const dateOfPronouncement = dayjs(dateOfPronouncementMeta.value);

  const dateOfDeathMeta: FieldMetaProps<any> = formik.getFieldMeta('caseDemographics.dateOfDeath');
  const dateOfDeath = dayjs(dateOfDeathMeta.value);

  const deathDates = [dateOfDeath, dateOfPronouncement];

  function calculateAge() {
    for (let i = 0; i < deathDates.length; i++) {
      const deathDate = deathDates[i];

      if (dateOfBirth.isValid() && deathDate.isValid()) {
        const { value: calculatedAge, unit: calculatedUnit } = calculateAgeBirthToDeath({
          birth: dateOfBirth,
          death: deathDate,
        });
        const unitName =
          Object.keys(AgeUnits).find(
            key => AgeUnits[key as keyof typeof AgeUnits] === calculatedUnit
          ) || 'Unknown';
        formik.setFieldValue('caseDemographics.age', calculatedAge);
        formik.setFieldValue('caseDemographics.ageUnit', {
          optionName: unitName,
          optionSeq: calculatedUnit,
        });

        return;
      }
    }
  }

  useEffect(() => {
    calculateAge();
  }, [
    formik.values?.caseDemographics?.dateOfDeath,
    formik.values?.caseDemographics?.pronouncer?.pronounciationDateTime,
    formik.values?.caseDemographics?.dateOfBirth,
  ]);

  const selectedAgeUnit: Option = useMemo(() => {
    if (Boolean(formik.values?.caseDemographics?.ageUnit)) {
      return formik.values?.caseDemographics?.ageUnit;
    }
    return {
      optionName: 'Unknown',
      optionSeq: AgeUnits.Unknown,
    };
  }, [
    ageUnitOptions,
    formik.values?.caseDemographics?.dateOfDeath,
    formik.values?.caseDemographics?.dateOfBirth,
    formik.values?.caseDemographics?.age,
  ]);

  return (
    <>
      {/* Unit:{JSON.stringify(selectedAgeUnit.optionName)}, Age:
      {JSON.stringify(formik.values?.caseDemographics?.age)}, DOB:
      {JSON.stringify(formik.values?.caseDemographics?.dateOfBirth)}, DOD:
      {JSON.stringify(formik.values?.caseDemographics?.dateOfDeath)} */}
      <FormikTextField
        authorizedToEdit={r => [
          r.Investigator,
          r.Medical_Examiner,
          r.Administrative_Assistant,
          r.Historic_Dataentryonly,
          r.Systems_Admin,
        ]}
        type='number'
        onBlur={e => {
          const newValue = e.target.value === '' ? null : Number(e.target.value);
          formik.setFieldValue('caseDemographics.age', newValue);
        }}
        formikField='caseDemographics.age'
        forceUpdateLocalStateWhenValueChanges={true}
        label='Age'
        InputLabelProps={{
          shrink: true,
          sx: {
            width: '100%',
          },
        }}
        InputProps={{
          endAdornment: !formik.status.editing ? (
            <InputAdornment position='end'>{selectedAgeUnit?.optionName}</InputAdornment>
          ) : (
            <InputAdornment position='end' sx={{ width: '100%', padding: 0, margin: 0 }}>
              <CaseViewOptionsAutocomplete
                label={<></>}
                optionsEndpoint='getageunitoptions'
                formikField='caseDemographics.ageUnit'
                disableClearable={true}
                textFieldProps={{
                  hiddenLabel: true,
                  variant: 'filled',
                  sx: {
                    '& .MuiInputBase-root': {
                      padding: '0 24px 0 8px !important',
                      minHeight: '0px',
                    },
                    '& .MuiFilledInput-input': {
                      padding: '4px 0',
                    },
                  },
                }}
                sx={{
                  width: '100%',
                  '& .MuiAutocomplete-input': {
                    width: '100%',
                    textOverflow: 'ellipsis',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '& .MuiAutocomplete-endAdornment': {
                    right: '0px',
                  },
                }}
              />
            </InputAdornment>
          ),
        }}
        sx={
          !formik.status.editing
            ? undefined
            : {
                display: 'flex',
                width: '100%',
                '& .MuiInputBase-root': {
                  display: 'flex',
                  width: '100%',
                  padding: '0 !important',
                },
                '& .MuiInputBase-input[type="number"]': {
                  flexGrow: 1,
                  width: 'auto',
                  padding: '8px 12px !important',
                  minWidth: '40px',
                  marginRight: '8px',
                },
                '& .MuiInputAdornment-root': {
                  margin: 0,
                  height: 'auto',
                  maxWidth: '50%',
                },
              }
        }
      />
    </>
  );
}
