import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';

import { Button } from '@mui/material';

import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import { useAuth } from '../../../utils/auth/AuthService';
import SiloDataGrid from '../../../utils/components/SiloDataGrid';

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = gridPageSelector(apiRef);
  const pageCount = gridPageCountSelector(apiRef);

  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      renderItem={props2 => (
        // @ts-expect-error
        <PaginationItem {...props2} sx={{ fontFamily: 'DataGrid, sans-serif' }} disableRipple />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{ mr: 3 }}
    />
  );
}

export default function QAReviewModule() {
  const [loading, setLoading] = useState(false);
  const [caseresults, setCaseResults] = useState([]);
  /* const [redirect, setRedirect] = useState(null);*/

  const navigate = useNavigate();

  const handleCaseClick = () => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const columns = [
    {
      field: 'caseid',
      headerName: 'Case ID',
      width: 110,
      editable: false,
      renderCell: params => {
        return (
          <Button
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
            }}
          >
            <b> {params.formattedValue} </b>
          </Button>
        );
      },
    },
    {
      field: 'decedentname',
      headerName: 'Decedent Name',
      width: 110,
      editable: false,
    },

    {
      field: 'blcity',
      headerName: 'City',
      width: 75,
      editable: false,
    },
    {
      field: 'blzip',
      headerName: 'Zip',
      width: 75,
      editable: false,
    },

    {
      field: 'investigator',
      headerName: 'Investigator',
      width: 175,
      editable: false,
    },
    {
      field: 'meaction',
      headerName: 'ME Action',
      width: 110,
      editable: false,
    },

    {
      field: 'menotified',
      headerName: 'Call Taken',
      width: 220,
      editable: false,
    },
    {
      field: 'username',
      headerName: 'User Name',
      width: 220,
      editable: false,
    },
    //{
    //    field: 'finalreviewby',
    //    headerName: 'Reviewed By',
    //    width: 220,
    //    editable: false,
    //}
  ];

  const { REACT_APP_API_URL } = process.env;
  const { user } = useAuth();

  useEffect(() => {
    loadData(); //move the API code into a function for loading by default
  }, []);

  //gather search params from form and FETCH/POST to API
  const loadData = async () => {
    let formData = new FormData();

    formData.append('JDXLIST', JSON.stringify(user.jdxAccessList));

    setLoading(true);

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'QALegacyImport', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setCaseResults(data);
          setLoading(false);
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      console.log('user or token is null');
    }
  };

  return (
    <SiloDataGrid
      loading={loading}
      listresults={caseresults}
      columnsInput={columns}
      idcolumn='caseid'
      gridheight={600}
      gridcolor='#a61930'
      title='Historical Cases that were Imported via Legacy Import Process'
      tooltip='This datagrid shows the number of Historical Cases that were Imported via Legacy Import Process'
      casecount
      rowsperpage={50}
    />
  );
}
