import { Box, Link, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useAuth } from '../auth/AuthService';

const { REACT_APP_API_URL } = process.env;

export function ErrorFallback_View({ error }: { error: any }) {
  const location = useLocation();
  const auth = useAuth();
  const [sendingError, setSendingError] = useState<string | null>(null);
  const [supportEmail, setSupportEmail] = useState<string>('');

  const sendEmail = async () => {
    if (process.env.NODE_ENV === 'development') return;

    const timestamp = new Date().toISOString();
    const formData = new FormData();
    formData.append('Subject', `[AUTO] Error Report - ${auth.user.userName} ${timestamp}`);
    formData.append(
      'Body',
      `
This is an automatically generated error report.
Timestamp: ${timestamp}

Error Details:
${
  error instanceof Error
    ? `
Type: ${error.name}
Message: ${error.message}
Stack Trace:
${error.stack}
`
    : `Unstructured Error:
${JSON.stringify(error, null, 2)}`
}

Client Information:
User Agent: ${window.navigator.userAgent}
Screen Resolution: ${window.screen.width}x${window.screen.height}
Window Size: ${window.innerWidth}x${window.innerHeight}
Device Pixel Ratio: ${window.devicePixelRatio}

This report was generated automatically when the error occurred.
No action is required from the user.`
    );
    formData.append('ReactRouterLocation', JSON.stringify(location));

    try {
      const response = await fetch(`${REACT_APP_API_URL}sendfeedbackemail`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.user.accessToken}`,
        },
        body: formData,
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.errorMessage || 'An error occurred while sending error report');
      }

      if (result.successfulSends !== 1) {
        throw new Error('Failed to send error report. Please try again.');
      }
    } catch (e) {
      setSendingError(
        e instanceof Error ? e.message : 'An unknown error occurred while sending the error report'
      );
    }
  };

  const fetchSupportEmail = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}getsupportemailaddress`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.user.accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch support email');
      }

      const email = await response.text();
      setSupportEmail(email);
    } catch (error) {
      console.error('Error fetching support email:', error);
      // Fallback to a default value in case of error
      setSupportEmail('medex.labs@doh.nj.gov');
    }
  };

  useEffect(() => {
    sendEmail();
    fetchSupportEmail();
  }, []);

  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
        minHeight: '100%',
        textAlign: 'center',
      }}
    >
      <Typography variant='h4' gutterBottom sx={{ fontWeight: 'bold' }}>
        Uh oh :(
      </Typography>
      <Typography variant='body1' gutterBottom>
        Something went wrong:
      </Typography>
      <pre style={{ color: 'red' }}>
        {error instanceof Error && (
          <Stack>
            <strong>{error.name} Details:</strong>
            <p>Message: {error.message}</p>
            <p>Stack: {error.stack}</p>
          </Stack>
        )}
        <Stack>
          <strong>Location Details:</strong>
          <p>Pathname: {location.pathname}</p>
          <p>State: {JSON.stringify(location.state)}</p>
        </Stack>
        <Stack>
          <strong>Browser Details:</strong>
          <p>User Agent: {window.navigator.userAgent}</p>
          <strong>Device Details:</strong>
          <p>
            Width: {window.screen.width}, Height: {window.screen.height}
          </p>
        </Stack>
      </pre>
      <Typography variant='h6' gutterBottom>
        Please report this issue to{' '}
        <Link href={`mailto:${supportEmail}`} underline='hover'>
          {supportEmail}
        </Link>
      </Typography>
    </Box>
  );
}
