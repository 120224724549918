import DailyCaseListIcon from '@mui/icons-material/RecentActors';

import BackupTableIcon from '@mui/icons-material/BackupTable';
import GradingIcon from '@mui/icons-material/Grading';
import CustomDrawer from '../../utils/components/CustomDrawer';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip,
  Hidden
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import '../../App.css';
import AuthService from '../../services/auth.service';

import { getDynamicMenu } from '../../services/utility-api';
import { useAuth } from '../../utils/auth/AuthService';
import { ADMIN_MENU_ICON, CASE_ADMIN } from '../CMSAdmin/Menu/constants';
import CustomTabPanel from '../../utils/components/CustomTabPanel';
import UnlockCase from './CaseAdminModules/UnlockCaseView';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const drawerWidth = 240;

export default function CaseAdminView() {
  const [redirect, setredirect] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menu, setMenu] = useState([]);
  const [tabContent, setTabContent] = useState('Reference Data');
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { user } = useAuth();

  const handleTabChange = (event, newTab) => {
    setTabContent(event.target.textContent);
    setCurrentTab(newTab);
  };

  const handleMenuClick = () => {
    setMenuOpen(true);
  };

  useEffect(() => {
    const currentUser = AuthService.getCurrentUser();
    if (!user) {
      return;
    }
    getDynamicMenu(user?.userSeq).then(response => {
      let formatMenu = [];
      response?.data?.data?.dynamicMenu?.[0]?.children?.filter(menuItems => {
        if (CASE_ADMIN?.[menuItems?.treeName]) {
          formatMenu.push({
            text: menuItems?.treeName,
            icon: ADMIN_MENU_ICON?.[menuItems?.treeName]?.icon,
          });
        }
      });
      setMenu(formatMenu);
    });
  }, []);

  const drawerContents = [
    { text: 'Unlock Case', icon: <LockOpenIcon />, disabled: false },
    ...menu, //this is being dynamically generated..I think it's too abstract...should declare all the items here imo...df
  ];

  const fetchComponent = () => {
    const menuName = menu?.filter(content => content?.treeName === tabContent);
    if (menuName?.[0]?.children?.length > 0) {
      return <SubmenuView title={tabContent} children={menuName?.[0]?.children} />;
    } else {
      return ADMIN_MENU_ICON?.[tabContent]?.component;
    }
  };

  return redirect ? (
    <Navigate to={redirect} />
  ) : (
    <Box
      component='section'
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      {/* Mobile and Desktop Drawer*/}
      <CustomDrawer
        drawerContents={drawerContents}
        currentTab={currentTab}
        // handleTabChange={handleTabChange}
        setCurrentTab={setCurrentTab}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        drawerWidth={drawerWidth}
        setTabContent={setTabContent}
      />
      {/* Page Contents */}
      <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
       <CustomTabPanel value={currentTab} index={0}>
          <UnlockCase handleMenuClick={handleMenuClick} />
        </CustomTabPanel>
        <CustomTabPanel sx={{ margin: 0 }} value={currentTab} index={currentTab}>
          <Box>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ ml: 'auto', mr: 2, mt: 1 }}>
                <Hidden mdUp>
                  <Tooltip title='Show Case Menu'>
                    <IconButton
                      size={isMediumScreen ? 'medium' : 'large'}
                      sx={{ width: 'max-content', height: 'max-content' }}
                      onClick={handleMenuClick}
                    >
                      <MenuIcon fontSize={isMediumScreen ? 'medium' : 'large'} />
                    </IconButton>
                  </Tooltip>
                </Hidden>
              </Box>
            </Box>
          </Box>
          {fetchComponent()}

        </CustomTabPanel>
      </Box>
    </Box>
  );
}
