import { ContentCopyRounded } from '@mui/icons-material';
import { Button, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';

export function AddressCell({ address }: { address: string | null }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [showCopyButton, setShowCopyButton] = useState(false);
  const [tooltipText, setTooltipText] = useState('Copy to clipboard');

  const handleCopyToClipboard = () => {
    if (address) {
      navigator.clipboard.writeText(address);
      setTooltipText('Copied to clipboard');
      setTimeout(() => setTooltipText('Copy to clipboard'), 2000);
    }
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!address) {
    return <span>Unknown</span>;
  }

  return (
    <div
      style={{ position: 'relative' }}
      onMouseEnter={() => setShowCopyButton(true)}
      onMouseLeave={() => setShowCopyButton(false)}
    >
      {showCopyButton && (
        <Tooltip title={tooltipText} placement='top' arrow>
          <IconButton
            color='primary'
            onClick={handleCopyToClipboard}
            size='small'
            style={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 1,
            }}
          >
            <ContentCopyRounded fontSize='small' />
          </IconButton>
        </Tooltip>
      )}
      <Button
        variant='text'
        href={`http://maps.apple.com/?q=${encodeURIComponent(address || '')}`}
        target='_blank'
        onMouseEnter={handlePopoverOpen}
        fullWidth
        sx={{
          justifyContent: 'flex-start',
          textAlign: 'left',
          textTransform: 'uppercase',
          paddingLeft: showCopyButton ? '32px' : 0, // Add left padding when copy button is shown
          '& .MuiButton-label': {
            display: 'block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '100%',
          },
        }}
      >
        {address || 'Unknown'}
      </Button>
      {/* <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: { maxWidth: 400, minWidth: 400 },
            onMouseLeave: handlePopoverClose,
          },
        }}
        disableRestoreFocus
      >
        <AddressMapCard addressSeq={addressSeq!} />
      </Popover> */}
    </div>
  );
}
