import React, { useContext } from 'react';
import { Modal, Grid, Button, Typography } from '@mui/material';
import { GlobalContext } from '../../../Context/GlobalContext';
import ReactPanZoom from 'react-image-pan-zoom-rotate';

const Viewphoto = ({ open, closeModal }) => {
  const { viewPhoto } = useContext(GlobalContext);

  const photoBasedOnModule = () => {
    return (
      <>
        <Typography variant='h6' sx={{ color: '#2196f3' }}>
          View Photo
        </Typography>
        {viewPhoto ? (
          <div style={{ height: '180px', width: '180px' }}>
            <ReactPanZoom image={viewPhoto} alt='Image alt text' />
          </div>
        ) : (
          <p>Photo not available. Please take photo.</p>
        )}
      </>
    );
  };

  return (
    <Modal
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={open}
      onClose={closeModal}
    >
      <Grid
        sx={{
          backgroundColor: '#FFF',
          borderRadius: '4px',
          padding: 2,
          height: '400px',
          width: '300px',
        }}
        container
        xs={10}
      >
        <Grid item xs={6}>
          {photoBasedOnModule()}
        </Grid>
        <Grid item xs={11}></Grid>
        <Grid item xs={1} mr={2}>
          <Button
            variant='contained'
            onClick={closeModal}
            sx={{ backgroundColor: '#2196f3' }}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default Viewphoto;
