import React, { useState } from 'react';
import axios from 'axios';

// Get the base API URL from the environment variables
const API_BASE_URL = process.env.REACT_APP_API_URL || '/medexlabapi/api/';

// const API_BASE_URL = process.env.REACT_APP_FASTAPI_URL 

const AIInputScreen = () => {
  const [input, setInput] = useState('');
  const [response, setResponse] = useState('');

  const handleChange = (event) => {
    setInput(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Form the complete endpoint using the base URL
      const apiUrl = `${API_BASE_URL}aiprompt`;

      // Make the API call using axios
      const res = await axios.get(apiUrl);
      setResponse(res.data);
    } catch (error) {
      console.error('Error fetching AI prompt:', error);
      setResponse('Error fetching prompt, please try again.');
    }
    setInput('');
  };

  return (
    <div style={{ height: '60vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: '#282c34', color: 'white', fontFamily: 'Arial, sans-serif' }}>
      <div style={{ padding: '20px', fontSize: '20px', textAlign: 'center' }}>
        {response}
      </div>
      <form onSubmit={handleSubmit} style={{ display: 'flex', padding: '10px' }}>
        <input
          type="text"
          value={input}
          onChange={handleChange}
          placeholder="Ask me anything...(under construction, random, fictitious answers will be given)"
          style={{ flexGrow: 1, padding: '10px', fontSize: '16px', color: 'black', borderRadius: '4px' }}
        />
        <button type="submit" style={{ padding: '10px 20px', marginLeft: '10px', fontSize: '16px', color: 'white', backgroundColor: '#4a90e2', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
          Send
        </button>
      </form>
    </div>
  );
};

export default AIInputScreen;
