// PublicationCardLoadingSkeleton.tsx

import { Box, Card, CardContent, Skeleton, Stack } from '@mui/material';

export const PublicationCardLoadingSkeleton = () => {
  return (
    <Card style={{ width: '250px', margin: '10px' }}>
      <CardContent style={{ padding: 0 }}>
        <Skeleton variant='rectangular' width={250} height={150} />
        <Box component='div' sx={{ p: 1, justifyContent: 'flex-start' }}>
          <Box component='div' mb={4}>
            <Skeleton variant='text' width={200} />
            <Skeleton variant='text' width={150} />
          </Box>
          <Stack direction='row' sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
            <Skeleton variant='circular' width={24} height={24} style={{ margin: '0 4px' }} />
            <Skeleton variant='circular' width={24} height={24} style={{ margin: '0 4px' }} />
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};
