import { GridColDef } from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import SiloDataGrid from '../../utils/components/SiloDataGrid';
import { useDashboard } from '../../views/Dashboard/DashboardContext';
import { DecedentNameCell } from './DecedentNameCell';
import { DashboardDataGridProps } from './useDashboardDataGridAPI.hook';

export function ReadyForCheckoutDataGrid({ data, isLoading }: DashboardDataGridProps) {
  const navigate = useNavigate();

  const { preferences, reloadSiloData } = useDashboard();
  const [reloading, setReloading] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        setReloading(true);
        await reloadSiloData('READY_FOR_CHECKOUT');
      } finally {
        setReloading(false);
      }
    }, preferences.refreshSeconds * 1000);

    return () => clearInterval(intervalId);
  }, [reloadSiloData]);

  const columns: GridColDef[] = [
    {
      field: 'caseid',
      headerName: 'CaseID',
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'decedentname',
      headerName: 'Decedent',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: params => <DecedentNameCell {...params} />,
    },
    {
      field: 'checkindatetime',
      headerName: 'Check-in Date',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'storagelocation',
      headerName: 'Storage Location',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'examphysician',
      headerName: 'Pathologist',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'investigator',
      headerName: 'MLDI',
      width: 140,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  return (
    <SiloDataGrid
      loading={isLoading}
      listresults={data}
      columnsInput={columns}
      idcolumn='caseid'
      gridheight={200}
      gridcolor='#3498db'
      title='Ready For Checkout'
      tooltip='These are cases that are ready to be released to the funeral home. The Exam must be marked completed, ID should be marked Confirmed, and there should be no active HOLDs on the case'
      casecount
      rowsperpage={50}
      filterModel={undefined}
      columnsVisibility={{
        checkindatetime: false,
      }}
      sorting={{
        sortModel: [
          {
            field: 'checkindatetime',
            sort: 'desc',
          },
        ],
      }}
      reloading={reloading}
      refreshSeconds={preferences.refreshSeconds}
    />
  );
}
