import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { saveAdminData } from '../../../../../../services/utility-api';

import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  FormControl,
  IconButton,
  FormHelperText,
} from '@mui/material';
import { TextField } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { HexColorPicker } from 'react-colorful';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
const AddEdit = ({
  editObj,
  referenceMasterTableName,
  isCounty,
  maxOrder,
  setIsAddEditOn,
  getAllData,
  countryNames,
  rows,
  referencePermissions,
}) => {
  console.log('editObj', editObj);
  console.log('referenceMasterTableName', referenceMasterTableName);
  const id = '00000000-0000-0000-0000-000000000000';
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  useEffect(() => {}, [editObj]);

  const initialValues = {
    ...editObj,
    // parentCountySeq: editObj?.parentCountySeq || id,
    countyName: editObj?.countyName || '',
    countyCode: editObj?.countyCode || '',
    countyOrder: editObj?.countyOrder
      ? Math.floor(Number(editObj.countyOrder) / 10) * 10
      : maxOrder + 10, // Pls explain this.
  };

  const validationSchema = Yup.object({
    countyName: Yup.string().required('Jurisdiction Name is required').nullable(),
    countyCode: Yup.string().required('Jurisdiction code order is required').max(2, 'Jurisdiction code should be 2 numbers or fewer').nullable(),
    countyOrder: Yup.string().required('Jurisdiction order is required').nullable(),
    // colorCode: Yup.string().required(`Color Code is required`).nullable(),
  });
  const onSave = async values => {
    try {
      const response = await saveAdminData({
        values: {
          ...values,
          [referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq']:
            values[
              referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq'
            ] || id,
        },
        tableName: referenceMasterTableName,
      });
      if (response.status == 0) {
        setTimeout(() => {
          setIsAddEditOn(false);
          getAllData();
        }, 1700);
      }
      setDialogContents(response.message);
      setOpenApiDialogBox(true);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      <Grid elevation={3} spacing={2} sx={{ padding: '16px', marginLeft: '-16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={10} mb={2} mt={2}>
            <Typography variant='h5'>
              <strong>{Object.keys(editObj).length ? 'Modify ' : 'Enter New '} Jurisdiction </strong>
            </Typography>
          </Grid>
        </Grid>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSave}>
          {({
            errors,
            handleBlur,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                {console.log('errors', errors)}
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth sx={{ m: 0, minWidth: 120 }} variant='standard'>
                      <TextField
                        id='county'
                        required
                        label='Jurisdiction '
                        variant='outlined'
                        name={'countyName'?.[0].toLowerCase() + 'countyName'?.slice(1) + '' ?? ''}
                        value={
                          values?.[
                            referenceMasterTableName?.[0].toLowerCase() +
                              referenceMasterTableName.slice(1) +
                              'Name'
                          ] ?? ''
                        }
                        onChange={handleChange}
                        defaultValue=''
                        margin='normal'
                        size='large'
                      />
                      <ErrorMessage
                        name={'countyName'}
                        render={msg => <FormHelperText error>{msg}</FormHelperText>}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth sx={{ m: 0, minWidth: 120 }} variant='standard'>
                      <TextField
                        id='code'
                        label='Code'
                        required
                        variant='outlined'
                        margin='normal'
                        size='large'
                        name={'countyCode'}
                        value={values?.countyCode}
                        onChange={handleChange}
                        defaultValue=''
                      />
                      <ErrorMessage
                        name={'countyCode'}
                        render={msg => <FormHelperText error>{msg}</FormHelperText>}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth sx={{ m: 0, minWidth: 120 }} variant='standard'>
                      <TextField
                        focused
                        required
                        id='denominationOrder'
                        label='List Order'
                        variant='outlined'
                        name='denominationOrder'
                        defaultValue=''
                        margin='normal'
                        size='large'
                        value={values?.countyOrder}
                        onChange={handleChange}
                        disabled
                        // disabled={
                        //     values[
                        //         referenceMasterTableName[0].toLowerCase() +
                        //             referenceMasterTableName.slice(1) +
                        //             'Order'
                        //     ] == 'Other' ||
                        //     values[
                        //         referenceMasterTableName[0].toLowerCase() +
                        //             referenceMasterTableName.slice(1) +
                        //             'Order'
                        //     ] == 'Unknown'
                        // }
                      />
                      <ErrorMessage
                        name={'countyOrder'}
                        render={msg => <FormHelperText error>{msg}</FormHelperText>}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} xs={12} md={6} lg={6}>
                    <Grid item sm={6} xs={12} md={12} lg={12}>
                      <FormControl
                        fullWidth
                        sx={{ m: 0, minWidth: 120 }}
                        error
                        // required
                        component='fieldset'
                        variant='standard'
                      >
                        <TextField
                          // required
                          focused
                          id='outlined-basic'
                          label='Color Code'
                          variant='outlined'
                          name={'colorCode'}
                          //   defaultValue={values['colorCode']}
                          value={values['colorCode']}
                          // onChange={handleChange}
                          margin='normal'
                          size='large'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12} md={6} lg={6}>
                      <HexColorPicker
                        style={{ width: '100%' }}
                        color={values['colorCode']}
                        onChange={e => {
                          // values['colorCodeName'] = e.split('#')[1].toString();
                          setFieldValue('colorCode', e.split('#')[1].toUpperCase().toString());
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormGroup>
                      <FormControlLabel
                        name={'isCaseIDUsedAsTissueNumber'}
                        checked={values.isCaseIDUsedAsTissueNumber}
                        control={<Checkbox />}
                        onClick={e => {
                          handleChange({
                            target: {
                              name: 'isCaseIDUsedAsTissueNumber',
                              value: !values.isCaseIDUsedAsTissueNumber,
                            },
                          });
                        }}
                        label=' Case ID Used As Tissue Number'
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormGroup>
                      <FormControlLabel
                        name={'isOptimalTissueNumberPreview'}
                        checked={values.isOptimalTissueNumberPreview}
                        control={<Checkbox />}
                        onClick={e => {
                          handleChange({
                            target: {
                              name: 'isOptimalTissueNumberPreview',
                              value: !values.isOptimalTissueNumberPreview,
                            },
                          });
                        }}
                        label='Optional Tissue Number Preview'
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormGroup>
                      <FormControlLabel
                        name={'isMorgueChecked'}
                        checked={values.isMorgueChecked}
                        control={<Checkbox />}
                        onClick={e => {
                          handleChange({
                            target: {
                              name: 'isMorgueChecked',
                              value: !values.isMorgueChecked,
                            },
                          });
                        }}
                        label='Support Multiple Morgues'
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormGroup>
                      <FormControlLabel
                        name={'isDecisionMakingNOK'}
                        checked={values.isDecisionMakingNOK}
                        control={<Checkbox />}
                        onClick={e => {
                          handleChange({
                            target: {
                              name: 'isDecisionMakingNOK',
                              value: !values.isDecisionMakingNOK,
                            },
                          });
                        }}
                        label='Decision Making NOK'
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormGroup>
                      <FormControlLabel
                        name={'trackLab'}
                        checked={values.trackLab}
                        control={<Checkbox />}
                        onClick={e => {
                          handleChange({
                            target: {
                              name: 'trackLab',
                              value: !values.trackLab,
                            },
                          });
                        }}
                        label='Track Lab'
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormGroup>
                      <FormControlLabel
                        name={'isRegularMorgue'}
                        checked={values.isRegularMorgue}
                        control={<Checkbox />}
                        onClick={e => {
                          handleChange({
                            target: {
                              name: 'isRegularMorgue',
                              value: !values.isRegularMorgue,
                            },
                          });
                        }}
                        label='Regular Morgue'
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormGroup>
                      <FormControlLabel
                        name={'isHospitalMorgue'}
                        checked={values.isHospitalMorgue}
                        control={<Checkbox />}
                        onClick={e => {
                          handleChange({
                            target: {
                              name: 'isHospitalMorgue',
                              value: !values.isHospitalMorgue,
                            },
                          });
                        }}
                        label='Hospital Morgue'
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormGroup>
                      <FormControlLabel
                        name={'isRecoverySealNoRequired'}
                        checked={values.isRecoverySealNoRequired}
                        control={<Checkbox />}
                        onClick={e => {
                          handleChange({
                            target: {
                              name: 'isRecoverySealNoRequired',
                              value: !values.isRecoverySealNoRequired,
                            },
                          });
                        }}
                        label='Duplicate Recovery Seal No.'
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormGroup>
                      <FormControlLabel
                        name={'isDocumentTrackerAutoPopulated'}
                        checked={values.isDocumentTrackerAutoPopulated}
                        control={<Checkbox />}
                        onClick={e => {
                          handleChange({
                            target: {
                              name: 'isDocumentTrackerAutoPopulated',
                              value: !values.isDocumentTrackerAutoPopulated,
                            },
                          });
                        }}
                        label='Document Tracker Auto Populated'
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth sx={{ m: 0, minWidth: 120 }} variant='standard'>
                      <TextField
                        id='TissueNumberDisplay'
                        label='Maximum Available Tissue Number to Display	'
                        variant='outlined'
                        name={'maxAvailableTissueNumberCount'}
                        value={values?.maxAvailableTissueNumberCount}
                        onChange={handleChange}
                        margin='normal'
                        size='large'
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Button
                        // type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        sx={{ minWidth: '125px', marginTop: '7px' }}
                        disabled={!referencePermissions?.add}
                      >
                        {Object.keys(editObj).length ? 'Update' : 'Save '}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
        <div className='modal'>
          <Dialog
            open={openApiDialogBox}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenApiDialogBox(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                {dialogContents}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <IconButton onClick={() => setOpenApiDialogBox(false)}>
                <CloseIcon />
              </IconButton>
            </DialogActions>
          </Dialog>
        </div>
      </Grid>
    </>
  );
};
export default AddEdit;
