import { NavigateFunction } from 'react-router';
import { AuthAction, AuthState } from '../reducers/authReducer';
import { UserAction, UserState } from '../reducers/userReducer';
import { MedexResponse, ResponseStatus } from '../../../types/MedexResponse.type';
import { User } from '../../../types/User';

// Extract the API URL from the environment variables
const { REACT_APP_API_URL } = process.env;

/**
 * Hook for sending and verifying 2-factor authentication (2FA) code via SMS.
 *
 * @param authDispatch Dispatch function for authentication-related actions.
 * @param authState The current authentication state.
 * @param userDispatch Dispatch function for user-related actions.
 * @param userState The current user state.
 * @param navigate Function to navigate to different routes.
 *
 * @return An object containing functions to send and verify the 2FA code.
 */
export function useSendCodeSMS(
  authDispatch: React.Dispatch<AuthAction>,
  authState: AuthState,
  userDispatch: React.Dispatch<UserAction>,
  userState: UserState,
  navigate: NavigateFunction
) {
  /**
   * Send the 2FA code to the user via SMS.
   * - If no user is logged in, throw an error.
   * - Dispatch a request to the server to send the 2FA code to the user's primary mobile number.
   * - Update the authentication state based on the server's response.
   */
  const sendSMS = async () => {
    try {
      if (!userState.user) {
        authDispatch({ type: 'SET_PASSED_2FA', payload: false });
        throw new Error('No user is logged in!');
      }

      authDispatch({ type: 'CLEAR_2FA_ERROR_MESSAGE' });
      authDispatch({ type: 'SET_SELECTED_2FA_METHOD', payload: 'sms' });
      authDispatch({
        type: 'SET_SMS_STATE',
        payload: {
          state: 'awaiting response',
        },
      });

      const endpoint = `${REACT_APP_API_URL}user/login/2fa`;
      const body = { auth_token: userState.user.token, method: 'sms' };
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });

      const { status, data, errorCode, message }: MedexResponse = await response.json();
      if (status === ResponseStatus.SUCCESS) {
        authDispatch({
          type: 'SET_SMS_STATE',
          payload: {
            state: 'code sent to user',
          },
        });
      } else {
        authDispatch({
          type: 'SET_SMS_STATE',
          payload: {
            state: 'error',
            message: message ?? "Unknown error while sending code",
          },
        });
      }
    } catch (error) {
      console.error(error);
      authDispatch({
        type: 'SET_SMS_STATE',
        payload: {
          state: 'error',
          message: error ?? "Unknown error while sending code",
        },
      });
    }
  };

  /**
   * Verify the 2FA code input by the user.
   * - If no user is logged in, throw an error.
   * - Send the provided code to the server for verification.
   * - Update both user and authentication states based on the server's response.
   *
   * @param {Object} params Parameters containing the 2FA code input by the user.
   * @param {string} params.code The 2FA code input by the user.
   */
  const verifySMS = async ({ code }: { code: string }) => {
    try {
      if (!userState.user) {
        authDispatch({ type: 'SET_PASSED_2FA', payload: false });
        throw new Error('No user is logged in!');
      }

      const endpoint = `${REACT_APP_API_URL}user/login/2fa/verify`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          auth_token: userState.user.token,
          method: 'sms',
          code,
        }),
      });

      const { status, data, errorCode, message}: MedexResponse = await response.json()

      if (status === ResponseStatus.SUCCESS) {
        // Process server response
        authDispatch({
          type: 'SET_SMS_STATE',
          payload: {
            state: 'success',
          },
        });
        authDispatch({ type: 'SET_AUTHENTICATED', payload: true });
        authDispatch({ type: 'SET_PASSED_2FA', payload: true });
        userDispatch({ type: 'SET_USER', payload: data });
        navigate('/home');
      } else {
        authDispatch({
          type: 'SET_SMS_STATE',
          payload: {
            state: 'error',
            message: message ?? 'Unknown error while verifying code',
          },
        });
      }
    } catch (error) {
      console.error(error);
      authDispatch({
        type: 'SET_SMS_STATE',
        payload: {
          state: 'error',
          message: error ?? 'Unknown error while verifying code' ,
        },
      });
    }
  };

  // Return the sendSMS and verifySMS functions from the hook
  return { sendSMS, verifySMS };
}
