export interface AuthState {
  isAuthenticated: boolean;
  ready: boolean;
  inactivityTimeout: NodeJS.Timeout | null;
  linking_phone: boolean;
  forgot_password: boolean;
  two_factor: {
    selected_method: TwoFactorMethods;
    email_state: AuthTwoFactorState;
    sms_state: AuthTwoFactorState;
    passed: boolean;
    is_verifying_code: boolean;
  };
  login_state: AuthLoginState;
  error_message: string | null;
  logout_state: AuthLogoutState | null;
}

export interface AuthLoginState {
  state: 'error' | 'awaiting response' | 'received response' | 'success' | 'init';
  message?: any;
}

export interface AuthLogoutState {
  message: string | null;
  dismissAfterSeconds: number;
  displayAlert: boolean;
  shouldDismiss: boolean;
}

export type TwoFactorMethods = 'none' | 'email' | 'sms';

export interface AuthTwoFactorState {
  state:
    | 'error'
    | 'awaiting response'
    | 'received response'
    | 'code sent to user'
    | 'success'
    | 'init';
  message?: any;
}

export type AuthAction =
  | { type: 'SET_AUTHENTICATED'; payload: boolean }
  | { type: 'SET_READY'; payload: boolean }
  | { type: 'SET_SELECTED_2FA_METHOD'; payload: 'none' | 'email' | 'sms' }
  | { type: 'SET_PASSED_2FA'; payload: boolean }
  | { type: 'SET_LINKING_PHONE'; payload: boolean }
  | { type: 'SET_FORGOT_PASSWORD_STATE'; payload: boolean }
  | { type: 'SET_VERIFYING_CODE'; payload: boolean }
  | { type: 'SET_EMAIL_STATE'; payload: AuthTwoFactorState }
  | { type: 'SET_SMS_STATE'; payload: AuthTwoFactorState }
  | { type: 'SET_INACTIVITY_TIMEOUT'; payload: NodeJS.Timeout | null }
  | { type: 'RESET_SELECTED_2FA_METHOD' }
  | { type: 'CLEAR_2FA_ERROR_MESSAGE' }
  | { type: 'SET_LOGIN_STATE'; payload: AuthLoginState }
  | { type: 'RESET_LOGIN_ERROR_MESSAGE' }
  | { type: 'SET_LOGIN_ERROR_MESSAGE'; payload: string }
  | {
      type: 'SET_LOGOUT_MESSAGE_STATE';
      payload: AuthLogoutState;
    }
  | { type: 'RESET_AUTH' };

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  ready: false,
  inactivityTimeout: null,
  linking_phone: false,
  forgot_password: false,
  two_factor: {
    selected_method: 'none',
    email_state: {
      state: 'init',
      message: null,
    },
    sms_state: {
      state: 'init',
      message: null,
    },
    passed: false,
    is_verifying_code: false,
  },
  login_state: {
    state: 'init',
    message: null,
  },
  logout_state: null,
  error_message: null,
};

export function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case 'SET_AUTHENTICATED':
      return { ...state, isAuthenticated: action.payload };
    case 'SET_READY':
      return { ...state, ready: action.payload };
    case 'SET_SELECTED_2FA_METHOD':
      return {
        ...state,
        two_factor: {
          ...state.two_factor,
          selected_method: action.payload,
        },
      };
    case 'SET_LOGIN_STATE':
      return {
        ...state,
        login_state: {
          state: action.payload.state,
          message: action.payload.message,
        },
      };
    case 'RESET_LOGIN_ERROR_MESSAGE': {
      return {
        ...state,
        login_state: {
          state: 'init',
          message: null,
        },
      };
    }
    case 'SET_PASSED_2FA':
      return {
        ...state,
        two_factor: {
          ...state.two_factor,
          passed: action.payload,
        },
      };
    case 'SET_FORGOT_PASSWORD_STATE':
      return {
        ...state,
        forgot_password: action.payload,
      };
    case 'SET_EMAIL_STATE':
      return {
        ...state,
        two_factor: {
          ...state.two_factor,
          email_state: {
            state: action.payload.state,
            message: action.payload.message,
          },
        },
      };
    case 'SET_SMS_STATE':
      return {
        ...state,
        two_factor: {
          ...state.two_factor,
          sms_state: {
            state: action.payload.state,
            message: action.payload.message,
          },
        },
      };
    case 'SET_INACTIVITY_TIMEOUT':
      return { ...state, inactivityTimeout: action.payload };
    case 'SET_VERIFYING_CODE':
      return {
        ...state,
        two_factor: {
          ...state.two_factor,
          is_verifying_code: action.payload,
        },
      };

    case 'SET_LINKING_PHONE':
      return { ...state, linking_phone: action.payload };
    case 'CLEAR_2FA_ERROR_MESSAGE':
      return clearErrorMessage(state);
    case 'RESET_SELECTED_2FA_METHOD':
      return {
        ...state,
        two_factor: {
          selected_method: 'none',
          email_state: {
            state: 'init',
            message: null,
          },
          sms_state: {
            state: 'init',
            message: null,
          },
          passed: false,
          is_verifying_code: false,
        },
      };
    case 'SET_LOGIN_ERROR_MESSAGE':
      return {
        ...state,
        error_message: action.payload,
      };
    case 'SET_LOGOUT_MESSAGE_STATE': {
      return {
        ...state,
        logout_state: action.payload,
      };
    }
    case 'RESET_AUTH':
      return { ...initialAuthState, logout_state: state.logout_state };
    default:
      return state;
  }
}

function clearErrorMessage(state: AuthState): AuthState {
  let shouldClearEmail = false;
  let shouldClearSMS = false;

  const initEmailState: AuthTwoFactorState = {
    state: 'init',
    message: null,
  };

  const currentEmailState: AuthTwoFactorState = {
    ...state.two_factor.email_state,
  };

  const initSMSState: AuthTwoFactorState = {
    state: 'init',
    message: null,
  };

  const currentSMSState: AuthTwoFactorState = {
    ...state.two_factor.sms_state,
  };

  if (state.two_factor.email_state.state === 'error') {
    shouldClearEmail = true;
  }

  if (state.two_factor.sms_state.state === 'error') {
    shouldClearSMS = true;
  }

  return {
    ...state,
    two_factor: {
      ...state.two_factor,
      email_state: shouldClearEmail ? initEmailState : currentEmailState,
      sms_state: shouldClearSMS ? initSMSState : currentSMSState,
    },
  };
}
