import { Box, Container, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { AppLogoImg } from '../../assets/index';
import { useAuth } from '../../utils/auth/AuthService';

export function LoginView() {
  const auth = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.ready && auth.user && auth.two_factor.passed) {
      navigate('/home');
    }
  }, [auth, auth.ready, auth.user]);

  return (
    <Box
      bgcolor={theme.palette.background.default}
      color={theme.palette.text.primary}
      height='100%'
      component='div'
      display='flex'
      alignItems='center'
      justifyContent='center'
      py={5}
    >
      <Container
        sx={{
          display: { md: 'grid', xs: 'flex' },
          flexDirection: { xs: 'column' },
          gridTemplateColumns: { lg: '1fr 1fr' },
          maxHeight: '100%',
          overflowY: 'auto',
        }}
      >
        <WarningBox />
        <LoginSection />
      </Container>
    </Box>
  );
}

function LoginSection() {
  return (
    <Box component='div' p={6}>
      <Box component='div'>
        <img src={AppLogoImg} className='profile-img-card' alt='logo' />
      </Box>

      <Outlet />
    </Box>
  );
}

function WarningBox() {
  return (
    <Box
      component='div'
      display={{ lg: 'flex' }}
      flexDirection='column'
      bgcolor='#282828'
      p={2}
      color='grey.500'
      // sx={{ opacity: '0.6' }}
      justifyContent='space-between'
    >
      <Box
        component='div'
        position='relative'
        display='flex'
        alignItems='center'
      >
        <Typography variant='h6' fontWeight='bold'>
          MedexLab
        </Typography>
      </Box>
      <Box component='div' position='relative' mt='auto'>
        <Typography variant='body2' fontWeight='bold'>
          WARNING
        </Typography>
        <Typography variant='body2'>
          You are accessing an application belonging to the State of New Jersey,
          Office of the Chief State Medical Examiner. This application and data
          contained within is only provided for use authorized by the New Jersey
          Department of Health, Office of the Chief State Medical Examiner.
          Unauthorized or improper use of the application or data contained
          within may result in civil proceedings and/or criminal prosecution.
        </Typography>
      </Box>
    </Box>
  );
}
