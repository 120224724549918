import { Autocomplete, Grid, TextField } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { ActionList } from '../../../../components/CaseView/CaseViewDateTimeField';
import { useAuth } from '../../../../utils/auth/AuthService';
import { renderAutocompleteEditCell } from '../../../../utils/components/CrudDataGrid/AutocompleteEditCell';
import DialogCrudDataGrid, {
  getDateValue,
  handleAutocompleteChange,
  handleDateChange,
  handleTextFieldChange,
} from '../../../../utils/components/DialogCrudDataGrid';

export function RequestedProcedures() {
  const { user } = useAuth();
  const [reqProcedureOptions, setreqProcedureOptions] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [allConsultantOptions, setallConsultantOptions] = useState([]);
  const { REACT_APP_API_URL } = process.env;
  const formik = useFormikContext<CMSCase>();

  useEffect(() => {
    // Obtain requested procedure options
    fetch(REACT_APP_API_URL + 'requestedProcedureOptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setreqProcedureOptions(data);
      })
      .catch(e => {
        alert(`Failed to get from requested Procedure Options\nError: ${e}`);
      });

    fetchConsultantOptions();
    fetchRequestedProcedures();
  }, []);

  const fetchRequestedProcedures = async () => {
    try {
      const caseSeq = formik.values?.caseSummary?.caseSeq;
      const response = await fetch(`${REACT_APP_API_URL}getrequestedproceduresoncase/${caseSeq}`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      const updatedData = await data.map(item => {
        return {
          ...item,
          isNew: false, // Set isNew to false initially for each item
        };
      });
      await setProcedures(updatedData); // Sets the data with isNew and consultant

      console.log(updatedData);
    } catch (error) {
      console.error('There was a problem with the fetch operation: ', error);
    }
  };

  // Currently fetches all w/o regard to procedures
  const fetchConsultantOptions = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}consultantonprocedureoptions`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const all_consultant_db_options = await response.json();

      const formattedConsultantOptions = all_consultant_db_options.map(option => ({
        ...option,
        optionName: option.optionName.replace(/,\s*$/, ''),
      }));

      setallConsultantOptions(formattedConsultantOptions);
    } catch (error) {
      console.error('There was a problem with the fetch operation: ', error);
    }
  };

  const requestedProceduresColumns: GridColDef[] = [
    {
      flex: 1,
      minWidth: 175,
      field: 'reqProcedure',
      headerName: 'Requested Procedure',
      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (value: Option) => {
        if (!value) return value;
        return value.optionName;
      },
      valueOptions: reqProcedureOptions,
      renderEditCell: params => renderAutocompleteEditCell(params),
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'consultant',
      headerName: 'Consultant',
      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (value: Option) => {
        if (!value) return value;
        return value.optionName;
      },
      valueOptions: allConsultantOptions,
      renderEditCell: params => renderAutocompleteEditCell(params),
    },
    {
      flex: 1,
      field: 'requestedDate',
      headerName: 'Date Requested',
      headerAlign: 'left',
      align: 'left',
      type: 'date',
      valueGetter: value => {
        if (!value) return undefined;
        if (value === 'Invalid Date') {
          return undefined;
        }
        return new Date(value);
      },
      valueSetter: (value, row) => {
        if (value == null || value == 'Invalid Date') {
          return { ...row, requestedDate: null };
        } else {
          const localDate = value ? dayjs(value).format('YYYY-MM-DDTHH:mm:ss') : null;

          return { ...row, requestedDate: localDate };
        }
      },
    },
    {
      flex: 1,
      field: 'receivedDate',
      headerName: 'Date Received',
      headerAlign: 'left',
      align: 'left',
      type: 'date',
      valueGetter: value => {
        if (!value) return undefined;
        if (value === 'Invalid Date') {
          return undefined;
        }
        return new Date(value);
      },
      valueSetter: (value, row) => {
        if (value == null || value == 'Invalid Date') {
          return { ...row, receivedDate: null };
        } else {
          const localDate = value ? dayjs(value).format('YYYY-MM-DDTHH:mm:ss') : null;

          return { ...row, receivedDate: localDate };
        }
      },
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'comments',
      headerName: 'Comments',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (value: Option) => {
        if (!value) return value;
        return value.optionName;
      },
      type: 'string',
    },
  ];

  const createRequestedProcedure = async newRow => {
    let formData = new FormData();
    formData.append('newRow', JSON.stringify(newRow));
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);
    formData.append('userSeq', user.userSeq);
    await fetch(REACT_APP_API_URL + 'createRequestedProcedure', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {
        // console.log('output', data);
        const newId = JSON.parse(data); // Parse the text to remove quotes
        newRow.requestedProcedureSeq = newId;
        // console.log('new ROW', newRow);
      })
      .catch(e => {
        console.log(e);
      });

    await fetchRequestedProcedures();
  };

  const deleteRequestedProcedure = async id => {
    await fetch(REACT_APP_API_URL + `deleterequestedprocedure/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {})
      .catch(e => {
        console.log(e);
      });

    await fetchRequestedProcedures();
  };

  const updateRequestedProcedure = async updatedRow => {
    let formData = new FormData();
    formData.append('updatedRow', JSON.stringify(updatedRow));
    formData.append('userSeq', user.userSeq);

    await fetch(REACT_APP_API_URL + 'updaterequestedprocedure', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {
        console.log('updated data', data);
      })
      .catch(e => {
        console.log(e);
      });

    await fetchRequestedProcedures();
  };

  const dialogContent = ({ mode, data, onChange }) => {
    const [localData, setLocalData] = useState(null);

    useEffect(() => {
      setLocalData(data);
    }, [data]);

    return (
      <Grid container spacing={1} sx={{ alignItems: 'center' }}>
        <Grid item xs={12} md={6} xl={3}>
          <Autocomplete
            options={reqProcedureOptions}
            getOptionLabel={option => option.optionName || ''}
            value={localData?.reqProcedure || null}
            onChange={(event, value) =>
              handleAutocompleteChange(
                event,
                value,
                'reqProcedure',
                localData,
                setLocalData,
                onChange
              )
            }
            renderInput={params => (
              <TextField {...params} label='Requested Procedure' margin='dense' fullWidth />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          <Autocomplete
            options={allConsultantOptions}
            getOptionLabel={option => option.optionName || ''}
            value={localData?.consultant || null}
            onChange={(event, value) =>
              handleAutocompleteChange(
                event,
                value,
                'consultant',
                localData,
                setLocalData,
                onChange
              )
            }
            renderInput={params => (
              <TextField {...params} label='Consultant' margin='dense' fullWidth />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label='Date Requested'
              value={getDateValue(localData?.requestedDate)}
              onChange={value =>
                handleDateChange(value, 'requestedDate', localData, setLocalData, onChange)
              }
              slots={{ actionBar: ActionList }}
              sx={{ width: '100%' }}
              slotProps={{ textField: { margin: 'dense' } }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label='Date Received'
              value={getDateValue(localData?.receivedDate)}
              onChange={value =>
                handleDateChange(value, 'receivedDate', localData, setLocalData, onChange)
              }
              slots={{ actionBar: ActionList }}
              sx={{ width: '100%' }}
              slotProps={{ textField: { margin: 'dense' } }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} xl={6}>
          <TextField
            value={localData?.comments ?? ''}
            onChange={event =>
              handleTextFieldChange(event, 'comments', localData, setLocalData, onChange)
            }
            label='Comments'
            fullWidth
            margin='dense'
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <DialogCrudDataGrid
      rows={procedures}
      columns={requestedProceduresColumns}
      idColumn='requestedProcedureSeq'
      createFunction={createRequestedProcedure}
      updateFunction={updateRequestedProcedure}
      deleteFunction={deleteRequestedProcedure}
      dialogContent={dialogContent}
      title='Requested Procedure'
    />
  );
}
