import { Stack, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import authHeader from '../../../services/auth-header';
import SecurityQuestion, { SecurityQuestionSkeleton } from './SecurityQuestion';

const id = '00000000-0000-0000-0000-000000000000';

const SecurityQuestionTab = ({
  securityQuestion = [],
  handleChange,
  setSecurityQuestionError,
  setSecurityQuestionMap,
}) => {
  const [loading, setLoading] = useState(true);
  const [securityQuestions, setSecurityQuestions] = useState({});

  const handleSecurityQuestionSelectionChange = (e, index) => {
    if (e.target.name !== 'securityQuestionAns') {
      setSecurityQuestionMap(prev => ({
        ...prev,
        [index]: securityQuestions?.[e.target.value],
      }));
    }

    const tempPanels = JSON.parse(JSON.stringify(securityQuestion));
    tempPanels[index][e.target.name] = e.target.value;
    handleChange(tempPanels);
  };

  const getSecurityQuestion = () => {
    const tempContactTypeNames = {};
    axios
      .get(`${process.env.REACT_APP_API_URL}GetMasterdata?tablename=SecurityQuestion`, {
        headers: authHeader(),
      })
      .then(response => {
        response.data.data.forEach(securityQuestion => {
          tempContactTypeNames[securityQuestion.securityQuestionSeq] = securityQuestion;
        });
        setSecurityQuestions(tempContactTypeNames);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSecurityQuestion();
  }, []);

  return (
    <>
      <Typography
        variant='h3'
        gutterBottom
        sx={{
          mt: 0,
          mb: 0,
          py: 2,
          fontSize: '1rem',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }}
      >
        Security Questions
      </Typography>

      {loading && (
        <Stack spacing={1}>
          <SecurityQuestionSkeleton />
          <SecurityQuestionSkeleton />
          <SecurityQuestionSkeleton />
        </Stack>
      )}
      {Object.keys(securityQuestions).length
        ? securityQuestion?.map((panel, index) => (
            <SecurityQuestion
              handleSecurityQuestionSelectionChange={handleSecurityQuestionSelectionChange}
              panel={panel}
              index={index}
              panels={securityQuestion}
              securityQuestions={securityQuestions}
              setSecurityQuestionError={setSecurityQuestionError}
            />
          ))
        : null}
    </>
  );
};

export default SecurityQuestionTab;
