// import InfoIcon from '@mui/icons-material/Info';
// import {
//   Autocomplete,
//   Checkbox,
//   FormControlLabel,
//   Paper,
//   Switch,
//   TextField,
// } from '@mui/material';
// import Tooltip from '@mui/material/Tooltip';
// import axios from 'axios';
// import React, { useEffect, useState } from 'react';
// import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
// import { useNavigate } from 'react-router';
// import {
//   Alert,
//   Button,
//   Card,
//   CardBody,
//   CardHeader,
//   Col,
//   Collapse,
//   NavLink,
//   Row,
// } from 'reactstrap';
// import '../../../App.css';
// import authHeader from '../../../services/auth-header';
// import { useAuth } from '../../../utils/auth/AuthService';
// import { BreadcrumbReportNav } from '../../../utils/components/BreadcrumbReportNav';
// import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

import InfoIcon from '@mui/icons-material/Info';
import authHeader from '../../../services/auth-header';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Paper,
  Slide,
  Stack,
  Switch,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useNavigate } from 'react-router';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function ViolentDeathView() {
  const { user } = useAuth();
  const [userReady, setuserReady] = useState(false);
  const [loading, setloading] = useState(false);
  const [results, setResults] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [mannerOfDeathOptions, setMannerOfDeathOptions] = useState([]);
  const [drugOptions, setDrugOptions] = useState([]);
  const [unnaturalDeathOptions, setUnnaturalDeathOptions] = useState([]);
  const [mannerOfDeath, setMannerOfDeath] = useState([]);
  const [drug, setDrug] = useState([]);
  const [unnaturalDeath, setUnnaturalDeath] = useState([]);
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [selectedJdxs, setSelectedJdxs] = useState([]);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [friendlyHeader, setFriendlyHeader] = useState(true);
  const [friendlyColumns, setFriendlyColumns] = useState([]);
  const [reportColumns, setReportColumns] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();
  const theme = useTheme();

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  //Update columns with desired fields
  const columns = [
    {
      field: 'caseID',
      headerName: 'Case ID',
      headerAlign: 'center',
      bgColor: 'black',
      width: 105,
      editable: false,
      renderCell: params => {
        return (
          <NavLink
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
            }}
          >
            <b>{params.formattedValue}</b>
          </NavLink>
        );
      },
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'personFirstName',
      headerName: 'First Name',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'personMiddleName',
      headerName: 'Middle Name',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'personLastName',
      headerName: 'Last Name',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'age',
      headerName: 'Age',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'ageUnitName',
      headerName: 'Age Unit',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'assignedME',
      headerName: 'Medical Examiner',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'caseMEFindingTypeName',
      headerName: 'Type of Findings',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'caseReporterTypeName',
      headerName: 'Place of Death',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'caseStatus',
      headerName: 'Case Status',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'caseSynopsis',
      headerName: 'Synopsis',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'causeOfDeath',
      headerName: 'Cause Of Death',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'contributory',
      headerName: 'Contributory',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'jurisdictionName',
      headerName: 'Jurisdiction',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'dateOfBirth',
      headerName: 'DOB',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'decAddressline',
      headerName: 'Address Line 1',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'decCity',
      headerName: 'City',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'decState',
      headerName: 'State',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'decZip',
      headerName: 'Zip Code',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'decedentAka',
      headerName: 'AKA',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'examEndDateTime',
      headerName: 'Exam End',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'genderName',
      headerName: 'Gender',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'hcfName',
      headerName: 'HCF',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'isTeleMetryUsed',
      headerName: 'Telemetry Used?',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'lobAddressline',
      headerName: 'Body Address Line 1',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'lobAddressline2',
      headerName: 'Body Address Line 2',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'lobCity',
      headerName: 'Body City',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'lobZip',
      headerName: 'Body Zip Code',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'mannerOfDeathName',
      headerName: 'Manner of Death',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'maritalStatusName',
      headerName: 'Marital Status',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'notes',
      headerName: 'Notes',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'occupationName',
      headerName: 'Occupation',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'onsetAddressType',
      headerName: 'Onset Location',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'onsetAddress',
      headerName: 'Onset Address Line 1',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'onsetCityZip',
      headerName: 'Onset City/Zip Code',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'onsetState',
      headerName: 'Onset State',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'onsetZip',
      headerName: 'Onset Zip Code',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'passengerType',
      headerName: 'Passenger Type',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'pronouncementDateTime',
      headerName: 'Pronouncement Date/Time',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'raceName',
      headerName: 'Race',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'reasonForNoPronouncementName',
      headerName: 'Reason for No Pronouncement',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'ssn',
      headerName: 'SSN',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'transportationType',
      headerName: 'Transportation Type',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'udCategoryName',
      headerName: 'Category of Unnatural Death',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'vehicle',
      headerName: 'Vehicle',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
  ];

  const { REACT_APP_API_URL } = process.env;

  //Update change functions to support all parameters
  const handleJdxChange = (event, data) => {
    setSelectedJdxs(data);
  };

  const handleStartDateChange = event => {
    if (event.target.value != '') {
      setStartDate(event.target.value);
    } else {
      setStartDate('');
    }
  };

  const handleEndDateChange = event => {
    if (event.target.value != '') {
      setEndDate(event.target.value);
    } else {
      setEndDate('');
    }
  };

  const handleMannerOfDeathChange = (event, data) => {
    setMannerOfDeath(data);
  };

  const handleDrugChange = (event, data) => {
    setDrug(data);
  };

  const handleUnnaturalDeathChange = (event, data) => {
    setUnnaturalDeath(data);
  };

  const friendlyHeaderToggle = event => {
    setFriendlyHeader(event.target.checked);
  };
  //-------------------------------------------------

  const dismissDateAlert = () => {
    setShowErrorAlert(false);
  };

  //Update to clear all available search parameters
  const clearAll = () => {
    setStartDate('');
    setEndDate('');
    setSelectedJdxs([]);
    setMannerOfDeath([]);
    setDrug([]);
    setUnnaturalDeath([]);
    setResultsVisible(false);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const errors = {};
    if (startDate == '' || endDate == '') {
      errors.date = 'Call taken range must be within 1 year or year dropdown must be set.';
    } else {
      let startDateArr = startDate.split('-');
      let endDateArr = endDate.split('-');

      let startDateValue = new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]);
      let endDateValue = new Date(endDateArr[0], endDateArr[1] - 1, endDateArr[2]);

      if (endDateValue - startDateValue > 31536000000) {
        errors.date = 'Call taken range must be within 1 year or year dropdown must be set.';
      }
    }

    if (selectedJdxs.length == 0) {
      errors.jurisdiction = 'Please select at least 1 jurisdiction.';
    }

    if (mannerOfDeath.length == 0) {
      errors.mannerOfDeath = 'Please select at least 1 Manner Of Death.';
    }

    setValidationErrors(errors);

    const hasErrors = Object.keys(errors).length > 0;
    setShowErrorAlert(hasErrors);
    if (hasErrors) {
      return;
    }

    setloading(true);
    setResultsVisible(true);

    var c = selectedJdxs.map(i => i.jdxSeq);
    var m = mannerOfDeath.map(i => i.optionSeq);
    var d = drug.map(i => i.optionSeq);
    var u = unnaturalDeath.map(i => i.optionSeq);

    var inputData = {
      inputData: {
        jurisdiction: c.toString(),
        mannerofdeath: m.toString(),
        startdate: startDate,
        enddate: endDate,
        drug: d.toString(),
        uodcategory: u.toString(),
      },
    };

    await axios
      .post(`${REACT_APP_API_URL}ViolentDeath`, JSON.stringify(inputData), {
        headers: authHeader(),
      })
      .then(response => {
        setResults(response.data);
      })
      .catch(e => {
        console.log('e:', e);
      });

    setloading(false);
  };

  useEffect(() => {
    if (user === null) return;

    const fetchMannerOfDeathOptions = async () => {
      await fetch(process.env.REACT_APP_API_URL + 'getmannerofdeathoptions', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => {
          if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setMannerOfDeathOptions(data);
        })
        .catch(e => {
          alert(e);
        });
    };

    const fetchDrugOptions = async () => {
      await fetch(process.env.REACT_APP_API_URL + 'getdrugoptions', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => {
          if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setDrugOptions(data);
        })
        .catch(e => {
          alert(e);
        });
    };

    const fetchUnnaturalDeathOptions = async () => {
      await fetch(process.env.REACT_APP_API_URL + 'getudcategoryoptions', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => {
          if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setUnnaturalDeathOptions(data);
        })
        .catch(e => {
          alert(e);
        });
    };

    fetchMannerOfDeathOptions();
    fetchDrugOptions();
    fetchUnnaturalDeathOptions();

    setAuthorizedJdxs(user.jdxAccessList);
    setSelectedJdxs(user.jdxAccessList);

    setuserReady(true);

    setFriendlyColumns(JSON.parse(JSON.stringify(columns)));
    for (var column of columns) {
      column.headerName = null;
    }
    setReportColumns(columns);
  }, [user]);

  return (
    <Card
      raised
      sx={{
        my: 3,
        mx: 'auto',
        width: { xs: '95%', lg: '75%' },
      }}
    >
      <CardHeader
        title='Violent Death Report'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
        action={
          <Tooltip
            sx={{ color: theme.palette.text.primary }}
            title='This report provides data on violent and unnatural deaths for the chosen jurisdictions'
            placement='right-end'
          >
            <InfoIcon />
          </Tooltip>
        }
      />

      <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

      <CardContent>
        <Slide direction='right' in={showErrorAlert} mountOnEnter unmountOnExit sx={{ mb: 2 }}>
          <Alert severity='warning' onClose={dismissDateAlert}>
            {Object.keys(validationErrors).map(fieldName => (
              <div style={{ fontSize: '14px' }}>
                <li key={fieldName}> {validationErrors[fieldName]}</li>
              </div>
            ))}
          </Alert>
        </Slide>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id='selectedJdxs'
              options={authorizedJdxs}
              value={selectedJdxs}
              disableCloseOnSelect
              limitTags={4}
              fullWidth
              size='small'
              getOptionLabel={option => option.jdxName}
              onChange={(event, data) => handleJdxChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.jdxName}
                </li>
              )}
              renderInput={params => (
                <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
              )}
              ListboxComponent={'ul'}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id='searchfromdate'
              name='searchfromdate'
              label='Exam Start From'
              type='date'
              size='small'
              value={startDate}
              onChange={handleStartDateChange}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id='searchtodate'
              name='searchtodate'
              label='Exam Start To'
              type='date'
              size='small'
              value={endDate}
              onChange={handleEndDateChange}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              limitTags={3}
              id='mannerofdeath'
              options={mannerOfDeathOptions}
              value={mannerOfDeath}
              size='small'
              getOptionLabel={option => option.optionName.toString()}
              onChange={(event, data) => handleMannerOfDeathChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.optionName.toString()}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField {...params} label='Manner Of Death' placeholder='Manner Of Death' />
              )}
              ListboxComponent={'ul'}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              limitTags={3}
              id='drugs'
              options={drugOptions}
              value={drug}
              size='small'
              getOptionLabel={option => option.optionName.toString()}
              onChange={(event, data) => handleDrugChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.optionName.toString()}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => <TextField {...params} label='Drugs' placeholder='Drugs' />}
              ListboxComponent={'ul'}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              limitTags={3}
              id='unnaturaldeath'
              options={unnaturalDeathOptions}
              value={unnaturalDeath}
              size='small'
              getOptionLabel={option => option.optionName.toString()}
              onChange={(event, data) => handleUnnaturalDeathChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.optionName.toString()}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Category of Unnatural Death'
                  placeholder='Category of Unnatural Death'
                />
              )}
              ListboxComponent={'ul'}
            />
          </Grid>

          <Grid item xs>
            <FormControlLabel
              control={<Switch checked={friendlyHeader} onChange={friendlyHeaderToggle} />}
              label='Friendly Headers'
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 2, opacity: 1 }} />

        <Stack
          direction='row'
          sx={{
            width: '100%',
            justifyContent: 'right',
          }}
          spacing={2}
        >
          <Button type='submit' variant='contained' color='primary' onClick={handleSubmit}>
            Calculate
          </Button>

          <Button color='secondary' variant='outlined' onClick={clearAll}>
            Clear
          </Button>
        </Stack>

        <Collapse in={resultsVisible} sx={{ mt: resultsVisible ? 4 : 0 }}>
          <ReportDataGrid
            loading={loading}
            listresults={results}
            idcolumn='caseID'
            columnsInput={friendlyHeader ? friendlyColumns : reportColumns}
            title='Violent Deaths' // Change to report name
            casecount
            chatcontent={
              <Paper
                elevation={3}
                sx={{
                  p: '10px',
                  px: '20px',
                  borderRadius: '20px',
                  width: 'max-content',
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  I've listed the detailed case records below, you can export the list to csv
                  format.
                </div>
              </Paper>
            }
          />
        </Collapse>
      </CardContent>
    </Card>

    // <div className='me-response-time' style={{ padding: 20 }}>
    //   <BreadcrumbReportNav currentPage='Violent Death Report' />
    //   <Card style={{ marginBottom: '1rem' }} className='card-main'>
    //     <CardHeader className='header-txt'>
    //       Violent Death Report
    //       <Tooltip
    //         style={{ float: 'right' }}
    //         title='This report provides data on violent and unnatural deaths for the chosen counties'
    //         placement='right-end'
    //       >
    //         <InfoIcon />
    //       </Tooltip>
    //     </CardHeader>

    //     <Alert
    //       color='info'
    //       isOpen={requiredAlertVisible}
    //       toggle={dismissDateAlert}
    //     >
    //       Date range must be within 1 year.
    //     </Alert>

    //     <Alert
    //       color='info'
    //       isOpen={jdxAlertVisible}
    //       toggle={dismissJdxAlert}
    //     >
    //       Please select at least 1 jdx.
    //     </Alert>

    //     <div>
    //       <CardBody>
    //         <Row>
    //           <Col className='md=12'>
    //             <Autocomplete
    //               multiple
    //               id='selectedJdxs'
    //               options={authorizedJdxs}
    //               value={selectedJdxs}
    //               disableCloseOnSelect
    //               limitTags={4}
    //               size='small'
    //               getOptionLabel={(option) => option.jdxName}
    //               onChange={(event, data) => handleJdxChange(event, data)}
    //               renderOption={(props, option, { selected }) => (
    //                 <li {...props}>
    //                   <Checkbox
    //                     icon={<MdCheckBoxOutlineBlank />}
    //                     checkedIcon={<MdOutlineCheckBox />}
    //                     style={{ marginRight: 8 }}
    //                     checked={selected}
    //                   />
    //                   {option.jdxName}
    //                 </li>
    //               )}
    //               style={{ width: '100%', marginTop: 15 }}
    //               renderInput={(params) => (
    //                 <TextField
    //                   {...params}
    //                   label='Jurisdiction'
    //                   placeholder='Jurisdiction'
    //                 />
    //               )}
    //               ListboxComponent={'ul'}
    //             />
    //           </Col>
    //         </Row>
    //         <Row>
    //           <Col className='me-col' md='6'>
    //             <TextField
    //               id='searchfromdate'
    //               name='searchfromdate'
    //               label='Exam Start From'
    //               type='date'
    //               size='small'
    //               value={startDate}
    //               onChange={handleStartDateChange}
    //               style={{ width: '100%' }}
    //               InputLabelProps={{
    //                 shrink: true,
    //               }}
    //             />
    //           </Col>
    //           <Col className='me-col' md='6'>
    //             <TextField
    //               id='searchtodate'
    //               name='searchtodate'
    //               label='Exam Start To'
    //               type='date'
    //               size='small'
    //               value={endDate}
    //               onChange={handleEndDateChange}
    //               style={{ width: '100%' }}
    //               InputLabelProps={{
    //                 shrink: true,
    //               }}
    //             />
    //           </Col>
    //         </Row>
    //         <Row>
    //           <Col className='me-col' md='4'>
    // <Autocomplete
    //   multiple
    //   disableCloseOnSelect
    //   limitTags={3}
    //   id='mannerofdeath'
    //   options={mannerOfDeathOptions}
    //   value={mannerOfDeath}
    //   size='small'
    //   getOptionLabel={(option) => option.optionName.toString()}
    //   onChange={(event, data) =>
    //     handleMannerOfDeathChange(event, data)
    //   }
    //   renderOption={(props, option, { selected }) => (
    //     <li {...props}>
    //       <Checkbox
    //         icon={<MdCheckBoxOutlineBlank />}
    //         checkedIcon={<MdOutlineCheckBox />}
    //         style={{ marginRight: 8 }}
    //         checked={selected}
    //       />
    //       {option.optionName.toString()}
    //     </li>
    //   )}
    //   style={{ width: '100%' }}
    //   renderInput={(params) => (
    //     <TextField
    //       {...params}
    //       label='Manner Of Death'
    //       placeholder='Manner Of Death'
    //     />
    //   )}
    //   ListboxComponent={'ul'}
    // />
    //           </Col>

    //           <Col className='me-col' md='4'>
    // <Autocomplete
    //   multiple
    //   disableCloseOnSelect
    //   limitTags={3}
    //   id='drugs'
    //   options={drugOptions}
    //   value={drug}
    //   size='small'
    //   getOptionLabel={(option) => option.optionName.toString()}
    //   onChange={(event, data) => handleDrugChange(event, data)}
    //   renderOption={(props, option, { selected }) => (
    //     <li {...props}>
    //       <Checkbox
    //         icon={<MdCheckBoxOutlineBlank />}
    //         checkedIcon={<MdOutlineCheckBox />}
    //         style={{ marginRight: 8 }}
    //         checked={selected}
    //       />
    //       {option.optionName.toString()}
    //     </li>
    //   )}
    //   style={{ width: '100%' }}
    //   renderInput={(params) => (
    //     <TextField {...params} label='Drugs' placeholder='Drugs' />
    //   )}
    //   ListboxComponent={'ul'}
    // />
    //           </Col>

    //           <Col className='me-col' md='4'>
    // <Autocomplete
    //   multiple
    //   disableCloseOnSelect
    //   limitTags={3}
    //   id='unnaturaldeath'
    //   options={unnaturalDeathOptions}
    //   value={unnaturalDeath}
    //   size='small'
    //   getOptionLabel={(option) => option.optionName.toString()}
    //   onChange={(event, data) =>
    //     handleUnnaturalDeathChange(event, data)
    //   }
    //   renderOption={(props, option, { selected }) => (
    //     <li {...props}>
    //       <Checkbox
    //         icon={<MdCheckBoxOutlineBlank />}
    //         checkedIcon={<MdOutlineCheckBox />}
    //         style={{ marginRight: 8 }}
    //         checked={selected}
    //       />
    //       {option.optionName.toString()}
    //     </li>
    //   )}
    //   style={{ width: '100%' }}
    //   renderInput={(params) => (
    //     <TextField
    //       {...params}
    //       label='Category of Unnatural Death'
    //       placeholder='Category of Unnatural Death'
    //     />
    //   )}
    //   ListboxComponent={'ul'}
    // />
    //           </Col>
    //         </Row>
    //         <Row>
    //           <Col className='me-col' md='4'>
    // <FormControlLabel
    //   control={
    //     <Switch
    //       checked={friendlyHeader}
    //       onChange={friendlyHeaderToggle}
    //     />
    //   }
    //   label='Friendly Headers'
    // />
    //           </Col>
    //         </Row>
    //       </CardBody>

    //       <Button
    //         type='submit'
    //         color='primary'
    //         style={{ width: 120, marginLeft: 18, float: 'right' }}
    //         onClick={handleSubmit}
    //       >
    //         Calculate
    //       </Button>

    //       <Button
    //         color='secondary'
    //         style={{ width: 120, marginLeft: 18, float: 'right' }}
    //         onClick={clearAll}
    //       >
    //         Clear
    //       </Button>
    //       <hr />
    //       <br />

    //       <Collapse isOpen={resultsVisible}>
    // <ReportDataGrid
    //   loading={loading}
    //   listresults={results}
    //   idcolumn='caseID'
    //   columnsInput={friendlyHeader ? friendlyColumns : reportColumns}
    //   title='Violent Deaths' // Change to report name
    //   casecount
    //   chatcontent={
    //     <Paper
    //       elevation={3}
    //       sx={{
    //         p: '10px',
    //         px: '20px',
    //         borderRadius: '20px',
    //         width: 'max-content',
    //       }}
    //     >
    //       <div style={{ textAlign: 'center' }}>
    //         I've listed the detailed case records below, you can export
    //         the list to csv format.
    //       </div>
    //     </Paper>
    //   }
    // />
    //       </Collapse>
    //     </div>
    //   </Card>
    // </div>
  );
}
