import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Button, Grid, Paper, Typography, Divider, ListItem, List, FormHelperText, TextField, IconButton } from '@mui/material';
import * as Yup from 'yup';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router';
import { saveAdminData } from '../../../../../../services/utility-api';
// import { checkEdit } from 'utils/index.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { referenceTypeFieldLengthChecks } from '../../referenceTypeFieldLengthChecks';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const id = '00000000-0000-0000-0000-000000000000';

const AddEdit = ({ editObj, referenceMasterTableName, ClothingType, maxOrder, setIsAddEditOn, getAllData, referencePermissions }) => {
    const [dialogContents, setDialogContents] = useState('');
    const [openApiDialogBox, setOpenApiDialogBox] = useState(false);

    const initialValues = {
        ...editObj,
        clothingTypeName: editObj?.clothingTypeName ? editObj?.clothingTypeName : '',
        clothingTypeOrder: editObj?.clothingTypeOrder ? Number(editObj.clothingTypeOrder) : maxOrder + 10,
        clothingSubType: editObj?.clothingSubType ?? []
    };

    const validationSchema = Yup.object().shape({
        clothingTypeName: Yup.string()
            .required('Clothing type name is required')
            .max(
                referenceTypeFieldLengthChecks[`clothingTypeName`],
                `Clothing Type Name can not be more than ${referenceTypeFieldLengthChecks['clothingTypeName']}`
            ),
        clothingTypeOrder: Yup.string()
            .required('Clothing type order is required')
            .max(99999, 'Clothing Type Order can not be more than 99999'),
        clothingSubType: Yup.array().of(Yup.object().shape({
            clothingSubTypeName: Yup.string().required('Sub Type Required!'),
            clothingSubTypeOrder: Yup.string().required('Order Required!')
        }))
    });

    const onSave = async (values) => {
        try {
            const response = await
                saveAdminData({
                    values: {
                        ...values,
                        [referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq']:
                            values[referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq'] || id,
                        clothingSuperTypeSeq: editObj?.clothingSuperTypeSeq ?? id
                    },
                    tableName: referenceMasterTableName
                })

            if (response.status == 0) {
                setTimeout(() => {
                    setIsAddEditOn(false);
                    getAllData();
                }, 1700);
            }
            setDialogContents(response.message);
            setOpenApiDialogBox(true);
        } catch (error) {
            console.log('error', error);
        }
    };
    const [subCatItem, SetSubCatItem] = useState(
        Object.keys(editObj).length && editObj['clothingSubType'] ? editObj['clothingSubType'] : []
    );
    const [count, setCount] = useState(0);

    const handleAddSubCatType = (values) => {
        const newItem = [...subCatItem, { id: subCatItem.length, itemName: 'item', listOrder: 33 }];
        setCount(count + 1);
        SetSubCatItem([...newItem]);
    };

    const handleRemove = (index) => {
        if (index > -1) {
            const filterSubtype = subCatItem.map((subtype, index) => subtype.id !== index);
            SetSubCatItem(filterSubtype);
        }
    };

    return (
        <>
            <Grid elevation={0} spacing={2} sx={{ padding: '16px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={10} mb={2} mt={2}>
                        <Typography variant="h5"><strong>{Object.keys(editObj).length ? 'Modify ' : 'Enter New '} Clothing Type</strong></Typography>
                    </Grid>
                </Grid>
                <Formik initialValues={initialValues} onSubmit={onSave} validationSchema={validationSchema}>
                    {({ handleSubmit, values, handleChange }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item sm={6} xs={12} md={6} lg={6}>
                                                <FormControl fullWidth error required variant="standard">
                                                    <TextField
                                                        required
                                                        focused
                                                        id="outlined-basic"
                                                        label={
                                                            'ClothingType'
                                                                .split('')
                                                                .map((alpha) => {
                                                                    if (alpha <= 'Z') return ' ' + alpha;
                                                                    return alpha;
                                                                })
                                                                .join('')
                                                                .trim() + ' Name'
                                                        }
                                                        onInput={(e) => {
                                                            e.target.value = e.target.value.slice(
                                                                0,
                                                                referenceTypeFieldLengthChecks['clothingTypeName']
                                                            );
                                                        }}
                                                        disabled={
                                                            values[
                                                            referenceMasterTableName[0].toLowerCase() +
                                                            referenceMasterTableName.slice(1) +
                                                            'Name'
                                                            ] == 'Other' ||
                                                            values[
                                                            referenceMasterTableName[0].toLowerCase() +
                                                            referenceMasterTableName.slice(1) +
                                                            'Name'
                                                            ] == 'Unknown'
                                                        }
                                                        variant="outlined"
                                                        name={'clothingTypeName'}
                                                        value={values?.['clothingTypeName']}
                                                        onChange={handleChange}
                                                    />
                                                    <ErrorMessage
                                                        name={'clothingTypeName'}
                                                        render={(msg) => <FormHelperText error>{msg}</FormHelperText>}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item sm={6} xs={12} md={6} lg={6}>
                                                <FormControl
                                                    error
                                                    fullWidth
                                                    variant="standard"
                                                    sx={{ position: 'relative', bottom: '16px' }}
                                                >
                                                    <TextField
                                                        focused
                                                        required
                                                        id="clothingTypeOrder"
                                                        label="Clothing Type Order"
                                                        variant="outlined"
                                                        name="clothingTypeOrder"
                                                        margin="normal"
                                                        size="large"
                                                        value={values?.clothingTypeOrder}
                                                        onChange={handleChange}
                                                        disabled={
                                                            values[
                                                            referenceMasterTableName[0].toLowerCase() +
                                                            referenceMasterTableName.slice(1) +
                                                            'Name'
                                                            ] == 'Other' ||
                                                            values[
                                                            referenceMasterTableName[0].toLowerCase() +
                                                            referenceMasterTableName.slice(1) +
                                                            'Name'
                                                            ] == 'Unknown'
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        name={'clothingTypeOrder'}
                                                        render={(msg) => <FormHelperText error>{msg}</FormHelperText>}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="h5" mt={0}>
                                                    Clothing Sub-Types
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} mt={-1}>
                                                <Button
                                                    onClick={() => {
                                                        let maxSubTypeOrder = -10;
                                                        values['clothingSubType'].forEach((subType) => {
                                                            if (subType['clothingSubTypeOrder'] > maxSubTypeOrder)
                                                                maxSubTypeOrder = subType['clothingSubTypeOrder'];
                                                        });

                                                        handleChange({
                                                            target: {
                                                                name: 'clothingSubType',
                                                                value: [
                                                                    ...values['clothingSubType'],
                                                                    {
                                                                        clothingSubTypeName: '',
                                                                        clothingSubTypeOrder: maxSubTypeOrder + 10,
                                                                        clothingSubTypeSeq: id,
                                                                        clothingTypeSeq: values.clothingTypeSeq,
                                                                        status: 'Active'
                                                                    }
                                                                ]
                                                            }
                                                        });
                                                    }}
                                                    variant="contained"
                                                    startIcon={<AddCircleIcon />}
                                                >
                                                    Add Sub Type
                                                </Button>
                                            </Grid>
                                            {values?.clothingSubType.length > 0
                                                ? <Grid item xs={12}>
                                                    <List className='border border-bottom-0'  sx={{ bgcolor: '#fff', py: '0px', color: '#000' }}>
                                                        <ListItem>
                                                            <Grid container spacing={2}>
                                                                <Grid
                                                                    xs={5}
                                                                    md={5}
                                                                    item
                                                                    sx={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        position: 'relative',
                                                                        left: '-16px'
                                                                    }}
                                                                >
                                                                    <Typography variant="h5" mt={0} sx={{ color: '#000' }}>
                                                                        Sub Type
                                                                    </Typography>
                                                                    {/* <Divider sx={{ position: 'absolute', right: '-10px' }}>|</Divider> */}
                                                                </Grid>
                                                                <Grid
                                                                    xs={5}
                                                                    md={5}
                                                                    item
                                                                    sx={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        position: 'relative',
                                                                        left: '-16px'
                                                                    }}
                                                                >
                                                                    <Typography variant="h5" mt={0} sx={{ color: '#000' }}>
                                                                        List Order
                                                                    </Typography>
                                                                    {/* <Divider sx={{ position: 'absolute', right: '-10px' }}>|</Divider> */}
                                                                </Grid>
                                                            </Grid>
                                                        </ListItem>
                                                    </List>

                                                    <List sx={{ paddingTop: '0px' }} className='statusTable border pb-0'>
                                                        {values?.clothingSubType
                                                            .filter((subType) => subType.status == 'Active')
                                                            .map((item, index) => (
                                                                <ListItem
                                                                    key={index}
                                                                    id={index}
                                                                    sx={{ py: '0px', marginBottom: '0px', backgroundColor: '#fff' }}
                                                                >
                                                                    <Grid className='mt-0' container spacing={2}>
                                                                        <Grid
                                                                            className='my-0 pb-3'
                                                                            xs={5}
                                                                            item
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                position: 'relative',
                                                                                left: '-16px'
                                                                            }}
                                                                        >
                                                                            <FormControl error variant="standard" sx={{ width: '90%' }}>
                                                                                <TextField
                                                                                    focused
                                                                                    required
                                                                                    id={`clothingType_${index}`}
                                                                                    label="Sub Type"
                                                                                    variant="outlined"
                                                                                    name={`clothingSubType.${index}.clothingSubTypeName`}
                                                                                    margin="normal"
                                                                                    size="large"
                                                                                    value={
                                                                                        values['clothingSubType'][index]['clothingSubTypeName']
                                                                                    }
                                                                                    onChange={handleChange}
                                                                                />
                                                                                <ErrorMessage
                                                                                    name={`clothingSubType.${index}.clothingSubTypeName`}
                                                                                    render={(msg) => <FormHelperText error>{msg}</FormHelperText>}
                                                                                />
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid
                                                                            xs={5}
                                                                            item
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                position: 'relative',
                                                                                left: '-16px'
                                                                            }}
                                                                        >
                                                                            <FormControl error variant="standard" sx={{ width: '90%' }}>
                                                                                <TextField
                                                                                    focused
                                                                                    required
                                                                                    type="number"
                                                                                    id={`subTypeOrder_${index}`}
                                                                                    label="Order"
                                                                                    variant="outlined"
                                                                                    name={`clothingSubType.${index}.clothingSubTypeOrder`}
                                                                                    margin="normal"
                                                                                    size="large"
                                                                                    value={
                                                                                        values['clothingSubType'][index]['clothingSubTypeOrder']
                                                                                    }
                                                                                    onChange={handleChange}
                                                                                />
                                                                                <ErrorMessage
                                                                                    name={`clothingSubType.${index}.clothingSubTypeOrder`}
                                                                                    render={(msg) => <FormHelperText error>{msg}</FormHelperText>}
                                                                                />
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid
                                                                            xs={2}
                                                                            item
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                paddingRight: '15px',
                                                                                position: 'relative'
                                                                            }}
                                                                        >
                                                                            <Button
                                                                                onClick={() => {
                                                                                    if (values?.clothingSubType[index]?.clothingSubTypeSeq) {
                                                                                        if (values?.clothingSubType[index]?.clothingSubTypeSeq !== id) {
                                                                                            handleChange({
                                                                                                target: {
                                                                                                    name: `clothingSubType.${index}.status`,
                                                                                                    value: 'InActive'
                                                                                                }
                                                                                            });
                                                                                        }
                                                                                        else {
                                                                                            handleChange({
                                                                                                target: {
                                                                                                    name: `clothingSubType.${index}.status`,
                                                                                                    value: values?.clothingSubType.splice(index, 1),
                                                                                                }
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                aria-label="delete"
                                                                                size="large"
                                                                                id={`row_id_${index}`}
                                                                                variant="contained"
                                                                            >
                                                                                <DeleteIcon fontSize="inherit" />
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </ListItem>
                                                            ))}
                                                    </List>
                                                </Grid>
                                                : <></>
                                            }

                                            <Grid item xs={12}>
                                                <Box>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        sx={{ minWidth: '125px', marginTop: '7px' }}
                                                        onClick={handleSubmit}
                                                        disabled={!referencePermissions?.add}
                                                    >
                                                        {Object.keys(editObj) > 1 ? 'Update' : 'Save'}
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>

                <div className="modal">
                    <Dialog
                        open={openApiDialogBox}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => setOpenApiDialogBox(false)}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle></DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">{dialogContents}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <IconButton onClick={() => setOpenApiDialogBox(false)}>
                                <CloseIcon />
                            </IconButton>
                        </DialogActions>
                    </Dialog>
                </div>
            </Grid>
        </>
    );
};
export default AddEdit;
