import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Portal,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { ActionList } from '../../../components/CaseView/CaseViewDateTimeField';
import { useOptionsAPI } from '../../../utils/api/useOptions.hook';
import { useAuth } from '../../../utils/auth/AuthService';
import { Option } from '../../Case/CaseViewModules/MortuaryModules/XrayRequestForm/xrayrequestform.constants';
import { LiveryCaseWithStatusOptions } from '../LiveryCheckInView';
import { formatShortTimeDistance } from './CaseActivityLogTimeline';
import { LegacyLiveryStatus, LiveryAPI } from './livery.api';

const { REACT_APP_API_URL } = process.env;

export function YourLiveryDataGridAcceptedActions(
  params: GridRenderCellParams<LiveryCaseWithStatusOptions, any, any, GridTreeNodeWithRender>
) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const apiRef = useGridApiContext();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { user } = useAuth();

  const latestActivity = params.row.activityLog
    .filter(a => a.createdOn !== null)
    .sort((a, b) => new Date(b.createdOn!).getTime() - new Date(a.createdOn!).getTime())[0];

  const filteredOptions = params.row.liveryStatusOptions.filter(
    o =>
      o.optionSeq !== LegacyLiveryStatus.FORCE_LIVERY_OPEN &&
      o.optionSeq !== LegacyLiveryStatus.FORCE_LIVERY_CLOSED &&
      o.optionSeq !== LegacyLiveryStatus.ASSIGNED &&
      o.optionSeq !== LegacyLiveryStatus.REOPEN_TRANS &&
      o.optionSeq !== LegacyLiveryStatus.DEFERRED
    // && !params.row.activityLog.some(
    //   activity =>
    //     activity?.activitySeq?.toUpperCase() === o?.optionSeq?.toUpperCase() &&
    //     latestActivity?.activitySeq?.toUpperCase() !== activity?.activitySeq?.toUpperCase()
    // )
  );
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [openSaveAsCompletedDialog, setOpenSaveAsCompletedDialog] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleSaveClick = () => {
    setOpenSaveDialog(true);
  };

  const handleSaveConfirm = async () => {
    setSaving(true);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async position => {
          await saveCase(position.coords.latitude, position.coords.longitude);
        },
        async error => {
          console.error('Error getting geolocation:', error);
          await saveCase(null, null);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
      await saveCase(null, null);
    }
  };

  const saveCase = async (latitude?: number | null, longitude?: number | null) => {
    try {
      setSaving(true);
      const updatedCase = await LiveryAPI.updateLiveryCase(
        {
          caseSeq: params.row.caseSeq,
          latestStatusSeq: formik.values.selectedStatus?.optionSeq ?? '',
          latitude: latitude ?? undefined,
          longitude: longitude ?? undefined,
          recoverySealNumber: formik.values.recoverySealNumber ?? undefined,
          checkinTime: formik.values.checkinTime,
          transporter1PersonSeq: formik.values.transporter1?.optionSeq || null,
          transporter2PersonSeq: formik.values.transporter2?.optionSeq || null,
        },
        user?.accessToken!
      );
      if (updatedCase) {
        const updatedRow = {
          ...updatedCase,
          setAt: new Date(updatedCase.setAt),
          status: updatedCase.status?.toLowerCase(),
          decedentFullName: updatedCase.decedentFullName?.toUpperCase(),
          liveryStatusOptions: params.row.liveryStatusOptions,
        };

        apiRef.current.updateRows([updatedRow]);
        setOpenSnackbar(true);
      }

      formik.resetForm();
    } catch (err) {
      console.error(err);
    } finally {
      setSaving(false);
      setOpenSaveDialog(false);
      params.row.refetchCases();
    }
  };

  const saveCaseAsCompleted = async () => {
    setOpenSaveAsCompletedDialog(true);
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const formik = useFormik<{
    selectedStatus: Option | null;
    liveryServiceUserCurrentGeolocation: {
      latitude: number | null;
      longitude: number | null;
    };
    recoverySealNumber: string | null;
    transporter1: Option | null;
    transporter2: Option | null;
    checkinTime: string | null;
  }>({
    initialValues: {
      selectedStatus: filteredOptions.find(
        o => o.optionSeq.toUpperCase() === latestActivity?.activitySeq?.toUpperCase()
      ) ?? { optionName: 'Unknown', optionSeq: 'Unknown' },
      liveryServiceUserCurrentGeolocation: {
        latitude: null,
        longitude: null,
      },
      recoverySealNumber: params.row.recoverySealNumber ?? null,
      transporter1: params.row.transporter1,
      transporter2: params.row.transporter2,
      checkinTime: null,
    },
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const isDisabled =
    (latestActivity &&
      latestActivity.activitySeq?.toUpperCase() ===
        formik.values.selectedStatus?.optionSeq?.toUpperCase()) ||
    formik.values.selectedStatus === null;

  const disableRecoverySealNumber =
    latestActivity.activitySeq?.toUpperCase() === LegacyLiveryStatus.CUSTODY_OF_BODY_SEALS_BODY;

  const lastUpdatedLabel = useMemo(() => {
    if (isDisabled) {
      if (formik.values.selectedStatus === null) {
        return 'Select a status';
      }

      const localDate = latestActivity?.createdOn ? dayjs(latestActivity.createdOn) : null;

      return `Last updated ${formatShortTimeDistance(localDate)}`;
    }

    if (formik.values.selectedStatus !== null) {
      return 'Last updated now';
    }

    return undefined;
  }, [isDisabled, formik.values.selectedStatus]);

  const showRecoverySeal = [
    LegacyLiveryStatus.COMPLETED,
    LegacyLiveryStatus.CUSTODY_OF_BODY_SEALS_BODY,
    LegacyLiveryStatus.DEPARTED_SCENE,
    LegacyLiveryStatus.LIVERY_AT_SCENE,
    LegacyLiveryStatus.CUSTODY_OF_BODY_SEALS_BODY,
    LegacyLiveryStatus.ENTER_REPLACEMENT_RECOVERY_SEAL,
  ].includes(
    formik.values.selectedStatus?.optionSeq?.toUpperCase() as unknown as LegacyLiveryStatus
  );

  const showCheckinDate = [LegacyLiveryStatus.COMPLETED].includes(
    formik.values.selectedStatus?.optionSeq?.toUpperCase() as unknown as LegacyLiveryStatus
  );

  const { options: transporterOptions } = useOptionsAPI(
    `gettransporteroptionsforliveryserviceuser?liveryUserSeq=${user.userSeq?.toUpperCase()}`
  );

  const pickupStartTime = useMemo(() => {
    const pickupEntry = params.row.activityLog.find(
      entry => entry.activitySeq.toUpperCase() === LegacyLiveryStatus.LIVERY_EN_ROUTE
    );
    return pickupEntry && pickupEntry.setAt ? dayjs(pickupEntry.setAt).toString() : 'Unknown';
  }, [params.row.activityLog]);

  const arrivalOnSceneTime = useMemo(() => {
    const arrivalEntry = params.row.activityLog.find(
      entry => entry.activitySeq.toUpperCase() === LegacyLiveryStatus.LIVERY_AT_SCENE
    );
    return arrivalEntry && arrivalEntry.setAt ? dayjs(arrivalEntry.setAt).toString() : 'Unknown';
  }, [params.row.activityLog]);

  const departedSceneTime = useMemo(() => {
    const departureEntry = params.row.activityLog.find(
      entry => entry.activitySeq.toUpperCase() === LegacyLiveryStatus.DEPARTED_SCENE
    );
    return departureEntry && departureEntry.setAt
      ? dayjs(departureEntry.setAt).toString()
      : 'Unknown';
  }, [params.row.activityLog]);

  const transporterNames = [
    formik.values.transporter1?.optionName,
    formik.values.transporter2?.optionName,
  ]
    .filter(Boolean)
    .join('. ');

  const isValidCheckinTime = useMemo(() => {
    if (!formik.values.checkinTime) return false;
    const checkinTime = dayjs(formik.values.checkinTime);
    const now = dayjs();
    return checkinTime.isValid();
  }, [formik.values.checkinTime]);

  return (
    <>
      {isMobile ? (
        <Grid container spacing={2}>
          {transporterOptions.length > 0 && (
            <Grid item xs={12}>
              <TransportersAutocomplete formik={formik} transporterOptions={transporterOptions} />
            </Grid>
          )}

          <Grid item xs={12}>
            <Autocomplete
              size='small'
              options={filteredOptions}
              autoHighlight
              isOptionEqualToValue={(o, v) =>
                o.optionSeq.toLowerCase() === v.optionSeq.toLowerCase()
              }
              getOptionLabel={option => option.optionName.toUpperCase()}
              value={formik.values.selectedStatus}
              onChange={(o, v) => {
                formik.setFieldValue('selectedStatus', v);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label={lastUpdatedLabel}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'none',
                  }}
                  fullWidth
                />
              )}
              fullWidth
            />
          </Grid>

          <Grid component={Collapse} in={showRecoverySeal} unmountOnExit item xs={12}>
            <TextField
              // disabled={disableRecoverySealNumber}
              value={formik.values.recoverySealNumber}
              label='Recovery Seal #'
              onChange={e => formik.setFieldValue('recoverySealNumber', e.target.value)}
              type='number'
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid component={Collapse} in={showCheckinDate} unmountOnExit item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                // fullWidth
                label='Check-in Time'
                // required
                // variant='outlined'
                value={formik.values.checkinTime !== null ? dayjs(formik.values.checkinTime) : null}
                onChange={date => {
                  const localDate = date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : null;
                  formik.setFieldValue('checkinTime', localDate);
                }}
                // InputLabelProps={{
                //   shrink: true,
                // }}
                // clearable
                format='L HH:mm'
                views={['year', 'month', 'day', 'hours', 'minutes']}
                timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                slots={{
                  actionBar: ActionList,
                }}
                ampm={false}
                sx={{ width: '100%' }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant={!formik.dirty ? 'text' : 'contained'}
              color={showCheckinDate ? 'success' : 'primary'}
              size='small'
              onClick={() => (showCheckinDate ? saveCaseAsCompleted() : setOpenSaveDialog(true))}
              disabled={!formik.dirty || (showCheckinDate && !isValidCheckinTime)}
              sx={{ px: 4 }}
            >
              {showCheckinDate ? 'Mark livery completed' : 'Update status'}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Stack direction='row' width='100%'>
          <Grid container direction='row' justifyContent='flex-start' alignItems='center' gap={2}>
            {transporterOptions.length > 0 && (
              <Grid item xs={3.5}>
                <TransportersAutocomplete formik={formik} transporterOptions={transporterOptions} />
              </Grid>
            )}

            <Grid item xs={3}>
              <Autocomplete
                size='small'
                options={filteredOptions}
                autoHighlight
                isOptionEqualToValue={(o, v) =>
                  o.optionSeq.toLowerCase() === v.optionSeq.toLowerCase()
                }
                getOptionLabel={option => option.optionName.toUpperCase()}
                value={formik.values.selectedStatus}
                onChange={(o, v) => {
                  formik.setFieldValue('selectedStatus', v);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={lastUpdatedLabel}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'none',
                    }}
                    fullWidth
                  />
                )}
                fullWidth
              />
            </Grid>

            <Grid component={Collapse} in={showRecoverySeal} unmountOnExit item xs={2}>
              <TextField
                // disabled={disableRecoverySealNumber}
                value={formik.values.recoverySealNumber}
                label='Recovery Seal #'
                onChange={e => formik.setFieldValue('recoverySealNumber', e.target.value)}
                type='number'
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid component={Collapse} in={showCheckinDate} unmountOnExit item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  // fullWidth
                  label='Check-in Time'
                  // required
                  // variant='outlined'
                  value={
                    formik.values.checkinTime !== null ? dayjs(formik.values.checkinTime) : null
                  }
                  onChange={date => {
                    const localDate = date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : null;
                    formik.setFieldValue('checkinTime', localDate);
                  }}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  // clearable
                  format='L HH:mm'
                  views={['year', 'month', 'day', 'hours', 'minutes']}
                  timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                  slots={{
                    actionBar: ActionList,
                  }}
                  ampm={false}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Button
            variant='contained'
            color={showCheckinDate ? 'success' : 'primary'}
            size='small'
            onClick={() => (showCheckinDate ? saveCaseAsCompleted() : setOpenSaveDialog(true))}
            disabled={!formik.dirty || (showCheckinDate && !isValidCheckinTime)}
            sx={{ px: 4 }}
          >
            {showCheckinDate ? 'Mark livery completed' : 'Update status'}
          </Button>
        </Stack>
      )}

      <Dialog open={openSaveDialog} onClose={() => setOpenSaveDialog(false)}>
        <DialogTitle>Confirm Save</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to save the changes?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSaveDialog(false)}>Cancel</Button>
          <Button disabled={saving} onClick={handleSaveConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openSaveAsCompletedDialog} onClose={() => setOpenSaveAsCompletedDialog(false)}>
        <DialogTitle>Mark livery as completed</DialogTitle>
        <DialogContent>
          <Typography fontWeight='bold'>
            {params.row.caseID} — {params.row.decedentFullName.toUpperCase()}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 0,
            }}
          >
            {[
              {
                label: 'Transporter(s):',
                value: transporterNames || 'Not applicable/self',
              },
              { label: 'Recovery seal #:', value: formik.values.recoverySealNumber || 'N/A' },
              { label: 'Replacement recovery seal #:', value: 'N/A' },
              { label: 'Pickup start time:', value: pickupStartTime },
              { label: 'Arrival on scene time:', value: arrivalOnSceneTime },
              { label: 'Departed scene time:', value: departedSceneTime },
              {
                label: 'Check-in time*:',
                value: formik.values.checkinTime
                  ? dayjs(formik.values.checkinTime).toString()
                  : 'Unknown',
              },
            ].map((item, index) => (
              <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ width: '50%', pr: 2 }}>{item.label}</Typography>
                <Typography sx={{ width: '50%', fontWeight: 'bold', textAlign: 'right' }}>
                  {item.value}
                </Typography>
              </Box>
            ))}
          </Box>
          <Typography color='error' fontWeight='bold' sx={{ mt: 2 }}>
            You will not be able to view or edit this case after saving. By continuing, you are
            confirming the above information as accurate.
          </Typography>

          <br />
          <Typography>Are you sure you want to continue? This cannot be undone.</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            color='error'
            onClick={() => setOpenSaveAsCompletedDialog(false)}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={saving || formik.values.checkinTime === null}
            onClick={handleSaveConfirm}
            autoFocus
          >
            Confirm livery as completed
          </Button>
        </DialogActions>
      </Dialog>
      <Portal>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity='success' sx={{ width: '100%' }}>
            Case updated successfully!
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
}

const icon = <CheckBoxOutlineBlank fontSize='small' />;
const checkedIcon = <CheckBox fontSize='small' />;

function TransportersAutocomplete({
  formik,
  transporterOptions = [],
}: {
  formik: ReturnType<typeof useFormik>;
  transporterOptions: Option[];
}) {
  const selectedTransporters = useMemo(() => {
    const porters: Option[] = [];
    if (formik.values.transporter1) {
      porters.push(formik.values.transporter1);
    }

    if (formik.values.transporter2) {
      porters.push(formik.values.transporter2);
    }

    return porters;
  }, [formik.values.transporter1, formik.values.transporter2]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleChange = (event, newValue) => {
    if (newValue.length <= 2) {
      if (typeof newValue[0] !== undefined) {
        formik.setFieldValue('transporter1', newValue[0]);
      } else {
        formik.setFieldValue('transporter1', null);
      }
      if (typeof newValue[0] !== undefined) {
        formik.setFieldValue('transporter2', newValue[1]);
      } else {
        formik.setFieldValue('transporter2', null);
      }
    } else {
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <Autocomplete
        size='small'
        options={transporterOptions}
        multiple
        autoHighlight
        disableCloseOnSelect
        getOptionLabel={option => option?.optionName}
        getOptionKey={o => o.optionSeq}
        isOptionEqualToValue={(o, v) => o.optionSeq.toUpperCase() === v?.optionSeq?.toUpperCase()}
        value={selectedTransporters}
        limitTags={1}
        onChange={handleChange}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              disabled={selectedTransporters.length >= 2}
            />

            {option?.optionName}
          </li>
        )}
        renderInput={params => (
          <TextField
            {...params}
            label='Transporter(s)'
            inputProps={{
              ...params.inputProps,
              autoComplete: 'none',
            }}
            fullWidth
          />
        )}
        fullWidth
      />
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        >
          <Alert severity='warning'>Maximum of 2 transporters can be selected.</Alert>
        </Snackbar>
      </Portal>
    </>
  );
}
