export const GET_MARITAL_STATUS_OPTION = 'GET_MARITAL_STATUS_OPTION';
export const GET_MARITAL_STATUS_OPTION_SUCCESS =
  'GET_MARITAL_STATUS_OPTION_SUCCESS';
export const GET_MARITAL_STATUS_OPTION_FAIL = 'GET_MARITAL_STATUS_OPTION_FAIL';

export const GET_STATE_OPTION = 'GET_STATE_OPTION';
export const GET_STATE_OPTION_SUCCESS = 'GET_STATE_OPTION_SUCCESS';
export const GET_STATE_OPTION_FAIL = 'GET_STATE_OPTION_FAIL';

export const UPDATE_DEMOGRAPHICS = 'UPDATE_DEMOGRAPHICS';
export const UPDATE_DEMOGRAPHICS_SUCCESS = 'UPDATE_DEMOGRAPHICS_SUCCESS';
export const UPDATE_DEMOGRAPHICS_FAIL = 'UPDATE_DEMOGRAPHICS_FAIL';
