import { Box, Grid, Stack } from '@mui/material';
import React from 'react';
import { CardHeader } from 'reactstrap';

export default function CaseViewFieldsContainer(props) {
  const { title = null, visible = true, children, actions } = props;

  return (
    <Box sx={{ pb: 2, display: visible ?? false ? 'block' : 'none' }}>
      {title !== null && (
        <CardHeader style={{ paddingBottom: 20 }}>
          <Stack direction='row' spacing={2}>
            <strong>{title.toUpperCase()}</strong>
            {actions}
          </Stack>
        </CardHeader>
      )}
      <Grid container spacing={2}>
        {children}
      </Grid>
    </Box>
  );
}
