import React, { useState, useRef, useCallback, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import Webcam from 'react-webcam';
import CloseIcon from '@mui/icons-material/Close';
import { GlobalContext } from '../../../Context/GlobalContext';

const Takephoto = ({ open = false, handleClose }) => {
  const [img, setImg] = useState();
  const webcamRef = useRef(null);
  const [webCam, setWebcam] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const { sendPhoto, sendPhotoToViewModal } = useContext(GlobalContext);

  const captureScreenShot = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      sendPhoto(imageSrc);
      setImgSrc(imageSrc);
      setImg(imageSrc);
      setWebcam(false);
    }
  }, [webcamRef]);

  const handleWebCam = () => {
    setWebcam(true);
    setImg(null);
    setImgSrc('');
  };

  const discardPhoto = () => {
    setImgSrc('');
    setWebcam(true);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h6'>Update Profile Picture</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box>
            <Typography>Take Photo:</Typography>
            {!webCam && !imgSrc ? (
              <Button variant='contained' onClick={handleWebCam}>
                Start Camera
              </Button>
            ) : (
              <>
                {imgSrc && (
                  <Button onClick={discardPhoto} variant='outlined' color='secondary'>
                    Discard Photo
                  </Button>
                )}
                {webCam && !imgSrc && (
                  <Button variant='contained' onClick={captureScreenShot}>
                    Capture
                  </Button>
                )}
              </>
            )}
          </Box>
          <Box>
            <Typography>or Upload from file:</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                value={img?.path || ''}
                size='small'
                placeholder='File name'
                sx={{ mr: 1 }}
              />
              <Button
                variant='contained'
                component='label'
                onClick={() => {
                  setImg('');
                  setWebcam(false);
                }}
              >
                Browse
                <input
                  onChange={e => {
                    setImgSrc(e.target?.files);
                    sendPhoto(e.target.files);
                    setImg(URL.createObjectURL(e.target?.files?.[0]));
                  }}
                  accept='image/*'
                  type='file'
                  hidden
                />
              </Button>
            </Box>
          </Box>
        </Box>
        <Box sx={{ height: 400, display: 'flex', justifyContent: 'center' }}>
          {webCam && (
            <Webcam
              width='100%'
              height='100%'
              audio={false}
              ref={webcamRef}
              screenshotFormat='image/jpeg'
              mirrored={true}
              disablePictureInPicture={true}
            />
          )}
          {imgSrc && (
            <Box sx={{ width: '100%', height: '100%' }}>
              <img src={img} width='100%' height='100%' alt='Captured' />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!imgSrc}
          onClick={() => {
            sendPhotoToViewModal(img);
            handleClose();
          }}
          variant='contained'
        >
          Upload
        </Button>
        <Button onClick={handleClose} variant='contained'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Takephoto;
