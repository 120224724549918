import React, { useState, useEffect } from 'react';
import { Box, Grid, Button, IconButton, Tooltip, Divider } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import MainCard from '../../../../../../components/AdministrationModules/MainCard';
import Filter from './Filter';
import AddEdit from './AddEdit';
import {
  activeData,
  deleteData,
  getAdminData,
} from '../../../../../../services/utility-api';
import AccessDenied from '../../../../../../components/AdministrationModules/AccessDenied';
import Breadcrumb from '../../../../../../components/AdministrationModules/Breadcrumb';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const LiveryPerson = () => {
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const referencePermissions = permissions?.['Reference Data'];
  const [columns, setColumns] = useState([
    {
      field: 'liveryPersonName',
      headerName: 'Person Name',
      // headerClassName: 'super-app-theme--header',
      minWidth: 200,
      align: 'left',
      flex: 5,
    },
    {
      field: 'liveryService',
      headerName: 'Livery Services',
      // headerClassName: 'super-app-theme--header',
      minWidth: 200,
      align: 'left',
      flex: 5,
    },
  ]);
  const [rows, setRows] = useState([]);
  const [openActiveInActiveDialogBox, setOpenActiveInActiveDialogBox] =
    useState(false);
  const [isAddEditOn, setIsAddEditOn] = useState();
  const [activeInactiveClickData, setActiveInactiveClickData] = useState({});
  const [editObj, setEditObj] = useState({});
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const [activeInActiveFilter, setActiveInActiveFilter] = useState('Active');
  const [rowsToShow, setRowsToShow] = useState(rows);
  const [apiSchema, setApiSchema] = useState([]);
  const [selectedRowApi, setSelectedRowApi] = useState([]);
  const MENU_OBJECT = {
    Home: '/sysadmin',
    'Reference Data': '/sysadmin',
    'Livery Person': '',
  };
  const referenceMasterTableName = 'LiveryPerson';

  let isStatusColumnAdded = false;
  columns.forEach((column) => {
    if (column.field == 'status') {
      isStatusColumnAdded = true;
    }
  });

  if (!isStatusColumnAdded) {
    setColumns((prev) => [
      ...prev,
      {
        field: 'status',
        headerName: 'Status',
        // headerClassName: 'super-app-theme--header',
        renderCell: (cellValues) => {
          const handleClick = async (row) => {
            // if (!permissions?.['Reference Data']?.edit) return;
            setOpenActiveInActiveDialogBox(true);
            const newIconData = row.status === 'Active' ? 'locked' : 'done';
            setActiveInactiveClickData({
              icon: newIconData,
              seq: row?.id,
              status: row.status,
            });
          };

          return Number(
            cellValues.row[
            referenceMasterTableName[0].toLowerCase() +
            referenceMasterTableName.slice(1) +
            'Order'
            ]
          ) > 90000 ? (
            <></>
          ) : (
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleClick(cellValues.row);
              }}
            //   disabled={!permissions?.['Reference Data']?.edit}
            >
              {cellValues.row.status === 'Active' ? (
                <Tooltip title='Active'>
                  <LockIcon />
                </Tooltip>
              ) : (
                <Tooltip title='InActive'>
                  <DoneIcon />
                </Tooltip>
              )}
            </Button>
          );
        },
      },
    ]);
  }

  const [isLoading, setIsLoading] = useState(true);
  const getAllData = async () => {
    setIsLoading(true);
    const response = await getAdminData('LiveryPerson');
    const data = response?.data?.data.map((liveryRow) => {
      const {
        personFirstName,
        personMiddleName,
        personLastName,
        personSalutationSeq,
        personSuffix,
      } = liveryRow?.person ?? {};
      const { liveryName } = liveryRow?.livery ?? {};
      const livery = {
        id: liveryRow?.liveryPersonSeq,
        liveryPersonName: personMiddleName
          ? personFirstName + ' ' + personMiddleName + ' ' + personLastName
          : personFirstName + ' ' + personLastName,
        liveryService: liveryName,
        status: liveryRow?.status,
        firstName: personFirstName,
        suffix: personSuffix,
        middleName: personMiddleName,
        lastName: personLastName,
        personSalutationSeq: personSalutationSeq,
      };

      return livery;
    });
    setRows(data);
    setRowsToShow(
      data.filter((row) => row['status'].toLowerCase() === 'active')
    );
    setApiSchema(response?.data?.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getAllData();
  }, []);

  const editRow = (params) => {
    if (referencePermissions?.edit) {
      if (params?.row?.status.toLowerCase() === 'active') {
        const filteredApiSchema = apiSchema?.filter(
          (selectedRow) => selectedRow?.liveryPersonSeq === params.id
        );

        setSelectedRowApi(filteredApiSchema);
        setIsAddEditOn(false);
        setTimeout(() => {
          setEditObj(params.row);
          setIsAddEditOn(true);
        }, 200);
      }
    }
  };

  const changeActiveInActiveClick = async () => {
    try {
      if (activeInactiveClickData.status === 'Active') {
        const responseMessage = await deleteData({
          seq: activeInactiveClickData.seq,
          tableName: 'LiveryPerson',
        });
        setDialogContents(responseMessage);
      } else {
        const responseMessage = await activeData({
          seq: activeInactiveClickData.seq,
          tableName: 'LiveryPerson',
        });
        setDialogContents(responseMessage);
      }
      setOpenActiveInActiveDialogBox(false);
      setOpenApiDialogBox(true);
      setRows([]);
      setRowsToShow([]);
      getAllData();
    } catch (error) {
      console.log('error', error);
    }
  };

  if (!referencePermissions?.view) {
    return (<>
      <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
      <AccessDenied />
    </>)
  }
  else {
    return (
      // <div className='container my-5 py-5'>
      <Grid container spacing={2} sx={{mt: '10px', padding: '16px'}}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
              <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
              {/* <MainCard> */}
                <Box
                  // sx={{
                  //   width: '100%',
                  //   height: '80vh',
                  //   color: '#fff',
                  //   margin: '10px 0px',
                  //   '& .super-app-theme--header': {
                  //     backgroundColor: 'rgb(2, 136, 209)',
                  //     color: '#fff',
                  //   },
                  // }}
                  style={{ tr: 'hover', cursor: 'pointer' }}
                >
                <Filter rows={rows} setRowsToShow={setRowsToShow} />
                  <DataGrid
                    initialState={{
                      sorting: {
                        sortModel: [
                          {
                            field: 'liveryPersonName',
                            sort: 'asc',
                          },
                        ],
                      },
                    }}
                    rowHeight={35}
                    rows={rowsToShow}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick={false}
                    onRowClick={editRow}
                    // sx={{
                    //   m: 2,
                    //   marginLeft: '0px',
                    //   fontSize: '14px',
                    //   fontFamily:
                    //     'Roboto , Helvetica , Arial , sans-serif !important',
                    //   height: 500,
                    //   width: '100%',
                    //   direction: 'row',
                    //   overflow: 'hidden',
                    //   borderRadius: '6px',
                    //   backgroundColor: 'white',
                    //   boxShadow: '0px 0px 10px #ddd',
                    // }}
                    loading={isLoading}
                  />
                </Box>
                <Box>
                  <div className='modal'>
                    <Dialog
                      open={openActiveInActiveDialogBox}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={() => setOpenActiveInActiveDialogBox(false)}
                      aria-describedby='alert-dialog-slide-description'
                    >
                      <DialogTitle></DialogTitle>
                      <DialogContent>
                        <DialogContentText id='alert-dialog-slide-description'>
                          Are you sure you want to{' '}
                          {activeInactiveClickData?.status === 'Active'
                            ? 'InActive '
                            : 'Active'}{' '}
                          this livery person?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => setOpenActiveInActiveDialogBox(false)}
                        >
                          No
                        </Button>
                        <Button onClick={changeActiveInActiveClick}>Yes</Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </Box>
                <Box>
                  <Button
                    variant='contained'
                    sx={{ minWidth: '125px', marginTop: '7px' }}
                    onClick={() => {
                      setIsAddEditOn(false);
                      setTimeout(() => {
                        setEditObj({});
                        setIsAddEditOn(true);
                      }, 200);
                    }}
                    disabled={!referencePermissions?.add}
                  >
                    Add Livery Person
                  </Button>
                </Box>
                <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                <Box>
                  {isAddEditOn && editObj && (
                    <AddEdit
                      editObj={editObj}
                      referenceMasterTableName='LiveryPerson'
                      setIsAddEditOn={setIsAddEditOn}
                      getAllData={getAllData}
                      selectedRowApi={selectedRowApi?.[0] ?? {}}
                      referencePermissions={referencePermissions}
                    />
                  )}
                </Box>

                <div className='modal'>
                  <Dialog
                    open={openApiDialogBox}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpenApiDialogBox(false)}
                    aria-describedby='alert-dialog-slide-description'
                  >
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                      <DialogContentText id='alert-dialog-slide-description'>
                        {dialogContents}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <IconButton onClick={() => setOpenApiDialogBox(false)}>
                        <CloseIcon />
                      </IconButton>
                    </DialogActions>
                  </Dialog>
                </div>
              {/* </MainCard> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      // </div>
    );
  }
};

export default LiveryPerson;
