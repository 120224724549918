import { DashboardSiloLayout } from "../dashboard.constants";
import { GridRow } from "./grid.constants";


export function convertGridRowsToDashboardLayouts(gridRows: GridRow[]): DashboardSiloLayout[] {
  const dashboardLayouts: DashboardSiloLayout[] = [];
  let layoutOrder = 0; // Initialize layout order to maintain the order of items

  gridRows.forEach(gridRow => {
    gridRow.items.forEach(item => {
      const dashboardSiloLayout: DashboardSiloLayout = {
        dashboardSiloSeq: item.key,
        dashboardSiloName: item.name,
        layoutOrder: layoutOrder++
      };
      dashboardLayouts.push(dashboardSiloLayout);
    });
  });

  return dashboardLayouts;
}
