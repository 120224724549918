import { LocalShippingRounded, People } from '@mui/icons-material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useAuth } from '../../utils/auth/AuthService';
import CustomDrawer from '../../utils/components/CustomDrawer';
import CustomHeader from '../../utils/components/CustomHeader';
import CustomTabPanel from '../../utils/components/CustomTabPanel';
import { AllRoles } from '../../utils/constants/AllRoles';
import { LiveryCheckinView } from '../Livery/LiveryCheckInView';

import YourCasesView from './YourProfileModules/YourCasesView';
import YourReportsView from './YourProfileModules/YourReportsView';

export default function YourCasesAndReportsView() {
  const auth = useAuth();
  const hasAccessToLivery = auth.user?.roleCheck([AllRoles.Livery]) ?? false;

  const [currentTab, setCurrentTab] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleTabChange = (event: any, newTab: any) => {
    setCurrentTab(newTab);
  };

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const drawerContents = [

    { text: 'Your Cases', icon: <People /> },
    ...(hasAccessToLivery ? [{ text: 'Your Livery', icon: <LocalShippingRounded /> }] : []),
    { text: 'Reports', icon: <AssessmentIcon /> },

  ];

  return (
    <Box
      component='section'
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      {/* Mobile and Desktop Drawer*/}
      <CustomDrawer
        drawerContents={drawerContents}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      {/* Page Contents */}
      <Box component='section' sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
       
<hr />
        {/* @ts-ignore */}
        <CustomTabPanel value={currentTab} index={0} sx={{ margin: 0 }}>
          {/* @ts-ignore */}
          <CustomHeader
            title='Your Case History'
            description='View all your cases, details and statistics'
            handleMenuClick={handleMenuClick}
          />
          <YourCasesView />
        </CustomTabPanel>
        {/* @ts-ignore */}
        {hasAccessToLivery && (
          //  @ts-ignore
          <CustomTabPanel value={currentTab} index={1} sx={{ margin: 0 }}>
            {/* @ts-ignore */}
            <CustomHeader
              title='Your Livery'
              description='Manage livery assignments (under construction)'
              handleMenuClick={handleMenuClick}
            />
            <LiveryCheckinView />
          </CustomTabPanel>
        )}
        {/* @ts-ignore */}
        <CustomTabPanel value={currentTab} index={hasAccessToLivery ? 2 : 1} sx={{ margin: 0 }}>
          {/* @ts-ignore */}
          <CustomHeader
            title='Reports'
            description='Create quick assessments based on your User data'
            handleMenuClick={handleMenuClick}
          />
          <YourReportsView />
        </CustomTabPanel>
        {/* @ts-ignore */}
      
      </Box>
    </Box>
  );
}
