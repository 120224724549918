import { Button, Chip, Stack } from '@mui/material';
import { useNavigate } from 'react-router';

export function CaseIDCell({ params, onCaseIDClick }: { params: any; onCaseIDClick: any }) {
  const navigate = useNavigate();

  const handleCaseClick = (event: React.MouseEvent, data: any) => {
    if (onCaseIDClick) {
      if (typeof onCaseIDClick === 'function') {
        onCaseIDClick();
      }
    } else {
      navigate('/caseview', {
        state: { cmscaseid: data.formattedValue, caseSeq: data.row.caseseq },
      });
    }
  };

  return (
    <Stack my={0.3} direction='row' alignItems='center' gap={0.5}>
      <Button
        variant='text'
        color='primary'
        sx={{
          fontFamily: 'DataGrid, sans-serif',
          fontWeight: 700,
          fontSize: '0.75rem',
        }}
        onClick={(event: React.MouseEvent<Element, MouseEvent>) => handleCaseClick(event, params)}
      >
        {params.formattedValue}
      </Button>

      {params.row?.isHold && (
        <Chip
          label='HOLD'
          color='warning'
          variant='outlined'
          size='small'
          sx={{
            maxHeight: 16,
            '& .MuiChip-label': {
              padding: '0 6px',
            },
          }}
        />
      )}
    </Stack>
  );
}
