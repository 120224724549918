import { Button, Stack, useTheme } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { animated, useSpring } from '@react-spring/web';
import axios from 'axios';
import React from 'react';
import { useAuth } from '../../utils/auth/AuthService';
import { useUnsavedChanges } from '../../Context/UnsavedChangesContext';


const { REACT_APP_API_URL } = process.env;

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const { children, in: open, onClick, onEnter, onExited, ownerState, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

interface TimeoutWarningModalProps {
  isOpen: boolean;
  onRequestClose: any;
  remainingTime: number;
}

export const TimeoutWarningModal = ({
  isOpen,
  onRequestClose,
  remainingTime,
}: TimeoutWarningModalProps) => {
  const theme = useTheme();
  const auth = useAuth();
  const { hasUnsavedChanges } = useUnsavedChanges();

  const userLoggedOut = async () => {
    try {
      await axios.post(REACT_APP_API_URL + `user/userloggedout?UserSeq=${auth?.user?.userSeq}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onLogOffCall = () => {
    userLoggedOut();
    auth.logout();
    window.location.assign(process.env.PUBLIC_URL + '/login');
  };

  const formatTime = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onRequestClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
        sx={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <Fade in={isOpen}>
          <Stack
            spacing={4}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '90%', md: '400px' },
              background: theme.palette.background.default,
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography variant='h5' component='h2'>
              Session expiring!
            </Typography>
            <Typography sx={{ mt: 2 }}>
              {hasUnsavedChanges ? (
                <span style={{ color: 'red' }}>You have unsaved changes!</span>
              ) : null}
              <br />
              Your session will expire soon. Do you want to log out or continue with your work?
            </Typography>
            <Stack direction='row' spacing={2}>
              <Button variant='outlined' color='error' onClick={onLogOffCall}>
                Log out ({formatTime(remainingTime)})
              </Button>
              <Button variant='contained' color='primary' onClick={onRequestClose}>
                Extend and continue
              </Button>
            </Stack>
          </Stack>
        </Fade>
      </Modal>
    </div>
  );
};
