import React from 'react';
import { useEffect, useState } from 'react';
// project imports

import MainCard from '../../../../../../components/AdministrationModules/MainCard';
import ReferenceMasterTableFilter from '../ReferenceMasterTableFilter';
import AddEdit from './AddEdit';
import { activeData, deleteData, getAdminData } from '../../../../../../services/utility-api';
import { useNavigate } from 'react-router';

// material-ui
import { Grid, Box, Button, IconButton, Divider } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from '@mui/x-data-grid';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import AccessDenied from '../../../../../../components/AdministrationModules/AccessDenied';
import Breadcrumb from '../../../../../../components/AdministrationModules/Breadcrumb';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
const RelationShip = () => {
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const referencePermissions = permissions?.['Reference Data'];
  const [isAddEditOn, setIsAddEditOn] = useState();
  const [editObj, setEditObj] = useState({});
  const [maxOrder, setMaxOrder] = useState(0);
  const [isCounty, setIsCounty] = useState(false);
  const [openActiveInActiveDialogBox, setOpenActiveInActiveDialogBox] =
    useState(false);
  const [activeInactiveClickData, setActiveInactiveClickData] = useState({});
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const [activeInActiveFilter, setActiveInActiveFilter] = useState('Active');
  const referenceMasterTableName = 'RelShip';

  const [columns, setColumns] = useState([
    {
      field: 'relshipNOKOrder',
      headerName: 'N.O.K Order',
      // headerClassName: 'super-app-theme--header',
      minWidth: 200,
      align: 'left',
      flex: 5,
    },
    {
      field: 'relshipName',
      headerName: 'Relationship Type',
      // headerClassName: 'super-app-theme--header',
      minWidth: 200,
      align: 'left',
      flex: 5,
    },
    {
      field: 'relshipOrder',
      headerName: 'List Order',
      // headerClassName: 'super-app-theme--header',
      minWidth: 150,
      align: 'left',
      flex: 5,
    },
    {
      field: 'relshipInverseMale',
      headerName: 'Inverse Male',
      // headerClassName: 'super-app-theme--header',
      minWidth: 150,
      align: 'left',
      flex: 5,
    },
    {
      field: 'relshipInverseFemale',
      headerName: 'Inverse Female',
      // headerClassName: 'super-app-theme--header',
      minWidth: 180,
      align: 'left',
      flex: 5,
    },
    {
      field: 'isNOKRelship',
      headerName: 'N.O.K Relationship',
      // headerClassName: 'super-app-theme--header',
      minWidth: 200,
      align: 'left',
      flex: 5,
      renderCell: (params) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            {params.value === 'True' ? (
              <Tooltip title='True'>
                <CheckBoxIcon color='primary' />
              </Tooltip>
            ) : (
              <Tooltip title='False'>
                <CheckBoxOutlineBlankIcon color='primary' />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ]);
  const [rows, setRows] = useState([]);
  const [rowsToShow, setRowsToShow] = useState([]);
  const MENU_OBJECT = {
    Home: '/sysadmin',
    'Reference Data': '/sysadmin',
    'Relationship': '',
  };
  let isStatusColumnAdded = false;
  columns.forEach((column) => {
    if (column.field == 'status') {
      isStatusColumnAdded = true;
    }
  });
  if (!isStatusColumnAdded) {
    setColumns((prev) => [
      ...prev,
      {
        field: 'status',
        headerName: 'Status',
        // headerClassName: 'super-app-theme--header',
        renderCell: (cellValues) => {
          const handleClick = async (row) => {
            // if (!permissions?.['Reference Data']?.edit) return;
            setOpenActiveInActiveDialogBox(true);
            const newIconData = row.status === 'Active' ? 'locked' : 'done';
            setActiveInactiveClickData({
              icon: newIconData,
              seq: row?.relshipSeq,
              status: row.status,
            });
          };

          return cellValues.row['relshipName'] == 'Other' ||
            cellValues.row['relshipName'] == 'Unknown' ? (
            <></>
          ) : (
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleClick(cellValues.row);
              }}
            //   disabled={!permissions?.['Reference Data']?.edit}
            >
              {cellValues.row.status === 'Active' ? (
                <Tooltip title='Inactive'>
                  <LockIcon />
                </Tooltip>
              ) : (
                <Tooltip title='Active'>
                  <DoneIcon />
                </Tooltip>
              )}
            </Button>
          );
        },
      },
    ]);
  }

  const getAllData = async () => {
    const response = await getAdminData('Relship');
    let data = response.data.data.map((row) => {
      const newObj = {};
      Object.keys(row).map((key) => {
        newObj[key] = row[key];
      });

      if (newObj['relshipOrder'])
        newObj['relshipOrder'] = Number(newObj['relshipOrder']);
      return newObj;
    });

    let maxOrder = 0;
    for (let i = 0; i < data.length; i++) {
      if (
        Number(data[i].relshipOrder) > maxOrder &&
        Number(data[i].relshipOrder) < 90000
      )
        maxOrder = Number(data[i].relshipOrder);
    }
    setMaxOrder(maxOrder);

    setRows(data);
    setRowsToShow(
      data.filter((row) => row['status'].toLowerCase() == 'active')
    );
  };

  const editRow = (params) => {
    if (referencePermissions?.edit) {
      if (params?.row?.status.toLowerCase() === 'active') {
        setIsAddEditOn(false);
        setTimeout(() => {
          setEditObj(params.row);
          setIsAddEditOn(true);
        }, 200);
      }
    }
  };
  const changeActiveInActiveClick = async () => {
    try {
      if (activeInactiveClickData.status === 'Active') {
        const responseMessage = await deleteData({
          seq: activeInactiveClickData.seq,
          tableName: 'Relship',
        });
        setDialogContents(responseMessage);
      } else {
        const responseMessage = await activeData({
          seq: activeInactiveClickData.seq,
          tableName: 'Relship',
        });
        setDialogContents(responseMessage);
      }
      setOpenActiveInActiveDialogBox(false);
      setOpenApiDialogBox(true);
      await getAllData();
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);

  if (!referencePermissions?.view) {
    return (<>
      <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
      <AccessDenied />
    </>)
  }
  else {
    return (
       // <div className='container my-5 py-5'>
       <Grid container spacing={2} sx={{mt: '10px', padding: '16px'}}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
              <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
              {/* <MainCard> */}
                <ReferenceMasterTableFilter
                  rows={rows}
                  isCounty={false}
                  countyNames={[]}
                  setRowsToShow={setRowsToShow}
                  activeInActiveFilter={activeInActiveFilter}
                  setActiveInActiveFilter={setActiveInActiveFilter}
                />
                <Box
                  // sx={{
                  //   width: '100%',
                  //   '& .super-app-theme--header': {
                  //     backgroundColor: 'rgb(2, 136, 209)',
                  //     color: '#fff',
                  //   },
                  // }}
                  style={{ tr: 'hover', cursor: 'pointer' }}
                >
                  <DataGrid
                    initialState={{
                      sorting: {
                        sortModel: [
                          {
                            field: 'relshipName',
                            sort: 'asc',
                          },
                        ],
                      },
                    }}
                    rowHeight={35}
                    rows={rowsToShow}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick={false}
                    getRowId={(row) => row.relshipSeq}
                    onRowClick={editRow}
                    // sx={{
                    //   m: 2,
                    //   marginLeft: '0px',
                    //   fontSize: '14px',
                    //   fontFamily:
                    //     'Roboto , Helvetica , Arial , sans-serif !important',
                    //   height: 500,
                    //   width: '100%',
                    //   direction: 'row',
                    //   overflow: 'hidden',
                    //   borderRadius: '6px',
                    //   backgroundColor: 'white',
                    //   boxShadow: '0px 0px 10px #ddd',
                    // }}
                  />
                </Box>
                <Box>
                  <Button
                    variant='contained'
                    sx={{ marginTop: '7px' }}
                    onClick={() => {
                      setIsAddEditOn(false);
                      setTimeout(() => {
                        setEditObj({});
                        setIsAddEditOn(true);
                      }, 200);
                    }}
                    disabled={!referencePermissions?.add}
                  >
                    Add Relationship
                  </Button>
                </Box>
                <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                {isAddEditOn && editObj && (
                  <AddEdit
                    maxOrder={maxOrder}
                    editObj={editObj}
                    referenceMasterTableName='Relship'
                    setIsAddEditOn={setIsAddEditOn}
                    getAllData={getAllData}
                    row={rows}
                    referencePermissions={referencePermissions}
                  />
                )}
                <div className='modal'>
                  <Dialog
                    open={openActiveInActiveDialogBox}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpenActiveInActiveDialogBox(false)}
                    aria-describedby='alert-dialog-slide-description'
                  >
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                      <DialogContentText id='alert-dialog-slide-description'>
                        Are you sure you want to{' '}
                        {activeInactiveClickData?.status === 'Active'
                          ? 'InActive '
                          : 'Active'}{' '}
                        this relationship?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => setOpenActiveInActiveDialogBox(false)}
                      >
                        No
                      </Button>
                      <Button onClick={changeActiveInActiveClick}>Yes</Button>
                    </DialogActions>
                  </Dialog>
                </div>

                <div className='modal'>
                  <Dialog
                    open={openApiDialogBox}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpenApiDialogBox(false)}
                    aria-describedby='alert-dialog-slide-description'
                  >
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                      <DialogContentText id='alert-dialog-slide-description'>
                        {dialogContents}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <IconButton onClick={() => setOpenApiDialogBox(false)}>
                        <CloseIcon />
                      </IconButton>
                    </DialogActions>
                  </Dialog>
                </div>
              {/* </MainCard> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      // </div>
    );
  }
};
export default RelationShip;
