import AddIcon from '@mui/icons-material/Add';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Search from '../../components/AppBar/Search';
import { useAuth } from '../../utils/auth/AuthService';
import MobileCard from './MobileCard';
import { getTimeBlock } from './getTimeBlock';
import { px } from 'framer-motion';
import { Form } from 'react-router-dom';

export default function MobileHomeView() {
  const { user } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [cardContent, setCardContent] = useState([]);
  const { REACT_APP_API_URL } = process.env;

  useEffect(() => {
    // GET CARD CONTENT
    fetch(REACT_APP_API_URL + 'home/getcardcontent', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setCardContent(data);
      })
      .catch(e => {
        //alert(e);
      });

    setLoading(false);
  }, []);

  return (
    <Box sx={{ display: { xs: 'block', md: 'none' }, height: '100%' }}>
      <Typography variant='h4'>
        Good {getTimeBlock()}, {user?.userFirstName}
      </Typography>

      <Grid container spacing={2} sx={{ mt: 5 }}>
        {loading ? (
          <Skeleton height={500} sx={{ width: '35%', borderRadius: 4 }} />
        ) : (
          cardContent.map(card => (
            <Grid xs={card.mobileSize}>
              <MobileCard
                title={card.title}
                subtitle={card.subtitle}
                content={
                  <Box sx={{ p: 2, alignItems: 'center', textAlign: 'center' }}>
                    <Typography variant='body1' sx={{ fontSize: 13 }}>
                      {card.content1}
                    </Typography>
                    <Typography variant='body2' sx={{ fontSize: 11, pt: 1 }}>
                      {card.content2}
                    </Typography>
                    <Typography variant='body2' sx={{ fontSize: 11, pt: 1, opacity: 0.6 }}>
                      {card.content3}
                    </Typography>
                  </Box>
                }
              />
            </Grid>
          ))
        )}
      </Grid>

      <Grid container spacing={2} sx={{ mt: 5 }}>
        {user.roleCheck(['DASHBOARD-ACCESS']) && (
          <Grid xs={6}>
            <Button
              size='large'
              onClick={() => {
                navigate('/dashboard');
              }}
              endIcon={<ArrowForwardRoundedIcon fontSize='small' />}
              sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                p: 2,
                borderRadius: 4,
                width: '100%',
              }}
            >
              Dashboard
            </Button>
          </Grid>
        )}

        {user.roleCheck(['REPORTS-MENU-ACCESS']) && (
          <Grid xs={6}>
            <Button
              size='large'
              onClick={() => {
                navigate('/reports');
              }}
              endIcon={<ArrowForwardRoundedIcon fontSize='small' />}
              sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                p: 2,
                borderRadius: 4,
                width: '100%',
              }}
            >
              Reports
            </Button>
          </Grid>
        )}

        <Grid xs={6}>
          <Button
            size='large'
            onClick={() => {
              navigate('/casesearch');
            }}
            endIcon={<ArrowForwardRoundedIcon fontSize='small' />}
            sx={{
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
              p: 2,
              borderRadius: 4,
              width: '100%',
            }}
          >
            Search Cases
          </Button>
        </Grid>

        <Grid xs={6}>
          <Button
            size='large'
            onClick={() => {
              navigate('/yourcasesandreports');
            }}
            endIcon={<ArrowForwardRoundedIcon fontSize='small' />}
            sx={{
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
              p: 2,
              borderRadius: 4,
              width: '100%',
            }}
          >
            Your Cases
          </Button>
        </Grid>

        {user.roleCheck(['CREATE-CASE-ACCESS']) && (
          <Grid xs={12}>
            <Button
              size='large'
              onClick={() => {
                navigate('/caseintake');
              }}
              endIcon={<AddIcon fontSize='small' />}
              sx={{
                // backgroundColor: theme.palette.background.paper,
                // color: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.text.primary,
                p: 2,
                borderRadius: 4,
                width: '100%',
              }}
            >
              New Case
            </Button>
          </Grid>
        )}
      </Grid>

      <FormControl
        sx={{
          width: '100%',
          px: 3,
          pb: 3,
          position: 'absolute',
          bottom: 0,
          left: 0,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <InputLabel sx={{ pl: 3 }}>Find a case</InputLabel>
        <Search
          endAdornment={
            <button type='submit' style={{ background: 'none', border: 'none', color: 'inherit' }}>
              <ArrowForwardRoundedIcon />
            </button>
          }
          sx={{ mt: 5, '& .MuiInputBase-root': { borderRadius: 4 } }}
          fullWidth
          size='medium'
        />
      </FormControl>
    </Box>
  );
}
