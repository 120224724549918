import { Breadcrumbs, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export function BreadcrumbReportNav({ currentPage }: { currentPage: string }) {
  return (
    <Breadcrumbs separator='/' aria-label='breadcrumb' sx={{ m: 1, mt: 0 }}>
      <RouterLink
        style={{ textDecoration: 'none' }}
        color='text.primary'
        to='/home'
      >
        Home
      </RouterLink>

      <RouterLink
        style={{ textDecoration: 'none' }}
        color='inherit'
        to='/reports'
      >
        Reports
      </RouterLink>

      <Typography color='text.primary'>
        <b>{currentPage}</b>
      </Typography>
    </Breadcrumbs>
  );
}
