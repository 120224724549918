import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-premium';
import React from 'react';
import { useNavigate } from 'react-router';
import SiloDataGrid from '../../utils/components/SiloDataGrid';
import { DecedentNameCell } from './DecedentNameCell';
import { DashboardDataGridProps } from './useDashboardDataGridAPI.hook';

export function AssignedToLiveryDataGrid({ data, isLoading }: DashboardDataGridProps) {
  const navigate = useNavigate();
  const handleCaseClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
  ) => {
    event.stopPropagation();
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const columns: GridColDef[] = [
    {
      field: 'caseid',
      headerName: 'CaseID',
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'decedentname',
      headerName: 'Decedent',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: params => <DecedentNameCell {...params} />,
    },
    {
      field: 'examphysician',
      headerName: 'Pathologist',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'investigator',
      headerName: 'MLDI',
      width: 140,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  return (
    <SiloDataGrid
      loading={isLoading}
      listresults={data}
      columnsInput={columns}
      idcolumn='caseid'
      gridheight={200}
      gridcolor='#e67e22'
      title='Assigned to Livery '
      tooltip='These are cases that have been accepted by investigations, have been assigned to Livery and are waiting to be transported from their place of death, to the building'
      casecount
      rowsperpage={50}
      filterModel={undefined}
      columnsVisibility={undefined}
    />
  );
}
