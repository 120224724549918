import React from 'react';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  FormControl,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
  IconButton,
  FormHelperText,
} from '@mui/material';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import { ErrorMessage } from 'formik';

const id = '00000000-0000-0000-0000-000000000000';

const Address = ({
  removeAddressTab,
  handleAddressSelectionChange,
  panel,
  panels,
  index,
  countyNames,
  countryNames,
  stateNames,
  addressTypeNames,
  isErrormsg,
}) => {
  const addAddressLine1Field = () => {
    if (
      addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
        'homeless' ||
      addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
        'unknown'
    ) {
      return addressTypeNames[panel?.addressTypeSeq]?.addressTypeName;
    } else {
      return panel?.addressLine1;
    }
  };

  const addCityField = () => {
    if (
      addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
        'homeless' ||
      addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
        'unknown'
    ) {
      return addressTypeNames[panel?.addressTypeSeq]?.addressTypeName;
    } else {
      return panel?.city;
    }
  };
  return (
    <fieldset style={{ marginBottom: '15px', padding: '15px 0', border: '0' }}>
      <legend style={{ marginBottom: '-20px', marginTop: '-20px' }}>
        <Typography
          variant='h3'
          gutterBottom
          sx={{
            mt: 0,
            mb: 0,
            py: 0,
            fontWeight: 'bold',
            // color: '#888888',
            fontSize: '1rem',
          }}
        >
          Address {index + 1}
        </Typography>
      </legend>
      <Box 
       sx={{
              flexGrow: 1,
              // border: '1px solid grey',
              // borderRadius: '12px',
              padding: '8px 0px 12px',
              marginTop: '16px',
            }}
            >
        <Grid Container container spacing={2} sx={{ py: 2, mt: 1 }}>
          <Grid item lg={6} xs={12} md={12} sm={12}>
            <FormGroup>
              <FormControl  variant='outlined'>
                <InputLabel htmlFor='addressTypeSeq' id='addressTypeSeq'>
                  Address Type
                </InputLabel>
                <Select
                  onChange={(e) => handleAddressSelectionChange(e, index)}
                  variant='outlined'
                  
                  label='Address Type'
                  displayEmpty
                  size='large'
                  name={`addressTypeSeq`}
                  value={panel?.addressTypeSeq || ''}
                >
                  <MenuItem key={id} value={id}>
                    --Select One--
                  </MenuItem>
                  {Object.keys(addressTypeNames).map((item, z) => {
                    return (
                      <MenuItem
                        key={addressTypeNames[item].addressTypeSeq}
                        value={addressTypeNames[item].addressTypeSeq}
                      >
                        {addressTypeNames[item].addressTypeName}
                      </MenuItem>
                    );
                  })}
                </Select>
                {isErrormsg && (
                <ErrorMessage
                  name={`address.${index}.addressTypeSeq`}
                  render={(msg) => <FormHelperText error>{msg}</FormHelperText>}
                />
                )}
              </FormControl>
            </FormGroup>
          </Grid>
          {panels.length > 0 && (
            <Grid
              item
              lg={6}
              xs={6}
              md={6}
              sm={6}
              display='flex'
              justifyContent='flex-end'
              sx={{ padding: 0 }}
            >
              <IconButton onClick={() => removeAddressTab(index)}>
                <DeleteIcon sx={{ fontSize: 30, color: 'rgb(2, 136, 209)' }} />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12} md={12} sm={12} sx={{ py: 1 }}>
            <FormGroup>
              <FormControl
                error
                required
                component='fieldset'
                variant='standard'
              >
                <TextField
                  required
                  type='text'
                  name={`addressLine1`}
                  label='Address Line 1'
                  onChange={(e) => handleAddressSelectionChange(e, index)}
                  value={addAddressLine1Field()}
                  disabled={
                    panel?.addressTypeSeq &&
                    (addressTypeNames[
                      panel?.addressTypeSeq
                    ]?.addressTypeName.toLowerCase() == 'homeless' ||
                      addressTypeNames[
                        panel?.addressTypeSeq
                      ]?.addressTypeName.toLowerCase() == 'unknown')
                  }
                  variant='outlined'
                  size='large'
                />
                {isErrormsg && (
                <ErrorMessage
                  name={`address.${index}.addressLine1`}
                  render={(msg) => <FormHelperText error>{msg}</FormHelperText>}
                />
                )}
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid item lg={6} xs={12} md={12} sm={12} sx={{ py: 1 }}>
            <FormGroup>
              <FormControl error component='fieldset' variant='standard'>
                <TextField
                  type='text'
                  label='Address Line 2'
                  name={`addressLine2`}
                  onChange={(e) => handleAddressSelectionChange(e, index)}
                  value={panel?.addressLine2 || ''}
                  variant='outlined'
                  size='large'
                  disabled={
                    panel?.addressTypeSeq &&
                    (addressTypeNames[
                      panel?.addressTypeSeq
                    ]?.addressTypeName.toLowerCase() == 'homeless' ||
                      addressTypeNames[
                        panel?.addressTypeSeq
                      ]?.addressTypeName.toLowerCase() == 'unknown')
                  }
                />
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid item lg={4} xs={12} md={12} sm={12}>
            <FormControl
              error
              component='fieldset'
              variant='standard'
              fullWidth
            >
              <TextField
                type='text'
                id='outlined-basic_tab_${activeStep + 1}'
                label='Apartment #'
                variant='outlined'
                name={`apartmentNo`}
                onChange={(e) => handleAddressSelectionChange(e, index)}
                value={panel?.apartmentNo || ''}
                size='large'
                disabled={
                  panel?.addressTypeSeq &&
                  (addressTypeNames[
                    panel?.addressTypeSeq
                  ]?.addressTypeName.toLowerCase() == 'homeless' ||
                    addressTypeNames[
                      panel?.addressTypeSeq
                    ]?.addressTypeName.toLowerCase() == 'unknown')
                }
                inputProps={{ maxLength: 10 }}
              />
            </FormControl>
          </Grid>
          <Grid item lg={4} xs={12} md={12} sm={12}>
            <FormGroup>
              <FormControl error component='fieldset' variant='standard'>
                <TextField
                  type='number'
                  id={`outlined-basic_tab_${panel.value}`}
                  label='Floor #'
                  name={`floor`}
                  onChange={(e) => handleAddressSelectionChange(e, index)}
                  value={panel?.floor || ''}
                  variant='outlined'
                  size='large'
                  disabled={
                    panel?.addressTypeSeq &&
                    (addressTypeNames[
                      panel?.addressTypeSeq
                    ]?.addressTypeName.toLowerCase() == 'homeless' ||
                      addressTypeNames[
                        panel?.addressTypeSeq
                      ]?.addressTypeName.toLowerCase() == 'unknown')
                  }
                  inputProps={{ maxLength: 10 }}
                />
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid item lg={4} xs={12} md={12} sm={12}>
            <FormGroup>
              <FormControl
                required
                component='fieldset'
                variant='standard'
              >
                <TextField
                  required
                  type='text'
                  name={`city`}
                  label='City/Town'
                  onChange={(e) => handleAddressSelectionChange(e, index)}
                  value={addCityField()}
                  disabled={
                    panel?.addressTypeSeq &&
                    (addressTypeNames[
                      panel?.addressTypeSeq
                    ]?.addressTypeName.toLowerCase() == 'homeless' ||
                      addressTypeNames[
                        panel?.addressTypeSeq
                      ]?.addressTypeName.toLowerCase() == 'unknown')
                  }
                  variant='outlined'
                  size='large'
                />
                {isErrormsg && (
                <ErrorMessage
                  name={`address.${index}.city`}
                  render={(msg) => <FormHelperText error>{msg}</FormHelperText>}
                />
                )}
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid item lg={3} xs={12} md={12} sm={12}>
            <FormGroup>
              <FormControl>
                <InputLabel htmlFor='stateSeq' id='stateSeq' variant='outlined'>
                  State
                </InputLabel>
                <Select
                  onChange={(e) => handleAddressSelectionChange(e, index)}
                  variant='outlined'
                  label='State'
                  size='large'
                  name={`stateSeq`}
                  value={
                    panel?.stateSeq !== null
                      ? panel?.stateSeq
                      : 'cfcd3025-4434-4c07-8e6a-951b4d0c7490'
                  }
                  disabled={addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
                    'homeless' ||
                  addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
                    'unknown'}
                >
                  <MenuItem key='select-option' value=''>
                    --Select One--
                  </MenuItem>
                  {stateNames.map((item, z) => {
                    return (
                      <MenuItem key={item.stateSeq} value={item.stateSeq}>
                        {item.stateName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid item lg={3} xs={12} md={12} sm={12}>
            <FormGroup>
              <FormControl error component='fieldset' variant='standard'>
                <TextField
                  type='text'
                  label='Zip Code'
                  name={`zip`}
                  onChange={(e) => handleAddressSelectionChange(e, index)}
                  value={panel?.zip || ''}
                  id={`outlined-basic_${panel.value}`}
                  // value={`${panel[0]?.zip}`}
                  variant='outlined'
                  size='large'
                  disabled={
                    panel?.addressTypeSeq &&
                    (addressTypeNames[
                      panel?.addressTypeSeq
                    ]?.addressTypeName.toLowerCase() == 'homeless' ||
                      addressTypeNames[
                        panel?.addressTypeSeq
                      ]?.addressTypeName.toLowerCase() == 'unknown')
                  }
                  inputProps={{ maxLength: 10 }}
                />
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid item lg={3} xs={12} md={12} sm={12} sx={{ py: 1 }}>
            <FormControl fullWidth>
              <InputLabel htmlFor='countySeq' id='countySeq'>
                Jurisdiction
              </InputLabel>
              <Select
                onChange={(e) => handleAddressSelectionChange(e, index)}
                variant='outlined'
                label='Jurisdiction'
                size='large'
                name={`countySeq`}
                value={panel?.countySeq || ''}
                disabled={
                  panel?.addressTypeSeq &&
                  (addressTypeNames[
                    panel?.addressTypeSeq
                  ]?.addressTypeName.toLowerCase() == 'homeless' ||
                    addressTypeNames[
                      panel?.addressTypeSeq
                    ]?.addressTypeName.toLowerCase() == 'unknown')
                }
              >
                <MenuItem key='select-option' value=''>
                  --Select One--
                </MenuItem>
                {countyNames
                  ? Object.keys(countyNames).map((item, z) => {
                      return (
                        <MenuItem
                          key={countyNames[item].countySeq}
                          value={countyNames[item].countySeq}
                          sx={{
                            marginLeft:
                              countyNames[item].countyName == 'New Jersey' ||
                              countyNames[item].countyName == 'NJ State Case' ||
                              countyNames[item].countyName == 'MAiD Case'
                                ? ''
                                : '15px',
                          }}
                        >
                          {countyNames[item].countyName}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} xs={12} md={12} sm={12} sx={{ py: 1 }}>
            <FormControl variant='outlined' fullWidth>
              <InputLabel htmlFor='countrySeq' id='countrySeq'>
                Country
              </InputLabel>
              <Select
                onChange={(e) => handleAddressSelectionChange(e, index)}
                variant='outlined'
                label='Country'
                size='large'
                name={`countrySeq`}
                value={
                  panel?.countrySeq || '39d0e397-af0f-4572-b605-7b2312fff516'
                }
                disabled={addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
                  'homeless' ||
                addressTypeNames[panel?.addressTypeSeq]?.addressTypeName.toLowerCase() ==
                  'unknown'}              >
                <MenuItem key='select-option' value=''>
                  --Select One--
                </MenuItem>
                {countryNames.map((item, z) => {
                  return (
                    <MenuItem key={item.countrySeq} value={item.countrySeq}>
                      {item.countryName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {/* <Grid item lg={2} xs={12} md={12} sm={12} my={2}>
        <Button
          variant='contained'
          endIcon={<PublishedWithChangesIcon />}
          // sx={{ backgroundColor: 'rgb(2, 136, 209)' }}
        >
          Verify Address
        </Button>
      </Grid> */}
    </fieldset>
  );
};

export default Address;
