import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  Grid,
  Box,
  Button,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

const PermissionBox = ({
  open,
  handleClose,
  treeItems,
  sendCheckedPermissions,
}) => {
  const [checked, setChecked] = React.useState({
    Creator: !!(treeItems?.color === 'Green' || treeItems?.color === '#3B6A0C'),
    Editor: !!(
      treeItems?.color === 'Green' ||
      treeItems?.color === 'Violet' ||
      treeItems?.color === '#C466C4' ||
      treeItems?.color === '#3B6A0C'
    ),
    Viewer: !!(
      treeItems?.color === 'Green' ||
      treeItems?.color === 'Violet' ||
      treeItems?.color === 'Blue' ||
      treeItems?.color === '#C466C4' ||
      treeItems?.color === '#004A9D' ||
      treeItems?.color === '#3B6A0C'
    ),
  });

  const close = () => {
    handleClose();
  };
  const handleChange = (event) => {
    if (event.target.name === 'Creator') {
      setChecked({
        Creator: !checked?.Creator,
        Editor: !checked?.Creator,
        Viewer: !checked?.Creator,
      });
    } else if (event.target.name === 'Editor') {
      setChecked({
        Creator: false,
        Editor: !checked?.Editor,
        Viewer: !checked?.Editor,
      });
    } else if (event.target.name === 'Viewer') {
      setChecked({
        Creator: false,
        Editor: false,
        Viewer: !checked?.Viewer,
      });
    }
  };

  const handleSubmit = () => {
    sendCheckedPermissions(checked, treeItems?.treeName);
    handleClose();
  };
  return (
    <Dialog onClose={close} open={open}>
      <DialogTitle sx={{ size: '12px', width: '280px' }}>
        <Box>{treeItems?.treeName}</Box>
      </DialogTitle>

      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: '#B0DEF3',
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <Grid item style={{ paddingLeft: '0px' }}>
          <p style={{ size: '12px', fontWeight: '600' }}>Permissions</p>
        </Grid>
        <Grid item>
          <p style={{ size: '12px', fontWeight: '600' }}>Allow</p>
        </Grid>
      </Grid>

      <Box
        container
        spacing={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '6px 30px',
        }}
      >
        <Box item lg={6}>
          Creator
        </Box>
        <Box item lg={6}>
          <Checkbox
            checked={checked?.Creator}
            onChange={handleChange}
            name='Creator'
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{ position: 'relative', bottom: '8px' }}
          />
        </Box>
      </Box>
      <Box
        container
        spacing={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '6px 30px',
        }}
      >
        <Box item lg={6}>
          Editor
        </Box>
        <Box item lg={6}>
          <Checkbox
            checked={checked?.Editor}
            onChange={handleChange}
            name='Editor'
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{ position: 'relative', bottom: '8px' }}
          />
        </Box>
      </Box>
      <Box
        container
        spacing={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '6px 30px',
        }}
      >
        <Box item lg={6}>
          Viewer
        </Box>
        <Box item lg={6}>
          <Checkbox
            checked={checked?.Viewer}
            onChange={handleChange}
            name='Viewer'
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{ position: 'relative', bottom: '8px' }}
          />
        </Box>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <Grid item>
          <Button variant='contained' color='info' onClick={handleSubmit}>
            Ok
          </Button>
        </Grid>
        <Grid item onClick={close}>
          <Button variant='contained' color='info'>
            Cancel
          </Button>
        </Grid>
      </Grid>
      <List sx={{ pt: 0 }}>
        <ListItem disableGutters></ListItem>
      </List>
    </Dialog>
  );
};

export default PermissionBox;
