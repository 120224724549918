import { Button } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-premium';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import SiloDataGrid from '../../utils/components/SiloDataGrid';
import { useDashboard } from '../../views/Dashboard/DashboardContext';
import { DecedentNameCell } from './DecedentNameCell';
import { DashboardDataGridProps } from './useDashboardDataGridAPI.hook';

export function ReleaseDataGrid({ data, isLoading }: DashboardDataGridProps) {
  const navigate = useNavigate();

  const { preferences, reloadSiloData } = useDashboard();
  const [reloading, setReloading] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        setReloading(true);
        await reloadSiloData('RELEASE');
      } finally {
        setReloading(false);
      }
    }, preferences.refreshSeconds * 1000);

    return () => clearInterval(intervalId);
  }, [reloadSiloData]);

  const handleCaseClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
  ) => {
    event.stopPropagation();
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const columns: GridColDef[] = [
    {
      field: 'caseID',
      headerName: 'CaseID',
      width: 90,
      editable: false,
      align: 'left',
      headerAlign: 'left',

      renderCell: params => {
        return (
          <Button
            color='primary'
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              handleCaseClick(event, params);
            }}
          >
            <b> {params.formattedValue} </b>
          </Button>
        );
      },
    },
    {
      field: 'decedentName',
      headerName: 'Decedent',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: params => <DecedentNameCell {...params} />,
    },
    {
      field: 'meNotified',
      headerName: 'Reported',
      width: 170,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      type: 'dateTime',
      valueGetter: value => {
        return value != null ? new Date(value) : null;
      },
      valueFormatter: value => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
      },
    },
    {
      field: 'dataSheetUploaded',
      headerName: 'D-Sheet?',
      width: 65,
      editable: false,
      type: 'boolean',
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'investigator',
      headerName: 'MLDI',
      width: 140,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    // {
    //   field: 'releasePhysician',
    //   headerName: 'PMD',
    //   width: 140,
    //   editable: false,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
    {
      field: 'approvingPathologist',
      headerName: 'ME/POC',
      width: 140,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  return (
    <SiloDataGrid
      loading={isLoading}
      listresults={data}
      columnsInput={columns}
      idcolumn='caseID'
      gridheight={200}
      gridcolor='#e74c3c'
      title='Releases awaiting ME Approval'
      tooltip='These are Release Cases that are awaiting ME approval, Physician refers to the Decedents doctor  who will sign the Death Certificate, ME refers to the Pathologist who will approve the Release case'
      casecount
      rowsperpage={50}
      filterModel={undefined}
      columnsVisibility={undefined}
      reloading={reloading}
      refreshSeconds={preferences.refreshSeconds}
    />
  );
}
