import { Autocomplete, AutocompleteProps, CircularProgress, TextField } from '@mui/material';
import React from 'react';
import { CASE_REPORTER_TYPES, useOptions } from '../utils/api/useOptions.hook';
import { Option } from '../views/Case/CaseViewModules/MortuaryModules/XrayRequestForm/xrayrequestform.constants';

interface CaseReporterTypesAutocompleteProps
  extends Omit<AutocompleteProps<Option, boolean, boolean, boolean>, 'onChange'> {
  onChange?: (event: React.ChangeEvent<{}>, value: Option | null) => void;
  label?: string;
}

export const CaseReporterTypesAutocomplete: React.FC<
  CaseReporterTypesAutocompleteProps
> = props => {
  const { options, loading } = useOptions('case reporter type options');

  return (
    <Autocomplete<Option, boolean, boolean, boolean>
      {...props}
      freeSolo={false}
      suppressContentEditableWarning={true}
      suppressHydrationWarning={true}
      options={options.filter(o =>
        CASE_REPORTER_TYPES.some(
          t => t.caseReporterTypeSeq.toLowerCase() === o.optionSeq.toLowerCase()
        )
      )}
      loading={loading}
      isOptionEqualToValue={(o, v) => o.optionSeq.toLowerCase() === v.optionSeq.toLowerCase()}
      getOptionLabel={o => (o as Option).optionName} // Type assertion here
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          label={props.label || 'Case Reporter Type'}
        />
      )}
      onChange={(event, value) => {
        if (props.onChange) {
          props.onChange(event, value as Option | null);
        }
      }}
    />
  );
};
