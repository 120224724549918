import React from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const DeleteApiNotification = ({
  openActiveInActiveDialogBox,
  setOpenActiveInActiveDialogBox,
  referenceMasterTableName,
  changeActiveInActiveClick,
  activeInactiveClickData,
}) => {
  return (
    <div className='modal'>
      <Dialog
        open={openActiveInActiveDialogBox}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenActiveInActiveDialogBox(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            Are you sure you want to{' '}
            {activeInactiveClickData?.status === 'Active'
              ? 'InActive '
              : 'Active'}{' '}
            this{' '}
            {referenceMasterTableName
              .split('')
              .map((alpha) => {
                if (alpha <= 'Z') return ' ' + alpha;
                return alpha;
              })
              .join('')
              .trim()}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenActiveInActiveDialogBox(false)}>
            No
          </Button>
          <Button onClick={changeActiveInActiveClick}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteApiNotification;
