import PrintIcon from '@mui/icons-material/Print';
import { Button } from '@mui/material';
import { Document, Font, Page, StyleSheet, Text, View, usePDF } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import InterExtraBold from '../../assets/fonts/inter/static/Inter-ExtraBold.ttf';
import { CaseSummary } from '../../types/CaseSummary.type';

Font.register({
  family: 'Inter',
  fonts: [{ src: InterExtraBold, fontWeight: 'heavy' }],
});

const styles = StyleSheet.create({
  container: {
    width: '2.35in', // Reduce width slightly
    height: '0.8in', // Reduce height slightly
    borderWidth: 1,
    borderColor: '#000000',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '0.21in',
    marginRight: '0.21in', // space between labels horizontally
    marginBottom: '0.205in', // space between labels vertically
  },
  section: {
    borderBottomWidth: 1,
    borderBottomColor: '#000000',
    marginBottom: 2,
  },
  sectionContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 2,
  },
  bold: {
    fontFamily: 'Inter',
    fontWeight: 'bold',
  },
  smallText: {
    fontSize: 10,
  },
  verySmallText: {
    fontSize: 8,
  },
  truncatedText: {
    flex: 1,
    overflow: 'hidden',
    paddingRight: 5, // Space for ellipsis
  },
  rightContainer: {
    flex: 1,
    alignItems: 'flex-end',
    textAlign: 'left',
  },
  page: {
    flexDirection: 'column',
    paddingTop: 0.6 * 72, // Top margin
    paddingBottom: 0.5 * 72,
    paddingLeft: 0.18 * 72,
    paddingRight: 0.18 * 72,
    width: '8.5in',
    height: '11in',
    display: 'flex',
    flexWrap: 'wrap',
  },
});

const tableStyles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableContainer: {
    width: '100%', // Take up the full width of the page
    height: '100%', // Take up the full height of the page
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
});

const truncateText = (text: string, maxLength: number) => {
  return text.length > maxLength ? `${text.slice(0, maxLength - 3)}...` : text;
};

const getFormattedInitials = (names: string[]) => {
  if (!names || names.length === 0) return '';
  const initials = names.map(name => name).join(', ');
  return `MT: ${initials}`;
};

const TableCell = ({
  caseSummary,
  selectedLabel,
  today,
}: {
  caseSummary: CaseSummary;
  selectedLabel: string;
  today: any;
}) => {
  const { decedent_information, case_number, case_information, morgue_techs } = caseSummary;
  const { decedent_name } = decedent_information;
  const { physician } = case_information;

  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <View style={styles.sectionContent}>
          <View style={styles.truncatedText}>
            <Text style={styles.smallText}>Name: {truncateText(decedent_name ?? '-', 20)}</Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.sectionContent}>
          <Text style={[styles.smallText, styles.bold]}>CASE #: {case_number ?? '-'}</Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.sectionContent}>
          <Text style={styles.smallText}>Doctor: </Text>
          <View style={styles.truncatedText}>
            <Text style={styles.verySmallText}>{truncateText(physician ?? '-', 16)}</Text>
          </View>
          <View style={styles.rightContainer}>
            <Text style={styles.verySmallText}>Date: {today}</Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.sectionContent}>
          <View>
            <Text style={styles.verySmallText}>{getFormattedInitials(morgue_techs)}</Text>
          </View>
          <View style={styles.rightContainer}>
            <Text style={styles.verySmallText}>{selectedLabel ?? '-'}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const generateTableContent = (
  caseSummary: CaseSummary,
  selectedLabel: string,
  examStartDate: dayjs.Dayjs
) => {
  // const today = new Date();
  // const fullYear = today.getFullYear();
  // const yearWithoutCentury = fullYear % 100;
  // const day = today.getDate();
  // const month = today.getMonth() + 1;
  // const date = `${month}/${day}/${yearWithoutCentury}`;
  return Array.from({ length: 10 }).map((_, index) => (
    <View style={tableStyles.row} key={index}>
      <TableCell
        caseSummary={caseSummary}
        selectedLabel={selectedLabel}
        today={examStartDate.isValid() ? examStartDate.format('MM/DD/YY') : ''}
      />
      <TableCell
        caseSummary={caseSummary}
        selectedLabel={selectedLabel}
        today={examStartDate.isValid() ? examStartDate.format('MM/DD/YY') : ''}
      />
      <TableCell
        caseSummary={caseSummary}
        selectedLabel={selectedLabel}
        today={examStartDate.isValid() ? examStartDate.format('MM/DD/YY') : ''}
      />
    </View>
  ));
};

export const LabelSetPDF = ({
  caseSummary,
  selectedLabel,
  examStartDate,
}: {
  caseSummary: CaseSummary;
  selectedLabel: string;
  examStartDate: dayjs.Dayjs;
}) => {
  return (
    <Document>
      <Page size='LETTER' style={styles.page}>
        <View style={tableStyles.tableContainer}>
          {generateTableContent(caseSummary, selectedLabel, examStartDate)}
        </View>
      </Page>
    </Document>
  );
};

export const PrintLabelSetButton = ({
  caseSummary,
  selectedLabel,
}: {
  caseSummary: CaseSummary;
  selectedLabel: string;
}) => {
  const formik = useFormikContext<any>();
  const examStartDate = dayjs(formik.values?.caseMortuary?.examStartDateTime);

  const [instance, updateInstance] = usePDF({
    document: (
      <LabelSetPDF
        caseSummary={caseSummary}
        selectedLabel={selectedLabel}
        examStartDate={examStartDate}
      />
    ),
  });

  useEffect(() => {
    // console.log('morgue_techs', caseSummary);
    // Call updateInstance with the new PDF instance
    updateInstance(
      <LabelSetPDF
        caseSummary={caseSummary}
        selectedLabel={selectedLabel}
        examStartDate={examStartDate}
      />
    );
  }, [selectedLabel, caseSummary, caseSummary.morgue_techs]);

  const handlePrint = () => {
    const fileName = `${caseSummary.case_number}_${selectedLabel}_label.pdf`;

    if (instance.blob) {
      const blobUrl = URL.createObjectURL(instance.blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      let iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);

      iframe.onload = () => {
        try {
          if (iframe.contentWindow) {
            iframe.contentWindow.print();
          }
        } catch (e) {
          console.error("Couldn't print the PDF", e);
        }
      };

      iframe.onclose = () => {
        document.body.removeChild(iframe);
        URL.revokeObjectURL(blobUrl);
      };
    }
  };

  // const isButtonDisabled = !selectedLabel || selectedLabel == '';

  return (
    <Button
      variant='contained'
      color='info'
      style={{ width: '100%', height: '100%' }}
      onClick={() => handlePrint()}
      startIcon={<PrintIcon />}
      // disabled={isButtonDisabled}
    >
      PRINT LABELS
    </Button>
  );
};
