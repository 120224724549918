import { Autocomplete, TextField } from '@mui/material';
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import React, { useRef } from 'react';

function AutocompleteEditCellWithDescription(props) {
  const { id, value, field, hasFocus, colDef } = props;
  const apiRef = useGridApiContext();
  const ref = useRef();

  const handleChange = (event, newValue) => {
    if (!apiRef.current) return;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  useEnhancedEffect(() => {
    if (hasFocus && ref.current) {
      const input = ref.current.querySelector(`input[value="${value}"]`);
      input?.focus();
    }
  }, [hasFocus, value]);

  const getOptionLabel = option => option.optionName;

  const renderOption = (props, option) => {
    return (
      <li {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '10px', minWidth: '50px' }}>{option.optionName}</div>
          <div>{option.optionShortDesc}</div>
        </div>
      </li>
    );
  };

  return (
    <Autocomplete
      options={colDef.valueOptions}
      value={value}
      getOptionLabel={getOptionLabel}
      fullWidth
      onChange={handleChange}
      renderInput={params => (
        <TextField {...params} variant='standard' placeholder={colDef.headerName} />
      )}
      renderOption={renderOption}
    />
  );
}

export const renderAutocompleteEditCellWithDescription = params => {
  const { colDef } = params;
  const options = colDef.valueOptions ?? [];

  return <AutocompleteEditCellWithDescription {...params} options={options} />;
};
