import { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../utils/auth/AuthService';
import { TimeoutWarningModal } from './TimeoutWarningModal';
import { addEventListeners, removeEventListeners } from './eventListenerUtil';
import { useUnsavedChanges } from '../../Context/UnsavedChangesContext';

export const TimeoutLogic = () => {
  const auth = useAuth();
  const { hasUnsavedChanges } = useUnsavedChanges();

  const isDashboard = location.pathname.includes('dashboard');
  const idleTimeout = isDashboard ? 1000 * 60 * 60 : 1000 * 60 * 20;
  let idleLogout = 1000 * 60 * 5; // 5 minutes
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const [remainingTime, setRemainingTime] = useState(idleLogout);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const createTimeout1 = () =>
      setTimeout(() => {
        setWarningModalOpen(true);
      }, idleTimeout);

    const createTimeout2 = () =>
      setTimeout(() => {
        auth.logout();
        auth.authDispatch({
          type: 'SET_LOGOUT_MESSAGE_STATE',
          payload: {
            dismissAfterSeconds: -1,
            displayAlert: true,
            message: 'You have been automatically logged out for inactivity',
            shouldDismiss: false,
          },
        });
        window.location.assign(process.env.PUBLIC_URL + '/login');
      }, idleLogout);

    const listener = () => {
      if (!isWarningModalOpen) {
        clearTimeout(timeout);
        timeout = createTimeout1();
      }
    };

    const startCountdown = () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      intervalRef.current = setInterval(() => {
        setRemainingTime(prev => {
          if (prev <= 1000) {
            clearInterval(intervalRef.current!);
            return 0;
          }
          return prev - 1000;
        });
      }, 1000);
    };

    let timeout = isWarningModalOpen ? createTimeout2() : createTimeout1();
    addEventListeners(listener);

    if (isWarningModalOpen) {
      startCountdown();
    }

    // Cleanup
    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isWarningModalOpen]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges && !isWarningModalOpen) {
        event.preventDefault();
        event.returnValue = ''; 
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
  }, [hasUnsavedChanges, isWarningModalOpen]);

  return (
    <div>
      {isWarningModalOpen && (
        <TimeoutWarningModal
          isOpen={isWarningModalOpen}
          onRequestClose={() => setWarningModalOpen(false)}
          remainingTime={remainingTime}
        />
      )}
    </div>
  );
};
