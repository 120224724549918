import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Tooltip,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { createSvgIcon } from '@mui/material/utils';
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid-premium';
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import CondensedDataGrid from '../../components/CondensedDataGrid';
import { useAuth } from '../../utils/auth/AuthService';
import MLDropzone from '../../utils/components/MLDropzone';
import { FileAPI } from '../../utils/functions/FileAPI';
import { PrintFileButton, triggerPrintDialog } from '../../utils/functions/triggerPrintDialog';
import { format } from 'date-fns';
import { LabTestingDocumentTypes } from '../../utils/constants/constants';

export default function CaseDocuments({ caseinfo, editing, isLab = false }) {
  const [documents, setDocuments] = useState([]);
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const { REACT_APP_API_URL } = process.env;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const ExportIcon = createSvgIcon(
    <path d='M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z' />,
    'SaveAlt'
  );
  const [showSelectionAlert, setShowSelectionAlert] = useState(false);
  const [clickTimeout, setClickTimeout] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only('sm'));
  const isMobileScreen = useMediaQuery(theme.breakpoints.only('xs'));
  const [itemToEdit, setItemToEdit] = useState(null);

  const buttonBaseProps = {
    color: 'primary',
    size: 'small',
    startIcon: <ExportIcon />,
  };

  const CustomExportButton = props => {
    const apiRef = useGridApiContext();
    const { hideMenu } = props;

    return downloading ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TailSpin
          color='#00BFFF'
          height={20}
          width={20}
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
        />
        <i style={{ color: '#00BFFF', paddingLeft: 10 }}>
          I'm preparing the download for you, this may take a bit of time....
        </i>
      </div>
    ) : (
      <Button
        {...buttonBaseProps}
        onClick={() => {
          const selectedRows = apiRef.current.getSelectedRows();
          downloadDocuments(selectedRows);
          hideMenu?.();
        }}
      >
        Download Selection
      </Button>
    );
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <CustomExportButton />
        <Button color='primary' startIcon={<AddIcon />} onClick={() => setOpen(true)}>
          Upload New Document
        </Button>
      </GridToolbarContainer>
    );
  }

  const downloadDocumentBlobOnly = async selectedRows => {
    const fileSeqs = [];
    if (!selectedRows || selectedRows.size === 0) {
      setShowSelectionAlert(true);
      return null;
    }
    for (var row of selectedRows) {
      fileSeqs.push(Array.isArray(selectedRows) ? row['key'] : row[0]);
    }

    let formData = new FormData();
    formData.append('caseId', caseinfo?.caseNumber);
    formData.append('fileSeqsString', JSON.stringify(fileSeqs));

    setDownloading(true);

    try {
      const response = await fetch(REACT_APP_API_URL + 'downloadfiles', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      });

      if (response.status === 401) {
        throw new Error('You are unauthorized to use this tool');
      } else if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const blob = await response.blob();
      // Check if the blob is already a PDF
      if (blob.type === 'application/pdf') {
        return blob;
      } else {
        // If it's not a PDF, create a new blob with PDF MIME type
        return new Blob([blob], { type: 'application/pdf' });
      }
    } catch (error) {
      alert(error.message);
      return null;
    } finally {
      setDownloading(false);
    }
  };

  const downloadDocuments = async selectedRows => {
    const fileSeqs = [];
    if (!selectedRows || selectedRows.size === 0) {
      setShowSelectionAlert(true);
      return;
    }
    for (var row of selectedRows) {
      fileSeqs.push(Array.isArray(selectedRows) ? row['key'] : row[0]);
    }

    let formData = new FormData();
    formData.append('caseId', caseinfo?.caseNumber);

    formData.append('fileSeqsString', JSON.stringify(fileSeqs));

    var zippedOutput = null;
    var response = null;

    setDownloading(true);

    await fetch(REACT_APP_API_URL + 'downloadfiles', {
      method: 'POST',
      headers: {
        resposeType: 'blob',
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        response = res;

        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          return res.text();
        }

        return res.blob();
      })
      .then(data => {
        if (response.status >= 400) {
          throw new Error(data);
        } else {
          if (selectedRows.length === 1 || selectedRows.size === 1) {
            // Single file selected, download the file directly
            const disposition = response.headers.get('content-disposition');
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            const filename =
              matches !== null && matches[1] ? matches[1].replace(/['"]/g, '') : 'file';

            saveAs(data, filename);
          } else {
            // Multiple files selected, save as a zip file
            saveAs(data, 'CaseDocuments_' + caseinfo?.caseNumber + '.zip');
          }
        }
      })
      .catch(e => {
        alert(e);
      });

    setDownloading(false);
  };

  const fetchDocuments = async () => {
    let formData = new FormData();
    formData.append('CASEID', caseinfo?.caseNumber);

    await fetch(REACT_APP_API_URL + 'getcmscasedocuments', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setDocuments(data);
        setLoadingDocuments(false);
      })
      .catch(e => {
        alert(e);
      });
  };

  const ConfirmationModal = () => {
    return (
      <Dialog
        open={showConfirmation}
        onClose={handleCancelDelete}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>{'Confirm Void File'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to void this file?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDelete} autoFocus>
            Yes
          </Button>
          <Button autoFocus onClick={handleCancelDelete}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const onEditClick = (e, row) => {
    e.stopPropagation();
    setItemToEdit(row);
    setShowEditDialog(true);
  };

  const EditDialog = () => {
    const [allDocumentType, setAllDocumentType] = useState([]);
    const [documentTypeOption, setDocumentTypeOption] = useState(null);
    const [notes, setNotes] = useState('');
    const [editAccess, setEditAccess] = useState(false);

    const handleDocumentTypeChange = (event, data) => {
      setDocumentTypeOption(data);
    };

    const handleNoteChange = event => {
      setNotes(event.target.value);
    };

    const handleEditSubmit = async () => {
      let formData = new FormData();
      formData.append('photoSeq', itemToEdit?.photoSeq);
      formData.append('photoType.PhotoTypeSeq', documentTypeOption?.photoTypeSeq);
      formData.append('photoType.PhotoTypeName', documentTypeOption?.photoTypeName);
      formData.append('notes', notes);

      fetch(REACT_APP_API_URL + 'updateDocument', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You are unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          fetchDocuments();
        })
        .catch(e => {
          alert(e);
        })
        .finally(() => {
          setShowEditDialog(false);
        });
    };

    const getDocumenttypes = async () => {
      await fetch(REACT_APP_API_URL + 'getdocumenttypes', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          setAllDocumentType(data);
        })
        .catch(e => {});
    };

    useEffect(() => {
      if (itemToEdit) {
        setDocumentTypeOption(itemToEdit?.photoType || null);
        setNotes(itemToEdit?.notes || '');
        {
          /* System-Admin and CASE-SUPER-ADMIN role and user uploaded the file got access to edit. */
        }
        const editAccess =
          String(itemToEdit?.uploadedBy?.optionSeq)?.toLowerCase() ===
            String(user?.userSeq)?.toLowerCase() || user.roleCheck(['451', 'f45']);
        setEditAccess(editAccess);
      }
    }, [itemToEdit]);

    useEffect(() => {
      getDocumenttypes();
    }, []);

    return (
      <Dialog
        open={showEditDialog}
        onClose={() => setShowEditDialog(false)}
        PaperProps={{
          style: {
            minWidth: isMobileScreen ? '90%' : isSmallScreen ? '80%' : '50%',
            margin: '0 auto',
          },
        }}
      >
        <DialogTitle>
          <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
            <strong>Edit Document</strong>
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={4}>
              <Autocomplete
                id='documentType'
                size='small'
                disabled={!editAccess}
                options={allDocumentType}
                value={documentTypeOption ?? null}
                getOptionLabel={option => option.photoTypeName}
                onChange={(event, option) => handleDocumentTypeChange(event, option)}
                fullWidth
                renderOption={(props, option, { selected }) => (
                  <li {...props}>{option.photoTypeName}</li>
                )}
                renderInput={params => (
                  <TextField {...params} label='Document Type' placeholder='Document Type' />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label='Note'
                name='note'
                type='text'
                disabled={!editAccess}
                multiline
                value={notes}
                onChange={handleNoteChange}
                fullWidth
                InputProps={{
                  inputProps: {
                    minRows: 1,
                  },
                }}
              />
            </Grid>
          </Grid>
          <DialogActions style={{ justifyContent: 'right', marginTop: '1rem' }}>
            {editAccess ? (
              <Button type='submit' variant='contained' onClick={handleEditSubmit}>
                Submit
              </Button>
            ) : (
              <Typography variant='body1' color='grey' align='left'>
                You do not have permission to edit this document.
              </Typography>
            )}
            <Button color='error' onClick={() => setShowEditDialog(false)}>
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  };

  const handleConfirmDelete = () => {
    setShowConfirmation(false);
    let formData = new FormData();
    formData.append('PHOTOSEQ', itemToDelete.photoSeq);
    formData.append('CASENO', caseinfo?.caseNumber);

    fetch(REACT_APP_API_URL + 'filedelete', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        fetchDocuments();
      })
      .catch(e => {
        alert(e);
      });
  };

  const handleRowClick = (params, event) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
      handleRowDoubleClick(params);
    } else {
      const timer = setTimeout(() => {
        clearTimeout(timer);
        setClickTimeout(null);
      }, 250); // 250ms to differentiate between single and double click
      setClickTimeout(timer);
    }
  };

  const handleRowDoubleClick = selectedRow => {
    // Extract rows that are currently selected
    if (selectedRow) {
      downloadDocuments([{ key: selectedRow.id, value: selectedRow.row }]);
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const handleSuccessfulUpload = () => {
    fetchDocuments();
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const filteredDocuments = isLab
    ? documents?.filter(doc =>
        LabTestingDocumentTypes?.includes(doc?.photoType?.photoTypeSeq?.toUpperCase())
      )
    : documents;

  const FileNameCell = params => {
    const { originalFileName, photoLabel, photoSeq, photoMimeType } = params.row;
    const fileExtension = photoMimeType === 'application/msword' ? 'docx' : 'pdf';
    const fileName = originalFileName || `${caseinfo?.caseNumber}_${photoLabel}.${fileExtension}`;

    const handleClick = async event => {
      event.stopPropagation(); // Stop the click from selecting the row
      try {
        const { blob, filename } = await FileAPI.downloadFileBlob({
          caseid: caseinfo?.caseNumber,
          fileSeq: photoSeq,
          accessToken: user?.accessToken,
        });
        // const blob = await downloadDocumentBlobOnly([{ key: photoSeq, value: params.row }]);
        if (blob instanceof Blob) {
          if (blob.type === 'application/pdf') {
            triggerPrintDialog({ blob });
          } else {
            saveAs(blob, filename);
          }
        } else {
          console.error('Expected a Blob, but received:', blob);
        }
      } catch (error) {
        console.error('Error downloading or displaying document:', error);
      }
    };

    return (
      <PrintFileButton
        filename={fileName}
        fileSeq={photoSeq}
        caseid={caseinfo?.caseNumber}
        size='small'
        color='primary'
        buttonSx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'normal',
          display: 'block',
          textAlign: 'left',
          wordWrap: 'break-word',
        }}
      />
      // <Button onClick={handleClick} style={{ justifyContent: 'left' }} size='small' color='primary'>
      //   {fileName}
      // </Button>
    );
  };

  const documentsColumns = [
    {
      field: 'photoSeq',
      headerName: 'PhotoSeq',
      minWidth: 150,
      hide: true,
      flex: 1,
      editable: false,
    },
    {
      field: 'originalFileName',
      headerName: 'File Name',
      minWidth: 300,
      flex: 1,
      editable: false,
      renderCell: params => <FileNameCell {...params} />,
    },
    {
      field: 'photoLabel',
      headerName: 'Document Type',
      minWidth: 180,
      flex: 1,
      editable: false,
    },

    {
      field: 'uploadedBy',
      headerName: 'Uploaded By',
      minWidth: 150,
      flex: 1,
      editable: false,
      valueFormatter: value => {
        return value ? value.optionName : '';
      },
    },
    {
      field: 'uploadedOn',
      headerName: 'Uploaded On',
      minWidth: 150,
      flex: 1,
      editable: false,
      valueFormatter: value => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 100,
      renderCell: params => {
        const [anchorEl, setAnchorEl] = useState(null);

        const onDeleteClick = (e, row) => {
          e.stopPropagation();
          setShowConfirmation(true);
          setItemToDelete(row);
        };

        const onInfoClick = e => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        };

        const onInfoClose = () => {
          setTimeout(() => setAnchorEl(null), 100);
        };

        const showInfo = Boolean(anchorEl);

        return (
          <div>
            <IconButton
              onClick={e => onEditClick(e, params.row)}
              size='small'
              color='primary'
              style={{
                justifyContent: 'left',
                marginRight: '1rem',
              }}
            >
              <EditIcon />
            </IconButton>
            {/* System-Admin and CASE-SUPER-ADMIN role and user uploaded the file got access to void. */}
            <IconButton
              onClick={e => onDeleteClick(e, params.row)}
              style={{
                justifyContent: 'left',
                marginRight: '1rem',
                visibility:
                  String(params.row?.uploadedBy?.optionSeq)?.toLowerCase() ===
                    String(user?.userSeq)?.toLowerCase() || user.roleCheck(['451', 'f45'])
                    ? 'visible'
                    : 'hidden',
              }}
              size='small'
              color='error'
            >
              <DeleteIcon />
            </IconButton>

            <Tooltip title={params.row?.notes ?? ''} arrow>
              <IconButton
                onClick={onInfoClick}
                size='small'
                style={{
                  justifyContent: 'left',
                  marginRight: '1rem',
                  visibility: params.row?.notes !== '' ? 'visible' : 'hidden',
                }}
                aria-describedby={showInfo ? 'simple-popover' : undefined}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
            <Popover
              id={showInfo ? 'simple-popover' : undefined}
              open={showInfo}
              anchorEl={anchorEl}
              onClose={onInfoClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              style={{ marginRight: '2rem' }}
              disableAutoFocus
              disableEnforceFocus
            >
              <div style={{ padding: '10px' }}>{params.row?.notes}</div>
            </Popover>
          </div>
        );
      },
    },
  ];

  return (
    <form>
      <Stack spacing={2}>
        {showSelectionAlert && (
          <Alert
            severity='warning'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => setShowSelectionAlert(false)}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
          >
            Please select at least one document to download.
          </Alert>
        )}

        <CondensedDataGrid
          columnsVisibility={{
            photoSeq: false,
          }}
          loading={loadingDocuments}
          listresults={filteredDocuments}
          columnsInput={documentsColumns}
          gridheight={filteredDocuments?.length > 0 ? 'auto' : 120}
          idcolumn='photoSeq'
          rowsperpage={10}
          gridcolor='#4b21a6'
          tooltip='A list of all documents/files associated with this case'
          title=''
          checkboxSelection={true}
          toolbarComponent={CustomToolbar}
          onRowClick={params => handleRowClick(params)}
          disableRowSelectionOnClick={false}
        />
        <ConfirmationModal />
        {showEditDialog && <EditDialog />}
      </Stack>

      <MLDropzone
        isOpen={open}
        setIsOpen={setOpen}
        documentTypeRequired={true}
        caseNumber={caseinfo?.caseNumber}
        caseSeq={caseinfo?.caseInformation?.caseSeq}
        onSuccessUpload={handleSuccessfulUpload}
        isLab={isLab}
      />
    </form>
  );
}
