import { User } from "../../types/User";

const { REACT_APP_API_URL } = process.env;

export const hasJurisdiction = async (user: User, jurisdictionName: string) => {
  try {
    const endpoint = `${REACT_APP_API_URL}user/jurisdictioncheck`;
    const body = {
      access_token: user.token,
      userSeq: user.userSeq,
      jurisdiction: jurisdictionName,
    };

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    const json = await response.json();
    const data = json.data;

    if (response.ok && data) {
      if (user.token !== data.access_token) {
        return false;
      }

      if (user.userSeq !== data.userSeq) {
        return false;
      }

      if (jurisdictionName !== data.requestedJurisdiction) {
        return false;
      }

      if (data.hasJurisdiction) {
        return true;
      }
    }
  } catch (error) {
    console.error(error);
    return false;
  }

  return false;
};
