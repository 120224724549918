import { AddCircleOutlineOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import GlossaryDataGrid from '../../../../components/GlossaryDataGrid';
import { useAuth } from '../../../../utils/auth/AuthService';
import { GlossaryAPI, GlossaryCategory } from './GlossaryAPI';
// @ts-ignore
import CustomHeader from './../../../../utils/components/CustomHeader';
import GlossaryDataGridLoadingSkeleton from './GlossaryDataGridLoadingSkeleton';

const { REACT_APP_API_URL } = process.env;

export default function GlossaryView({ handleMenuClick }: { handleMenuClick: any }) {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<GlossaryCategory[]>([]);
  const auth = useAuth();

  const [open, setOpen] = useState(false);
  const [newCategoryTitle, setNewCategoryTitle] = useState('');
  const [newCategoryDescription, setNewCategoryDescription] = useState('');

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const fetchedCategories = await GlossaryAPI.getCategories(auth.user?.accessToken!);
      setCategories(fetchedCategories);
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewCategoryTitle('');
    setNewCategoryDescription('');
  };

  const handleCreateCategory = async () => {
    try {
      const newCategory = await GlossaryAPI.createCategory(auth.user?.accessToken!, {
        name: newCategoryTitle,
        description: newCategoryDescription,
      });
      setCategories([...categories, newCategory]);
      handleClose();
    } catch (error) {
      console.error('Error creating category:', error);
    } finally {
      fetchCategories();
    }
  };

  return (
    <>
      <CustomHeader
        title={'Glossary'}
        description='View common and important definitions'
        handleMenuClick={handleMenuClick}
        endAdornment={
          auth.user?.roleCheck(['d1582600-5f86-49dd-bab7-6f7205bfeffd']) && (
            <Button
              size='small'
              variant='text'
              startIcon={<AddCircleOutlineOutlined />}
              onClick={handleClickOpen}
            >
              Add Category
            </Button>
          )
        }
      />

      {loading && <GlossaryDataGridLoadingSkeleton />}
      {!loading && categories.length === 0 && <p>No terms found.</p>}

      <Stack spacing={2}>
        {categories.map((category, index) => (
          <GlossaryDataGrid
            glossaryCategorySeq={category.glossaryCategorySeq}
            initialRows={category.items.map(item => ({
              ...item,
              isNew: false,
              isDeleted: false,
              isUpdated: false,
            }))}
            title={category.name}
            description={category.description}
          />
        ))}
      </Stack>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            label='Title'
            fullWidth
            size='small'
            value={newCategoryTitle}
            onChange={e => setNewCategoryTitle(e.target.value)}
          />
          <TextField
            margin='dense'
            label='Description'
            fullWidth
            size='small'
            value={newCategoryDescription}
            onChange={e => setNewCategoryDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleCreateCategory} disabled={!newCategoryTitle}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
