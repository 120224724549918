import { CancelRounded, MoreVert } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { Jurisdiction } from '../../types/Jurisdiction.interface';
import { useAuth } from '../../utils/auth/AuthService';
import { Option } from '../../views/Case/CaseViewModules/MortuaryModules/XrayRequestForm/xrayrequestform.constants';
import { useDashboard } from '../../views/Dashboard/DashboardContext';
import { ArrangeSilosButton } from './ArrangeSilosButton';
import { HomicidesChiclet } from './HomicidesChiclet';
import { SuicidesChiclet } from './SuicidesChiclet';
import { SuspectedDrugsChiclet } from './SuspectedDrugsChiclet';
import { TurnaroundTimeChiclet } from './TurnaroundTimeChiclet';

export function DashboardChiclets() {
  const { isEditing, toggleEditing, saveDashboard } = useDashboard();
  const [showDropdown, setShowDropdown] = useState(false); // State to toggle dropdown visibility

  const toggleDropdown = async () => {
    if (isEditing) {
      toggleEditing(false);
      setShowDropdown(false);
      await saveDashboard();
    }
    setShowDropdown(!showDropdown);
  }; // Function to toggle dropdown visibility

  return (
    <Stack spacing={1} sx={{ p: 1 }}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Grid container spacing={1}>
          <Grid item xs={6} md={3}>
            <TurnaroundTimeChiclet />
          </Grid>
          <Grid item xs={6} md={3}>
            <SuspectedDrugsChiclet />
          </Grid>
          <Grid item xs={6} md={3}>
            <SuicidesChiclet />
          </Grid>
          <Grid item xs={6} md={3}>
            <HomicidesChiclet />
          </Grid>
        </Grid>
        <Tooltip title='Edit Dashboard'>
          <IconButton
            onClick={toggleDropdown} /*sx={{ color: grey[500], m: 0, p: 0, flexGrow: 0 }}*/
            size='small'
            sx={{ ml: 1, width: 'max-content', height: 'max-content' }}
          >
            <MoreVert sx={{ color: grey[700] }} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Dropdown showDropdown={showDropdown} />

      <Divider variant='middle' sx={{ opacity: 1 }} />
    </Stack>
  );
}

function Dropdown({ showDropdown }: { showDropdown: boolean }) {
  const auth = useAuth();
  const userJdx = auth.user?.jdxAccessList || [];
  const userJdxOptions: Option[] = userJdx.map(jdx => ({
    optionName: jdx.jdxName,
    optionSeq: jdx.jdxSeq,
  }));

  const {
    selectedJurisdictions = [],
    setSelectedJurisdictions,
    selectedJurisdictionsLoading,
    saveDashboardJurisdictions,
  } = useDashboard();

  return (
    <Box component='div'>
      <Collapse in={showDropdown}>
        <Stack spacing={1} direction='row' alignItems='center' justifyContent='space-between'>
          <JurisdictionSelect />
          <ArrangeSilosButton />
        </Stack>
      </Collapse>
    </Box>
  );
}

const JurisdictionSelect = () => {
  const auth = useAuth();
  const {
    selectedJurisdictions,
    setSelectedJurisdictions,
    selectedJurisdictionsLoading,
    saveDashboardJurisdictions,
  } = useDashboard();
  const [temporarySelections, setTemporarySelections] = useState([]);

  const sortJurisdictionsAlphabetically = (jurisdictions: Jurisdiction[]) => {
    return jurisdictions.sort((a, b) => {
      if (a.jdxName && b.jdxName) {
        return a.jdxName.localeCompare(b.jdxName);
      }
      return 0;
    });
  };

  useEffect(() => {
    setTemporarySelections(selectedJurisdictions);
  }, [selectedJurisdictions]);

  return selectedJurisdictionsLoading ? (
    <Skeleton
      variant='rectangular'
      width={210}
      height={40}
      animation='wave'
      sx={{
        borderRadius: '10px',
      }}
    />
  ) : (
    <Autocomplete
      disableCloseOnSelect={true}
      disabled={selectedJurisdictionsLoading}
      multiple={true}
      options={sortJurisdictionsAlphabetically(auth.user?.jdxAccessList ?? [])}
      size='small'
      value={temporarySelections}
      limitTags={6}
      fullWidth
      onChange={(e, selected) => {
        e.stopPropagation();
        setTemporarySelections(selected);
        saveDashboardJurisdictions(selected);
      }}
      onBlur={e => setSelectedJurisdictions(temporarySelections)}
      onClose={e => setSelectedJurisdictions(temporarySelections)}
      isOptionEqualToValue={(option, value) =>
        option?.jdxSeq?.toUpperCase() === value?.jdxSeq.toUpperCase()
      }
      getOptionLabel={option => String(option?.jdxName)}
      renderInput={params => <TextField {...params} label={null} />}
      renderTags={(v, getTagProps, ownerState) => {
        return v.map((option, index) => {
          return (
            <Chip
              {...getTagProps({ index })}
              sx={{
                height: 'auto',
                '& .MuiChip-label': {
                  display: 'flex',
                  alignItems: 'center',
                },
                '& .MuiChip-deleteIcon': {
                  fontSize: '16px',
                },
              }}
              label={option.jdxName}
              deleteIcon={<CancelRounded />}
            />
          );
        });
      }}
      renderOption={(props, option, state, ownerState) => {
        return (
          <li {...props}>
            <Checkbox
              icon={<MdCheckBoxOutlineBlank />}
              checkedIcon={<MdOutlineCheckBox />}
              style={{ marginRight: 8 }}
              checked={state.selected}
              onChange={event => event.stopPropagation()}
            />
            {String(option?.jdxName)}
          </li>
        );
      }}
    />
  );
};
