import { useCallback, useEffect } from 'react';
import { NavigateFunction } from 'react-router';
import { User } from '../../../types/User';
import { AuthAction } from '../reducers/authReducer';
import { UserAction } from '../reducers/userReducer';
import { MedexResponse, ResponseStatus } from '../../../types/MedexResponse.type';

// Extract the API URL from the environment variables
const { REACT_APP_API_URL } = process.env;

/**
 * Hook to revalidate the authentication status of a user.
 *
 * @param authDispatch Dispatch function for authentication-related actions.
 * @param userDispatch Dispatch function for user-related actions.
 * @param navigate Function to navigate to different routes.
 */
export function useAuthRevalidation(
  authDispatch: React.Dispatch<AuthAction>,
  userDispatch: React.Dispatch<UserAction>,
  navigate: NavigateFunction
) {
  /**
   * Function to handle the revalidation process.
   * - Checks if user information exists in local storage.
   * - If it doesn't, reset authentication and navigate to login.
   * - If it does, send a request to the server to revalidate the user's tokens.
   */
  const onRevalidation = useCallback(async () => {
    try {
      // Fetch the user tokens stored in local storage
      const access_token = localStorage.getItem('access_token');
      const refresh_token = localStorage.getItem('refresh_token');
      if (!access_token || !refresh_token) {
        authDispatch({ type: 'RESET_AUTH' });
        userDispatch({ type: 'CLEAR_USER' });
        navigate('/login');
        authDispatch({ type: 'SET_READY', payload: true });
        return;
      }

      // Construct the endpoint URL for revalidation
      const endpoint = `${REACT_APP_API_URL}user/login/revalidate`;

      // Send a POST request to the revalidation endpoint
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ access_token, refresh_token }),
      });

      // Parse the response JSON
      const { status, data, errorCode, message }: MedexResponse = await response.json();

      // If the revalidation is successful, update the user states.
      // Otherwise, reset states and navigate to login.
      if (status === ResponseStatus.SUCCESS) {
        const new_refresh_token = data.refresh_token;
        const new_access_token = data.access_token;
        const new_user = data.user;
        if (new_refresh_token && new_access_token && new_user) {
          userDispatch({ type: 'SET_USER', payload: new User(new_user) });
          userDispatch({ type: 'SET_REFRESH_TOKEN', payload: new_refresh_token });
          userDispatch({ type: 'SET_ACCESS_TOKEN', payload: new_access_token });
          authDispatch({ type: 'SET_PASSED_2FA', payload: true });
          authDispatch({ type: 'SET_AUTHENTICATED', payload: true });
        } else {
          authDispatch({ type: 'RESET_AUTH' });
          userDispatch({ type: 'CLEAR_USER' });
          navigate('/login');
        }
      } else {
        authDispatch({ type: 'RESET_AUTH' });
        userDispatch({ type: 'CLEAR_USER' });
        navigate('/login');
      }

      authDispatch({ type: 'SET_READY', payload: true });
    } catch (error) {
      console.error(error);
      authDispatch({ type: 'RESET_AUTH' });
      userDispatch({ type: 'CLEAR_USER' });
      navigate('/login');
      authDispatch({ type: 'SET_READY', payload: true });
    }
  }, [authDispatch, userDispatch, navigate]);

  useEffect(() => {
    // Run the revalidation check as soon as the hook is used.
    onRevalidation();
  }, []);
}
