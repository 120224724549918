import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    ReactNode,
  } from 'react';
  import UnsavedChangesDialog from '../components/UnsavedChangesDialog';
  import { useLocation } from 'react-router';

  interface UnsavedChangesContextType {
    hasUnsavedChanges: boolean;
    setUnsavedChanges: () => void;
    resetUnsavedChanges: () => void;
  }
  
  const UnsavedChangesContext = createContext<UnsavedChangesContextType | undefined>(undefined);
  

  export const useUnsavedChanges = (): UnsavedChangesContextType => {
    const context = useContext(UnsavedChangesContext);
    if (!context) {
      throw new Error('useUnsavedChanges must be used within an UnsavedChangesProvider');
    }
    return context;
  };
  
  interface UnsavedChangesProviderProps {
    children: ReactNode;
  }
  
  export const UnsavedChangesProvider: React.FC<UnsavedChangesProviderProps> = ({ children }) => {
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
    const [unsavedDialogOpen, setUnsavedDialogOpen] = useState<boolean>(false);
    const location = useLocation();
  
    const setUnsavedChanges = () => {
      if (!hasUnsavedChanges) {
        setHasUnsavedChanges(true);
      }
    };

    const resetUnsavedChanges = () => setHasUnsavedChanges(false);
  
    const handleBackButtonEvent = (event: PopStateEvent) => {
      // event.preventDefault();
      // if (hasUnsavedChanges) {
      //   setUnsavedDialogOpen(true);
      // }
      setHasUnsavedChanges(false);
    };

    const handleUnsavedDialogLeave = () => {
      setUnsavedDialogOpen(false);
      setHasUnsavedChanges(false);
      window.history.back();
    };
  
    const handleUnsavedDialogCancel = () => {
      setUnsavedDialogOpen(false); 
      window.history.pushState(null, null, window.location.pathname);
    };

    useEffect(() => {
      //commented as issue triggered on reload. will recheck on unsaved changes checking for browser back button.
      // window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', handleBackButtonEvent);
      return () => {
        window.removeEventListener('popstate', handleBackButtonEvent);
      };
    }, [hasUnsavedChanges]);
      

    const value = { hasUnsavedChanges, setUnsavedChanges, resetUnsavedChanges };
  
    return (
      <>
      <UnsavedChangesContext.Provider value={value}>
        {children}
      </UnsavedChangesContext.Provider>
      <UnsavedChangesDialog open={unsavedDialogOpen} onLeave={handleUnsavedDialogLeave} onCancel={handleUnsavedDialogCancel} />
      </>
    );
  };
