//todo allow the user to search for a case id, 
//todo display the case details in a table and who it's locked by and when it was locked
//todo allow the user to unlock the case

import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';

export default function UnlockCaseView() {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [gridData, setGridData] = useState([]);
  const theme = useTheme();

  // Load environment variables
  const baseUrl = process.env.REACT_APP_API_URL;

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const UploadHL7File = async () => {
    if (!file) {
      setUploadStatus('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${baseUrl}UnlockCase/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setUploadStatus('File uploaded successfully.');
      console.log('Response:', response.data);
      setGridData(response.data.data); // Assuming the JSON data is in response.data.data
    } catch (error) {
      setUploadStatus('Failed to upload file.');
      console.error('Error uploading file:', error);
    }
  };

  

  return (
    <Card raised sx={{ my: 3, mx: 'auto', width: { xs: '95%', lg: '95%' } }}>
      <CardHeader
        title="Unlock Case  (under construction)"
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
      />
      <Divider variant="middle" sx={{ my: 2, opacity: 1 }} />
      <CardContent>
      
      
        {gridData.length > 0 && (
          <Box mt={3} sx={{ height: 400, width: '100%' }}>
            <DataGrid rows={gridData} columns={columns} pageSize={5} rowsPerPageOptions={[5]} />
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
