import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import MainCard from '../../../../components/AdministrationModules/MainCard';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';

const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${theme.palette.primary[200]} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${theme.palette.primary[200]} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));


const CountCard = ({ message, totalCount, activeCount }) => {
    const theme = useTheme();

    return (
        <>
            <CardWrapper border={false} content={false}>
                <Box sx={{ p: 2 }}>
                    <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                        <ListItemAvatar>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.largeAvatar,
                                    backgroundColor: theme.palette.primary[800],
                                    color: '#fff'
                                }}
                            >
                                <TableChartOutlinedIcon fontSize="inherit" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                py: 0,
                                mt: 0.45,
                                mb: 0.45
                            }}
                            primary={
                                <Typography variant="h4" sx={{ color: '#fff' }}>
                                    {activeCount}
                                </Typography>
                            }
                            secondary={
                                <Typography variant="subtitle2" sx={{ color: 'primary.light', mt: 0.25 }}>
                                    {message}
                                    <Typography variant="h4" sx={{ color: '#fff' }}>
                                        {totalCount}
                                    </Typography>
                                </Typography>
                            }
                        />
                    </ListItem>
                    {/* </List> */}
                </Box>
            </CardWrapper>
        </>
    );
};

CountCard.propTypes = {
    isLoading: PropTypes.bool
};

export default CountCard;
