import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { TailSpin } from 'react-loader-spinner';
import { useNavigate } from 'react-router';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  NavLink,
  Row,
} from 'reactstrap';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import { BreadcrumbReportNav } from '../../../utils/components/BreadcrumbReportNav';
// import { breadcrumbReportNav } from '../../../common/helper-functions'

export default function MissingDemographicsView() {
  const { user } = useAuth();
  const [userReady, setuserReady] = useState(false);
  const [loading, setloading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const [averageresults, setaverageresults] = useState([
    { averageresponsetimeseconds: '', averageresponsetime: '' },
  ]);
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [selectedJdxs, setSelectedJdxs] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [year, setYear] = useState('');
  const [currentUser, setcurrentUser] = useState(false);

  const [requiredAlertVisible, setRequiredAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const navigate = useNavigate();

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };
  const columns = [
    {
      field: 'caseID',
      headerName: 'Case ID',
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <NavLink
            color='primary'
            onClick={(event) => {
              handleCaseClick(event, params);
            }}
          >
            {params.formattedValue}
          </NavLink>
        );
      },
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      flex: 1,
      editable: false,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 1,
      editable: false,
    },
    {
      field: 'age',
      headerName: 'Age',
      flex: 1,
      editable: false,
    },
    {
      field: 'ageUnit',
      headerName: 'Age Unit',
      flex: 1,
      editable: false,
    },
    {
      field: 'race',
      headerName: 'Race',
      flex: 1,
      editable: false,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      flex: 2,
      editable: false,
    },
    {
      field: 'investigator',
      headerName: 'Investigator',
      flex: 1,
      editable: false,
    },
  ];

  const { REACT_APP_API_URL } = process.env;

  const handleJdxChange = (event, data) => {
    setSelectedJdxs(data);
  };

  const handleStartDateChange = (event) => {
    if (event.target.value != '') {
      setStartDate(event.target.value);
    } else {
      setStartDate('');
    }
  };

  const handleEndDateChange = (event) => {
    if (event.target.value != '') {
      setEndDate(event.target.value);
    } else {
      setEndDate('');
    }
  };

  const handleYearChange = (event) => {
    if (event.target.value != '') {
      setYear(event.target.value);
    } else {
      setYear('');
    }
  };

  const dismissDateAlert = () => {
    setRequiredAlertVisible(false);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  const clearAll = () => {
    setStartDate('');
    setEndDate('');
    setYear('');
    setSelectedJdxs([]);
    setResultsVisible(false);
  };

  //gather search params from form and FETCH/POST to API
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (user === null) return;

    if (year == '') {
      let startDateArr = startDate.split('-');
      let endDateArr = endDate.split('-');

      let startDateValue = new Date(
        startDateArr[0],
        startDateArr[1] - 1,
        startDateArr[2]
      );
      let endDateValue = new Date(
        endDateArr[0],
        endDateArr[1] - 1,
        endDateArr[2]
      );

      if (endDateValue - startDateValue > 31536000000) {
        setRequiredAlertVisible(true);
        return;
      }
    }

    if (selectedJdxs.length == 0) {
      setJdxAlertVisible(true);
      return;
    }

    let formData = new FormData();

    setloading(true);
    setResultsVisible(true);

    formData.append('SEARCHFROMDATE', startDate);
    formData.append('SEARCHTODATE', endDate);
    formData.append('CASEYEAR', year);
    formData.append('SELECTEDJDXLIST', JSON.stringify(selectedJdxs));

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'MissingDemographics', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then((res) => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then((data) => {
          setlistresults(data);
          setloading(false);
        })
        .catch((e) => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    await setloading(false);
  };

  const PageContent = () => {
    return loading ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TailSpin color='#00BFFF' height={80} width={80} />
      </div>
    ) : (
      <div style={{ height: 600, width: '100%' }}>
        <b>Cases with Missing Documents: </b>
        <hr />

        <StyledDataGrid
          getRowId={(row) => row.caseID}
          rows={listresults}
          columns={columns}
          pageSize={15}
          components={{
            Toolbar: MyExportButton,
          }}
          rowsPerPageOptions={[15]}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          disableSelectionOnClick
        />
      </div>
    );
  };

  const MyExportButton = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  const customCheckbox = (theme) => {
    return {
      '& .MuiCheckbox-root svg': {
        width: 16,
        height: 16,
        backgroundColor: 'transparent',
        border: `1px solid ${
          theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
        }`,
        borderRadius: 2,
      },
      '& .MuiCheckbox-root svg path': {
        display: 'none',
      },
      '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
        backgroundColor: '#1890ff',
        borderColor: '#1890ff',
      },
      '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
        position: 'absolute',
        display: 'table',
        border: '2px solid #fff',
        borderTop: 0,
        borderLeft: 0,
        transform: 'rotate(45deg) translate(-50%,-50%)',
        opacity: 1,
        transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
        content: '""',
        top: '50%',
        left: '39%',
        width: 5.71428571,
        height: 9.14285714,
      },
      '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after':
        {
          width: 8,
          height: 8,
          backgroundColor: '#1890ff',
          transform: 'none',
          top: '39%',
          border: 0,
        },
    };
  };

  const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
    border: 1,
    color:
      theme.palette.mode === 'light'
        ? 'rgba(0,0,0,.85)'
        : 'rgba(255,255,255,0.85)',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      borderRight: `1px solid ${
        theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
      }`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${
        theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
      }`,
    },
    '& .MuiDataGrid-cell': {
      color:
        theme.palette.mode === 'light'
          ? 'rgba(0,0,0,.85)'
          : 'rgba(255,255,255,0.65)',
    },
    '& .MuiPaginationItem-root': {
      borderRadius: 0,
    },
    ...customCheckbox(theme),
  }));

  useEffect(() => {
    if (user === null) return;

    setAuthorizedJdxs(user.jdxAccessList);

    setuserReady(true);
  }, [user]);

  return (
    <div className='me-response-time' style={{ padding: 20 }}>
      {/* {breadcrumbReportNav("Missing Demographics")} */}
      <BreadcrumbReportNav currentPage='Missing Demographics' />
      <Card style={{ marginBottom: '1rem' }} className='card-main'>
        <CardHeader className='header-txt'>
          Cases with Missing Demographics
        </CardHeader>

        <Alert
          color='info'
          isOpen={requiredAlertVisible}
          toggle={dismissDateAlert}
        >
          Date range must be within 1 year or year dropdown must be set.
        </Alert>

        <Alert
          color='info'
          isOpen={jdxAlertVisible}
          toggle={dismissJdxAlert}
        >
          Please select at Least 1 Jdx.
        </Alert>

        <div>
          <CardBody>
            <Row>
              <Col className='md=12'>
                <Autocomplete
                  multiple
                  id='selectedJdxs'
                  options={authorizedJdxs}
                  value={selectedJdxs}
                  disableCloseOnSelect
                  limitTags={4}
                  size='small'
                  getOptionLabel={(option) => option.jdxName}
                  onChange={(event, data) => handleJdxChange(event, data)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<MdCheckBoxOutlineBlank />}
                        checkedIcon={<MdOutlineCheckBox />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.jdxName}
                    </li>
                  )}
                  style={{ width: '100%', marginTop: 15 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Jurisdiction'
                      placeholder='Jurisdiction'
                    />
                  )}
                  ListboxComponent={'ul'}
                />
              </Col>
            </Row>
            <Row>
              <Col className='me-col' md='4'>
                <FormControl fullWidth>
                  <InputLabel style={{ marginTop: -7 }}>Year</InputLabel>
                  <Select
                    label='Year'
                    id='year'
                    name='year'
                    size='small'
                    value={year}
                    onChange={handleYearChange}
                    style={{ width: '100%' }}
                  >
                    <MenuItem value={'2023'}>2023</MenuItem>
                    <MenuItem value={'2022'}>2022</MenuItem>
                    <MenuItem value={'2021'}>2021</MenuItem>
                    <MenuItem value={'2020'}>2020</MenuItem>
                    <MenuItem value={'2019'}>2019</MenuItem>
                    <MenuItem value={'2018'}>2018</MenuItem>
                    <MenuItem value={'2017'}>2017</MenuItem>
                    <MenuItem value={'2016'}>2016</MenuItem>
                    <MenuItem value={'2015'}>2015</MenuItem>
                    <MenuItem value={'2014'}>2014</MenuItem>
                    <MenuItem value={'2013'}>2013</MenuItem>
                    <MenuItem value={'2012'}>2012</MenuItem>
                    <MenuItem value={'2011'}>2011</MenuItem>
                    <MenuItem value={'2010'}>2010</MenuItem>
                    <MenuItem value={'2009'}>2009</MenuItem>
                    <MenuItem value={'2008'}>2008</MenuItem>
                    <MenuItem value={''}>None</MenuItem>
                  </Select>
                </FormControl>
              </Col>
              <Col className='me-col' md='4'>
                <TextField
                  id='searchfromdate'
                  name='searchfromdate'
                  label='Call Taken From'
                  type='date'
                  size='small'
                  value={startDate}
                  onChange={handleStartDateChange}
                  disabled={year}
                  style={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Col>
              <Col className='me-col' md='4'>
                <TextField
                  id='searchtodate'
                  name='searchtodate'
                  label='Call Taken To'
                  type='date'
                  size='small'
                  value={endDate}
                  onChange={handleEndDateChange}
                  disabled={year}
                  style={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Col>
            </Row>
          </CardBody>
          <br />

          <Button
            type='submit'
            color='primary'
            style={{ width: 120, marginLeft: 18, float: 'right' }}
            onClick={handleSubmit}
          >
            Calculate
          </Button>

          <Button
            color='secondary'
            style={{ width: 120, marginLeft: 18, float: 'right' }}
            onClick={clearAll}
          >
            Clear
          </Button>
          <hr />
          <br />

          <Collapse isOpen={resultsVisible}>
            <PageContent />
          </Collapse>
        </div>
      </Card>
    </div>
  );
}
