import InfoIcon from '@mui/icons-material/Info';
import { Autocomplete, Checkbox, Paper, TextField } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { TailSpin } from 'react-loader-spinner';
import { useNavigate } from 'react-router';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  NavLink,
  Row,
} from 'reactstrap';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import { BreadcrumbReportNav } from '../../../utils/components/BreadcrumbReportNav';

export default function DataEntryResponseTimeView() {
  const { user } = useAuth();
  const [redirect, setredirect] = useState(null);
  const [userReady, setuserReady] = useState(false);
  const [loading, setloading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const [acceptedAverage, setAcceptedAverage] = useState('');
  const [facilitatedAverage, setFacilitatedAverage] = useState('');
  const [releaseAverage, setReleaseAverage] = useState('');
  const [storageAverage, setStorageAverage] = useState('');
  const [transferAverage, setTransferAverage] = useState('');
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [selectedJdxs, setSelectedJdxs] = useState([]);
  const [MEAction, setMEAction] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [year, setYear] = useState('');
  const [currentUser, setcurrentUser] = useState(false);
  const [requiredAlertVisible, setRequiredAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const navigate = useNavigate();

  const yearOptions = [
    '2023',
    '2022',
    '2021',
    '2020',
    '2019',
    '2018',
    '2017',
    '2016',
    '2015',
    '2014',
    '2013',
    '2012',
    '2011',
    '2010',
    '2009',
    '2008',
  ];

  const MEActionOptions = [
    'Accepted',
    'Case Release',
    'Facilitated Release',
    'Storage Only',
    'Transfer',
  ];

  const makePretty = (ns) => {
    var prettyTime = '';

    if (ns >= 86400) {
      var numDays = Math.floor(ns / 86400);
      prettyTime += numDays + (numDays == 1 ? ' Day ' : ' Days ');
      ns %= 86400;
    }
    if (ns >= 3600) {
      var numHours = Math.floor(ns / 3600);
      prettyTime += numHours + (numHours == 1 ? ' Hour ' : ' Hours ');
      ns %= 3600;
    }
    if (ns >= 60) {
      var numMinutes = Math.floor(ns / 60);
      prettyTime += numMinutes + (numMinutes == 1 ? ' Minute ' : ' Minutes ');
      ns %= 60;
    }
    if (ns > 0) {
      var numSeconds = ns;
      prettyTime += numSeconds + (numSeconds == 1 ? ' Second ' : ' Seconds ');
    }

    if (prettyTime == '') {
      prettyTime = '---';
    }

    return prettyTime;
  };

  const calculateAcceptedAverage = (data) => {
    var count = 0;
    var numSeconds = 0;

    for (const x of data) {
      if (x.meaction == 'Accepted') {
        numSeconds += +x.responsetimeseconds;
        count++;
      }
    }

    setAcceptedAverage(makePretty(Math.round(numSeconds / count)));
  };

  const calculateFacilitatedAverage = (data) => {
    var count = 0;
    var numSeconds = 0;

    for (const x of data) {
      if (x.meaction == 'Facilitated Release') {
        numSeconds += +x.responsetimeseconds;
        count++;
      }
    }

    setFacilitatedAverage(makePretty(Math.round(numSeconds / count)));
  };

  const calculateReleaseAverage = (data) => {
    var count = 0;
    var numSeconds = 0;

    for (const x of data) {
      if (x.meaction == 'Case Release') {
        numSeconds += +x.responsetimeseconds;
        count++;
      }
    }

    setReleaseAverage(makePretty(Math.round(numSeconds / count)));
  };

  const calculateStorageAverage = (data) => {
    var count = 0;
    var numSeconds = 0;

    for (const x of data) {
      if (x.meaction == 'Storage Only') {
        numSeconds += +x.responsetimeseconds;
        count++;
      }
    }

    setStorageAverage(makePretty(Math.round(numSeconds / count)));
  };

  const calculateTransferAverage = (data) => {
    var count = 0;
    var numSeconds = 0;

    for (const x of data) {
      if (x.meaction == 'Transfer') {
        numSeconds += +x.responsetimeseconds;
        count++;
      }
    }

    setTransferAverage(makePretty(Math.round(numSeconds / count)));
  };

  const calculateAverages = (data) => {
    calculateAcceptedAverage(data);
    calculateFacilitatedAverage(data);
    calculateReleaseAverage(data);
    calculateStorageAverage(data);
    calculateTransferAverage(data);
  };

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const columns = [
    {
      field: 'caseid',
      headerName: 'Case ID',
      width: 105,
      editable: false,
      renderCell: (params) => {
        return (
          <NavLink
            color='primary'
            onClick={(event) => {
              handleCaseClick(event, params);
            }}
          >
            {params.formattedValue}
          </NavLink>
        );
      },
    },
    {
      field: 'lastname',
      headerName: 'Last Name',
      width: 120,
      editable: false,
    },
    {
      field: 'pronounced',
      headerName: 'Pronounced',
      width: 190,
      editable: false,
    },
    {
      field: 'meaction',
      headerName: 'ME Action',
      width: 190,
      editable: false,
    },
    {
      field: 'entrytime',
      headerName: '(Entered Into CMS     -',
      width: 190,
      editable: false,
    },
    {
      field: 'calltaken',
      headerName: 'Call Received)',
      width: 190,
      editable: false,
    },
    {
      field: 'dataentryresponsetime',
      headerName: '=Data Entry Response Time',
      width: 190,
      editable: false,
    },
  ];

  const { REACT_APP_API_URL } = process.env;

  const handleJdxChange = (event, data) => {
    setSelectedJdxs(data);
  };

  const handleMEActionChange = (event, data) => {
    setMEAction(data);
  };

  const handleStartDateChange = (event) => {
    if (event.target.value != '') {
      setStartDate(event.target.value);
    } else {
      setStartDate('');
    }
  };

  const handleEndDateChange = (event) => {
    if (event.target.value != '') {
      setEndDate(event.target.value);
    } else {
      setEndDate('');
    }
  };

  const handleYearChange = (event, data) => {
    setYear(data);
  };

  const dismissDateAlert = () => {
    setRequiredAlertVisible(false);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  const clearAll = () => {
    setStartDate('');
    setEndDate('');
    setYear('');
    setSelectedJdxs([]);
    setMEAction([]);
    setResultsVisible(false);
  };

  //gather search params from form and FETCH/POST to API
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (user === null) return;

    if (year == '' && startDate == '' && endDate == '') {
      setRequiredAlertVisible(true);
      return;
    } else if (startDate != '' && endDate != '') {
      let startDateArr = startDate.split('-');
      let endDateArr = endDate.split('-');

      let startDateValue = new Date(
        startDateArr[0],
        startDateArr[1] - 1,
        startDateArr[2]
      );
      let endDateValue = new Date(
        endDateArr[0],
        endDateArr[1] - 1,
        endDateArr[2]
      );

      if (endDateValue - startDateValue > 31536000000) {
        setRequiredAlertVisible(true);
        return;
      }
    }

    if (selectedJdxs.length == 0) {
      setJdxAlertVisible(true);
      return;
    }

    let formData = new FormData();

    setloading(true);
    setResultsVisible(true);

    formData.append('SEARCHFROMDATE', startDate);
    formData.append('SEARCHTODATE', endDate);
    formData.append('CASEYEAR', year);
    formData.append('SELECTEDJDXLIST', JSON.stringify(selectedJdxs));
    formData.append('MEACTION', JSON.stringify(MEAction));

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'DEResponseTime', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then((res) => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then((data) => {
          setlistresults(data);
          setloading(false);
          calculateAverages(data);
        })
        .catch((e) => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    setloading(false);
  };

  const PageContent = () => {
    return loading ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TailSpin color='#00BFFF' height={80} width={80} />
      </div>
    ) : (
      <div style={{ width: '100%' }}>
        <p style={{ textAlign: 'center' }}>
          <b>Data Entry Response Time by ME Action: </b>
        </p>
        <hr />

        <Row style={{ justifyContent: 'center' }}>
          <Col
            xs='12'
            sm='12'
            md='6'
            lg='4'
            xl='4'
            xxl='auto'
            style={{ marginTop: 10, padding: 3 }}
          >
            <Paper
              elevation={3}
              style={{ padding: 10, width: '100%', height: '100%' }}
            >
              <div style={{ textAlign: 'center' }}>
                <b>Accepted Cases</b>
                <br />
                <i style={{ fontSize: 12 }}>
                  (Average time from Call Taken to CMS Entry Time)
                </i>
                <p style={{ marginTop: 15 }}>{acceptedAverage}</p>
              </div>
            </Paper>
          </Col>

          <Col
            xs='12'
            sm='12'
            md='6'
            lg='4'
            xl='4'
            xxl='auto'
            style={{ marginTop: 10, padding: 3 }}
          >
            <Paper
              elevation={3}
              style={{ padding: 10, width: '100%', height: '100%' }}
            >
              <div style={{ textAlign: 'center' }}>
                <b>Facilitated Releases</b>
                <br />
                <i style={{ fontSize: 12 }}>
                  (Average time from Call Taken to CMS Entry Time)
                </i>
                <p style={{ marginTop: 15 }}>{facilitatedAverage}</p>
              </div>
            </Paper>
          </Col>

          <Col
            xs='12'
            sm='12'
            md='6'
            lg='4'
            xl='4'
            xxl='auto'
            style={{ marginTop: 10, padding: 3 }}
          >
            <Paper
              elevation={3}
              style={{ padding: 10, width: '100%', height: '100%' }}
            >
              <div style={{ textAlign: 'center' }}>
                <b>Releases</b>
                <br />
                <i style={{ fontSize: 12 }}>
                  (Average time from Call Taken to CMS Entry Time)
                </i>
                <p style={{ marginTop: 15 }}>{releaseAverage}</p>
              </div>
            </Paper>
          </Col>

          <Col
            xs='12'
            sm='12'
            md='6'
            lg='6'
            xl='6'
            xxl='auto'
            style={{ marginTop: 10, padding: 3 }}
          >
            <Paper
              elevation={3}
              style={{ padding: 10, width: '100%', height: '100%' }}
            >
              <div style={{ textAlign: 'center' }}>
                <b>Storage Only</b>
                <br />
                <i style={{ fontSize: 12 }}>
                  (Average time from Call Taken to CMS Entry Time)
                </i>
                <p style={{ marginTop: 15 }}>{storageAverage}</p>
              </div>
            </Paper>
          </Col>

          <Col
            xs='12'
            sm='12'
            md='12'
            lg='6'
            xl='6'
            xxl='auto'
            style={{ marginTop: 10, padding: 3 }}
          >
            <Paper
              elevation={3}
              style={{ padding: 10, width: '100%', height: '100%' }}
            >
              <div style={{ textAlign: 'center' }}>
                <b>Transferred Cases</b>
                <br />
                <i style={{ fontSize: 12 }}>
                  (Average time from Call Taken to CMS Entry Time)
                </i>
                <p style={{ marginTop: 15 }}>{transferAverage}</p>
              </div>
            </Paper>
          </Col>
        </Row>

        <hr />

        <div style={{ height: 800, width: '100%' }}>
          <StyledDataGrid
            getRowId={(row) => row.caseid}
            rows={listresults}
            columns={columns}
            checkboxSelection
            pageSize={15}
            components={{
              Toolbar: MyExportButton,
            }}
            rowsPerPageOptions={[25]}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            disableSelectionOnClick
          />
        </div>
      </div>
    );
  };

  const MyExportButton = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  const customCheckbox = (theme) => {
    return {
      '& .MuiCheckbox-root svg': {
        width: 16,
        height: 16,
        backgroundColor: 'transparent',
        border: `1px solid ${
          theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
        }`,
        borderRadius: 2,
      },
      '& .MuiCheckbox-root svg path': {
        display: 'none',
      },
      '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
        backgroundColor: '#1890ff',
        borderColor: '#1890ff',
      },
      '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
        position: 'absolute',
        display: 'table',
        border: '2px solid #fff',
        borderTop: 0,
        borderLeft: 0,
        transform: 'rotate(45deg) translate(-50%,-50%)',
        opacity: 1,
        transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
        content: '""',
        top: '50%',
        left: '39%',
        width: 5.71428571,
        height: 9.14285714,
      },
      '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after':
        {
          width: 8,
          height: 8,
          backgroundColor: '#1890ff',
          transform: 'none',
          top: '39%',
          border: 0,
        },
    };
  };

  const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
    border: 1,
    color:
      theme.palette.mode === 'light'
        ? 'rgba(0,0,0,.85)'
        : 'rgba(255,255,255,0.85)',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      borderRight: `1px solid ${
        theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
      }`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${
        theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
      }`,
    },
    '& .MuiDataGrid-cell': {
      color:
        theme.palette.mode === 'light'
          ? 'rgba(0,0,0,.85)'
          : 'rgba(255,255,255,0.65)',
    },
    '& .MuiPaginationItem-root': {
      borderRadius: 0,
    },
    ...customCheckbox(theme),
  }));

  useEffect(() => {
    if (user === null) return;

    setAuthorizedJdxs(user.jdxAccessList);
    setuserReady(true);
  }, [user]);

  return (
    <div className='me-response-time' style={{ padding: 20 }}>
      <BreadcrumbReportNav currentPage='UNIMPLEMENTED!' />
      <Card style={{ marginBottom: '1rem' }} className='card-main'>
        <CardHeader className='header-txt'>
          Data Entry Response Time
          <Tooltip
            style={{ float: 'right' }}
            title='This Report Measures how long it takes on average to enter a case into CMS after the call has come in'
            placement='right-end'
          >
            <InfoIcon />
          </Tooltip>
        </CardHeader>

        <Alert
          color='info'
          isOpen={requiredAlertVisible}
          toggle={dismissDateAlert}
          style={{ marginTop: 10 }}
        >
          Date range must be within 1 year or year dropdown must be set.
        </Alert>

        <Alert
          color='info'
          isOpen={jdxAlertVisible}
          toggle={dismissJdxAlert}
          style={{ marginTop: 10 }}
        >
          Please select at least 1 jdx.
        </Alert>

        <div>
          <CardBody>
            <Row>
              {/*<Col className="md=12">*/}
              <Col className='me-col' md='6'>
                <Autocomplete
                  multiple
                  id='selectedjdxs'
                  size='small'
                  options={authorizedJdxs}
                  value={selectedJdxs}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.jdxName}
                  onChange={(event, data) => handleJdxChange(event, data)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<MdCheckBoxOutlineBlank />}
                        checkedIcon={<MdOutlineCheckBox />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.jdxName}
                    </li>
                  )}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Jurisdiction'
                      placeholder='Jurisdiction'
                    />
                  )}
                />
              </Col>

              {/*<Col className="me-col" md="4">*/}
              <Col className='me-col' md='6'>
                <Autocomplete
                  multiple
                  id='meaction'
                  size='small'
                  options={MEActionOptions}
                  value={MEAction}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  onChange={(event, data) => handleMEActionChange(event, data)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<MdCheckBoxOutlineBlank />}
                        checkedIcon={<MdOutlineCheckBox />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='ME Action'
                      placeholder='ME Action'
                    />
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col className='me-col' md='4'>
                <Autocomplete
                  id='year'
                  options={yearOptions}
                  value={year}
                  disableCloseOnSelect
                  size='small'
                  getOptionLabel={(option) => option}
                  onChange={(event, data) => handleYearChange(event, data)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<MdCheckBoxOutlineBlank />}
                        checkedIcon={<MdOutlineCheckBox />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Case Year'
                      placeholder='Case Year'
                    />
                  )}
                />
              </Col>

              <Col className='me-col' md='4'>
                <TextField
                  id='searchfromdate'
                  name='searchfromdate'
                  label='Call Taken From'
                  type='date'
                  size='small'
                  value={startDate}
                  onChange={handleStartDateChange}
                  disabled={year}
                  style={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Col>

              <Col className='me-col' md='4'>
                <TextField
                  id='searchtodate'
                  name='searchtodate'
                  label='Call Taken To'
                  type='date'
                  size='small'
                  value={endDate}
                  onChange={handleEndDateChange}
                  disabled={year}
                  style={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Col>
            </Row>
          </CardBody>
          <br />

          <Button
            type='submit'
            color='primary'
            style={{ width: 120, marginLeft: 18, float: 'right' }}
            onClick={handleSubmit}
          >
            Calculate
          </Button>

          <Button
            color='secondary'
            style={{ width: 120, marginLeft: 18, float: 'right' }}
            onClick={clearAll}
          >
            Clear
          </Button>
          <hr />
          <br />

          <Collapse isOpen={resultsVisible}>
            <PageContent />
          </Collapse>
        </div>
      </Card>
    </div>
  );
}
