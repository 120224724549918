import React, { useEffect, useState } from 'react';
import { ErrorMessage, Formik, Field } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  FormControlLabel,
  Grid,
} from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const FilterActivity = ({
  rows,
  activity,
  department,
  setActivity,
  setDepartment,
  setRowsToShow,
  departmentData,
  activeInActiveFilter,
  setActiveInActiveFilter,
}) => {
  const onFilter = () => {
    let newRows = rows;
    console.log('filter', department, activity, activeInActiveFilter);
    if (department)
      newRows = newRows.filter(({ department: dpt }) => dpt == department);
    if (activity)
      newRows = newRows.filter(
        (r) =>
          r.activityName.toLowerCase().indexOf(activity.toLowerCase()) != -1
      );
    if (activeInActiveFilter == 'all') setRowsToShow(newRows);
    else if (activeInActiveFilter == 'Active')
      setRowsToShow(
        newRows.filter((row) => row.status.toLowerCase() == 'active')
      );
    else
      setRowsToShow(
        newRows.filter((row) => row.status.toLowerCase() != 'active')
      );
  };

  useEffect(() => {
    onFilter();
  }, [rows, activeInActiveFilter, department, activity]);

  const FormikAutocomplete = ({ textFieldProps, ...props }) => {
    return (
      <Autocomplete
        {...props}
        onChange={(e, v) => {
          setCountyValue(v);
        }}
        value={countyValue?.label || ''}
        getOptionSelected={(item, current) => item.value === current.value}
        renderInput={(props) => (
          <TextField {...props} label='Choose a County' />
        )}
      />
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <FormControl variant='outlined' fullWidth>
            <InputLabel htmlFor='department' id='department'>
              Department
            </InputLabel>
            <Select
              label='Department'
              id='department'
              name='department'
              size='large'
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
              style={{ width: '100%', height: '100%' }}
            >
              <MenuItem value=''>Select a department...</MenuItem>
              {departmentData?.map((departmentOption) => {
                return (
                  <MenuItem value={departmentOption?.assignmentTypeName}>
                    {departmentOption?.assignmentTypeName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            value={activity}
            onChange={(e) => setActivity(e.target.value)}
            size='medium'
            variant='outlined'
            id='activity'
            label='Activity'
          />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <RadioGroup
            row
            aria-labelledby='demo-row-radio-buttons-group-label'
            name='isActive'
            onChange={(e) => setActiveInActiveFilter(e.target.value)}
            value={activeInActiveFilter}
            sx={{ marginLeft: 1 }}
          >
            {' '}
            <FormControlLabel
              value='Active'
              control={<Radio />}
              label='Active Only'
            />{' '}
            <FormControlLabel
              value='Inactive'
              control={<Radio />}
              label='Inactive Only'
            />{' '}
            <FormControlLabel value='all' control={<Radio />} label='All' />{' '}
          </RadioGroup>
        </Grid>
      </Grid>
    </>
  );
};

export default FilterActivity;
