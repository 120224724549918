import { useDraggable, useDroppable } from '@dnd-kit/core';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import { memo, useRef } from 'react';
import { useDashboard } from '../DashboardContext';

const AnimatedBox = motion(Box);

export const DraggableSiloContainer = memo(
  ({
    children,
    id,
    size,
    rowIndex = -1,
    itemIndex = -1,
  }: {
    children: React.ReactNode;
    id: string;
    size: number;
    rowIndex: number;
    itemIndex: number;
  }) => {
    const { isEditing } = useDashboard();
    const draggableRef = useRef<HTMLElement | null>(null);
    const droppableRef = useRef<HTMLElement | null>(null);
    const {
      attributes,
      listeners,
      setNodeRef: setDragNodeRef,
      isDragging,
    } = useDraggable({
      id,
      disabled: !isEditing,
      data: {
        type: size === 12 ? 'row' : 'item',
        rowIndex,
        itemIndex,
      },
    });

    const { setNodeRef: setDropNodeRef } = useDroppable({
      id,
      disabled: !isEditing,
      data: {
        accepts: size === 12 ? 'row' : 'item',
        rowIndex,
        itemIndex,
      },
    });

    const setCombinedRef = (node: HTMLElement | null) => {
      setDragNodeRef(node);
      setDropNodeRef(node);
      draggableRef.current = node;
      droppableRef.current = node;
    };

    const normalStyle: React.CSSProperties = {
      position: 'relative',
      transition: 'opacity 0.3s ease, box-shadow 0.3s ease, scale 0.3 ease',
      borderRadius: '12px',
      width: '100%',
    };

    const editingStyle: React.CSSProperties = {
      cursor: isDragging ? 'grabbing' : 'grab',
      position: 'relative',
      transition: 'opacity 0.3s ease, box-shadow 0.3s ease, scale 0.3 ease',
      borderRadius: '12px',
      width: '100%',
    };

    const style = isEditing ? editingStyle : normalStyle;

    return (
      <motion.div
        whileHover={{ opacity: isEditing ? 0.8 : 1 }}
        ref={setCombinedRef}
        style={style}
        {...attributes}
        {...listeners}
      >
        <AnimatedBox borderRadius={3}>{children}</AnimatedBox>
      </motion.div>
    );
  }
);
