import { useEffect, useState } from 'react';

export function CodeExpirationCountdown({
  isCounting,
}: {
  isCounting: boolean;
}) {
  const [minutes, setMinutes] = useState(10);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let interval: any = null;
    if (isCounting) {
      interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds((seconds) => seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setMinutes((minutes) => minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);
    } else if (!isCounting && minutes !== 0 && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isCounting, seconds, minutes]);

  return ` ${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
}
