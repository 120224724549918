import { Collapse, Divider, Grid, Paper, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import AddressData from '../../../../components/AdministrationModules/AddressComponents/AddressData';
import { PathologyCard } from '../../../../components/PathologyCard';
import { PersonCard } from '../../../../components/PersonCard';
import { useAuth } from '../../../../utils/auth/AuthService';
import { CaseViewOptionsAutocomplete } from '../../../../utils/components/CaseViewOptionsAutocomplete';
import { FormikTextField } from '../../../../utils/components/FormikTextField';
import { MEAction } from '../../../../utils/constants/MEAction';

export function MAiDFields() {
  const { user } = useAuth();
  const formik = useFormikContext<any>();

  const authorizedToEdit =
    user?.roleCheck([
      'caef1f91-c392-4b53-a466-a8f418d8d25a', // Medical Examiner
      'd1582600-5f86-49dd-bab7-6f7205bfeffd', // Sys admin
      'e0556a35-b626-485d-9090-0d1a23abf38b', // Investigator
      '18c92818-6969-466b-a82b-c4817fdfacf4', // Data entry (HISTORIC-DATAENTRYONLY)
      '7bea8708-b1c8-4bf5-8fda-bc023453f072', // Admin assistant
    ]) ?? false;

  const Selected_Disposition = useMemo(() => {
    return formik.values?.caseMAIDDetails?.maidDispositionSeq?.toUpperCase() || '';
  }, [formik.values?.caseMAIDDetails?.notificationTypeSeq]);

  const Selected_Education_Level = useMemo(() => {
    return formik.values?.caseMAIDDetails?.educationType?.optionSeq?.toUpperCase() || '';
  }, [formik.values?.caseMAIDDetails?.educationType]);

  const Selected_Underlying_Illness = useMemo(() => {
    return formik.values?.caseMAIDDetails?.underlyingIllness?.optionSeq?.toUpperCase() || '';
  }, [formik.values?.caseMAIDDetails?.underlyingIllness]);

  const Selected_NotificationType = useMemo(() => {
    return formik.values?.caseMAIDDetails?.notificationTypeSeq?.toUpperCase() || '';
  }, [formik.values?.caseMAIDDetails?.notificationTypeSeq]);

  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={12} md={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <CaseViewOptionsAutocomplete
              label='Decedent Education Level'
              optionsEndpoint='getmaideducationleveloptions'
              formikField='caseMAIDDetails.educationType'
              authorizedToEdit={r => [
                r.Investigator,
                r.Medical_Examiner,
                r.Administrative_Assistant,
                r.Historic_Dataentryonly,
                r.Systems_Admin,
              ]}
            />
          </Grid>
          <Grid
            component={Collapse}
            in={Selected_Education_Level === MEAction.Constants.MAiD.EducationLevel.Other}
            unmountOnExit
            item
            xs={12}
            md={2}
          >
            <FormikTextField
              label='Other Education Level'
              formikField='caseMAIDDetails.otherEducation'
              authorizedToEdit={r => [
                r.Investigator,
                r.Medical_Examiner,
                r.Administrative_Assistant,
                r.Historic_Dataentryonly,
                r.Systems_Admin,
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Paper
          variant='outlined'
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            px: 2,
            pb: 2,
            pt: 1,
          }}
        >
          <PathologyCard
            formikFields={{
              decedentDateOfDeath: 'caseDemographics.dateOfDeath',
              decedentUnderlyingIllness: 'caseMAIDDetails.underlyingIllness',
              decedentOtherUnderlyingIllness: 'caseMAIDDetails.otherUnderlyingIllness',
              decedentCauseOfDeath: 'casePathology.causeOfDeath',
              decedentMannerOfDeath: 'casePathology.mannerOfDeath',
              decedentDrugToxicityConfirmed: 'casePathology.drugToxicityConfirmed',
              decedentInfectiousDiseaseConfirmed: 'casePathology.infectiousDiseaseConfirmed',
              decedentOpiodConfirmed: 'casePathology.opioidConfirmed',
            }}
          />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <AttendingPhysicianView />
      </Grid>
    </Grid>
  );
}

function AttendingPhysicianView() {
  const formik = useFormikContext<any>();
  const { user } = useAuth();
  const authorizedToEdit =
    user?.roleCheck([
      'caef1f91-c392-4b53-a466-a8f418d8d25a', // Medical Examiner
      'd1582600-5f86-49dd-bab7-6f7205bfeffd', // Sys admin
      'e0556a35-b626-485d-9090-0d1a23abf38b', // Investigator
      '18c92818-6969-466b-a82b-c4817fdfacf4', // Data entry (HISTORIC-DATAENTRYONLY)
      '7bea8708-b1c8-4bf5-8fda-bc023453f072', // Admin assistant
    ]) ?? false;

  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={12} md={4}>
        <Paper sx={{ px: 2, py: 1, pb: 2 }} variant='outlined'>
          <PersonCard
            label='Attending MD'
            facilityLabel='Facility name'
            formikFields={{
              firstName: 'caseMAIDDetails.attendingPhysician.firstName',
              middleName: 'caseMAIDDetails.attendingPhysician.middleName',
              lastName: 'caseMAIDDetails.attendingPhysician.lastName',
              suffix: 'caseMAIDDetails.attendingPhysician.suffix',
              medicalLicenceNumber: 'caseMAIDDetails.attendingPhysician.licenseNo',
              facilityName: 'caseMAIDDetails.attendingPhysician.facilityName',
              baseAddressField: 'caseMAIDDetails.attendingPhysician.address[0]',
              address: {
                addressLine1: 'caseMAIDDetails.attendingPhysician.address[0].addressLine1',
                addressLine2: 'caseMAIDDetails.attendingPhysician.address[0].addressLine2',
                city: 'caseMAIDDetails.attendingPhysician.address[0].city',
                country: 'caseMAIDDetails.attendingPhysician.address[0].countrySeq',
                floor: 'caseMAIDDetails.attendingPhysician.address[0].apartmentNo',
                jurisdiction: 'caseMAIDDetails.attendingPhysician.address[0].countySeq',
                stateSeq: 'caseMAIDDetails.attendingPhysician.address[0].stateSeq',
                suite: 'caseMAIDDetails.attendingPhysician.address[0].suite',
                zip: 'caseMAIDDetails.attendingPhysician.address[0].zip',
              },
            }}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper sx={{ px: 2, py: 1, pb: 2 }} variant='outlined'>
          <PersonCard
            label='Consulting MD'
            facilityLabel='Facility name'
            formikFields={{
              firstName: 'caseMAIDDetails.consultingPhysician.firstName',
              middleName: 'caseMAIDDetails.consultingPhysician.middleName',
              lastName: 'caseMAIDDetails.consultingPhysician.lastName',
              suffix: 'caseMAIDDetails.consultingPhysician.suffix',
              medicalLicenceNumber: 'caseMAIDDetails.consultingPhysician.licenseNo',
              facilityName: 'caseMAIDDetails.consultingPhysician.facilityName',
              baseAddressField: 'caseMAIDDetails.consultingPhysician.address[0]',
              address: {
                addressLine1: 'caseMAIDDetails.consultingPhysician.address[0].addressLine1',
                addressLine2: 'caseMAIDDetails.consultingPhysician.address[0].addressLine2',
                city: 'caseMAIDDetails.consultingPhysician.address[0].city',
                country: 'caseMAIDDetails.consultingPhysician.address[0].countrySeq',
                floor: 'caseMAIDDetails.consultingPhysician.address[0].apartmentNo',
                jurisdiction: 'caseMAIDDetails.consultingPhysician.address[0].countySeq',
                stateSeq: 'caseMAIDDetails.consultingPhysician.address[0].stateSeq',
                suite: 'caseMAIDDetails.consultingPhysician.address[0].suite',
                zip: 'caseMAIDDetails.consultingPhysician.address[0].zip',
              },
            }}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper sx={{ px: 2, py: 1, pb: 2 }} variant='outlined'>
          <PersonCard
            label='MD Signing Death Certificate'
            facilityLabel='Facility name'
            formikFields={{
              firstName: 'caseMAIDDetails.dcSignedPhysician.firstName',
              middleName: 'caseMAIDDetails.dcSignedPhysician.middleName',
              lastName: 'caseMAIDDetails.dcSignedPhysician.lastName',
              suffix: 'caseMAIDDetails.dcSignedPhysician.suffix',
              medicalLicenceNumber: 'caseMAIDDetails.dcSignedPhysician.licenseNo',
              facilityName: 'caseMAIDDetails.dcSignedPhysician.facilityName',
              baseAddressField: 'caseMAIDDetails.dcSignedPhysician.address[0]',
              address: {
                addressLine1: 'caseMAIDDetails.dcSignedPhysician.address[0].addressLine1',
                addressLine2: 'caseMAIDDetails.dcSignedPhysician.address[0].addressLine2',
                city: 'caseMAIDDetails.dcSignedPhysician.address[0].city',
                country: 'caseMAIDDetails.dcSignedPhysician.address[0].countrySeq',
                floor: 'caseMAIDDetails.dcSignedPhysician.address[0].apartmentNo',
                jurisdiction: 'caseMAIDDetails.dcSignedPhysician.address[0].countySeq',
                stateSeq: 'caseMAIDDetails.dcSignedPhysician.address[0].stateSeq',
                suite: 'caseMAIDDetails.dcSignedPhysician.address[0].suite',
                zip: 'caseMAIDDetails.dcSignedPhysician.address[0].zip',
              },
            }}
          />
        </Paper>
      </Grid>
      {/* <Grid item xs={12} md={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 0, pb: 0 }} />
        <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
          <strong>Attending MD</strong>
        </Typography>
      </Grid>

      <Grid item xs={12} md={2}>
        <FormikTextField
          label='Last Name'
          formikField='caseMAIDDetails.attendingPhysician.lastName'
          authorizedToEdit={r => [
            r.Investigator,
            r.Medical_Examiner,
            r.Administrative_Assistant,
            r.Historic_Dataentryonly,
            r.Systems_Admin,
          ]}
        />
      </Grid>

      <Grid item xs={12} md={2}>
        <FormikTextField
          label='First Name'
          formikField='caseMAIDDetails.attendingPhysician.firstName'
          authorizedToEdit={r => [
            r.Investigator,
            r.Medical_Examiner,
            r.Administrative_Assistant,
            r.Historic_Dataentryonly,
            r.Systems_Admin,
          ]}
        />
      </Grid>

      <Grid item xs={12} md={0.6}>
        <FormikTextField
          label='Suffix'
          formikField='caseMAIDDetails.attendingPhysician.suffix'
          authorizedToEdit={r => [
            r.Investigator,
            r.Medical_Examiner,
            r.Administrative_Assistant,
            r.Historic_Dataentryonly,
            r.Systems_Admin,
          ]}
        />
      </Grid>

      <Grid item xs={12} md={2}>
        <FormikTextField
          label='Middle Name'
          formikField='caseMAIDDetails.attendingPhysician.middleName'
          authorizedToEdit={r => [
            r.Investigator,
            r.Medical_Examiner,
            r.Administrative_Assistant,
            r.Historic_Dataentryonly,
            r.Systems_Admin,
          ]}
        />
      </Grid>

      <Grid item xs={12} md={1}>
        <FormikTextField
          label={`MD License Number`}
          formikField='caseMAIDDetails.attendingPhysician.licenseNo'
          authorizedToEdit={r => [
            r.Investigator,
            r.Medical_Examiner,
            r.Administrative_Assistant,
            r.Historic_Dataentryonly,
            r.Systems_Admin,
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <Paper variant='elevation' elevation={0} sx={{ p: 2 }}>
          <Grid item xs={12} md={6} sx={{ mb: 2 }}>
            <FormikTextField
              label={`Attending MD Facility`}
              formikField='caseMAIDDetails.attendingPhysician.facilityName'
              authorizedToEdit={r => [
                r.Investigator,
                r.Medical_Examiner,
                r.Administrative_Assistant,
                r.Historic_Dataentryonly,
                r.Systems_Admin,
              ]}
              variant='standard'
            />
          </Grid>
          <AddressData
            isMultipleOn={false}
            handleChange={data => {
              formik.setFieldValue('caseMAIDDetails.attendingPhysician.address', data);
            }}
            addresses={formik.values?.caseMAIDDetails?.attendingPhysician?.address || []}
            title='Address'
            disabled={!formik.status.editing || !authorizedToEdit}
          />
        </Paper>
      </Grid> */}
    </Grid>
  );
}

function ConsultingPhysicianView() {
  const formik = useFormikContext<any>();
  const { user } = useAuth();
  const authorizedToEdit =
    user?.roleCheck([
      'caef1f91-c392-4b53-a466-a8f418d8d25a', // Medical Examiner
      'd1582600-5f86-49dd-bab7-6f7205bfeffd', // Sys admin
      'e0556a35-b626-485d-9090-0d1a23abf38b', // Investigator
      '18c92818-6969-466b-a82b-c4817fdfacf4', // Data entry (HISTORIC-DATAENTRYONLY)
      '7bea8708-b1c8-4bf5-8fda-bc023453f072', // Admin assistant
    ]) ?? false;

  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={12} md={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 0, pb: 0 }} />
        <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
          <strong>Consulting MD</strong>
        </Typography>
      </Grid>
    </Grid>
  );
}

function PhysicianSigningDCView() {
  const formik = useFormikContext<any>();
  const { user } = useAuth();
  const authorizedToEdit =
    user?.roleCheck([
      'caef1f91-c392-4b53-a466-a8f418d8d25a', // Medical Examiner
      'd1582600-5f86-49dd-bab7-6f7205bfeffd', // Sys admin
      'e0556a35-b626-485d-9090-0d1a23abf38b', // Investigator
      '18c92818-6969-466b-a82b-c4817fdfacf4', // Data entry (HISTORIC-DATAENTRYONLY)
      '7bea8708-b1c8-4bf5-8fda-bc023453f072', // Admin assistant
    ]) ?? false;

  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={12} md={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 0, pb: 0 }} />
        <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
          <strong>MD Signing Death Certificate</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} md={2}>
        <FormikTextField
          label='Last Name'
          formikField='caseMAIDDetails.dcSignedPhysician.lastName'
          authorizedToEdit={r => [
            r.Investigator,
            r.Medical_Examiner,
            r.Administrative_Assistant,
            r.Historic_Dataentryonly,
            r.Systems_Admin,
          ]}
        />
      </Grid>

      <Grid item xs={12} md={2}>
        <FormikTextField
          label='First Name'
          formikField='caseMAIDDetails.dcSignedPhysician.firstName'
          authorizedToEdit={r => [
            r.Investigator,
            r.Medical_Examiner,
            r.Administrative_Assistant,
            r.Historic_Dataentryonly,
            r.Systems_Admin,
          ]}
        />
      </Grid>

      <Grid item xs={12} md={0.6}>
        <FormikTextField
          label='Suffix'
          formikField='caseMAIDDetails.dcSignedPhysician.suffix'
          authorizedToEdit={r => [
            r.Investigator,
            r.Medical_Examiner,
            r.Administrative_Assistant,
            r.Historic_Dataentryonly,
            r.Systems_Admin,
          ]}
        />
      </Grid>

      <Grid item xs={12} md={2}>
        <FormikTextField
          label='Middle Name'
          formikField='caseMAIDDetails.dcSignedPhysician.middleName'
          authorizedToEdit={r => [
            r.Investigator,
            r.Medical_Examiner,
            r.Administrative_Assistant,
            r.Historic_Dataentryonly,
            r.Systems_Admin,
          ]}
        />
      </Grid>

      <Grid item xs={12} md={1}>
        <FormikTextField
          label={`MD License Number`}
          formikField='caseMAIDDetails.dcSignedPhysician.licenseNo'
          authorizedToEdit={r => [
            r.Investigator,
            r.Medical_Examiner,
            r.Administrative_Assistant,
            r.Historic_Dataentryonly,
            r.Systems_Admin,
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <Paper variant='elevation' elevation={0} sx={{ p: 2 }}>
          <Grid item xs={12} md={6} sx={{ mb: 2 }}>
            <FormikTextField
              label={`Signing MD Facility`}
              formikField='caseMAIDDetails.dcSignedPhysician.facilityName'
              authorizedToEdit={r => [
                r.Investigator,
                r.Medical_Examiner,
                r.Administrative_Assistant,
                r.Historic_Dataentryonly,
                r.Systems_Admin,
              ]}
              variant='standard'
            />
          </Grid>
          <AddressData
            isMultipleOn={false}
            handleChange={data => {
              formik.setFieldValue('caseMAIDDetails.dcSignedPhysician.address', data);
            }}
            addresses={formik.values?.caseMAIDDetails?.dcSignedPhysician?.address || []}
            title='Address'
            disabled={!formik.status.editing || !authorizedToEdit}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
