import { CancelRounded } from '@mui/icons-material';
import { Autocomplete, Box, Checkbox, Chip, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';

export default function SimpleAutocompleteCheckbox({
  xs,
  sm,
  md,
  lg,
  xl,
  options,
  label,
  multiple = false,
  onChange,
}) {
  const [selectedValue, setSelectedValue] = useState(multiple ? [] : null);

  const handleChange = (e, value) => {
    setSelectedValue(value);
    if (onChange) onChange(value);
  };

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <Box sx={{ position: 'relative', width: '100%' }}>
        <Autocomplete
          disableCloseOnSelect
          multiple={multiple}
          fullWidth
          options={options}
          value={selectedValue}
          onChange={handleChange}
          isOptionEqualToValue={(option, value) =>
            option?.optionSeq?.toUpperCase() === value?.optionSeq?.toUpperCase()
          }
          getOptionLabel={option => option?.optionName}
          renderInput={params => <TextField {...params} label={label} />}
          renderTags={(v, getTagProps) =>
            v.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                sx={{
                  height: 'auto',
                  '& .MuiChip-label': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                  '& .MuiChip-deleteIcon': {
                    fontSize: '16px',
                  },
                }}
                label={option.optionName}
                deleteIcon={<CancelRounded />}
              />
            ))
          }
          renderOption={(props, option, state) => (
            <li {...props}>
              <Checkbox
                icon={<MdCheckBoxOutlineBlank />}
                checkedIcon={<MdOutlineCheckBox />}
                style={{ marginRight: 8 }}
                checked={state.selected}
              />
              {option.optionName}
            </li>
          )}
        />
      </Box>
    </Grid>
  );
}
