import { NavigateFunction } from 'react-router';
import { AuthAction, AuthState } from '../reducers/authReducer';
import { UserAction, UserState } from '../reducers/userReducer';
import { MedexResponse, ResponseStatus } from '../../../types/MedexResponse.type';
import { User } from '../../../types/User';

// Extract the API URL from the environment variables
const { REACT_APP_API_URL } = process.env;

const has_override_placeholder = true;

/**
 * Hook for resetting user passwords.
 *
 * @param authDispatch Dispatch function for authentication-related actions.
 * @param authState The current authentication state.
 * @param userDispatch Dispatch function for user-related actions.
 * @param userState The current user state.
 * @param navigate Function to navigate to different routes.
 *
 * @return An object containing functions to send and verify the 2FA code.
 */
export function useResetPassword(
  authDispatch: React.Dispatch<AuthAction>,
  authState: AuthState,
  userDispatch: React.Dispatch<UserAction>,
  userState: UserState,
  navigate: NavigateFunction
) {
  /**
   * Function to handle step 1 of password reset process.
   * 1) Calling will request a code be sent to the current users primary 2FA method.
   * 2) Once the user receives the code, call verifyPasswordResetCode(code)
   * 3) Call changePassword(new_password) to change a users password and redirect them to the dashboard
   */
  const requestPasswordResetCode = async ({ username }: { username: string }) => {
    try {
      const endpoint = `${REACT_APP_API_URL}user/login/forgotpassword/requestcode`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userName: username }),
      });

      const { status, data, errorCode, message }: MedexResponse = await response.json();

      if (status === ResponseStatus.SUCCESS) {
        userDispatch({
          type: 'SET_USER',
          payload: new User(data),
        });
        navigate('/login/forgot-password/verify');
        return true;
      }

      if (status === ResponseStatus.FAIL) {
        authDispatch({
          type: 'SET_LOGIN_STATE',
          payload: {
            state: 'error',
            message: `${message || 'Unknown error'}`,
          },
        });
        return false;
      }

      return false;
    } catch (error) {
      authDispatch({
        type: 'SET_LOGIN_STATE',
        payload: {
          state: 'error',
          message: `${error || 'Unknown error'}`,
        },
      });

      return false;
    }
  };

  const verifyPasswordResetCode = async ({
    username,
    code,
    authToken,
  }: {
    username: string;
    code: string;
    authToken: string;
  }) => {
    try {
      if (!username) throw new Error('Username cannot be empty');
      if (!code) throw new Error('Code cannot be empty');
      if (!authToken) throw new Error('AuthToken cannot be empty');

      const endpoint = `${REACT_APP_API_URL}user/login/forgotpassword/verifycode`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userName: username, authToken, code }),
      });

      const { data, message, status, errorCode }: MedexResponse = await response.json();

      if (status === ResponseStatus.SUCCESS) {
        userDispatch({
          type: 'SET_USER',
          payload: data.user,
        });
        userDispatch({ type: 'SET_AUTH_TOKEN', payload: data.auth_token });
        authDispatch({ type: 'CLEAR_2FA_ERROR_MESSAGE' });
        authDispatch({ type: 'RESET_LOGIN_ERROR_MESSAGE' });
        authDispatch({ type: 'SET_AUTHENTICATED', payload: false });
        authDispatch({ type: 'SET_PASSED_2FA', payload: false });
        return true;
      }

      if (status === ResponseStatus.FAIL) {
        authDispatch({
          type: 'SET_LOGIN_STATE',
          payload: {
            state: 'error',
            message: `Error: ${message}`,
          },
        });
        return false;
      }

      return false;
    } catch (e) {
      authDispatch({
        type: 'SET_LOGIN_STATE',
        payload: {
          state: 'error',
          message: `Error: ${(e as Error).message}`,
        },
      });
      return false;
    }
  };

  const changePassword = async ({
    username,
    userseq,
    new_password,
    auth_token,
  }: {
    username: string;
    userseq: string;
    new_password: string;
    auth_token: string;
  }) => {
    try {
      if (!username) throw new Error('Username cannot be empty');
      if (!userseq) throw new Error('Userseq cannot be empty');
      if (!new_password) throw new Error('Password cannot be empty');
      if (!auth_token) throw new Error('Auth token cannot be empty');

      // Construct the login endpoint URL
      const endpoint = `${REACT_APP_API_URL}user/changepassword`;

      // Send a POST request to the request password code endpoint with the userid
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          UserName: username,
          AuthToken: auth_token,
          UserSeq: userseq,
          Password: new_password,
        }),
      });

      const { status, data, errorCode, message }: MedexResponse = await response.json();
      const user = data?.user;
      const access_token = data?.access_token;
      const refresh_token = data?.refresh_token;
      // assume state is success
      if (status === ResponseStatus.SUCCESS) {
        return new User({
          ...user,
          accessToken: access_token,
          token: access_token,
          refreshToken: refresh_token,
          authToken: data.auth_token,
        });
      } else {
        authDispatch({
          type: 'SET_LOGIN_STATE',
          payload: {
            state: 'error',
            message: message ?? 'Unknown error while resetting password',
          },
        });
        return null;
      }
    } catch (error) {
      authDispatch({
        type: 'SET_LOGIN_STATE',
        payload: {
          state: 'error',
          message: `${error}`,
        },
      });
      return null;
    }
  };
  /**
   * Function that when called, will force reset a users password to a random set of characters.
   * This is only for SYSTEMS-ADMINS.
   * This will fail if the calling user does not have the SYSTEMS-ADMIN role
   */
  const forceResetPassword = async ({ username }: { username: string }) => {
    try {
      const callingUser = userState.user;
      if (!callingUser) throw new Error('Calling user cannot be null');
      if (await callingUser.hasRole('SYSTEMS-ADMIN')) {
        // Check if user has an override in place that would prevent their password from being reset...
        if (has_override_placeholder) throw new Error(`${username}'s is not allowed to be reset`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { requestPasswordResetCode, verifyPasswordResetCode, changePassword, forceResetPassword };
}
