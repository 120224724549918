import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';

const StyledChip = styled(Chip)`
  ${({ theme }) => `
  cursor: pointer;
  transition: ${theme.transitions.create(['transform'], {
    duration: theme.transitions.duration.standard,
  })};
  &:hover {
    transform: scale(1.1);
  }
  `}
`;

export default function Chips({title, location}) {
  const navigate = useNavigate();

  const handleChicletClick = () => {
    navigate(location);
  };

  return (
    <div style={{ marginLeft: '1%', marginRight: '1%' }}>
      <StyledChip
        label={
          <Box>
            <Typography variant='h6' sx={{ color: 'white' }}>
              <b>{title}</b>
            </Typography>
          </Box>
        }
        sx={{
          background: 'black',
          height: 45,
          borderRadius: 30,
          padding: 1.5,
          display: { xs: 'none', sm: 'flex' },
        }}
        variant='outlined'
        onClick={handleChicletClick}
      />
      
    </div>
  );
}
