import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';

export default function BulkMessageView() {
  const { user } = useAuth();
  const [userProfile, setuserProfile] = useState([]);
  const [allRecipients, setAllRecipients] = useState([]);
  const [sendToAll, setSendToAll] = useState(false);
  const [messageRecipients, setMessageRecipients] = useState([]);
  const [messageSubject, setMessageSubject] = useState('');
  const [messageBody, setMessageBody] = useState('');
  const [messageSentAlert, setMessageSentAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setloading] = useState(false);

  const [sendingEmail, setSendingEmail] = useState(false);

  const { REACT_APP_API_URL } = process.env;

  const handleRecipientsChange = (
    event: React.SyntheticEvent<Element, Event>,
    data: ((prevState: never[]) => never[]) | (string | never[])[]
  ) => {
    // @ts-ignore
    setMessageRecipients(data);
  };

  const handleSendToAllChange = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setSendToAll(event.target.checked);

    if (event.target.checked) {
      setMessageRecipients(allRecipients);
    } else {
      setMessageRecipients([]);
    }
  };

  const handleSubjectChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setMessageSubject(event.target.value);
  };

  const handleBodyChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setMessageBody(event.target.value);
  };

  const dismissMessageSentAlert = () => {
    setMessageSentAlert(false);
  };

  useEffect(() => {
    if (user === null) return;

    fetch(REACT_APP_API_URL + 'user/getallactiveusers', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setAllRecipients(data);
      })
      .catch(e => {
        //alert(e);
      });
  }, [user]);

  const handleSendEmail = async event => {
    const emailData = {
      Subject: messageSubject,
      Body: messageBody,
      RecipientEmails: messageRecipients.map(recipient => recipient.primaryEmail),
    };

    try {
      setSendingEmail(true);
      const response = await fetch(REACT_APP_API_URL + 'sendemail', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });

      if (response.status === 401) {
        throw new Error('You are unauthorized to use this tool');
      } else if (!response.ok) {
        throw new Error('An error occurred');
      }

      const result = await response.json();

      if (result.FailedSends > 0) {
        setErrorMessage(
          `${result.FailedSends} out of ${result.TotalRecipients} emails failed to send.`
        );
        setErrorAlert(true);
      } else {
        setMessageSentAlert(true);
      }
    } catch (e) {
      setErrorMessage(e.message);
      setErrorAlert(true);
    } finally {
      setSendingEmail(false);
    }
  };

  const handleSendSMS = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (user === null) return;

    let formData = new FormData();

    // @ts-ignore
    formData.append('MESSAGEID', 5001);
    formData.append('SUBJECT', messageSubject);
    formData.append('BODY', messageBody);
    formData.append('RECIPIENTS', JSON.stringify(messageRecipients));

    setloading(true);

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'sendsms', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          // DO SOMETHING WITH RESPONSE
          setloading(false);
          setMessageSentAlert(true);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    setloading(false);
  };

  useEffect(() => {
    if (user === null) return;

    if (!userProfile.length) {
      // get user profile info
      let formData = new FormData();
      // @ts-ignore
      formData.append('userSeq', user !== null ? user.userSeq : null);
      fetch(REACT_APP_API_URL + 'user/getuserprofile', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You are unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          //Api returns UserProfile Structure
          setuserProfile(data);
        })
        .catch(e => {
          alert(e);
        });
    }
  }, [user]);

  return (
    <div>
      <Stack>
        <Typography variant='h5' component='h1'>
          <b>Bulk Messaging</b>
        </Typography>
        <Typography variant='subtitle1' color='gray'>
          Send E-Mail or Text (SMS) to Users
        </Typography>
      </Stack>
      <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
      <br />

      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Autocomplete
            freeSolo
            multiple
            fullWidth
            id='recipients-select'
            limitTags={3}
            options={allRecipients}
            value={messageRecipients}
            getOptionLabel={option =>
              // @ts-ignore
              option.personLastName + ', ' + option.personFirstName
            }
            onChange={(event, data) => handleRecipientsChange(event, data)}
            renderInput={params => (
              <TextField
                {...params}
                variant='standard'
                label='Recipients'
                placeholder='Recipients'
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            label='Select All Recipients'
            control={<Checkbox checked={sendToAll} onChange={handleSendToAllChange} />}
            style={{ marginTop: 10 }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id='subject'
            label='Subject'
            value={messageSubject}
            onChange={handleSubjectChange}
            variant='filled'
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            multiline
            placeholder='Body'
            value={messageBody}
            onChange={handleBodyChange}
            fullWidth
            variant='outlined'
          />
        </Grid>
      </Grid>

      <div>
        <hr />
        <Button
          disabled={sendingEmail}
          color='primary'
          variant='contained'
          size='large'
          onClick={handleSendEmail}
        >
          Send Bulk Email
        </Button>
        {/* <Button type='submit' color='success' onClick={handleSendSMS}>
          Send Bulk SMS
        </Button> */}
      </div>
      <Snackbar
        open={messageSentAlert}
        autoHideDuration={5000}
        onClose={() => setMessageSentAlert(false)}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert color='success' style={{ marginTop: 20 }}>
          Bulk email sent successfully.
        </Alert>
      </Snackbar>

      <Snackbar
        open={errorAlert}
        autoHideDuration={5000}
        onClose={() => setErrorAlert(false)}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert color='error' style={{ marginTop: 20 }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
