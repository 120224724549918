import { Autocomplete, TextField } from '@mui/material';
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import { useEffect, useRef, useState } from 'react';

function AutocompleteEditCellWithTextField({
  options,
  title,
  toggleTextfieldoption,
  textFieldText,
  rowField,
  ...otherProps
}) {
  const { id, value, field, hasFocus, colDef } = otherProps;
  const apiRef = useGridApiContext();
  const ref = useRef();
  const [localOptions, setLocalOptions] = useState([]);
  const [showTextField, setShowTextField] = useState(value?.optionName === toggleTextfieldoption);
  const [textFieldValue, setTextFieldValue] = useState(textFieldText ?? '');

  useEffect(() => {
    // Update localOptions when options change
    console.log('the local options? ', options);
    setLocalOptions(options);
  }, [options]);

  useEffect(() => {
    console.log('does this exist?: ', textFieldText);
    if (textFieldText) {
      setTextFieldValue(textFieldText);
    }
  }, [textFieldText]);

  const handleChange = (event, newValue) => {
    if (!apiRef.current) return;
    apiRef.current.setEditCellValue({ id, field, value: newValue });

    if (newValue?.optionName === toggleTextfieldoption) {
      setShowTextField(true);
    } else {
      setShowTextField(false);
    }
  };

  const handleTextFieldChange = async event => {
    const newValue = event.target.value;
    setTextFieldValue(event.target.value);
    try {
      // This is linked to another column (hopefully will be hidden)
      await apiRef.current.setEditCellValue({
        id,
        field: rowField,
        value: newValue,
      });

      const rowData = apiRef.current.getRow(id);
      console.log('Row Data?:', rowData);
    } catch (error) {
      console.error('Error updating cell value:', error);
    }
  };

  useEnhancedEffect(() => {
    if (hasFocus && ref.current) {
      const input = ref.current.querySelector(`input[value="${value}"]`);
      input?.focus();
    }
  }, [hasFocus, value]);

  return (
    // if you want the option to be right underneath, use the div with that style (its a bit squished if in a )
    // <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
    <>
      <Autocomplete
        options={localOptions}
        value={value}
        getOptionLabel={option => option.optionName}
        fullWidth
        onChange={handleChange}
        renderInput={params => (
          <TextField {...params} variant='standard' placeholder={colDef.headerName} />
        )}
      />
      {showTextField && (
        <TextField
          variant='standard'
          placeholder='Enter details'
          value={textFieldValue}
          onChange={handleTextFieldChange}
          fullWidth
          inputProps={{ maxLength: 30 }}
        />
      )}
      {/* </div> */}
    </>
  );
}

export const renderAutocompleteEditCellWithTextField = params => {
  const { colDef, textFieldText, rowField } = params; // textfieldtext is what you want the textfield to initially state
  const options = colDef.valueOptions ?? [];
  const toggleTextfieldoption = colDef.toggleTextfieldoption ?? ''; // the option to toggle textfield

  return (
    <AutocompleteEditCellWithTextField
      {...params}
      options={options}
      toggleTextfieldoption={toggleTextfieldoption}
      textFieldText={textFieldText}
    />
  );
};
