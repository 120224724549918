import React, { createContext, useContext, useState } from 'react';

interface CaseSearchContextProps {
  caseID: string;
  setCaseID: React.Dispatch<React.SetStateAction<string>>;
  caseIDFromCaseView: string;
  setCaseIDFromCaseView: React.Dispatch<React.SetStateAction<string>>;
}

// Create the context with default values
const CaseSearchContext = createContext<CaseSearchContextProps | undefined>(undefined);

export const CaseSearchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [caseID, setCaseID] = useState<string>('');
  const [caseIDFromCaseView, setCaseIDFromCaseView] = useState<string>('');

  return (
    <CaseSearchContext.Provider
      value={{ caseID, setCaseID, caseIDFromCaseView, setCaseIDFromCaseView }}
    >
      {children}
    </CaseSearchContext.Provider>
  );
};

export const useCaseSearch = (): CaseSearchContextProps => {
  const context = useContext(CaseSearchContext);
  if (!context) {
    throw new Error('useCaseSearch must be used within a CaseSearchProvider');
  }
  return context;
};
