import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
/** Weird bug relating to yup types */
// @ts-ignore
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { useAuth } from '../../../utils/auth/AuthService';

const validationSchema = yup.object({
  userid: yup.string().required('UserID is required'),
});

export function ForgotPasswordForm() {
  const auth = useAuth();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      userid: '',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      if (values.userid.length <= 0) return;

      setLoading(true);
      const codeSent = await auth.requestPasswordResetCode({
        username: values.userid,
      });

      if (codeSent) {
        navigate('/login/forgot-password/verify');
      }

      setLoading(false);
    },
  });

  const [loading, setLoading] = useState(false);
  const isEmpty = formik.values.userid.length <= 0;

  return (
    <>
      <Stack display='flex' flexDirection='column' alignItems='center' py={2}>
        <Typography variant='h5' fontWeight='bold'>
          Forgot password
        </Typography>
        <Typography variant='subtitle2' sx={{ opacity: '0.6' }}>
          Enter your UserID below to continue
        </Typography>

        {auth.login_state.state === 'error' && (
          <Typography variant='body2' color='error' my={1} textAlign='center'>
            <b>Error: </b>
            {auth.login_state.message ?? 'Unknown error'}
          </Typography>
        )}
      </Stack>
      <form onSubmit={formik.handleSubmit}>
        <div className='form-group login-input'>
          <TextField
            size='small'
            fullWidth
            id='userid'
            name='userid'
            label='UserID'
            value={formik.values.userid}
            onChange={formik.handleChange}
            error={formik.touched.userid && Boolean(formik.errors.userid)}
            helperText={formik.touched.userid && formik.errors.userid}
          />
        </div>
        <div className='form-group login-input'>
          <Button
            color='primary'
            disabled={loading || isEmpty}
            startIcon={loading && <CircularProgress sx={{ color: 'grey.200' }} size={20} />}
            sx={{
              fontWeight: 'bold',
            }}
            variant='contained'
            fullWidth
            type='submit'
          >
            {loading ? 'Loading' : 'Continue'}
          </Button>
        </div>
      </form>
    </>
  );
}
