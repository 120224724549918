import { NavigateFunction } from 'react-router';
import { AuthAction, AuthState } from '../reducers/authReducer';
import { UserAction, UserState } from '../reducers/userReducer';
import { MedexResponse, ResponseStatus } from '../../../types/MedexResponse.type';

// Extract the API URL from the environment variables
const { REACT_APP_API_URL } = process.env;

/**
 * Hook to handle sending and verifying 2-factor authentication (2FA) code via email.
 *
 * @param authDispatch Dispatch function for authentication-related actions.
 * @param authState Current authentication state.
 * @param userDispatch Dispatch function for user-related actions.
 * @param userState Current user state.
 * @param navigate Function to navigate to different routes.
 *
 * @return Object containing functions to send the 2FA code email and verify the code.
 */
export function useSendCodeEmail(
  authDispatch: React.Dispatch<AuthAction>,
  authState: AuthState,
  userDispatch: React.Dispatch<UserAction>,
  userState: UserState,
  navigate: NavigateFunction
) {
  /**
   * Function to send the 2FA code via email to the user.
   * - Checks if a user is logged in.
   * - Sends a request to the server to dispatch the 2FA code to the user's email.
   * - Updates the authentication state based on server response.
   */
  const sendEmail = async () => {
    try {
      // Check if there's a user logged in
      if (!userState.user) {
        authDispatch({ type: 'SET_PASSED_2FA', payload: false });
        throw new Error('No user is logged in!');
      }

      authDispatch({ type: 'CLEAR_2FA_ERROR_MESSAGE' });
      authDispatch({ type: 'SET_SELECTED_2FA_METHOD', payload: 'email' });
      authDispatch({
        type: 'SET_EMAIL_STATE',
        payload: {
          state: 'awaiting response',
        },
      });

      // Construct the 2FA endpoint URL
      const endpoint = `${REACT_APP_API_URL}user/login/2fa`;

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ auth_token: userState.user.token, method: 'email' }),
      });

      // Process server response
      const { status, data, errorCode, message }: MedexResponse = await response.json();

      if (status === ResponseStatus.SUCCESS) {
        authDispatch({
          type: 'SET_EMAIL_STATE',
          payload: {
            state: 'code sent to user',
          },
        });
      } else {
        authDispatch({
          type: 'SET_EMAIL_STATE',
          payload: {
            state: 'error',
            message: message ?? 'Could not send email',
          },
        });
      }
    } catch (error) {
      console.error(error);
      authDispatch({
        type: 'SET_EMAIL_STATE',
        payload: {
          state: 'error',
          message: `${error}`,
        },
      });
    }
  };

  /**
   * Function to verify the 2FA code sent to the user's email.
   * - Checks if a user is logged in.
   * - Sends the provided code to the server for verification.
   * - Updates user and authentication states based on server response.
   *
   * @param {Object} params User's input.
   * @param {string} params.code 2FA code input by the user.
   */
  const verifyEmail = async ({ code }: { code: string }) => {
    try {
      // Check if there's a user logged in
      if (!userState.user) {
        authDispatch({ type: 'SET_PASSED_2FA', payload: false });
        throw new Error('No user is logged in!');
      }

      // Construct the verification endpoint URL
      const endpoint = `${REACT_APP_API_URL}user/login/2fa/verify`;
      const body = {
        auth_token: userState.user.token,
        method: 'email',
        code,
      };
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });

      const { status, data, message, errorCode }: MedexResponse = await response.json();

      if (status === ResponseStatus.SUCCESS) {
        authDispatch({
          type: 'SET_EMAIL_STATE',
          payload: {
            state: 'success',
          },
        });
        authDispatch({ type: 'SET_AUTHENTICATED', payload: true });
        authDispatch({ type: 'SET_PASSED_2FA', payload: true });
        userDispatch({
          type: 'SET_USER',
          payload: data.user,
        });
        userDispatch({ type: 'SET_AUTH_TOKEN', payload: data.auth_token });
        userDispatch({ type: 'SET_ACCESS_TOKEN', payload: data.access_token });
        userDispatch({ type: 'SET_REFRESH_TOKEN', payload: data.refresh_token });

        authDispatch({ type: 'CLEAR_2FA_ERROR_MESSAGE' });
        authDispatch({ type: 'RESET_LOGIN_ERROR_MESSAGE' });
        navigate('/home');
      } else {
        authDispatch({
          type: 'SET_EMAIL_STATE',
          payload: {
            state: 'error',
            message: message ?? 'Could not verify code',
          },
        });
      }
    } catch (error) {
      console.error(error);
      authDispatch({
        type: 'SET_EMAIL_STATE',
        payload: {
          state: 'error',
          message: error ?? 'Unknown error while verifying code',
        },
      });
    }
  };

  // Return the sendEmail and verifyEmail functions from the hook
  return { sendEmail, verifyEmail };
}
