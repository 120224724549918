import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useEffect, useState } from 'react';
import * as jdxLogo from '../../assets';

const imageMapping = {
  Atlantic: jdxLogo.AtlanticLogo,
  Bergen: jdxLogo.BergenLogo,
  Burlington: jdxLogo.BurlingtonLogo,
  Camden: jdxLogo.CamdenLogo,
  CapeMay: jdxLogo.CapeMayLogo,
  Cumberland: jdxLogo.CumberlandLogo,
  Essex: jdxLogo.EssexLogo,
  Gloucester: jdxLogo.GloucesterLogo,
  Hudson: jdxLogo.HudsonLogo,
  Hunterdon: jdxLogo.HunterdonLogo,
  Mercer: jdxLogo.MercerLogo,
  Middlesex: jdxLogo.MiddlesexLogo,
  Monmouth: jdxLogo.MonmouthLogo,
  Morris: jdxLogo.MorrisLogo,
  Ocean: jdxLogo.OceanLogo,
  Passaic: jdxLogo.PassaicLogo,
  Salem: jdxLogo.SalemLogo,
  Somerset: jdxLogo.SomersetLogo,
  Sussex: jdxLogo.SussexLogo,
  Union: jdxLogo.UnionLogo,
  Warren: jdxLogo.WarrenLogo,
};

const BodyReleaseForm = ({ casedata }) => {
  const { REACT_APP_API_URL } = process.env;
  const [reportHeader, setreportHeader] = useState([]);
  const [funeralHomeInfo, setFuneralHomeInfo] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [isFetchComplete, setIsFetchComplete] = useState(false);

  const fetchReportHeader = async () => {
    const qryParams = {
      JdxSeq: casedata.caseSummary.jdxSeq,
    };

    const url = `${REACT_APP_API_URL}getreportheader?${new URLSearchParams(qryParams)}`;

    await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setreportHeader(data);
      })
      .catch(e => {
        alert(e);
      });
  };

  const fetchStateOptions = async () => {
    fetch(REACT_APP_API_URL + 'getstateoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setStateOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchFuneralHomeInfo = async () => {
    const qryParams = {
      CaseSeq: casedata.caseSummary.caseSeq,
    };

    const url = `${REACT_APP_API_URL}getfuneralhomeinfo?${new URLSearchParams(qryParams)}`;

    await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setFuneralHomeInfo(data);
      })
      .catch(e => {
        alert(e);
      })
      .finally(() => {
        setIsFetchComplete(true);
      });
  };

  useEffect(() => {
    fetchFuneralHomeInfo();
    fetchStateOptions();
    // fetchReportHeader();
  }, [casedata]);

  const generateAndOpenPDF = () => {
    try {
      const pdfDoc = new jsPDF();

      // Add content to the PDF using jsPDF methods
      pdfDoc.setFont('Arial', 'bold');
      pdfDoc.setFontSize(10);
      pdfDoc.setLineWidth(0.5);

      // Define the header text
      // const headerText = reportHeader[0]?.reportHeaderName + "\n" + reportHeader[0]?.reportAddress;
      // const headerLines = headerText.split('\n');
      const headerText = '';
      const headerLines = '00';

      // Calculate the starting position for the header text
      const center = pdfDoc.internal.pageSize.getWidth() / 2;

      // const headerLinePositions = [];
      // headerLines.forEach((line) => {
      // const lineWidth = pdfDoc.getTextWidth(line);
      // const lineCenter = center - lineWidth / 2;
      // headerLinePositions.push(lineCenter);
      // });

      // Draw the header text
      // headerLines.forEach((line, index) => {
      // pdfDoc.text(line, headerLinePositions[index], 15 + index * 6);
      // });

      // Add the logo in the top-left corner
      // const logoImage = imageMapping[casedata.caseSummary.caseJdx];
      // const logoWidth = 20; // Adjust the logo width
      // const logoHeight = 20; // Adjust the logo height
      // const logoStartX = 20; // Position from left
      // const logoStartY = 10; // Position from top
      // pdfDoc.addImage(logoImage, 'PNG', logoStartX, logoStartY, logoWidth, logoHeight);

      let maxHeaderWidth = 60;
      const padding = 2;

      // Calculate the starting position for the form elements
      const formX = 10;
      const logoBottom = 20 + headerLines.length * 6 + padding;
      const formY = logoBottom + padding; // Start the form elements below the logo

      //function to draw underlined value with fixed length and padding
      const drawField = (header, value, x, y, underlineLength) => {
        pdfDoc.setFont('Arial', 'normal');
        // Draw the header and value with padding
        pdfDoc.text(header, x + maxHeaderWidth, y, { align: 'right', direction: 'rtl' }); // Set text direction to "rtl"
        pdfDoc.setFont('Arial', 'bold');
        pdfDoc.text(value, x + maxHeaderWidth + padding, y); // Use the maximum header width here

        // Draw the underline only under the value
        pdfDoc.line(
          x + maxHeaderWidth + padding,
          y + 2,
          x + maxHeaderWidth + padding + underlineLength,
          y + 2
        );
      };

      const drawField2 = (header, value, x, y, underlineLength) => {
        // Draw the header and value with padding, starting at the position of the first field
        const headerWidth = 80;
        pdfDoc.setFont('Arial', 'normal');
        pdfDoc.text(header, x + maxHeaderWidth + headerWidth, y, {
          align: 'right',
          direction: 'rtl',
        }); // Set text direction to "rtl"
        pdfDoc.setFont('Arial', 'bold');
        pdfDoc.text(value, x + maxHeaderWidth + headerWidth + padding, y);

        // Draw the underline only under the value
        pdfDoc.line(
          x + maxHeaderWidth + headerWidth + padding,
          y + 2,
          x + maxHeaderWidth + headerWidth + padding + underlineLength,
          y + 2
        );
      };

      // Draw the main header text
      pdfDoc.setFontSize(14); // Increase the font size for main header
      const documentName = 'BODY RELEASE FORM';
      const documentNameWidth = pdfDoc.getTextWidth(documentName);
      const documentNameX = pdfDoc.internal.pageSize.getWidth() / 2 - documentNameWidth / 2;
      pdfDoc.text(documentName, documentNameX, formY);
      pdfDoc.line(documentNameX, formY + 3, documentNameX + documentNameWidth, formY + 3);
      pdfDoc.setFontSize(10); // Reset font size to normal size

      // If they are next of kin and are the person authorizing the release
      const authorizingReleasePerson = casedata?.caseDemographics?.informantList?.find(
        informant => {
          const informantSeq = informant?.personSeq?.toLowerCase() || '';
          const authorizingReleaseSeq = (
            casedata?.caseMortuary?.authorizingRelease || ''
          ).toLowerCase();

          return informantSeq === authorizingReleaseSeq;
        }
      );

      const formattedName = authorizingReleasePerson
        ? `${authorizingReleasePerson.personLastName}, ${authorizingReleasePerson.personFirstName}`
        : null;

      // console.log('the auth guy: ', authorizingReleasePerson);
      // console.log('the state options: ', stateOptions);

      const matchState = stateseq => {
        return stateOptions.find(option => option.optionSeq === stateseq);
      };

      const stateseqToFind = authorizingReleasePerson?.informantAddress?.address?.[0]?.stateSeq
        ? authorizingReleasePerson.informantAddress.address[0].stateSeq.toUpperCase()
        : '';

      const kinState = matchState(stateseqToFind);

      // Draw the fields with the desired format
      drawField(
        'Name of deceased:',
        casedata.caseSummary?.decedentInfo?.decedentName,
        formX,
        formY + 20,
        60
      );
      drawField2('Case No:', casedata.caseSummary.caseNumber, formX, formY + 20, 40);
      drawField(
        'Address:',
        casedata.caseSummary?.decedentInfo?.residence?.addressLine1 +
          ' ' +
          casedata.caseSummary?.decedentInfo?.residence?.addressLine2,
        formX,
        formY + 30,
        120
      );
      drawField(
        'City:',
        casedata.caseSummary?.decedentInfo?.residence?.cityTown || '',
        formX,
        formY + 40,
        40
      );
      drawField2(
        'State:',
        casedata.caseSummary?.decedentInfo?.residence?.state || '',
        formX,
        formY + 40,
        40
      );
      drawField(
        'This is to certify that I:',
        casedata.caseMortuary?.signedBy ?? '',
        formX,
        formY + 50,
        60
      );
      drawField(
        'Representing the funeral home of:',
        funeralHomeInfo[0]?.funeralHomeName ?? '',
        formX,
        formY + 60,
        60
      );
      drawField(
        'Address:',
        (funeralHomeInfo[0]?.addressLine1 ?? '') + ' ' + (funeralHomeInfo[0]?.addressLine2 ?? ''),
        formX,
        formY + 70,
        120
      );
      drawField('City:', funeralHomeInfo[0]?.city ?? '', formX, formY + 80, 40);
      drawField2('State:', funeralHomeInfo[0]?.stateName ?? '', formX, formY + 80, 40);
      drawField('Zip code:', funeralHomeInfo[0]?.zip ?? '', formX, formY + 90, 40);
      drawField2('Contact Info:', '', formX, formY + 90, 40);
      drawField('Has been authorized by: (Name)', formattedName ?? '', formX, formY + 100, 60);
      drawField(
        'Address:',
        authorizingReleasePerson?.informantAddress?.address?.[0]?.addressLine1 ?? '',
        formX,
        formY + 110,
        120
      );
      drawField(
        'City:',
        authorizingReleasePerson?.informantAddress?.address?.[0]?.city ?? '',
        formX,
        formY + 120,
        40
      );
      drawField2('State:', kinState?.optionName ?? '', formX, formY + 120, 40);
      drawField(
        'Zip code:',
        authorizingReleasePerson?.informantAddress?.address?.[0]?.zip ?? '',
        formX,
        formY + 130,
        40
      );
      drawField2(
        'Contact Info:',
        authorizingReleasePerson?.informantAddress?.contactItem?.[0]?.contactItemDetails ?? '',
        formX,
        formY + 130,
        40
      );
      drawField(
        'Having the relationship of:',
        authorizingReleasePerson?.relship?.optionName ?? '',
        formX,
        formY + 140,
        60
      );
      pdfDoc.setFont('Arial', 'normal');
      pdfDoc.text('to the deceased,', formX + maxHeaderWidth + 70 + padding, formY + 140);
      drawField(
        'To remove and to care for the body of:',
        casedata.caseSummary?.decedentInfo?.decedentName,
        formX,
        formY + 150,
        60
      );

      const paragraphText =
        'From the Office of the Medical Examiner for the purpose of funeral arrangements, embalming, shipping, cremation, burial, or other final means of disposition or personal postmortem care.';
      const paragraphLines = pdfDoc.splitTextToSize(paragraphText, 180);
      const paragraphX = formX + 5;
      const paragraphY = formY + 160;

      let maxLineWidth = 0;
      paragraphLines.forEach(line => {
        const lineWidth = pdfDoc.getTextWidth(line);
        if (lineWidth > maxLineWidth) {
          maxLineWidth = lineWidth;
        }
      });

      // Calculate the right boundary position
      const rightBoundaryX = paragraphX + maxLineWidth;

      // Check if the text exceeds the right boundary
      if (rightBoundaryX > formX + 200) {
        // Wrap the text to the next line if it exceeds the boundary
        paragraphLines.pop();
        pdfDoc.text('...continued on next line...', paragraphX, paragraphY + 3 * 6 + 2);
      }

      paragraphLines.forEach((line, index) => {
        if (index < 3) {
          // Limit the number of lines to 3, adjust as needed
          pdfDoc.text(line, paragraphX, paragraphY + index * 6);
        }
      });

      drawField('Signed:', '', formX, formY + 180, 60);
      drawField('Released by :', casedata.caseMortuary?.releasedBy || '', formX, formY + 190, 60);
      drawField('Witnessed by :', casedata.caseMortuary?.witnessedBy || '', formX, formY + 200, 60);
      drawField('Date:', new Date().toLocaleDateString(), formX, formY + 210, 40);
      drawField2('Time:', new Date().toLocaleTimeString(), formX, formY + 210, 40);
      drawField('EDRS No:', casedata?.caseCertification?.edrsNumber || '', formX, formY + 220, 40);

      const pdfBlob = pdfDoc.output('blob');
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, '_blank');
    } catch (error) {
      console.error('Error while generating or downloading PDF:', error);
    }
  };

  useEffect(() => {
    if (isFetchComplete) {
      generateAndOpenPDF();
    }
  }, [isFetchComplete, generateAndOpenPDF]);
};

export default BodyReleaseForm;
