/**
 * TO DO: userRoles, requiredRole interfaces
 * @param userJdxs
 * @param requiredJdx
 * @returns
 */
// @ts-ignore

export function jurisdictionCheck(userJdxs, requiredJdx) {
  if (!userJdxs) {
    return false;
  }
  var found = false;
  // @ts-ignore
  userJdxs.forEach((jdx) => {
    if (jdx.jdxCode == requiredJdx) {
      found = true;
    }
  });
  return found;
}
