import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LockIcon from '@mui/icons-material/Lock';

import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  FormHelperText,
  Select,
  MenuItem,
  TextField,
  IconButton,
} from '@mui/material';
import { Container } from '@mui/system';
import * as Yup from 'yup';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router';
import { saveAdminData } from '../../../../../../services/utility-api';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const id = '00000000-0000-0000-0000-000000000000';

const AddEdit = ({
  editObj,
  referenceMasterTableName,
  isCounty,
  maxOrder,
  setIsAddEditOn,
  getAllData,
  countryNames,
  row,
  referencePermissions,
}) => {
  const navigate = useNavigate();
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  useEffect(() => {}, [editObj]);

  const initialValues = {
    ...editObj,
    relshipNOKOrder: editObj?.relshipNOKOrder || '',
    relshipOrder: editObj?.relshipOrder ? Number(editObj.relshipOrder) : maxOrder + 10,
    isNOKRelship: editObj?.isNOKRelship?.toLowerCase() === 'true' ? true : false,
    relshipGender: editObj?.relshipGender || '',
    relshipInverseFemale: editObj?.relshipInverseFemale || '',
    relshipInverseMale: editObj?.relshipInverseMale || '',
    relshipName: editObj?.relshipName || '',
    relshipSubType: editObj?.relshipSubType || [],
  };

  const validationSchema = Yup.object({
    relshipNOKOrder: Yup.string().required('NOK Order is required').nullable(),
    relshipName: Yup.string().required('Relationship Type is required').nullable(),
    relshipGender: Yup.string().required('Sex is required').nullable(),
  });

  const onSave = async values => {
    try {
      const response = await saveAdminData({
        values: {
          ...values,
          isNOKRelship: values?.isNOKRelship ? 'True' : 'False',
          [referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq']:
            values[
              referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq'
            ] || id,
        },
        tableName: referenceMasterTableName,
      });
      if (response.status == 0) {
        setTimeout(() => {
          setIsAddEditOn(false);
          getAllData();
        }, 1700);
      }
      setDialogContents(response.message);
      setOpenApiDialogBox(true);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <Grid elevation={0} spacing={2} sx={{ padding: '16px', marginLeft: '-16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={10} mb={3} mt={1}>
            <Typography variant='h5'>
              <strong>{Object.keys(editObj).length ? 'Modify ' : 'Enter New '} RelationShip</strong>
            </Typography>
          </Grid>
        </Grid>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSave}>
          {({ handleSubmit, values, handleChange }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12} md={6} lg={6}>
                        <FormControl fullWidth error variant='standard'>
                          <TextField
                            required
                            focused
                            id='outlined-basic'
                            label={'N.O.K. Order'}
                            variant='outlined'
                            name='relshipNOKOrder'
                            value={values?.relshipNOKOrder}
                            onChange={handleChange}
                            disabled={
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Other' ||
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Unknown'
                            }
                          />
                          <ErrorMessage
                            name={'relshipNOKOrder'}
                            render={msg => <FormHelperText error>{msg}</FormHelperText>}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={6} xs={12} md={6} lg={6}>
                        <FormControl fullWidth error variant='standard'>
                          <TextField
                            required
                            focused
                            id='outlined-basic'
                            label={'Relationship Type'}
                            variant='outlined'
                            name='relshipName'
                            value={values?.relshipName ?? ''}
                            onChange={handleChange}
                            disabled={
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Other' ||
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Unknown'
                            }
                          />
                          <ErrorMessage
                            name={'relshipName'}
                            render={msg => <FormHelperText error>{msg}</FormHelperText>}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={6} xs={12} md={6} log={6} mt={2}>
                        <FormControl fullWidth>
                          <InputLabel id='relshipGenderLabel' required>
                            Sex
                          </InputLabel>
                          <Select
                            labelId='relshipGenderLabel'
                            id='relshipGender'
                            name='relshipGender'
                            value={values?.relshipGender}
                            label='Sex'
                            onChange={handleChange}
                            disabled={
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Other' ||
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Unknown'
                            }
                          >
                            <MenuItem value='M'>Male</MenuItem>
                            <MenuItem value='F'>Female</MenuItem>
                            <MenuItem value='T'>Transgend</MenuItem>
                            <MenuItem value='U'>Unknown</MenuItem>
                          </Select>
                          <ErrorMessage
                            name={'relshipGender'}
                            render={msg => <FormHelperText error>{msg}</FormHelperText>}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={6} xs={12} md={6} lg={6}>
                        <FormControl error fullWidth variant='standard'>
                          <TextField
                            focused
                            required
                            id='relshipOrder'
                            label='List Order'
                            variant='outlined'
                            name='relshipOrder'
                            margin='normal'
                            size='large'
                            value={values?.relshipOrder}
                            onChange={handleChange}
                            disabled={
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Other' ||
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Unknown'
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item sm={6} xs={12} md={6} lg={6}>
                        <FormControl error fullWidth variant='standard'>
                          <TextField
                            focused
                            id='relshipInverseMale'
                            label='Inverse Male'
                            variant='outlined'
                            name='relshipInverseMale'
                            margin='normal'
                            size='large'
                            value={values?.relshipInverseMale ?? ''}
                            onChange={handleChange}
                            disabled={
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Other' ||
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Unknown'
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={6} xs={12} md={6} lg={6}>
                        <FormControl error fullWidth variant='standard'>
                          <TextField
                            focused
                            id='relshipInverseFemale'
                            label='Inverse Female'
                            variant='outlined'
                            name='relshipInverseFemale'
                            margin='normal'
                            size='large'
                            value={values?.relshipInverseFemale}
                            onChange={handleChange}
                            disabled={
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Other' ||
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Unknown'
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item sm={6} xs={12} md={6} lg={6}>
                        <FormGroup>
                          <FormControlLabel
                            name={'isNOKRelship'}
                            checked={values?.isNOKRelship}
                            control={<Checkbox />}
                            onClick={e => {
                              handleChange({
                                target: {
                                  name: 'isNOKRelship',
                                  value: e.target.checked, // !values?.isNOKRelship,
                                },
                              });
                            }}
                            disabled={
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Other' ||
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Unknown'
                            }
                            label='N.O.K. Relationship'
                          />
                        </FormGroup>
                      </Grid>
                      <Grid container spacing={2} m={2}>
                        <Grid item xs={12}>
                          <Button
                            onClick={e => {
                              handleChange({
                                target: {
                                  name: 'relshipSubType',
                                  value: [
                                    ...values.relshipSubType,
                                    {
                                      relshipSubTypeSeq: id,
                                      relshipSeq: values?.relshipSeq || id,
                                      relshipSubTypeName: '',
                                      relshipSubTypeOrder: '',
                                      status: 'Active',
                                    },
                                  ],
                                },
                              });
                            }}
                            disabled={
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Other' ||
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Unknown'
                            }
                            variant='contained'
                            startIcon={<AddCircleIcon />}
                          >
                            Add Sub Type
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          {values?.relshipSubType && values?.relshipSubType?.length ? (
                            <List
                              sx={{
                                bgcolor: 'rgb(2, 136, 209)',
                                py: '0px',
                                color: '#fff',
                              }}
                            >
                              <ListItem>
                                <Grid container spacing={2}>
                                  <Grid
                                    xs={5}
                                    item
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      position: 'relative',
                                      left: '-16px',
                                    }}
                                  >
                                    <Typography variant='h5' mt={0} sx={{ color: '#fff' }}>
                                      Sub Type
                                    </Typography>
                                    <Divider
                                      sx={{
                                        position: 'absolute',
                                        right: '-10px',
                                      }}
                                    >
                                      |
                                    </Divider>
                                  </Grid>
                                  <Grid
                                    xs={5}
                                    item
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      position: 'relative',
                                      left: '-16px',
                                    }}
                                  >
                                    <Typography variant='h5' mt={0} sx={{ color: '#fff' }}>
                                      Sub-Type Order
                                    </Typography>
                                    <Divider
                                      sx={{
                                        position: 'absolute',
                                        right: '-10px',
                                      }}
                                    >
                                      |
                                    </Divider>
                                  </Grid>
                                </Grid>
                              </ListItem>
                            </List>
                          ) : null}

                          <List sx={{ paddingTop: '0px' }}>
                            {values?.relshipSubType.map((item, index) => (
                              <ListItem
                                key={index}
                                id={index}
                                sx={{
                                  py: '0px',
                                  marginBottom: '5px',
                                  backgroundColor: '#f3f4f4',
                                  display: item.status == 'Active' ? '' : 'none',
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid
                                    xs={5}
                                    item
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      position: 'relative',
                                      left: '-16px',
                                    }}
                                  >
                                    <FormControl error variant='standard' sx={{ width: '90%' }}>
                                      <TextField
                                        focused
                                        required
                                        label='Sub Type'
                                        variant='outlined'
                                        margin='normal'
                                        size='large'
                                        id={`relshipSubType.${index}.relshipSubTypeName`}
                                        name={`relshipSubType.${index}.relshipSubTypeName`}
                                        value={
                                          values
                                            ? values['relshipSubType'][index]['relshipSubTypeName']
                                            : ''
                                        }
                                        onChange={e => {
                                          handleChange({
                                            target: {
                                              name: e.target.name,
                                              value: e.target.value,
                                            },
                                          });
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    xs={5}
                                    item
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      position: 'relative',
                                      left: '-16px',
                                    }}
                                  >
                                    <FormControl error variant='standard' sx={{ width: '90%' }}>
                                      <TextField
                                        focused
                                        required
                                        type='number'
                                        label='Order'
                                        variant='outlined'
                                        margin='normal'
                                        size='large'
                                        id={`relshipSubType.${index}.relshipSubTypeOrder`}
                                        name={`relshipSubType.${index}.relshipSubTypeOrder`}
                                        value={
                                          values
                                            ? values['relshipSubType'][index]['relshipSubTypeOrder']
                                            : ''
                                        }
                                        onChange={e => {
                                          handleChange({
                                            target: {
                                              name: e.target.name,
                                              value: e.target.value,
                                            },
                                          });
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    xs={2}
                                    item
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      position: 'relative',
                                    }}
                                  >
                                    <Button
                                      onClick={() => {
                                        handleChange({
                                          target: {
                                            name: 'relshipSubType',
                                            value:
                                              item.relshipSubTypeSeq == id
                                                ? values.relshipSubType.filter(
                                                    (_, ind) => ind != index
                                                  )
                                                : values.relshipSubType.map((item, ind) => {
                                                    if (ind == index) {
                                                      return {
                                                        ...item,
                                                        status: 'InActive',
                                                      };
                                                    }
                                                    return item;
                                                  }),
                                          },
                                        });
                                        return;
                                      }}
                                      aria-label='delete'
                                      size='large'
                                      id={`row_id_${index}`}
                                      sx={{
                                        backgroundColor: 'rgb(2, 136, 209)',
                                      }}
                                    >
                                      <DeleteIcon fontSize='inherit' sx={{ color: '#fff' }} />
                                    </Button>
                                  </Grid>
                                </Grid>
                              </ListItem>
                            ))}
                          </List>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Button
                              type='submit'
                              variant='contained'
                              sx={{ minWidth: '125px', marginTop: '7px' }}
                              onClick={handleSubmit}
                              disabled={!referencePermissions?.add}
                            >
                              {Object.keys(editObj) > 1 ? 'Update' : 'Save'}
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>

        <div className='modal'>
          <Dialog
            open={openApiDialogBox}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenApiDialogBox(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                {dialogContents}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <IconButton onClick={() => setOpenApiDialogBox(false)}>
                <CloseIcon />
              </IconButton>
            </DialogActions>
          </Dialog>
        </div>
      </Grid>
    </>
  );
};
export default AddEdit;
