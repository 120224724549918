import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormControlLabel, Grid, TextField, Select, MenuItem, InputLabel } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import FormControl from '@mui/material/FormControl';

const Filter = ({ rows, setRowsToShow, county, statusFilter, setStatusFilter, setNameFilter, setCountyFilter }) => {
    const onFilter = (values) => {
        setStatusFilter(values?.isActive);
        setNameFilter(values.locationName);
        setCountyFilter(values.county);
        let newRows = rows;
        if (values.locationName) {
            newRows = newRows.filter((row) => row['locationName'].toLowerCase().includes(values.locationName.toLowerCase()));
        }
        if (values.county) {
            newRows = newRows.filter((row) => row.locationOnCountySeqs.includes(values.county));
        }
        if (values?.isActive == 'all') setRowsToShow(newRows);
        else if (values?.isActive == 'Active') setRowsToShow(newRows.filter((row) => row['status'].toLowerCase() == 'active'));
        else setRowsToShow(newRows.filter((row) => row['status'].toLowerCase() != 'active'));
    };

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <>
            <Formik
                initialValues={{
                    isActive: statusFilter
                }}
                validationSchema={Yup.object().shape({
                    isActive: Yup.string().nullable()
                })}
                onSubmit={onFilter}
            >
                {({ errors, handleBlur, handleSubmit, isSubmitting, touched, values, handleChange }) => (
                    <>
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={2}>
                                <Grid item xs={12} md={3} lg={3}>
                                <FormControl variant="standard" fullWidth>
                                <TextField
                                    sx={{ marginTop: '0px' }}
                                    id="locationName"
                                    label="Location Name"
                                    variant="outlined"
                                    name="locationName"
                                    defaultValue=""
                                    margin="normal"
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleSubmit();
                                    }}
                                    value={values.locationName}
                                />
                                </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="countyLabel">Jurisdiction</InputLabel>
                                    <Select
                                        // sx={{ minWidth: '220px' }}
                                        labelId="countyLabel"
                                        id="countySelect"
                                        name="county"
                                        value={values.county}
                                        label="Jurisdiction"
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleSubmit();
                                        }}
                                    >
                                        <MenuItem value={''} key={'all'}>
                                            --Select One--
                                        </MenuItem>
                                        {Object.keys(county).map((row) => {
                                            return (
                                                <MenuItem
                                                    value={county[row].countySeq}
                                                    key={county[row].countySeq}
                                                    sx={{
                                                        marginLeft:
                                                            county[row].countyName == 'New Jersey' ||
                                                                county[row].countyName == 'NJ State Case' ||
                                                                county[row].countyName == 'MAiD Case'
                                                                ? ''
                                                                : '15px'
                                                    }}
                                                >
                                                    {county[row].countyName}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                <RadioGroup
                                    sx={{ display: 'inline' }}
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="isActive"
                                    value={values.isActive}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleSubmit();
                                    }}
                                    defaultValue="Active"
                                >
                                    <FormControlLabel value="Active" control={<Radio />} label="Active Only" />{' '}
                                    <FormControlLabel value="Inactive" control={<Radio />} label="Inactive Only" />{' '}
                                    <FormControlLabel value="all" control={<Radio />} label="All" />{' '}
                                </RadioGroup>
                                </Grid>
                           </Grid>
                        </form>
                    </>
                )}
            </Formik>
        </>
    );
};
export default Filter;
