import React from 'react';
import HL7Uploader from './ToolsModules/hl7uploaderView';

export default function ToolsView() {
  return (
    <div style={{ 
      height: '75vh', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'flex-start', 
      backgroundColor: '#282c34', 
      color: 'white', 
      fontSize: '10px', 
      fontFamily: 'Arial, sans-serif',
      paddingTop: '20px'  // Adjust this value as needed for spacing from the top
    }}>
      <HL7Uploader />
    </div>
  );
}
