import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineProps,
  TimelineSeparator,
  timelineOppositeContentClasses,
} from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { LegacyCaseActivityLogRecord } from './livery.api';

dayjs.extend(relativeTime);

interface CaseActivityLogTimelineProps extends TimelineProps {
  caseActivityLogRecords: LegacyCaseActivityLogRecord[];
}

export function CaseActivityLogTimeline(props: CaseActivityLogTimelineProps) {
  const { caseActivityLogRecords = [] } = props;

  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
      }}
    >
      {[...caseActivityLogRecords]
        .sort((a, b) => {
          const timeA = a.createdOn ? dayjs(a.createdOn).valueOf() : 0;
          const timeB = b.createdOn ? dayjs(b.createdOn).valueOf() : 0;
          return timeB - timeA;
        })
        .map((record, index) => {
          const isLatest = index === 0;
          const dotColor = isLatest ? 'success' : 'grey';

          return (
            <TimelineItem key={record.caseActivityLogSeq}>
              <TimelineOppositeContent color='textSecondary'>
                {record.createdOn ? (
                  <>
                    {dayjs(record.createdOn).format('ddd, MMM D, YYYY').toUpperCase()}
                    <br />
                    {dayjs(record.createdOn).format('h:mm A')}
                  </>
                ) : (
                  'N/A'
                )}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  color={dotColor}
                  variant={isLatest ? 'filled' : 'outlined'}
                  sx={{
                    animation: isLatest ? 'pulse 2s ease-in-out infinite' : 'none',
                    '@keyframes pulse': {
                      '0%': {
                        transform: 'scale(1)',
                        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.2)',
                      },
                      '70%': {
                        transform: 'scale(1.2)',
                        boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)',
                      },
                      '100%': {
                        transform: 'scale(1)',
                        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                      },
                    },
                  }}
                />
                {index !== caseActivityLogRecords.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                {isLatest ? (
                  <Stack direction='row' gap={2}>
                    <Typography fontWeight='bold' color='text.primary'>
                      {record.activity?.activityName}
                    </Typography>
                    <Typography color='text.secondary'>
                      {record.createdOn ? formatShortTimeDistance(dayjs(record.createdOn)) : 'N/A'}
                    </Typography>
                  </Stack>
                ) : (
                  <Typography fontWeight='bold' color='text.secondary'>
                    {record.activity?.activityName}
                  </Typography>
                )}

                <Typography variant='caption' color='text.secondary'>
                  {/* @ts-ignore */}
                  Logged by {record.loggedByUsername}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          );
        })}
    </Timeline>
  );
}

export function formatShortTimeDistance(date: dayjs.Dayjs | null) {
  if (!date) return 'N/A';

  const distance = date.fromNow();
  return distance
    .replace('minutes', 'm')
    .replace('minute', 'm')
    .replace('hours', 'h')
    .replace('hour', 'h')
    .replace('days', 'd')
    .replace('day', 'd')
    .replace('weeks', 'w')
    .replace('week', 'w')
    .replace('months', 'mo')
    .replace('month', 'mo')
    .replace('years', 'y')
    .replace('year', 'y')
    .replace('about ', '')
    .replace('less than ', '<')
    .replace('almost ', '~')
    .replace('over ', '>')
    .replace(/(\d+) /g, '$1'); // Remove the space between the number and the unit
}
