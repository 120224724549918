import {
  Box,
  ButtonBase,
  ButtonBaseProps,
  Checkbox,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

export interface CheckboxIconButtonProps extends ButtonBaseProps {
  icon: React.ReactNode;
  label: string;
  checked: boolean;
  disabled?: boolean;
  loading?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function CheckboxIconButton({
  icon,
  label,
  checked,
  disabled = false,
  loading = false,
  onClick,
  sx,
  ...rest
}: CheckboxIconButtonProps) {
  const theme = useTheme();

  return (
    <ButtonBase
      onClick={onClick}
      disabled={disabled}
      sx={[
        {
          width: 300,
          pt: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: theme.transitions.duration.short,
          }),
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
          '& .MuiCheckbox-root': {
            padding: theme.spacing(1),
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      <Stack alignItems='center'>
        <Box component='div' sx={{ mb: 1 }}>
          {icon}
        </Box>
        <Typography variant='subtitle2' sx={{ mb: 1 }}>
          {label}
        </Typography>

        <Checkbox
          checked={checked}
          disabled={disabled}
          icon={loading ? <CircularProgress size={24} /> : undefined}
          checkedIcon={loading ? <CircularProgress size={24} /> : undefined}
        />
      </Stack>
    </ButtonBase>
  );
}
