import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';

interface ForceCaseUnlockDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  discardCaseEdits: () => {};
}

export default function ForceCaseUnlockDialog({
  open,
  setOpen,
  discardCaseEdits,
}: ForceCaseUnlockDialogProps) {
  const [loadingUnlock, setLoadingUnlock] = useState(false);

  const handleUnlockCase = async () => {
    setLoadingUnlock(true);
    await discardCaseEdits();
    setLoadingUnlock(false);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          console.log('Form Submitted');
          setOpen(false);
        },
      }}
    >
      <DialogTitle>Force Unlock This Case</DialogTitle>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <DialogContentText>
          <Typography>
            Choosing to unlock will forcably remove any user from editing this case, losing any
            unsaved changes they have. Are you sure you want to do this?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <LoadingButton loading={loadingUnlock} onClick={handleUnlockCase} color='warning'>
          <span>Unlock</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
