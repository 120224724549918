import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CaseViewOptionsAutocomplete } from './CaseViewOptionsAutocomplete';

interface Props {
  contacts: any[];
  handleChange: (updatedContacts: any[]) => void;
  disabled: boolean;
  onAddNewDefaultContact?: (newContact: any) => void;
}

export const ContactsViewNew: React.FC<Props> = ({
  contacts = [],
  handleChange,
  disabled,
  onAddNewDefaultContact,
}) => {
  const [open, setOpen] = useState(false);
  const [contactToDelete, setContactToDelete] = useState<number | null>(null);

  const createNewContact = () => ({
    // contactItemSeq: uuidv4(),
    contactItemType: null,
    contactItemDetails: '',
  });

  const formatPhoneNumber = (value: string): string => {
    const cleaned = ('' + value).replace(/\D/g, '').slice(0, 10);
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return value;
  };

  const isValidEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const isValidInput = (value: string, type: string) => {
    if (!value) return true;
    if (type === 'Email') {
      return isValidEmail(value);
    } else {
      return /^\(\d{3}\) \d{3}-\d{4}$/.test(formatPhoneNumber(value));
    }
  };

  const handleAddContact = () => {
    let makeNew = true;
    contacts?.forEach(contact => {
      if (
        !contact?.contactItemType ||
        !contact?.contactItemDetails ||
        contact?.contactItemDetails === ''
      ) {
        makeNew = false;
      }
    });
    if (!makeNew) {
      return;
    }

    const newContact = createNewContact();
    handleChange([...contacts, newContact]);
  };

  const handleDeleteContact = (index: number) => {
    const contact = contacts[index];
    if (contact.contactItemSeq) {
      setContactToDelete(index);
      setOpen(true);
    } else {
      deleteContact(index);
    }
  };

  const deleteContact = (index: number) => {
    const updatedContacts = contacts.filter((_, i) => i !== index);
    handleChange(updatedContacts);
    setOpen(false);
  };

  const handleChangeContact = (index: number, field: string, value: string) => {
    const newContacts = contacts?.map((contact, i) => {
      if (i === index) {
        const newContact = { ...contact, [field]: value };
        if (field === 'contactItemDetails' && contact?.contactItemType?.optionName !== 'Email') {
          newContact.contactItemDetails = formatPhoneNumber(value);
        }
        return newContact;
      }
      return contact;
    });
    handleChange(newContacts);
  };

  useEffect(() => {
    if (!contacts || contacts.length === 0) {
      const newContact = createNewContact();
      if (typeof onAddNewDefaultContact !== undefined) {
        onAddNewDefaultContact(newContact);
      }
    } else {
      const formattedContacts = contacts.map(contact => {
        let contactItemDetails = contact.contactItemDetails;
        if (contact?.contactItemType?.optionName !== 'Email') {
          contactItemDetails = formatPhoneNumber(contactItemDetails);
        }
        return { ...contact, contactItemDetails };
      });
      handleChange(formattedContacts);
    }
  }, []);

  const renderHelperTextLabel = (contact: any) => {
    return ' ';
  };

  return (
    <Box component={'div'}>
      {(contacts?.length > 0 ? contacts : [createNewContact()]).map((contact, index) => (
        <Grid container spacing={1} key={index} style={{ marginTop: 0 }}>
          <Grid item xs={12} sm={5} md={5}>
            <CaseViewOptionsAutocomplete
              label='Contact via'
              optionsEndpoint='getcontactitemtypeoptions'
              formikField=''
              disabled={disabled}
              value={contact?.contactItemType || null}
              onOptionChange={(newOption: any) => {
                handleChangeContact(index, 'contactItemType', newOption);
              }}
              textFieldProps={{
                variant: 'standard',
              }}
            />
          </Grid>
          <Grid item xs={10} sm={5} md={5}>
            <TextField
              label={<></>}
              value={contact?.contactItemDetails || ''}
              onChange={e => handleChangeContact(index, 'contactItemDetails', e.target.value)}
              fullWidth
              disabled={disabled}
              error={
                !isValidInput(contact?.contactItemDetails, contact?.contactItemType?.optionName)
              }
              helperText={
                !isValidInput(contact?.contactItemDetails, contact?.contactItemType?.optionName)
                  ? contact?.contactItemType?.optionName !== 'Email'
                    ? 'Invalid contact number'
                    : 'Invalid email'
                  : renderHelperTextLabel(contact)
              }
              variant='standard'
            />
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Stack direction='row' alignItems='center' justifyContent='center' ml={1}>
              {contacts?.length > 1 && (
                <Tooltip title='Delete Contact'>
                  <IconButton disabled={disabled} onClick={() => handleDeleteContact(index)}>
                    <RemoveCircleOutline />
                  </IconButton>
                </Tooltip>
              )}
              {index === contacts?.length - 1 && (
                <Tooltip title='Add New Contact'>
                  <IconButton disabled={disabled} onClick={handleAddContact}>
                    <AddCircleOutline />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Grid>
        </Grid>
      ))}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Confirm Delete'}</DialogTitle>
        <DialogContent>Are you sure you want to delete this contact?</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color='primary'>
            No
          </Button>
          {contactToDelete !== null && (
            <Button onClick={() => deleteContact(contactToDelete)} color='primary' autoFocus>
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};