import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Grid } from '@mui/material';
import Button from '@mui/material/Button';

import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../../../utils/auth/AuthService';
import SiloDataGrid from '../../../utils/components/SiloDataGrid';

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  RadioGroup,
  Radio,
  Stack,
  Typography,
  Divider,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from '@mui/material';

export default function AppSettingsView() {
  const { user } = useAuth();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [appSettingsData, setAppSettingsData] = useState([]);
  const [initialAppSettingsData, setInitialAppSettingsData] = useState([]);
  const { REACT_APP_API_URL } = process.env;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editedRows, setEditedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saveSuccessfullAlertVisible, setSaverequiredAlertVisible] = useState(false);
  const [errorAlertVisible, setErrorAlertVisible] = useState(false);

  const initialFormValues = {
    globalAppSettingsSeq: '',
    key: '',
    value: '',
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    onSubmit: values => {
      const newRow = {
        globalAppSettingsSeq: values.globalAppSettingsSeq || uuidv4(),
        key: values.key,
        value: values.value,
      };

      const keyExists = appSettingsData.some(
        row => row.globalAppSettingsSeq !== values.globalAppSettingsSeq && row.key === values.key
      );
      if (keyExists) {
        setErrorAlertVisible(true); // Display an error message if the key already exists
        return;
      }

      if (values.globalAppSettingsSeq) {
        // Editing existing row
        const newEditedData = editedRows.map(row =>
          row.globalAppSettingsSeq === newRow.globalAppSettingsSeq ? newRow : row
        );

        if (!newEditedData.some(row => row.globalAppSettingsSeq === newRow.globalAppSettingsSeq)) {
          newEditedData.push(newRow);
        }

        setEditedRows(newEditedData);

        const newData = appSettingsData.map(row =>
          row.globalAppSettingsSeq === newRow.globalAppSettingsSeq ? newRow : row
        );
        setAppSettingsData(newData);
      } else {
        // Adding new row
        const newRows = [...editedRows, newRow];
        setEditedRows(newRows);

        const newData = [...appSettingsData, newRow];
        setAppSettingsData(newData);
      }

      setIsDialogOpen(false);
    },
  });

  const columns = [
    // {
    //     field: 'globalAppSettingsSeq',
    //     headerName: 'GlobalAppSettingsSeq',
    //     flex: 1,
    //     editable: false,
    // },
    {
      field: 'key',
      headerName: 'Key',
      flex: 1,
      editable: false,
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 2,
      editable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: params => {
        return (
          <div>
            <Button
              onClick={e => onEditClick(e, params.row)}
              size='small'
              color='primary'
              style={{ justifyContent: 'left' }}
              startIcon={<EditIcon />}
            ></Button>
            <Button
              onClick={e => onDeleteClick(e, params.row)}
              size='small'
              color='error'
              hidden='true' //For now hide the delete functionality.
              style={{ justifyContent: 'left' }}
              startIcon={<DeleteIcon />}
            ></Button>
          </div>
        );
      },
    },
  ];

  const dismissSaveAlert = () => {
    setSaverequiredAlertVisible(false);
    setErrorAlertVisible(false);
  };

  const onAddClick = () => {
    formik.resetForm();
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const onEditClick = (e, row) => {
    formik.setValues({
      globalAppSettingsSeq: row.globalAppSettingsSeq,
      key: row.key,
      value: row.value,
    });

    setIsDialogOpen(true);
  };

  const onDeleteClick = (e, row) => {
    setShowConfirmation(true);
    setItemToDelete(row);
  };

  const handleConfirmDelete = () => {
    setShowConfirmation(false);
    const updatedData = appSettingsData.filter(
      row => row.globalAppSettingsSeq !== itemToDelete.globalAppSettingsSeq
    );
    setAppSettingsData(updatedData);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const fetchAppSettingsData = async () => {
    if (user === null) return;
    setLoading(true);
    await fetch(REACT_APP_API_URL + 'GlobalAppSettings/getallglobalappsettings', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setAppSettingsData(data);
        setInitialAppSettingsData(data);
        setLoading(false);
      })
      .catch(e => {
        alert(e);
      });
  };

  const saveAppSettingsData = async () => {
    if (user === null) return;
    await fetch(REACT_APP_API_URL + 'GlobalAppSettings/saveglobalappsettings', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(editedRows),
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setAppSettingsData(data);
        setInitialAppSettingsData(data);
        setEditedRows([]);
        setSaverequiredAlertVisible(true);
      })
      .catch(e => {
        alert(e);
      });
  };

  useEffect(() => {
    fetchAppSettingsData();
  }, []);

  const ConfirmationModal = () => {
    return (
      <Dialog
        open={showConfirmation}
        onClose={handleCancelDelete}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>{'Confirm Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this row?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDelete} autoFocus>
            Yes
          </Button>
          <Button autoFocus onClick={handleCancelDelete}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div>
      <Stack>
        <Typography variant='h5' component='h1'>
          <b>App Settings</b>
        </Typography>
        <Typography variant='subtitle1' color='gray'>
          Configure settings related to two factor settings and storage locations
        </Typography>
      </Stack>
      <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
      <br />

      <div>
        <Alert
          style={{ marginTop: -15 }}
          color='info'
          isOpen={saveSuccessfullAlertVisible}
          toggle={dismissSaveAlert}
        ></Alert>
      </div>
      <Box
        sx={{
          position: 'relative',
          top: -15,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <Grid item xs={12} lg={6}>
          <SiloDataGrid
            loading={loading}
            listresults={appSettingsData}
            columnsInput={columns}
            idcolumn='globalAppSettingsSeq'
            gridheight={300}
            gridcolor='#a61930'
            title='<strong>Authorized Use only: </strong>
            Changing global settings affects the stability of the ENTIRE system.<br/>
            Please carefully verify the correct folder directories before changing them.'
            tooltip='This datagrid shows the global app settings'
            rowsperpage={50}
          />
        </Grid>
      </Box>
      <Button
        onClick={onAddClick}
        style={{
          color: '#fff',
          backgroundColor: '#1b6ec2',
          marginRight: '1rem',
        }}
      >
        Add
      </Button>
      <Button
        type='submit'
        onClick={saveAppSettingsData}
        style={{ color: '#fff', backgroundColor: '#1b6ec2' }}
      >
        Save
      </Button>
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Add/Edit Settings</DialogTitle>

        <DialogContent>
          <Alert color='danger' isOpen={errorAlertVisible} toggle={dismissSaveAlert}>
            Key already exists.
          </Alert>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              label='Key'
              name='key'
              type='text'
              size='small'
              value={formik.values.key}
              onChange={formik.handleChange}
              error={formik.touched.key && Boolean(formik.errors.key)}
              helperText={formik.touched.key && formik.errors.key}
              disabled={initialAppSettingsData.some(
                row => row.globalAppSettingsSeq === formik.values.globalAppSettingsSeq
              )}
              fullWidth
              style={{ marginTop: '1rem', marginBottom: '1rem' }}
            />

            <TextField
              label='Value'
              name='value'
              type='text'
              size='small'
              value={formik.values.value}
              onChange={formik.handleChange}
              error={formik.touched.value && Boolean(formik.errors.value)}
              helperText={formik.touched.value && formik.errors.value}
              fullWidth
              style={{ marginBottom: '1rem' }}
            />

            <DialogActions>
              <Button type='submit' style={{ color: '#fff', backgroundColor: '#1b6ec2' }}>
                {formik.values.globalAppSettingsSeq ? 'Update' : 'Add'}
              </Button>
              <Button onClick={handleDialogClose}>Cancel</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <ConfirmationModal />
    </div>
  );
}
