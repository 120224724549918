import { Box, Divider, Paper, Stack, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';

export default function PathologyHUD() {
  const formik = useFormikContext();
  const theme = useTheme();

  return (
    <div>
      <Box
        component={Paper}
        elevation={0}
        sx={{ p: 0, m: 0, backgroundColor: theme.palette.mode === 'light' ? '#e3f2fd' : '#213a52' }}
      >
        <Stack spacing={0.5} sx={{ pt: 0.5 }}>
          <Grid container justifyContent='space-evenly' spacing={0.5}>
            <Grid item xs='auto'>
              <Stack direction='row' spacing={0.5}>
                <Typography variant='body1' sx={{ color: 'gray', fontSize: '0.9rem' }}>
                  Exam Type:
                </Typography>
                <Typography variant='body1' sx={{ fontSize: '0.9rem' }}>
                  {formik.values?.caseMortuary?.examtype?.optionName ?? '--'}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs='auto'>
              <Stack direction='row' spacing={0.5}>
                <Typography variant='body1' sx={{ color: 'gray', fontSize: '0.9rem' }}>
                  Exam Started:
                </Typography>
                <Typography variant='body1' sx={{ fontSize: '0.9rem' }}>
                  {formik.values?.caseMortuary?.examStartDateTime != null
                    ? new dayjs(formik.values?.caseMortuary?.examStartDateTime).format(
                        'MM/DD/YYYY HH:mm'
                      )
                    : '--'}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs='auto'>
              <Stack direction='row' spacing={0.5}>
                <Typography variant='body1' sx={{ color: 'gray', fontSize: '0.9rem' }}>
                  Exam Ended:
                </Typography>
                <Typography variant='body1' sx={{ fontSize: '0.9rem' }}>
                  {formik.values?.caseMortuary?.examEndDateTime != null
                    ? new dayjs(formik.values?.caseMortuary?.examEndDateTime).format(
                        'MM/DD/YYYY HH:mm'
                      )
                    : '--'}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs='auto'>
              <Stack direction='row' spacing={0.5}>
                <Typography variant='body1' sx={{ color: 'gray', fontSize: '0.9rem' }}>
                  Forensic Technicians:
                </Typography>
                {formik.values?.caseMortuary?.morgueTech != null ? (
                  <Stack>
                    {formik.values?.caseMortuary?.morgueTech?.map(morgueTech => (
                      <Typography variant='body1' sx={{ fontSize: '0.9rem' }}>
                        {morgueTech.optionName}
                      </Typography>
                    ))}
                  </Stack>
                ) : (
                  <Typography variant='body1' sx={{ fontSize: '0.9rem' }}>
                    --
                  </Typography>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Divider sx={{ opacity: 1, mt: 1, mb: 2 }} />
      </Box>
    </div>
  );
}
