import {
  AddCircleOutlineRounded,
  ArrowBackIosNewRounded,
  DeleteRounded,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../utils/auth/AuthService';
import { ModelAsset } from '../../../../utils/components/ModelViewer/types/ModelAsset.interface';
import { GLTFModel } from './GltfModel';
import { UploadModelDialog } from './UploadModelDialog';
import { ModelRecordWithFiles, ModelViewerAPI } from './modelviewer.api';

const AnimatedBox = motion(Box);
const AnimatedStack = motion(Stack);
const AnimatedTypography = motion(Typography);

export default function ResourcesModelViewer() {
  const theme = useTheme();
  const [animationCompleted, setAnimationCompleted] = useState(false);

  const [models, setModels] = useState<ModelRecordWithFiles[]>([]);
  const [selected, setSelected] = useState<Partial<ModelRecordWithFiles> | null>(null);
  const [open, setOpen] = useState(false);
  const [loadingModels, setLoadingModels] = useState(false);
  const { user } = useAuth();

  const handleOnDelete = async (modelSeq: string, confirmed: boolean) => {
    if (confirmed) {
      try {
        const response = await ModelViewerAPI.deleteModelRecord({
          accessToken: user?.accessToken!,
          modelSeq: modelSeq,
        });

        if (response.ok) {
          console.log('Model deleted');
          fetchModels();
        } else {
          const errorData = await response.json();
          console.log(errorData);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchModels = async () => {
    setLoadingModels(true);
    try {
      const models = await ModelViewerAPI.getModels({
        accessToken: user?.accessToken!,
        includeThumbnail: true,
        mimeTypes: ['model/gltf+json', 'model/gltf-binary'],
      });
      setModels(models);
      setLoadingModels(false);
    } catch (e) {
      setLoadingModels(false);
    }
  };

  useEffect(() => {
    if (models.length <= 0) {
      fetchModels();
    }
  }, []);

  return (
    <Stack p={0} m={0}>
      {user?.roleCheck(['d1582600-5f86-49dd-bab7-6f7205bfeffd']) && (
        <>
          <Button
            variant='text'
            startIcon={<AddCircleOutlineRounded />}
            onClick={() => setOpen(true)}
            sx={{ mb: 1 }}
          >
            Upload New 3D Model
          </Button>

          <UploadModelDialog open={open} setOpen={setOpen} fetchModels={fetchModels} />
        </>
      )}

      {!loadingModels && models.length === 0 && (
        <Typography variant='body2'>No models found</Typography>
      )}

      <LayoutGroup>
        <Grid container spacing={2}>
          {loadingModels &&
            Array.from({ length: 5 }).map((_, index) => (
              <Grid item key={index}>
                <ModelSkeleton />
              </Grid>
            ))}

          {models.map(m => {
            const isFullscreen = m.modelSeq === selected?.modelSeq;
            const gltfModelFileRecord =
              m.modelFiles.find(
                file => file.modelFileMimeType === 'model/gltf+json' || 'model/gltf-binary'
              ) ?? null;
            return (
              <Grid item p={0} m={0} key={m.modelSeq}>
                <AnimatedBox
                  layout
                  whileHover={{ scale: isFullscreen ? undefined : 1.05 }}
                  transition={{ type: 'spring', bounce: 0, delayChildren: 2, staggerChildren: 0.1 }}
                  position={isFullscreen ? 'fixed' : 'relative'}
                  top={isFullscreen ? 60 : undefined}
                  right={isFullscreen ? 0 : undefined}
                  left={isFullscreen ? 0 : undefined}
                  bottom={isFullscreen ? 0 : undefined}
                  width={isFullscreen ? '100%' : '300px'}
                  height={isFullscreen ? 'calc(100% - 60)' : '300px'} // Calculate height to extend from the top position to the bottom of the viewport
                  zIndex={isFullscreen ? 1000 : 1}
                  borderRadius={isFullscreen ? 0 : '12px'}
                  bgcolor={
                    isFullscreen ? theme.palette.background.default : theme.palette.background.paper
                  }
                  style={{
                    cursor: isFullscreen ? undefined : 'pointer',
                  }}
                  component='div'
                  onClick={e => {
                    e.stopPropagation();
                    setSelected(m);
                    if (!isFullscreen) {
                      setAnimationCompleted(false);
                    }
                  }}
                  onLayoutAnimationComplete={() => {
                    setAnimationCompleted(true);
                  }}
                >
                  <AnimatePresence>
                    {!isFullscreen && (
                      <motion.img
                        src={m.thumbnailUrl}
                        alt='Model Thumbnail'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        style={{
                          position: 'absolute',
                          top: '60px',
                          left: '50%',
                          transform: 'translateX(-50%)',
                          width: '100%',
                          height: 'calc(100% - 80px)',
                          objectFit: 'contain',
                          borderRadius: '0 0 12px 12px',
                          zIndex: 2,
                          display: isFullscreen ? 'hidden' : undefined,
                        }}
                      />
                    )}
                  </AnimatePresence>

                  <motion.div layout style={{ position: 'relative', zIndex: 3 }}>
                    <ModelHeader
                      model={m}
                      fullscreen={isFullscreen}
                      setSelected={setSelected}
                      setAnimationComplete={setAnimationCompleted}
                    />
                  </motion.div>

                  {isFullscreen && animationCompleted && gltfModelFileRecord && (
                    <GLTFModel
                      key={m.modelSeq}
                      modelSeq={m.modelSeq}
                      modelUrl={gltfModelFileRecord.url}
                    />
                  )}
                  {user?.roleCheck(['d1582600-5f86-49dd-bab7-6f7205bfeffd']) && (
                    <DeleteButton
                      onClick={confirmed => {
                        handleOnDelete(m.modelSeq, confirmed);
                      }}
                    />
                  )}
                </AnimatedBox>
              </Grid>
            );
          })}
        </Grid>
      </LayoutGroup>
    </Stack>
  );
}

function ModelHeader({
  model,
  fullscreen,
  setSelected,
  setAnimationComplete,
}: {
  model: Partial<ModelRecordWithFiles>;
  fullscreen: boolean;
  setSelected: React.Dispatch<React.SetStateAction<Partial<ModelAsset> | null>>;
  setAnimationComplete: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const theme = useTheme();

  function handleClose(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();

    setSelected(null);
    setAnimationComplete(true);
  }

  return (
    <AnimatedStack
      layout
      style={{
        backgroundColor: fullscreen ? undefined : 'transparent',
        zIndex: 10,
      }}
    >
      <AnimatedStack
        spacing={1}
        px={2}
        pt={2}
        style={{
          backgroundColor: fullscreen ? undefined : 'transparent',
        }}
      >
        {fullscreen && (
          <Button
            sx={{ width: 'max-content' }}
            variant='text'
            onClick={e => handleClose(e)}
            startIcon={<ArrowBackIosNewRounded />}
          >
            Close Viewer
          </Button>
        )}
        <Box component='div' width={fullscreen ? undefined : '100%'} overflow='hidden'>
          <AnimatedTypography
            layout='preserve-aspect'
            variant={fullscreen ? 'h5' : 'h6'}
            fontWeight={fullscreen ? 'bold' : undefined}
            noWrap
            textOverflow='ellipsis'
            sx={{
              color: theme.palette.text.primary,
            }}
          >
            {fullscreen ? `Viewing ${model.modelName}` : `${model.modelName}`}
          </AnimatedTypography>
        </Box>
        <Divider component='hr' variant='fullWidth' />
      </AnimatedStack>
    </AnimatedStack>
  );
}

function DeleteButton({ onClick }: { onClick: (confirmed: boolean) => void }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = (confirmed: boolean) => {
    setOpen(false);
    onClick(confirmed);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          position: 'absolute',
          bottom: '16px',
          right: '16px',
          zIndex: 1001,
          color: theme.palette.grey[500],
        }}
      >
        <DeleteRounded />
      </IconButton>
      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>Delete Model</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this model? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color='primary'>
            No
          </Button>
          <Button onClick={() => handleClose(true)} color='primary' autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function ModelSkeleton() {
  const theme = useTheme();
  return (
    <Box
      component='div'
      width='300px'
      height='300px'
      borderRadius='12px'
      bgcolor={theme.palette.background.default}
    >
      <Skeleton variant='rectangular' width='100%' height='100%' sx={{ borderRadius: '12px' }} />
    </Box>
  );
}
