import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  Paper,
  Slide,
  Stack,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useNavigate } from 'react-router';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

function chatContent(numSuspected, numConfirmed, data, totalCases) {
  let suspectedRatio = numSuspected;
  let confirmedRatio = numConfirmed;
  var percentage = null;

  if (data == 'Confirmed') {
    percentage = (
      <p>
        <b>{numSuspected} </b> ({Math.round((numSuspected / totalCases) * 10000) / 100}%) of these
        were also inititally suspected. <br /> You can export the detailed records to CSV below:{' '}
      </p>
    );
  } else if (data == 'Suspected') {
    percentage = (
      <p>Percent also confirmed: {Math.round((numConfirmed / totalCases) * 10000) / 100}%</p>
    );
  } else {
    percentage = (
      <p>
        The Ratio of suspected to confirmed is{' '}
        <b>
          {suspectedRatio}:{confirmedRatio}
        </b>
      </p>
    );
  }

  return (
    <Paper
      elevation={3}
      sx={{
        p: '10px',
        px: '20px',
        pt: 3,
        borderRadius: '20px',
        width: 'max-content',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        I searched the database and found <b>{totalCases}</b> cases.
        <i style={{ fontSize: 14 }}>{percentage}</i>
      </div>
    </Paper>
  );
}

export default function DrugToxicity() {
  const { user } = useAuth();
  const [loading, setloading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const [numSuspected, setNumSuspected] = useState(0);
  const [numConfirmed, setNumConfirmed] = useState(0);
  const [totalCases, setTotalCases] = useState(0);
  const [drugTox, setDrugTox] = useState('Confirmed');
  const [data, setData] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [year, setYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [requiredAlertVisible, setRequiredAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [drugToxAlertVisible, setDrugToxAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const navigate = useNavigate();
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [selectedJdxs, setSelectedJdxs] = useState([]);
  const [RTCasesMapVisible, setRTCasesMapVisible] = useState(false);
  const [RTCasesMapData, setRTCasesMapData] = useState([]);
  const { REACT_APP_API_URL } = process.env;
  const theme = useTheme();

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const columns = [
    {
      field: 'caseid',
      headerName: 'Case ID',
      width: 100,
      editable: false,
      headerAlign: 'center',
      renderCell: params => {
        return (
          <NavLink
            //variant="contained"
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
              //goToCMSClassic(event, params);
            }}
          >
            {params.formattedValue}
          </NavLink>
        );
      },
    },
    {
      field: 'menotified',
      headerName: 'Call Taken',
      width: 200,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'blzip',
      headerName: 'BLZip',
      width: 90,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'drugtoxicity',
      headerName: 'Susp/OD',
      width: 110,
      editable: false,
      headerAlign: 'left',
    },

    {
      field: 'drugtoxicityconfirmed',
      headerName: 'Confirmed/OD',
      width: 110,
      editable: false,
      headerAlign: 'left',
    },

    {
      field: 'age',
      headerName: 'Age',
      width: 80,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'race',
      headerName: 'Race',
      width: 80,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'gender',
      headerName: 'Gender',
      width: 80,
      editable: false,
      headerAlign: 'left',
    },

    {
      field: 'jurisdictionname',
      headerName: 'Jurisdiction',
      width: 100,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'mannerofdeath',
      headerName: 'Manner of Death',
      width: 125,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'causeofdeath',
      headerName: 'Cause of Death',
      width: 210,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'toxnotes',
      headerName: 'Tox Notes',
      width: 280,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 60,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'casestatus',
      headerName: 'Case Status',
      width: 90,
      editable: false,
      headerAlign: 'left',
    },
  ];

  const drugToxOptions = ['Suspected', 'Confirmed', 'All'];

  const handleJdxChange = (event, data) => {
    setSelectedJdxs(data);
  };

  const handleDrugToxChange = (event, data) => {
    setDrugTox(data);
  };

  const handleStartDateChange = event => {
    if (event.target.value != '') {
      setStartDate(event.target.value);
    } else {
      setStartDate('');
    }
  };

  const handleEndDateChange = event => {
    if (event.target.value != '') {
      setEndDate(event.target.value);
    } else {
      setEndDate('');
    }
  };

  const handleYearChange = (event, data) => {
    setYear(data);
  };

  const dismissDateAlert = () => {
    setRequiredAlertVisible(false);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  const dismissDrugToxAlert = () => {
    setDrugToxAlertVisible(false);
  };

  const clearAll = () => {
    setStartDate('');
    setEndDate('');
    setDrugTox('Confirmed');
    setYear(null);
    setSelectedJdxs([]);
    setResultsVisible(false);
  };

  //gather search params from form and FETCH/POST to API
  // 6/27/2023 added map call to happen on submit
  const handleSubmit = async event => {
    event.preventDefault();

    if (year == null && (startDate == '' || endDate == '')) {
      setRequiredAlertVisible(true);
      return;
    }

    if (year == '') {
      let startDateArr = startDate.split('-');
      let endDateArr = endDate.split('-');

      let startDateValue = new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]);
      let endDateValue = new Date(endDateArr[0], endDateArr[1] - 1, endDateArr[2]);

      if (endDateValue - startDateValue > 31536000000) {
        setRequiredAlertVisible(true);
        return;
      }
    }

    if (drugTox != 'Suspected' && drugTox != 'Confirmed' && drugTox != 'All') {
      setDrugToxAlertVisible(true);
      return;
    }

    if (drugTox == 'Suspected') {
      setData('Suspected');
    } else if (drugTox == 'Confirmed') {
      setData('Confirmed');
    } else {
      setData('All');
    }

    if (selectedJdxs.length == 0) {
      setJdxAlertVisible(true);
      return;
    }

    let formData = new FormData();

    setloading(true);
    setResultsVisible(true);

    formData.append('SEARCHFROMDATE', startDate);
    formData.append('SEARCHTODATE', endDate);
    formData.append('CASEYEAR', year);
    formData.append('DRUGTOXICITY', drugTox);
    formData.append('SELECTEDJDXLIST', JSON.stringify(selectedJdxs));

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'DrugToxicity', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setlistresults(data.tableresults);
          setNumSuspected(data.numsuspected);
          setNumConfirmed(data.numconfirmed);
          setTotalCases(data.totalcases);
          setloading(false);
          setRTCasesMapVisible(true);
          setRTCasesMapData(data.tableresults);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }
    setloading(false);
  };

  useEffect(() => {
    if (user === null) return;

    const fetchYear = async () => {
      try {
        const currentYear = new Date().getFullYear();
        setYear(currentYear);
        setYearOptions([...Array(16).keys()].map(i => i + (currentYear - 15)).reverse());
      } catch (e) {
        alert(e);
      }
    };

    fetchYear();
    setAuthorizedJdxs(user.jdxAccessList);
    setSelectedJdxs(user.jdxAccessList);
  }, [user]);

  return (
    <Card
      raised
      sx={{
        my: 3,
        mx: 'auto',
        width: { xs: '95%', lg: '75%' },
      }}
    >
      <CardHeader
        title='Drug Toxicity Activity'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
        action={
          <Tooltip
            sx={{ color: theme.palette.text.primary }}
            title='This Report Tracks the number of cases that are Suspected/Confirmed to be drug overdose cases, and includes the percentage of the confirmed cases were initially suspected to be so'
            placement='right-end'
          >
            <InfoIcon />
          </Tooltip>
        }
      />

      <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

      <CardContent>
        <Slide
          direction='right'
          in={requiredAlertVisible}
          mountOnEnter
          unmountOnExit
          sx={{ mb: 2 }}
        >
          <Alert severity='warning' onClose={dismissDateAlert}>
            Call taken range must be within 1 year or year dropdown must be set.
          </Alert>
        </Slide>

        <Slide direction='right' in={jdxAlertVisible} mountOnEnter unmountOnExit sx={{ mb: 2 }}>
          <Alert severity='warning' onClose={dismissJdxAlert}>
            Please select at least 1 jurisdiction.
          </Alert>
        </Slide>

        <Slide direction='right' in={drugToxAlertVisible} mountOnEnter unmountOnExit sx={{ mb: 2 }}>
          <Alert severity='warning' onClose={dismissDrugToxAlert}>
            Please select a drug toxicity status.
          </Alert>
        </Slide>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id='selectedJdxs'
              options={authorizedJdxs}
              value={selectedJdxs}
              disableCloseOnSelect
              limitTags={4}
              fullWidth
              size='small'
              getOptionLabel={option => option.jdxName}
              onChange={(event, data) => handleJdxChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.jdxName}
                </li>
              )}
              renderInput={params => (
                <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
              )}
              ListboxComponent={'ul'}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Autocomplete
              id='year'
              options={yearOptions}
              value={year}
              size='small'
              getOptionLabel={option => option.toString()}
              onChange={(event, data) => handleYearChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.toString()}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => <TextField {...params} label='Year' placeholder='Year' />}
              ListboxComponent={'ul'}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id='searchfromdate'
              name='searchfromdate'
              label='Call Taken From'
              type='date'
              size='small'
              value={startDate}
              onChange={handleStartDateChange}
              disabled={year}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id='searchtodate'
              name='searchtodate'
              label='Call Taken To'
              type='date'
              size='small'
              value={endDate}
              onChange={handleEndDateChange}
              disabled={year}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              id='toxstatus'
              size='small'
              options={drugToxOptions}
              value={drugTox}
              getOptionLabel={option => option}
              onChange={(event, data) => handleDrugToxChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Drug Toxicity Status'
                  placeholder='Drug Toxicity Status'
                />
              )}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 2, opacity: 1 }} />

        <Stack
          direction='row'
          sx={{
            width: '100%',
            justifyContent: 'right',
          }}
          spacing={2}
        >
          <Button type='submit' variant='contained' color='primary' onClick={handleSubmit}>
            Calculate
          </Button>

          <Button color='secondary' variant='outlined' onClick={clearAll}>
            Clear
          </Button>
        </Stack>

        <Collapse in={resultsVisible} sx={{ mt: resultsVisible ? 4 : 0 }}>
          <ReportDataGrid
            loading={loading}
            listresults={listresults}
            idcolumn='caseid'
            columnsInput={columns}
            title='Drug Toxicity & OD Activity Cases'
            casecount
            chatcontent={chatContent(numSuspected, numConfirmed, data, totalCases)}
          />
        </Collapse>
      </CardContent>
    </Card>
  );
}

//<RTCasesMap cases={data.tableresults} />
