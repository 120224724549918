import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  FormGroup,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Container } from '@mui/system';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
// import { gridSpacing } from 'redux/store/constant';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import { referenceTypeFieldLengthChecks } from '../../referenceTypeFieldLengthChecks';
import { saveAdminData } from '../../../../../../services/utility-api';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const AddEdit = ({
  editObj,
  isCode,
  isCounty,
  referenceMasterTableName,
  maxOrder,
  setIsAddEditOn,
  getAllData,
  countyNames,
  reasonTypes,
  referencePermissions,
}) => {
    console.log('editObj', editObj);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  useEffect(() => {}, [editObj]);

  const onSave = async values => {
    const id = '00000000-0000-0000-0000-000000000000';
    // make api call to save add data
    try {
      const response = await saveAdminData({
        values: {
          ...values,
          [referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq']:
            values[
              referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq'
            ] || id,
        },
        tableName: referenceMasterTableName,
      });
      if (response.status == 0) {
        setTimeout(() => {
          setIsAddEditOn(false);
          getAllData();
        }, 1700);
      }
      setDialogContents(response.message);
      setOpenApiDialogBox(true);
      // close add edit part
      await getAllData();
      setIsAddEditOn(false);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={10} mb={2} mt={2}>
          <Typography variant='h5'>
          <strong>
            {Object.keys(editObj).length ? 'Modify ' : 'Enter New '}{' '}
            {referenceMasterTableName
              .split('')
              .map(alpha => {
                if (alpha <= 'Z') return ' ' + alpha;
                return alpha;
              })
              .join('')
              .trim()}
            </strong>
          </Typography>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          ...editObj,
          reasonDetailName: editObj['reasonDetailName'],
          reasonTypeSeq: editObj?.reasonTypeSeq,
          reasonDetailOrder: editObj?.reasonDetailOrder
            ? Math.floor(Number(editObj.reasonDetailOrder) / 10) * 10
            : maxOrder + 10,
        }}
        validationSchema={Yup.object().shape({
          reasonDetailName: Yup.string()
            .required(
              `${referenceMasterTableName
                .split('')
                .map(alpha => {
                  if (alpha <= 'Z') return ' ' + alpha;
                  return alpha;
                })
                .join('')
                .trim()} is required`
            )
            .max(
              referenceTypeFieldLengthChecks[`reasonDetailName`],
              `Reason Detail name can not be more than ${referenceTypeFieldLengthChecks['reasonDetailName']}`
            )
            .nullable(),
          reasonDetailOrder: Yup.number()
            .required('Reason Detail Order is Required')
            .max(99999, 'Reason Detail Order can not be more than 99999')
            .nullable(),
          reasonDetailOrder: Yup.number().required('Reason Detail Order is Required').nullable(),
          reasonTypeSeq: Yup.string().required('Reason Type is  Required').nullable(),
          countySeq: isCounty
            ? Yup.string().required('County is Required').nullable()
            : Yup.string().nullable(),
        })}
        onSubmit={onSave}
      >
        {({ errors, handleBlur, handleSubmit, isSubmitting, touched, values, handleChange }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {isCounty && (
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth focused sx={{ minWidth: '88%' }}>
                      <InputLabel htmlFor='countySeq' id='countySeq'>
                        County
                      </InputLabel>
                      <Select
                        onChange={handleChange}
                        variant='outlined'
                        label='County'
                        focused
                        displayEmpty
                        size='small'
                        name={`countySeq`}
                        value={values?.countySeq || ''}
                        disabled={
                          values[
                            referenceMasterTableName[0].toLowerCase() +
                              referenceMasterTableName.slice(1) +
                              'Name'
                          ] == 'Other' ||
                          values[
                            referenceMasterTableName[0].toLowerCase() +
                              referenceMasterTableName.slice(1) +
                              'Name'
                          ] == 'Unknown'
                        }
                      >
                        {Object.keys(countyNames).map((item, z) => {
                          return (
                            <MenuItem
                              key={countyNames[item].countySeq}
                              value={countyNames[item].countySeq}
                            >
                              {countyNames[item].countyName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormControl fullWidth error required component='fieldset' variant='standard'>
                    <TextField
                      required
                      focused
                      id='outlined-basic'
                      defaultValue={''}
                      label={referenceMasterTableName
                        .split('')
                        .map(alpha => {
                          if (alpha <= 'Z') return ' ' + alpha;
                          return alpha;
                        })
                        .join('')
                        .trim()}
                      variant='outlined'
                      name={'reasonDetailName'}
                      value={values['reasonDetailName']}
                      onChange={handleChange}
                      // inputProps={{ maxLength: 50 }}
                      onInput={e => {
                        e.target.value = e.target.value.slice(
                          0,
                          referenceTypeFieldLengthChecks['reasonDetailName']
                        );
                      }}
                      disabled={
                        values[
                          referenceMasterTableName[0].toLowerCase() +
                            referenceMasterTableName.slice(1) +
                            'Name'
                        ] == 'Other' ||
                        values[
                          referenceMasterTableName[0].toLowerCase() +
                            referenceMasterTableName.slice(1) +
                            'Name'
                        ] == 'Unknown'
                      }
                    />
                    <ErrorMessage
                      name={'reasonDetailName'}
                      render={msg => <FormHelperText error>{msg}</FormHelperText>}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormControl
                    // error
                    required
                    fullWidth
                    focused
                    // component="fieldset"
                    variant='outlined'
                  >
                    <InputLabel htmlFor='reasonTypeSeq' id='reasonTypeSeq'>
                      Reason Type
                    </InputLabel>
                    <Select
                      onChange={handleChange}
                      variant='outlined'
                      focused
                      label='Reason Type'
                      displayEmpty
                      size='medium'
                      name={`reasonTypeSeq`}
                      value={values?.reasonTypeSeq || ''}
                      disabled={
                        values[
                          referenceMasterTableName[0].toLowerCase() +
                            referenceMasterTableName.slice(1) +
                            'Name'
                        ] == 'Other' ||
                        values[
                          referenceMasterTableName[0].toLowerCase() +
                            referenceMasterTableName.slice(1) +
                            'Name'
                        ] == 'Unknown'
                      }
                    >
                      {reasonTypes.map(item => {
                        return (
                          <MenuItem key={item.reasonTypeSeq} value={item.reasonTypeSeq}>
                            {item.reasonTypeName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage
                      name={`reasonTypeSeq`}
                      render={msg => <FormHelperText error>{msg}</FormHelperText>}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <FormControl fullWidth error required component='fieldset' variant='standard'>
                    <TextField
                      required
                      focused
                      id='outlined-basic'
                      defaultValue={''}
                      label='List Order'
                      variant='outlined'
                      name='reasonDetailOrder'
                      type='number'
                      value={values.reasonDetailOrder}
                      onChange={handleChange}
                      disabled={
                        values[
                          referenceMasterTableName[0].toLowerCase() +
                            referenceMasterTableName.slice(1) +
                            'Name'
                        ] == 'Other' ||
                        values[
                          referenceMasterTableName[0].toLowerCase() +
                            referenceMasterTableName.slice(1) +
                            'Name'
                        ] == 'Unknown'
                      }
                      onInput={e => {
                        const maxValue = 99999;
                        const input = e.target.value;
                        if (parseInt(input, 10) > maxValue) {
                          e.target.value = input.slice(0, input.length - 1);
                        }
                      }}
                    />
                    <ErrorMessage
                      name='reasonDetailOrder'
                      render={msg => <FormHelperText error>{msg}</FormHelperText>}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Box>
                    <Button
                      variant='contained'
                      sx={{ marginTop: '7px' }}
                      onClick={handleSubmit}
                      disabled={!referencePermissions?.add}
                    >
                      {Object.keys(editObj) > 1 ? 'Update' : 'Save'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>

      <div className='modal'>
        <Dialog
          open={openApiDialogBox}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenApiDialogBox(false)}
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              {dialogContents}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <IconButton onClick={() => setOpenApiDialogBox(false)}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default AddEdit;
