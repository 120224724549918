import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Stack, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { List, ListItem, ListItemText } from '@mui/material';
import { DialogActions } from '@mui/material';
import axios from 'axios';
import authHeader from '../../../../services/auth-header';
import { alphabet } from '../../../CMSAdmin/Menu/constants';
import { GlobalContext } from '../../../../Context/GlobalContext';

const CopyRole = props => {
  const { roleGriddata, onCopyRole, onReset } = props;
  const { sendCopyRoleObject } = useContext(GlobalContext);
  const [fetchedRoles, setFetchedRoles] = useState([]);
  const handleSelectionChange = ids => {
    const selectedIDs = new Set(ids);
    const selectedRows = fetchedRoles.filter(row => selectedIDs.has(row.roleSeq));
    sendCopyRoleObject(selectedRows[0]);
    setSelectedRows(selectedRows);
    onCopyRole();
  };

  const [rows, setRows] = useState([]);
  const [noRowsFound, setNoRowsFound] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const columns = [
    {
      field: 'role',
      // headerClassName: 'super-app-theme--header',
      headerName: 'Role',
      type: 'text',
      align: 'left',
      flex: 5,
      editable: false,
    },
  ];
  const [selectedRows, setSelectedRows] = useState([]);
  const [reset, setReset] = useState(Math.random());

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}GetAllRole`, {
        headers: authHeader(),
      })
      .then(res => {
        let data = res?.data?.data.map(row => ({
          ...row,
          id: `${row.roleSeq}`,
          role: `${row.roleName}`,
          status: `${row.status}`,
        }));
        setRows(data);
        setFetchedRoles(data);
        roles = data;
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const handleSearchChange = event => {
    setSearchQuery(event.target.value);
    const filteredRows = fetchedRoles
      .filter(row => row.roleName.toLowerCase().startsWith(event.target.value.toLowerCase()))
      .map(row => ({
        id: `${row.roleSeq}`,
        role: `${row.roleName}`,
        status: `${row.status}`,
      }))
      .sort((a, b) => a.role.localeCompare(b.role));
    setRows(filteredRows);
  };
  const ReeSet = () => {
    setReset(Math.random());
    setSelectedRows([]);
  };
  const handleClick = () => {
    onReset();
  };

  const copyroleseq = selectedRows[0]?.roleSeq;
  const firstChars = fetchedRoles?.map(row => row.roleName.charAt(0));
  const uniqueChars = [...new Set(firstChars)];

  useEffect(() => {
    let gridRole = [];
    if (fetchedRoles.length === 0) {
      gridRole = fetchedRoles;
    } else {
      gridRole = fetchedRoles;
    }
    if (fetchedRoles || fetchedRoles) {
      const formattedRows = gridRole
        .map(row => ({
          id: `${row.roleSeq}`,
          role: `${row.roleName}`,
          status: `${row.status}`,
        }))
        .sort((a, b) => a.role.localeCompare(b.role));

      setRows(formattedRows);
    }
  }, [fetchedRoles, reset, fetchedRoles]);

  const handleLetterClick = letter => {
    const filteredRows = fetchedRoles?.filter(row => row.roleName.charAt(0) === letter);

    const formattedRows = filteredRows?.map(row => ({
      id: `${row.roleSeq}`,
      role: `${row.roleName}`,
      status: `${row.status}`,
    }));
    setRows(formattedRows);
    setNoRowsFound(formattedRows.length === 0);
  };

  // useEffect(() => {
  //     if (typeof copyroleseq != 'undefined' && selectedRows != '') {
  //         sendCopyRoleObject;
  //     }
  // }, [selectedRows]);

  return (
    <>
      <div className=''>
        <strong>
          <h3 className=''>Copy Role </h3>
        </strong>

        <Box className=''>
          <Box className=''>
            <div className=''>
              <List component={Stack} direction='row'>
                {[...Array(10).keys()].map(i => (
                  <ListItem key={i} onClick={() => handleLetterClick(i)} sx={{ cursor: 'pointer' }}>
                    <ListItemText primary={`${i}`} />
                  </ListItem>
                ))}
              </List>
            </div>
            <div className=''>
              <List component={Stack} direction='row'>
                {alphabet.map(letter => (
                  <ListItem
                    direction='row'
                    key={letter}
                    sx={{
                      cursor: 'pointer',
                      backgroundColor: uniqueChars.includes(letter) ? '#2196f3' : '#ededed',
                    }}
                    onClick={() => handleLetterClick(letter)}
                  >
                    {letter}
                  </ListItem>
                ))}
              </List>
            </div>
          </Box>
          <Box className=''>Click on the Role Name to Copy Permission:</Box>
          <Box
            sx={{
              '& .super-app-theme--header': {
                backgroundColor: '#2196f3',
              },
              height: '350px',
              width: '100%',
              tr: 'hover',
              cursor: 'pointer',
            }}
          >
            <TextField label='Search' fullWidth value={searchQuery} onChange={handleSearchChange} />
            <DataGrid
              // className='ViewTable'
              getRowId={row => row.id}
              rowHeight={30}
              rows={rows}
              columns={columns}
              pageSize={50}
              scrollbarSize={5}
              rowsPerPageOptions={[15]}
              disableSelectionOnClick={false}
              onRowSelectionModelChange={handleSelectionChange}
              sx={{
                m: 2,
                marginLeft: '0px',
                fontSize: '12px',
                fontFamily: 'Roboto , Helvetica , Arial , sans-serif !important',
                direction: 'row',
                overflow: 'hidden',
                width: '100%',
              }}
            />
          </Box>
        </Box>
      </div>
      <div style={{ marginTop: '80px' }}>
        <DialogActions>
          <Button onClick={ReeSet} variant='contained' color='info'>
            Reset
          </Button>
          {/* <Button variant='contained'
                    color='info'
                    onClick={() => close}>close</Button> */}
        </DialogActions>
      </div>
    </>
  );
};

export default CopyRole;
