import InfoIcon from '@mui/icons-material/Info';
import { Autocomplete, Checkbox, Paper, TextField } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { Alert, Button, Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';
import '../../../App.css';
// import { breadcrumbReportNav } from "../../../common/helper-functions";
//import { caseResults } from '../../../components/DashboardDataGrids/OpenCasesDataGrid';
import { useAuth } from '../../../utils/auth/AuthService';
import { BreadcrumbReportNav } from '../../../utils/components/BreadcrumbReportNav';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function DeathInvestigationsBreakdownView() {
  const { user } = useAuth();
  const [redirect, setredirect] = useState(null);
  const [userReady, setuserReady] = useState(false);
  const [loading, setloading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [year, setYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  //const [examPhysician, setExamPhysician] = useState([]);
  const [MEOptions, setMEOptions] = useState([]);
  const [currentUser, setcurrentUser] = useState(false);
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [selectedJdxs, setSelectedJdxs] = useState([]);
  const [requiredAlertVisible, setRequiredAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const navigate = useNavigate();
  const { REACT_APP_API_URL } = process.env;

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const columns = [
    {
      field: 'jurisdictionname',
      headerName: 'Jurisdiction',
      width: 190,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'autopsy',
      headerName: 'Autopsy',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'view',
      headerName: 'View',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'caserelease',
      headerName: 'Case Release',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'totalcount',
      headerName: 'Total',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
  ];

  //console.log(columns);

  const handleJdxChange = (event, data) => {
    setSelectedJdxs(data);
  };

  const handleStartDateChange = event => {
    if (event.target.value != '') {
      setStartDate(event.target.value);
    } else {
      setStartDate('');
    }
  };

  const handleEndDateChange = event => {
    if (event.target.value != '') {
      setEndDate(event.target.value);
    } else {
      setEndDate('');
    }
  };

  const handleYearChange = (event, data) => {
    setYear(data);
  };

  // const handleExamPhysicianChange = (event, data) => {
  //   setExamPhysician(data);
  // };

  const dismissDateAlert = () => {
    setRequiredAlertVisible(false);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  const clearAll = () => {
    setStartDate('');
    setEndDate('');
    setYear(null);
    //setExamPhysician([]);
    setSelectedJdxs([]);
    setResultsVisible(false);
  };

  //gather search params from form and FETCH/POST to API
  const handleSubmit = async event => {
    event.preventDefault();
    if (user === null) return;

    // if (year == null && examPhysician.length == 0) {
    //   if (startDate == '') {
    //     setRequiredAlertVisible(true);
    //     return;
    //   }

    //   let startDateArr = startDate.split('-');
    //   let endDateArr = endDate.split('-');

    //   let startDateValue = new Date(
    //     startDateArr[0],
    //     startDateArr[1] - 1,
    //     startDateArr[2]
    //   );
    //   let endDateValue = new Date(
    //     endDateArr[0],
    //     endDateArr[1] - 1,
    //     endDateArr[2]
    //   );

    //   if (endDateValue - startDateValue > 31536000000) {
    //     setRequiredAlertVisible(true);
    //     return;
    //   }
    // }

    // if (selectedJdxs.length == 0 && examPhysician.length == 0) {
    //   setJdxAlertVisible(true);
    //   return;
    // }

    if (selectedJdxs.length == 0) {
      setJdxAlertVisible(true);
      return;
    }

    let formData = new FormData();

    setloading(true);
    setResultsVisible(true);

    formData.append('SEARCHFROMDATE', startDate);
    formData.append('SEARCHTODATE', endDate);
    formData.append('CASEYEAR', year);
    formData.append('SELECTEDJDXLIST', JSON.stringify(selectedJdxs));
    //formData.append('EXAMPHYSICIAN', JSON.stringify(examPhysician));

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'DeathInvesigationsSummary', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setlistresults(data);
          setloading(false);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    setloading(false);
  };

  useEffect(() => {
    if (user === null) return;

    const fetchYear = async () => {
      try {
        const currentYear = new Date().getFullYear();
        //setYear(currentYear);
        setYear(null);

        setYearOptions([...Array(16).keys()].map(i => i + (currentYear - 15)).reverse());
      } catch (e) {
        alert(e);
      }
    };

    const fetchMEs = async () => {
      await fetch(process.env.REACT_APP_API_URL + 'getpathologistoptions', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => {
          if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setMEOptions(data);
        })
        .catch(e => {
          alert(e);
        });
    };

    fetchYear();
    fetchMEs();
    setAuthorizedJdxs(user.jdxAccessList);
    setSelectedJdxs(user.jdxAccessList);
  }, [user]);

  return (
    <div className='me-response-time' style={{ padding: 20 }}>
      {/* {breadcrumbReportNav("Death Investigations Breakdown")} */}
      <BreadcrumbReportNav currentPage='Death Investigations Breakdown' />
      <Card style={{ marginBottom: '1rem' }} className='card-main'>
        <CardHeader className='header-txt'>
          Death Investigations Breakdown
          <Tooltip
            style={{ float: 'right' }}
            title='This Report Tracks the number of Investigations within the selected date range'
          >
            <InfoIcon />
          </Tooltip>
        </CardHeader>

        <Alert
          color='info'
          isOpen={requiredAlertVisible}
          toggle={dismissDateAlert}
          style={{ margin: 10 }}
        >
          Enter a Date Range to find the Fiscal Year for the Death Investigations Breakdown
        </Alert>

        <Alert
          color='info'
          isOpen={jdxAlertVisible}
          toggle={dismissJdxAlert}
          style={{ margin: 10 }}
        >
          Please select an Exam Physician, or at least 1 Jdx.
        </Alert>

        <div>
          <CardBody>
            <Row>
              <Col className='md=12'>
                <Autocomplete
                  multiple
                  id='selectedJdxs'
                  options={authorizedJdxs}
                  value={selectedJdxs}
                  disableCloseOnSelect
                  limitTags={4}
                  size='small'
                  getOptionLabel={option => option.jdxName}
                  onChange={(event, data) => handleJdxChange(event, data)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<MdCheckBoxOutlineBlank />}
                        checkedIcon={<MdOutlineCheckBox />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.jdxName}
                    </li>
                  )}
                  style={{ width: '100%', marginTop: 15 }}
                  renderInput={params => (
                    <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
                  )}
                  ListboxComponent={'ul'}
                />
              </Col>
            </Row>

            <Row>
              {/* <Col className='me-col' md='4'>
                <Autocomplete
                  id='year'
                  options={yearOptions}
                  value={year}
                  size='small'
                  getOptionLabel={(option) => option.toString()}
                  onChange={(event, data) => handleYearChange(event, data)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<MdCheckBoxOutlineBlank />}
                        checkedIcon={<MdOutlineCheckBox />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.toString()}
                    </li>
                  )}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} label='Year' placeholder='Year' />
                  )}
                  ListboxComponent={'ul'}
                />
              </Col> */}

              {/* <Col className='md=4'>
                <Autocomplete
                  multiple
                  id='physicians'
                  options={MEOptions}
                  value={examPhysician}
                  disableCloseOnSelect
                  limitTags={4}
                  size='small'
                  getOptionLabel={(option) => option.optionName}
                  onChange={(event, data) =>
                    handleExamPhysicianChange(event, data)
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<MdCheckBoxOutlineBlank />}
                        checkedIcon={<MdOutlineCheckBox />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.optionName}
                    </li>
                  )}
                  style={{ width: '100%', marginTop: 10 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Medical Examiner'
                      placeholder='Medical Examiner'
                    />
                  )}
                  ListboxComponent={'ul'}
                />
              </Col> */}

              <Col className='me-col' md='6'>
                <TextField
                  id='searchfromdate'
                  name='searchfromdate'
                  label='Case Closed From'
                  type='date'
                  size='small'
                  value={startDate}
                  onChange={handleStartDateChange}
                  //disabled={year}
                  style={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Col>

              <Col className='me-col' md='6'>
                <TextField
                  id='searchtodate'
                  name='searchtodate'
                  label='Case Closed To'
                  type='date'
                  size='small'
                  value={endDate}
                  onChange={handleEndDateChange}
                  //disabled={year}
                  style={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Col>
            </Row>
          </CardBody>
          <br />

          <Button
            type='submit'
            color='primary'
            style={{ width: 120, marginLeft: 18, float: 'right' }}
            onClick={handleSubmit}
          >
            Search
          </Button>

          <Button
            color='secondary'
            style={{ width: 120, marginLeft: 18, float: 'right' }}
            onClick={clearAll}
          >
            Clear
          </Button>
          <hr />
          <br />

          <Collapse isOpen={resultsVisible}>
            <ReportDataGrid
              loading={loading}
              listresults={listresults}
              // idcolumn='caseid'
              idcolumn='did'
              columnsInput={columns}
              title='Death Investigations Breakdown'
              casecount
              chatcontent={
                <Paper
                  elevation={3}
                  sx={{
                    p: '10px',
                    px: '20px',
                    borderRadius: '20px',
                    width: 'max-content',
                  }}
                >
                  <div style={{ textAlign: 'center' }}>
                    I've listed the Death Investigation Breakdown in the selected date range below,
                    you can export the list to csv format.
                  </div>
                </Paper>
              }
            />
          </Collapse>
        </div>
      </Card>
    </div>
  );
}
