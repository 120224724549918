import { ViewInArSharp } from '@mui/icons-material';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import '../../App.css';
import { useAuth } from '../../utils/auth/AuthService';
import CustomDrawer from '../../utils/components/CustomDrawer';
import CustomHeader from '../../utils/components/CustomHeader';
import CustomTabPanel from '../../utils/components/CustomTabPanel';
import AIPromptView from './ResourcesModules/AIPromptView';
import Glossary from './ResourcesModules/Glossary/GlossaryView';
import ResourcesModelViewer from './ResourcesModules/ModelViewer/ResourcesModelViewer';
import Publications from './ResourcesModules/Publications/PublicationsView';
import Tools from './ResourcesModules/Tools/ToolsView';
import { fetchVideoThumbnailURL } from './ResourcesModules/Videos/VideoPlayer';
import Videos from './ResourcesModules/Videos/Videos';

export default function ResourcesView() {
  const auth = useAuth();
  const [currentTab, setCurrentTab] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab);
  };

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const drawerContents = [
    { text: 'Publications', icon: <LibraryBooksIcon />, disabled: false },
    { text: 'Glossary', icon: <MenuBookIcon />, disabled: false },
    {
      text: 'Videos',
      icon: <VideoLibraryIcon />,
      // authorizedRoles: ['EXPERIMENTAL-FEATURES-ACCESS'],
      disabled: false,
    },
    {
      text: 'Model Library',
      icon: <ViewInArSharp />,
      authorizedRoles: ['EXPERIMENTAL-FEATURES-ACCESS'],
      disabled: false,
    },
    {
      text: 'AI Prompt',
      icon: <CenterFocusWeakIcon />,
      authorizedRoles: ['EXPERIMENTAL-FEATURES-ACCESS'],
      disabled: false,
    },
    {
      text: 'Tools',
      icon: <HomeRepairServiceIcon />,
      authorizedRoles: ['EXPERIMENTAL-FEATURES-ACCESS'],
      disabled: false,
    },
  ];

  const [thumbnailURL, setThumbnailURL] = useState('');
  useEffect(() => {
    fetchVideoThumbnailURL('bec34fef-113b-492f-90d3-96f53c053bb7', auth.user.accessToken).then(
      url => setThumbnailURL(url)
    );
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <CustomDrawer
        drawerContents={drawerContents}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        // handleTabChange={handleTabChange}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />

      <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <CustomTabPanel
          authorizedRoles={drawerContents[0].authorizedRoles}
          value={currentTab}
          index={0}
        >
          <Publications handleMenuClick={handleMenuClick} />
        </CustomTabPanel>

        <CustomTabPanel
          authorizedRoles={drawerContents[0].authorizedRoles}
          value={currentTab}
          index={2}
        >
          {/* <VideoPlayer
            videoSeq='bec34fef-113b-492f-90d3-96f53c053bb7'
            thumbnailURL={thumbnailURL}
          /> */}
          <Videos handleMenuClick={handleMenuClick} />
        </CustomTabPanel>

        <CustomTabPanel
          authorizedRoles={drawerContents[0].authorizedRoles}
          value={currentTab}
          index={1}
        >
          <Glossary handleMenuClick={handleMenuClick} />
        </CustomTabPanel>

        <CustomTabPanel
          authorizedRoles={drawerContents[0].authorizedRoles}
          value={currentTab}
          index={3}
        >
          <CustomHeader
            title='Models'
            description='View and manage 3D model resources'
            handleMenuClick={handleMenuClick}
          />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ResourcesModelViewer />
          </ErrorBoundary>
        </CustomTabPanel>

        <CustomTabPanel
          authorizedRoles={drawerContents[0].authorizedRoles}
          value={currentTab}
          index={5}
        >
          <CustomHeader
            title='Tools'
            description='Forensic and Analytical tools'
            handleMenuClick={handleMenuClick}
          />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Tools />
          </ErrorBoundary>
        </CustomTabPanel>

        <CustomTabPanel
          authorizedRoles={drawerContents[0].authorizedRoles}
          value={currentTab}
          index={4}
        >
          <CustomHeader
            title='AI Prompt'
            description='Use AI to help with your analysis'
            handleMenuClick={handleMenuClick}
          />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <AIPromptView />
          </ErrorBoundary>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <hr />
      <p>Please try again later</p>
    </div>
  );
}
