import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';

export default function HL7Uploader() {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [gridData, setGridData] = useState([]);
  const theme = useTheme();

  // Load environment variables
  const baseUrl = process.env.REACT_APP_FASTAPI_URL;

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const UploadHL7File = async () => {
    if (!file) {
      setUploadStatus('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${baseUrl}nmshl7tojson/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setUploadStatus('File uploaded successfully.');
      console.log('Response:', response.data);
      setGridData(response.data.data); // Assuming the JSON data is in response.data.data
    } catch (error) {
      setUploadStatus('Failed to upload file.');
      console.error('Error uploading file:', error);
    }
  };

  const columns = [
    { field: 'PatientID', headerName: 'Patient ID', width: 150 },
    { field: 'PatientName', headerName: 'Patient Name', width: 150 },
    { field: 'TestOrderID', headerName: 'Test Order ID', width: 150 },
    { field: 'TestDescription', headerName: 'Test Description', width: 200 },
    { field: 'TestCode', headerName: 'Test Code', width: 150 },
    { field: 'Analyte', headerName: 'Analyte', width: 150 },
    { field: 'ResultValue', headerName: 'Result Value', width: 150 },
    { field: 'Units', headerName: 'Units', width: 100 },
  ];

  return (
    <Card raised sx={{ my: 3, mx: 'auto', width: { xs: '95%', lg: '95%' } }}>
      <CardHeader
        title="Upload HL7 File"
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
      />
      <Divider variant="middle" sx={{ my: 2, opacity: 1 }} />
      <CardContent>
        <Box my={2}>
          <input
            type="file"
            onChange={handleFileChange}
            style={{ display: 'block', marginBottom: 10 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={UploadHL7File}
            disabled={!file}
          >
            Upload File
          </Button>
        </Box>
        {uploadStatus && (
          <Typography variant="body2" style={{ color: theme.palette.secondary.main }}>
            {uploadStatus}
          </Typography>
        )}
        {gridData.length > 0 && (
          <Box mt={3} sx={{ height: 400, width: '100%' }}>
            <DataGrid rows={gridData} columns={columns} pageSize={5} rowsPerPageOptions={[5]} />
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
