/**
 * TO DO: userRoles, requiredRole interfaces
 * @param userRoles
 * @param requiredRole
 * @returns
 */
// @ts-ignore
export function roleCharacteristicsCheck(userRoles, requiredRole) {
  var found = false;
  if (userRoles == null) return false;

  // @ts-ignore
  userRoles.forEach((role) => {
    if (role.roleCharacteristicsName == requiredRole) {
      found = true;
    }
  });
  return found;
}
