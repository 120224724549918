import React, { useEffect, useContext } from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { Field } from 'formik';
import {
  fetchCounty,
  fetchRoles,
  getNameFromCountySequence,
  getNameFromOCSME,
  getNameFromPathology,
  getNameFromRolesSequence,
  getNameFromSpecialPrivilege,
  getNameFromPhotoTypePermissions,
  getNameFromDocumentTypePermissions,
  getNameFromReportTypePermissions,
} from '../../services/utility-api';
import { GlobalContext } from '../../Context/GlobalContext';

const MultiSelectComponent = ({ name, label, options, module, disabled, ...rest }) => {
  useEffect(() => {
    if (module === 'County') {
      fetchCounty();
    }
    if (module === 'Roles') {
      fetchRoles();
    }
  }, []);

  const { photoTypeObject, documentTypeObject, reportTypeObject } =
    useContext(GlobalContext);
  const getNameFromPhotoTypePermissions = (sequenceArray) => {
    return sequenceArray.map((sequence) => photoTypeObject?.[sequence]);
  };

  const getNameFromDocumentTypePermissions = (sequenceArray) => {
    return sequenceArray.map((sequence) => documentTypeObject?.[sequence]);
  };
  const getNameFromReportTypePermissions = (sequenceArray) => {
    return sequenceArray.map((sequence) => reportTypeObject?.[sequence]);
  };

  let updatedOptions = [
    // { name: 'Select All', value: 'Select All' },
    ...options,
  ];

  console.log('updatedOptions', updatedOptions);
  return (
    <div>
      <Field id={name} name={name} {...rest}>
        {({ field, form }) => {
          return (
            <FormControl
              error={!!form?.errors?.[name]}
              fullWidth
              variant='outlined'
            >
              <InputLabel id={label}>{label}</InputLabel>
              <Select
                disabled={disabled}
                label={label}
                id={label}
                name={name}
                multiple
                size='large'
                style={{ width: '100%', height: '100%' }}
                {...rest}
                renderValue={(selected) => {
                  const filterSelected = selected?.filter(
                    (option) => option && option !== 'Select All'
                  );
                  if (selected?.includes('Select All')) {
                    return options
                      ?.filter((option) => option !== 'Select All')
                      .map((item) => item.name)
                      .join(', ');
                  }
                  if (module === 'County') {
                    return getNameFromCountySequence(filterSelected).join(', ');
                  } else if (module === 'Roles') {
                    return getNameFromRolesSequence(filterSelected)?.join(', ');
                  } else if (module === 'Special Privilege') {
                    return getNameFromSpecialPrivilege(filterSelected)?.join(
                      ', '
                    );
                  } else if (module === 'Pathology') {
                    return getNameFromPathology(filterSelected)?.join(', ');
                  } else if (module === 'OCSME') {
                    return getNameFromOCSME(filterSelected)?.join(', ');
                  } else if (module === 'Photo Type Permissions') {
                    return getNameFromPhotoTypePermissions(
                      filterSelected
                    )?.join(', ');
                  } else if (module === 'Document Type Permissions') {
                    return getNameFromDocumentTypePermissions(
                      filterSelected
                    )?.join(', ');
                  } else if (module === 'Report Type Permissions') {
                    return getNameFromReportTypePermissions(
                      filterSelected
                    )?.join(', ');
                  }
                }}
                {...field}
              >
                {updatedOptions?.map((option) => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox
                        checked={
                          form?.values?.[name].includes('Select All')
                            ? true
                            : form?.values?.[name]?.indexOf(option.value) > -1
                        }
                      />
                      <ListItemText primary={option?.name} />
                    </MenuItem>
                  );
                })}
              </Select>
              {!!form?.errors?.[name] ? (
                <p style={{ color: '#d32f2f' }}>{form?.errors?.[name]}</p>
              ) : null}
            </FormControl>
          );
        }}
      </Field>
    </div>
  );
};

export default MultiSelectComponent;
