import React, { useEffect, useState } from 'react';
import { startConnection, registerReceiveMessageHandler } from '../services/signalr.service';

export default function SignalRTest() {
  const [messages, setMessages] = useState([]);
  const connection = startConnection();

  useEffect(() => {
    registerReceiveMessageHandler(connection, onReceiveMessage);
  }, [connection]);

  const onReceiveMessage = message => {
    setMessages(prevMessages => [...prevMessages, message]);
  };

  const sendMessage = async () => {
    const user = 'YourUsername'; // Replace with the actual username
    const message = 'Hello, SignalR!';
    await connection.invoke('SendMessage', user, message);
  };

  return (
    <div>
      <h1>Your React App</h1>
      <ul>
        {messages.map((msg, index) => (
          <li key={index}>{`${msg.user}: ${msg.message}`}</li>
        ))}
      </ul>
      <button onClick={sendMessage}>Send Message</button>
    </div>
  );
}
