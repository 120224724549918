import Atlantic from './assets/Atlantic.jpg';
import Bergen from './assets/Bergen.jpg';
import Burlington from './assets/Burlington.jpg';
import Camden from './assets/Camden.jpg';
import CapeMay from './assets/CapeMay.jpg';
import Cumberland from './assets/Cumberland.jpg';
import emblem from './assets/emblem.jpg';
import Essex from './assets/Essex.jpg';
import Gloucester from './assets/Gloucester.jpg';
import Hudson from './assets/Hudson.jpg';
import Hunterdon from './assets/Hunterdon.jpg';
import Mercer from './assets/Mercer.jpg';
import Middlesex from './assets/Middlesex.jpg';
import Monmouth from './assets/Monmouth.jpg';
import Morris from './assets/Morris.jpg';
import NJ_seal from './assets/NJ_seal.jpg';
import NJDCJ_logo from './assets/NJDCJ_logo.jpg';
import Ocean from './assets/Ocean.jpg';
import Passaic from './assets/Passaic.jpg';
import Salem from './assets/Salem.jpg';
import Somerset from './assets/Somerset.jpg';
import Sussex from './assets/Sussex.jpg';
import Union from './assets/Union.jpg';
import Warren from './assets/Warren.jpg';

export const RIME_REPORT_LOGOS = {
    Atlantic,

    Bergen,

    Burlington,

    Camden,

    CapeMay,

    Cumberland,

    emblem,

    Essex,

    // Essex1,

    Gloucester,

    Hudson,

    Hunterdon,

    Mercer,

    Middlesex,

    Monmouth,

    Morris,

    NJ_seal,

    NJDCJ_logo,

    Ocean,

    Passaic,

    Salem,

    Somerset,

    Sussex,

    Union,

    Warren
};
