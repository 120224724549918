import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

interface UnsavedChangesDialogProps {
  open: boolean;
  onLeave: () => void;
  onCancel: () => void;
}

const UnsavedChangesDialog: React.FC<UnsavedChangesDialogProps> = ({
  open,
  onLeave,
  onCancel
}) => {
  return (
    <Dialog open={open} onClose={onCancel} sx={{ zIndex: 9999}}>
      <DialogTitle>Unsaved Changes</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: 'text.primary' }}>
          You have unsaved changes. Are you sure you want to leave?
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{margin: '1rem' }}>
        <Button onClick={onLeave} variant='contained'>Leave</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnsavedChangesDialog;