import React from 'react';
import { Pagination, PaginationItem } from '@mui/material';
import {
  useGridApiContext,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid-premium';

export default function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = gridPageSelector(apiRef);
  const pageCount = gridPageCountSelector(apiRef);

  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={props2 => (
        <PaginationItem {...props2} sx={{ fontFamily: 'DataGrid, sans-serif' }} disableRipple />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{ mr: 3 }}
    />
  );
}
