// import { Button } from '@mui/material';
// import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
// import L from 'leaflet';
// import 'leaflet/dist/leaflet.css';
// import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
// import { useNavigate } from 'react-router';
// // @ts-ignore
import mapMarker from '../../assets/images/map-marker.png';
// import { Jurisdiction } from '../../types/Jurisdiction.interface';
// import { useAuth } from '../auth/AuthService';

import { ExpandMoreOutlined } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Collapse,
  Stack,
  Typography,
} from '@mui/material';
import L from 'leaflet';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

const NJ_COORDS = { lat: 40.0583, lng: -74.4057 };
const ZOOM_LEVEL = 8;

// /* global L */

// // Create a DivIcon with text
const customIcon = (text: number) =>
  L.divIcon({
    className: 'custom-icon',
    html: `<div style="position: relative;">
        <img src="${mapMarker}" style="position: absolute;" width="41" height="41" />
        <div id="textContainer" class="text-container" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, 20%);
          color: white; font-size: ${
            text.toString().length > 2 ? '15px' : '18px'
          }; font-weight: bold; margin: 0;">${text}</div>
      </div>`,
    iconSize: [41, 41],
    iconAnchor: [20, 41], // Adjust the iconAnchor to center the marker
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

// Use the aws sdk
const AWS = require('aws-sdk');

// Set the region where your identity pool exists (us-east-1, eu-west-1)
AWS.config.region = 'us-east-1';

// Configure the credentials provider to use your identity pool
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'us-east-1:269924ac-7862-42d3-9a9c-4bd1c1861f39',
});

// Make the call to obtain credentials
await AWS.config.credentials.get();

// Make the location options
const locationOptions = {
  region: AWS.config.region,
  credentials: AWS.config.credentials,
};

// Create the location client
const location = new AWS.Location(locationOptions);

const getLatLngFromZipCode = async (zipcodes: string[]) => {
  const zipCodeToLatLngMap = new Map<string, { lat: number; lng: number }>();
  const promises = zipcodes.map(async zipcode => {
    const params = {
      IndexName: 'MedexLabPlaceIndex',
      Text: zipcode,
      MaxResults: 1,
      FilterCountries: ['USA'],
    };
    try {
      const { Results } = await location.searchPlaceIndexForText(params).promise();
      if (Results && Results.length > 0) {
        const { Point } = Results[0].Place.Geometry;
        return [zipcode, { lat: parseFloat(Point[1]), lng: parseFloat(Point[0]) }];
      }
    } catch (error) {
      console.log(error);
    }
  });

  const results = await Promise.all(promises);
  return new Map(
    results.filter(
      (result): result is [string, { lat: number; lng: number }] => result !== undefined
    )
  );
};

interface Case {
  caseid: string;
  blzip: string;
  blcity: string;
  investigator: string;
  meaction: string;
  menotified: string;
}
const { REACT_APP_API_URL } = process.env;

interface HomicideMarker extends Case {
  zipCode: string;
  count: number;
  caseIds: string[];
  position: [lat: number, lng: number];
}

export default function RTHomicideMap({
  cases = [],
  open = false,
}: {
  cases: Case[];
  open: boolean;
}) {
  const navigate = useNavigate();
  const [casesWithoutBlzip, setCasesWithoutBlzip] = useState<Case[]>([]);
  const [showCasesWithoutBlzip, setShowCasesWithoutBlzip] = useState(false);

  const [loading, setLoading] = useState(true);
  const mapRef = useRef<L.Map>(null);
  const [markers, setMarkers] = useState<HomicideMarker[]>([]);

  const calculateMarkerLocations = async () => {
    setLoading(true);
    const filteredCases = cases.filter(caseData => !caseData.blzip || caseData.blzip.trim() === '');
    setCasesWithoutBlzip(filteredCases);

    const casesGroupedByZipCode = cases.reduce((acc, c) => {
      if (c.blzip) {
        if (acc[c.blzip]) {
          acc[c.blzip].push(c);
        } else {
          acc[c.blzip] = [c];
        }
      }
      return acc;
    }, {} as { [zipCode: string]: Case[] });

    const uniqueZipCodes = Object.keys(casesGroupedByZipCode);
    const zipCodeToLatLngMap = await getLatLngFromZipCode(uniqueZipCodes);

    const mappedMarkers = uniqueZipCodes.map(zipCode => {
      const coords = zipCodeToLatLngMap.get(zipCode);
      if (coords && coords.lat && coords.lng) {
        return {
          zipCode,
          count: casesGroupedByZipCode[zipCode].length,
          caseIds: casesGroupedByZipCode[zipCode].map(c => c.caseid),
          position: [coords.lat, coords.lng],
        };
      }
      return null;
    });

    setMarkers(mappedMarkers.filter((marker): marker is HomicideMarker => marker !== null));
    setLoading(false);
  };

  const markerRefs = useRef<Array<L.Marker | null>>([]);

  const memoizedMarkers = useMemo(
    () =>
      markers.map((marker, index) => (
        <Marker
          key={marker.zipCode}
          position={marker.position}
          icon={customIcon(marker.count)}
          ref={ref => (markerRefs.current[index] = ref)}
          eventHandlers={{
            click: () => {
              if (mapRef.current && markerRefs.current[index]) {
                mapRef.current.flyTo(markerRefs.current[index]!.getLatLng(), 12, {
                  duration: 0.5,
                });
              }
            },
          }}
        >
          <Popup
            autoPan={true}
            autoPanPaddingTopLeft={[50, 50]}
            autoPanPaddingBottomRight={[50, 50]}
          >
            <Stack spacing={0}>
              <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
                ZIP {marker.zipCode}
              </Typography>

              {marker.caseIds.map(caseId => (
                <Button
                  key={caseId}
                  variant='text'
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    navigate('/caseview', {
                      state: {
                        cmscaseid: caseId,
                        caseSeq: null,
                      },
                    });
                  }}
                >
                  {caseId}
                </Button>
              ))}
            </Stack>
          </Popup>
        </Marker>
      )),
    [markers]
  );

  useEffect(() => {
    calculateMarkerLocations();
  }, [cases]);

  return (
    <Collapse in={open}>
      <Stack>
        <Collapse in={loading}>
          <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
            <CircularProgress size={12} color='primary' />
            <Typography variant='subtitle2' color='GrayText'>
              Updating map, please wait...
            </Typography>
          </Stack>
        </Collapse>

        <Collapse appear={true} in={!loading}>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: '1', height: '600px', width: '100%' }}>
              <MapContainer
                preferCanvas={true}
                style={{ height: '100%', width: '100%' }}
                center={NJ_COORDS}
                zoom={ZOOM_LEVEL}
                ref={mapRef}
                fadeAnimation={true}
                zoomAnimation={true}
                markerZoomAnimation={true}
              >
                <TileLayer url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'} />
                {memoizedMarkers}
              </MapContainer>
            </div>
          </div>
        </Collapse>

        {casesWithoutBlzip.length > 0 && (
          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
              <Typography variant='subtitle2' color='text.secondary' sx={{ fontWeight: 'bold' }}>
                {casesWithoutBlzip.length} cases without Body Location Zip Code
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={0}>
                {casesWithoutBlzip.map(caseData => (
                  <Button
                    key={caseData.caseid}
                    variant='text'
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.preventDefault();
                      navigate('/caseview', {
                        state: {
                          cmscaseid: caseData.caseid,
                          caseSeq: null,
                        },
                      });
                    }}
                  >
                    {caseData.caseid}
                  </Button>
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        )}
      </Stack>
    </Collapse>
  );
}
