import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/auth/AuthService';
import { useUnsavedChanges } from '../../Context/UnsavedChangesContext';
import UnsavedChangesDialog from '../../components/UnsavedChangesDialog';

// Define the props type
interface NavButtonProps {
  navigationPageTitle?: string;
  startIcon?: React.ReactNode;
  authorizedRoles?: string[];
  title?: string;
  mono?: boolean;
}


const NavButton: React.FC<NavButtonProps> = ({
  navigationPageTitle,
  startIcon,
  authorizedRoles,
  title = '',
  mono = false,
}) => {
  const location = window.location;
  const navigate = useNavigate();
  const { user } = useAuth();
  const { REACT_APP_URL } = process.env;
  const { hasUnsavedChanges, resetUnsavedChanges } = useUnsavedChanges();
  const [unsavedDialogOpen, setUnsavedDialogOpen] = useState<boolean>(false);

  const handleNavigation = () => {
    if (hasUnsavedChanges) {
      setUnsavedDialogOpen(true);
    } else {
      navigate(`/${navigationPageTitle}`);
    }
  };

  const handleUnsavedDialogLeave = () => {
    resetUnsavedChanges(); 
    setUnsavedDialogOpen(false);
    navigate(`/${navigationPageTitle}`);
  };

  const handleUnsavedDialogCancel = () => {
    setUnsavedDialogOpen(false); 
  };

  const isActive = location.pathname?.toLowerCase() === `${REACT_APP_URL}${navigationPageTitle?.toLowerCase()}`;
  const isAuthorized = authorizedRoles == null || user?.roleCheck(authorizedRoles);

  return (
    <div>
      {isAuthorized && (
        <Button
          variant='text'
          color={isActive ? 'primary' : 'inherit'}
          sx={{
            whiteSpace: 'nowrap',
          }}
          startIcon={startIcon}
          onClick={handleNavigation}
        >
          {title}
        </Button>
      )}
      <UnsavedChangesDialog open={unsavedDialogOpen} onLeave={handleUnsavedDialogLeave} onCancel={handleUnsavedDialogCancel} />
    </div>
  );
};

export default NavButton;
