import { Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { useUnsavedChanges } from '../../Context/UnsavedChangesContext';
import UnsavedChangesDialog from '../../components/UnsavedChangesDialog';
import React, { useState } from 'react';

export default function AppTitle() {
  const navigate = useNavigate();
  const [unsavedDialogOpen, setUnsavedDialogOpen] = useState<boolean>(false);
  const { hasUnsavedChanges, resetUnsavedChanges } = useUnsavedChanges();

  const handleNavigation = () => {
    if (hasUnsavedChanges) {
      setUnsavedDialogOpen(true);
    } else {
      navigate('/home')
    }
  };

  const handleUnsavedDialogLeave = () => {
    resetUnsavedChanges(); 
    setUnsavedDialogOpen(false);
    navigate('/home');
  };

  const handleUnsavedDialogCancel = () => {
    setUnsavedDialogOpen(false); 
  };

  return (
    <div>
      <Typography
        variant='h5'
        noWrap
        onClick={handleNavigation}
        sx={{
          minWidth: 'max-content',
          fontFamily: 'monospace',
          fontWeight: 700,
          letterSpacing: '.3rem',
          color: 'inherit',
          textDecoration: 'none',
        }}
      >
        MedexLAB
      </Typography>

      <UnsavedChangesDialog open={unsavedDialogOpen} onLeave={handleUnsavedDialogLeave} onCancel={handleUnsavedDialogCancel} />
    </div>
  );
}
