import { Typography } from '@mui/material';

function AutocompleteCellDisplay({ value, textFieldText }) {
  const optionName = value?.optionName ?? '';

  // if you want them top and bottom
  //   return (
  //     <div style={{ display: 'flex', flexDirection: 'column' }}>
  //       <Typography variant='body3'>{optionName}</Typography>
  //       {optionName === 'Other' && textFieldText && (
  //         <Typography variant='body1' color='textSecondary'>
  //           {textFieldText}
  //         </Typography>
  //       )}
  //     </div>
  //   );

  // if you want them side by side
  //   return (
  //     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
  //       <Typography variant='body3'>{optionName}</Typography>
  //       {optionName === 'Other' && textFieldText && (
  //         <Typography variant='body1' color='textSecondary'>
  //           {textFieldText}
  //         </Typography>
  //       )}
  //     </div>
  //   );

  // only renders the textfield if the option is other
  return (
    <Typography variant='body3'>
      {optionName === 'Other' && textFieldText?.trim() ? textFieldText : optionName}
    </Typography>
  );
}

export const renderAutocompleteCellDisplay = params => {
  const { value, textFieldText } = params; // textFieldText is the additional text to display if "Other" is selected

  return <AutocompleteCellDisplay value={value} textFieldText={textFieldText} />;
};
