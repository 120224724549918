import React, { useEffect, useState, useContext } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel, Button, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { getAdminData, deleteData, activeData } from '../../../../../services/utility-api';
import {
  DataGridPremium,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useNavigate } from 'react-router';
import { GlobalContext } from '../../../../../Context/GlobalContext';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';
import ApiNotification from '../../../../../components/DialogBox';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import FilterActivity from './FilterActivity';
import MainCard from '../../../../../components/AdministrationModules/MainCard';
import AccessDenied from '../../../../../components/AdministrationModules/AccessDenied';
import Breadcrumb from '../../../../../components/AdministrationModules/Breadcrumb';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = gridPageSelector(apiRef);
  const pageCount = gridPageCountSelector(apiRef);

  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={props2 => (
        <PaginationItem {...props2} sx={{ fontFamily: 'DataGrid, sans-serif' }} disableRipple />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{ mr: 3 }}
    />
  );
}

const ActivityAdminView = () => {
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const activityPermissions = permissions?.['Activity'];
  const [department, setDepartment] = useState('');
  const [departmentData, setDepartmentData] = useState([]);
  const [activity, setActivity] = useState('');
  const [activityStatus, setActivityStatus] = useState([]);
  const [activityStatusToShow, setActivityStatusToShow] = useState([]);
  const [activeInActiveFilter, setActiveInActiveFilter] = useState('Active');
  const [dialogContents, setDialogContents] = useState('');
  const [openActiveInActiveDialogBox, setOpenActiveInActiveDialogBox] = useState(false);
  const [activeInactiveClickData, setActiveInactiveClickData] = useState({});
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const { sendActivityStatus } = useContext(GlobalContext);

  const navigate = useNavigate();

  const columns = [
    {
      field: 'department',
      headerName: 'Department',
      // headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: 'activityName',
      headerName: 'Activity',
      // headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      // headerClassName: 'super-app-theme--header',
      renderCell: cellValues => {
        const handleClick = async row => {
          setOpenActiveInActiveDialogBox(true);
          const newIconData = row.status === 'Active' ? 'locked' : 'done';
          setActiveInactiveClickData({
            icon: newIconData,
            seq: row['activitySeq'],
            minWidth: 150,
            status: row.status,
          });
        };

        return (
          <Button
            onClick={e => {
              e.preventDefault();
              handleClick(cellValues.row);
            }}
            disabled={!activityPermissions?.edit}
          >
            {cellValues.row.status === 'Active' ? <LockIcon /> : <DoneIcon />}
          </Button>
        );
      },
    },
  ];

  const getDepartment = async () => {
    try {
      const departmentResult = await getAdminData('assignmentType');
      setDepartmentData(departmentResult.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDepartment();
  }, []);

  const getActivity = async () => {
    try {
      const departmentResult = await getAdminData('assignmentType');
      const activityStatusResult = await getAdminData('activity');
      const activityStatusData = activityStatusResult.data.data.map((ele, index) => {
        return {
          ...ele,
          department: departmentResult.data.data.find(
            d => d.assignmentTypeSeq == ele.assignmentTypeSeq
          )?.assignmentTypeName,
        };
      });
      setActivityStatus(activityStatusData);
      setActivityStatusToShow(activityStatusData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getActivity();
  }, [departmentData]);

  const changeActiveInActiveClick = async () => {
    try {
      if (activeInactiveClickData.status === 'Active') {
        const responseMessage = await deleteData({
          seq: activeInactiveClickData.seq,
          tableName: 'activity',
        });
        setDialogContents(responseMessage);
      } else {
        const responseMessage = await activeData({
          seq: activeInactiveClickData.seq,
          tableName: 'activity',
        });
        setDialogContents(responseMessage);
      }
      setOpenActiveInActiveDialogBox(false);
      setOpenApiDialogBox(true);
      await getActivity();
    } catch (error) {
      console.log('error', error);
    }
  };

  const MENU_OBJECT = {
    Home: '/sysadmin',
    Activity: '/sysadmin/businessrule',
  };

  console.log('activityStatusToShow', activityStatusToShow);
  return (
    // <div className='container my-5 py-5'>
    <Grid container spacing={2} sx={{ mt: '10px', padding: '16px' }}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            {/* <MainCard> */}
            <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
            <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0, mb: 3 }} />
            {activityPermissions?.view ? (
              <Box>
                <FilterActivity
                  activity={activity}
                  rows={activityStatus}
                  department={department}
                  setActivity={setActivity}
                  setDepartment={setDepartment}
                  departmentData={departmentData}
                  setRowsToShow={setActivityStatusToShow}
                  activeInActiveFilter={activeInActiveFilter}
                  setActiveInActiveFilter={setActiveInActiveFilter}
                />

                <Box mt={2}>
                  <DataGridPremium
                    onCellClick={cell => {
                      if (cell?.field != 'status' && cell?.row?.status.toLowerCase() === 'active') {
                        navigate('edit-activity', {
                          state: { activityStatus: cell.row },
                        });
                      }
                    }}
                    getRowId={row => row.activitySeq}
                    rows={activityStatusToShow}
                    columns={columns}
                    columnHeaderHeight={50}
                    rowHeight={30}
                    pageSize={50}
                    scrollbarSize={5}
                    pagination
                    slots={{
                      pagination: CustomPagination,
                    }}
                    // sx={{
                    //   width: '100%',
                    //   height: 450,
                    //   // border: 'none',
                    //   borderRadius: '6px',
                    //   backgroundColor: 'white',
                    //   // boxShadow: '0px 0px 10px #ddd',
                    // }}
                  />

                  <Button
                    variant='contained'
                    color='info'
                    sx={{ mt: 2 }}
                    onClick={() =>
                      navigate('add-activity', {
                        state: {
                          activityStatus: {},
                        },
                      })
                    }
                    startIcon={<ControlPointIcon />}
                    disabled={!activityPermissions?.add}
                  >
                    Add Activity
                  </Button>
                </Box>

                <div className='modal'>
                  <Dialog
                    open={openActiveInActiveDialogBox}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpenActiveInActiveDialogBox(false)}
                    aria-describedby='alert-dialog-slide-description'
                  >
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                      <DialogContentText id='alert-dialog-slide-description'>
                        Are you sure you want to{' '}
                        {activeInactiveClickData?.status === 'Active' ? 'InActive ' : 'Active'} this
                        Activity ?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setOpenActiveInActiveDialogBox(false)}>No</Button>
                      <Button onClick={changeActiveInActiveClick}>Yes</Button>
                    </DialogActions>
                  </Dialog>
                </div>

                <ApiNotification
                  openApiDialogBox={openApiDialogBox}
                  closeDialogBox={() => {
                    setOpenApiDialogBox(false);
                  }}
                  dialogContents={dialogContents}
                />
              </Box>
            ) : (
              <AccessDenied />
            )}
            {/* </MainCard> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    // </div>
  );
};

export default ActivityAdminView;
