import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

export default function CMSAdminHeader({
  title,
  subtitle1 = null,
  subtitle2 = null,
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        mb: 2,
        backgroundColor: theme.palette.background.default,
        width: '100%',
        position: 'sticky',
        top: 0,
        zIndex: 20,
        px: { xs: 2, sm: 0 },
        pt: 2,
        pb: 0,
        textAlign: { xs: 'center', sm: 'left' },
      }}
    >
      <Stack>
        <Typography variant='h5' component='h1'>
          <b>{title}</b>
        </Typography>
        {subtitle1 && (
          <Typography variant='subtitle1' color='gray'>
            {subtitle1}
          </Typography>
        )}
        {subtitle2 && (
          <Typography variant='subtitle2' color='gray'>
            {subtitle2}
          </Typography>
        )}
      </Stack>

      <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
    </Box>
  );
}
