import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
//import {theme} from './theme';
import { Provider } from 'react-redux';
import { GlobalProvider } from './Context/GlobalContext';
import store from './redux-store';
import { AuthProvider } from './utils/auth/AuthService';

//check environment to see where the base app lives
const isProduction = process.env.NODE_ENV === 'production';
const basename = isProduction ? process.env.PUBLIC_URL : '';

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <GlobalProvider>
      <BrowserRouter basename={basename}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </GlobalProvider>
  </Provider>
);
