import dayjs from 'dayjs';

export const AgeUnits = {
  Hours: '204200ff-2009-4465-91b3-12f5a1092a7f',
  Weeks: '8bb1568c-cd26-4097-9756-1d4a1a5d1630',
  Days: 'cf203d26-49f6-4653-938a-2a4cd3448e8a',
  Years: '1df59643-3ece-41a0-8254-3d8acbd2f2ca',
  Months: '17eeaa6e-2e8d-4db9-9688-bbb52fe464ea',
  Minutes: '7f285191-5e18-4fe6-a477-cfd7d9a7750a',
  Unknown: 'a16d9364-f7ab-4fb3-8876-c1b278023a40',
};

interface AgeResult {
  value: number;
  unit: (typeof AgeUnits)[keyof typeof AgeUnits];
}

export function calculateAgeBirthToDeath({
  birth,
  death,
}: {
  birth: dayjs.Dayjs;
  death: dayjs.Dayjs;
}): AgeResult {
  const diffYears = death.diff(birth, 'year');
  const diffMonths = death.diff(birth.add(diffYears, 'year'), 'month');
  const diffDays = death.diff(birth.add(diffYears, 'year').add(diffMonths, 'month'), 'day');

  if (diffYears > 0) {
    return { value: diffYears, unit: AgeUnits.Years };
  } else if (diffMonths >= 3) {
    return { value: diffMonths, unit: AgeUnits.Months };
  } else if (diffMonths > 0 || diffDays > 0) {
    return { value: death.diff(birth, 'day'), unit: AgeUnits.Days };
  } else {
    const diffHours = death.diff(birth, 'hour');
    if (diffHours > 0) {
      return { value: diffHours, unit: AgeUnits.Hours };
    }

    const diffMinutes = death.diff(birth, 'minute');
    if (diffMinutes > 0) {
      return { value: diffMinutes, unit: AgeUnits.Minutes };
    }

    return { value: 0, unit: AgeUnits.Unknown };
  }
}

export function calculateAgeBirthToNow(birth: dayjs.Dayjs): AgeResult {
  const now = dayjs();
  return calculateAgeBirthToDeath({ birth, death: now });
}
