import React from 'react';
import { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

// project imports
import MainCard from '../../../../../../components/AdministrationModules/MainCard';
import ReferenceMasterTableFilter from '../ReferenceMasterTableFilter';
import AddEdit from './AddEdit';
import {
  activeData,
  deleteData,
  getAdminData,
} from '../../../../../../services/utility-api';

// material-ui
import { Grid, Box, Button, IconButton, Tooltip, Divider } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';
import AccessDenied from '../../../../../../components/AdministrationModules/AccessDenied';
import Breadcrumb from '../../../../../../components/AdministrationModules/Breadcrumb';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
const Item = () => {
  const referenceMasterTableName = 'Item';
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const referencePermissions = permissions?.['Reference Data'];
  const [isAddEditOn, setIsAddEditOn] = useState();
  const [editObj, setEditObj] = useState({});
  const [maxOrder, setMaxOrder] = useState(0);
  const [isCounty, setIsCounty] = useState(false);
  const [openActiveInActiveDialogBox, setOpenActiveInActiveDialogBox] =
    useState(false);
  const [activeInactiveClickData, setActiveInactiveClickData] = useState({});
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const [activeInActiveFilter, setActiveInActiveFilter] = useState('Active');

  const [columns, setColumns] = useState([
    {
      field:
        referenceMasterTableName[0].toLowerCase() +
        referenceMasterTableName.slice(1) +
        'Name',

      headerName: referenceMasterTableName
        .split('')
        .map((alpha) => {
          if (alpha <= 'Z') return ' ' + alpha;
          return alpha;
        })
        .join('')
        .trim(),
      // headerClassName: 'super-app-theme--header',
      minWidth: 150,
      align: 'left',
      flex: 5,
    },
    {
      field: 'itemOrder',
      headerName: 'List Order',
      // headerClassName: 'super-app-theme--header',
      minWidth: 150,
      align: 'left',
      flex: 5,
    },
  ]);
  const [rows, setRows] = useState([]);
  const [rowsToShow, setRowsToShow] = useState([]);
  const [countyNames, setCountyNames] = useState({});
  const MENU_OBJECT = {
    Home: '/sysadmin',
    'Reference Data': '/sysadmin',
    [referenceMasterTableName
      .split('')
      .map((alpha) => {
        if (alpha <= 'Z') return ' ' + alpha;
        return alpha;
      })
      .join('')
      .trim()]: '',
  };
  let isStatusColumnAdded = false;
  columns.forEach((column) => {
    if (column.field == 'status') {
      isStatusColumnAdded = true;
    }
  });
  if (!isStatusColumnAdded) {
    setColumns((prev) => [
      ...prev,
      {
        field: 'status',
        headerName: 'Status',
        // headerClassName: 'super-app-theme--header',
        renderCell: (cellValues) => {
          const handleClick = async (row) => {
            // if (!permissions?.['Reference Data']?.edit) return;
            setOpenActiveInActiveDialogBox(true);
            const newIconData = row.status === 'Active' ? 'locked' : 'done';
            setActiveInactiveClickData({
              icon: newIconData,

              seq: row?.itemSeq,
              status: row.status,
            });
          };

          return Number(
            cellValues.row[
            referenceMasterTableName[0].toLowerCase() +
            referenceMasterTableName.slice(1) +
            'Order'
            ]
          ) > 90000 ? (
            <></>
          ) : (
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleClick(cellValues.row);
              }}
            //   disabled={!permissions?.['Reference Data']?.edit}
            >
              {cellValues.row.status === 'Active' ? (
                <Tooltip title='Active'>
                  <LockIcon />
                </Tooltip>
              ) : (
                <Tooltip title='InActive'>
                  <DoneIcon />
                </Tooltip>
              )}
            </Button>
          );
        },
      },
    ]);
  }
  const getAllData = async () => {
    const response = await getAdminData(referenceMasterTableName);

    const data = response?.data?.data.map((row) => {
      const newObj = {};
      Object.keys(row).map((key) => {
        newObj[key] = row[key];
      });
      newObj.id = newObj['itemSeq'];
      if (newObj['itemOrder'])
        newObj['itemOrder'] = Number(newObj['itemOrder']);
      return newObj;
    });
    let maxOrder = 0;
    for (let i = 0; i < data.length; i++) {
      if (
        Number(
          data[i][
          referenceMasterTableName[0].toLowerCase() +
          referenceMasterTableName.slice(1) +
          'Order'
          ]
        ) > maxOrder &&
        Number(
          data[i][
          referenceMasterTableName[0].toLowerCase() +
          referenceMasterTableName.slice(1) +
          'Order'
          ]
        ) < 90000
      )
        maxOrder = Number(
          data[i][
          referenceMasterTableName[0].toLowerCase() +
          referenceMasterTableName.slice(1) +
          'Order'
          ]
        );
    }
    setMaxOrder(maxOrder);
    setRows(data);
    setRowsToShow(
      data.filter((row) => row['status'].toLowerCase() == 'active')
    );
  };

  const editRow = (params) => {
    if (referencePermissions?.edit) {
      if (params?.row?.status.toLowerCase() === 'active') {
        setIsAddEditOn(false);
        setTimeout(() => {
          setEditObj(params.row);
          setIsAddEditOn(true);
        }, 200);
      }
    }
  };
  const changeActiveInActiveClick = async () => {
    try {
      if (activeInactiveClickData.status === 'Active') {
        const responseMessage = await deleteData({
          seq: activeInactiveClickData.seq,
          tableName: referenceMasterTableName,
        });
        setDialogContents(responseMessage);
      } else {
        const responseMessage = await activeData({
          seq: activeInactiveClickData.seq,
          tableName: referenceMasterTableName,
        });
        setDialogContents(responseMessage);
      }
      setOpenActiveInActiveDialogBox(false);
      setOpenApiDialogBox(true);
      await getAllData();
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);

  if (!referencePermissions?.view) {
    return (<>
      <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
      <AccessDenied />
    </>)
  }
  else {
    return (
       // <div className='container my-5 py-5'>
       <Grid container spacing={2} sx={{mt: '10px', padding: '16px'}}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
              <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
              {/* <MainCard> */}
              <Box
                // sx={{
                //   width: '100%',
                //   '& .super-app-theme--header': {
                //     backgroundColor: 'rgb(2, 136, 209)',
                //     color: '#fff',
                //   },
                // }}
                style={{ tr: 'hover', cursor: 'pointer' }}
              >
                <ReferenceMasterTableFilter
                  rows={rows}
                  isCounty={isCounty}
                  countyNames={countyNames}
                  setRowsToShow={setRowsToShow}
                  activeInActiveFilter={activeInActiveFilter}
                  setActiveInActiveFilter={setActiveInActiveFilter}
                />
                  <DataGrid
                    initialState={{
                      sorting: {
                        sortModel: [
                          {
                            field:
                              referenceMasterTableName[0].toLowerCase() +
                              referenceMasterTableName.slice(1) +
                              'Name',
                            sort: 'asc',
                          },
                        ],
                      },
                    }}
                    rowHeight={35}
                    rows={rowsToShow}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick={false}
                    onRowClick={editRow}
                    // sx={{
                    //   m: 2,
                    //   marginLeft: '0px',
                    //   fontSize: '14px',
                    //   fontFamily:
                    //     'Roboto , Helvetica , Arial , sans-serif !important',
                    //   height: 500,
                    //   width: '100%',
                    //   direction: 'row',
                    //   overflow: 'hidden',
                    //   borderRadius: '6px',
                    //   backgroundColor: 'white',
                    //   boxShadow: '0px 0px 10px #ddd',
                    // }}
                  />
                </Box>
                <Box>
                  <Button
                    variant='contained'
                    sx={{ marginTop: '7px' }}
                    onClick={() => {
                      setIsAddEditOn(false);
                      setTimeout(() => {
                        setEditObj({});
                        setIsAddEditOn(true);
                      }, 200);
                    }}
                    disabled={!referencePermissions?.add}
                  >
                    Add{' '}
                    {referenceMasterTableName
                      .split('')
                      .map((alpha) => {
                        if (alpha <= 'Z') return ' ' + alpha;
                        return alpha;
                      })
                      .join('')
                      .trim()}{' '}
                  </Button>
                </Box>
                <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                {isAddEditOn && editObj && (
                  <AddEdit
                    maxOrder={maxOrder}
                    editObj={editObj}
                    referenceMasterTableName={referenceMasterTableName}
                    setIsAddEditOn={setIsAddEditOn}
                    getAllData={getAllData}
                    referencePermissions={referencePermissions}
                  />
                )}

                <div className='modal'>
                  <Dialog
                    open={openActiveInActiveDialogBox}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpenActiveInActiveDialogBox(false)}
                    aria-describedby='alert-dialog-slide-description'
                  >
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                      <DialogContentText id='alert-dialog-slide-description'>
                        Are you sure you want to{' '}
                        {activeInactiveClickData?.status === 'Active'
                          ? 'InActive '
                          : 'Active'}{' '}
                        this item?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => setOpenActiveInActiveDialogBox(false)}
                      >
                        No
                      </Button>
                      <Button onClick={changeActiveInActiveClick}>Yes</Button>
                    </DialogActions>
                  </Dialog>
                </div>

                <div className='modal'>
                  <Dialog
                    open={openApiDialogBox}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpenApiDialogBox(false)}
                    aria-describedby='alert-dialog-slide-description'
                  >
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                      <DialogContentText id='alert-dialog-slide-description'>
                        {dialogContents}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <IconButton onClick={() => setOpenApiDialogBox(false)}>
                        <CloseIcon />
                      </IconButton>
                    </DialogActions>
                  </Dialog>
                </div>
              {/* </MainCard> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      // </div>
    );
  }
};
export default Item;
