import { Box, Chip, Paper, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../../utils/auth/AuthService';

export function TurnaroundTimeChiclet() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(0);
  const [percent, setPercent] = useState(0);
  const navigate = useNavigate();
  const { REACT_APP_API_URL } = process.env;

  const handleChicletClick = () => {
    navigate('/reports/turnaroundtimereport');
  };
  const { user } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      let formData = new FormData();

      formData.append('JDXLIST', JSON.stringify(user?.jdxAccessList));

      if (user && user.token && user.jdxAccessList != null) {
        await fetch(REACT_APP_API_URL + 'getTurnaroundTimeChiclet', {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
          body: formData,
        })
          .then(res => {
            if (res.status == 401) {
              throw new Error('You unauthorized to use this tool');
            } else if (res.status >= 400) {
              throw new Error('An error occured');
            }
            return res.json();
          })
          .then(data => {
            if (data == -1) {
              setData(-1);
            } else {
              setData(data);
            }
          })
          .catch(e => {
            console.log(e);
          });
      } else {
        console.log('user or token is null');
      }

      var b = new Date(); // 1 Jan -> 30 Dec

      // @ts-ignore
      formData.append('THRESHOLD', 60);
      // @ts-ignore
      formData.append('YEAR', null);
      formData.append('ENDDATE', b.toISOString());
      b.setDate(b.getDate() - 90);
      formData.append('STARTDATE', b.toISOString());

      if (user && user.token && user.jdxAccessList != null) {
        await fetch(REACT_APP_API_URL + 'getMETurnaroundTimeChiclet', {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
          body: formData,
        })
          .then(res => {
            if (res.status == 401) {
              throw new Error('You unauthorized to use this tool');
            } else if (res.status >= 400) {
              throw new Error('An error occured');
            }
            return res.json();
          })
          .then(data => {
            setPercent(data);
            setLoading(false);
          })
          .catch(e => {
            alert(e);
          });
      } else {
        console.log('user or token is null');
      }
    };

    fetchData();
  }, []);

  return loading ? (
    <Skeleton
      variant='rounded'
      width='100%'
      height='100%'
      sx={{
        borderRadius: 30,
      }}
    />
  ) : (
    <Paper sx={{ p: 0, borderRadius: 20 }}>
      <Chip
        sx={{
          height: '100%',
          width: '100%',
        }}
        label={
          <Box component='div' sx={{ xs: { p: 0, m: 0 }, md: { p: 0.5, m: 0.5 } }}>
            <Typography variant='h6'>
              Turnaround Time: <b>{data}</b> Day{data != 1 && 's'}
            </Typography>
            <Typography variant='caption'>
              <i>% Complete Within 60 Days: </i>
              <i
                style={{
                  fontSize: '7pt',
                  color: percent >= 90 ? 'green' : 'red',
                }}
              >
                <b>{percent}%</b>
              </i>
            </Typography>
          </Box>
        }
        onClick={handleChicletClick}
      />
    </Paper>
  );
}
