import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  Paper,
  Slide,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useNavigate } from 'react-router';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';
import { useAuth } from '../../../utils/auth/AuthService';
import { format } from 'date-fns';
import { ActionList } from '../../../components/CaseView/CaseViewDateTimeField';

export default function ApprovedCasesByPathologistView() {
  const [numberOfCasesFound, setNumberOfCasesFound] = useState('');
  const [loading, setLoading] = useState(false);
  const [listResults, setListResults] = useState([]);
  const [dateAlertVisible, setDateAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [pathologistAlertVisible, setPathologistAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [pathologistOptions, setPathologistOptions] = useState([]);
  const navigate = useNavigate();
  const { user } = useAuth();
  const theme = useTheme();
  const { REACT_APP_API_URL } = process.env;

  const formik = useFormik({
    initialValues: {
      jurisdictions: user?.jdxAccessList ?? [],
      pathologists: [],
      searchFromDate: null,
      searchToDate: null,
      monthAndYear: null,
    },
    enableReinitialize: true,
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    // INITIALIZE PATHOLOGIST OPTIONS
    fetch(REACT_APP_API_URL + 'getpathologistoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setPathologistOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  }, []);

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const columns = [
    {
      field: 'caseID',
      headerName: 'Case ID',
      width: 105,
      editable: false,
      headerAlign: 'center',

      renderCell: params => {
        return (
          <NavLink
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
            }}
          >
            {params.formattedValue}
          </NavLink>
        );
      },
    },
    {
      field: 'jdxname',
      headerName: 'Jurisdiction',
      width: 190,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'decedent',
      headerName: 'Decedent',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'pathologist',
      headerName: 'Pathologist',
      width: 190,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'caseReleasedDateTime',
      headerName: 'Date Released',
      type: 'dateTime',
      valueGetter: value => value && new Date(value),
      valueFormatter: value => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
      },
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'caseApprovedRejectedDateTime',
      headerName: 'Date Approved or Rejected',
      type: 'dateTime',
      valueGetter: value => value && new Date(value),
      valueFormatter: value => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
      },
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
  ];

  const dismissDateAlert = () => {
    setDateAlertVisible(false);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  const dismissPathologistAlert = () => {
    setPathologistAlertVisible(false);
  };

  const dismissAlerts = () => {
    dismissDateAlert();
    dismissJdxAlert();
    dismissPathologistAlert();
  };

  //gather search params from form and FETCH/POST to API
  const handleSubmit = async values => {
    dismissAlerts();

    if (
      (values?.searchFromDate == null || values?.searchToDate == null) &&
      values?.monthAndYear == null
    ) {
      setDateAlertVisible(true);
      return;
    }

    if ((values?.jurisdictions ?? []).length == 0) {
      setJdxAlertVisible(true);
      return;
    }

    if ((values?.pathologists ?? []).length == 0) {
      setPathologistAlertVisible(true);
      return;
    }

    setLoading(true);
    setResultsVisible(true);

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'GetApprovedReleaseCasesByPathologist', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          } else if (res.status == 204) {
            return;
          }
          return res.json();
        })
        .then(data => {
          setListResults(data ?? []);
          setNumberOfCasesFound(data?.length ?? 0);
          setLoading(false);
        })
        .catch(e => {
          setListResults([]);
          setNumberOfCasesFound(0);
          console.error(e);
        });
    } else {
      console.log('user or token is null');
    }

    setLoading(false);
  };

  return (
    <Grid container spacing={2}>
      <Card
        raised
        sx={{
          my: 3,
          mx: 'auto',
          width: { xs: '95%', lg: '75%' },
        }}
      >
        <CardHeader
          title='Approved/Rejected Release Cases by Pathologists'
          sx={{
            mx: 'auto',
            textAlign: 'center',
            color: theme.palette.primary.main,
          }}
          action={
            <Tooltip
              sx={{ color: theme.palette.text.primary }}
              title='This report enumerates the approved or rejected release cases in the chosen jurisdiction(s) for the chosen pathologist(s) over the chosen date range'
              placement='right-end'
            >
              <InfoIcon />
            </Tooltip>
          }
        />

        <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

        <CardContent>
          <Slide direction='right' in={dateAlertVisible} mountOnEnter unmountOnExit sx={{ mb: 2 }}>
            <Alert severity='warning' onClose={dismissDateAlert}>
              Please either enter a date range or choose a month and year for when the case was
              approved/rejected.
            </Alert>
          </Slide>

          <Slide direction='right' in={jdxAlertVisible} mountOnEnter unmountOnExit sx={{ mb: 2 }}>
            <Alert severity='warning' onClose={dismissJdxAlert}>
              Please select at least 1 jusrisdiction.
            </Alert>
          </Slide>

          <Slide
            direction='right'
            in={pathologistAlertVisible}
            mountOnEnter
            unmountOnExit
            sx={{ mb: 2 }}
          >
            <Alert severity='warning' onClose={dismissPathologistAlert}>
              Please select at least 1 pathologist.
            </Alert>
          </Slide>

          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} md={12}>
              <Autocomplete
                multiple
                id='jurisdictions'
                name='jurisdictions'
                options={
                  user?.jdxAccessList.sort((a, b) => a.jdxName.localeCompare(b.jdxName)) ?? []
                }
                value={formik.values?.jurisdictions}
                disableCloseOnSelect
                limitTags={4}
                size='small'
                getOptionLabel={option => option.jdxName}
                onChange={(event, value) => formik.setFieldValue('jurisdictions', value)}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<MdCheckBoxOutlineBlank />}
                      checkedIcon={<MdOutlineCheckBox />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.jdxName}
                  </li>
                )}
                fullWidth
                renderInput={params => (
                  <TextField
                    {...params}
                    label={
                      formik.values?.jurisdictions.length == 0
                        ? 'All Jurisdictions'
                        : 'Jurisdiction'
                    }
                    placeholder={
                      formik.values?.jurisdictions.length == 0
                        ? 'All Jurisdictions'
                        : 'Jurisdiction'
                    }
                  />
                )}
                ListboxComponent={'ul'}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                multiple
                id='pathologists'
                name='pathologists'
                options={pathologistOptions ?? []}
                value={formik.values?.pathologists}
                disableCloseOnSelect
                limitTags={4}
                size='small'
                getOptionLabel={option => option.optionName}
                onChange={(event, value) => formik.setFieldValue('pathologists', value)}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<MdCheckBoxOutlineBlank />}
                      checkedIcon={<MdOutlineCheckBox />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.optionName}
                  </li>
                )}
                fullWidth
                renderInput={params => (
                  <TextField {...params} label='Pathologist' placeholder='Pathologist' />
                )}
                ListboxComponent={'ul'}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  id='searchFromDate'
                  name='searchFromDate'
                  label='Approval Date From'
                  value={formik.values.searchFromDate}
                  onChange={value => formik.setFieldValue('searchFromDate', value)}
                  onBlur={formik.handleBlur}
                  disabled={formik.values.monthAndYear}
                  slotProps={{
                    field: { clearable: true },
                  }}
                  slots={{
                    actionBar: ActionList,
                  }}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  id='searchToDate'
                  name='searchToDate'
                  label='Approval Date To'
                  value={formik.values.searchToDate}
                  onChange={value => formik.setFieldValue('searchToDate', value)}
                  onBlur={formik.handleBlur}
                  disabled={formik.values.monthAndYear}
                  slotProps={{
                    field: { clearable: true },
                  }}
                  slots={{
                    actionBar: ActionList,
                  }}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs='auto'>
              <Typography>OR</Typography>
            </Grid>

            <Grid item xs>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  id='monthAndYear'
                  name='monthAndYear'
                  value={formik.values?.monthAndYear}
                  onChange={value => formik.setFieldValue('monthAndYear', value)}
                  label='Month Approved or Rejected'
                  views={['month', 'year']}
                  onBlur={formik.handleBlur}
                  disabled={formik.values.searchFromDate || formik.values.searchToDate}
                  slotProps={{
                    field: { clearable: true },
                  }}
                  slots={{
                    actionBar: ActionList,
                  }}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Divider sx={{ my: 2, opacity: 1 }} />

          <Stack
            direction='row'
            sx={{
              width: '100%',
              justifyContent: 'right',
            }}
            spacing={2}
          >
            <Button type='submit' variant='contained' color='primary' onClick={formik.handleSubmit}>
              Search
            </Button>

            <Button
              color='secondary'
              variant='outlined'
              onClick={() => {
                formik.resetForm();
                setResultsVisible(false);
                dismissAlerts();
              }}
            >
              Clear
            </Button>
          </Stack>

          <Collapse in={resultsVisible} sx={{ mt: resultsVisible ? 4 : 0 }}>
            <ReportDataGrid
              loading={loading}
              listresults={listResults}
              idcolumn='caseID'
              columnsInput={columns}
              title='Your Releases'
              chatcontent={
                <Paper
                  elevation={3}
                  sx={{
                    p: '10px',
                    px: '20px',
                    borderRadius: '20px',
                    width: 'max-content',
                  }}
                >
                  <div style={{ textAlign: 'center' }}>
                    I found <b>{numberOfCasesFound} </b> cases that matches your search below... you
                    can export this list to csv format via the 'EXPORT' button in the top-right of
                    the grid. <br></br>
                    <i>
                      (The double-arrow icon next to this button will expand the grid to a
                      full-sized view)
                    </i>
                  </div>
                </Paper>
              }
              casecount
            />
          </Collapse>
        </CardContent>
      </Card>
    </Grid>
  );
}
