import React from 'react';
import { IconButton, CircularProgress, IconButtonProps, Box } from '@mui/material';

interface LoadingIconButtonProps extends IconButtonProps {
  loading?: boolean;
}

const LoadingIconButton: React.FC<LoadingIconButtonProps> = ({
  loading = false,
  children,
  ...props
}) => {
  return (
    <Box position='relative' display='inline-flex'>
      <IconButton
        {...props}
        disabled={loading || props.disabled}
        sx={{
          ...props.sx,
          position: 'relative',
        }}
      >
        {children}
      </IconButton>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>
  );
};

export default LoadingIconButton;
