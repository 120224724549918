import React, { useContext } from 'react';
import { GlobalContext } from '../../../../Context/GlobalContext';
import { Divider, Stack, Typography } from '@mui/material';

const UserHeaderContainer = () => {
  const { profileInformation } =
    useContext(GlobalContext);
  return (
    <>
      <Stack>
        <Typography variant='h5' component='h1'>
        {window.location.pathname.includes('add-user') ? <b>Enter New User</b> : <b>Modify User</b> }
        </Typography>
      </Stack>
      <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
      <br />
      {/* {window.location.pathname.includes('add-user') ? (
        <div
          style={{
            background: '#003',
            borderRadius: '12px',
            padding: '10px 14px',
          }}
        >
          <h4 style={{ color: 'white' }}>Enter New User</h4>
        </div>
      ) : (
        <div
          style={{
            background: '#003',
            borderRadius: '12px',
            padding: '20px 24px',
          }}
        >
          <h3 style={{ color: 'white' }}>Modify User</h3>
        </div>
      )} */}
    </>
  );
};

export default UserHeaderContainer;
