import { Avatar, AvatarProps } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useAuth } from '../../utils/auth/AuthService';

interface UserAvatarProps extends AvatarProps {
  initials?: string;
  userseq?: string;
}

export function UserAvatar(props: UserAvatarProps) {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [src, setSrc] = useState('');

  const transformedInitials = props.initials?.toUpperCase() ?? '';

  const fetchAvatar = async () => {
    try {
      // @ts-ignore
      const url = `${process.env.REACT_APP_API_URL}GetUserPicture?userseq=${props.userseq}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + auth.user?.accessToken,
          'Content-Type': 'application/json',
        },
      });
      if (response.data.data.length > 0) {
        setSrc(`data:image/png;base64,${response?.data?.data?.[0]?.photo}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAvatar();
  }, [props.userseq]);

  return (
    <Avatar {...props} sx={{ ...props.sx, fontSize: '0.7rem' }} src={src}>
      {transformedInitials}
    </Avatar>
  );
}
