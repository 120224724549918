import React, { Component, useEffect, useState } from 'react';
import { Button, Stack, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router';
import AuthService from '../../services/auth.service';
import {
  DataGridPremium,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ReportDataGrid from '../../utils/components/ReportDataGrid/ReportDataGrid';
import { useAuth } from '../../utils/auth/AuthService.tsx';
import { useLocation } from 'react-router-dom';

export default function ClientAdminView() {
  const navigate = useNavigate();
  const [clientsResults, setclientsResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentUser, setcurrentUser] = useState(false);
  const [redirect, setredirect] = useState(null);
  const { state } = useLocation();
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [selectedProcedureData, setSelectedProcedureData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useAuth();

  const columns = [
    { field: 'ClientSeq', headerName: 'Client Sequence', width: 200 },
    { field: 'ClientCode', headerName: 'Client Code', width: 200 },
    { field: 'ClientName', headerName: 'Client Name', width: 200 },
    { field: 'ContactName', headerName: 'Contact Name', width: 200 },
    { field: 'ContactEmail', headerName: 'Contact Email', width: 200 },
    { field: 'ContactPhone', headerName: 'Contact Phone', width: 150 },
    { field: 'BillingContactName', headerName: 'Billing Contact Name', width: 220 },
    { field: 'BillingContactEmail', headerName: 'Billing Contact Email', width: 220 },
    { field: 'BillingContactPhone', headerName: 'Billing Contact Phone', width: 200 },
    { field: 'BillingStreet', headerName: 'Billing Street', width: 200 },
    { field: 'BillingCity', headerName: 'Billing City', width: 150 },
    { field: 'BillingState', headerName: 'Billing State', width: 150 },
    { field: 'BillingPostalCode', headerName: 'Billing Postal Code', width: 180 },
    { field: 'BillingCountry', headerName: 'Billing Country', width: 180 },
    { field: 'MailingStreet', headerName: 'Mailing Street', width: 200 },
    { field: 'MailingCity', headerName: 'Mailing City', width: 150 },
    { field: 'MailingState', headerName: 'Mailing State', width: 150 },
    { field: 'MailingPostalCode', headerName: 'Mailing Postal Code', width: 180 },
    { field: 'MailingCountry', headerName: 'Mailing Country', width: 180 },
    { field: 'ClientID', headerName: 'Client ID', width: 150 },
  ];

  const { REACT_APP_API_URL } = process.env;

  useEffect(() => {
    loadData(); //move the API code into a function for loading by default
  }, []);

  //gather search params from form and FETCH/POST to API
  const loadData = async () => {
    let formData = new FormData();

    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      setredirect('/login');
    }
    setcurrentUser(currentUser);

    setLoading(true);

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'getLIMSClients', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setclientsResults(data);
          setLoading(false);
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      console.log('user or token is null');
    }
  };

  return (
    <ReportDataGrid
      loading={loading}
      listresults={clientsResults}
      columnsInput={columns}
      headerAlignment='left'
      columnAlignment='left'
      idcolumn='labtestresultseq'
      gridheight={200}
      gridcolor='#006400'
      title='Lab Testing Results'
      casecount
      rowsperpage={10}
    />
  );
}
