import { useEffect, useState } from 'react';
// material-ui
import { Grid, Box, Divider } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

import CaseVisitSearchFilter from './CaseVisitSearchFilter';
import axios from 'axios';
import MainCard from '../../../../components/AdministrationModules/MainCard';
import {
    DataGridPremium, gridPageCountSelector,
    gridPageSelector, useGridApiContext
} from '@mui/x-data-grid-premium';

const columns = [
    {
        field: 'fullName',
        headerName: 'User',
        // headerClassName: 'super-app-theme--header',
        width: 130,
        align: 'left',
        flex: 5
    },
    {
        field: 'caseID',
        headerName: 'Case ID',
        // headerClassName: 'super-app-theme--header',
        width: 130,
        align: 'left',
        flex: 5
    },
    {
        field: 'ip',
        headerName: 'IP',
        // headerClassName: 'super-app-theme--header',
        width: 130,
        align: 'left',
        flex: 5
    },
    {
        field: 'visitTime',
        headerName: 'Visit Time',
        // headerClassName: 'super-app-theme--header',
        width: 130,
        align: 'left',
        flex: 5
    }
];

// ==============================|| DEFAULT DASHBOARD ||============================== //
function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = gridPageSelector(apiRef);
    const pageCount = gridPageCountSelector(apiRef);
    return (
        <Pagination
            color='primary'
            variant='outlined'
            shape='rounded'
            page={page + 1}
            count={pageCount}
            // @ts-expect-error
            renderItem={(props2) => (
                <PaginationItem
                    {...props2}
                    sx={{ fontFamily: 'DataGrid, sans-serif' }}
                    disableRipple
                />
            )}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
            sx={{ mr: 3 }}
        />
    );

}

const CaseVisitView = () => {
    const [rows, setRows] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
    const caseVisitPermissions = permissions?.['Case Visit'];

    if (!caseVisitPermissions?.view) {
        return (<>
            <AccessDenied />
        </>)
    }
    else {
        return (
            <Grid container
                spacing={3}
            // style={{ width: "100%" }}
            >
                <Grid item xs={12}>
                    <Grid container
                        spacing={3}
                    >
                        <Grid item xs={12} md={12}>
                            <Grid>
                                <CaseVisitSearchFilter setRows={setRows} setShowTable={setShowTable} />
                                <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0, mb: 3 }} />
                                {showTable ? (
                                    <Box
                                        // sx={{
                                        //     width: '100%',
                                        //     '& .super-app-theme--header': {
                                        //         backgroundColor: '#2196f3'
                                        //     }
                                        // }}
                                        style={{ tr: 'hover', cursor: 'pointer' }}
                                    >
                                        <DataGridPremium
                                            getRowId={(row, id) => row.visitTime + row.fullName}
                                            rows={rows}
                                            columns={columns}
                                            columnHeaderHeight={50}
                                            rowHeight={30}
                                            pageSize={50}
                                            scrollbarSize={5}
                                            pagination
                                            slots={{
                                                pagination: CustomPagination,
                                            }}
                                            // sx={{
                                            //     width: '100%',
                                            //     height: 450,
                                            //     borderRadius: '6px',
                                            //     backgroundColor: 'white',
                                            //     boxShadow: '0px 0px 10px #ddd',
                                            //     mt: 2,
                                            // }}
                                        />

                                    </Box>) : null}

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
};

export default CaseVisitView;
