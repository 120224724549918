import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  Alert,
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import Hls from 'hls.js';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth } from '../../../../utils/auth/AuthService';
import VideoControls from './VideoControls';

const { REACT_APP_API_URL, REACT_APP_VIDEO_LIBRARY_BASE_URL } = process.env;

interface VideoPlayerProps {
  videoSeq: string;
  thumbnailURL?: string;
  videoProps?: React.VideoHTMLAttributes<HTMLVideoElement>;
  qualitySelectorAutocompleteProps?: Partial<React.ComponentProps<typeof Autocomplete>>;
}

interface QualityLevel {
  index: number;
  height: number;
  bitrate: number;
}

interface QualityOption {
  label: string;
  value: number;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videoSeq,
  thumbnailURL,
  videoProps,
  qualitySelectorAutocompleteProps,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [hlsInstance, setHlsInstance] = useState<Hls | null>(null);
  const [qualityLevels, setQualityLevels] = useState<QualityLevel[]>([]);
  const [currentQuality, setCurrentQuality] = useState<QualityOption | null>({
    label: 'Auto',
    value: -1,
  });
  const [aspectRatio, setAspectRatio] = useState<string>('16:9');
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();

  // State for custom controls
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(100);
  const [isMuted, setIsMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [isPiP, setIsPiP] = useState(false);
  const hideControlsTimeout = useRef<NodeJS.Timeout | null>(null);

  const [isSeeking, setIsSeeking] = useState(false);
  const [seekValue, setSeekValue] = useState(0);

  const [error, setError] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Error handling function
  const handleError = (message: string) => {
    setError(message);
    setSnackbarOpen(true);
  };

  // Snackbar close handler
  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // // Fetch video metadata to set aspect ratio
  // useEffect(() => {
  //   const fetchVideoMetadata = async () => {
  //     try {
  //       const response = await fetch(`${REACT_APP_API_URL}videos/${videoSeq}/metadata`, {
  //         headers: {
  //           Authorization: `Bearer ${user?.accessToken}`,
  //         },
  //       });
  //       if (!response.ok) throw new Error('Failed to fetch video metadata');
  //       const metadata = await response.json();
  //       if (metadata.width && metadata.height) {
  //         setAspectRatio(`${metadata.width}:${metadata.height}`);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching video metadata:', error);
  //       // Fallback to default aspect ratio if metadata fetch fails
  //       setAspectRatio('16:9');
  //     }
  //   };

  //   fetchVideoMetadata();
  // }, [videoSeq, user?.accessToken]);

  useEffect(() => {
    if (videoRef.current) {
      const videoElement = videoRef.current;
      const videoSrc = `${REACT_APP_VIDEO_LIBRARY_BASE_URL}${videoSeq}/playlist.m3u8`;

      if (Hls.isSupported()) {
        const hls = new Hls({
          startLevel: 0, // Start with the lowest quality level
          autoStartLoad: true,
          enableWorker: true,
          capLevelToPlayerSize: true,
          lowLatencyMode: true,
          backBufferLength: 90,
          startFragPrefetch: true,
          capLevelOnFPSDrop: true,
        });

        hls.config.xhrSetup = function (xhr) {
          xhr.setRequestHeader('Authorization', `Bearer ${user?.accessToken}`);
        };

        hls.loadSource(videoSrc);
        hls.attachMedia(videoElement);
        setHlsInstance(hls);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          const levels = hls.levels.map((level, index) => ({
            index,
            height: level.height,
            bitrate: level.bitrate,
          }));
          setQualityLevels(levels);
          setCurrentQuality({ label: 'Auto', value: -1 });
          setIsLoading(false);
        });

        hls.on(Hls.Events.ERROR, (event, data) => {
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                handleError(
                  'Network error while loading video. Please check your connection and try again.'
                );
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                handleError('Media error. The video might be corrupted or unsupported.');
                break;
              default:
                handleError('An error occurred while playing the video.');
                break;
            }
            hls.destroy();
          }
        });

        return () => {
          hls.destroy();
        };
      } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
        videoElement.src = videoSrc;
        videoElement.addEventListener('loadedmetadata', () => {
          setIsLoading(false);
        });
        videoElement.addEventListener('error', () => {
          handleError('An error occurred while loading the video.');
        });
      } else {
        handleError('This browser does not support HLS playback.');
      }
    }
  }, [videoSeq, user?.accessToken]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleTimeUpdate = () => {
      if (!isSeeking) {
        setCurrentTime(video.currentTime);
      }
    };

    const handleDurationChange = () => {
      setDuration(video.duration);
    };

    const handlePlay = () => {
      setIsPlaying(true);
      // Start loading and playing when the user initiates playback
      if (hlsInstance && hlsInstance.autoLevelEnabled === false) {
        hlsInstance.startLoad(0); // Start loading from the lowest level
      }
    };

    const handlePause = () => {
      setIsPlaying(false);
    };

    const handleEnterPiP = () => {
      setIsPiP(true);
    };

    const handleLeavePiP = () => {
      setIsPiP(false);
    };

    video.addEventListener('timeupdate', handleTimeUpdate);
    video.addEventListener('durationchange', handleDurationChange);
    video.addEventListener('play', handlePlay);
    video.addEventListener('pause', handlePause);
    video.addEventListener('enterpictureinpicture', handleEnterPiP);
    video.addEventListener('leavepictureinpicture', handleLeavePiP);

    return () => {
      video.removeEventListener('timeupdate', handleTimeUpdate);
      video.removeEventListener('durationchange', handleDurationChange);
      video.removeEventListener('play', handlePlay);
      video.removeEventListener('pause', handlePause);
      video.removeEventListener('enterpictureinpicture', handleEnterPiP);
      video.removeEventListener('leavepictureinpicture', handleLeavePiP);
    };
  }, [hlsInstance, isSeeking]);

  // Auto-hide controls after inactivity
  const resetControlsTimeout = useCallback(() => {
    if (hideControlsTimeout.current) {
      clearTimeout(hideControlsTimeout.current);
    }
    setShowControls(true);
    hideControlsTimeout.current = setTimeout(() => {
      setShowControls(false);
    }, 2000); // Hide after 2 seconds of inactivity
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleActivity = () => {
      resetControlsTimeout();
    };

    container.addEventListener('mousemove', handleActivity);
    container.addEventListener('mouseenter', handleActivity);
    container.addEventListener('touchstart', handleActivity);

    resetControlsTimeout();

    return () => {
      container.removeEventListener('mousemove', handleActivity);
      container.removeEventListener('mouseenter', handleActivity);
      container.removeEventListener('touchstart', handleActivity);
      if (hideControlsTimeout.current) {
        clearTimeout(hideControlsTimeout.current);
      }
    };
  }, [resetControlsTimeout]);

  useEffect(() => {
    if (isPlaying) {
      resetControlsTimeout();
    } else {
      setShowControls(true);
    }
  }, [isPlaying, resetControlsTimeout]);

  const qualityOptions: QualityOption[] = [
    { label: 'Auto', value: -1 },
    ...qualityLevels.map(level => ({ label: `${level.height}p`, value: level.index })),
  ];

  const handleQualityChange = (event: React.SyntheticEvent, newValue: QualityOption | null) => {
    if (newValue && hlsInstance) {
      setCurrentQuality(newValue);
      if (newValue.value === -1) {
        hlsInstance.currentLevel = -1; // Enable automatic quality switching
      } else {
        hlsInstance.currentLevel = newValue.value; // Set to specific quality level
      }
      resetControlsTimeout();
    }
  };

  // Custom Control Handlers
  const togglePlayPause = () => {
    const video = videoRef.current;
    if (!video) return;
    if (video.paused) {
      video.play().catch(error => {
        console.error('Error attempting to play the video:', error);
      });
    } else {
      video.pause();
    }
    resetControlsTimeout();
  };

  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    const vol = Array.isArray(newValue) ? newValue[0] : newValue;
    const video = videoRef.current;
    if (video) {
      video.volume = vol / 100;
      setVolume(vol);
      setIsMuted(vol === 0);
    }
    resetControlsTimeout();
  };

  const toggleMute = () => {
    const video = videoRef.current;
    if (!video) return;
    video.muted = !video.muted;
    setIsMuted(video.muted);
    setVolume(video.muted ? 0 : video.volume * 100);
    resetControlsTimeout();
  };

  const handleSeek = (event: Event, newValue: number | number[]) => {
    const time = Array.isArray(newValue) ? newValue[0] : newValue;
    setSeekValue(time);
    setIsSeeking(true);
  };

  const handleSeekCommitted = (event: React.SyntheticEvent, newValue: number | number[]) => {
    const time = Array.isArray(newValue) ? newValue[0] : newValue;
    const video = videoRef.current;
    if (video) {
      video.currentTime = time;
      setCurrentTime(time);
      setIsSeeking(false);
    }
    resetControlsTimeout();
  };

  const toggleFullscreen = () => {
    const videoContainer = containerRef.current;
    if (!videoContainer) return;

    if (!document.fullscreenElement) {
      videoContainer
        .requestFullscreen()
        .then(() => setIsFullscreen(true))
        .catch(err => {
          console.error(`Error attempting to enable fullscreen mode: ${err.message}`);
        });
    } else {
      document
        .exitFullscreen()
        .then(() => setIsFullscreen(false))
        .catch(err => {
          console.error(`Error attempting to disable fullscreen mode: ${err.message}`);
        });
    }
    resetControlsTimeout();
  };

  const togglePiP = async () => {
    const video = videoRef.current;
    if (!video) return;

    try {
      if (!isPiP) {
        await video.requestPictureInPicture();
      } else {
        await document.exitPictureInPicture();
      }
    } catch (error) {
      console.error('Error toggling Picture-in-Picture:', error);
    }
    resetControlsTimeout();
  };

  // Format time in mm:ss
  const formatTime = (seconds: number): string => {
    if (isNaN(seconds)) return '00:00';
    const flooredSeconds = Math.floor(seconds);
    const minutes = Math.floor(flooredSeconds / 60);
    const secs = flooredSeconds % 60;
    return `${padZero(minutes)}:${padZero(secs)}`;
  };

  const padZero = (num: number): string => (num < 10 ? `0${num}` : `${num}`);

  // Central Play/Pause Button Handler
  const handleCentralPlayPause = () => {
    togglePlayPause();
  };

  return (
    <Box
      ref={containerRef}
      position='relative'
      width='100%'
      sx={{
        position: 'relative',
        cursor: showControls ? 'default' : 'none',
      }}
    >
      <div
        style={{
          position: 'relative',
          paddingTop: aspectRatio
            ? `calc(${aspectRatio.split(':')[1]} / ${aspectRatio.split(':')[0]} * 100%)`
            : '56.25%', // Default to 16:9 if aspectRatio is null
          background: '#000',
          overflow: 'hidden',
        }}
      >
        <video
          ref={videoRef}
          // Remove native controls
          // controls
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: isLoading ? 'none' : 'block',
            backgroundColor: 'black',
            cursor: isPlaying ? 'default' : 'pointer',
          }}
          poster={thumbnailURL}
          onClick={togglePlayPause} // Toggle play/pause on video click
          {...videoProps}
        />

        {/* Central Play/Pause Button with Loading Indicator */}
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pointerEvents: 'none', // Allow clicks to pass through to IconButton
          }}
        >
          {isLoading && (
            <CircularProgress
              size={64} // Adjust size as needed
              sx={{
                position: 'absolute',
                color: 'rgba(255, 255, 255, 0.6)',
              }}
            />
          )}
          <IconButton
            onClick={e => {
              e.stopPropagation(); // Prevent event from bubbling to the video element
              handleCentralPlayPause();
            }}
            sx={{
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              opacity: showControls ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out, background-color 0.3s ease-in-out',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
              },
              pointerEvents: 'auto', // Enable clicks on the button
            }}
          >
            {isPlaying ? <PauseIcon fontSize='large' /> : <PlayArrowIcon fontSize='large' />}
          </IconButton>
        </Box>

        {/* Custom controls */}
        {!isLoading && (
          <VideoControls
            isPlaying={isPlaying}
            togglePlayPause={togglePlayPause}
            isMuted={isMuted}
            toggleMute={toggleMute}
            volume={volume}
            handleVolumeChange={handleVolumeChange}
            currentTime={currentTime}
            duration={duration}
            isSeeking={isSeeking}
            seekValue={seekValue}
            handleSeek={handleSeek}
            handleSeekCommitted={handleSeekCommitted}
            isFullscreen={isFullscreen}
            toggleFullscreen={toggleFullscreen}
            isPiP={isPiP}
            togglePiP={togglePiP}
            qualityOptions={qualityOptions}
            currentQuality={currentQuality}
            handleQualityChange={handleQualityChange}
            formatTime={formatTime}
            showControls={showControls}
          />
        )}
      </div>
      {error && (
        <Typography color='error' variant='body2' mt={1}>
          {error}
        </Typography>
      )}
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity='error' variant='filled'>
          <Stack>
            <Typography>{typeof error === 'string' && error}</Typography>
            <Typography>Please try again later</Typography>
          </Stack>
        </Alert>
      </Snackbar>
    </Box>
  );
};

// Fetch video thumbnail URL utility remains unchanged
export const fetchVideoThumbnailURL = async (videoSeq: string, accessToken: string) => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}videos/${videoSeq}/thumbnail`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch thumbnail');
    }

    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error('Error fetching thumbnail:', error);
    return null;
  }
};
