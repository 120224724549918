import React, { useState } from 'react';

import { Button, Grid, Typography, Divider, ListItem, List, Select, MenuItem, FormHelperText } from '@mui/material';

import { ErrorMessage } from 'formik';
import FormControl from '@mui/material/FormControl';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const id = '00000000-0000-0000-0000-000000000000';

const PrerequisiteTable = ({ values, handleChange, statuses, statusValueObjs }) => {
    const [openDeleteStatusDialogBox, setOpenDeleteStatusDialogBox] = useState(false);
    const [deleteStatusClickData, setDeleteStatusClickData] = useState(() => {
        setOpenDeleteStatusDialogBox(false);
    });
    return (
        <>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={12} mt={-1}>
                    <Button
                        onClick={() =>
                            handleChange({
                                target: {
                                    name: 'activityPrerequisite',
                                    value: [
                                        ...values.activityPrerequisite,
                                        {
                                            activityPrerequisiteSeq: id,
                                            activitySeq: values.activitySeq,
                                            statusTypeSeq: '',
                                            statusValueCode: '',
                                            statusTypeName: '',
                                            statusValueDesc: '',
                                            status: 'Active',
                                            isEqual: false
                                        }
                                    ]
                                }
                            })
                        }
                        variant="contained"
                        startIcon={<AddCircleIcon />}
                    >
                        Add Prerequisites
                    </Button>
                </Grid>
                {values?.activityPrerequisite && values?.activityPrerequisite?.length ? (
                    <Grid item xs={12}>
                        <List className='border border-bottom-0' sx={{ bgcolor: '#fff', py: '0px', color: '#000' }}>
                            <ListItem>
                                <Grid container spacing={2}>
                                    <Grid
                                        xs={4}
                                        item
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'relative',
                                            left: '-16px'
                                        }}
                                    >
                                        <Typography variant='h6' mt={0} sx={{ color: '#000' }}>
                                            Status Type
                                        </Typography>
                                        {/* <Divider sx={{ position: 'absolute', right: '-10px' }}>|</Divider> */}
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        item
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'relative',
                                            left: '-16px'
                                        }}
                                    >
                                        <Typography variant='h6' mt={0} sx={{ color: '#000' }}>
                                            Status Value
                                        </Typography>
                                        {/* <Divider sx={{ position: 'absolute', right: '-10px' }}>|</Divider> */}
                                    </Grid>
                                    <Grid
                                        xs={1}
                                        item
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'relative',
                                            left: '-16px'
                                        }}
                                    ></Grid>
                                </Grid>
                            </ListItem>
                        </List>

                        <List sx={{ paddingTop: '0px' }} className='statusTable border pb-0'>
                            {values?.activityPrerequisite &&
                                values?.activityPrerequisite.map((item, index) => (
                                    <ListItem
                                        key={index}
                                        id={index}
                                        sx={{
                                            py: '0px',
                                            marginBottom: '0px',
                                            backgroundColor: '#fff',
                                            display: item.status == 'Active' ? '' : 'none'
                                        }}
                                    >
                                        <Grid className='mt-0' container spacing={2}>
                                            <Grid
                                                xs={4}
                                                item
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    position: 'relative',
                                                    left: '-16px'
                                                }}
                                            >
                                                <FormControl variant="standard" sx={{ width: '90%' }}>
                                                    <Select
                                                        focused
                                                        displayEmpty
                                                        id={`activityPrerequisite.${index}.statusTypeSeq`}
                                                        variant="outlined"
                                                        name={`activityPrerequisite.${index}.statusTypeSeq`}
                                                        margin="normal"
                                                        size="large"
                                                        value={values ? values['activityPrerequisite'][index]['statusTypeSeq'] : ''}
                                                        onChange={(e) => {
                                                            handleChange({
                                                                target: {
                                                                    name: e.target.name,
                                                                    value: e.target.value
                                                                }
                                                            });
                                                            handleChange({
                                                                target: {
                                                                    name: `activityPrerequisite.${index}.statusTypeName`,
                                                                    value: statuses.filter((row) => row.statusTypeSeq == e.target.value)[0][
                                                                        'statusTypeName'
                                                                    ]
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        {statuses.map((row) => {
                                                            return (
                                                                <MenuItem key={row.statusTypeSeq} value={row.statusTypeSeq}>
                                                                    {row.statusTypeName}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                    <ErrorMessage
                                                        name={`activityPrerequisite.${index}.statusTypeSeq`}
                                                        render={(msg) => <FormHelperText error>{msg}</FormHelperText>}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                item
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    position: 'relative',
                                                    left: '-16px',
                                                    margin: '5px 0'
                                                }}
                                            >
                                                <FormControl variant="standard" sx={{ width: '90%' }}>
                                                    <Select
                                                        focused
                                                        displayEmpty
                                                        id={`activityPrerequisite.${index}.statusValueCode`}
                                                        variant="outlined"
                                                        name={`activityPrerequisite.${index}.statusValueCode`}
                                                        margin="normal"
                                                        size="large"
                                                        value={values ? values['activityPrerequisite'][index]['statusValueCode'] : ''}
                                                        onChange={(e) => {
                                                            handleChange({
                                                                target: {
                                                                    name: e.target.name,
                                                                    value: e.target.value
                                                                }
                                                            });
                                                            handleChange({
                                                                target: {
                                                                    name: `activityPrerequisite.${index}.statusValueDesc`,
                                                                    value: statusValueObjs[
                                                                        values['activityPrerequisite'][index]['statusTypeSeq']
                                                                    ].filter((row) => row.statusValueCode == e.target.value)[0]
                                                                        ? statusValueObjs[
                                                                              values['activityPrerequisite'][index]['statusTypeSeq']
                                                                          ].filter((row) => row.statusValueCode == e.target.value)[0][
                                                                              'statusValueDesc'
                                                                          ]
                                                                        : ''
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        {values['activityPrerequisite'][index]['statusTypeSeq'] &&
                                                            statusValueObjs[values['activityPrerequisite'][index]['statusTypeSeq']] &&
                                                            statusValueObjs[values['activityPrerequisite'][index]['statusTypeSeq']].map(
                                                                (row) => {
                                                                    return (
                                                                        <MenuItem key={row.statusValueCode} value={row.statusValueCode}>
                                                                            {row.statusValueDesc}
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            )}
                                                    </Select>
                                                    <ErrorMessage
                                                        name={`activityPrerequisite.${index}.statusValueCode`}
                                                        render={(msg) => <FormHelperText error>{msg}</FormHelperText>}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid
                                                className='my-0 pb-3'
                                                xs={1}
                                                item
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    position: 'relative',
                                                    margin: '5px 0'
                                                }}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        const deleteFunc = () => {
                                                            handleChange({
                                                                target: {
                                                                    name: 'activityPrerequisite',
                                                                    value:
                                                                        item.activityPrerequisiteSeq == id
                                                                            ? values.activityPrerequisite.filter((_, ind) => ind != index)
                                                                            : values.activityPrerequisite.map((item, ind) => {
                                                                                  if (ind == index) {
                                                                                      return {
                                                                                          ...item,
                                                                                          status: 'InActive'
                                                                                      };
                                                                                  }
                                                                                  return item;
                                                                              })
                                                                }
                                                            });
                                                            setOpenDeleteStatusDialogBox(false);
                                                        };
                                                        setOpenDeleteStatusDialogBox(true);
                                                        setDeleteStatusClickData(() => deleteFunc);
                                                        return;
                                                    }}
                                                    aria-label="delete"
                                                    size="large"
                                                    id={`row_id_${index}`}
                                                    style={{ backgroundColor: '#2196f3' }}
                                                >
                                                    <DeleteIcon fontSize="inherit" sx={{ color: '#fff' }} />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                ))}
                        </List>
                    </Grid>
                ) : null}
                <div className="modal">
                    <Dialog
                        open={openDeleteStatusDialogBox}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => setOpenDeleteStatusDialogBox(false)}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle></DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                Are you sure you want to remove this prerequisite?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenDeleteStatusDialogBox(false)}>No</Button>
                            <Button onClick={deleteStatusClickData}>Yes</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Grid>
        </>
    );
};

export default PrerequisiteTable;
