import React, { useEffect, useState, useRef } from 'react';
import cornerstone from 'cornerstone-core';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools';
import Hammer from 'hammerjs';
import dicomParser from 'dicom-parser';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import { Box, Skeleton } from '@mui/material';

cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;

export default function DicomViewer({ dicomSeriesData, isLoading, setIsLoading }) {
  const [imageData, setImageData] = useState(null);
  const [stack, setStack] = useState(null);
  const dicomImageRef = useRef(null);

  const initializeDicomSeries = () => {
    var imageIds = [];

    for (var instance of dicomSeriesData) {
      imageIds.push(cornerstoneWADOImageLoader.wadouri.fileManager.add(instance));
    }
    setStack({
      currentImageIdIndex: 0,
      imageIds,
    });
    loadAndViewImageStack(imageIds);

    return () => {
      cornerstoneWADOImageLoader.wadouri.fileManager.purge();
    };
  };

  useEffect(() => {
    if (imageData && dicomImageRef.current && stack) {
      const StackScrollMouseWheelTool = cornerstoneTools.StackScrollMouseWheelTool;

      // If imageData is available and dicomImageRef.current is not null,
      // then enable CornerstoneJS on the element and display the image.
      const element = dicomImageRef.current;
      cornerstone.enable(element);
      cornerstone.displayImage(element, imageData);

      const viewport = cornerstone.getDefaultViewportForImage(element, imageData);
      cornerstone.displayImage(element, imageData, viewport);
      cornerstoneTools.addStackStateManager(element, ['stack']);
      cornerstoneTools.addToolState(element, 'stack', stack);

      cornerstoneTools.addTool(StackScrollMouseWheelTool);
      cornerstoneTools.setToolActive('StackScrollMouseWheel', {});
    }
  }, [imageData, isLoading]);

  useEffect(() => {
    cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
    cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
    cornerstoneWADOImageLoader.webWorkerManager.initialize();

    if (dicomSeriesData != null && dicomSeriesData.length > 0) {
      initializeDicomSeries();
    }
  }, [dicomSeriesData]);

  function loadAndViewImageStack(imageIds) {
    // Setup tools
    cornerstoneTools.init();

    // Add Tool
    const LengthTool = cornerstoneTools['LengthTool'];
    cornerstoneTools.addTool(LengthTool);

    // Set Tool Active
    cornerstoneTools.setToolActive('Length', { mouseButtonMask: 1 });

    // Display an image
    cornerstone.loadImage(imageIds[0]).then(
      function (image) {
        setImageData(image);
        setIsLoading(false);
      },
      function (err) {
        // console.log(err);
      }
    );
  }

  return isLoading ? (
    <Box sx={{ pl: 2, width: '100%' }}>
      <Skeleton variant='rect' animation='wave' width='100%' height={500} />
    </Box>
  ) : (
    <Box sx={{ pl: 2, width: '100%' }}>
      <div ref={dicomImageRef} style={{ width: '100%', height: '500px' }} />
    </Box>
  );
}
