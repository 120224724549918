import React, { useEffect, useState } from 'react';
import { Box, Card, CardHeader, CardContent, IconButton, Typography, Grid, Button} from '@mui/material';
import { MdAccessAlarms, MdNotificationsActive} from 'react-icons/md';
import { useNavigate } from 'react-router';
import MenuIcon from '@mui/icons-material/Menu';

export default function BusinessLogicView() {
  const navigate = useNavigate();
  const OpenActivity = () => {
    navigate('/sysadmin/businessrule', { state: { name: 'messageFromServer' } });

  }

  const OpenNotifications = () => {
    navigate('/sysadmin/notificationtemplate', { state: { name: 'messageFromServer' } });
  }
  return (
    <Card>
      <CardHeader
        // title='General Statistical Reports & Extracts'
        // action={
        //   <IconButton
        //     sx={{
        //       ml: 'auto',
        //       display: { xs: 'block', md: 'none' },
        //     }}
        //   >
        //     <MenuIcon />
        //   </IconButton>
        // }
      />
      <CardContent>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Button
                variant='contained'
                style={{ width: '100%', minHeight: '72px' }}
                onClick={OpenActivity}
                startIcon={<MdAccessAlarms />}
              >
                Activity
              </Button>
            </Grid>

            <Grid item xs={12} md={3}>
              <Button
                variant='contained'
                style={{ width: '100%', minHeight: '72px' }}
                onClick={OpenNotifications}
                startIcon={<MdNotificationsActive />}
                // disabled
              >
                Notifications
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}
