import React, { useState } from 'react';
import { Button, Grid, Alert, Card, CardContent, CardHeader, Collapse, Typography } from '@mui/material';
import MLDropzone from '../../../utils/components/MLDropzone';

export default function BulkFileUpload() {
  const [open, setOpen] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);


  const handleSuccessfulUpload = () => {
    setShowSuccessAlert(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Collapse in={true}>
          <div>
            {showSuccessAlert && (
              <Alert
                style={{ marginBottom: '1rem' }}
                severity='success'
                onClose={() => setShowSuccessAlert(false)}
              >
                All accepted files uploaded successfully!
              </Alert>
            )}
            <Card>
              {/* <CardHeader title="Bulk Upload Case Documents" /> */}
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={3}>
                    <Button
                      variant='contained'
                      style={{ marginTop: 15 }}
                      color='primary'
                      onClick={() => setOpen(true)}
                    >
                      Upload Documents
                    </Button>
                  </Grid>                 
                </Grid>
                <Grid item xs={12}>
                  <MLDropzone
                    isOpen={open}
                    setIsOpen={setOpen}
                    documentTypeRequired={true}
                    onSuccessUpload={handleSuccessfulUpload}
                    isLab={true}
                  />
                </Grid>
              </CardContent>
            </Card>
          </div>
        </Collapse>
      </Grid>
    </Grid>
  );
}
