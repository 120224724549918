import { Button, Grid, Box } from '@mui/material';
import React from 'react';

import { BsClipboard2Check, BsFillKanbanFill } from 'react-icons/bs';
import { MdPayments, MdBiotech } from 'react-icons/md';
import { useNavigate } from 'react-router';
import '../../App.css';
import CompletedPathologistExamsView from './BusinessReports/CompletedPathologistExamsView';

export default function BusinessReports() {
  const navigate = useNavigate();

  const ApprovedReleasesCasesByPathologistView = () => {
    navigate('/reports/approvedreleasesbypathologist', {
      state: { name: 'messageFromServer' },
    });
  };

  const CompletedPathologistExamsView = () => {
    navigate('/reports/completedpathologistexams', {
      state: { name: 'messageFromServer' },
    });
  };

  const DeathInvestigationsSummaryView = () => {
    navigate('/reports/deathinvestigationssummary', {
      state: { name: 'messageFromServer' },
    });
  };

  const LabTestingTagsReportView = () => {
    navigate('/reports/labtestingtagsreport', {
      state: { name: 'messageFromServer' },
    });
  };

  const CasePaymentsReportView = () => {
    navigate('/reports/casepaymentsreport', {
      state: { name: 'messageFromServer' },
    });
  };

  const ClosedCasesByPathologistView = () => {
    navigate('/reports/closedcasesbypathologist', {
      state: { name: 'messageFromServer' },
    });
  };

  const ExamReportUploadedByPathologistView = () => {
    navigate('/reports/examreportuploadedbypathologist', {
      state: { name: 'messageFromServer' },
    });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              CompletedPathologistExamsView();
            }}
            startIcon={<BsClipboard2Check />}
          >
            Completed Exams by Pathologist
          </Button>
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              DeathInvestigationsSummaryView();
            }}
            startIcon={<BsFillKanbanFill />}
            disabled
          >
            DEATH INVESTIGATIONS SUMMARY
          </Button>
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              LabTestingTagsReportView();
            }}
            startIcon={<BsFillKanbanFill />}
            // disabled
          >
            Lab Testing Tags Report
          </Button>
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              CasePaymentsReportView();
            }}
            startIcon={<MdPayments />}
            disabled
          >
            Case Payments Report
          </Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              ApprovedReleasesCasesByPathologistView();
            }}
            startIcon={<BsClipboard2Check />}
          >
            Approved Releases By Pathologist
          </Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              ClosedCasesByPathologistView();
            }}
            startIcon={<BsClipboard2Check />}
          >
            Cases Closed By Pathologist
          </Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              ExamReportUploadedByPathologistView();
            }}
            startIcon={<MdBiotech />}
          >
            Exam Report Uploaded By Pathologist
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
