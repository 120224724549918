import { Typography } from '@mui/material';
import { useTwoFactorHelper } from '../../../utils/auth/functions/useTwoFactorHelper.hook';

export function TwoFactorError() {
  const { getErrorMessage, hasTwoFactorError } = useTwoFactorHelper();

  if (hasTwoFactorError) {
    return (
      <Typography variant='body2' color='error' my={1}>
        <b>Error:</b> {getErrorMessage()}
      </Typography>
    );
  }

  return null;
}
