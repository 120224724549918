import { Card, CardContent, CardHeader, useTheme } from '@mui/material';

export default function MobileCard({ title, subtitle = '', content }) {
  const theme = useTheme();

  return (
    <Card sx={{ borderRadius: 4, height: '100%', width: '100%' }}>
      <CardHeader title={title} subheader={subtitle} />
      <CardContent sx={{ p: 0, pb: 0 }}>{content}</CardContent>
    </Card>
  );
}
