import { Button, DialogContentText, Divider, Skeleton, Typography, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../utils/auth/AuthService';
import { useNavigate } from 'react-router';
import { useCaseSearch } from '../../utils/hooks/CaseSearch/useCaseSearch.hook';

interface CaseViewGroupedCasesDialogParams {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  caseSeq: string;
  groupCaseSeq: string;
}

interface GroupedCaseData {
  caseID: string;
  decedentName: string;
  groupedToCaseID: string;
  groupedToDecedentName: string;
}

export default function CaseViewGroupedCasesDialog({
  open,
  setOpen,
  caseSeq,
  groupCaseSeq,
}: CaseViewGroupedCasesDialogParams) {
  const navigate = useNavigate();
  const { setCaseID } = useCaseSearch();
  const theme = useTheme();
  const { user } = useAuth();
  const { REACT_APP_API_URL } = process.env;
  const [groupedCases, setGroupedCases] = useState([]);
  const [loading, setLoading] = useState(false);

  const getGroupedCases = async () => {
    setLoading(true);

    await fetch(
      REACT_APP_API_URL + `getGroupedCasesByCase?caseSeq=${caseSeq}&groupCaseSeq=${groupCaseSeq}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user?.accessToken,
        },
      }
    )
      .then(res => {
        return res.json();
      })
      .then(data => {
        setGroupedCases(data);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      getGroupedCases();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} PaperProps={{ sx: { width: '100rem' } }}>
      <DialogTitle>Grouped Cases</DialogTitle>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <DialogContentText sx={{ mb: 2 }}>
          <Typography>
            Grouped cases are a set of cases in which multiple decedents are associated with a
            single incident.
          </Typography>
        </DialogContentText>
        {loading ? (
          <Skeleton height={100} />
        ) : (
          <List sx={{ width: '100%', bgcolor: theme.palette.background.default, borderRadius: 2 }}>
            {groupedCases.map((value: GroupedCaseData, index: number) => (
              <>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setCaseID(value.caseID);
                      navigate('/caseview', {
                        state: { cmscaseid: value.caseID },
                      });
                    }}
                  >
                    <ListItemText
                      primary={`${value.caseID} (${value.decedentName})`}
                      secondary={
                        value.groupedToCaseID != null
                          ? `Linked to ${value.groupedToCaseID} (${value.groupedToDecedentName})`
                          : 'Base case'
                      }
                    />
                  </ListItemButton>
                </ListItem>

                {index < groupedCases.length - 1 && <Divider sx={{ opacity: 0.8 }} />}
              </>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
