import React from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import RectangleIcon from '@mui/icons-material/Rectangle';
import { Box } from '@mui/material';

const ColorCode = () => {
    return (
        <Box>
            <List>
                <ListItem disablePadding>
                    <ListItemButton style={{ padding: 0 }}>
                        <ListItemIcon>
                            <RectangleIcon fontSize="large" style={{ color: '#3B6A0C' }} />
                        </ListItemIcon>
                        <ListItemText primary="Creator, Editor, Viewer" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton style={{ padding: 0 }}>
                        <ListItemIcon>
                            <RectangleIcon fontSize="large" style={{ color: '#C466C4' }} />  {' '}
                        </ListItemIcon>
                        <ListItemText primary="Editor, Viewer" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton style={{ padding: 0 }}>
                        <ListItemIcon>
                            <RectangleIcon fontSize="large" style={{ color: '#004A9D' }} />  {' '}
                        </ListItemIcon>
                        <ListItemText primary="Viewer" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton style={{ padding: 0 }}>
                        <ListItemIcon>
                            <RectangleIcon fontSize="large" style={{ color: '#272727' }} />
                        </ListItemIcon>
                        <ListItemText primary="No Access" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    )
}

export default ColorCode