import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  Paper,
  Slide,
  Stack,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function MorgueTechCaseActivityView() {
  const { user } = useAuth();
  const [loading, setloading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const [numberOfCasesFound, setNumberOfCasesFound] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [year, setYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [currentUser, setcurrentUser] = useState(false);
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [selectedJdxs, setSelectedJdxs] = useState([]);
  const [requiredAlertVisible, setRequiredAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const { REACT_APP_API_URL } = process.env;

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const columns = [
    {
      field: 'caseid',
      headerName: 'Case ID',
      width: 125,
      editable: false,
      headerAlign: 'center',
      renderCell: params => {
        return (
          <NavLink
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
            }}
          >
            {params.formattedValue}
          </NavLink>
        );
      },
    },
    {
      field: 'id',
      hide: true,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'labTestName',
      headerName: 'Test DPT',
      width: 100,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'jurisdictionName',
      headerName: 'Jurisdiction',
      width: 110,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'examType',
      headerName: 'Exam Type',
      width: 80,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'morgueTech',
      headerName: 'Morgue Tech',
      width: 100,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'examPhysician',
      headerName: 'Pathologist',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'examStartDateTime',
      headerName: 'Exam Start Time',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'menotified',
      headerName: 'ME Notified',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'mannerOfDeath',
      headerName: 'Manner of Death',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'causeOfDeath',
      headerName: 'Cause of Death',
      width: 250,
      editable: false,
      headerAlign: 'left',
    },
  ];

  const handleJdxChange = (event, data) => {
    setSelectedJdxs(data);
  };

  const handleStartDateChange = event => {
    if (event.target.value != '') {
      setStartDate(event.target.value);
    } else {
      setStartDate('');
    }
  };

  const handleEndDateChange = event => {
    if (event.target.value != '') {
      setEndDate(event.target.value);
    } else {
      setEndDate('');
    }
  };

  const handleYearChange = (event, data) => {
    setYear(data);
  };

  const dismissDateAlert = () => {
    setRequiredAlertVisible(false);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  const clearAll = () => {
    setStartDate('');
    setEndDate('');
    setYear(null);
    setSelectedJdxs([]);
    setResultsVisible(false);
  };

  //gather search params from form and FETCH/POST to API
  const handleSubmit = async event => {
    event.preventDefault();
    if (user === null) return;

    if (year == null) {
      let startDateArr = startDate.split('-');
      let endDateArr = endDate.split('-');

      let startDateValue = new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]);
      let endDateValue = new Date(endDateArr[0], endDateArr[1] - 1, endDateArr[2]);

      if (endDateValue - startDateValue > 31536000000) {
        setRequiredAlertVisible(true);
        return;
      }
    }

    if (selectedJdxs.length == 0) {
      setJdxAlertVisible(true);
      return;
    }

    let formData = new FormData();

    setloading(true);
    setResultsVisible(true);

    formData.append('SEARCHFROMDATE', startDate);
    formData.append('SEARCHTODATE', endDate);
    formData.append('CASEYEAR', year);
    formData.append('SELECTEDJDXLIST', JSON.stringify(selectedJdxs));

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'getMTActivityExtraction', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setlistresults(data);
          console.log(data);
          setNumberOfCasesFound(data.length);
          setloading(false);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    await setloading(false);
  };

  useEffect(() => {
    if (user === null) return;

    const fetchYear = async () => {
      try {
        const currentYear = new Date().getFullYear();
        setYear(currentYear);
        setYearOptions([...Array(16).keys()].map(i => i + (currentYear - 15)).reverse());
      } catch (e) {
        alert(e);
      }
    };

    fetchYear();
    setAuthorizedJdxs(user.jdxAccessList);
    setSelectedJdxs(user.jdxAccessList);
  }, [user]);

  return (
      <Card
        raised
        sx={{
          my: 3,
          mx: 'auto',
          width: { xs: '95%', lg: '75%' },
        }}
      >
        <CardHeader
        title='Morgue Tech Case Activity'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
        action={
          <Tooltip
            sx={{ color: theme.palette.text.primary }}
            title='This Report Tracks the activity of the Morgue Techs, grouped by the exam type'
            placement='right-end'
          >
            <InfoIcon />
          </Tooltip>
        }
      />

        <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

        <CardContent>
          <Slide
            direction='right'
            in={requiredAlertVisible}
            mountOnEnter
            unmountOnExit
            sx={{ mb: 2 }}
          >
            <Alert severity='warning' onClose={dismissDateAlert}>
              Call taken range must be within 1 year or year dropdown must be set.
            </Alert>
          </Slide>

          <Slide direction='right' in={jdxAlertVisible} mountOnEnter unmountOnExit sx={{ mb: 2 }}>
            <Alert severity='warning' onClose={dismissJdxAlert}>
              Please select at least 1 jurisdiction.
            </Alert>
          </Slide>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id='selectedJdxs'
                options={authorizedJdxs}
                value={selectedJdxs}
                disableCloseOnSelect
                limitTags={4}
                fullWidth
                size='small'
                getOptionLabel={option => option.jdxName}
                onChange={(event, data) => handleJdxChange(event, data)}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<MdCheckBoxOutlineBlank />}
                      checkedIcon={<MdOutlineCheckBox />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.jdxName}
                  </li>
                )}
                renderInput={params => (
                  <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
                )}
                ListboxComponent={'ul'}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                id='year'
                options={yearOptions}
                value={year}
                size='small'
                getOptionLabel={option => option.toString()}
                onChange={(event, data) => handleYearChange(event, data)}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<MdCheckBoxOutlineBlank />}
                      checkedIcon={<MdOutlineCheckBox />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.toString()}
                  </li>
                )}
                style={{ width: '100%' }}
                renderInput={params => <TextField {...params} label='Year' placeholder='Year' />}
                ListboxComponent={'ul'}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id='searchfromdate'
                name='searchfromdate'
                label='Exam Start From'
                type='date'
                size='small'
                value={startDate}
                onChange={handleStartDateChange}
                disabled={year}
                style={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id='searchtodate'
                name='searchtodate'
                label='Exam Start To'
                type='date'
                size='small'
                value={endDate}
                onChange={handleEndDateChange}
                disabled={year}
                style={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <Divider sx={{ my: 2, opacity: 1 }} />

          <Stack
            direction='row'
            sx={{
              width: '100%',
              justifyContent: 'right',
            }}
            spacing={2}
          >
            <Button type='submit' variant='contained' color='primary' onClick={handleSubmit}>
              Search
            </Button>

            <Button color='secondary' variant='outlined' onClick={clearAll}>
              Clear
            </Button>
          </Stack>

          <Collapse in={resultsVisible} sx={{ mt: resultsVisible ? 4 : 0 }}>
            <ReportDataGrid
              loading={loading}
              listresults={listresults}
              idcolumn='id'
              columnsInput={columns}
              title='Morgue Tech Case Activity '
              casecount
              chatcontent={
                <Paper
                  elevation={3}
                  sx={{
                    p: '10px',
                    px: '20px',
                    borderRadius: '20px',
                    width: 'max-content',
                  }}
                >
                  <div style={{ textAlign: 'center' }}>
                    I found <b>{numberOfCasesFound} </b> cases with morgue tech activity. Details are
                    listed below, you can export this list to CSV format.
                  </div>
                </Paper>
              }
            />
          </Collapse>
        </CardContent>
      </Card>
  );
}
