import React, { useEffect, useState } from 'react';
import ControlComponents from '../../views/SysAdmin/SysAdminModules/UserAdminModules/ControlComponents';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { fetchRoles } from '../../services/utility-api';
import axios from 'axios';
import authHeader from '../../services/auth-header';

const Roles = ({ name }) => {
  const [rolesDropDown, setRolesDropDown] = useState([]);

  const getActiveRoles = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}GetAllRole`, {
        headers: authHeader(),
      });
      const roles = response?.data?.data?.map(role => ({
        status: role?.status,
        name: role?.roleName,
        value: role?.roleSeq,
      })).filter((role) => role?.status === 'Active');
      setRolesDropDown(roles);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getActiveRoles();
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        // border: '1px solid grey',
        // borderRadius: '12px',
        padding: '8px 0px 12px',
        marginTop: '16px',
      }}
    >
      <h5 style={{ marginTop: '8px' }}>Roles</h5>
      <Grid container spacing={2}>
        <Grid item lg={12} xs={12} md={12} sm={12}>
          <ControlComponents
            control='multiSelect'
            name={name}
            label='Select Role Permissions*'
            options={rolesDropDown}
            type='select'
            module='Roles'
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Roles;
