// import { Autocomplete, Checkbox, Paper, TextField , FormControlLabel} from '@mui/material';
// import React, { useEffect, useState } from 'react';
// import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
// import { useNavigate } from 'react-router';
// import {
//   Alert,
//   Button,
//   Card,
//   CardBody,
//   CardHeader,
//   Col,
//   Collapse,
//   NavLink,
//   Row,
// } from 'reactstrap';
// import '../../../App.css';
// import { useAuth } from '../../../utils/auth/AuthService';
// import { BreadcrumbReportNav } from '../../../utils/components/BreadcrumbReportNav';
// import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Collapse,
  Divider,
  Paper,
  Slide,
  Stack,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function AnalyteDetectedView() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [listResults, setListResults] = useState([]);
  const [jdxs, setJdxs] = useState([]);
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [mENotifiedFrom, setMENotifiedFrom] = useState('');
  const [mENotifiedTo, setMENotifiedTo] = useState('');
  const [analyteValue, setAnalyteValue] = useState('');
  const [checkboxState, setCheckboxState] = useState({
    matchesExactly: false,
    contains: false,
    beginsWith: false,
    endWith: false,
  });
  const [dateError, setDateError] = useState('');
  const theme = useTheme();

  const navigate = useNavigate();
  const { REACT_APP_API_URL } = process.env;

  const handleJdxChange = (event, data) => {
    setJdxs(data);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const handleMENotifiedFromChange = event => {
    if (event.target.value != '') {
      setMENotifiedFrom(event.target.value);
      if (mENotifiedTo && event.target.value > mENotifiedTo) {
        setDateError('From date cannot be greater than To date');
      } else {
        setDateError('');
      }
    } else {
      setMENotifiedFrom('');
    }
  };

  const handleMENotifiedToChange = event => {
    if (event.target.value != '') {
      setMENotifiedTo(event.target.value);
      if (event.target.value && mENotifiedFrom > event.target.value) {
        setDateError('From date cannot be greater than To date');
      } else {
        setDateError('');
      }
    } else {
      setMENotifiedTo('');
    }
  };

  const handleAnalyteChange = event => {
    const newValue = event.target.value;
    setAnalyteValue(newValue);
  };

  const handleCheckboxChange = checkboxName => event => {
    setCheckboxState({
      ...checkboxState,
      [checkboxName]: event.target.checked,
    });
  };

  const listColumns = [
    {
      field: 'caseID',
      headerName: 'Case ID',
      width: 125,
      editable: false,
      headerAlign: 'center',
      renderCell: params => {
        return (
          <NavLink
            //variant="contained"
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
            }}
          >
            {params.formattedValue}
          </NavLink>
        );
      },
    },
    {
      field: 'analyte',
      headerName: 'Substance/Analyte',
      width: 200,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'causeOfDeath',
      headerName: 'Cause of Death',
      width: 350,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'zip',
      headerName: 'Zip Code',
      width: 125,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'meNotifiedDate',
      headerName: 'MENotified Date',
      width: 125,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'jurisdictionName',
      headerName: 'Jurisdiction',
      width: 125,
      editable: false,
      headerAlign: 'left',
    },
  ];

  //gather search params from form and FETCH/POST to API
  const handleSubmit = async event => {
    event.preventDefault();

    if (jdxs.length == 0) {
      setJdxAlertVisible(true);
      return;
    }

    let formData = new FormData();

    setLoading(true);
    setResultsVisible(true);

    formData.append('JdxList', JSON.stringify(jdxs));
    formData.append('Analyte', JSON.stringify(analyteValue));
    formData.append('MENotifiedStartDate', mENotifiedFrom);
    formData.append('MENotifiedEndDate', mENotifiedTo);
    formData.append('MatchesExactly', checkboxState.matchesExactly);
    formData.append('Contains', checkboxState.contains);
    formData.append('BeginsWith', checkboxState.beginsWith);
    formData.append('EndWith', checkboxState.endWith);

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'GetAnalyteDetectedList', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setListResults(data);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    await setLoading(false);
  };

  const clearAll = () => {
    setJdxs([]);
    setResultsVisible(false);
  };

  useEffect(() => {
    if (user === null) return;
    setAuthorizedJdxs(user.jdxAccessList);
    setJdxs(user.jdxAccessList);
  }, [user]);

  return (
    <Card
      raised
      sx={{
        my: 3,
        mx: 'auto',
        width: { xs: '95%', lg: '75%' },
      }}
    >
      <CardHeader
        title='Substance/Analyte Detected Report'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
      />

      <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

      <CardContent>
        <Slide direction='right' in={jdxAlertVisible} mountOnEnter unmountOnExit sx={{ mb: 2 }}>
          <Alert severity='warning' onClose={dismissJdxAlert}>
            Please select at least 1 jurisdiction.
          </Alert>
        </Slide>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id='jdxs'
              options={authorizedJdxs}
              value={jdxs}
              disableCloseOnSelect
              limitTags={4}
              fullWidth
              size='small'
              getOptionLabel={option => option.jdxName}
              onChange={(event, data) => handleJdxChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.jdxName}
                </li>
              )}
              renderInput={params => (
                <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
              )}
              ListboxComponent={'ul'}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id='searchfromdate'
              name='searchfromdate'
              label='Call Taken From'
              type='date'
              size='small'
              value={mENotifiedFrom}
              onChange={handleMENotifiedFromChange}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id='searchtodate'
              name='searchtodate'
              label='Call Taken To'
              type='date'
              size='small'
              value={mENotifiedTo}
              onChange={handleMENotifiedToChange}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id='analyte'
              name='analyte'
              label='Substance/Analyte Filter'
              size='small'
              type='text'
              fullWidth
              onChange={handleAnalyteChange}
              value={analyteValue}
            />
          </Grid>

          <Grid item xs='auto'>
            <FormControlLabel
              label='Matches Exactly'
              style={{ width: '58%' }}
              control={
                <Checkbox
                  checked={checkboxState.matchesExactly}
                  onChange={handleCheckboxChange('matchesExactly')}
                />
              }
            />
          </Grid>

          <Grid item xs='auto'>
            <FormControlLabel
              label='Contains'
              style={{ width: '30%' }}
              control={
                <Checkbox
                  checked={checkboxState.contains}
                  onChange={handleCheckboxChange('contains')}
                />
              }
            />
          </Grid>

          <Grid item xs='auto'>
            <FormControlLabel
              label='Begins With'
              style={{ width: '58%' }}
              control={
                <Checkbox
                  checked={checkboxState.beginsWith}
                  onChange={handleCheckboxChange('beginsWith')}
                />
              }
            />
          </Grid>

          <Grid item xs='auto'>
            <FormControlLabel
              label='End With'
              style={{ width: '38%' }}
              control={
                <Checkbox
                  checked={checkboxState.endWith}
                  onChange={handleCheckboxChange('endWith')}
                />
              }
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 2, opacity: 1 }} />

        <Stack
          direction='row'
          sx={{
            width: '100%',
            justifyContent: 'right',
          }}
          spacing={2}
        >
          <Button type='submit' variant='contained' color='primary' onClick={handleSubmit}>
            Calculate
          </Button>

          <Button color='secondary' variant='outlined' onClick={clearAll}>
            Clear
          </Button>
        </Stack>

        <Collapse in={resultsVisible} sx={{ mt: resultsVisible ? 4 : 0 }}>
          <ReportDataGrid
            loading={loading}
            listresults={listResults}
            idcolumn='caseID'
            columnsInput={listColumns}
            title='Records Found'
            casecount
          />
        </Collapse>
      </CardContent>
    </Card>

    // <div style={{ padding: 20 }}>
    //   <BreadcrumbReportNav currentPage='Substance/Analyte Detected' />

    //   <Card style={{ marginBottom: '1rem' }} className='card-main'>
    //     <CardHeader className='header-txt'>
    //       <strong>Substance/Analyte Detected Report</strong>
    //     </CardHeader>
    //     <br />

    //     <Alert
    //       color='info'
    //       isOpen={jdxAlertVisible}
    //       toggle={dismissJdxAlert}
    //     >
    //       Please select at least 1 jdx.
    //     </Alert>

    //     <div>
    //       <CardBody>
    //         <Row>
    //           <Col className='me-col' xs='12'>
    //             <Autocomplete
    //               multiple
    //               id='jdxs'
    //               size='small'
    //               options={authorizedJdxs}
    //               value={jdxs}
    //               disableCloseOnSelect
    //               limitTags={4}
    //               getOptionLabel={(option) => option.jdxName}
    //               onChange={(event, data) => handleJdxChange(event, data)}
    //               renderOption={(props, option, { selected }) => (
    //                 <li {...props}>
    //                   <Checkbox
    //                     icon={<MdCheckBoxOutlineBlank />}
    //                     checkedIcon={<MdOutlineCheckBox />}
    //                     style={{ marginRight: 8 }}
    //                     checked={selected}
    //                   />
    //                   {option.jdxName}
    //                 </li>
    //               )}
    //               style={{ width: '100%' }}
    //               renderInput={(params) => (
    //                 <TextField
    //                   {...params}
    //                   label='Jurisdiction'
    //                   placeholder='Jurisdiction'
    //                 />
    //               )}
    //             />
    //           </Col>
    //         </Row>
    //         <Row>
    //           <Col className='me-col' xs='12' md='3'>
    //             <TextField
    //               id='calltakenfrom'
    //               name='calltakenfrom'
    //               label='Call Taken From'
    //               size='small'
    //               type='date'
    //               onChange={handleMENotifiedFromChange}
    //               style={{ width: '100%', marginTop: 15 }}
    //               InputLabelProps={{
    //                 shrink: true,
    //               }}
    //               value={mENotifiedFrom}
    //             />
    //           </Col>

    //           <Col className='me-col' xs='12' md='3'>
    //             <TextField
    //               id='calltakento'
    //               name='calltakento'
    //               label='Call Taken To'
    //               type='date'
    //               size='small'
    //               onChange={handleMENotifiedToChange}
    //               style={{ width: '100%', marginTop: 15 }}
    //               InputLabelProps={{
    //                 shrink: true,
    //               }}
    //               value={mENotifiedTo}
    //               error={dateError}
    //               helperText={dateError}
    //             />
    //           </Col>

    //           <Col className='me-col' xs='12' md='3'>
    // <TextField
    //   id='analyte'
    //   name='analyte'
    //   label='Substance/Analyte Filter'
    //   size='small'
    //   type='text'
    //   onChange={handleAnalyteChange}
    //   style={{ width: '100%', marginTop: 15 }}
    //   value={analyteValue}
    // />
    //           </Col>

    //           <Col className='me-col' xs='12' md='3'>
    // <FormControlLabel
    //   label='Matches Exactly'
    //   style={{ width: '58%' }}
    //   control={
    //     <Checkbox
    //       checked={checkboxState.matchesExactly}
    //       onChange={handleCheckboxChange('matchesExactly')}
    //     />
    //   }
    // />

    // <FormControlLabel
    //   label='Contains'
    //   style={{ width: '30%' }}
    //   control={
    //     <Checkbox
    //       checked={checkboxState.contains}
    //       onChange={handleCheckboxChange('contains')}
    //     />
    //   }
    // />
    // <FormControlLabel
    //   label='Begins With'
    //   style={{ width: '58%' }}
    //   control={
    //     <Checkbox
    //       checked={checkboxState.beginsWith}
    //       onChange={handleCheckboxChange('beginsWith')}
    //     />
    //   }
    // />

    // <FormControlLabel
    //   label='End With'
    //   style={{ width: '38%' }}
    //   control={
    //     <Checkbox
    //       checked={checkboxState.endWith}
    //       onChange={handleCheckboxChange('endWith')}
    //     />
    //   }
    // />
    //           </Col>
    //         </Row>
    //       </CardBody>
    //       <br />

    //       <Button
    //         type='submit'
    //         color='primary'
    //         style={{ width: 150, marginLeft: 18, float: 'right' }}
    //         onClick={handleSubmit}
    //       >
    //         Generate Report
    //       </Button>

    //       <Button
    //         color='secondary'
    //         style={{ width: 120, marginLeft: 18, float: 'right' }}
    //         onClick={clearAll}
    //       >
    //         Clear
    //       </Button>
    //       <hr />
    //       <br />

    //       <Collapse isOpen={resultsVisible}>
    // <ReportDataGrid
    //   loading={loading}
    //   listresults={listResults}
    //   idcolumn='caseID'
    //   columnsInput={listColumns}
    //   title='Records Found'
    //   casecount
    // />
    //       </Collapse>
    //     </div>
    //   </Card>
    // </div>
  );
}
