import {
  Autocomplete,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { GridColDef } from '@mui/x-data-grid-premium';
import { useAuth } from '../../../utils/auth/AuthService';
import { renderAutocompleteEditCell } from '../../../utils/components/CrudDataGrid/AutocompleteEditCell';
import { renderAutocompleteEditCellWithDescription } from '../../../utils/components/CrudDataGrid/AutocompleteEditCellWithDescription';
import DialogCrudDataGrid, {
  handleAutocompleteChange,
  handleNumericTextFieldChange,
  handleTextFieldChange,
} from '../../../utils/components/DialogCrudDataGrid';
import NumericTextField from '../../../utils/components/NumericTextField';

export default function ToxResultsDataGrid() {
  const { user } = useAuth();
  const { REACT_APP_API_URL } = process.env;
  const formik = useFormikContext<CMSCase>();
  const [codTagOptions, setCODTagOptions] = useState([]);
  const [specimenOptions, setSpecimenOptions] = useState([]);
  const [analyteOptions, setAnalyteOptions] = useState([]);
  const [toxResultsRows, setToxResultsRows] = useState(
    formik.values?.caseLabTesting.toxLabResults ?? []
  );

  useEffect(() => {
    fetch(REACT_APP_API_URL + 'getcodtagoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setCODTagOptions(data);
      })
      .catch(e => {
        //alert(e);
      });

    fetch(REACT_APP_API_URL + 'getspecimenoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setSpecimenOptions(data);
      })
      .catch(e => {
        //alert(e);
      });

    fetch(REACT_APP_API_URL + 'getanalyteoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setAnalyteOptions(data);
      })
      .catch(e => {
        //alert(e);
      });

    getToxResults();
  }, []);

  const getToxResults = async () => {
    let formData = new FormData();

    formData.append('JDXLIST', JSON.stringify(user.jdxAccessList));
    formData.append('USERSEQ', user.userSeq);
    formData.append('CASESEQ', formik.values?.caseSummary.caseSeq);

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'getToxResults', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setToxResultsRows(data);
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      console.log('user or token is null');
    }
  };

  const createToxResult = async newRow => {
    console.log(newRow);

    let formData = new FormData();
    formData.append('newRow', JSON.stringify(newRow));
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);
    formData.append('userSeq', user.userSeq);

    await fetch(REACT_APP_API_URL + 'createToxResult', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {})
      .catch(e => {
        console.log(e);
      });

    await getToxResults();
  };

  const updateToxResult = async updatedRow => {
    let formData = new FormData();
    formData.append('updatedRow', JSON.stringify(updatedRow));
    formData.append('userSeq', user.userSeq);

    await fetch(REACT_APP_API_URL + 'updatelabtestresult', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {})
      .catch(e => {
        console.log(e);
      });

    await getToxResults();
  };

  const deleteToxResult = async id => {
    await fetch(REACT_APP_API_URL + `deletelabtestresult/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {})
      .catch(e => {
        console.log(e);
      });

    await getToxResults();
  };

  const toxResultsColumns: GridColDef[] = [
    {
      flex: 1,
      minWidth: 100,
      field: 'labreferencenumber',
      headerName: 'Work Order#',
      type: 'string',
      align: 'left',
      headerAlign: 'left',
    },
    {
      flex: 1,
      minWidth: 200,
      field: 'specimen',
      headerName: 'Specimen',
      type: 'singleSelect',
      align: 'left',
      headerAlign: 'left',
      valueFormatter: (value: Option) => {
        return value ? value.optionName : '';
      },
      valueOptions: specimenOptions,
      renderEditCell: renderAutocompleteEditCell,
    },
    {
      flex: 1,
      minWidth: 200,
      field: 'analyte',
      headerName: 'Analyte',
      type: 'singleSelect',
      align: 'left',
      headerAlign: 'left',
      valueFormatter: (value: Option) => {
        return value ? value.optionName : '';
      },
      valueOptions: analyteOptions,
      renderEditCell: renderAutocompleteEditCell,
    },
    {
      flex: 1,
      minWidth: 75,
      field: 'quantitativeresult',
      headerName: 'Amount Detected',
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      // valueFormatter: value => {
      //   if (value !== undefined) {
      //     return parseFloat(value).toFixed(3);
      //   }
      //   return null;
      // },
      renderCell: params => {
        if (typeof params.value === 'number') {
          // const formattedValue = params.value.toFixed(3);
          return <div>{params.value}</div>;
        }
        return null;
      },
    },
    {
      flex: 1,
      minWidth: 75,
      field: 'unitofmeasurement',
      headerName: 'Unit',
      type: 'string',
      align: 'left',
      headerAlign: 'left',
    },
    {
      flex: 1,
      minWidth: 75,
      field: 'quantitativelimit',
      headerName: 'Limit of Quantitation',
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      renderCell: params => {
        if (typeof params.value === 'number') {
          // const formattedValue = params.value.toFixed(3);
          return <div>{params.value}</div>;
        }
        return null;
      },
    },
    {
      flex: 1,
      minWidth: 100,
      field: 'finalresult',
      headerName: 'Interpretation',
      type: 'string',
      align: 'left',
      headerAlign: 'left',
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'labnotes',
      headerName: 'Lab Notes',
      type: 'string',
      align: 'left',
      headerAlign: 'left',
    },
    {
      flex: 1,
      minWidth: 200,
      field: 'codTag',
      headerName: 'COD Tag',
      type: 'singleSelect',
      align: 'left',
      headerAlign: 'left',
      valueFormatter: (value: Option) => {
        return value ? value.optionName : '';
      },
      valueOptions: codTagOptions,
      renderEditCell: renderAutocompleteEditCellWithDescription,
    },
  ];

  const dialogContent = ({ mode, data, onChange }) => {
    const [localData, setLocalData] = useState(null);

    useEffect(() => {
      setLocalData(data);
    }, [data]);

    return (
      <Grid container spacing={1} sx={{ alignItems: 'center' }}>
        <Grid item xs={12} md={6}>
          <TextField
            value={localData?.labreferencenumber ?? ''}
            onChange={event =>
              handleTextFieldChange(event, 'labreferencenumber', localData, setLocalData, onChange)
            }
            label='Work Order #'
            fullWidth
            margin='dense'
          />
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          <Autocomplete
            options={specimenOptions}
            getOptionLabel={option => option.optionName || ''}
            value={localData?.specimen || null}
            onChange={(event, value) =>
              handleAutocompleteChange(event, value, 'specimen', localData, setLocalData, onChange)
            }
            renderInput={params => (
              <TextField {...params} label='Specimen' margin='dense' fullWidth />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          <Autocomplete
            options={analyteOptions}
            getOptionLabel={option => option.optionName || ''}
            value={localData?.analyte || null}
            onChange={(event, value) =>
              handleAutocompleteChange(event, value, 'analyte', localData, setLocalData, onChange)
            }
            renderInput={params => (
              <TextField {...params} label='Analyte' margin='dense' fullWidth />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          <NumericTextField
            value={localData?.quantitativeresult}
            onChange={value =>
              handleNumericTextFieldChange(
                value,
                'quantitativeresult',
                localData,
                setLocalData,
                onChange
              )
            }
            label='Amount Detected'
            margin='dense'
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            value={localData?.unitofmeasurement ?? ''}
            onChange={event =>
              handleTextFieldChange(event, 'unitofmeasurement', localData, setLocalData, onChange)
            }
            label='Unit'
            fullWidth
            margin='dense'
          />
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          <NumericTextField
            value={localData?.quantitativelimit}
            onChange={value =>
              handleNumericTextFieldChange(
                value,
                'quantitativelimit',
                localData,
                setLocalData,
                onChange
              )
            }
            label='Limit of Quantitation'
            margin='dense'
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            value={localData?.finalresult ?? ''}
            onChange={event =>
              handleTextFieldChange(event, 'finalresult', localData, setLocalData, onChange)
            }
            label='Interpretation'
            fullWidth
            margin='dense'
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            value={localData?.labnotes ?? ''}
            onChange={event =>
              handleTextFieldChange(event, 'labnotes', localData, setLocalData, onChange)
            }
            label='Lab Notes'
            fullWidth
            margin='dense'
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Autocomplete
            options={codTagOptions}
            getOptionLabel={option => option.optionName || ''}
            value={localData?.codTag || []}
            onChange={(event, value) =>
              handleAutocompleteChange(event, value, 'codTag', localData, setLocalData, onChange)
            }
            renderInput={params => (
              <TextField {...params} label='COD Tag' margin='dense' fullWidth />
            )}
            size='small'
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <DialogCrudDataGrid
      rows={toxResultsRows}
      columns={toxResultsColumns}
      title='Tox Results'
      createFunction={createToxResult}
      deleteFunction={deleteToxResult}
      updateFunction={updateToxResult}
      dialogContent={dialogContent}
      legend={
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell key='code'>Code</TableCell>
                <TableCell key='short'>Short Description</TableCell>
                <TableCell key='long'>Description of Code Substances</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {codTagOptions.map(row => (
                <TableRow key={row.code}>
                  <TableCell>{row.optionName}</TableCell>
                  <TableCell>{row.optionShortDesc}</TableCell>
                  <TableCell>{row.optionLongDesc}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    />
  );
}
