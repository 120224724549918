import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Alert,
  AlertTitle,
  Button,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';

import { CloseRounded, DoneAll } from '@mui/icons-material';
import { NotificationListItem } from './NotificationListItem';
import { useNotifications } from './NotificationsContext';

export const NotificationsMenuDrawer = ({
  notificationMenuOpen,
  handleCloseNotificationMenu,
}: {
  notificationMenuOpen: boolean;
  handleCloseNotificationMenu: any;
}) => {
  const { notifications, error, updateNotificationReadStatus } = useNotifications();

  // Sort notifications by date in descending order
  const sortedNotifications = useMemo(() => {
    return notifications.slice().sort((a, b) => {
      const dateA = new Date(a.notificationCreatedOn).getTime();
      const dateB = new Date(b.notificationCreatedOn).getTime();
      return dateB - dateA; // Sort in descending order
    });
  }, [notifications]);

  const [tab, setTab] = useState(0);
  const handleTabChange = (e: any, newTab: number) => {
    setTab(newTab);
  };

  // Filter notifications based on tab selection
  const displayedNotifications = useMemo(() => {
    switch (tab) {
      case 0: // Unread
        return sortedNotifications.filter(n => !n.notificationRead);
      case 1: // All
        return sortedNotifications;
      default:
        return sortedNotifications;
    }
  }, [tab, sortedNotifications, notifications]);

  const handleMarkAllAsRead = async (e: any) => {
    e.stopPropagation();
    for (let i = 0; i < notifications.length; i++) {
      const notification = notifications[i];
      if (!notification.notificationRead) {
        await updateNotificationReadStatus(notification.notificationSeq, true);
      }
    }
  };

  return (
    <Drawer
      variant='temporary'
      open={notificationMenuOpen}
      anchor='right'
      onClose={handleCloseNotificationMenu}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: 'block', sm: 'md' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: 280,
          // backgroundColor: 'black',
        },
        zIndex: theme => theme.zIndex.drawer + 3,
      }}
    >
      <Tabs variant='scrollable'></Tabs>
      <br />
      <List sx={{ mb: 0, pb: 0 }}>
        <ListItem>
          <Stack sx={{ width: '100%' }}>
            <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center'>
              <NotificationsIcon />
              <ListItemText primary={`Notifications`} />
              <IconButton onClick={handleCloseNotificationMenu}>
                <CloseRounded />
              </IconButton>
            </Stack>
            {error && (
              <Alert variant='filled' severity='error' color='error' sx={{ my: 1 }}>
                <AlertTitle>Error</AlertTitle>
                {String(error)}
              </Alert>
            )}
            <Divider sx={{ p: 1, opacity: 1 }} />
          </Stack>
        </ListItem>
        <Tabs value={tab} onChange={handleTabChange} variant='fullWidth'>
          <Tab label={`Unread`} />
          <Tab label='All' />
        </Tabs>

        {displayedNotifications.length === 0 && tab === 0 && (
          <Stack sx={{ color: 'darkgray', my: 3 }} textAlign='center'>
            <Typography variant='body2'>You're all caught up!</Typography>
            <Typography variant='body2'>You have no unread notifications</Typography>
          </Stack>
        )}
        {displayedNotifications.length === 0 && tab === 1 && (
          <Stack sx={{ color: 'darkgray', my: 3 }} textAlign='center'>
            <Typography variant='body2'>You're all caught up!</Typography>
            <Typography variant='body2'>You have no notifications</Typography>
          </Stack>
        )}
        {displayedNotifications.map(notification => (
          <Collapse in={notification.isActive} key={notification.notificationSeq}>
            <ListItem
              key={notification.notificationSeq}
              sx={{
                opacity: notification.notificationRead ? 0.6 : 1,
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                padding: 0,
                margin: 0,
              }}
            >
              <NotificationListItem
                key={notification.notificationSeq}
                n={notification}
                closeSidebar={handleCloseNotificationMenu}
              />
            </ListItem>
          </Collapse>
        ))}
      </List>
      <Divider sx={{ backgroundColor: '#fff', mb: 3 }} />

      <Button
        variant='text'
        size='small'
        startIcon={<DoneAll />}
        fullWidth
        sx={{
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          },
        }}
        onClick={handleMarkAllAsRead}
      >
        Mark all as read
      </Button>
    </Drawer>
  );
};
