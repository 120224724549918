import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  Slide,
  Stack,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import RTHomicideMap from '../../../utils/components/RTHomicideMap';

ChartJS.register(CategoryScale, BarElement, Title, Legend, LinearScale);

export default function HomicideChartView() {
  const { user } = useAuth();
  const [loading, setloading] = useState(true);
  const [yearOptions, setYearOptions] = useState([]);
  const [year, setYear] = useState(null);
  const [authorizedJdxs, setAuthorizedJdxs] = useState(user.jdxAccessList);
  const [selectedJdxs, setSelectedJdxs] = useState(user.jdxAccessList);
  const [chartJdxs, setChartJdxs] = useState([]);
  const [mapJdx, setMapJdx] = useState(user.jdxAccessList);
  const [requiredAlertVisible, setRequiredAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [total, setTotal] = useState(0);
  const [cases, setCases] = useState([]);
  const { REACT_APP_API_URL } = process.env;
  const theme = useTheme();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },

      labels: {
        position: 'top',
      },
      title: {
        display: true,
      },
    },
  };

  var labels = [];

  var definingChartData = {
    labels,
    datasets: [
      {
        label: '# of Homicides',
        data: [],
        backgroundColor: 'lightblue',
      },
    ],
  };

  const handleJdxChange = (event, data) => {
    setSelectedJdxs(data);
  };

  const handleYearChange = (event, data) => {
    setYear(data);
  };

  const dismissDateAlert = () => {
    setRequiredAlertVisible(false);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  const clearAll = () => {
    setYear(null);
    setSelectedJdxs([]);
    setMapJdx([]);
    setResultsVisible(false);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (year == null) {
      setRequiredAlertVisible(true);
      return;
    }
    if (selectedJdxs.length == 0) {
      setJdxAlertVisible(true);
      return;
    }

    getCases();

    var chartJdxsTemp = [];
    for (var jdx of selectedJdxs) {
      chartJdxsTemp.push(jdx.jdxName);
    }

    setMapJdx(selectedJdxs);
    setChartJdxs(chartJdxsTemp);

    let formData = new FormData();
    setResultsVisible(true);

    formData.append('YEAR', year);
    formData.append('JDXLIST', JSON.stringify(selectedJdxs));

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'NewHomicide', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setChartData(null);

          var ChartLabels = [];
          var ValuesArray = [];
          var definingTotal = 0;

          data.forEach(dataPoint => {
            ChartLabels.push(dataPoint.jurisdictionName);
            ValuesArray.push(dataPoint.count);
            definingTotal += dataPoint.count;
          });

          definingChartData.labels = ChartLabels;
          definingChartData.datasets[0].data = ValuesArray;

          setChartData(definingChartData);
          setTotal(definingTotal);
          setloading(false);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    setloading(false);
  };

  useEffect(() => {
    if (user === null) return;

    const fetchYear = async () => {
      try {
        const currentYear = new Date().getFullYear();
        setYear(currentYear);
        setYearOptions([...Array(16).keys()].map(i => i + (currentYear - 15)).reverse());
      } catch (e) {
        alert(e);
      }
    };

    fetchYear();
  }, []);

  const getCases = async () => {
    const url = `${REACT_APP_API_URL}RTHomicide/${year}`;
    await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user?.accessToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedJdxs),
    })
      .then(res => {
        if (res.status === 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occurred');
        }
        return res.json();
      })
      .then(data => {
        setCases(data);
      })
      .catch(e => {
        setCases([]);
      });
  };

  return (
    <Card
      raised
      sx={{
        my: 3,
        mx: 'auto',
        width: { xs: '95%', lg: '75%' },
      }}
    >
      <CardHeader
        title='Homicide By Count Chart'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
        action={
          <Tooltip
            sx={{ color: theme.palette.text.primary }}
            title='This report displays a bar chart with the number of suicides per jurisdiction for the year and jurisdictions you specify.'
            placement='right-end'
          >
            <InfoIcon />
          </Tooltip>
        }
      />

      <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

      <CardContent>
        <Slide
          direction='right'
          in={requiredAlertVisible}
          mountOnEnter
          unmountOnExit
          sx={{ mb: 2 }}
        >
          <Alert severity='warning' onClose={dismissDateAlert}>
            Please select a year.
          </Alert>
        </Slide>

        <Slide direction='right' in={jdxAlertVisible} mountOnEnter unmountOnExit sx={{ mb: 2 }}>
          <Alert severity='warning' onClose={dismissJdxAlert}>
            Please select at least 1 jurisdiction.
          </Alert>
        </Slide>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id='selectedJdxs'
              options={authorizedJdxs}
              value={selectedJdxs}
              disableCloseOnSelect
              limitTags={4}
              fullWidth
              size='small'
              getOptionLabel={option => option.jdxName}
              onChange={(event, data) => handleJdxChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.jdxName}
                </li>
              )}
              renderInput={params => (
                <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
              )}
              ListboxComponent={'ul'}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              id='year'
              options={yearOptions}
              value={year}
              size='small'
              getOptionLabel={option => option.toString()}
              onChange={(event, data) => handleYearChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.toString()}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => <TextField {...params} label='Year' placeholder='Year' />}
              ListboxComponent={'ul'}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 2, opacity: 1 }} />

        <Stack
          direction='row'
          sx={{
            width: '100%',
            justifyContent: 'right',
          }}
          spacing={2}
        >
          <Button type='submit' variant='contained' color='primary' onClick={handleSubmit}>
            Calculate
          </Button>

          <Button color='secondary' variant='outlined' onClick={clearAll}>
            Clear
          </Button>
        </Stack>

        <Collapse in={resultsVisible} sx={{ mt: resultsVisible ? 4 : 0 }}>
          {loading ? (
            <div style={{ height: 350 }}></div>
          ) : (
            <div>
              <Bar options={options} data={chartData} />
              <p className='text-center chart-definingTotal'>
                <i>Total:</i> <b>{total}</b>
              </p>
            </div>
          )}
        </Collapse>

        <br />
        <RTHomicideMap cases={cases} open={resultsVisible && cases.length > 0} />
      </CardContent>
    </Card>
  );
}
