import { useMemo } from 'react';
import { useAuth } from '../AuthService';

export function useTwoFactorHelper() {
  const auth = useAuth();

  const hasTwoFactorError = useMemo(
    () =>
      auth.two_factor.email_state.state === 'error' ||
      auth.two_factor.sms_state.state === 'error',
    [auth.two_factor.email_state.state, auth.two_factor.sms_state.state]
  );

  const userHasEmail = useMemo(
    () => Boolean(auth.user?.primaryEmail),
    [auth.user?.primaryEmail]
  );

  const userEmail = useMemo(
    () => (userHasEmail ? auth.user?.primaryEmail : ''),
    [userHasEmail]
  );

  const userHasPhone = useMemo(
    () =>
      Boolean(
        auth.user?.primaryMobileNo && auth.user.primaryMobileNo.length > 3
      ),
    [auth.user?.primaryMobileNo]
  );

  const userPhoneNumber = useMemo(
    () => (userHasPhone ? auth.user?.primaryMobileNo : ''),
    [userHasPhone]
  );

  function getErrorMessage() {
    if (auth.two_factor.email_state.state === 'error') {
      return auth.two_factor.email_state.message ?? 'Unknown error';
    } else if (auth.two_factor.sms_state.state === 'error') {
      return auth.two_factor.sms_state.message ?? 'Unknown error';
    } else if (auth.login_state.state === 'error') {
      return auth.login_state.message ?? 'Unknown error';
    } else return 'Unknown error';
  }

  return {
    hasTwoFactorError,
    userHasEmail,
    userPhoneNumber,
    userHasPhone,
    userEmail,
    getErrorMessage,
  };
}
