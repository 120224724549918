import React from 'react';
import { Field } from 'formik';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormControl } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { ActionList } from '../CaseView/CaseViewDateTimeField';

const DatePickerComponent = ({ label, name, module, ...rest }) => {
  return (
    <div>
      <Field name={name}>
        {({ form, field }) => {
          return (
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {module === 'date-time' ? (
                  <DatePicker
                    label={label}
                    value={dayjs(field?.value) ?? null}
                    onChange={dateTime => form?.setFieldValue(name, dateTime)}
                    inputVariant='outlined'
                    dateFormat='yyyy/MM/dd'
                    // disableFuture={label == 'Expiry Date' ? false : true}
                    slotProps={{
                      textField: {
                        error: false,
                      },
                    }}
                    slots={{
                      actionBar: ActionList,
                    }}
                    {...rest}
                  />
                ) : (
                  <DatePicker
                    label={label}
                    value={dayjs(field?.value) ?? null}
                    onChange={dateTime => form?.setFieldValue(name, dateTime)}
                    inputVariant='outlined'
                    dateFormat='yyyy/MM/dd'
                    // disableFuture={label == 'Expiry Date' ? false : true}
                    slotProps={{
                      textField: {
                        error: false,
                      },
                    }}
                    slots={{
                      actionBar: ActionList,
                    }}
                    {...rest}
                  />
                )}
              </LocalizationProvider>
              {!!form?.errors?.[name] ? (
                <p style={{ color: '#d32f2f' }}>{form?.errors?.[name]}</p>
              ) : null}
            </FormControl>
          );
        }}
      </Field>
    </div>
  );
};

export default DatePickerComponent;
