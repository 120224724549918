import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  Paper,
  Slide,
  Stack,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function CasesNoNOKReportView() {
  const { user } = useAuth();
  const [loading, setloading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const [numberOfCasesFound, setNumberOfCasesFound] = useState('');
  const [requiredAlertVisible, setRequiredAlertVisible] = useState(false);
  const [MEAction, setMEAction] = useState([]);
  const [MEActionOptions, setMEActionOptions] = useState([]);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [selectedJdxs, setSelectedJdxs] = useState([]);
  const { REACT_APP_API_URL } = process.env;
  const theme = useTheme();

  const columns = [
    {
      field: 'caseid',
      headerName: 'Case ID',
      width: 80,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'decedentname',
      headerName: 'Decedent Name',
      width: 100,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'jurisdiction',
      headerName: 'Jurisdiction',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'menotified',
      headerName: 'Reported On',
      width: 120,
      editable: false,
      headerAlign: 'left',
      valueFormatter: value => {
        const date = new Date(value);
        return date.toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true, // Optional, use 24-hour format if false
        });
      },
    },
    {
      field: 'meaction',
      headerName: 'ME Action',
      width: 100,
      editable: false,
      headerAlign: 'left',
    },
  ];

  const handleJdxChange = (event, data) => {
    setSelectedJdxs(data);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  const handleMEActionChange = (event, data) => {
    setMEAction(data);
  };

  const clearAll = () => {
    setSelectedJdxs([]);
    setMEAction([]);
    setResultsVisible(false);
  };

  //gather search params from form and FETCH/POST to API
  const handleSubmit = async event => {
    event.preventDefault();

    if (selectedJdxs.length == 0) {
      setJdxAlertVisible(true);
      return;
    }

    let formData = new FormData();

    setloading(true);
    setResultsVisible(true);

    formData.append('SELECTEDJDXLIST', JSON.stringify(selectedJdxs));
    formData.append('MEACTION', JSON.stringify(MEAction));

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'getcasesnonok', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setlistresults(data);
          setNumberOfCasesFound(data.length);
          setloading(false);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    await setloading(false);
  };

  useEffect(() => {
    if (user === null) return;
    // Get MEAction Options
    const getMeActionOptions = async () => {
      await fetch(process.env.REACT_APP_API_URL + 'getmeactionoptions', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => {
          if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setMEActionOptions(data);
        })
        .catch(e => {
          alert(e);
        });
    };

    getMeActionOptions();
    setAuthorizedJdxs(user.jdxAccessList);
    setSelectedJdxs(user.jdxAccessList);
  }, [user]);

  return (
    <Card
      raised
      sx={{
        my: 3,
        mx: 'auto',
        width: { xs: '95%', lg: '75%' },
      }}
    >
      <CardHeader
        title='All Cases with no NOK'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
        action={
          <Tooltip
            sx={{ color: theme.palette.text.primary }}
            title='Criteria: any active case in your selected jurisdiction(s) with a valid MEAction and Case Reported time entered that does not have any NOK entered'
            placement='right-end'
          >
            <InfoIcon />
          </Tooltip>
        }
      />

      <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

      <CardContent>
        <Slide direction='right' in={jdxAlertVisible} mountOnEnter unmountOnExit sx={{ mb: 2 }}>
          <Alert severity='warning' onClose={dismissJdxAlert}>
            Please select at least 1 jurisdiction.
          </Alert>
        </Slide>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id='selectedJdxs'
              //options={authorizedJdxs}
              options={authorizedJdxs.sort((a, b) => a.jdxName.localeCompare(b.jdxName))}
              value={selectedJdxs}
              disableCloseOnSelect
              limitTags={4}
              fullWidth
              size='small'
              getOptionLabel={option => option.jdxName}
              onChange={(event, data) => handleJdxChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.jdxName}
                </li>
              )}
              renderInput={params => (
                <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
              )}
              ListboxComponent={'ul'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              id='meaction'
              size='small'
              options={MEActionOptions.filter(o => o.isActive !== false)}
              value={MEAction}
              disableCloseOnSelect
              getOptionLabel={option => option.optionName}
              onChange={(event, data) => handleMEActionChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.optionName}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField {...params} label='ME Action' placeholder='ME Action' />
              )}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 2, opacity: 1 }} />

        <Stack
          direction='row'
          sx={{
            width: '100%',
            justifyContent: 'right',
          }}
          spacing={2}
        >
          <Button type='submit' variant='contained' color='primary' onClick={handleSubmit}>
            Calculate
          </Button>

          <Button color='secondary' variant='outlined' onClick={clearAll}>
            Clear
          </Button>
        </Stack>

        <Collapse in={resultsVisible} sx={{ mt: resultsVisible ? 4 : 0 }}>
          <ReportDataGrid
            loading={loading}
            listresults={listresults}
            idcolumn='caseid'
            columnsInput={columns}
            title={`${numberOfCasesFound} Cases Found: `}
            chatcontent={
              <Paper
                elevation={3}
                sx={{
                  p: '10px',
                  px: '20px',
                  borderRadius: '20px',
                  width: 'max-content',
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  I've listed all {numberOfCasesFound} cases found without NOK entered, ordered by
                  the time that the case was reported. You can export this list in CSV format by
                  clicking the 'EXPORT' button on the top-right of the results grid
                </div>
              </Paper>
            }
          />
        </Collapse>
      </CardContent>
    </Card>
  );
}
