import { Box, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { ActionList } from './CaseViewDateTimeField';
import { useUnsavedChanges } from '../../Context/UnsavedChangesContext';

function getValueByNestedKey(object, key) {
  const keys = key.split('.');
  return keys.reduce((obj, k) => obj?.[k], object);
}

export default function CaseViewDateField({
  xs,
  sm,
  md,
  lg,
  xl,
  authorizedToEdit,
  fieldName,
  label,
  sx,
}) {
  const formik = useFormikContext();
  const fieldValue = formik?.values && getValueByNestedKey(formik?.values, fieldName);
  const fieldTouched = formik?.touched && getValueByNestedKey(formik?.touched, fieldName);
  const fieldErrors = formik?.errors && getValueByNestedKey(formik?.errors, fieldName);
  const { setUnsavedChanges } = useUnsavedChanges();

  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      sx={sx}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            position: 'relative',
            display: 'inline-block',
            width: '100%',
          }}
        >
          <DatePicker
            disabled={
              !formik?.status?.editing || !authorizedToEdit
            }
            fullWidth
            name={fieldName}
            label={label}
            value={fieldValue !== null ? dayjs(fieldValue) : null}
            onChange={data => { 
              formik.setFieldValue(fieldName, data);
              setUnsavedChanges();           
            }}
            error={(fieldErrors ?? false) && (fieldTouched ?? false)}
            helperText={fieldTouched ? fieldErrors?.toString() : ''}
            slots={{
              actionBar: ActionList,
            }}
            slotProps={{
              textField: {
                id: { fieldName },
              },
              field: { clearable: true },
            }}
            sx={{ width: '100%' }}
          />
        </Box>
      </LocalizationProvider>
    </Grid>
  );
}
