import React from 'react';

import { IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ApiNotification = ({
  openApiDialogBox,
  closeDialogBox,
  dialogContents,
}) => {
  return (
    <div className='modal'>
      <Dialog
        open={openApiDialogBox}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeDialogBox}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            {dialogContents}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton onClick={closeDialogBox}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ApiNotification;
