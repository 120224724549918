/*
 * THIS VIEW SHOULD BE EVENTUALLY BE DEPRECATED AND REPLACED WITH A MORE ELEGANT
 * VIEW WHICH WORKS FOR BOTH THE LEGACY CASE INTAKE AND THE NEW QUICK INTAKE
 * - SEAN, 9/7/23
 */

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

export default function __QuickCreatingCaseView({
  newCaseID,
  error,
}: {
  newCaseID?: string;
  error?: string;
}) {
  const [counter, setCounter] = React.useState(0);
  const [progress, setProgress] = React.useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (!newCaseID) {
      // Start increasing progress every second
      intervalId = setInterval(() => {
        setProgress(prevProgress => {
          const newProgress = prevProgress + 5;
          // Ensure progress does not exceed 100%
          return newProgress >= 100 ? 100 : newProgress;
        });
      }, 1000);
    }

    return () => {
      // Clear interval when component unmounts or newCaseID changes
      clearInterval(intervalId);
    };
  });

  useEffect(() => {
    if (newCaseID) {
      setProgress(100);

      const timer = setTimeout(() => {
        navigate('/caseview', { state: { cmscaseid: newCaseID } });
      }, 750);

      return () => clearTimeout(timer);
    }
  }, [newCaseID]);

  if (error) {
    return (
      <Box component='div' sx={{ width: '100%', textAlign: 'center', marginTop: 30 }}>
        <h1 style={{ margin: 40 }}>Uh oh</h1>
        <h3>There was an error while creating your case:</h3>
        <h3>{`${error}`}</h3>
      </Box>
    );
  }

  return (
    <Box component='div' sx={{ paddingX: 20, width: '100%', textAlign: 'center', marginTop: 30 }}>
      <h1 style={{ margin: 40 }}>Creating New Case..</h1>
      <LinearProgress variant='determinate' value={progress} />
    </Box>
  );
}
