import React, { useEffect, useState } from 'react';
import ControlComponents from '../../views/SysAdmin/SysAdminModules/UserAdminModules/ControlComponents';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const InvestigatorCertification = ({
  abmdiCertification,
  boardCertifiedFellow,
  abmdiCertificationExpiryDate
}) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        // border: '1px solid grey',
        // borderRadius: '12px',
        // padding: '8px 0px 12px',
        marginTop: '16px',
      }}
    >
      <h5 style={{ marginTop: '8px' }}>Investigator Certification</h5>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12} md={12} sm={12}>
          <ControlComponents
            control='input'
            name={abmdiCertification}
            label='ABMDI Certification'
            type='text'
          />
        </Grid>
        <Grid item lg={6} xs={12} md={12} sm={12}>
          <ControlComponents
            control='input'
            name={boardCertifiedFellow}
            label='Board Certified Fellow'
            type='input'
          />
        </Grid>
        <Grid item lg={6} xs={12} md={12} sm={12}>
          <ControlComponents
            control='date'
            name={abmdiCertificationExpiryDate}
            label='Expiry Date'
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvestigatorCertification;
