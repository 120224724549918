import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { GridColDef } from '@mui/x-data-grid-premium';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { isDayjs } from 'dayjs';
import { useAuth } from '../../../utils/auth/AuthService';
import { renderAutocompleteEditCell } from '../../../utils/components/CrudDataGrid/AutocompleteEditCell';
import { dateColumnType } from '../../../utils/components/CrudDataGrid/DateColumn';
import { renderMultiAutocompleteEditCell } from '../../../utils/components/CrudDataGrid/MultiAutocompleteEditCell';
import DialogCrudDataGrid, {
  getDateValue,
  handleAutocompleteChange,
  handleCheckboxChange,
  handleDateChange,
  handleTextFieldChange,
} from '../../../utils/components/DialogCrudDataGrid';
import { sortOptionsAlphabetically } from '../../../utils/functions/sortOptionsAlphabetically';
import { ActionList } from '../CaseViewDateTimeField';

export default function LabTestTrackingDataGrid() {
  const { user } = useAuth();
  const { REACT_APP_API_URL } = process.env;
  const formik = useFormikContext<CMSCase>();
  const [testOptions, setTestOptions] = useState(null);
  const [labOptions, setLabOptions] = useState(null);
  const [tagOptions, setTagOptions] = useState(null);
  const [labTestTrackingRows, setLabTestTrackingRows] = useState(
    formik.values?.caseLabTesting.labTestTracking ?? []
  );

  useEffect(() => {
    // INITIALIZE TEST OPTIONS
    fetch(REACT_APP_API_URL + 'getlabtestoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setTestOptions(sortOptionsAlphabetically(data, 'optionName'));
      })
      .catch(e => {
        //alert(e);
      });

    // INITIALIZE LAB OPTIONS
    fetch(REACT_APP_API_URL + 'getlaboptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setLabOptions(sortOptionsAlphabetically(data, 'optionName'));
      })
      .catch(e => {
        //alert(e);
      });

    // INITIALIZE TAG OPTIONS
    fetch(REACT_APP_API_URL + 'getlabtesttrackingtagoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setTagOptions(data);
      })
      .catch(e => {
        //alert(e);
      });

    getLabTestTracking();
  }, []);

  const getLabTestTracking = async () => {
    try {
      const caseSeq = formik.values?.caseSummary?.caseSeq;
      const response = await fetch(`${REACT_APP_API_URL}getlabtesttracking/?caseSeq=${caseSeq}`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      } else if (response.status === 204) {
        setLabTestTrackingRows([]);
      }
      const data = await response.json();
      setLabTestTrackingRows(data);
    } catch (error) {
      console.error('There was a problem fetching Lab Test Tracking data: ', error);
    }
  };

  const createLabTestTracking = async newRow => {
    let formData = new FormData();
    formData.append('newRow', JSON.stringify(newRow));
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);
    formData.append('userSeq', user.userSeq);
    await fetch(REACT_APP_API_URL + 'createlabtesttracking', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {})
      .catch(e => {
        console.log(e);
      });

    await getLabTestTracking();
  };

  const updateLabTestTracking = async updatedRow => {
    let formData = new FormData();
    formData.append('updatedRow', JSON.stringify(updatedRow));
    formData.append('userSeq', user.userSeq);

    await fetch(REACT_APP_API_URL + 'updatelabtesttracking', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {})
      .catch(e => {
        console.log(e);
      });

    await getLabTestTracking();
  };

  const deleteLabTestTracking = async id => {
    await fetch(REACT_APP_API_URL + `deletelabtesttracking/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {})
      .catch(e => {
        console.log(e);
      });

    await getLabTestTracking();
  };

  const labTestTrackingColumns: GridColDef[] = [
    {
      flex: 1,
      minWidth: 150,
      field: 'test',
      headerName: 'Type of Test',
      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (value: Option) => {
        if (!value) return value;
        return value.optionName;
      },
      valueOptions: testOptions,
      renderEditCell: params => renderAutocompleteEditCell(params),
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'retained',
      headerName: 'Retained (Not Tested)',
      type: 'boolean',
      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 0.65,
      minWidth: 97,
      field: 'notTaken',
      headerName: 'Not Taken',
      type: 'boolean',
      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 1,
      field: 'dateRequested',
      headerName: 'Date Requested',
      headerAlign: 'left',
      align: 'left',
      ...dateColumnType,
      valueGetter: value => {
        if (!value) return undefined;
        if (value === 'Invalid Date') {
          return undefined;
        }
        return new Date(value);
      },
    },
    {
      flex: 1,
      field: 'dateReceived',
      headerName: 'Date Received',
      headerAlign: 'left',
      align: 'left',
      ...dateColumnType,
      valueGetter: value => {
        if (!value) return undefined;
        if (value === 'Invalid Date') {
          return undefined;
        }
        return new Date(value);
      },
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'lab',
      headerName: 'Lab Name',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (value: Option) => {
        if (!value) return value;
        return value.optionName;
      },
      type: 'singleSelect',
      valueOptions: labOptions,
      renderEditCell: params => renderAutocompleteEditCell(params),
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'tags',
      headerName: 'Tags',
      headerAlign: 'left',
      align: 'left',
      type: 'singleSelect',
      valueOptions: tagOptions,
      renderCell: params => {
        if (!params.value) return null;
        return (
          <Stack direction='row' spacing={1}>
            {params.value.map(tag => (
              <Chip label={tag.optionName} size='small' />
            ))}
          </Stack>
        );
      },
      renderEditCell: params => renderMultiAutocompleteEditCell(params),
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'comments',
      headerName: 'Comments',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
    },
  ];

  const dialogContent = ({ mode, data, onChange }) => {
    const [localData, setLocalData] = useState(null);

    useEffect(() => {
      setLocalData(data);
    }, [data]);

    return (
      <Grid container spacing={1} sx={{ alignItems: 'center' }}>
        <Grid item xs={12} md={6} xl={3}>
          <Autocomplete
            options={testOptions}
            getOptionLabel={option => option.optionName || ''}
            value={localData?.test || null}
            onChange={(event, value) =>
              handleAutocompleteChange(event, value, 'test', localData, setLocalData, onChange)
            }
            renderInput={params => (
              <TextField {...params} label='Type of Test' margin='dense' fullWidth />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          <Autocomplete
            options={labOptions}
            getOptionLabel={option => option.optionName || ''}
            value={localData?.lab || null}
            onChange={(event, value) =>
              handleAutocompleteChange(event, value, 'lab', localData, setLocalData, onChange)
            }
            renderInput={params => (
              <TextField {...params} label='Lab Name' margin='dense' fullWidth />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label='Date Requested'
              value={getDateValue(localData?.dateRequested)}
              onChange={value =>
                handleDateChange(value, 'dateRequested', localData, setLocalData, onChange)
              }
              slots={{ actionBar: ActionList }}
              sx={{ width: '100%' }}
              slotProps={{ textField: { margin: 'dense' } }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label='Date Received'
              value={getDateValue(localData?.dateReceived)}
              onChange={value =>
                handleDateChange(value, 'dateReceived', localData, setLocalData, onChange)
              }
              slots={{ actionBar: ActionList }}
              sx={{ width: '100%' }}
              slotProps={{ textField: { margin: 'dense' } }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            options={tagOptions}
            getOptionLabel={option => option.optionName || ''}
            value={localData?.tags || []}
            onChange={(event, value) =>
              handleAutocompleteChange(event, value, 'tags', localData, setLocalData, onChange)
            }
            renderInput={params => <TextField {...params} label='Tags' margin='dense' fullWidth />}
            size='small'
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            value={localData?.comments ?? ''}
            onChange={event =>
              handleTextFieldChange(event, 'comments', localData, setLocalData, onChange)
            }
            label='Comments'
            fullWidth
            margin='dense'
          />
        </Grid>

        <Grid item xs='auto'>
          <FormControlLabel
            label='Retained (Not Tested)'
            control={
              <Checkbox
                checked={localData?.retained ?? false}
                onChange={event =>
                  handleCheckboxChange(event, 'retained', localData, setLocalData, onChange)
                }
              />
            }
          />
        </Grid>

        <Grid item xs='auto'>
          <FormControlLabel
            label='Not Taken'
            control={
              <Checkbox
                checked={localData?.notTaken ?? false}
                onChange={event =>
                  handleCheckboxChange(event, 'notTaken', localData, setLocalData, onChange)
                }
              />
            }
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <DialogCrudDataGrid
      rows={labTestTrackingRows}
      columns={labTestTrackingColumns}
      title='Requested Test'
      idColumn='labTestOnCaseSeq'
      createFunction={createLabTestTracking}
      deleteFunction={deleteLabTestTracking}
      updateFunction={updateLabTestTracking}
      dialogContent={dialogContent}
    />
  );
}
