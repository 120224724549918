import React, { useState, useEffect } from 'react';

import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  FormHelperText,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  IconButton,
} from '@mui/material';
import { TextField } from '@mui/material';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import {
  getAdminData,
  saveAdminData,
} from '../../../../../../services/utility-api';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import * as Yup from 'yup';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const AddEdit = ({
  editObj,
  referenceMasterTableName,
  setIsAddEditOn,
  getAllData,
  selectedRowApi,
  referencePermissions,
}) => {
  const [liveryService, setLiveryService] = useState(editObj?.liveryService);
  const [middleName, setMiddleName] = useState(editObj?.middleName);
  const [lastName, setLastName] = useState(editObj?.lastName);
  const [firstName, setFirstName] = useState(editObj?.firstName);
  const [suffix, setSuffix] = useState(editObj?.suffix);
  const [liveryInputs, setLiveryInputs] = useState(false);
  const [liveryOptions, setLiveryOptions] = useState([]);
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);

  const initialValues = {
    ...editObj,
    firstName,
    middleName,
    lastName,
    suffix,
    liveryService: editObj?.liveryService,
  };

  const validationSchema = Yup.object({
    lastName: Yup.string().required('lastName is required').nullable(),
  });

  useEffect(() => {
    const fetchLiveryServices = async () => {
      const response = await getAdminData('liveryservices');

      let arr = [];
      response?.data?.data.forEach((liveryService) => {
        const livery = liveryService?.['livery']?.[0]?.liveryName ?? '';

        if (!arr.includes(livery)) {
          setLiveryOptions((prev) => [...prev, livery]);
          arr.push(livery);
        }
      });
    };
    fetchLiveryServices();
  }, []);

  const onSave = async () => {
    if (!lastName || !liveryService) return;
    const id = '00000000-0000-0000-0000-000000000000';
    const { status, countySeq, liveryPersonOrder, liveryPersonSeq, liverySeq } =
      selectedRowApi;
    const { liveryCode, liveryDescription, liveryName } =
      selectedRowApi.livery ?? {};
    const {
      address,
      caseWorksheetSeq,
      contactItem,
      fdnylicenseNo,
      licenseNo,
      personContactInfoSeq,
      personFirstName,
      personGenderCode,
      personLastName,
      personMaidenName,
      personMiddleName,
      personOtherRace,
      personRaceSeq,
      personSalutationSeq,
      personSeq,
      personSuffix,
      personTitle,
    } = selectedRowApi?.person ?? {};

    const liveryPersonPayload = {
      liveryPersonSeq: liveryPersonSeq ?? id,
      person: {
        address: address ?? null,
        caseWorksheetSeq: caseWorksheetSeq ?? id,
        contactItem: contactItem ?? null,
        fdnylicenseNo: fdnylicenseNo ?? id,
        licenseNo: licenseNo ?? id,
        personContactInfoSeq: personContactInfoSeq ?? id,
        personFirstName: firstName,
        personGenderCode: personGenderCode ?? '',
        personLastName: lastName ?? '',
        personMaidenName: personMiddleName ?? '',
        personMiddleName: middleName,
        personOtherRace: personOtherRace ?? '',
        personRaceSeq: personRaceSeq ?? id,
        personSalutationSeq: personSalutationSeq ?? id,
        personSeq: personSeq ?? id,
        personSuffix: suffix,
        personTitle: personTitle ?? '',
      },
      liverySeq: liverySeq ?? id,
      livery: {
        countySeq: countySeq ?? '00000000-0000-0000-0000-000000000000',
        liveryCode: liveryCode ?? '1',
        liveryDescription: liveryDescription ?? '',
        liveryName: liveryService,
        liverySeq: liverySeq ?? id,
        status: status ?? 'Active',
      },
      liveryPersonOrder: liveryPersonOrder ?? 0,
      countySeq: id,
      personSeq: personSeq ?? id,
      status: 'Active',
    };

    try {
      const response = await saveAdminData({
        values: liveryPersonPayload,
        tableName: referenceMasterTableName,
      });
      if (response.status == 0) {
        setTimeout(() => {
          setIsAddEditOn(false);
          getAllData();
        }, 1700);
      }
      setDialogContents(response.message);
      setOpenApiDialogBox(true);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    setFirstName(editObj.firstName);
    setLastName(editObj.lastName);
    setMiddleName(editObj.middleName);
    setSuffix(editObj.suffix);
    setLiveryService(editObj?.liveryService);
  }, [editObj]);

  return (
    <>
      <Grid elevation={0} spacing={2} sx={{ padding: '16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={10} mb={2} mt={2}>
            <Typography variant='h5'>
              <strong>
                {Object.keys(editObj).length ? 'Modify ' : 'Enter New '} Livery
                Person
              </strong>
            </Typography>
          </Grid>
        </Grid>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSave}
        >
          {({ handleSubmit, values, handleChange }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl
                      fullWidth
                      sx={{ mt: 2, minWidth: 120 }}
                      required
                    >
                      <InputLabel id='liveryServiceLabel'>
                        Livery Service
                      </InputLabel>
                      <Select
                        displayEmpty
                        name='liveryService'
                        value={liveryService ? liveryService : ''}
                        label='Livery Service'
                        onChange={(e) => setLiveryService(e.target.value)}
                        required
                      >
                        {liveryOptions.map((service) => {
                          return (
                            <MenuItem value={service} name={service}>
                              {service}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl
                      fullWidth
                      sx={{ m: 0, minWidth: 120 }}
                      variant='standard'
                    >
                      <TextField
                        focused
                        required
                        id='lastName'
                        label='Last Name'
                        variant='outlined'
                        name='lastName'
                        value={lastName}
                        margin='normal'
                        size='large'
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl
                      fullWidth
                      sx={{ m: 0, minWidth: 120 }}
                      variant='standard'
                    >
                      <TextField
                        focused
                        id='Suffix'
                        label='Suffix'
                        variant='outlined'
                        name='suffix'
                        value={suffix}
                        onChange={(e) => setSuffix(e.target.value)}
                        margin='normal'
                        size='large'
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl
                      fullWidth
                      sx={{ m: 0, minWidth: 120 }}
                      variant='standard'
                    >
                      <TextField
                        focused
                        id='firstName'
                        label='First Name'
                        variant='outlined'
                        name='firstName'
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        margin='normal'
                        size='large'
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl
                      fullWidth
                      sx={{ m: 0, minWidth: 120 }}
                      variant='standard'
                    >
                      <TextField
                        focused
                        id='middleName'
                        label='Middle Name'
                        variant='outlined'
                        name='middleName'
                        value={middleName}
                        margin='normal'
                        size='large'
                        onChange={(e) => setMiddleName(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Button
                        type='submit'
                        onClick={() => {
                          onSave();
                        }}
                        variant='contained'
                        sx={{ minWidth: '125px', marginTop: '7px' }}
                        disabled={!referencePermissions?.add}
                      >
                        Save
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>

      <div className='modal'>
        <Dialog
          open={openApiDialogBox}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenApiDialogBox(false)}
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              {dialogContents}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <IconButton onClick={() => setOpenApiDialogBox(false)}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
export default AddEdit;
