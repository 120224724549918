import { Delete, Folder, QuestionMark, SettingsSuggest, UploadFile } from '@mui/icons-material';
import {
  Fade,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Notification, useNotifications } from './NotificationsContext';

import 'swiper/css';
import 'swiper/css/effect-creative';
import { EffectCreative } from 'swiper/modules';

export const NotificationListItem = ({
  n,
  closeSidebar,
}: {
  n: Notification;
  closeSidebar: Function;
}) => {
  const { updateNotificationReadStatus, updateNotification } = useNotifications();
  const navigate = useNavigate();

  /**
   * To view all NotificationTypeSeq's and their purposes run the following db query:
   * SELECT * FROM NotificationType
   */
  switch (n.notificationTypeSeq.toLowerCase()) {
    /**
     * Message
     * 2cd795ca-ac1b-43c3-839a-80bea8d9e13b
     */
    case '2cd795ca-ac1b-43c3-839a-80bea8d9e13b': {
      return (
        <SwipeableNotificationListItem
          notification={n}
          icon={<Folder color='primary' />}
          primary={`New message`}
          secondary={n.notificationData?.message}
        />
      );
    }

    /**
     * Case Created
     */
    case '10b834f0-13a9-4c62-b592-96dcc811b781': {
      const handleOnClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e?.stopPropagation();

        await updateNotificationReadStatus(n?.notificationSeq, true);
        navigate('/caseview', {
          state: {
            cmscaseid: n.notificationData?.caseid,
            caseSeq: n.notificationData?.caseseq,
          },
        });

        if (typeof closeSidebar === 'function') {
          closeSidebar();
        }
      };

      return (
        <SwipeableNotificationListItem
          notification={n}
          icon={<Folder color='primary' />}
          primary={`New case ${n.notificationData?.caseid}`}
          secondary={`by ${n.notificationData?.createdBy?.userName}`}
          onClick={handleOnClick}
        />
      );
    }

    /**
     * Case Assign
     */
    case '09d65094-5f8a-4bd5-b2d9-e716403a8d02': {
      const handleOnClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e?.stopPropagation();

        await updateNotificationReadStatus(n?.notificationSeq, true);
        navigate('/caseview', {
          state: {
            cmscaseid: n.notificationData?.caseid,
            caseSeq: n.notificationData?.caseseq,
          },
        });

        if (typeof closeSidebar === 'function') {
          closeSidebar();
        }
      };

      return (
        <SwipeableNotificationListItem
          notification={n}
          icon={<Folder color='secondary' />}
          primary='Case Assigned'
          secondary={n?.notificationData?.caseid}
          onClick={handleOnClick}
        />
      );
    }

    /**
     * Document Uploaded
     * Notifies a user when a document has been uploaded
     */
    case '096b22b4-d934-4139-a1a5-c3340d2f602c': {
      const handleOnClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e?.stopPropagation();

        await updateNotificationReadStatus(n?.notificationSeq, true);
        navigate('/caseview', {
          state: {
            cmscaseid: n.notificationData?.caseid,
            caseSeq: n.notificationData?.caseseq,
          },
        });

        if (typeof closeSidebar === 'function') {
          closeSidebar();
        }
      };
      return (
        <SwipeableNotificationListItem
          notification={n}
          icon={<UploadFile color='primary' />}
          primary='Document Uploaded'
          secondary={`${n.notificationData?.fileRelatedToName} uploaded for case ${n.notificationData?.caseid}`}
          onClick={handleOnClick}
        />
      );
    }

    /**
     * Document Upload
     * Notifies a user when a document has been uploaded
     */
    case '096b22b4-d934-4139-a1a5-c3340d2f602c': {
      const handleOnClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e?.stopPropagation();

        await updateNotificationReadStatus(n?.notificationSeq, true);
        navigate('/caseview', {
          state: {
            cmscaseid: n.notificationData?.caseid,
            caseSeq: n.notificationData?.caseseq,
          },
        });

        if (typeof closeSidebar === 'function') {
          closeSidebar();
        }
      };

      return (
        <SwipeableNotificationListItem
          notification={n}
          icon={<Folder color='primary' />}
          primary={`New case ${n.notificationData?.caseid}`}
          secondary={`by ${n.notificationData?.createdBy?.userName}`}
          onClick={handleOnClick}
        />
      );
    }

    /**
     * Case Assign
     */
    case '09d65094-5f8a-4bd5-b2d9-e716403a8d02': {
      return (
        <SwipeableNotificationListItem
          notification={n}
          icon={<Folder color='secondary' />}
          primary='Case Assigned'
          secondary={n?.notificationData?.message}
        />
      );
    }

    /**
     * Document Uploaded
     * Notifies a user when a document has been uploaded
     */
    case '096b22b4-d934-4139-a1a5-c3340d2f602c': {
      const handleOnClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e?.stopPropagation();

        await updateNotificationReadStatus(n?.notificationSeq, true);
        navigate('/caseview', {
          state: {
            cmscaseid: n.notificationData?.caseid,
            caseSeq: n.notificationData?.caseseq,
          },
        });

        if (typeof closeSidebar === 'function') {
          closeSidebar();
        }
      };
      return (
        <SwipeableNotificationListItem
          notification={n}
          icon={<UploadFile color='primary' />}
          primary='Document Uploaded'
          secondary={`${n.notificationData?.fileRelatedToName} uploaded for case ${n.notificationData?.caseid}`}
          onClick={handleOnClick}
        />
      );
    }

    /**
     * System
     */
    case '049ce6de-0d7b-44a8-93d8-47f75850d9c4': {
      return (
        <SwipeableNotificationListItem
          notification={n}
          icon={<SettingsSuggest color='success' />}
          primary='System'
          secondary={n?.notificationData?.message}
        />
      );
    }

    /**
     * Notification system welcome message
     */
    case 'c31f5efd-0436-4964-8eab-7454dae344f1': {
      return (
        <SwipeableNotificationListItem
          notification={n}
          primary='Welcome 🥳'
          secondary={
            <React.Fragment>
              <ul>
                <Typography component='li' variant='body2'>
                  Click to mark as read
                </Typography>
                <Typography component='li' variant='body2'>
                  Hover right to or swipe left to delete
                </Typography>
                <Typography component='li' variant='body2'>
                  <a href={n.notificationData?.url}>Read the docs here</a>
                </Typography>
              </ul>
            </React.Fragment>
          }
        />
      );
    }

    /**
     * Other
     */
    case '6cb2cfb9-9b8c-48ed-bb08-24334e455b6e': {
      return <SwipeableNotificationListItem notification={n} primary='Other' />;
    }

    /**
     * Unknown / Default
     */
    default: {
      return (
        <SwipeableNotificationListItem
          notification={n}
          icon={<QuestionMark color='primary' />}
          primary='Unknown'
          secondary={n?.notificationData?.message}
        />
      );
    }
  }
};

interface SwipeableNotificationListItemProps {
  notification: Notification;
  icon?: React.ReactNode;
  primary?: string | React.ReactNode;
  secondary?: string | React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
}
function SwipeableNotificationListItem(props: SwipeableNotificationListItemProps) {
  const { notification, icon, onClick, primary = 'Unknown', secondary = null, children } = props;

  const { updateNotificationReadStatus, updateNotification } = useNotifications();

  const [hoverRight, setHoverRight] = useState(false);
  const updateHoverState = (event: any) => {
    const target = event.currentTarget;
    const { right, width } = target.getBoundingClientRect();
    const hoverAreaStart = right - width * 0.2;
    setHoverRight(event.clientX >= hoverAreaStart);
  };

  const handleMouseLeave = () => {
    setHoverRight(false);
  };

  const handleDeleteNotification = (e: any, notification: Notification) => {
    e?.stopPropagation();
    updateNotification({
      ...notification,
      isActive: false,
    });
  };

  // Default action if no onClick is provided
  const markAsRead = () => {
    updateNotificationReadStatus(notification.notificationSeq, true);
  };

  // Handler that checks if onClick is provided, otherwise marks as read
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(e);
    } else {
      markAsRead();
    }
  };

  const listItemButtonStyle = {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    '&:hover': {
      backgroundImage: hoverRight
        ? 'linear-gradient(to left, rgba(0, 0, 0, 1) 20%, transparent 80%)'
        : 'none',
    },
    minHeight: '80px',
  };

  const listItemTextStyle = {
    // '& .MuiListItemText-secondary': { color: '#f8f8f8' },
    // color: '#fff',
    opacity: hoverRight ? 0.3 : 1,
  };

  const swiperRef = useRef<SwiperRef>(null);
  const [slideHeight, setSlideHeight] = useState('auto');

  useEffect(() => {
    const swiper = swiperRef.current;
    if (!swiper) return;

    const swiperInstance = swiper.swiper;
    if (swiperInstance) {
      // Calculate the height of the first slide
      const firstSlideHeight = swiperInstance.slides[0].offsetHeight;
      console.log(firstSlideHeight);

      setSlideHeight(`${firstSlideHeight}px`);
    }
  }, [props.children]);

  const theme = useTheme();

  return (
    <Swiper
      ref={swiperRef}
      grabCursor={true}
      effect={'creative'}
      creativeEffect={{
        prev: {
          shadow: true,
          translate: ['-20%', 0, -1],
        },
        next: {
          translate: ['100%', 0, 0],
        },
      }}
      allowTouchMove={true}
      modules={[EffectCreative]}
      onProgress={(swiper, progress) => {
        if (swiper.activeIndex === 1 && progress >= 0.7) {
          handleDeleteNotification(null, props.notification);
        }
      }}
    >
      <SwiperSlide>
        <ListItemButton
          onMouseEnter={updateHoverState}
          onMouseMove={updateHoverState}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
          sx={listItemButtonStyle}
        >
          {icon && <ListItemIcon>{icon}</ListItemIcon>}
          <Stack>
            <ListItemText primary={primary} secondary={secondary} sx={listItemTextStyle} />
            {children}
            <Typography component='p' variant='caption' color='gray' sx={{ margin: 0, padding: 0 }}>
              {formatDistanceToNow(new Date(props.notification.notificationCreatedOn), {
                addSuffix: true,
              })}
            </Typography>
            <Fade
              in={hoverRight}
              style={{
                position: 'absolute',
                right: 12,
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              <IconButton
                onClick={e => handleDeleteNotification(e, props.notification)}
                color='info'
              >
                <Delete color='error' />
              </IconButton>
            </Fade>
          </Stack>
        </ListItemButton>
      </SwiperSlide>
      <SwiperSlide>
        <ListItemButton
          sx={{
            backgroundColor: theme.palette.error.main,
            // color: 'white', // White text color
            '&:hover': {
              backgroundColor: theme.palette.error.dark,
            },
            height: slideHeight,
          }}
        >
          <ListItemIcon sx={{ color: 'white', minWidth: '40px' }}>
            <Delete />
          </ListItemIcon>
          <ListItemText
            primary='Delete'
            sx={{ color: 'white', '.MuiTypography-root': { fontWeight: 'bold' } }}
          />
        </ListItemButton>
      </SwiperSlide>
    </Swiper>
  );
}
