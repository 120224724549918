import {
  AddCircleOutlineRounded,
  AddRounded,
  DeleteRounded,
  RemoveCircleOutlineOutlined,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  BoxProps,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DateTimeField as MUIDateTimeField } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { User } from '../../../../types/User';
import { useOptionsAPI } from '../../../../utils/api/useOptions.hook';
import { GuidEmptySeq } from '../../../../utils/constants/GuidEmptySeq';
import { Option } from '../MortuaryModules/XrayRequestForm/xrayrequestform.constants';
import { useUnsavedChanges } from '../../../../Context/UnsavedChangesContext';

dayjs.extend(utc);
dayjs.extend(timezone);

interface CourtRecord {
  courtRecordsInfoSeq?: string;
  caseSeq?: string;
  subpeonaDate?: string;
  courtInfoDate?: string;
  depositionUser?: Option;
  depositionDate?: string;
  depositionPhone?: string;
  depositionExt?: string;
  testifyUser?: Option;
  testifyDate?: string;
  comments?: string;
  courtRecordsInfoOrder?: number;
  courtLocation?: string;
  judge?: string;
  assistantProsecutor?: string;
  isActive: boolean;
  workConferences: WorkConference[];
  testifyInfo: TestifyInfo[];
  pretrialConferences: PretrialConference[];
  participantList: ParticipantList[];
}

interface WorkConference {
  workConferenceSeq?: string;
  workConferenceUser?: Option;
  workConferenceDate?: string;
  workConferenceWith?: string;
  courtRecordsInfoSeq?: string;
  workConferenceOrder?: number;
  isActive: boolean;
}

interface TestifyInfo {
  courtRecordsInfoSeq?: string;
  testifyInfoSeq?: string;
  testifyUser?: Option;
  testifyInfoDate?: string;
  testifyInfoOrder?: number;
  isActive: boolean;
}

interface PretrialConference {
  pretrialConferenceSeq?: string;
  pretrialConferenceUser?: Option;
  pretrialConferenceDate?: string;
  pretrialCoveringPhysicianUser?: Option;
  courtRecordsInfoSeq?: string;
  pretrialConferenceOrder?: number;
  isActive: boolean;
}

export interface ParticipantList {
  participantListSeq?: string;
  role?: Option;
  participantName?: string;
  participantPhone?: string;
  participantNotes?: string;
  courtRecordsInfoSeq?: string;
  participantListOrder?: number;
  isActive: boolean;
}

export function CourtDocumentsView() {
  const formik = useFormikContext<any>();
  const { options: participantRoleOptions } = useOptionsAPI(`getcourtdocumentroleoptions`);
  const { options: depositionUserOptionsUnmapped } = useOptionsAPI<Partial<User>>(
    `user/getAllUsersInCurrentUserJdx?jdxSeq=${formik.values?.caseSummary?.jdxSeq}`
  );

  const { setUnsavedChanges } = useUnsavedChanges();

  const depositionUserOptions: Option[] = depositionUserOptionsUnmapped.map(u => ({
    optionName: `${u.userLastName}, ${u.userFirstName}`,
    optionSeq: u.userSeq,
    isActive: true,
  }));

  const courtRecords: CourtRecord[] = formik.values?.caseRecords?.courtRecords || [];
  const [selectedRecordIndex, setSelectedRecordIndex] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedRecordIndex(newValue);
  };

  const addNewCourtRecord = useCallback(() => {
    const newRecord: CourtRecord = {
      courtRecordsInfoSeq: GuidEmptySeq,
      caseSeq: formik.values?.caseSummary?.caseSeq,
      assistantProsecutor: '',
      comments: '',
      courtLocation: '',
      depositionDate: null,
      depositionPhone: '',
      depositionExt: '',
      depositionUser: null,
      judge: '',
      subpeonaDate: null,
      courtInfoDate: null,
      isActive: true,
      workConferences: [],
      testifyInfo: [],
      pretrialConferences: [],
      participantList: [],
    };

    const updatedRecordsOrderUnmapped = [...courtRecords, newRecord];
    const updatedRecords = updatedRecordsOrderUnmapped.map((r, index) => ({
      ...r,
      courtRecordsInfoOrder: index + 1,
    }));

    formik.setFieldValue('caseRecords.courtRecords', updatedRecords, false);
    setSelectedRecordIndex(updatedRecords.length - 1);
  }, [courtRecords, formik.values?.caseSummary?.caseSeq, courtRecords.length]);

  const deleteCourtRecord = useCallback(() => {
    const updatedRecords = courtRecords.map((record, index) =>
      index === selectedRecordIndex ? { ...record, isActive: false } : record
    );

    formik.setFieldValue('caseRecords.courtRecords', updatedRecords, false);

    setSelectedRecordIndex(0);
    setUnsavedChanges();
  }, [courtRecords, selectedRecordIndex]);

  const handleRecordChange = useCallback(
    (updatedRecord: CourtRecord, activeIndex: number) => {
      const fullIndex = courtRecords.findIndex(
        record => record.courtRecordsInfoSeq === updatedRecord.courtRecordsInfoSeq
      );

      setUnsavedChanges();

      if (fullIndex !== -1) {
        formik.setFieldValue(`caseRecords.courtRecords[${fullIndex}]`, updatedRecord);
      }
    },
    [formik, courtRecords]
  );

  const editing = formik.status?.editing || false;
  return (
    <>
      {/* <pre>
        {JSON.stringify(
          courtRecords.map(r => r.depositionUser),
          null,
          2
        )}
      </pre> */}

      {courtRecords.length > 0 && (
        <Stack spacing={1}>
<Tabs
  value={selectedRecordIndex}
  onChange={handleChange}
  variant='scrollable'
  scrollButtons='auto'
>
  {courtRecords.map((record, index) => (
    <Tab
      key={index}
      label={`Record ${index + 1}`} 
      aria-controls={`court-tabpanel-${index}`}
      sx={{ display: record.isActive ? undefined : 'none' }}
    />
  ))}
  {editing && (
    <Button
      variant='text'
      startIcon={<AddRounded />}
      onClick={addNewCourtRecord}
      sx={{ ml: 2 }}
    >
      Add Court Record
    </Button>
  )}
</Tabs>
          {editing && (
            <Box sx={{ display: 'flex', justifyContent: 'right', mb: 2 }}>
              <Button
                variant='text'
                color='error'
                startIcon={<DeleteRounded />}
                onClick={() => deleteCourtRecord()}
              >
                Delete Court Record
              </Button>
            </Box>
          )}
          {courtRecords.map((record, index) => (
            <TabPanel
              key={record.courtRecordsInfoSeq || index}
              value={selectedRecordIndex}
              index={index}
              sx={{ display: record.isActive ? undefined : 'none' }}
            >
              <CourtRecordPanel
                courtRecord={record}
                onRecordChange={updatedRecord => handleRecordChange(updatedRecord, index)}
                index={index}
                depositionUserOptions={depositionUserOptions}
                participantRoleOptions={participantRoleOptions}
              />
            </TabPanel>
          ))}
        </Stack>
      )}

      {editing && courtRecords.filter(r => r.isActive).length <= 0 && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Button variant='text' startIcon={<AddRounded />} onClick={addNewCourtRecord}>
              Add Court Record
            </Button>
          </Grid>
        </Grid>
      )}

      {!editing && courtRecords.filter(r => r.isActive).length <= 0 && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>No court records found.</Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}

interface CourtRecordPanelProps {
  courtRecord: CourtRecord;
  depositionUserOptions: Option[];
  participantRoleOptions: Option[];
  onRecordChange: (updatedRecord: CourtRecord) => void;
  index: number;
}

const CourtRecordPanel = React.memo(
  ({
    courtRecord,
    onRecordChange,
    index = 0,
    depositionUserOptions = [],
    participantRoleOptions = [],
  }: CourtRecordPanelProps) => {
    const handleChange = useCallback(
      (field: keyof CourtRecord) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedRecord = { ...courtRecord, [field]: event.target.value };
        onRecordChange(updatedRecord);
      },
      [courtRecord, onRecordChange]
    );

    const authorizedToEdit = true;
    const formik = useFormikContext<any>();
    const editing: boolean = formik.status?.editing || false;

    const [phoneInputs, setPhoneInputs] = useState({
      depositionPhone: '',
      participantPhones: {},
    });

    useEffect(() => {
      setPhoneInputs({
        depositionPhone: formatPhoneNumber(
          formik.values?.caseRecords?.courtRecords[index]?.depositionPhone || ''
        ),
        participantPhones: courtRecord.participantList.reduce((acc, p, i) => {
          acc[i] = formatPhoneNumber(p.participantPhone || '');
          return acc;
        }, {}),
      });
    }, [formik.values?.caseRecords?.courtRecords, courtRecord.participantList, index]);

    const handlePhoneChange = useCallback(
      (fieldName: string, participantIndex?: number) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;
          const formattedValue = formatPhoneNumber(value);

          if (participantIndex !== undefined) {
            setPhoneInputs(prev => ({
              ...prev,
              participantPhones: { ...prev.participantPhones, [participantIndex]: formattedValue },
            }));
          } else {
            setPhoneInputs(prev => ({ ...prev, [fieldName]: formattedValue }));
          }

          // Update Formik with raw value
          const rawValue = value.replace(/[^\d]/g, '');
          formik.setFieldValue(fieldName, rawValue, false);
        },
      [formik]
    );

    const { setValue: setCourtLocation } = formik.getFieldHelpers(
      `caseRecords.courtRecords[${index}].courtLocation`
    );

    const { setValue: setJudge } = formik.getFieldHelpers(
      `caseRecords.courtRecords[${index}].judge`
    );

    const addWorkConference = useCallback(() => {
      const newWorkConference: WorkConference = {
        workConferenceSeq: GuidEmptySeq,
        courtRecordsInfoSeq: courtRecord.courtRecordsInfoSeq,
        workConferenceOrder: formik.values?.caseRecords?.courtRecords[index].workConferences.filter(
          conf => conf.isActive
        ).length,
        workConferenceWith: '',
        isActive: true,
      };

      formik.setFieldValue(
        `caseRecords.courtRecords[${index}].workConferences`,
        [...formik.values?.caseRecords?.courtRecords[index].workConferences, newWorkConference],
        false
      );
    }, [formik, formik.values?.caseRecords?.courtRecords, index, courtRecord.courtRecordsInfoSeq]);

    const removeWorkConference = useCallback(
      (workConferenceIndex: number) => {
        const updatedWorkConferences = formik.values?.caseRecords?.courtRecords[
          index
        ].workConferences.map((conf, i) =>
          i === workConferenceIndex ? { ...conf, isActive: false } : conf
        );

        formik.setFieldValue(
          `caseRecords.courtRecords[${index}].workConferences`,
          updatedWorkConferences,
          false
        );
      },
      [formik, formik.values?.caseRecords?.courtRecords, index]
    );

    const addTestifyingUser = useCallback(() => {
      const newTestifyInfo: TestifyInfo = {
        courtRecordsInfoSeq: courtRecord.courtRecordsInfoSeq,
        testifyInfoSeq: GuidEmptySeq,
        testifyUser: null,
        testifyInfoDate: null,
        testifyInfoOrder: formik.values?.caseRecords?.courtRecords[index].testifyInfo.filter(
          info => info.isActive
        ).length,
        isActive: true,
      };

      formik.setFieldValue(
        `caseRecords.courtRecords[${index}].testifyInfo`,
        [...formik.values?.caseRecords?.courtRecords[index].testifyInfo, newTestifyInfo],
        false
      );
    }, [formik, formik.values?.caseRecords?.courtRecords, index, courtRecord.courtRecordsInfoSeq]);

    const removeTestifyingUser = useCallback(
      (testifyIndex: number) => {
        const updatedTestifyInfo = formik.values?.caseRecords?.courtRecords[index].testifyInfo.map(
          (testify, i) => (i === testifyIndex ? { ...testify, isActive: false } : testify)
        );

        formik.setFieldValue(
          `caseRecords.courtRecords[${index}].testifyInfo`,
          updatedTestifyInfo,
          false
        );
      },
      [formik, formik.values?.caseRecords?.courtRecords, index]
    );

    const addPretrialConference = useCallback(() => {
      const newPretrialConference: PretrialConference = {
        pretrialConferenceSeq: GuidEmptySeq,
        pretrialConferenceUser:
          depositionUserOptions.find(
            u =>
              u?.optionSeq?.toUpperCase() ===
              formik.values?.caseMortuary?.assignedUser?.optionSeq?.toUpperCase()
          ) || null,
        courtRecordsInfoSeq: courtRecord.courtRecordsInfoSeq,
        pretrialConferenceOrder: formik.values?.caseRecords?.courtRecords[
          index
        ].pretrialConferences.filter(conf => conf.isActive).length,
        isActive: true,
      };

      formik.setFieldValue(
        `caseRecords.courtRecords[${index}].pretrialConferences`,
        [
          ...formik.values?.caseRecords?.courtRecords[index].pretrialConferences,
          newPretrialConference,
        ],
        false
      );
    }, [formik, formik.values?.caseRecords?.courtRecords, index, courtRecord.courtRecordsInfoSeq]);

    const removePretrialConference = useCallback(
      (pretrialIndex: number) => {
        const updatedPretrialConferences = formik.values?.caseRecords?.courtRecords[
          index
        ].pretrialConferences.map((pretrial, i) =>
          i === pretrialIndex ? { ...pretrial, isActive: false } : pretrial
        );

        formik.setFieldValue(
          `caseRecords.courtRecords[${index}].pretrialConferences`,
          updatedPretrialConferences,
          false
        );
      },
      [formik, formik.values?.caseRecords?.courtRecords, index]
    );

    const addParticipant = useCallback(() => {
      const newParticipant: ParticipantList = {
        participantListSeq: GuidEmptySeq,
        participantName: '',
        participantPhone: '',
        participantNotes: '',
        courtRecordsInfoSeq: courtRecord.courtRecordsInfoSeq,
        participantListOrder: courtRecord.participantList.filter(p => p.isActive).length,
        isActive: true,
      };

      formik.setFieldValue(
        `caseRecords.courtRecords[${index}].participantList`,
        [...courtRecord.participantList, newParticipant],
        false
      );
    }, [formik, courtRecord, index]);

    const removeParticipant = useCallback(
      (participantIndex: number) => {
        const updatedParticipants = formik.values?.caseRecords?.courtRecords[
          index
        ].participantList.map((participant, i) =>
          i === participantIndex ? { ...participant, isActive: false } : participant
        );

        formik.setFieldValue(
          `caseRecords.courtRecords[${index}].participantList`,
          updatedParticipants,
          false
        );
      },
      [formik, formik.values?.caseRecords?.courtRecords, index]
    );

    const all_work_conferences: WorkConference[] =
      formik?.values?.caseRecords?.courtRecords[index]?.workConferences || [];
    const last_active_work_conference_index = useMemo(() => {
      let lastActiveIndex = 0;
      for (let i = 0; i < all_work_conferences.length; i++) {
        let work_conference = all_work_conferences[i];
        if (work_conference.isActive) {
          lastActiveIndex = i;
        }
      }

      return lastActiveIndex;
    }, [all_work_conferences]);

    const all_pretrial_conferences: PretrialConference[] =
      formik?.values?.caseRecords?.courtRecords[index]?.pretrialConferences || [];
    const last_active_pretrial_conference_index = useMemo(() => {
      let lastActiveIndex = 0;
      for (let i = 0; i < all_pretrial_conferences.length; i++) {
        let pretrial_conference = all_pretrial_conferences[i];
        if (pretrial_conference.isActive) {
          lastActiveIndex = i;
        }
      }

      return lastActiveIndex;
    }, [all_pretrial_conferences]);

    const all_participants: ParticipantList[] =
      formik?.values?.caseRecords?.courtRecords[index]?.participantList || [];
    const last_active_participant_index = useMemo(() => {
      let lastActiveIndex = 0;
      for (let i = 0; i < all_participants.length; i++) {
        let participant = all_participants[i];
        if (participant.isActive) {
          lastActiveIndex = i;
        }
      }

      return lastActiveIndex;
    }, [all_participants]);

    const all_testifying_staff: TestifyInfo[] =
      formik?.values?.caseRecords?.courtRecords[index]?.testifyInfo || [];
    const last_active_all_testifying_staff_index = useMemo(() => {
      let lastActiveIndex = 0;
      for (let i = 0; i < all_testifying_staff.length; i++) {
        let staff = all_testifying_staff[i];
        if (staff.isActive) {
          lastActiveIndex = i;
        }
      }

      return lastActiveIndex;
    }, [all_testifying_staff]);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <DateTimeField
            authorizedToEdit={authorizedToEdit}
            label='Subpoeana Date'
            fieldName={`caseRecords.courtRecords[${index}].subpeonaDate`}
            includeTime={false}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <DateTimeField
            authorizedToEdit={authorizedToEdit}
            label='Court Date'
            fieldName={`caseRecords.courtRecords[${index}].courtInfoDate`}
            includeTime={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label='Court Location'
            fullWidth
            value={courtRecord.courtLocation || ''}
            disabled={!editing}
            onChange={e => setCourtLocation(e.target.value, false)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label='Judge'
            fullWidth
            value={courtRecord.judge || ''}
            disabled={!editing}
            onChange={e => setJudge(e.target.value, false)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label='Assistant Prosecutor'
            fullWidth
            value={courtRecord.assistantProsecutor || ''}
            disabled={!editing}
            onChange={handleChange('assistantProsecutor')}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ borderColor: '#555', m: 0, mt: 0, pb: 0 }} />
          <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
            <strong>Work Conference(s)</strong>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {formik.values?.caseRecords?.courtRecords[index].workConferences.filter(
            conf => conf.isActive
          ).length > 0 && (
            <Grid container spacing={1}>
              <Grid item xs={3} md={3}>
                <Typography variant='subtitle2'>Conference User</Typography>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography variant='subtitle2'>Conference Date</Typography>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography variant='subtitle2'>Conference With</Typography>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          )}
          {formik.values?.caseRecords?.courtRecords[index].workConferences.map(
            (workConference, workConferenceIndex) => (
              <Grid
                container
                spacing={1}
                key={workConference.workConferenceSeq || workConferenceIndex}
                sx={{ mb: 1, display: workConference.isActive ? undefined : 'none' }}
              >
                <Grid item xs={3} md={3}>
                  <Autocomplete
                    options={depositionUserOptions}
                    getOptionKey={option => option?.optionSeq}
                    getOptionLabel={option => option?.optionName}
                    value={
                      formik.values?.caseRecords?.courtRecords[index]?.workConferences[
                        workConferenceIndex
                      ].workConferenceUser
                    }
                    disabled={!editing}
                    isOptionEqualToValue={(o, v) =>
                      o?.optionSeq?.toUpperCase() === v?.optionSeq?.toUpperCase()
                    }
                    onChange={(e, v) =>
                      formik.setFieldValue(
                        `caseRecords.courtRecords[${index}].workConferences[${workConferenceIndex}].workConferenceUser`,
                        v,
                        false
                      )
                    }
                    renderInput={params => (
                      <TextField {...params} placeholder='Select Conference User' />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DateTimeField
                    authorizedToEdit={authorizedToEdit}
                    label=''
                    fieldName={`caseRecords.courtRecords[${index}].workConferences[${workConferenceIndex}].workConferenceDate`}
                    includeTime={true}
                  />
                </Grid>

                <Grid item xs={3} md={3}>
                  <TextField
                    fullWidth
                    placeholder='Conference With'
                    value={workConference.workConferenceWith || ''}
                    disabled={!editing}
                    onChange={e =>
                      formik.setFieldValue(
                        `caseRecords.courtRecords[${index}].workConferences[${workConferenceIndex}].workConferenceWith`,
                        e.target.value,
                        false
                      )
                    }
                  />
                </Grid>

                <Grid item xs={3}>
                  <Stack direction='row'>
                    <Tooltip title='Remove work conference' arrow>
                      <IconButton
                        onClick={() => removeWorkConference(workConferenceIndex)}
                        disabled={!editing}
                      >
                        <RemoveCircleOutlineOutlined />
                      </IconButton>
                    </Tooltip>

                    {workConferenceIndex === last_active_work_conference_index && (
                      <Tooltip title='Add another work conference' arrow>
                        <IconButton onClick={addWorkConference} disabled={!editing}>
                          <AddCircleOutlineRounded />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            )
          )}
          {editing &&
            formik.values?.caseRecords?.courtRecords[index].workConferences.filter(
              conf => conf.isActive
            ).length === 0 && (
              <Grid item xs={12}>
                <Button variant='text' startIcon={<AddRounded />} onClick={addWorkConference}>
                  Add Work Conference Event
                </Button>
              </Grid>
            )}

          {courtRecord.workConferences.filter(conf => conf.isActive).length === 0 && !editing && (
            <Grid item xs={12}>
              <Typography color='text.secondary'>No work conference events found</Typography>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ borderColor: '#555', m: 0, mt: 0, pb: 0 }} />
          <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
            <strong>Pretrial Conference(s)</strong>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {formik.values?.caseRecords?.courtRecords[index].pretrialConferences.filter(
            pretrial => pretrial.isActive
          ).length > 0 && (
            <Grid container spacing={1}>
              <Grid item xs={3} md={3}>
                <Typography variant='subtitle2'>User</Typography>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography variant='subtitle2'>Date</Typography>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography variant='subtitle2'>Covering Physician</Typography>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          )}
          {formik.values?.caseRecords?.courtRecords[index]?.pretrialConferences
            .filter((p: PretrialConference) => p.isActive)
            .map((pretrial: PretrialConference, pretrialIndex: number) => (
              <Grid
                container
                spacing={1}
                key={pretrial.pretrialConferenceSeq || pretrialIndex}
                sx={{ mb: 1, display: pretrial.isActive ? undefined : 'none' }}
              >
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    options={depositionUserOptions}
                    getOptionKey={option => option?.optionSeq}
                    getOptionLabel={option =>
                      Boolean(option?.isActive)
                        ? option?.optionName
                        : `${option?.optionName} (Inactive)`
                    }
                    value={
                      formik.values?.caseRecords?.courtRecords[index]?.pretrialConferences[
                        pretrialIndex
                      ].pretrialConferenceUser
                    }
                    disabled={!editing}
                    isOptionEqualToValue={(o, v) =>
                      o?.optionSeq?.toUpperCase() === v?.optionSeq?.toUpperCase()
                    }
                    onChange={(e, v) =>
                      formik.setFieldValue(
                        `caseRecords.courtRecords[${index}].pretrialConferences[${pretrialIndex}].pretrialConferenceUser`,
                        v,
                        false
                      )
                    }
                    renderInput={params => (
                      <TextField {...params} placeholder='Select Conference User' />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DateTimeField
                    authorizedToEdit={authorizedToEdit}
                    label=''
                    fieldName={`caseRecords.courtRecords[${index}].pretrialConferences[${pretrialIndex}].pretrialConferenceDate`}
                    includeTime={true}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <Autocomplete
                    options={depositionUserOptions}
                    getOptionKey={option => option?.optionSeq}
                    getOptionLabel={option => option?.optionName}
                    value={
                      formik.values?.caseRecords?.courtRecords[index]?.pretrialConferences[
                        pretrialIndex
                      ].pretrialCoveringPhysicianUser
                    }
                    disabled={!editing}
                    isOptionEqualToValue={(o, v) =>
                      o?.optionSeq?.toUpperCase() === v?.optionSeq?.toUpperCase()
                    }
                    onChange={(e, v) =>
                      formik.setFieldValue(
                        `caseRecords.courtRecords[${index}].pretrialConferences[${pretrialIndex}].pretrialCoveringPhysicianUser`,
                        v,
                        false
                      )
                    }
                    renderInput={params => (
                      <TextField {...params} placeholder='Select Covering Physician' />
                    )}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Stack direction='row'>
                    <Tooltip title='Remove pretrial conference' arrow>
                      <IconButton
                        onClick={() => removePretrialConference(pretrialIndex)}
                        disabled={!editing}
                      >
                        <RemoveCircleOutlineOutlined />
                      </IconButton>
                    </Tooltip>

                    {pretrialIndex === last_active_pretrial_conference_index && (
                      <Tooltip title='Add another pretrial conference' arrow>
                        <IconButton onClick={addPretrialConference} disabled={!editing}>
                          <AddCircleOutlineRounded />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            ))}

          {editing &&
            formik.values?.caseRecords?.courtRecords[index].pretrialConferences.filter(
              pretrial => pretrial.isActive
            ).length === 0 && (
              <Grid item xs={12}>
                <Button variant='text' startIcon={<AddRounded />} onClick={addPretrialConference}>
                  Add Pretrial Conference Event
                </Button>
              </Grid>
            )}

          {courtRecord.pretrialConferences.filter(pretrial => pretrial.isActive).length === 0 &&
            !editing && (
              <Grid item xs={12}>
                <Typography color='text.secondary'>No pretrial conference events found</Typography>
              </Grid>
            )}
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ borderColor: '#555', m: 0, mt: 0, pb: 0 }} />
          <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
            <strong>Participant(s)</strong>
          </Typography>
        </Grid>

        {courtRecord.participantList.filter(p => p.isActive).length > 0 && (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <Typography variant='subtitle2'>Role</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant='subtitle2'>Participant Name</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant='subtitle2'>Phone</Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography variant='subtitle2'>Notes</Typography>
              </Grid>
              <Grid item xs={12} md={1}></Grid>
            </Grid>
          </Grid>
        )}

        {courtRecord.participantList.map((participant, participantIndex) => (
          <Grid
            container
            spacing={1}
            key={participant.participantListSeq || participantIndex}
            sx={{ mb: 1, pl: 2, pr: 2, display: participant.isActive ? undefined : 'none' }}
          >
            <Grid item xs={12} md={3}>
              <Autocomplete
                options={participantRoleOptions}
                getOptionLabel={option => option.optionName}
                value={participant?.role || null}
                isOptionEqualToValue={(o, v) =>
                  o?.optionSeq?.toUpperCase() === v?.optionSeq?.toUpperCase()
                }
                onChange={(_, newValue) =>
                  formik.setFieldValue(
                    `caseRecords.courtRecords[${index}].participantList[${participantIndex}].role`,
                    newValue,
                    false
                  )
                }
                disabled={!editing}
                renderInput={params => <TextField {...params} variant='outlined' size='small' />}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size='small'
                value={participant.participantName || ''}
                onChange={e =>
                  formik.setFieldValue(
                    `caseRecords.courtRecords[${index}].participantList[${participantIndex}].participantName`,
                    e.target.value,
                    false
                  )
                }
                disabled={!editing}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size='small'
                value={phoneInputs.participantPhones[participantIndex] || ''}
                onChange={handlePhoneChange(
                  `caseRecords.courtRecords[${index}].participantList[${participantIndex}].participantPhone`,
                  participantIndex
                )}
                disabled={!editing}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                size='small'
                value={participant.participantNotes || ''}
                onChange={e =>
                  formik.setFieldValue(
                    `caseRecords.courtRecords[${index}].participantList[${participantIndex}].participantNotes`,
                    e.target.value,
                    false
                  )
                }
                disabled={!editing}
              />
            </Grid>

            <Grid item xs={12} md={1}>
              <Stack direction='row'>
                <Tooltip title='Remove participant' arrow>
                  <IconButton
                    onClick={() => removeParticipant(participantIndex)}
                    disabled={!editing}
                  >
                    <RemoveCircleOutlineOutlined />
                  </IconButton>
                </Tooltip>

                {participantIndex === last_active_participant_index && (
                  <Tooltip title='Add another participant' arrow>
                    <IconButton onClick={addParticipant} disabled={!editing}>
                      <AddCircleOutlineRounded />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </Grid>
          </Grid>
        ))}

        {courtRecord.participantList.filter(pretrial => pretrial.isActive).length === 0 && (
          <Grid item xs={12}>
            {!editing && <Typography color='text.secondary'>No participants found</Typography>}
            {editing && (
              <Button
                variant='text'
                startIcon={<AddRounded />}
                onClick={addParticipant}
                disabled={!editing}
              >
                Add Participant
              </Button>
            )}
          </Grid>
        )}

        <Grid item xs={12} sx={{ mb: 1 }}>
          <Divider sx={{ borderColor: '#555', m: 0, mt: 0, pb: 0 }} />
          <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
            <strong>Deposition</strong>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {/* <Autocomplete
            options={depositionUserOptions}
            getOptionKey={option => option?.optionSeq}
            getOptionLabel={option =>
              Boolean(option?.isActive) ? option?.optionName : `${option?.optionName} (Inactive)`
            }
            value={courtRecord?.depositionUser}
            disabled={!editing}
            isOptionEqualToValue={(o, v) =>
              o?.optionSeq?.toUpperCase() === v?.optionSeq?.toUpperCase()
            }
            onChange={(e, v) =>
              formik.setFieldValue(`caseRecords.courtRecords[${index}].depositionUser`, v, false)
            }
            renderInput={params => <TextField {...params} label='Deposition Name' />}
          /> */}
          <Autocomplete
            options={depositionUserOptions}
            getOptionLabel={option => option.optionName}
            value={courtRecord?.depositionUser || null}
            isOptionEqualToValue={(o, v) =>
              o?.optionSeq?.toUpperCase() === v?.optionSeq?.toUpperCase()
            }
            onChange={(_, newValue) =>
              formik.setFieldValue(
                `caseRecords.courtRecords[${index}].depositionUser`,
                newValue,
                false
              )
            }
            disabled={!editing}
            renderInput={params => (
              <TextField {...params} variant='outlined' size='small' label='Deposition User' />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DateTimeField
            authorizedToEdit={authorizedToEdit}
            label='Deposition Date'
            fieldName={`caseRecords.courtRecords[${index}].depositionDate`}
            includeTime={false}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            label='Phone Number'
            fullWidth
            value={phoneInputs.depositionPhone}
            disabled={!editing}
            onChange={handlePhoneChange(`caseRecords.courtRecords[${index}].depositionPhone`)}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ borderColor: '#555', m: 0, mt: 0, pb: 0 }} />
          <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
            <strong>Testifying Staff</strong>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {formik.values?.caseRecords?.courtRecords[index].testifyInfo.map(
            (testify, testifyIndex) => (
              <Grid
                container
                spacing={1}
                key={testify.testifyInfoSeq || testifyIndex}
                sx={{ mb: 1, display: testify.isActive ? undefined : 'none' }}
              >
                <Grid item xs={5} md={4}>
                  <Autocomplete
                    options={depositionUserOptions}
                    getOptionKey={option => option?.optionSeq}
                    getOptionLabel={option =>
                      Boolean(option?.isActive)
                        ? option?.optionName
                        : `${option?.optionName} (Inactive)`
                    }
                    value={
                      formik.values?.caseRecords?.courtRecords[index]?.testifyInfo[testifyIndex]
                        .testifyUser
                    }
                    disabled={!editing}
                    isOptionEqualToValue={(o, v) =>
                      o?.optionSeq?.toUpperCase() === v?.optionSeq?.toUpperCase()
                    }
                    onChange={(e, v) =>
                      formik.setFieldValue(
                        `caseRecords.courtRecords[${index}].testifyInfo[${testifyIndex}].testifyUser`,
                        v,
                        false
                      )
                    }
                    renderInput={params => (
                      <TextField {...params} placeholder='Select Staff Member' />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <DateTimeField
                    authorizedToEdit={authorizedToEdit}
                    label=''
                    fieldName={`caseRecords.courtRecords[${index}].testifyInfo[${testifyIndex}].testifyInfoDate`}
                    includeTime={true}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Stack direction='row'>
                    <Tooltip title='Remove testifying user' arrow>
                      <IconButton
                        onClick={() => removeTestifyingUser(testifyIndex)}
                        disabled={!editing}
                      >
                        <RemoveCircleOutlineOutlined />
                      </IconButton>
                    </Tooltip>

                    {testifyIndex === last_active_all_testifying_staff_index && (
                      <Tooltip title='Add another testifying user' arrow>
                        <IconButton onClick={addTestifyingUser} disabled={!editing}>
                          <AddCircleOutlineRounded />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            )
          )}

          {editing && courtRecord.testifyInfo.filter(cr => cr.isActive).length === 0 && (
            <Grid item xs={12}>
              <Button
                variant='text'
                startIcon={<AddRounded />}
                onClick={addTestifyingUser}
                disabled={!editing}
              >
                Add Testifying User
              </Button>
            </Grid>
          )}

          {!editing && courtRecord.testifyInfo.filter(cr => cr.isActive).length === 0 && (
            <Grid item xs={12}>
              <Typography color='text.secondary'>No testifying users found</Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ borderColor: '#555', m: 0, mt: 0, pb: 0 }} />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label='Comments'
            multiline
            fullWidth
            value={formik.values?.caseRecords?.courtRecords[index]?.comments || ''}
            disabled={!editing}
            onChange={e =>
              formik.setFieldValue(
                `caseRecords.courtRecords[${index}].comments`,
                e.target.value,
                false
              )
            }
          />
        </Grid>
      </Grid>
    );
  }
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  sx?: BoxProps;
}

const TabPanel = React.memo(({ children, value, index, sx, ...other }: TabPanelProps) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`court-tab-${index}`}
      aria-labelledby={`court-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component={Paper} elevation={2} sx={{ mt: 1, p: 2, py: 3, ...sx }}>
          {children}
        </Box>
      )}
    </div>
  );
});

function DateTimeField({
  authorizedToEdit = false,
  fieldName = '',
  label = '',
  includeTime = true,
}) {
  const formik = useFormikContext();
  const { setValue } = formik.getFieldHelpers(fieldName);
  const { value, error } = formik.getFieldMeta(fieldName);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MUIDateTimeField
        disabled={!formik?.status?.editing || !authorizedToEdit}
        fullWidth
        id={fieldName}
        name={fieldName}
        label={label}
        variant='outlined'
        value={value ? dayjs(value as string) : null}
        onChange={date => {
          const localDate = date
            ? includeTime
              ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss')
              : dayjs(date)
            : null;
          setValue(localDate, false);
        }}
        helperText={error}
        InputLabelProps={{
          shrink: true,
        }}
        clearable
        format={includeTime ? 'L HH:mm' : 'L'}
      />
    </LocalizationProvider>
  );
}

export const usePhoneFormat = (initialValue = '') => {
  const formatPhoneNumber = value => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const [displayValue, setDisplayValue] = useState(formatPhoneNumber(initialValue));

  const handleChange = (event, setFieldValue, fieldName) => {
    const { value } = event.target;
    // Store the raw input in formik
    setFieldValue(fieldName, value.replace(/[^\d]/g, ''));
    // Update the display value with formatting
    setDisplayValue(formatPhoneNumber(value));
  };

  return [displayValue, handleChange, formatPhoneNumber];
};

const MemoizedTabPanel = React.memo(TabPanel);
const MemoizedCourtRecordPanel = React.memo(CourtRecordPanel);

const formatPhoneNumber = value => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  }
  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};
