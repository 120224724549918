import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_MARITAL_STATUS_OPTION,
  GET_STATE_OPTION,
  UPDATE_DEMOGRAPHICS,
} from './actionTypes';

import {
  getMaritalStatusOptionsFail,
  getMaritalStatusOptionsSuccess,
  getStateOptionsFail,
  getStateOptionsSuccess,
  updateDemographicsFail,
  updateDemographicsSuccess,
} from './actions';

import { getMaritalStatusOptionsApi } from '../../utils/api/demographics/getMaritalStatusOptionsApi';
import { getStateOptionsApi } from '../../utils/api/demographics/getStateOptionsApi';
import { updateDemographicsApi } from '../../utils/api/demographics/updateDemographicsApi';

function* onGetMaritalStatusOptions() {
  try {
    const response = yield call(getMaritalStatusOptionsApi);
    yield put(getMaritalStatusOptionsSuccess(response));
  } catch (error) {
    yield put(getMaritalStatusOptionsFail(error.response));
  }
}

function* onGetStateOptions() {
  try {
    const response = yield call(getStateOptionsApi);
    yield put(getStateOptionsSuccess(response));
  } catch (error) {
    yield put(getStateOptionsFail(error.response));
  }
}

function* onUpdateDemographics(action) {
  try {
    const response = yield call(updateDemographicsApi, action.payload);
    yield put(updateDemographicsSuccess(response));
  } catch (error) {
    yield put(updateDemographicsFail(error.response));
  }
}

function* DemographicsSaga() {
  yield takeLatest(GET_MARITAL_STATUS_OPTION, onGetMaritalStatusOptions);
  yield takeLatest(GET_STATE_OPTION, onGetStateOptions);
  yield takeLatest(UPDATE_DEMOGRAPHICS, onUpdateDemographics);
}

export default DemographicsSaga;
