import ChatIcon from '@mui/icons-material/Chat';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { useState } from 'react';
import { useNotifications } from '../../utils/components/AppBar/Notifications/NotificationsContext';
import { NotificationsMenuDrawer } from '../../utils/components/AppBar/Notifications/NotificationsMenuDrawer';
import { FeedbackDialog } from '../FeedbackDialog';

export const CommunicationsButtons = ({
  notificationMenuOpen = false,
  userMenuOpen = false,
  handleOpenNotificationMenu,
  handleCloseNotificationMenu,
}: {
  handleOpenNotificationMenu: any;
  handleCloseNotificationMenu: any;
  notificationMenuOpen: boolean;
  userMenuOpen: boolean;
}) => {
  const [messagesOpen, setMessagesOpen] = useState(false);
  const closeMessages = () => {
    setMessagesOpen(false);
  };

  const { notifications } = useNotifications();
  const unreadNotifications = notifications.filter(n => !n.notificationRead && n.isActive);
  const theme = useTheme();

  return (
    <Stack direction='row' spacing={1} sx={{ p: 0.5, display: 'flex' }}>
      <IconButton
        title='Messages'
        sx={{ display: { xs: 'none', sm: 'flex' } }}
        onClick={() => setMessagesOpen(true)}
      >
        <Tooltip title='Messages'>
          <Badge badgeContent={0} color='error'>
            <ChatIcon sx={{ color: theme.palette.text.primary }} />
          </Badge>
        </Tooltip>
      </IconButton>
      <IconButton title='Notifications' onClick={handleOpenNotificationMenu}>
        <Tooltip title='Notifications'>
          <Badge badgeContent={unreadNotifications.length} color='error'>
            <NotificationsIcon sx={{ color: theme.palette.text.primary }} />
          </Badge>
        </Tooltip>
      </IconButton>

      <NotificationsMenuDrawer
        handleCloseNotificationMenu={handleCloseNotificationMenu}
        notificationMenuOpen={notificationMenuOpen}
      />

      <FeedbackDialog open={messagesOpen} onClose={closeMessages} />
    </Stack>
  );
};
