import React, { createContext, useReducer } from 'react';
import reducer from './reducer';

const initialState = {
  profileInformation: [],
  signature: '',
  photo: '',
  notificationDetails: [],
  roleDetails: {},
  photoTypeObject: {},
  copyRoleObject: {},
  viewPhoto: '',
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const sendUserProfile = (profileInformation) => {
    dispatch({ type: 'USER_PROFILE_INFORMATION', payload: profileInformation });
  };

  const sendSignature = (signature) => {
    dispatch({ type: 'UPLOAD_SIGNATURE', payload: signature });
  };

  const sendPhoto = (photo) => {
    dispatch({ type: 'UPLOAD_PHOTO', payload: photo });
  };

  const sendPhotoToViewModal = (photo) => {
    dispatch({ type: 'UPLOAD_PHOTO_TO_VIEW', payload: photo });
  };

  const sendRoleDetails = (roleDetails) => {
    dispatch({ type: 'SEND_ROLES', payload: roleDetails });
  };

  const sendNotificationTemplate = (notification) => {
    dispatch({ type: 'NOTIFICATION_DETAILS', payload: notification });
  };

  const sendPhotoTypeObject = (photoTypeObject) => {
    dispatch({ type: 'PHOTO_TYPE_OBJECT', payload: photoTypeObject });
  };
  const sendDocumentTypeObject = (documentTypeObject) => {
    dispatch({ type: 'DOCUMENT_TYPE_OBJECT', payload: documentTypeObject });
  };
  const sendReportTypeObject = (reportTypeObject) => {
    dispatch({ type: 'REPORT_TYPE_OBJECT', payload: reportTypeObject });
  };
  const sendCopyRoleObject = (copyRoleObject) => {
    dispatch({ type: 'COPY_ROLE_OBJECT', payload: copyRoleObject });
  };

  return (
    <GlobalContext.Provider
      value={{
        sendUserProfile,
        profileInformation: state.profileInformation,
        sendSignature,
        signature: state.signature,
        sendPhoto,
        photo: state.photo,
        sendNotificationTemplate: sendNotificationTemplate,
        notificationDetails: state.notificationDetails,
        signature: state.signature,
        sendPhoto,
        photo: state.photo,
        sendRoleDetails,
        roleDetails: state.roleDetails,
        sendPhotoTypeObject,
        photoTypeObject: state.photoTypeObject,
        sendDocumentTypeObject,
        documentTypeObject: state.documentTypeObject,
        sendReportTypeObject,
        reportTypeObject: state.reportTypeObject,
        copyRoleObject: state.copyRoleObject,
        sendCopyRoleObject,
        sendPhotoToViewModal: sendPhotoToViewModal,
        viewPhoto: state.viewPhoto,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
