import React from 'react';
import { Skeleton, TableRow, TableCell, Stack, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const DailyCaseRowSkeleton = () => {
  return (
    <React.Fragment>
      <TableRow>
        <TableCell sx={{ pl: 3, pr: 1, py: 1, width: 0 }} align='left'>
          <Skeleton variant='text' width={100} />
        </TableCell>
        <TableCell sx={{ px: 1, py: 1, width: 0 }} align='left'>
          <Skeleton variant='text' width={150} />
        </TableCell>
        <TableCell sx={{ px: 1, py: 1, width: 0 }} align='left'>
          <Stack direction='row' spacing={1}>
            <Skeleton variant='rectangular' width={80} height={20} />
            <Skeleton variant='rectangular' width={80} height={20} />
          </Stack>
        </TableCell>
        <TableCell sx={{ pl: 5, pr: 0, py: 1 }} align='right'>
          <Skeleton variant='rectangular' width={200} height={56} />
        </TableCell>
        <TableCell sx={{ px: 1, py: 1 }} align='right'>
          <Skeleton variant='rectangular' width={200} height={56} />
        </TableCell>
        <TableCell sx={{ pl: 0, pr: 5, py: 1 }} align='right'>
          <Skeleton variant='rectangular' width={200} height={56} />
        </TableCell>
        <TableCell sx={{ px: 1, py: 1, width: 0 }} align='right'>
          <Skeleton variant='rectangular' width={60} height={30} />
        </TableCell>
        <TableCell sx={{ pl: 1, pr: 3, py: 1, width: 0 }} align='right'>
          <IconButton size='small'>
            <KeyboardArrowDownIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
