import { Check } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  ButtonBase,
  Collapse,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../../../utils/auth/AuthService';

export const DidntReceiveCodeHelp = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onSendAnother = async () => {
    if (auth.two_factor.selected_method === 'email') {
      auth.two_factor.sendEmail();
      setShowAlert(true);
      setDisableButton(true);
    } else if (auth.two_factor.selected_method === 'sms') {
      auth.two_factor.sendSMS();
      setShowAlert(true);
      setDisableButton(true);
    }
  };

  const onTrySomethingElse = () => {
    auth.authDispatch({ type: 'RESET_SELECTED_2FA_METHOD' });
    navigate('/login/2fa');
  };

  useEffect(() => {
    let buttonTimeoutId: NodeJS.Timeout;
    if (disableButton) {
      buttonTimeoutId = setTimeout(() => {
        setDisableButton(false);
      }, 300000); // re-enable button after 5 minutes
    }
    return () => clearTimeout(buttonTimeoutId);
  }, [disableButton]);

  return (
    <Box
      component='div'
      sx={{
        bgcolor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        component='div'
        sx={{
          bgcolor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box component='div' sx={{ textAlign: 'center', py: 1, px: 1 }}>
          <Divider flexItem>
            <ButtonBase onClick={handleToggle}>
              <Grid
                container
                alignItems='center'
                justifyContent='center'
                spacing={2}
              >
                <Grid item xs>
                  <Typography
                    color='text.secondary'
                    gutterBottom
                    variant='body2'
                    component='div'
                  >
                    Didn't receive a code?
                  </Typography>
                </Grid>
              </Grid>
            </ButtonBase>
          </Divider>

          {showAlert && (
            <>
              {auth.two_factor.selected_method === 'sms' && (
                <Alert icon={<Check fontSize='inherit' />} severity='success'>
                  A new code has been sent to
                  <b>{auth.user?.primaryMobileNo}</b>.
                </Alert>
              )}
              {auth.two_factor.selected_method === 'email' && (
                <Alert icon={<Check fontSize='inherit' />} severity='success'>
                  A new code has been sent to <b>{auth.user?.primaryEmail}</b>.
                </Alert>
              )}
            </>
          )}
        </Box>

        <Collapse in={isCollapsed}>
          <Box component='div'>
            <Typography color='text.secondary' variant='body2' sx={{ my: 1 }}>
              Codes can take a couple of minutes to be sent.
            </Typography>
            <Typography color='text.secondary' variant='body2'>
              If you haven't received one after 5 minutes, you can request
              another or try a different authentication method.
            </Typography>
          </Box>
          <Box component='div' sx={{ m: 2 }}>
            <Stack direction='row' spacing={1} justifyContent='center'>
              <Button
                variant='outlined'
                color='primary'
                sx={{ borderRadius: 16, textTransform: 'none' }}
                onClick={() => onSendAnother()}
                disabled={disableButton}
              >
                Send another
              </Button>
              <Button
                variant='outlined'
                color='primary'
                sx={{ borderRadius: 16, textTransform: 'none' }}
                onClick={() => onTrySomethingElse()}
              >
                Try something else
              </Button>
            </Stack>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};
