import React from 'react';
import { Field } from 'formik';
import { FormControl, TextField } from '@mui/material';
const InputComponent = ({ label, name, disabled, ...rest }) => {
  return (
    <>
      <Field id={name} name={name} {...rest}>
        {({ field, form }) => {
          return (
            <FormControl
              error
              required
              component='fieldset'
              fullWidth
              variant='standard'
            >
              <TextField
                error={!!form?.errors?.[name]}
                size='large'
                fullWidth
                label={label}
                variant='outlined'
                id={name}
                disabled={disabled}
                {...rest}
                {...field}
              />
              {!!form?.errors?.[name] ? (
                <p style={{ color: '#d32f2f' }}>{form?.errors?.[name]}</p>
              ) : null}
            </FormControl>
          );
        }}
      </Field>
    </>
  );
};

export default InputComponent;
