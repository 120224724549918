import { Box, ButtonBase, Chip } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import React from 'react';

const PreviewContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  gap: theme.spacing(1),
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
  // disable pointer events on children
  '& > *': {
    pointerEvents: 'none',
  },
}));

const PreviewTitle = styled(Chip)({
  textAlign: 'center',
  marginTop: 10,
});

export const PreviewSilo = ({
  title,
  onClick,
  children,
}: {
  title: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  children: React.ReactNode;
}) => {
  const theme = useTheme();

  return (
    <Box
      component='div'
      sx={{
        padding: theme.spacing(1),
        boxSizing: 'border-box',
        width: '100%',
        [theme.breakpoints.down('sm')]: {},
      }}
    >
      <ButtonBase
        onClick={onClick}
        sx={{ width: '100%', display: 'block', textAlign: 'inherit' }} // Ensure ButtonBase fills the container
      >
        <PreviewContainer>
          {children}
          <PreviewTitle variant='filled' size='medium' label={title} />
        </PreviewContainer>
      </ButtonBase>
    </Box>
  );
};
