import JSZip from 'jszip';

const exportToZip = (files, setOpenExportDialogBox) => {
    const zip = new JSZip();

    files.forEach((file, index) => {
        const fileName = file.fileName;
        const fileContent = atob(file.photo); // decode base64 string to binary data
        zip.file(fileName, fileContent, { binary: true });
    });

    zip.generateAsync({ type: 'blob' }).then((content) => {
        const url = URL.createObjectURL(content);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'files.zip';
        link.click();
        setOpenExportDialogBox(false);
    });
};

export default exportToZip;
