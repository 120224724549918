import { Check } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { ActionList } from '../../../../../components/CaseView/CaseViewDateTimeField';
import { User } from '../../../../../types/User';
import { useAuth } from '../../../../../utils/auth/AuthService';
import {
  BodyCategoryDto,
  CreateXrayRequestDto,
  DecedentType,
  ExtremityTypeDto,
  TypeOfCaseDto,
  addXrayRequest,
} from './xrayrequestform.constants';

const validationSchema = Yup.object().shape({
  requestedByUserSeq: Yup.string().required('Requested by is required'),
  requestedOn: Yup.date().required('Requested on is required'),
  // assignedMedicalExaminerUserSeq: Yup.string().required('Assigned Medical Examiner is required'),
});

export function CreateXrayRequestFormModal({
  caseId,
  open = false,
  loading = true,
  handleClose,
  extremities = [],
  caseTypes = [],
  decedentTypes = [],
  bodyCategories = [],
  users = [],
  medicalExaminerOptions = [],
}: {
  caseId: string;
  open: boolean;
  loading: boolean;
  handleClose: any;
  extremities: ExtremityTypeDto[];
  bodyCategories: BodyCategoryDto[];
  caseTypes: TypeOfCaseDto[];
  decedentTypes: DecedentType[];
  users: Partial<User>[];
  medicalExaminerOptions: Partial<Option>[];
}) {
  const auth = useAuth();
  const [initialDate] = useState(dayjs().local());
  const [saveAlertOpen, setSaveAlertOpen] = useState(false);

  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCode, setErrorCode] = useState(0);

  const formik = useFormik<CreateXrayRequestDto>({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: {
      caseId,
      assignedMedicalExaminerUserSeq: null,
      bodyCategories: [],
      caseTypes: caseTypes.map(type => ({
        ...type,
        isChecked: false,
      })),
      completedBy: null,
      completedOn: null,
      extremitiesRequested: extremities.map(e => ({
        ...e,
        isChecked: false,
        subExtremityTypes: e.subExtremityTypes.map(se => ({
          ...se,
          isChecked: false,
        })),
      })),
      requestedByUserSeq: auth.user?.userSeq ?? null,
      // @ts-ignore
      requestedOn: initialDate,
      specialProcedures: null,
      decedentTypeSeq: null,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const validatedCompletedOn = values.completedOn ? dayjs(values.completedOn) : null;
        const validatedRequestedOn = values.requestedOn ? dayjs(values.requestedOn) : null;

        // Format dates without timezone
        const completedOnFormatted =
          validatedCompletedOn && validatedCompletedOn.isValid()
            ? validatedCompletedOn.format('YYYY-MM-DDTHH:mm:ss')
            : null;
        const requestedOnFormatted =
          validatedRequestedOn && validatedRequestedOn.isValid()
            ? validatedRequestedOn.format('YYYY-MM-DDTHH:mm:ss') // Changed from toISOString()
            : null;

        const response = await addXrayRequest(auth.user?.accessToken!, {
          caseId,
          assignedMedicalExaminerUserSeq: formik.values.assignedMedicalExaminerUserSeq,
          bodyCategories: formik.values.bodyCategories,
          caseTypes: formik.values.caseTypes,
          completedBy: values.completedBy,
          extremitiesRequested: formik.values.extremitiesRequested.map(e => ({
            ...e,
            subExtremities: e.subExtremityTypes
              .filter(st => st.isChecked)
              .map(st => ({
                ...st,
              })),
          })),
          requestedByUserSeq: formik.values.requestedByUserSeq,
          completedOn: completedOnFormatted,
          requestedOn: requestedOnFormatted, // Use formatted date
          specialProcedures: formik.values.specialProcedures,
          decedentTypeSeq: formik.values.decedentTypeSeq ?? null,
        });

        if (response.ok) {
          setSaveAlertOpen(true);
          internalHandleClose();
        } else {
          setErrorMessage(await response.json());
          setErrorCode(response.status);
          setErrorAlertOpen(true);
        }
      } catch (error) {
        setErrorMessage(`${error}`);
        setErrorCode(500); // You might want to set a default error code
        setErrorAlertOpen(true);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const internalHandleClose = () => {
    handleClose(formik.dirty);
    formik.resetForm({
      values: {
        caseId,
        assignedMedicalExaminerUserSeq: null,
        bodyCategories: [],
        caseTypes: caseTypes.map(type => ({
          ...type,
          isChecked: false,
        })),
        completedBy: null,
        completedOn: null,
        extremitiesRequested: extremities.map(e => ({
          ...e,
          isChecked: false,
          subExtremityTypes: e.subExtremityTypes.map(se => ({
            ...se,
            isChecked: false,
          })),
        })),
        requestedByUserSeq: auth.user?.userSeq ?? null,
        // @ts-ignore
        requestedOn: dayjs().local(), // Reset to local date
        specialProcedures: null,
        decedentTypeSeq: null,
      },
    });
  };

  const handleBodyCategoryChange = (categorySeq: string, checked: boolean) => {
    const categoryExists = formik.values.bodyCategories.some(
      c => c.bodyCategorySeq === categorySeq
    );
    if (!categoryExists) {
      const baseCategory = bodyCategories.find(c => c.bodyCategorySeq === categorySeq) ?? null;
      if (!baseCategory) {
        return;
      }

      // Add it to the bodyCategories
      const newCategory: BodyCategoryDto = {
        bodyCategoryName: baseCategory.bodyCategoryName,
        bodyCategoryOnRequestSeq: null,
        bodyCategorySeq: baseCategory.bodyCategorySeq,
        isChecked: true,
        subCategories: [],
      };

      const newCategories = [...formik.values.bodyCategories, newCategory];
      formik.setFieldValue('bodyCategories', newCategories);

      return;
    } else {
      const categoryToUpdate = formik.values.bodyCategories.find(
        c => c.bodyCategorySeq === categorySeq
      )!;
      const clonedCategories = [...formik.values.bodyCategories];
      const categoryToUpdateIndex = clonedCategories.findIndex(
        c => c.bodyCategorySeq === categorySeq
      );

      if (categoryToUpdateIndex < 0) return;

      const updatedCategory: BodyCategoryDto = {
        ...categoryToUpdate,
        isChecked: checked,
      };

      clonedCategories[categoryToUpdateIndex] = updatedCategory;
      formik.setFieldValue('bodyCategories', clonedCategories);
    }
  };

  const handleSubCategoryChange = (
    parentCategory: BodyCategoryDto,
    subCategorySeq: string,
    checked: boolean
  ) => {
    const newCategories = formik.values.bodyCategories.map(category => {
      if (category.bodyCategorySeq === parentCategory.bodyCategorySeq) {
        const newSubCategories = checked
          ? [
              ...(category.subCategories || []),
              parentCategory.subCategories?.find(sc => sc.bodySubCategorySeq === subCategorySeq)!,
            ]
          : category.subCategories?.filter(sc => sc.bodySubCategorySeq !== subCategorySeq) || [];

        return {
          ...category,
          subCategories: newSubCategories,
        };
      }
      return category;
    });

    formik.setFieldValue('bodyCategories', newCategories);
  };

  const handleCloseSuccessAlert = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSaveAlertOpen(false);
  };

  const handleCloseErrorAlert = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorAlertOpen(false);
  };

  const upperExtremities = formik.values.extremitiesRequested.filter(e => e.isUpper);
  const lowerExtremities = formik.values.extremitiesRequested.filter(e => e.isLower);

  return (
    <Dialog open={open} onClose={internalHandleClose} fullWidth maxWidth='lg'>
      <DialogTitle>
        <Typography variant='h5' fontWeight='bold'>
          Add new X-Ray Request
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={saveAlertOpen}
          autoHideDuration={6000}
          onClose={handleCloseSuccessAlert}
        >
          <Alert icon={<Check fontSize='inherit' />} severity='success'>
            X-Ray request successfully saved
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={errorAlertOpen}
          autoHideDuration={6000}
          onClose={handleCloseErrorAlert}
        >
          <Alert severity='error'>
            <AlertTitle>Error {errorCode}</AlertTitle>
            {errorMessage}
          </Alert>
        </Snackbar>
        <form onSubmit={formik.handleSubmit}>
          <Stack mt={1}>
            <Stack
              direction={{
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
              }}
              spacing={1}
              pb={1}
            >
              <Autocomplete
                disabled
                readOnly
                disablePortal
                autoHighlight
                value={formik.values.requestedByUserSeq}
                onChange={(event, newValue) => {
                  formik.setFieldValue('requestedByUserSeq', newValue);
                }}
                inputMode='text'
                getOptionLabel={o => {
                  const user = users.find(u => u.userSeq === o) ?? null;
                  if (!user) return '';
                  return `${user.userLastName}, ${user.userFirstName}`;
                }}
                options={users.map(user => user.userSeq).filter(seq => seq !== undefined)}
                isOptionEqualToValue={(o, v) => o?.toLowerCase() === v?.toLowerCase()}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Requested by'
                    error={Boolean(
                      formik.touched.requestedByUserSeq && formik.errors.requestedByUserSeq
                    )}
                    helperText={
                      formik.touched.requestedByUserSeq && formik.errors.requestedByUserSeq
                    }
                  />
                )}
                sx={{ flex: 1 }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl
                  sx={{ flex: 1 }}
                  error={Boolean(formik.touched.requestedOn && formik.errors.requestedOn)}
                >
                  <DateTimePicker
                    label='Requested on'
                    disabled={formik.isSubmitting || loading}
                    value={formik.values.requestedOn ? dayjs(formik.values.requestedOn) : null}
                    onChange={(date: dayjs.Dayjs | null): void => {
                      formik.setFieldValue('requestedOn', date ? dayjs(date).local() : null);
                    }}
                    format='MM/DD/YYYY, HH:mm' // Ensure consistent format
                    timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                    slots={{
                      actionBar: ActionList,
                    }}
                    ampm={false}
                    sx={{ width: '100%' }}
                  />
                  {formik.touched.requestedOn && formik.errors.requestedOn && (
                    <FormHelperText>{formik.errors.requestedOn}</FormHelperText>
                  )}
                </FormControl>
              </LocalizationProvider>

              <Autocomplete
                disabled={formik.isSubmitting || loading}
                disablePortal
                inputMode='text'
                getOptionLabel={o => String(o?.optionName)}
                value={
                  medicalExaminerOptions.find(
                    u => u?.optionSeq === formik.values.assignedMedicalExaminerUserSeq
                  ) ?? null
                }
                isOptionEqualToValue={(o, v) =>
                  o.optionSeq.toLowerCase() === v.optionSeq.toLowerCase()
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue('assignedMedicalExaminerUserSeq', newValue?.optionSeq);
                }}
                options={medicalExaminerOptions}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Assigned Medical Examiner'
                    error={Boolean(
                      formik.touched.assignedMedicalExaminerUserSeq &&
                        formik.errors.assignedMedicalExaminerUserSeq
                    )}
                    helperText={
                      formik.touched.assignedMedicalExaminerUserSeq &&
                      formik.errors.assignedMedicalExaminerUserSeq
                    }
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.optionSeq}>
                    {`${option.optionName}`}
                  </li>
                )}
                sx={{ flex: 1 }}
              />
              <Autocomplete
                autoHighlight
                disablePortal
                disabled={formik.isSubmitting || loading}
                multiple={true}
                limitTags={1}
                inputMode='text'
                getOptionLabel={o => o.typeOfCaseName}
                isOptionEqualToValue={(o, v) =>
                  o.typeOfCaseSeq.toLowerCase() === v.typeOfCaseSeq.toLowerCase()
                }
                value={formik.values.caseTypes.filter(t => t.isChecked)}
                onChange={(event, newValue) => {
                  const checkedCaseTypes = formik.values.caseTypes.map(t => {
                    if (newValue.some(caseType => caseType.typeOfCaseSeq === t.typeOfCaseSeq)) {
                      return {
                        ...t,
                        isChecked: true,
                      };
                    }
                    return {
                      ...t,
                      isChecked: false,
                    };
                  });
                  formik.setFieldValue('caseTypes', checkedCaseTypes);
                }}
                options={caseTypes}
                sx={{ flex: 2 }}
                renderInput={params => (
                  <TextField {...params} label='Case Type' helperText={'Select all that apply'} />
                )}
              />
              <Autocomplete
                autoHighlight
                disablePortal
                disabled={formik.isSubmitting || loading}
                options={decedentTypes}
                getOptionLabel={(option: DecedentType) => option.decedentTypeName}
                isOptionEqualToValue={(o, v) =>
                  o.decedentTypeSeq.toLowerCase() === v.decedentTypeSeq.toLowerCase()
                }
                value={
                  decedentTypes.find(dt => dt.decedentTypeSeq === formik.values.decedentTypeSeq) ||
                  null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue('decedentTypeSeq', newValue?.decedentTypeSeq || null);
                }}
                renderInput={params => <TextField {...params} label='Decedent Type' />}
                sx={{ flex: 1 }}
              />
            </Stack>
            <Typography variant='h6' component='h6'>
              Areas
            </Typography>

            <Stack
              direction={{
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
              }}
            >
              {bodyCategories.map(category => {
                const parentCategory =
                  formik.values.bodyCategories.find(
                    c => c.bodyCategorySeq === category.bodyCategorySeq
                  ) ?? null;

                const isChecked = parentCategory?.isChecked ?? false;
                const subCategories = category.subCategories!;

                return (
                  <Stack key={category.bodyCategorySeq} spacing={1} mr={2}>
                    <FormControlLabel
                      label={category.bodyCategoryName}
                      disabled={formik.isSubmitting || loading}
                      control={
                        <Checkbox
                          size='small'
                          checked={isChecked}
                          onChange={(event, checked) =>
                            handleBodyCategoryChange(category.bodyCategorySeq!, checked)
                          }
                        />
                      }
                    />
                    <Collapse in={isChecked}>
                      {subCategories.map(subCategory => {
                        const isSubCategoryChecked =
                          parentCategory?.subCategories?.some(
                            sc => sc.bodySubCategorySeq === subCategory.bodySubCategorySeq
                          ) ?? false;

                        return (
                          <FormControlLabel
                            key={subCategory.bodySubCategorySeq}
                            label={subCategory.bodySubCategoryName}
                            disabled={formik.isSubmitting || loading}
                            control={
                              <Checkbox
                                sx={{ ml: 3 }}
                                size='small'
                                checked={isSubCategoryChecked}
                                onChange={(event, checked) =>
                                  handleSubCategoryChange(
                                    category,
                                    subCategory.bodySubCategorySeq!,
                                    checked
                                  )
                                }
                              />
                            }
                          />
                        );
                      })}
                    </Collapse>
                  </Stack>
                );
              })}
            </Stack>
            <Stack direction='row' justifyContent='flex-start' spacing={2} mt={2}>
              <Stack>
                <Typography variant='h6' component='h6'>
                  Upper Extremities
                </Typography>
                {upperExtremities.map(e => (
                  <React.Fragment key={e.extremitySeq}>
                    <FormControlLabel
                      label={e.extremityName}
                      control={<Checkbox checked={e.isChecked ?? false} />}
                      disabled={formik.isSubmitting || loading}
                      onChange={(event, checked) => {
                        const newExtremities = formik.values.extremitiesRequested.map(extr => {
                          if (extr.extremitySeq === e.extremitySeq) {
                            return {
                              ...extr,
                              isChecked: checked,
                              subExtremityTypes: checked
                                ? extr.subExtremityTypes
                                : extr.subExtremityTypes.map(subExtr => ({
                                    ...subExtr,
                                    isChecked: false,
                                  })),
                            };
                          }

                          return extr;
                        });

                        formik.setFieldValue('extremitiesRequested', newExtremities);
                      }}
                    />

                    <Collapse in={e.isChecked}>
                      {e.subExtremityTypes.map(se => (
                        <FormControlLabel
                          key={se.subExtremitySeq}
                          sx={{ ml: 2 }}
                          label={se.subExtremityName}
                          control={<Checkbox checked={se.isChecked ?? false} />}
                          disabled={formik.isSubmitting || loading}
                          onChange={(event, checked) => {
                            const newExtremities = formik.values.extremitiesRequested.map(extr => {
                              if (extr.extremitySeq === e.extremitySeq) {
                                return {
                                  ...extr,
                                  subExtremityTypes: extr.subExtremityTypes.map(subExtr => {
                                    if (subExtr.subExtremitySeq === se.subExtremitySeq) {
                                      return {
                                        ...subExtr,
                                        isChecked: checked,
                                      };
                                    }

                                    return subExtr;
                                  }),
                                };
                              }

                              return extr;
                            });

                            formik.setFieldValue('extremitiesRequested', newExtremities);
                          }}
                        />
                      ))}
                    </Collapse>
                  </React.Fragment>
                ))}
              </Stack>
              <Stack>
                <Typography variant='h6' component='h6'>
                  Lower Extremities
                </Typography>
                {lowerExtremities.map(e => (
                  <React.Fragment key={e.extremitySeq}>
                    <FormControlLabel
                      label={e.extremityName}
                      control={<Checkbox checked={e.isChecked ?? false} />}
                      disabled={formik.isSubmitting || loading}
                      onChange={(event, checked) => {
                        const newExtremities = formik.values.extremitiesRequested.map(extr => {
                          if (extr.extremitySeq === e.extremitySeq) {
                            return {
                              ...extr,
                              isChecked: checked,
                              subExtremityTypes: checked
                                ? extr.subExtremityTypes
                                : extr.subExtremityTypes.map(subExtr => ({
                                    ...subExtr,
                                    isChecked: false,
                                  })),
                            };
                          }

                          return extr;
                        });

                        formik.setFieldValue('extremitiesRequested', newExtremities);
                      }}
                    />

                    <Collapse in={e.isChecked}>
                      {e.subExtremityTypes.map(se => (
                        <FormControlLabel
                          key={se.subExtremitySeq}
                          sx={{ ml: 2 }}
                          label={se.subExtremityName}
                          control={<Checkbox checked={se.isChecked ?? false} />}
                          disabled={formik.isSubmitting || loading}
                          onChange={(event, checked) => {
                            const newExtremities = formik.values.extremitiesRequested.map(extr => {
                              if (extr.extremitySeq === e.extremitySeq) {
                                return {
                                  ...extr,
                                  subExtremityTypes: extr.subExtremityTypes.map(subExtr => {
                                    if (subExtr.subExtremitySeq === se.subExtremitySeq) {
                                      return {
                                        ...subExtr,
                                        isChecked: checked,
                                      };
                                    }

                                    return subExtr;
                                  }),
                                };
                              }

                              return extr;
                            });

                            formik.setFieldValue('extremitiesRequested', newExtremities);
                          }}
                        />
                      ))}
                    </Collapse>
                  </React.Fragment>
                ))}
              </Stack>
            </Stack>
          </Stack>

          <TextField
            disabled={formik.isSubmitting || loading}
            sx={{ my: 2 }}
            fullWidth
            placeholder='Special procedure notes'
            name='specialProcedures'
            value={formik.values.specialProcedures}
            onChange={formik.handleChange}
          />
          <Stack direction='row' spacing={1} mt={0}>
            <Autocomplete
              disabled={formik.isSubmitting || loading}
              autoHighlight
              // @ts-ignore
              value={users.find(u => u.userSeq === formik.values.completedBy?.userSeq) ?? null}
              onChange={(event, newValue) => {
                const u: Partial<CreateXrayRequestDto['completedBy']> = {
                  // @ts-ignore
                  personFirstName: newValue?.userFirstName,
                  personLastName: newValue?.userLastName,
                  userSeq: newValue?.userSeq,
                };
                formik.setFieldValue('completedBy', u);
              }}
              inputMode='text'
              getOptionLabel={option => `${option.userLastName}, ${option.userFirstName}`}
              options={users}
              isOptionEqualToValue={(option, value) => option.userSeq === value.userSeq}
              sx={{ flex: 1 }}
              renderInput={params => (
                <TextField {...params} label='Completed by' required={false} />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.userSeq}>
                  {`${option.userLastName}, ${option.userFirstName}`}
                </li>
              )}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label='Completed on'
                disabled={formik.isSubmitting || loading}
                value={formik.values.completedOn ? dayjs(formik.values.completedOn) : null}
                onChange={(date: dayjs.Dayjs | null): void => {
                  formik.setFieldValue('completedOn', date ? dayjs(date).local() : null);
                }}
                format='MM/DD/YYYY, HH:mm' // Ensure consistent format
                timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                slots={{
                  actionBar: ActionList,
                }}
                ampm={false}
                sx={{ flex: 1, width: '100%' }}
              />
            </LocalizationProvider>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          fullWidth
          color='error'
          disabled={formik.isSubmitting || loading}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={e => formik.handleSubmit()}
          fullWidth
          disabled={formik.isSubmitting || loading}
        >
          Add X-Ray Request
        </Button>
      </DialogActions>
    </Dialog>
  );
}
