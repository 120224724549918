import { Button } from '@mui/material';

import { GridColDef } from '@mui/x-data-grid-premium';

import { useEffect, useState } from 'react';
import SiloDataGrid from '../../utils/components/SiloDataGrid';
import { useDashboard } from '../../views/Dashboard/DashboardContext';
import { DecedentNameCell } from './DecedentNameCell';
import { DashboardDataGridProps } from './useDashboardDataGridAPI.hook';

export function AwaitingIDConfirmationDataGrid({ data, isLoading }: DashboardDataGridProps) {
  const { preferences, reloadSiloData } = useDashboard();
  const [reloading, setReloading] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        setReloading(true);
        await reloadSiloData('AWAITING_ID_CONFIRMATION');
      } finally {
        setReloading(false);
      }
    }, preferences.refreshSeconds * 1000);

    return () => clearInterval(intervalId);
  }, [reloadSiloData]);

  const columns: GridColDef[] = [
    {
      field: 'caseid',
      headerName: 'CaseID',
      width: 120,
      editable: false,
      align: 'left',
      headerAlign: 'left',

      renderCell: params => {
        return (
          <Button
            color='primary'
            onClick={() => {
              // handleCaseClick(event, params);
            }}
          >
            <b> {params.formattedValue} </b>
          </Button>
        );
      },
    },
    {
      field: 'decedentname',
      headerName: 'Decedent',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: params => <DecedentNameCell {...params} />,
    },

    {
      field: 'investigator',
      headerName: 'MLDI',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  return (
    <SiloDataGrid
      loading={isLoading}
      listresults={data}
      columnsInput={columns}
      idcolumn='caseid'
      gridheight={200}
      gridcolor='#800080'
      title='Awaiting ID Confirmation'
      tooltip='This grid represents cases that are waiting for the ME to Confirm the ID made by the Investigator'
      casecount
      rowsperpage={50}
      filterModel={undefined}
      columnsVisibility={undefined}
      reloading={reloading}
      refreshSeconds={preferences.refreshSeconds}
    />
  );
}
