import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { useUnsavedChanges } from '../../Context/UnsavedChangesContext';

function getValueByNestedKey(object, key) {
  const keys = key.split('.');
  return keys.reduce((obj, k) => obj?.[k], object);
}

export default function CaseViewAutocomplete({
  xs,
  sm,
  md,
  lg,
  xl,
  options,
  authorizedToEdit = true,
  fieldName,
  label,
  multiple = false,
}) {
  const formik = useFormikContext();
  const fieldValue = formik?.values && getValueByNestedKey(formik?.values, fieldName);
  const fieldTouched = formik?.touched && getValueByNestedKey(formik?.touched, fieldName);
  const fieldErrors = formik?.errors && getValueByNestedKey(formik?.errors, fieldName);
  const { setUnsavedChanges } = useUnsavedChanges();

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <Box
        sx={{
          position: 'relative',
          display: 'inline-block',
          width: '100%',
        }}
      >
        <Autocomplete
          multiple={multiple}
          disabled={!formik.status.editing || !authorizedToEdit}
          fullWidth
          id={fieldName}
          name={fieldName}
          options={options}
          value={multiple ? fieldValue ?? [] : fieldValue}
          onChange={(e, value) => {
            formik.setFieldValue(fieldName, value);
            setUnsavedChanges();
          }}
          isOptionEqualToValue={(option, value) =>
            option?.optionSeq?.toUpperCase() === value?.optionSeq?.toUpperCase()
          }
          getOptionLabel={option => option?.optionName}
          renderInput={params => <TextField {...params} label={label} />}
          error={fieldTouched && Boolean(fieldErrors)}
          helperText={fieldTouched ? fieldErrors?.toString() : ''}
        />
      </Box>
    </Grid>
  );
}
