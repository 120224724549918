import React, { useContext } from 'react';
import { Modal, Grid, Button, Switch, Typography, Box } from '@mui/material';
import { useState } from 'react';
import { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { GlobalContext } from '../../Context/GlobalContext';

const Signature = ({ open, closeSignature }) => {
  const signCanvas = useRef();
  const [isUpload, setIsUpload] = useState(false);
  const [uploadImg, setImg] = useState({});
  const [signature, setSignature] = useState('');
  // const [alert, setAlert] = useState(false);
  const { sendSignature } = useContext(GlobalContext);

  const handleSubmit = () => {
    let signatureData;
    if (isUpload) {
      signatureData = signature[0];
      setSignature(signatureData);
      // setAlert(true);
      closeSignature();
      sendSignature(signatureData);
    } else {
      const canvas = signCanvas.current.getTrimmedCanvas();
      canvas.toBlob(blob => {
        const file = new File([blob], 'signature.png', { type: 'image/png' });
        signatureData = file;
        setSignature(signatureData);
        sendSignature(signatureData);
        // setAlert(true);
        closeSignature();
      });
    }
  };

  const checkFileSize = file => {
    if (file[0]?.size <= 100000) {
      setImg(file);
      setSignature(file);
    } else {
      alert('Upload a file of 100kb size');
    }
  };
  return (
    <Modal sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} open={open}>
      <Grid sx={{ backgroundColor: '#FFF', borderRadius: '4px', padding: 2 }} container xs={10}>
        <Grid item xs={10}>
          <Typography variant='h5' gutterBottom>
            E-Signature
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
          item
          xs={2}
        >
          <Switch onChange={() => setIsUpload(!isUpload)} /> Upload File
        </Grid>
        <Grid sx={{ marginBottom: 2 }} item xs={12}>
          {!isUpload && (
            <SignatureCanvas
              canvasProps={{
                style: { background: '#ddd', width: '100%', height: 250 },
              }}
              penColor='black'
              ref={signCanvas}
            />
          )}
          {isUpload && (
            <>
              <Button component='label' variant='contained'>
                Browse
                <input
                  type='file'
                  accept='image/*'
                  hidden
                  onChange={e => {
                    checkFileSize(e.target.files);
                  }}
                />
              </Button>{' '}
              <br />
              {Object.keys(uploadImg)?.length > 0 && (
                <img src={URL.createObjectURL(uploadImg?.[0])} />
              )}
              {uploadImg?.path && (
                <Typography sx={{ display: 'inline', marginLeft: 2 }} gutterBottom>
                  {uploadImg.path}
                </Typography>
              )}
              {uploadImg?.preview && (
                <Box
                  component='img'
                  src={uploadImg.preview}
                  sx={{ width: '100%', height: 300, marginTop: 2 }}
                />
              )}
            </>
          )}
        </Grid>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {!isUpload && (
              <Grid item>
                <Button
                  onClick={() => signCanvas.current.clear()}
                  variant='contained'
                  sx={{ backgroundColor: '#2196f3', height: '40px' }}
                >
                  Clear
                </Button>
              </Grid>
            )}
            <Grid item>
              {!isUpload && (
                <Button
                  variant='contained'
                  onClick={handleSubmit}
                  sx={{ backgroundColor: '#2196f3', height: '40px' }}
                >
                  Submit
                </Button>
              )}
            </Grid>
            <Grid sx={{ marginTop: '16px' }}>
              {isUpload && (
                <Button
                  variant='contained'
                  onClick={handleSubmit}
                  sx={{
                    backgroundColor: '#2196f3',
                    width: '200px',
                    height: '40px',
                  }}
                >
                  Sign Upload
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                onClick={closeSignature}
                sx={{ backgroundColor: '#2196f3', height: '40px' }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default Signature;
