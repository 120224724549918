import { Stack } from '@mui/material';
import React from 'react';
import { useAuth } from '../../../../utils/auth/AuthService';
import { Appearance } from './Appearance';
import { Notifications } from './Notifications';

const { REACT_APP_API_URL } = process.env;

export default function YourPreferences(props) {
  const { user, userState, userDispatch } = useAuth();

  const handleDarkModeToggle = async () => {
    const isDarkMode = !userState.user.isDarkMode;

    userDispatch({
      type: 'SET_DARK_MODE',
      payload: isDarkMode,
    });

    try {
      if (!user) return;

      const endpoint = `${REACT_APP_API_URL}user/update`;

      const body = JSON.stringify({
        access_token: user.token,
        setting: 'theme display mode',
        new_value: `${isDarkMode}`,
      });

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body,
      });

      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error('There was an error toggling theme display mode:', error);
      // Handle or display the error message as required
    }
  };

  return (
    <Stack spacing={4}>
      <Appearance />
      <Notifications />
    </Stack>

    // <Card
    //   raised
    //   sx={{
    //     my: 0,
    //     mx: 'auto',
    //     width: { xs: '95%', lg: '100%' },
    //   }}
    // >
    //   <CardHeader title='Preferences' />
    //   <Divider variant='middle' sx={{ opacity: 1 }} />
    //   <CardContent>
    //     <FormControlLabel
    //       control={
    //         <Switch
    //           checked={userState.user?.isDarkMode || false}
    //           onChange={handleDarkModeToggle}
    //         />
    //       }
    //       label='Dark Mode'
    //     />
    //   </CardContent>
    // </Card>
  );
}
