import React, { useEffect } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import { Tab } from '@mui/material';
import { Container } from '@mui/system';
import { useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Address from './Address';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import authHeader from '../../../services/auth-header';

const { REACT_APP_API_URL } = process.env;

const id = '00000000-0000-0000-0000-000000000000';

const AddressData = ({
  addresses = [],
  handleChange,
  nursingHomeSeq = '',
  funeralHomeSeq = '',
  lawEnforcementSeq = '',
  contactInfoSeq = '',
  isAddressOn = true,
  isErrormsg = true,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(1);
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));

  const removeAddressTab = (addressIndex) => {
    const tempPanels = addresses?.filter((tab, index) => index != addressIndex);

    handleChange(tempPanels);
    setSelectedTab(1);
  };
  const handleAddressSelectionChange = (e, index) => {
    const tempPanels = JSON.parse(JSON.stringify(addresses));
    tempPanels[index][e.target.name] = e.target.value;
    if (
      e.target.name == 'addressTypeSeq' &&
      (e.target.value == 'f8362705-4d9d-4fc9-afe9-486051fbc3cc' ||
        e.target.value == 'eea37e74-0a50-419e-9de9-372d21d1f890')
    ) {
      tempPanels[index] = {
        ...tempPanels[index],
        addressTypeSeq: e.target.value ?? id,
        addressLine1: e.target.value == 'f8362705-4d9d-4fc9-afe9-486051fbc3cc' ? 'Homeless' : 'Unknown',
        city: '',
        contactInfoSeq: id,
        placeSeq: id,
        stateSeq: id,
        countrySeq: '39d0e397-af0f-4572-b605-7b2312fff516',
        stateSeq: 'cfcd3025-4434-4c07-8e6a-951b4d0c7490',
        countySeq: id,
        floor: '',
        otherState: '',
        zip: '',
        addressLine2: '',
        apartmentNo: '',
        crossStreet: '',
        otherAddressType: '',
      };
    }

    handleChange(tempPanels);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const createNewTab = () => {
    let makeTab = true;
    addresses?.forEach((panel) => {
      if (
        addressTypeNames[
          panel?.addressTypeSeq
        ]?.addressTypeName.toLowerCase() == 'unknown' ||
        addressTypeNames[
          panel?.addressTypeSeq
        ]?.addressTypeName.toLowerCase() == 'homeless'
      ) {
      } else if (
        // !panel?.addressTypeSeq || //Address type is not mandatory, it causing issues to add new tab when it is null.
        !panel?.addressLine1 ||
        !panel?.city
      ) {
        makeTab = false;
      }
    });
    if (!makeTab) {
      return;
    }

    handleChange([
      ...addresses,
      {
        addressSeq: id,
        addressTypeSeq: id,
        addressLine1: '',
        city: '',
        placeSeq: id,
        countrySeq: '39d0e397-af0f-4572-b605-7b2312fff516',
        stateSeq: 'cfcd3025-4434-4c07-8e6a-951b4d0c7490',
        countySeq: id,
        nursingHomeSeq: nursingHomeSeq || id,
        funeralHomeSeq: funeralHomeSeq || id,
        lawEnforcementSeq: lawEnforcementSeq || id,
        isWithinServiceArea: true,
        creationDateTime: new Date().toISOString(),
        status: 'Active',
        geoX: null,
        geoY: null,
        isCopyAddress: false,
        floor: '',
        isPrimary: false,
        otherState: '',
        zip: '',
        addressLine2: '',
        apartmentNo: '',
        crossStreet: '',
        otherAddressType: '',
        contactInfoSeq: contactInfoSeq || id,
        isActive: true
      },
    ]);
    setSelectedTab(addresses.length + 1);
  };

  const [countyNames, setCountyNames] = useState({});
  const [countryNames, setCountryNames] = useState([]);
  const [stateNames, setStateNames] = useState([]);
  const [addressTypeNames, setAddressTypeNames] = useState({});

  const getCountyNamesAddressTypes = () => {
    const tempCountyNames = {};
    const tempAddressTypeNames = {};
    axios
      .get(REACT_APP_API_URL + 'GetAddressType', {
        headers: authHeader(),
      })
      .then((response) => {
        response.data.data.forEach((addressType) => {
          if (
            addressType.addressTypeName != 'Medical Facility' &&
            addressType.addressTypeName != 'Nursing Home' &&
            addressType.addressTypeName != 'Funeral Home' &&
            addressType.addressTypeName != 'Law Enforcement'
          )
            tempAddressTypeNames[addressType.addressTypeSeq] = addressType;
        });
      });
    axios
      .get(REACT_APP_API_URL + 'GetAllCounty', {
        headers: authHeader(),
      })
      .then((response) => {
        response.data.data.forEach((county) => {
          tempCountyNames[county.countySeq] = {
            countySeq: county.countySeq,
            countyName: county.countyName,
            code: county.code,
            order: county.order,
          };
          if (county.hasOwnProperty('children')) {
            county.children.forEach((county) => {
              tempCountyNames[county.countySeq] = {
                countySeq: county.countySeq,
                countyName: county.countyName,
                code: county.code,
                order: county.order,
              };
            });
          }
        });
      });
    axios
      .get(REACT_APP_API_URL + 'GetMasterData?tableName=country', {
        headers: authHeader(),
      })
      .then((response) => {
        setCountryNames(response?.data?.data || []);
      });
    axios
      .get(REACT_APP_API_URL + 'GetMasterData?tableName=state', {
        headers: authHeader(),
      })
      .then((response) => {
        setStateNames(response?.data?.data || []);
      });
    setCountyNames(tempCountyNames);
    setAddressTypeNames(tempAddressTypeNames);
  };

  useEffect(() => {
    getCountyNamesAddressTypes();
  }, []);

  useEffect(() => { }, [addresses]);
  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: 'red' },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderRadius: '10px',
          },
        },
      },
    },
  });

  return (
    // <ThemeProvider theme={theme}> //Remarked dark mode have issue with it.
      <Container maxWidth={false} style={{ padding: 0 }}>
        {isAddressOn && (
          <Button
            onClick={createNewTab}
            startIcon={<AddCircleIcon />}
            variant='contained'
            sx={{ marginTop: '30px' }}
          >
            Add Address
          </Button>
        )}
        <TabContext value={selectedTab}>
          {isAddressOn && (
            <TabList onChange={handleTabChange} sx={{ minWidth: '30px' }}>
              {addresses?.map((tab, index) => (
                <Tab
                  label={index + 1}
                  value={index + 1}
                  key={index + 1}
                  sx={{ minWidth: '30px' }}
                />
              ))}
            </TabList>
          )}

          {addresses?.map((panel, index) => (
            <TabPanel
              label={index + 1}
              value={index + 1}
              key={panel.value}
              sx={{ paddingLeft: '0px', paddingBottom: '0px', paddingRight: '0px' }}
            >
              <Address
                removeAddressTab={removeAddressTab}
                handleAddressSelectionChange={handleAddressSelectionChange}
                panel={panel}
                index={index}
                panels={addresses}
                countyNames={countyNames}
                countryNames={countryNames}
                stateNames={stateNames}
                addressTypeNames={addressTypeNames}
                isErrormsg = {isErrormsg}
              />
            </TabPanel>
          ))}
        </TabContext>
      </Container>
    // </ThemeProvider>
  );
};

export default AddressData;
