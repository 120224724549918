import InfoIcon from '@mui/icons-material/Info';
import { Autocomplete, Checkbox, Paper, TextField } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { Alert, Button, Card, CardBody, CardHeader, Col, Collapse, NavLink, Row } from 'reactstrap';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import { BreadcrumbReportNav } from '../../../utils/components/BreadcrumbReportNav';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function DrugToxAuditView() {
  const { user } = useAuth();
  const [loading, setloading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const [numCases, setNumCases] = useState('');
  const [year, setYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [currentUser, setcurrentUser] = useState(false);

  const [requiredAlertVisible, setRequiredAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [selectedJdxs, setSelectedJdxs] = useState([]);

  const navigate = useNavigate();
  const { REACT_APP_API_URL } = process.env;

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const columns = [
    {
      field: 'caseid',
      headerName: 'Case Number',
      headerClassName: 'header-theme',
      flex: 1.5,
      editable: false,
      renderCell: params => {
        return (
          <NavLink
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
            }}
          >
            {params.formattedValue}
          </NavLink>
        );
      },
    },
    {
      field: 'lastname',
      headerName: 'Decedent Last Name',
      headerClassName: 'header-theme',
      flex: 1.25,
      editable: false,
    },
    {
      field: 'status',
      headerName: 'Case Status',
      headerClassName: 'header-theme',
      flex: 1.5,
      editable: false,
    },
    {
      field: 'meaction',
      headerName: 'ME Action',
      headerClassName: 'header-theme',
      flex: 1.5,
      editable: false,
    },
    {
      field: 'investigator',
      headerName: 'Investigator',
      headerClassName: 'header-theme',
      flex: 1.5,
      editable: false,
    },
    {
      field: 'examtype',
      headerName: 'Exam Type',
      headerClassName: 'header-theme',
      flex: 1.5,
      editable: false,
    },
    {
      field: 'casesignedandfileddate',
      headerName: 'Signed/Filed Date',
      headerClassName: 'header-theme',
      flex: 1.75,
      editable: false,
    },
    {
      field: 'drugtoxicity',
      headerName: 'DrugTox Suspected',
      headerClassName: 'header-theme',
      flex: 2,
      editable: false,
    },
    {
      field: 'drugtoxicityconfirmed',
      headerName: 'DrugTox Confirmed',
      headerClassName: 'header-theme',
      flex: 2,
      editable: false,
    },
    {
      field: 'causeofdeath',
      headerName: 'Cause of Death',
      headerClassName: 'header-theme',
      flex: 1.5,
      editable: false,
    },
    {
      field: 'mannerofdeath',
      headerName: 'Manner of Death',
      headerClassName: 'header-theme',
      flex: 2,
      editable: false,
    },
    {
      field: 'toxnotes',
      headerName: 'Tox Notes',
      headerClassName: 'header-theme',
      flex: 1,
      editable: false,
    },
  ];

  const handleJdxChange = (event, data) => {
    setSelectedJdxs(data);
  };

  const handleYearChange = (event, data) => {
    setYear(data);
  };

  const setResultsOverview = data => {
    var numCases = 0;

    for (const x of data) {
      numCases++;
    }

    setNumCases(numCases);
  };

  const dismissDateAlert = () => {
    setRequiredAlertVisible(false);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  const clearAll = () => {
    setYear(null);
    setSelectedJdxs([]);
    setResultsVisible(false);
  };

  //gather search params from form and FETCH/POST to API
  const handleSubmit = async event => {
    event.preventDefault();
    if (user === null) return;

    if (selectedJdxs.length == 0) {
      setJdxAlertVisible(true);
      return;
    }

    let formData = new FormData();

    setloading(true);
    setResultsVisible(true);

    formData.append('CASEYEAR', year);
    formData.append('SELECTEDJDXLIST', JSON.stringify(selectedJdxs));

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'drugtoxaudit', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setlistresults(data);
          setResultsOverview(data);
          setloading(false);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    setloading(false);
  };

  useEffect(() => {
    if (user === null) return;

    const fetchYear = async () => {
      try {
        const currentYear = new Date().getFullYear();
        setYear(currentYear);
        setYearOptions([...Array(16).keys()].map(i => i + (currentYear - 15)).reverse());
      } catch (e) {
        alert(e);
      }
    };

    fetchYear();
    setAuthorizedJdxs(user.jdxAccessList);
    setSelectedJdxs(user.jdxAccessList);
  }, [user]);

  return (
    <div className='me-response-time' style={{ padding: 20 }}>
      <BreadcrumbReportNav currentPage='Drug Tox Audit' />
      <Card style={{ marginBottom: '1rem' }} className='card-main'>
        <CardHeader className='header-txt'>
          Drug Tox Audit
          <Tooltip
            style={{ float: 'right' }}
            title="The Drug Tox Audit Report shows the selected Jurisdictions' list of cases that currently have Null values for DrugToxicity or DrugToxicityConfirmed. The purpose is to know which cases require additional action to help validate statistical queries surrounding Tox Data. "
            placement='right-end'
          >
            <InfoIcon />
          </Tooltip>
        </CardHeader>

        <Alert color='info' isOpen={requiredAlertVisible} toggle={dismissDateAlert}>
          Date range must be within 1 year or year dropdown must be set.
        </Alert>

        <Alert color='info' isOpen={jdxAlertVisible} toggle={dismissJdxAlert}>
          Please select at Least 1 Jurisdiction.
        </Alert>

        <div>
          <CardBody>
            <Row>
              <Col className='md=12'>
                <Autocomplete
                  multiple
                  id='selectedJdxs'
                  options={authorizedJdxs}
                  value={selectedJdxs}
                  disableCloseOnSelect
                  limitTags={4}
                  size='small'
                  getOptionLabel={option => option.jdxName}
                  onChange={(event, data) => handleJdxChange(event, data)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<MdCheckBoxOutlineBlank />}
                        checkedIcon={<MdOutlineCheckBox />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.jdxName}
                    </li>
                  )}
                  style={{ width: '100%', marginTop: 15 }}
                  renderInput={params => (
                    <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
                  )}
                  ListboxComponent={'ul'}
                />
              </Col>
            </Row>
            <Row>
              <Col className='me-col' md='12'>
                <Autocomplete
                  id='year'
                  options={yearOptions}
                  value={year}
                  size='small'
                  getOptionLabel={option => option.toString()}
                  onChange={(event, data) => handleYearChange(event, data)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<MdCheckBoxOutlineBlank />}
                        checkedIcon={<MdOutlineCheckBox />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.toString()}
                    </li>
                  )}
                  style={{ width: '100%' }}
                  renderInput={params => <TextField {...params} label='Year' placeholder='Year' />}
                  ListboxComponent={'ul'}
                />
              </Col>
            </Row>
          </CardBody>
          <br />

          <Button
            type='submit'
            color='primary'
            style={{ width: 120, marginLeft: 18, float: 'right' }}
            onClick={handleSubmit}
          >
            Calculate
          </Button>

          <Button
            color='secondary'
            style={{ width: 120, marginLeft: 18, float: 'right' }}
            onClick={clearAll}
          >
            Clear
          </Button>
          <hr />
          <br />

          <Collapse isOpen={resultsVisible}>
            <ReportDataGrid
              title='Audit Results'
              loading={loading}
              listresults={listresults}
              columnsInput={columns}
              idcolumn='caseid'
              casecount
              chatcontent={
                <Paper
                  elevation={3}
                  sx={{
                    p: '10px',
                    px: '20px',
                    borderRadius: '20px',
                    width: 'max-content',
                  }}
                >
                  <div style={{ textAlign: 'center' }}>
                    I've searched through and found <b>{numCases} Cases</b> that have Null values in
                    the output. These need to be addressed before official statistics can be
                    published. I've listed the detailed case records below, you can export the list
                    to csv format.
                  </div>
                </Paper>
              }
            />
          </Collapse>
        </div>
      </Card>
    </div>
  );
}
