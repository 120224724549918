import React, { useEffect, useState } from 'react';
import ControlComponents from '../../views/SysAdmin/SysAdminModules/UserAdminModules/ControlComponents';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  fetchCounty,
  getNameAndSequenceForCounty,
} from '../../services/utility-api';

const County = ({
  label,
  countyPermissions,
  countyPermissionsArray,
  dashboardPermissions,
  options,
  ...rest
}) => {
  const [countyDropDown, setCountyDropDown] = useState([]);
  const [dashboardDropDown, setDashboardDropDown] = useState([]);

  useEffect(() => {
    if (countyPermissionsArray?.length > 0) {
      setDashboardDropDown(getNameAndSequenceForCounty(countyPermissionsArray));
    }
  }, [countyPermissionsArray]);

  useEffect(() => {
    fetchCounty().then((response) => setCountyDropDown(response));
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        // border: '1px solid grey',
        // borderRadius: '12px',
        padding: '8px 0px 12px',
      }}
    >
      <h5 style={{ marginTop: '8px' }}>Jurisdiction</h5>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12} md={12} sm={12}>
          <ControlComponents
            control='multiSelect'
            name={countyPermissions}
            label='Select Jurisdiction Permissions*'
            options={countyDropDown}
            type='select'
            module='County'
          />
        </Grid>
        <Grid item lg={6} xs={12} md={12} sm={12}>
          <ControlComponents
            control='multiSelect'
            name={dashboardPermissions}
            label='Dashboard'
            options={dashboardDropDown}
            type='select'
            module='County'
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default County;
