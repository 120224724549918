const { REACT_APP_API_URL } = process.env;

export interface ModelRecordWithFiles {
  modelSeq: string;
  modelName: string;
  modelDescription: string;
  createdBy: string;
  createdOn: Date;
  isActive: boolean;
  thumbnailUrl: string;
  modelFiles: ModelFileRecordWithUrl[];
}

interface ModelFileRecordWithUrl extends ModelFileRecord {
  url: string;
}

export interface ModelFileRecord {
  modelFileSeq: string;
  modelSeq: string;
  modelFileMimeType: string;
  modelFileDetailLevel: string;
  modelFileOriginalFileName: string;
  createdBy: string;
  createdOn: Date;
  isActive: boolean;
}

interface GetModelsRequest {
  accessToken: string;
  includeThumbnail?: boolean;
  modelDetailLevel?: 'low' | 'medium' | 'high';
  mimeTypes?: ('model/gltf+json' | 'model/gltf-binary' | 'model/vnd.usdz+zip')[];
}

async function getModels({
  accessToken,
  includeThumbnail = true,
  modelDetailLevel = 'high',
  mimeTypes,
}: GetModelsRequest): Promise<ModelRecordWithFiles[]> {
  const url = `${REACT_APP_API_URL}models`;

  const requestBody: Partial<GetModelsRequest> = {
    includeThumbnail,
    modelDetailLevel,
  };

  if (mimeTypes !== undefined) {
    requestBody.mimeTypes = mimeTypes;
  }
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error(`API call failed: ${response.status}`);
  }

  const jsonResponse = await response.json();
  return jsonResponse as ModelRecordWithFiles[];
}

interface ModelUploadRequestModelFile {
  file: File;
  modelFileDetailLevel?: 'low' | 'medium' | 'high';
}

interface ModelUploadRequest {
  accessToken: string;
  modelName: string;
  modelDescription?: string;
  files: ModelUploadRequestModelFile[];
}

async function uploadModel({
  accessToken,
  files,
  modelName,
  modelDescription,
}: ModelUploadRequest) {
  const formData = new FormData();
  formData.append('ModelName', modelName);

  if (modelDescription !== undefined) {
    formData.append('ModelDescription', modelDescription);
  }

  files.forEach((modelFile, index) => {
    formData.append(`Files[${index}].File`, modelFile.file, modelFile.file.name);
    if (modelFile.modelFileDetailLevel) {
      formData.append(`Files[${index}].ModelFileDetailLevel`, modelFile.modelFileDetailLevel);
    }
  });

  console.log(files);
  console.log(formData);

  const url = `${REACT_APP_API_URL}models/upload`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: formData,
  });

  return response;
}

export interface ModelRecord {
  modelSeq: string;
  modelName: string;
  modelDescription?: string;
  createdBy: string;
  createdOn: Date;
  isActive: boolean;
}

async function updateModelRecord({
  accessToken,
  modelRecord,
}: {
  accessToken: string;
  modelRecord: Partial<ModelRecord>;
}) {
  const url = `${REACT_APP_API_URL}models`;

  const response = await fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ modelRecord }),
  });

  if (!response.ok) {
    throw new Error(`API call failed: ${response.status}`);
  }
  return response;
}

async function deleteModelRecord({
  accessToken,
  modelSeq,
}: {
  accessToken: string;
  modelSeq: string;
}) {
  const url = `${REACT_APP_API_URL}models/${modelSeq}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error(`API call failed: ${response.status}`);
  }

  return response;
}

export const ModelViewerAPI = {
  getModels,
  uploadModel,
  updateModelRecord,
  deleteModelRecord,
};
