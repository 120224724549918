import { Box, Drawer } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeItem, treeItemClasses, TreeItemProps } from '@mui/x-tree-view/TreeItem';
import React, { useState, useEffect } from 'react';
import dimensions from '../../utils/dimensions';
import { useLocation } from 'react-router-dom';

// Define the CustomTreeItem with correct types
const CustomTreeItem = styled((props: TreeItemProps) => <TreeItem {...props} />)(({ theme }) => ({
  color: theme.palette.grey[200],
  [`& .${treeItemClasses.content}`]: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
    [`& .${treeItemClasses.label}`]: {
      fontSize: '0.8rem',
      fontWeight: 500,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 10,
    paddingLeft: 13,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  ...theme.applyStyles('light', {
    color: theme.palette.grey[800],
  }),
}));

export default function TreeDrawer({
  drawerContents,
  setCurrentTab,
  currentTab,
  menuOpen,
  setMenuOpen,
  setTabContent = string => {},
}) {
  const theme = useTheme();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(currentTab);

  const handleTabChange = (event, id) => {
    let newTab = '';

    if ((drawerContents?.findIndex(item => item?.id === id) ?? 0) !== -1) {
      if (drawerContents?.find(item => item?.id === id)?.children === undefined) {
        newTab = id;
      } else {
        newTab = drawerContents?.find(item => item?.id === id)?.children?.[0]?.id;
      }
    } else {
      newTab = id;
      setMenuOpen(false);
    }

    if (newTab === 'User Admin') {
      setTabContent('Users');
    } else if (newTab === 'Roles Admin') {
      setTabContent('Roles');
    } else {
      setTabContent(newTab);
    }

    setCurrentTab(newTab);
    setSelectedItem(newTab);
    localStorage.setItem('currentSysAdminTab', '');
  };

  useEffect(() => {
    if (localStorage?.currentSysAdminTab) {
      setTabContent(localStorage.currentSysAdminTab);
      setCurrentTab(localStorage.currentSysAdminTab);
      setSelectedItem(localStorage.currentSysAdminTab);    
    }
  }, [localStorage?.currentSysAdminTab]);

  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: theme.palette.background.default,
        borderRight: '1px solid rgba(120, 120, 120, 0.2)',
        pr: 1,
      }}
    >
      <Box
        sx={{
          width: 'max-content',
          height: '100%',
          display: { xs: 'none', md: 'block' },
        }}
      >
        <RichTreeView
          sx={{ mt: 2 }}
          items={drawerContents}
          onItemClick={handleTabChange}
          selectedItems={selectedItem}
          slots={{
            item: (props) => {
              const { itemId} = props; // Destructure props
        
              // Find the current item from the drawerContents based on itemId
              const currentItem = drawerContents.find(item => item.id === itemId);
        
              return (
                <CustomTreeItem
                  {...props}
                  label={
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      {currentItem?.icon && <span style={{ marginRight: 8 }}>{currentItem.icon}</span>} {/* Render icon here */}
                      {props.label} {/* Render label text */}
                    </span>
                  }
                >
                  {currentItem?.children?.map((child) => (
            <CustomTreeItem
              key={child.id}
              itemId={child.id}
              label={
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {child.icon && (
                    <span style={{ marginRight: 8 }}>{child.icon}</span>
                  )}
                  {child.label}
                </span>
              }
            />
          ))}
                </CustomTreeItem>
              );
            },
          }}
        />
      </Box>

      <Drawer
        variant='temporary'
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 'max-content',
            position: 'relative',
            top: dimensions.app_bar_height,
            height: `calc(100% - ${dimensions.app_bar_height})`,
            borderRadius: 1.5,
            backgroundColor: theme.palette.background.default,
          },
          zIndex: theme.zIndex.drawer + 500,
        }}
      >
        <RichTreeView
          sx={{ mt: 2 }}
          items={drawerContents}
          onItemClick={handleTabChange}
          selectedItems={selectedItem}
          slots={{
            item: (props) => {
              const { itemId} = props; // Destructure props
        
              // Find the current item from the drawerContents based on itemId
              const currentItem = drawerContents.find(item => item.id === itemId);
        
              return (
                <CustomTreeItem
                  {...props}
                  label={
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      {currentItem?.icon && <span style={{ marginRight: 8 }}>{currentItem.icon}</span>} {/* Render icon here */}
                      {props.label} {/* Render label text */}
                    </span>
                  }
                >
                  {currentItem?.children?.map((child) => (
            <CustomTreeItem
              key={child.id}
              itemId={child.id}
              label={
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {child.icon && (
                    <span style={{ marginRight: 8 }}>{child.icon}</span>
                  )}
                  {child.label}
                </span>
              }
            />
          ))}
                </CustomTreeItem>
              );
            },
          }}
        />
      </Drawer>
    </Box>
  );
}
