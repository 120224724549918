import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
  FormHelperText,
  TextField,
  ClickAwayListener,
  Popper,
} from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/system';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import * as Yup from 'yup';
import { Formik, ErrorMessage, Form } from 'formik';

import FormControl from '@mui/material/FormControl';

import { saveAdminData } from '../../../../services/utility-api';
import ApiNotification from '../../../../components/DialogBox';

const PopperStyledComponent = styled(Popper)(({ theme }) => ({
  border: `1px solid ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
}));
const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const AddEdit = ({
  editObj,
  county,
  getAllData,
  setIsAddEditOn,
  allRow,
  liveryServicesPermissions,
}) => {
  const [dropdownopen, setDropdownopen] = useState(false);
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const [lCounty, setLCounty] = useState();

  useEffect(() => {
    setLCounty(editObj.liveryOnCounty);
  }, [editObj]);

  const handleClickAway = (e) => {
    setDropdownopen(false);
  };

  const initialValues =
    editObj?.livery && Object.keys(editObj?.livery)
      ? {
          ...editObj.livery[0],
          countys: editObj.liveryOnCounty,
        }
      : { countys: [], liveryOnCounty: [] };
  const validationSchema = Yup.object({
    liveryName: Yup.string().required('Livery name is required').nullable(),
    liveryOnCounty: Yup.array().min(1, 'Jurisdiction is required').nullable(),
  });

  const [countyNameMapping, setCountyNameMapping] = useState({});

  useEffect(() => {
    const tempCountyNameMapping = {};
    Object.keys(county).map((countySeq) => {
      tempCountyNameMapping[county[countySeq]['countyName']] =
        county[countySeq];
    });

    setCountyNameMapping(tempCountyNameMapping);
  }, [county]);

  const onSave = async (values) => {
    const id = '00000000-0000-0000-0000-000000000000';

    const newLiveryCounty = [];

    editObj?.liveryOnCounty?.map((coun) => {
      let foundMatchingCounty = false;

      values?.liveryOnCounty?.map((val) => {
        if (coun.countySeq === countyNameMapping[val]['countySeq']) {
          foundMatchingCounty = true;
        }
      });
      if (foundMatchingCounty) {
        newLiveryCounty.push({
          liverySeq: values?.liverySeq,
          countySeq: coun.countySeq,
          status: 'Active',
        });
      } else {
        newLiveryCounty.push({
          liverySeq: values?.liverySeq,
          countySeq: coun.countySeq,
          status: 'InActive',
        });
      }
    });

    values?.liveryOnCounty?.map((val) => {
      let foundMatchingCounty = false;

      editObj?.liveryOnCounty?.map((coun) => {
        if (coun.countySeq === countyNameMapping[val]['countySeq']) {
          foundMatchingCounty = true;
        }
      });
      if (!foundMatchingCounty) {
        newLiveryCounty.push({
          liverySeq: id,
          countySeq: countyNameMapping[val]['countySeq'],
          status: 'Active',
        });
      }
    });

    const postObj = {
      livery: [
        {
          liverySeq: Object.keys(editObj).length === 0 ? id : editObj?.id,
          liveryName: values?.liveryName || '',
          liveryDescription: values?.liveryDescription || '',
          liveryCode: values?.liveryCode || '',
          countySeq: values?.countySeq || id,
          status: values?.status || 'Active',
        },
      ],

      liveryOnCounty: newLiveryCounty,
    };
    try {
      const response = await saveAdminData({
        values: postObj,
        tableName: 'liveryservices',
      });
      if (response.status == 0) {
        setTimeout(() => {
          setIsAddEditOn(false);
          getAllData();
        }, 1700);
      }
      setDialogContents(response.message);
      setOpenApiDialogBox(true);
    } catch (error) {
      console.log('error', error);
    }
  };

  const [checkAll, setCheckAll] = useState(false);

  const checkAllChange = (event, handleChange) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      let newdata = [
        ...Object.keys(county).map(
          (countySeq) => county[countySeq]['countyName']
        ),
      ];
      delete newdata[0];
      handleChange({ target: { value: newdata, name: 'liveryOnCounty' } });
    } else {
      handleChange({ target: { value: [], name: 'liveryOnCounty' } });
    }
  };

  return (
    <>
      {' '}
      <Grid elevation={3} spacing={2} sx={{ padding: '16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={10} mb={2} mt={2}>
            <Typography variant='h5'>
              <strong>
                {Object.keys(editObj).length ? 'Modify' : 'Enter New'} Livery
                Service
              </strong>
            </Typography>
          </Grid>
        </Grid>
        <Formik
          initialValues={{
            ...initialValues,
            liveryOnCounty: initialValues.countys
              ? initialValues.countys.map(
                  (livery) =>
                    county &&
                    Object.keys(county) &&
                    county[livery.countySeq] &&
                    county[livery.countySeq]['countyName']
                )
              : [],
            liveryName: initialValues?.liveryName
              ? initialValues?.liveryName
              : '',
          }}
          validationSchema={validationSchema}
          onSubmit={onSave}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            handleChange,
          }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Box>
                        <Autocomplete
                          multiple
                          disableCloseOnSelect
                          limitTags={9}
                          id='checkboxes-tags-demo'
                          options={
                            Object.keys(county).map(
                              (countySeq) => county[countySeq]['countyName']
                            ) || []
                          }
                          value={values.liveryOnCounty}
                          open={dropdownopen}
                          name='liveryOnCounty'
                          onChange={(event, newValue, reason) => {
                            if (reason === 'selectOption') {
                              handleChange({
                                target: {
                                  value: newValue,
                                  name: 'liveryOnCounty',
                                },
                              });
                            } else if (reason === 'removeOption') {
                              setCheckAll(false);
                              handleChange({
                                target: {
                                  value: newValue,
                                  name: 'liveryOnCounty',
                                },
                              });
                            } else if (reason === 'clear') {
                              handleChange({
                                target: { value: [], name: 'liveryOnCounty' },
                              });
                              setCheckAll(false);
                            }
                          }}
                          onClose={(e, reason) => {
                            if (reason === 'escape') {
                              setDropdownopen(false);
                            }
                          }}
                          onOpen={() => {
                            setDropdownopen(true);
                          }}
                          PopperComponent={(param) => (
                            <PopperStyledComponent {...param}>
                              <Box {...param} />
                              <Divider />
                            </PopperStyledComponent>
                          )}
                          getOptionLabel={(option) => {
                            return option ? option?.toString() : [];
                          }}
                          renderOption={(props, option, { selected }) => (
                            <>
                              <li
                                {...props}
                                style={{
                                  marginLeft:
                                    option == 'New Jersey' ||
                                    option == 'NJ State Case' ||
                                    option == 'MAiD Case'
                                      ? ''
                                      : '15px',
                                }}
                              >
                                {option === 'Select All' ? (
                                  <Checkbox
                                    checked={checkAll}
                                    onChange={(e) =>
                                      checkAllChange(e, handleChange)
                                    }
                                    id='check-all'
                                    sx={{ marginRight: '8px' }}
                                    onMouseDown={(e) => e.preventDefault()}
                                  />
                                ) : (
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected || checkAll}
                                  />
                                )}
                                {option}
                              </li>
                            </>
                          )}
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label='Jurisdiction'
                              placeholder='Select Jurisdiction'
                            />
                          )}
                        />
                      </Box>
                    </ClickAwayListener>
                    {
                      <ErrorMessage
                        name={`liveryOnCounty`}
                        render={(msg) => (
                          <FormHelperText error>{msg}</FormHelperText>
                        )}
                      />
                    }
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} mb={0} mt={2} spacing={2}>
                    <Typography variant='h6'>
                      <strong>Livery Service</strong>
                    </Typography>
                  </Grid>

                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth error variant='standard'>
                      <TextField
                        focused
                        required
                        id='liveryName'
                        label='Livery Name'
                        variant='outlined'
                        name='liveryName'
                        defaultValue=''
                        margin='normal'
                        size='large'
                        value={values.liveryName}
                        onChange={handleChange}
                        onInput={(e) => {
                          e.target.value = e.target.value.slice(0, 50);
                        }}
                      />
                    </FormControl>
                    <ErrorMessage
                      name='liveryName'
                      render={(msg) => (
                        <FormHelperText error>{msg}</FormHelperText>
                      )}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth error variant='standard'>
                      <TextField
                        focused
                        id='liveryCode'
                        label='Livery Code'
                        variant='outlined'
                        name='liveryCode'
                        defaultValue=''
                        margin='normal'
                        size='large'
                        value={values.liveryCode}
                        onChange={handleChange}
                        onInput={(e) => {
                          e.target.value = e.target.value.slice(0, 3);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12} mSd={6} lg={6}>
                      <FormControl
                        fullWidth
                        sx={{ marginTop: '20px', marginLeft: '15px' }}
                      >
                        <TextField
                          focused
                          id='liveryDescription'
                          label='Livery Description'
                          variant='outlined'
                          name='liveryDescription'
                          size='small'
                          fullWidth
                          multiline
                          rows={5}
                          rowsMax={5}
                          value={values.liveryDescription}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Button
                        onClick={handleSubmit}
                        type='submit'
                        variant='contained'
                        sx={{ minWidth: '125px', marginTop: '7px' }}
                        disabled={
                          !liveryServicesPermissions?.edit &&
                          !liveryServicesPermissions?.add
                        }
                      >
                        {Object.keys(editObj) > 1 ? 'Update' : 'Save'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      <ApiNotification
        openApiDialogBox={openApiDialogBox}
        setOpenApiDialogBox={setOpenApiDialogBox}
        dialogContents={dialogContents}
        closeDialogBox={() => {
          setOpenApiDialogBox(false);
        }}
      />
    </>
  );
};
export default AddEdit;
