import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
/** Weird bug relating to yup types */
// @ts-ignore
import { CheckBoxOutlineBlankRounded, CheckRounded } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { List } from 'reactstrap';
import * as yup from 'yup';
import { useAuth } from '../../../utils/auth/AuthService';

const validationSchema = yup.object({
  new_password: yup.string().required('Password is required'),
  confirm_password: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('new_password')], 'Passwords must match'),
});

export function NewPasswordForm() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const formik = useFormik({
    initialValues: {
      new_password: '',
      confirm_password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      setLoading(true);

      const user = await auth.changePassword({
        username: auth.user?.userName as any as string,
        userseq: auth.user?.userSeq as any as string,
        new_password: values.new_password,
        auth_token: auth.user?.authToken as any as string,
      });

      if (user) {
        auth.userDispatch({ type: 'SET_USER', payload: user });
        auth.authDispatch({ type: 'SET_AUTHENTICATED', payload: true });
        auth.authDispatch({ type: 'SET_PASSED_2FA', payload: true });
        auth.authDispatch({
          type: 'SET_LOGIN_STATE',
          payload: {
            state: 'success',
          },
        });
        auth.authDispatch({ type: 'SET_READY', payload: true });
        navigate('/home');
      }

      setLoading(false);
    },
  });

  const [loading, setLoading] = useState(false);

  const isValidLength = formik.values.new_password.length >= 8;
  const charactersRemaining = () => {
    if (formik.values.new_password.length >= 8) return 0;
    else return 8 - formik.values.new_password.length;
  };

  const containsNumber = /\d/.test(formik.values.new_password);
  const numbersRemaining = () => {
    const numCount = (formik.values.new_password.match(/\d/g) || []).length;
    return Math.max(1 - numCount, 0);
  };

  const containsSpecialCharacter = /[!@#$%^&*(),.?":{}|<>[\]\\;'/`~\-_+=]/.test(
    formik.values.new_password
  );
  const specialCharsRemaining = () => {
    const specialCount = (
      formik.values.new_password.match(/[!@#$%^&*(),.?":{}|<>[\]\\;'/`~\-_+=]/g) || []
    ).length;
    return Math.max(1 - specialCount, 0);
  };

  const isValidPassword = isValidLength && containsSpecialCharacter && containsNumber;

  return (
    <>
      <Stack display='flex' flexDirection='column' alignItems='center' py={2}>
        <Typography variant='h5' fontWeight='bold'>
          Change password
        </Typography>
        <Typography variant='subtitle2' sx={{ opacity: '0.6' }}>
          Enter a new password below for your account
        </Typography>

        <Typography variant='subtitle2' sx={{ opacity: '0.6' }}>
          For security purposes, your password must have the following:
        </Typography>

        <Box component='div' width='100%' mt={1}>
          <List>
            <ListItem disablePadding dense={true} sx={{ opacity: '0.8' }}>
              <ListItemIcon>
                {isValidLength ? <CheckRounded color='success' /> : <CheckBoxOutlineBlankRounded />}
              </ListItemIcon>
              <ListItemText
                primary={`at least ${charactersRemaining()} more characters`}
                sx={{
                  color: isValidLength ? 'success.main' : 'inherit',
                  textDecoration: isValidLength ? 'line-through' : 'none',
                }}
              />
            </ListItem>
            <ListItem disablePadding dense={true} sx={{ opacity: '0.8' }}>
              <ListItemIcon>
                {containsNumber ? (
                  <CheckRounded color='success' />
                ) : (
                  <CheckBoxOutlineBlankRounded />
                )}
              </ListItemIcon>
              <ListItemText
                primary={`at least ${numbersRemaining()} more number`}
                sx={{
                  color: containsNumber ? 'success.main' : 'inherit',
                  textDecoration: containsNumber ? 'line-through' : 'none',
                }}
              />
            </ListItem>
            <ListItem disablePadding dense={true} sx={{ opacity: '0.8' }}>
              <ListItemIcon>
                {containsSpecialCharacter ? (
                  <CheckRounded color='success' />
                ) : (
                  <CheckBoxOutlineBlankRounded />
                )}
              </ListItemIcon>
              <ListItemText
                primary={`at least ${specialCharsRemaining()} more special character ($ & # ! %)`}
                sx={{
                  color: containsSpecialCharacter ? 'success.main' : 'inherit',
                  textDecoration: containsSpecialCharacter ? 'line-through' : 'none',
                }}
              />
            </ListItem>
          </List>
        </Box>

        {auth.login_state.state === 'error' && (
          <Typography variant='body2' color='error' my={1} textAlign='center'>
            <b>Error:</b> {auth.login_state.message ?? 'Unknown error'}
          </Typography>
        )}
      </Stack>
      <form onSubmit={formik.handleSubmit}>
        <div className='form-group login-input'>
          <TextField
            disabled={loading}
            size='small'
            fullWidth
            id='new_password'
            name='new_password'
            label='New password'
            type='password'
            value={formik.values.new_password}
            onChange={formik.handleChange}
            error={formik.touched.new_password && Boolean(formik.errors.new_password)}
            helperText={formik.touched.new_password && formik.errors.new_password}
          />
        </div>
        <div className='form-group login-input'>
          <TextField
            disabled={loading}
            size='small'
            fullWidth
            id='confirm_password'
            name='confirm_password'
            label='Confirm new password'
            type='password'
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
            helperText={formik.touched.confirm_password && formik.errors.confirm_password}
          />
        </div>
        <div className='form-group login-input'>
          <Button
            color='primary'
            disabled={!isValidPassword || loading}
            sx={{
              fontWeight: 'bold',
            }}
            variant='contained'
            fullWidth
            type='submit'
            startIcon={loading && <CircularProgress sx={{ color: 'grey.200' }} size={20} />}
          >
            {loading ? 'Changing password' : 'Change password'}
          </Button>
        </div>
      </form>
    </>
  );
}
