import {
  AddCircleOutlineRounded,
  DeleteRounded,
  EditRounded,
  QuestionMarkRounded,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  ListItemIcon,
  MenuItem,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../../utils/auth/AuthService';
import CustomHeader from '../../../../utils/components/CustomHeader';
import { PublicationCardLoadingSkeleton } from '../Publications/PublicationCardLoadingSkeleton';
import { ResourceCard } from '../ResourceFiles/ResourceCard';
import VideoDropzone from './VideoDropzone';
import { VideoPlayer } from './VideoPlayer';
export interface Video {
  videoSeq: string;
  videoLabel: string;
  videoNotes: string;
  userSeq: string | null;
  dateTime: string | null;
  videoMimeType: string | null;
  entityName: string | null;
  originalFileName: string | null;
  originalFileSize: number | null;
  isActive: boolean;
  uploadedBy: string | null;
}

export interface VideoWithContent extends Video {
  videoContentURL: string;
  thumbnailURL: string;
}

export default function Videos({ handleMenuClick }: { handleMenuClick: any }) {
  const [loading, setLoading] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState<VideoWithContent | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { REACT_APP_API_URL } = process.env;
  const auth = useAuth();

  const [videos, setVideos] = useState<VideoWithContent[]>([]);
  const [isDropzoneOpen, setIsDropzoneOpen] = useState(false);
  const [editingOpen, setEditingOpen] = useState(false);

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  const handleOpenUploadDialog = () => setIsUploadDialogOpen(true);
  const handleCloseUploadDialog = () => setIsUploadDialogOpen(false);

  const fetchThumbnail = useCallback(
    async (videoSeq: string) => {
      try {
        const response = await fetch(`${REACT_APP_API_URL}videos/${videoSeq}/thumbnail`, {
          headers: {
            Authorization: `Bearer ${auth.user?.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch thumbnail');
        }

        const blob = await response.blob();
        return URL.createObjectURL(blob);
      } catch (error) {
        console.error('Error fetching thumbnail:', error);
        return null;
      }
    },
    [REACT_APP_API_URL, auth.user?.accessToken]
  );

  const fetchVideos = useCallback(async () => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}videos`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.user?.accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch videos');
      }

      const data: Video[] = await response.json();
      const videosWithThumbnails = await Promise.all(
        data.map(async video => ({
          ...video,
          videoContentURL: '',
          thumbnailURL: (await fetchThumbnail(video.videoSeq)) || '',
        }))
      );
      setVideos(videosWithThumbnails);
    } catch (error) {
      console.error('Error fetching videos:', error);
      alert('An error occurred while fetching videos');
    } finally {
      setLoading(false);
    }
  }, [REACT_APP_API_URL, auth.user?.accessToken, fetchThumbnail]);

  useEffect(() => {
    fetchVideos();
  }, [fetchVideos]);

  const handleVideoSelect = (selectedVideo: VideoWithContent) => {
    setSelectedVideo(selectedVideo);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedVideo(null);
  };

  const handleEditVideo = useCallback((video: VideoWithContent) => {
    setSelectedVideo(video);
    setEditingOpen(true);
  }, []);

  const handleDeleteVideo = useCallback(
    async (video: VideoWithContent) => {
      if (window.confirm(`Are you sure you want to delete the video "${video.videoLabel}"?`)) {
        try {
          const response = await fetch(`${REACT_APP_API_URL}videos/${video.videoSeq}/softdelete`, {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${auth.user?.accessToken}`,
            },
          });

          if (!response.ok) {
            throw new Error('Failed to delete video');
          }

          setVideos(prevVideos => prevVideos.filter(v => v.videoSeq !== video.videoSeq));
          alert('Video successfully deleted.');
        } catch (error) {
          console.error('Error deleting video:', error);
          alert('An error occurred while deleting the video');
        }
      }
    },
    [REACT_APP_API_URL, auth.user?.accessToken]
  );

  return (
    <Stack>
      <CustomHeader
        title='Videos'
        description='Helpful video resources'
        handleMenuClick={handleMenuClick}
        endAdornment={<></>}
      />
      <Stack spacing={1}>
        {auth.user?.roleCheck(['d1582600-5f86-49dd-bab7-6f7205bfeffd']) && (
          <Button
            variant='text'
            startIcon={<AddCircleOutlineRounded />}
            onClick={handleOpenUploadDialog}
            fullWidth
          >
            Upload New Video
          </Button>
        )}

        <UploadVideoDialog
          open={isUploadDialogOpen}
          onClose={handleCloseUploadDialog}
          onUploadSuccess={() => {
            fetchVideos();
            handleCloseUploadDialog();
          }}
        />

        <Grid container spacing={2}>
          {loading &&
            Array.from({ length: 4 }, (_, index) => (
              <Grid item xs='auto' key={index}>
                <PublicationCardLoadingSkeleton />
              </Grid>
            ))}

          {videos.map((video, index) => (
            <Grid item xs='auto' key={video.videoSeq}>
              <ResourceCard
                resource={{
                  author: video.uploadedBy,
                  createdAt: video.dateTime,
                  title: video.videoLabel,
                  subtitle: video?.videoNotes || null,
                  thumbnailString: video.thumbnailURL,
                  menuItems: [
                    auth.user?.roleCheck(['d1582600-5f86-49dd-bab7-6f7205bfeffd']) && (
                      <MenuItem onClick={() => handleEditVideo(video)}>
                        <ListItemIcon>
                          <EditRounded />
                        </ListItemIcon>
                        Edit Video
                      </MenuItem>
                    ),
                    auth.user?.roleCheck(['d1582600-5f86-49dd-bab7-6f7205bfeffd']) && (
                      <MenuItem onClick={() => handleDeleteVideo(video)}>
                        <ListItemIcon>
                          <DeleteRounded />
                        </ListItemIcon>
                        Delete Video
                      </MenuItem>
                    ),
                  ],
                }}
                onResourceSelect={() => handleVideoSelect(video)}
                showMore={auth.user?.roleCheck(['d1582600-5f86-49dd-bab7-6f7205bfeffd'])}
              />
            </Grid>
          ))}
        </Grid>

        <EditVideoDialog
          video={selectedVideo}
          open={editingOpen}
          onClose={() => {
            setSelectedVideo(null);
            setEditingOpen(false);
          }}
          onSave={() => {
            fetchVideos();
            setEditingOpen(false);
          }}
        />

        <VideoDropzone
          isOpen={isDropzoneOpen}
          setIsOpen={setIsDropzoneOpen}
          onSuccessUpload={() => {
            console.log('Video uploaded successfully!');
            fetchVideos();
          }}
        />

        <Dialog open={modalOpen} onClose={handleCloseModal} maxWidth='md' fullWidth>
          <DialogContent>
            {selectedVideo && (
              <Stack spacing={2}>
                <Typography variant='h5'>{selectedVideo.videoLabel}</Typography>
                <VideoPlayer
                  videoSeq={selectedVideo.videoSeq}
                  // poster={selectedVideo.thumbnailURL}
                />
                {selectedVideo.videoNotes && (
                  <Typography variant='body1'>{selectedVideo.videoNotes}</Typography>
                )}
              </Stack>
            )}
          </DialogContent>
        </Dialog>
      </Stack>
    </Stack>
  );
}

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 350,
  position: 'relative',
  background: 'transparent',
  cursor: 'pointer',
  aspectRatio: '16 / 9',
  overflow: 'hidden',
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center',
  textAlign: 'left',
  borderRadius: 4,
  transition: 'opacity 0.3s ease',
  opacity: 1,
  '&:hover': {
    opacity: 0.5,
  },
  '&:active': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  cursor: 'pointer',
}));

interface EditVideoDialogProps {
  video: VideoWithContent | null;
  open: boolean;
  onClose?: () => void;
  onSave?: () => void;
}

function EditVideoDialog({
  video = null,
  open,
  onClose = () => {},
  onSave = () => {},
}: EditVideoDialogProps) {
  const [thumbnailPreview, setThumbnailPreview] = useState<string | null>(null);
  const { REACT_APP_API_URL } = process.env;
  const auth = useAuth();

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      thumbnail: null as File | null,
    },
    onSubmit: async values => {
      if (!video) return;

      const formData = new FormData();
      formData.append('videoLabel', values.title);
      formData.append('videoNotes', values.description);
      if (values.thumbnail) {
        formData.append('thumbnail', values.thumbnail);
      }

      try {
        const response = await fetch(`${REACT_APP_API_URL}videos/${video.videoSeq}`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${auth.user?.accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to update video');
        }

        onSave();
      } catch (error) {
        console.error('Error updating video:', error);
        alert('An error occurred while updating the video');
      }
    },
  });

  const fetchThumbnail = useCallback(
    async (videoSeq: string) => {
      try {
        const response = await fetch(`${REACT_APP_API_URL}videos/${videoSeq}/thumbnail`, {
          headers: {
            Authorization: `Bearer ${auth.user?.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch thumbnail');
        }

        const blob = await response.blob();
        const thumbnailUrl = URL.createObjectURL(blob);
        setThumbnailPreview(thumbnailUrl);
      } catch (error) {
        console.error('Error fetching thumbnail:', error);
        setThumbnailPreview(null);
      }
    },
    [REACT_APP_API_URL, auth.user?.accessToken]
  );

  useEffect(() => {
    if (video) {
      formik.setValues({
        title: video.videoLabel || '',
        description: video.videoNotes || '',
        thumbnail: null,
      });
      fetchThumbnail(video.videoSeq);
    }
  }, [video, fetchThumbnail]);

  const handleThumbnailUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    if (file) {
      formik.setFieldValue('thumbnail', file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setThumbnailPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              id='title'
              name='title'
              label='Title'
              value={formik.values.title}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              id='description'
              name='description'
              label='Description'
              multiline
              rows={3}
              value={formik.values.description}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: true }}
            />
            <Typography variant='subtitle1'>Thumbnail Preview</Typography>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <StyledCard>
                {thumbnailPreview ? (
                  <StyledCardMedia image={thumbnailPreview} title='Thumbnail preview' />
                ) : (
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    <QuestionMarkRounded sx={{ fontSize: 80, color: 'text.secondary' }} />
                  </Box>
                )}
              </StyledCard>
            </Box>
            <Button variant='outlined' component='label' fullWidth>
              Upload New Thumbnail
              <input type='file' hidden accept='image/*' onChange={handleThumbnailUpload} />
            </Button>
            <Button type='submit' variant='contained' fullWidth>
              Save Changes
            </Button>
            <Button onClick={onClose} variant='outlined' fullWidth>
              Close
            </Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
}

interface UploadVideoDialogProps {
  open: boolean;
  onClose: () => void;
  onUploadSuccess: () => void;
}

function UploadVideoDialog({ open, onClose, onUploadSuccess }: UploadVideoDialogProps) {
  const [thumbnailPreview, setThumbnailPreview] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const { REACT_APP_API_URL } = process.env;
  const auth = useAuth();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string(),
    video: Yup.mixed().required('Video file is required'),
    thumbnail: Yup.mixed().required('Thumbnail is required'),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      video: null as File | null,
      thumbnail: null as File | null,
    },
    validationSchema,
    onSubmit: async values => {
      setIsUploading(true);

      const formData = new FormData();
      formData.append('video', values.video as File);
      formData.append('thumbnail', values.thumbnail as File);
      formData.append('videoLabel', values.title);
      formData.append('videoNotes', values.description);

      try {
        const response = await fetch(`${REACT_APP_API_URL}videos`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.user?.accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to upload video');
        }

        onUploadSuccess();
      } catch (error) {
        console.error('Error uploading video:', error);
        alert('An error occurred while uploading the video');
      } finally {
        setIsUploading(false);
      }
    },
  });

  const handleVideoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    if (file) {
      formik.setFieldValue('video', file);
    }
  };

  const handleThumbnailUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    if (file) {
      formik.setFieldValue('thumbnail', file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setThumbnailPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              id='title'
              name='title'
              label='Title'
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
            <TextField
              fullWidth
              id='description'
              name='description'
              label='Description'
              multiline
              rows={3}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
            <Button variant='outlined' component='label' fullWidth>
              Select Video
              <input type='file' hidden accept='video/*' onChange={handleVideoUpload} />
            </Button>
            {formik.values.video && (
              <Typography variant='body2'>Selected video: {formik.values.video.name}</Typography>
            )}
            {formik.touched.video && formik.errors.video && (
              <Typography color='error'>{formik.errors.video as string}</Typography>
            )}
            <Typography variant='subtitle1'>Thumbnail Preview (Required)</Typography>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <StyledCard>
                {thumbnailPreview ? (
                  <StyledCardMedia image={thumbnailPreview} title='Thumbnail preview' />
                ) : (
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    <QuestionMarkRounded sx={{ fontSize: 80, color: 'text.secondary' }} />
                  </Box>
                )}
              </StyledCard>
            </Box>
            <Button variant='outlined' component='label' fullWidth>
              Upload Thumbnail
              <input type='file' hidden accept='image/*' onChange={handleThumbnailUpload} />
            </Button>
            {formik.touched.thumbnail && formik.errors.thumbnail && (
              <Typography color='error'>{formik.errors.thumbnail as string}</Typography>
            )}
            <Button
              type='submit'
              variant='contained'
              fullWidth
              disabled={isUploading || !formik.isValid}
              startIcon={isUploading ? <CircularProgress size={20} color='inherit' /> : null}
            >
              {isUploading ? 'Uploading...' : 'Upload Video'}
            </Button>
            <Button onClick={onClose} variant='outlined' fullWidth disabled={isUploading}>
              Cancel
            </Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
}
