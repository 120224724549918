import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { IconButton, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarExport,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import React from 'react';
import '../../../App.css';

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = gridPageSelector(apiRef);
  const pageCount = gridPageCountSelector(apiRef);

  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => (
        <PaginationItem {...props2} sx={{ fontFamily: 'DataGrid, sans-serif' }} disableRipple />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{ mr: 3 }}
    />
  );
}

const GridToolbar = ({ title, casecount, count, expanded, setExpanded }) => {
  const theme = useTheme();

  const toggleExpandDataGrid = (event) => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  return (
    <GridToolbarContainer sx={{ p: 0 }}>
      <Stack sx={{ width: '100%', m: 0, p: 0 }}>
        <Stack direction='row'>
          {title != null && casecount ? (
            <Typography variant='h5' sx={{ p: 2 }}>
              {title}: {count}
            </Typography>
          ) : (
            (title != null && (
              <Typography variant='h5' sx={{ p: 2 }}>
                {title}
              </Typography>
            )) ||
            (casecount && (
              <Typography variant='h5' sx={{ p: 2 }}>
                Cases Found: {count}
              </Typography>
            ))
          )}
          <GridToolbarExport
            sx={{
              p: 1,
              m: 1,
              ml: 'auto',
            }}
          />
          <IconButton
            sx={{
              color: theme.palette.text.primary,
              opacity: 0.8,
              m: 2,
            }}
            size='small'
            onClick={toggleExpandDataGrid}
          >
            <OpenInFullIcon fontSize='small' />
          </IconButton>
        </Stack>
        <Divider sx={{ opacity: 0.6 }} />
      </Stack>
    </GridToolbarContainer>
  );
};

export default function CondensedReportDataGrid({
  loading,
  rows,
  columns,
  chatcontent = null,
  title,
  idcolumn,
  casecount,
  rowsperpage = 5,
  expanded,
  setExpanded,
}) {
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: rowsperpage,
    page: 0,
  });

  return (
    <Stack spacing={2}>
      {chatcontent != null && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {loading ? (
            <Skeleton
              variant='rounded'
              animation='wave'
              height={50}
              sx={{
                borderRadius: 20,
                width: '80%',
              }}
            />
          ) : (
            chatcontent
          )}
        </Box>
      )}
      <DataGridPremium
        columns={columns}
        rows={rows}
        loading={loading}
        getRowId={(row) => row[idcolumn]}
        pagination
        autoHeight
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        disableRowSelectionOnClick
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              ID: false,
              resultseq: false,
              caseseq: false,
            },
          },
        }}
        slots={{
          pagination: CustomPagination,
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: {
            title: title,
            casecount: casecount,
            count: rows.length,
            expanded: expanded,
            setExpanded: setExpanded,
          },
        }}
        sx={{
          '& .MuiDataGrid-cell': {
            fontSize: '0.775rem', // Adjust the font size as needed
            borderRight: '1px solid rgba(200, 200, 200, 0.6)', // Add right border to cells
          },
          '& .MuiDataGrid-row': {
            borderBottom: '1px solid rgba(200, 200, 200, 0.6)', // Add bottom border to rows
          },
        }}
      />
    </Stack>
  );
}
