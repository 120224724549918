import React from 'react';

interface StickyValue<T> {
  value: T;
  clearanceKey: string;
}

function useStickyState<T>(defaultValue: T, key: string, clearanceKey: string) {
  const [value, setValue] = React.useState<T>(() => {
    const stickyValue = window.localStorage.getItem(key);
    if (stickyValue !== null) {
      const parsed: StickyValue<T> = JSON.parse(stickyValue);
      if (parsed.clearanceKey === clearanceKey) {
        return parsed.value;
      }
    }
    return defaultValue;
  });

  React.useEffect(() => {
    const stickyValue: StickyValue<T> = {
      value: value,
      clearanceKey: clearanceKey,
    };
    window.localStorage.setItem(key, JSON.stringify(stickyValue));
  }, [key, value, clearanceKey]);

  return [value, setValue] as const;
}

export default useStickyState;
