import React from 'react';
import { useEffect, useState } from 'react';
// project imports
import MainCard from '../../../../../../components/AdministrationModules/MainCard';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import {
  activeData,
  deleteData,
  getAdminData,
  getAllCounty,
} from '../../../../../../services/utility-api';
import AddEdit from './AddEdit';

// material-ui
import { Grid, Box, Button, IconButton, Tooltip, Divider } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ReferenceMasterTableFilter from '../ReferenceMasterTableFilter';
import AccessDenied from '../../../../../../components/AdministrationModules/AccessDenied';
import Breadcrumb from '../../../../../../components/AdministrationModules/Breadcrumb';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
const Details = () => {
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const referencePermissions = permissions?.['Reference Data'];
  const [isAddEditOn, setIsAddEditOn] = useState();
  const [editObj, setEditObj] = useState({});
  const [maxOrder, setMaxOrder] = useState(0);
  const referenceMasterTableName = 'CaseLocation';

  const [openActiveInActiveDialogBox, setOpenActiveInActiveDialogBox] = useState(false);
  const [activeInactiveClickData, setActiveInactiveClickData] = useState({});
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const [activeInActiveFilter, setActiveInActiveFilter] = useState('Active');

  const [caseClassification, setCaseClassification] = useState('');
  const [caseClassificationMapping, setCaseClassificationMapping] = useState({});
  const [priority, setPriority] = useState('');
  const [priorityMapping, setPriorityMapping] = useState({});

  const [columns, setColumns] = useState([
    {
      field: 'caseLocationName',
      headerName: 'Details Name',
      // headerClassName: 'super-app-theme--header',
      minWidth: 200,
      align: 'left',
      flex: 5,
    },
    {
      field: 'caseLocationOrder',
      headerName: 'List Order',
      // headerClassName: 'super-app-theme--header',
      minWidth: 150,
      align: 'left',
      flex: 5,
    },
  ]);
  const [rows, setRows] = useState([]);
  const [rowsToShow, setRowsToShow] = useState([]);
  const [countyNames, setCountyNames] = useState({});
  const MENU_OBJECT = {
    Home: '/sysadmin',
    'Reference Data': '/sysadmin',
    Details: '',
  };
  const getAllData = async () => {
    const response = await getAdminData('CaseLocation');
    const countyResponse = await getAllCounty();
    const casePriorityResponse = await getAdminData('caseClassification');
    const priorityResponse = await getAdminData('Priority');

    let tempCaseClassificationMapping = {};
    casePriorityResponse?.data.data.map(row => {
      tempCaseClassificationMapping[row.caseClassificationSeq] = row;
    });

    let tempPriorityMapping = {};
    priorityResponse?.data.data.map(row => {
      tempPriorityMapping[row.prioritySeq] = row;
    });

    setCaseClassification(casePriorityResponse?.data.data);
    setCaseClassificationMapping(tempCaseClassificationMapping);
    setPriority(priorityResponse?.data.data);
    setPriorityMapping(tempPriorityMapping);

    let isCaseClassificationNameColumnAdded = false;
    columns.forEach(column => {
      if (column.field == 'caseClassificationName') {
        isCaseClassificationNameColumnAdded = true;
      }
    });
    if (!isCaseClassificationNameColumnAdded) {
      setColumns(prev => [
        ...prev,
        {
          field: 'caseClassificationName',
          renderCell: params => {
            return (
              <>
                <p>
                  {params.row.caseLocationOnCaseClassification.map(item => (
                    <span
                      key={item.caseLocationOnCaseClassificationSeq}
                      style={{ display: 'block' }}
                    >
                      {tempCaseClassificationMapping[item.caseClassificationSeq]
                        ? tempCaseClassificationMapping[item.caseClassificationSeq]
                            .caseClassificationName
                        : ''}
                    </span>
                  ))}
                </p>
              </>
            );
          },

          headerName: 'High Priority',
          // headerClassName: 'super-app-theme--header',
          minWidth: 150,
          align: 'left',
          flex: 5,
        },
        {
          field: 'priorityName',
          renderCell: params => (
            <>
              <p>
                {params.row.caseLocationOnCaseClassification.map((item, index) => (
                  <span key={item.caseLocationOnCaseClassificationSeq} style={{ display: 'block' }}>
                    {tempPriorityMapping[item.prioritySeq]
                      ? tempPriorityMapping[item.prioritySeq].priorityName
                      : ''}
                  </span>
                ))}
              </p>
            </>
          ),

          headerName: 'Priority',
          // headerClassName: 'super-app-theme--header',
          minWidth: 150,
          align: 'left',
          flex: 5,
        },
      ]);
    }

    let isStatusColumnAdded = false;
    columns.forEach(column => {
      if (column.field == 'status') {
        isStatusColumnAdded = true;
      }
    });
    if (!isStatusColumnAdded) {
      setColumns(prev => [
        ...prev,
        {
          field: 'status',
          headerName: 'Status',
          // headerClassName: 'super-app-theme--header',
          renderCell: cellValues => {
            const handleClick = async row => {
              //   if (!permissions?.['Reference Data']?.edit) return;
              setOpenActiveInActiveDialogBox(true);
              const newIconData = row.status === 'Active' ? 'locked' : 'done';
              setActiveInactiveClickData({
                icon: newIconData,
                seq: row[
                  referenceMasterTableName[0].toLowerCase() +
                    referenceMasterTableName.slice(1) +
                    'Seq'
                ],
                status: row.status,
              });
            };

            return Number(cellValues.row['caseLocationOrder']) > 90000 ? (
              <></>
            ) : (
              <Button
                onClick={e => {
                  e.preventDefault();
                  handleClick(cellValues.row);
                }}
                disabled={!referencePermissions?.edit}
              >
                {cellValues.row.status === 'Active' ? (
                  <Tooltip title='Active'>
                    <LockIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title='InActive'>
                    <DoneIcon />
                  </Tooltip>
                )}
              </Button>
            );
          },
        },
      ]);
    }

    setCountyNames(countyResponse);
    let maxOrder = 0;
    for (let i = 0; i < response.data.data.length; i++) {
      if (
        Number(response.data.data[i].caseLocationOrder) > maxOrder &&
        Number(response.data.data[i].caseLocationOrder) < 90000
      )
        maxOrder = Number(response.data.data[i].caseLocationOrder);
    }
    setMaxOrder(maxOrder);
    const data =
      response?.data?.data.map(row => {
        const newObj = {};

        Object.keys(row).map(key => {
          newObj[key] = row[key];
        });

        if (newObj['caseLocationOrder'])
          newObj['caseLocationOrder'] = Number(newObj['caseLocationOrder']);

        return newObj;
      }) || [];
    setRows(data);
    setRowsToShow(data.filter(row => row['status'].toLowerCase() == 'active'));
  };

  const editRow = params => {
    if (referencePermissions?.edit) {
      if (params?.row?.status.toLowerCase() === 'active') {
        setIsAddEditOn(false);
        setTimeout(() => {
          setEditObj(params.row);
          setIsAddEditOn(true);
        }, 200);
      }
    }
  };
  const changeActiveInActiveClick = async () => {
    try {
      if (activeInactiveClickData.status === 'Active') {
        const responseMessage = await deleteData({
          seq: activeInactiveClickData.seq,
          tableName: 'CaseLocation',
        });
        setDialogContents(responseMessage);
      } else {
        const responseMessage = await activeData({
          seq: activeInactiveClickData.seq,
          tableName: 'CaseLocation',
        });
        setDialogContents(responseMessage);
      }
      setOpenActiveInActiveDialogBox(false);
      setOpenApiDialogBox(true);
      await getAllData();
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);

  if (!referencePermissions?.view) {
    return (
      <>
        <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
        <AccessDenied />
      </>
    );
  } else {
    return (
       // <div className='container my-5 py-5'>
       <Grid container spacing={2} sx={{mt: '10px', padding: '16px'}}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Breadcrumb MENU_OBJECT={MENU_OBJECT} />
                <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
              {/* <MainCard> */}                  <ReferenceMasterTableFilter
                    rows={rows}
                    isCounty={false}
                    countyNames={[]}
                    setRowsToShow={setRowsToShow}
                    activeInActiveFilter={activeInActiveFilter}
                    setActiveInActiveFilter={setActiveInActiveFilter}
                  />
                  {rows.length ? (
                    <Box
                      // sx={{
                      //   width: '100%',
                      //   '& .super-app-theme--header': {
                      //     backgroundColor: 'rgb(2, 136, 209)',
                      //     color: '#fff',
                      //   },
                      // }}
                      style={{ tr: 'hover', cursor: 'pointer' }}
                    >
                      <DataGrid
                        initialState={{
                          sorting: {
                            sortModel: [
                              {
                                field: 'caseLocationName',
                                sort: 'asc',
                              },
                            ],
                          },
                        }}
                        getRowHeight={() => 'auto'}
                        rows={rowsToShow}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick={false}
                        getRowId={row => row.caseLocationSeq}
                        onRowClick={editRow}
                        sx={{
                          width: '100%',
                          height: 450,
                          // border: 'none',
                          // borderRadius: '6px',
                          // backgroundColor: 'white',
                          // boxShadow: '0px 0px 10px #ddd',
                        }}
                      />
                    </Box>
                  ) : null}
                  <Box>
                    <Button
                      variant='contained'
                      sx={{ marginTop: '7px' }}
                      onClick={() => {
                        setIsAddEditOn(false);
                        setTimeout(() => {
                          setEditObj({});
                          setIsAddEditOn(true);
                        }, 200);
                      }}
                      disabled={!referencePermissions?.add}
                    >
                      Add Details
                    </Button>
                  </Box>
                  <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                  {isAddEditOn && editObj && (
                    <AddEdit
                      maxOrder={maxOrder}
                      editObj={editObj}
                      referenceMasterTableName='CaseLocation'
                      setIsAddEditOn={setIsAddEditOn}
                      getAllData={getAllData}
                      countyNames={countyNames}
                      rows={rows}
                      caseClassification={caseClassification}
                      caseClassificationMapping={caseClassificationMapping}
                      priority={priority}
                      priorityMapping={priorityMapping}
                      referencePermissions={referencePermissions}
                    />
                  )}
                  <div className='modal'>
                    <Dialog
                      open={openActiveInActiveDialogBox}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={() => setOpenActiveInActiveDialogBox(false)}
                      aria-describedby='alert-dialog-slide-description'
                    >
                      <DialogTitle></DialogTitle>
                      <DialogContent>
                        <DialogContentText id='alert-dialog-slide-description'>
                          Are you sure you want to{' '}
                          {activeInactiveClickData?.status === 'Active' ? 'InActive ' : 'Active'}{' '}
                          this detail?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setOpenActiveInActiveDialogBox(false)}>No</Button>
                        <Button onClick={changeActiveInActiveClick}>Yes</Button>
                      </DialogActions>
                    </Dialog>
                  </div>

                  <div className='modal'>
                    <Dialog
                      open={openApiDialogBox}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={() => setOpenApiDialogBox(false)}
                      aria-describedby='alert-dialog-slide-description'
                    >
                      <DialogTitle></DialogTitle>
                      <DialogContent>
                        <DialogContentText id='alert-dialog-slide-description'>
                          {dialogContents}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <IconButton onClick={() => setOpenApiDialogBox(false)}>
                          <CloseIcon />
                        </IconButton>
                      </DialogActions>
                    </Dialog>
                  </div>
                {/* </MainCard> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      // </div>
    );
  }
};
export default Details;
