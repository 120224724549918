import { Autocomplete, Grid, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { ActionList } from '../../../../components/CaseView/CaseViewDateTimeField';
import { useAuth } from '../../../../utils/auth/AuthService';
import { renderAutocompleteEditCell } from '../../../../utils/components/CrudDataGrid/AutocompleteEditCell.jsx';
import { dateTimeColumnType } from '../../../../utils/components/CrudDataGrid/DateTimeColumn.jsx';
import DialogCrudDataGrid, {
  getDateValue,
  handleAutocompleteChange,
  handleDateChange,
} from '../../../../utils/components/DialogCrudDataGrid';
import { TimeZone } from '../../../../utils/constants/constants.js';

dayjs.extend(utc);
dayjs.extend(timezone);

const { REACT_APP_API_URL } = process.env;

const SceneExaminedBy = () => {
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const [investigatorAndMEOptions, setInvestigatorAndMEOptions] = useState({});
  const formik = useFormikContext();
  const { user } = useAuth();
  const timeZone = TimeZone;

  const sceneExaminedByColumns = [
    {
      flex: 1,
      minWidth: 150,
      field: 'userSeq',
      headerName: 'Scene Examined By',
      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: value => {
        if (!value) return value;
        return value.optionName;
      },
      valueOptions: investigatorAndMEOptions,
      renderEditCell: params => renderAutocompleteEditCell(params),
    },
    {
      flex: 1,
      field: 'dateTime',
      headerName: 'Date Time',
      headerAlign: 'left',
      align: 'left',
      ...dateTimeColumnType,
      valueSetter: (value, row) => {
        if (value == null || value == 'Invalid Date') {
          return { ...row, dateTime: null };
        } else {
          const localDate = value ? dayjs(value).format('YYYY-MM-DDTHH:mm:ss') : null;

          return { ...row, dateTime: localDate };
        }
      },
    },
  ];

  const handleSceneExaminedBySave = async sceneExaminedBy => {
    let formData = new FormData();
    formData.append('sceneExaminedBy', JSON.stringify(sceneExaminedBy));
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'updateSceneExaminedBy', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {})
        .catch(e => {
          alert(e);
        })
        .finally(async () => {
          await fetchSceneExaminedBy();
        });
    } else {
      console.log('user or token is null');
    }
    return false;
  };

  const handleSceneExaminedByDelete = async id => {
    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'deleteSceneExaminedBy?id=' + id, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {})
        .catch(e => {
          alert(e);
        })
        .finally(async () => {
          await fetchSceneExaminedBy();
        });
    } else {
      console.log('user or token is null');
    }
    return false;
  };

  const fetchSceneExaminedUserOptions = () => {
    fetch(
      REACT_APP_API_URL +
        'GetSceneExaminedUserOptions?jdxSeq=' +
        formik.values?.caseSummary?.jdxSeq,
      {
        method: 'GET',
      }
    )
      .then(res => {
        return res.json();
      })
      .then(data => {
        setInvestigatorAndMEOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchSceneExaminedBy = async () => {
    await fetch(
      REACT_APP_API_URL + 'GetSceneExaminedBy?caseSeq=' + formik.values?.caseSummary?.caseSeq,
      {
        method: 'GET',
      }
    )
      .then(res => {
        return res.json();
      })
      .then(data => {
        formik.setFieldValue('caseInvestigations.discovery.sceneExaminedBy', data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  useEffect(() => {
    fetchSceneExaminedUserOptions();
  }, []);

  const dialogContent = ({ mode, data, onChange }) => {
    const [localData, setLocalData] = useState(data);

    useEffect(() => {
      setLocalData(data);
    }, [data]);

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={investigatorAndMEOptions || []}
            getOptionLabel={option => option.optionName || ''}
            value={localData?.userSeq || null}
            onChange={(event, value) =>
              handleAutocompleteChange(event, value, 'userSeq', localData, setLocalData, onChange)
            }
            renderInput={params => (
              <TextField {...params} label='Scene Examined By' margin='dense' fullWidth required />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label='Date Time'
              value={getDateValue(localData?.dateTime)}
              onChange={value =>
                handleDateChange(value, 'dateTime', localData, setLocalData, onChange)
              }
              slots={{ actionBar: ActionList }}
              ampm={false}
              timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
              clearable
              sx={{ width: '100%' }}
              slotProps={{ textField: { margin: 'dense' } }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    );
  };

  return (
    <DialogCrudDataGrid
      title='Scene Examined By'
      columns={sceneExaminedByColumns}
      rows={formik.values?.caseInvestigations?.discovery?.sceneExaminedBy || []}
      createFunction={handleSceneExaminedBySave}
      updateFunction={handleSceneExaminedBySave}
      deleteFunction={handleSceneExaminedByDelete}
      dialogContent={dialogContent}
    />
  );
};

export default SceneExaminedBy;
