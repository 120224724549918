import { ArrowBackIosNewRounded, CancelRounded, HelpOutlineRounded } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PrintIcon from '@mui/icons-material/Print';
import {
  Alert,
  AlertTitle,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogTitle,
  Divider,
  Pagination,
  Snackbar,
  Tab,
  Tabs,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { usePDF } from '@react-pdf/renderer';
import { useFormik } from 'formik';
import { motion } from 'framer-motion';
import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useOptions } from '../../../utils/api/useOptions.hook';
import { useAuth } from '../../../utils/auth/AuthService';
import { DailyCaseCardSkeleton } from './DailyExamCardSkeleton';
import { DailyExamListDocument } from './DailyExamListPDF';
import { DailyCaseRowSkeleton } from './DailyExamRowSkeleton';
// @ts-ignore
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { TailSpin } from 'react-loader-spinner';
import CasePhotos from '../../../components/CaseRecords/CasePhotos';
import { objExamtypeOptions } from '../../../utils/constants/constants';
import { PrintFileButton } from '../../../utils/functions/triggerPrintDialog';

const { REACT_APP_API_URL } = process.env;

export interface DailyCase {
  caseId: string | null;
  caseSeq: string | null;
  caseStatus: string | null;
  caseSynopsis: string | null;
  caseNotes: SupplementalCaseInfoDetails[];
  inHouseStatus: boolean;
  /**
   * idStatus
   * optionSeq = 000000-0000-0000-0000000000 = Identified
   * optionSeq = 222222-2222-2222-2222222222 = Pending
   */
  isIdentified: boolean;
  isObjection: boolean;
  checkInDate: string | null;
  menotifieddate: string | null;
  decedentName: string | null;
  decedentAge: Age | null;
  decedentRace: Race | null;
  decedentGender: Option | null;
  assignedExam: Option | null;
  examStartDate: string | null;
  assignedPathologist: Option | null;
  assignedMorgueTechs: Option[];
  jurisdiction: Jurisdiction | null;
  createdOn: string;
  lastUpdatedOn: string;
  datasheet: any[] | null;
}

interface Race {
  raceName: string;
  raceSeq: string;
  raceOther: string | null;
}

interface Age {
  ageValue: number | null;
  ageUnit: string | null;
}

interface Jurisdiction {
  jdxCode: string | null;
  jdxName: string | null;
  jdxSeq: string | null;
}

interface Option {
  optionName: string | null;
  optionSeq: string | null;
}

interface SupplementalCaseInfoDetails {
  supplementalCaseInfoDetailsSeq: string;
  supplementalCaseInfoSeq: string;
  supplementalUserSeq: string;
  supplementalCaseNoteTypeSeq: string;
  assignmentTypeSeq: string;
  nextOfKinSeq: string | null;
  supplementalDateTime: string;
  isImportantNotes: boolean;
  supplementalNote: string;
  isActive: boolean;
  isUserDefined: boolean;
}

interface PaginatedDailyExamListResponse {
  cases: DailyCase[];
  totalCount: number;
  currentPage: number;
  totalPages: number;
}

interface AssignCaseRequest {
  caseSeq: string;
  assignedExamTypeSeq: string;
  assignedPathologistSeq: string;
  assignedMorgueTechSeqs: string[];
}

export interface DCLUserJdxPrefences {
  userSeq: string;
  jurisdictions: Jurisdiction[];
}

export const DailyExamListNew = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const {
    options: examOptions,
    loading: examOptionsLoading,
    error: examOptionsError,
  } = useOptions('exam types');

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedJurisdictions, setSelectedJurisdictions] = useState<Jurisdiction[]>([]);
  const [selectedJurisdictionsLoading, setSelectedJurisdictionsLoading] = useState(false);
  const [sortBy, setSortBy] = useState('unassigned');

  const [cases, setCases] = useState<DailyCase[]>([]);
  const [casesLoading, setCasesLoading] = useState(false);
  const [casesError, setCasesError] = useState<null | string>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCases, setTotalCases] = useState(0);
  const [searchedCaseId, setSearchedCaseId] = useState('');

  const AnimatedStack = motion(Stack);
  const AnimatedTypography = motion(Typography);

  const jdxSeqList = useMemo(
    () =>
      selectedJurisdictions.map(jurisdiction => jurisdiction.jdxSeq).filter(Boolean) as string[],
    [selectedJurisdictions]
  );

  const {
    options: pathologistOptions,
    loading: pathologistOptionsLoading,
    error: pathologistOptionsError,
  } = useOptions('pathologist options', { jdxSeq: jdxSeqList, shouldFetch: true });

  const {
    options: morgueTechOptions,
    loading: morgueTechOptionsLoading,
    error: morgueTechOptionsError,
  } = useOptions('morgue tech options', { jdxSeq: jdxSeqList, shouldFetch: true });

  const fetchDailyExamList = async (pageNumber = 1) => {
    try {
      setCasesLoading(true);
      const response = await fetch(REACT_APP_API_URL + `dailyexamlist/all`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user?.token,
        },
        body: JSON.stringify({
          sortBy: sortBy ?? 'assigned',
          userSeq: user?.userSeq,
          jdx: selectedJurisdictions.map(j => j.jdxSeq),
          pageNumber: pageNumber,
          pageSize: 20,
          caseID: searchedCaseId,
        }),
      });

      const data: PaginatedDailyExamListResponse = await response.json();
      setCases(data.cases);
      setTotalPages(data.totalPages);
      setCurrentPage(data.currentPage);
      setTotalCases(data.totalCount);
    } catch (error) {
      setCasesError(`${error}`);
    } finally {
      setCasesLoading(false);
    }
  };

  const fetchDCLUserJdxPreferences = async () => {
    try {
      setSelectedJurisdictionsLoading(true);
      const url = `${REACT_APP_API_URL}user/preferences/getDCLJdxPreferences`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
          Accept: 'application/json',
        },
      });
      const preferences: DCLUserJdxPrefences = await response.json();
      setSelectedJurisdictions(preferences.jurisdictions);
    } catch (error) {
      console.log(error);
    } finally {
      setSelectedJurisdictionsLoading(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        await fetchDCLUserJdxPreferences();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  /**
   * Refetches the daily cases when either Sort By or selected Jurisdictions changes
   */
  useEffect(() => {
    fetchDailyExamList();
  }, [selectedJurisdictions, sortBy, searchedCaseId]);

  /**
   * Updates a case LOCALLY ONLY. This will not update a case with the database. Instead, use assignCase().
   * @param formik
   * @param fieldName
   * @param value
   */
  const updateCase = async <K extends keyof DailyCase>(
    formik: ReturnType<typeof useFormik<DailyCase>>,
    fieldName: K,
    value: DailyCase[K]
  ) => {
    await formik.setFieldValue(fieldName, value);
  };

  /**
   * Assigns/updates a case with the database
   * @param params AssignCaseRequest
   * @returns
   */
  const assignCase = async (params: AssignCaseRequest) => {
    const response = await fetch(`${REACT_APP_API_URL}dailyexamlist/assign`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user?.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...params,
      }),
    });

    return response;
  };

  const handlePageChange = (event: any, newPage: number) => {
    setCurrentPage(newPage);
    fetchDailyExamList(newPage);
  };

  const loading = useMemo(() => {
    return (
      pathologistOptionsLoading || examOptionsLoading || morgueTechOptionsLoading || casesLoading
    );
  }, [pathologistOptionsLoading, examOptionsLoading, morgueTechOptionsLoading, casesLoading]);

  const getFilteredPathologistOptions = rowJdxCode => {
    return pathologistOptions?.filter(option => option.jdxCodes.includes(rowJdxCode));
  };

  const getFilteredMorgueTechOptions = rowJdxCode => {
    return morgueTechOptions?.filter(option => option.jdxCodes.includes(rowJdxCode));
  };

  function ModelHeader({
    handleClosePhotoViewer,
    modelName,
  }: {
    handleClosePhotoViewer: () => void;
    modelName: string;
  }) {
    return (
      <AnimatedStack
        layout
        style={{
          zIndex: 10,
        }}
      >
        <AnimatedStack spacing={1} px={2} pt={2}>
          <Button
            sx={{ width: 'max-content' }}
            variant='text'
            onClick={handleClosePhotoViewer}
            startIcon={<ArrowBackIosNewRounded />}
          >
            Back To Daily Exam List
          </Button>
          <Box component='div' overflow='hidden'>
            <AnimatedTypography
              layout='preserve-aspect'
              variant={'h5'}
              fontWeight={'bold'}
              noWrap
              textOverflow='ellipsis'
              sx={{
                color: theme.palette.text.primary,
              }}
            >
              {`Viewing ${modelName}`}
            </AnimatedTypography>
          </Box>
          <Divider component='hr' variant='fullWidth' />
        </AnimatedStack>
      </AnimatedStack>
    );
  }

  const PhotoViewerContainer = ({
    handleClosePhotoViewer,
    caseId,
    caseSeq,
  }: {
    handleClosePhotoViewer: () => void;
    caseId: string;
    caseSeq: string;
  }) => (
    <motion.div
      layout
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 999,
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        // borderRadius: '12px',
      }}
    >
      <motion.div style={{ position: 'absolute', top: '60px', left: '10px' }}>
        <ModelHeader handleClosePhotoViewer={handleClosePhotoViewer} modelName='Case Photos' />
      </motion.div>

      <div
        style={{
          position: 'absolute',
          top: '150px',
          right: isSmallScreen ? '30px' : '120px',
          bottom: '150px',
          left: isSmallScreen ? '30px' : '120px',
        }}
      >
        <CasePhotos
          caseSeq={caseSeq}
          caseNumber={caseId}
          fullScreen={isSmallScreen ? false : true}
        />
      </div>
    </motion.div>
  );

  const DailyCaseRow = React.memo(({ data, index }: { data: DailyCase; index: number }) => {
    const [open, setOpen] = useState(false);
    const [assigned, setAssigned] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isPhotoViewerOpen, setIsPhotoViewerOpen] = useState(false);

    const formik = useFormik<DailyCase>({
      initialValues: data,
      enableReinitialize: true,
      onSubmit: async values => {
        try {
          const assignedMorgueTechSeqs = values.assignedMorgueTechs
            .map(mt => mt.optionSeq)
            .filter(seq => seq !== null) as string[];

          const response = await assignCase({
            caseSeq: values.caseSeq!,
            assignedExamTypeSeq: values.assignedExam?.optionSeq!,
            assignedPathologistSeq: values.assignedPathologist?.optionSeq!,
            assignedMorgueTechSeqs: assignedMorgueTechSeqs,
          });

          if (response.ok) {
            setAssigned(true);
          } else {
            setError(`${response.statusText}`);
          }
        } catch (err) {
          setError(`${err}`);
        }
      },
    });

    const handleOpenPhotoViewer = () => {
      setIsPhotoViewerOpen(true);
    };

    const handleClosePhotoViewer = () => {
      setIsPhotoViewerOpen(false);
    };

    const formatCreatedOnDate = () => {
      const date = new Date(data.createdOn).toDateString();
      const time = new Date(data.createdOn).toLocaleTimeString();
      return `Created on ${date} at ${time}`;
    };

    const formatLastUpdatedOnDate = () => {
      const date = new Date(data.lastUpdatedOn).toDateString();
      const time = new Date(data.lastUpdatedOn).toLocaleTimeString();
      return `Last updated on ${date} at ${time}`;
    };

    const onAlertClose = () => {
      setAssigned(false);
    };

    const onCaseIdSelect = () => {
      navigate('/caseview', {
        state: { cmscaseid: formik.values.caseId, caseSeq: formik.values.caseSeq },
      });
    };

    const renderDataSheets = (files: any[] | null, caseId: any) => {
      if (Array.isArray(files) && files?.length > 0) {
        return (
          <Stack direction='row' spacing={2} sx={{ mt: '1rem !important' }}>
            {files?.map((file, index) => (
              <PrintFileButton fileSeq={file.fileseq} filename={file.filename} caseid={caseId} />
            ))}
          </Stack>
        );
      } else {
        return null;
      }
    };

    const handleRowClick = (event: any) => {
      if (
        !event.target.classList.contains('MuiAutocomplete-option') &&
        !event.target.classList.contains('MuiAutocomplete-input')
      ) {
        setOpen(!open);
      }
    };

    const isEdited = formik.dirty;
    const isEditedRowStyle = isEdited ? { backgroundColor: 'rgba(255, 255, 0, 0.05)' } : {};
    const isAssignedRowStyle = assigned ? { backgroundColor: 'rgba(144, 238, 144, 0.08)' } : {};
    const chipStyle = { minWidth: '5rem' }; // ensure chips are uniform in width

    // State to control the visibility of each row
    const [show, setShow] = useState(false);

    useEffect(() => {
      // Set a timeout based on the index to stagger the animation
      const timeoutId = setTimeout(() => {
        setShow(true);
      }, index * 5000); // Adjust the multiplier (300ms here) to control the delay between each row

      return () => clearTimeout(timeoutId);
    }, [index]);

    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
      setIsMounted(true);
    }, []);

    return (
      <React.Fragment key={`daily-case-row-${data.caseSeq}`}>
        <Snackbar
          open={assigned}
          autoHideDuration={3000}
          onClose={onAlertClose}
          onClick={onAlertClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity='success' sx={{ width: '100%' }}>
            <AlertTitle>
              {!formik.values.assignedPathologist?.optionName && sortBy === 'assigned' ? 'UnAssigned' : 'Assigned'} <strong>{formik.values.caseId}</strong>:
            </AlertTitle>
            <ul>
              <li>
                Exam: <strong>{formik.values.assignedExam?.optionName ?? 'None'}</strong>
              </li>
              <li>
                Pathologist: <strong>{formik.values.assignedPathologist?.optionName ?? 'None'}</strong>
              </li>
              <li>
                Forensic Techs:
                <strong>
                  {formik.values.assignedMorgueTechs.length > 0 ? (
                    <ul>
                      {formik.values.assignedMorgueTechs.map(mt => (
                        <li>{mt.optionName}</li>
                      ))}
                    </ul>
                  ) : (
                    ' None'
                  )}
                </strong>
              </li>
            </ul>
          </Alert>
        </Snackbar>
        <Snackbar
          open={Boolean(error)}
          autoHideDuration={3000}
          onClose={onAlertClose}
          onClick={onAlertClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity='error' sx={{ width: '100%' }}>
            <AlertTitle>
              Error assigning <strong>{formik.values.caseId}</strong>:
            </AlertTitle>
            {error}
          </Alert>
        </Snackbar>
        <TableRow
          onClick={handleRowClick}
          sx={{
            '& > *': { borderBottom: 'unset', ...isEditedRowStyle, ...isAssignedRowStyle },
          }}
        >
          <TableCell sx={{ pl: 3, pr: 1, py: 1, width: 0 }} align='left'>
            <Typography sx={{ width: 'max-content' }}>
              {formik.values?.caseId ? (
                <Button
                  variant='text'
                  sx={{
                    fontSize: theme.typography.body1,
                    fontWeight: 'bold',
                    color: 'primary',
                  }}
                  onClick={onCaseIdSelect}
                >
                  {formik.values.caseId}
                </Button>
              ) : (
                <b>Unknown Case ID</b>
              )}
            </Typography>
          </TableCell>
          <TableCell sx={{ px: 1, py: 1, width: 0 }} align='left'>
            <Typography sx={{ width: 'max-content' }}>
              {formik.values?.decedentName ?? 'Unknown Decedent'}
            </Typography>
          </TableCell>
          <TableCell sx={{ px: 1, py: 1, width: 0 }} align='left'>
            <Stack direction='row' spacing={1}>
              {data.isIdentified ? (
                <Chip
                  label='Identified'
                  color='success'
                  variant='outlined'
                  size='small'
                  sx={chipStyle}
                />
              ) : (
                <Chip
                  label='Pending'
                  color='warning'
                  variant='outlined'
                  size='small'
                  sx={chipStyle}
                />
              )}
              {formik.values.inHouseStatus && (
                <Tooltip title={`Checked in ${data.checkInDate}`}>
                  <Chip
                    label='In-House'
                    color='primary'
                    variant='outlined'
                    size='small'
                    sx={chipStyle}
                  />
                </Tooltip>
              )}

              {formik.values.isObjection && (
                <Chip label='OBJ' color='error' size='small' sx={chipStyle} />
              )}
            </Stack>
          </TableCell>
          <TableCell sx={{ pl: 5, pr: 0, py: 1 }} align='right'>
            <Autocomplete
              options={
                formik.values?.isObjection
                  ? examOptions?.filter(option =>
                      objExamtypeOptions
                        .map(seq => seq.toLowerCase())
                        .includes(option?.optionSeq?.toLowerCase())
                    )
                  : examOptions
              }
              loading={examOptions.length === 0}
              fullWidth
              size='small'
              value={formik.values.assignedExam}
              onChange={async (e, value) => {
                await updateCase(formik, 'assignedExam', {
                  optionName: value?.optionName ?? null,
                  optionSeq: value?.optionSeq ?? null,
                });
              }}
              getOptionLabel={option => option.optionName ?? ''}
              isOptionEqualToValue={(option, value) =>
                option.optionSeq?.toLocaleLowerCase() === value.optionSeq?.toLocaleLowerCase()
              }
              renderInput={params => <TextField {...params} label='Exam' />}
            />
          </TableCell>
          <TableCell sx={{ px: 1, py: 1 }} align='right'>
            <Autocomplete
              options={getFilteredPathologistOptions(formik.values.jurisdiction?.jdxCode)}
              loading={pathologistOptions.length === 0}
              fullWidth
              size='small'
              value={formik.values.assignedPathologist}
              onChange={async (e, value) => {
                await updateCase(formik, 'assignedPathologist', {
                  optionName: value?.optionName ?? null,
                  optionSeq: value?.optionSeq ?? null,
                });
              }}
              getOptionLabel={option => option.optionName ?? ''}
              isOptionEqualToValue={(option, value) => {
                return (
                  option.optionSeq?.toLocaleLowerCase() === value.optionSeq?.toLocaleLowerCase()
                );
              }}
              renderInput={params => <TextField {...params} label='Pathologist' />}
            />
          </TableCell>
          <TableCell sx={{ pl: 0, pr: 5, py: 1 }} align='right'>
            <Autocomplete
              options={getFilteredMorgueTechOptions(formik.values.jurisdiction?.jdxCode)}
              loading={morgueTechOptions.length === 0}
              multiple={true}
              fullWidth
              size='small'
              value={formik.values.assignedMorgueTechs}
              onChange={async (e, value) => {
                await updateCase(formik, 'assignedMorgueTechs', value!);
              }}
              key={`assigned-mt-${data.caseSeq}`}
              getOptionLabel={option => {
                if (option) {
                  if (option.optionName) {
                    return option.optionName;
                  } else return '';
                } else return '';
              }}
              isOptionEqualToValue={(option, value) => option?.optionSeq === value?.optionSeq}
              renderInput={params => <TextField {...params} label='Forensic Techs' />}
            />
          </TableCell>
          <TableCell sx={{ px: 1, py: 1, width: 0 }} align='right'>
            <Button
              disabled={loading
                // !formik.values.assignedExam?.optionSeq ||
                // sortBy !== 'assigned' &&
                // (!formik.values.assignedPathologist?.optionSeq || loading)
              }
              onClick={e => formik.handleSubmit()}
            >
              Save
            </Button>
          </TableCell>
          <TableCell sx={{ pl: 1, pr: 3, py: 1, width: 0 }} align='right'>
            <IconButton size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ p: 0 }} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{ p: 3 }} component='div'>
                <Stack direction='row' spacing={2} sx={{ width: '100%' }}>
                  <Stack direction='column' spacing={1} sx={{ flex: '0 0 15%' }}>
                    <Typography variant='body2'>
                      Age: <b>{formik.values.decedentAge?.ageValue ?? 'Unknown'}</b>
                    </Typography>
                    <Typography variant='body2'>
                      Race: <b>{formik.values.decedentRace?.raceName ?? 'Unknown'}</b>
                    </Typography>
                    <Typography variant='body2'>
                      Gender: <b>{formik.values.decedentGender?.optionName ?? 'Unknown'}</b>
                    </Typography>
                  </Stack>
                  <Divider flexItem orientation='vertical' sx={{ opacity: 1 }} />
                  <Stack direction='column' spacing={1} sx={{ flex: '0 0 40%' }}>
                    <Typography variant='body1'>Synopsis: </Typography>
                    <Typography variant='body2'>
                      <b>{formik.values?.caseSynopsis ?? 'None'}</b>
                    </Typography>

                    <Button
                      onClick={handleOpenPhotoViewer}
                      size='small'
                      color='primary'
                      variant='outlined'
                      sx={{ mt: '1rem !important', alignSelf: 'start', width: 'auto' }}
                    >
                      View Photos
                    </Button>

                    {renderDataSheets(formik.values?.datasheet, formik.values?.caseId)}
                  </Stack>

                  <Divider flexItem orientation='vertical' sx={{ opacity: 1 }} />
                  <Stack direction='column' spacing={1} sx={{ flex: '0 0 40%' }}>
                    <Typography variant='body1'>Notes: </Typography>
                    <Typography variant='body2'>
                      {data.caseNotes?.length > 0 ? (
                        <ul>
                          {data.caseNotes?.map(note => (
                            <li>{note.supplementalNote}</li>
                          ))}
                        </ul>
                      ) : (
                        <b>None</b>
                      )}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction='row'
                  spacing={2}
                  alignItems='center'
                  pt={2}
                  sx={{
                    opacity: 0.8,
                  }}
                >
                  <Typography variant='caption'>
                    <Chip label={data.jurisdiction?.jdxName} size='small' variant='outlined' />
                  </Typography>

                  <Typography variant='caption'>{formatCreatedOnDate()}</Typography>
                  <Typography variant='caption'>{formatLastUpdatedOnDate()}</Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>

          {isPhotoViewerOpen && (
            <>
              <PhotoViewerContainer
                handleClosePhotoViewer={handleClosePhotoViewer}
                caseId={data?.caseId || ''}
                caseSeq={data?.caseSeq || ''}
              />
            </>
          )}
        </TableRow>
      </React.Fragment>
    );
  });

  const DailyCaseCard = React.memo(({ data }: { data: DailyCase }) => {
    const [open, setOpen] = useState(false);
    const [assigned, setAssigned] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isPhotoViewerOpen, setIsPhotoViewerOpen] = useState(false);

    const formik = useFormik<DailyCase>({
      initialValues: data,
      enableReinitialize: true,
      onSubmit: async values => {
        try {
          const assignedMorgueTechSeqs = values.assignedMorgueTechs
            .map(mt => mt.optionSeq)
            .filter(seq => seq !== null) as string[];

          const response = await assignCase({
            caseSeq: values.caseSeq!,
            assignedExamTypeSeq: values.assignedExam?.optionSeq!,
            assignedPathologistSeq: values.assignedPathologist?.optionSeq!,
            // @ts-ignore
            assignedMorgueTechSeqs: assignedMorgueTechSeqs,
          });

          if (response.ok) {
            setAssigned(true);
          } else {
            setError(`${response.statusText}`);
          }
        } catch (err) {
          setError(`${err}`);
        }
      },
    });

    const handleOpenPhotoViewer = () => {
      setIsPhotoViewerOpen(true);
    };

    const handleClosePhotoViewer = () => {
      setIsPhotoViewerOpen(false);
    };

    const formatCreatedOnDate = () => {
      const date = new Date(data.createdOn).toDateString();
      const time = new Date(data.createdOn).toLocaleTimeString();
      return `Created on ${date} at ${time}`;
    };

    const formatLastUpdatedOnDate = () => {
      const date = new Date(data.lastUpdatedOn).toDateString();
      const time = new Date(data.lastUpdatedOn).toLocaleTimeString();
      return `Last updated on ${date} at ${time}`;
    };

    const onAlertClose = () => {
      setAssigned(false);
    };

    const onCaseIdSelect = () => {
      navigate('/caseview', {
        state: { cmscaseid: formik.values.caseId, caseSeq: formik.values.caseSeq },
      });
    };

    const renderDataSheets = (files: any[] | null, caseId: any) => {
      if (Array.isArray(files) && files?.length > 0) {
        return (
          <Stack direction='row' spacing={2} sx={{ mt: '1rem !important' }}>
            {files?.map((file, index) => (
              <PrintFileButton fileSeq={file.fileseq} filename={file.filename} caseid={caseId} />
            ))}
          </Stack>
        );
      } else {
        return null;
      }
    };

    const handleCardClick = (event: any) => {
      if (
        !event.target.classList.contains('MuiAutocomplete-option') &&
        !event.target.classList.contains('MuiAutocomplete-input') &&
        !event.target.classList.contains('MuiButton-text')
      ) {
        setOpen(!open);
      }
    };

    const isEdited = formik.dirty;
    const isEditedRowStyle = isEdited ? { backgroundColor: 'rgba(255, 255, 0, 0.05)' } : {};
    const isAssignedRowStyle = assigned ? { backgroundColor: 'rgba(144, 238, 144, 0.08)' } : {};

    return (
      <React.Fragment>
        <Snackbar
          open={assigned}
          autoHideDuration={3000}
          onClose={onAlertClose}
          onClick={onAlertClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity='success' sx={{ width: '100%' }}>
            <AlertTitle>
              {!formik.values.assignedPathologist?.optionName && sortBy === 'assigned' ? 'UnAssigned' : 'Assigned'} <strong>{formik.values.caseId}</strong>:
            </AlertTitle>
            <ul>
              <li>
                Exam: <strong>{formik.values.assignedExam?.optionName ?? ' None'}</strong>
              </li>
              <li>
                Pathologist: <strong>{formik.values.assignedPathologist?.optionName ?? 'None'}</strong>
              </li>
              <li>
                Forensic Tech: 
                <strong>
                  {formik.values.assignedMorgueTechs?.length > 0 ? (
                    <ul>
                      {formik.values.assignedMorgueTechs?.map(mt => (
                        <li>{mt.optionName}</li>
                      ))}
                    </ul>
                  ) : (
                    ' None'
                  )}
                </strong>
              </li>
            </ul>
          </Alert>
        </Snackbar>
        <Snackbar
          open={Boolean(error)}
          autoHideDuration={3000}
          onClose={onAlertClose}
          onClick={onAlertClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity='error' sx={{ width: '100%' }}>
            <AlertTitle>
              Error assigning <strong>{formik.values.caseId}</strong>:
            </AlertTitle>
            {error}
          </Alert>
        </Snackbar>
        <Card
          onClick={handleCardClick}
          sx={{ minWidth: 275, my: 1, ...isEditedRowStyle, ...isAssignedRowStyle }}
        >
          <CardContent>
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              sx={{ flexGrow: 1, gap: 1, flexWrap: 'wrap' }}
            >
              <Typography component='div'>
                {formik.values?.caseId ? (
                  <Button
                    variant='text'
                    sx={{
                      color: 'primary',
                      fontSize: 14,
                    }}
                    onClick={onCaseIdSelect}
                  >
                    {formik.values.caseId}
                  </Button>
                ) : (
                  <b>Unknown Case ID</b>
                )}
              </Typography>
              {data.inHouseStatus && (
                <Tooltip title={`Checked in ${data.checkInDate}`}>
                  <Chip label='In-House' color='primary' variant='outlined' size='small' />
                </Tooltip>
              )}
              {data.isIdentified ? (
                <Chip label='Identified' color='success' variant='outlined' size='small' />
              ) : (
                <Chip label='Pending' color='warning' variant='outlined' size='small' />
              )}

              {data.isObjection && <Chip label='OBJ' color='error' size='small' />}

              <Typography variant='h5' component='div' sx={{ wordBreak: 'break-word' }}>
                {data.decedentName ?? 'Unknown Decedent'}
              </Typography>
            </Stack>

            <Stack spacing={2} sx={{ pt: 2 }}>
              <Autocomplete
                options={
                  formik.values?.isObjection
                    ? examOptions?.filter(option =>
                        objExamtypeOptions
                          .map(seq => seq.toLowerCase())
                          .includes(option?.optionSeq?.toLowerCase())
                      )
                    : examOptions
                }
                fullWidth
                size='small'
                value={formik.values.assignedExam}
                onChange={(e, value) => formik.setFieldValue('assignedExam', value)}
                getOptionLabel={option => option.optionName ?? ''}
                isOptionEqualToValue={(option, value) =>
                  option.optionSeq?.toLocaleLowerCase() === value.optionSeq?.toLocaleLowerCase()
                }
                renderInput={params => <TextField {...params} label='Exam' />}
              />
              <Autocomplete
                options={getFilteredPathologistOptions(formik.values.jurisdiction?.jdxCode)}
                fullWidth
                size='small'
                value={formik.values.assignedPathologist}
                onChange={(e, value) => formik.setFieldValue('assignedPathologist', value)}
                getOptionLabel={option => option.optionName ?? ''}
                isOptionEqualToValue={(option, value) =>
                  option.optionSeq?.toLocaleLowerCase() === value.optionSeq?.toLocaleLowerCase()
                }
                renderInput={params => <TextField {...params} label='Pathologist' />}
              />
              <Autocomplete
                options={getFilteredMorgueTechOptions(formik.values.jurisdiction?.jdxCode)}
                fullWidth
                size='small'
                multiple={true}
                // @ts-ignore
                value={formik.values.assignedMorgueTechs}
                onChange={async (e, value) => {
                  await updateCase(formik, 'assignedMorgueTechs', value!);
                }}
                getOptionLabel={option => option.optionName ?? ''}
                isOptionEqualToValue={(option, value) =>
                  option.optionSeq?.toLocaleLowerCase() === value.optionSeq?.toLocaleLowerCase()
                }
                renderInput={params => <TextField {...params} label='Forensic Tech' />}
              />
            </Stack>

            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{ p: 2 }} component='div'>
                <Stack direction='column' spacing={2}>
                  <Stack direction='column' spacing={2} sx={{ width: '20rem' }}>
                    <Typography>
                      Age:
                      <b>
                        {data.decedentAge?.ageValue ?? 'Unknown'} {data.decedentAge?.ageUnit}
                      </b>
                    </Typography>
                    <Typography>
                      Race: <b>{data.decedentRace?.raceName ?? 'Unknown'}</b>
                    </Typography>
                    <Typography>
                      Gender: <b>{data.decedentGender?.optionName ?? 'Unknown'}</b>
                    </Typography>
                  </Stack>
                  <Divider flexItem orientation='horizontal' sx={{ opacity: 1 }} />
                  <Stack>
                    <Typography variant='body1'>Synopsis: </Typography>
                    <Typography variant='body2'>
                      <b>{data.caseSynopsis ?? 'None'}</b>
                    </Typography>

                    <Button
                      onClick={handleOpenPhotoViewer}
                      size='small'
                      color='primary'
                      variant='outlined'
                      sx={{ mt: '1rem !important', alignSelf: 'start', width: 'auto' }}
                    >
                      View Photos
                    </Button>

                    {renderDataSheets(formik.values?.datasheet, formik.values?.caseId)}
                  </Stack>
                  <Divider flexItem orientation='horizontal' sx={{ opacity: 1 }} />
                  <Stack>
                    <Typography variant='body1'>Notes: </Typography>
                    <Typography variant='body2'>
                      {data.caseNotes?.length > 0 ? (
                        <ul>
                          {data.caseNotes?.map(note => (
                            <li>{note.supplementalNote}</li>
                          ))}
                        </ul>
                      ) : (
                        <b>None</b>
                      )}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction='row'
                  spacing={2}
                  alignItems='center'
                  pt={2}
                  sx={{
                    opacity: 0.8,
                  }}
                >
                  <Typography variant='caption'>
                    <Chip label={data.jurisdiction?.jdxName} size='small' variant='outlined' />
                  </Typography>

                  <Typography variant='caption'>{formatCreatedOnDate()}</Typography>
                  <Typography variant='caption'>{formatLastUpdatedOnDate()}</Typography>
                </Stack>
              </Box>
            </Collapse>
            {isPhotoViewerOpen && (
              <>
                <PhotoViewerContainer
                  handleClosePhotoViewer={handleClosePhotoViewer}
                  caseId={data?.caseId || ''}
                  caseSeq={data?.caseSeq || ''}
                />
              </>
            )}
          </CardContent>
          <CardActions>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <Button
                  onClick={() => formik.handleSubmit()}
                  size='small'
                  sx={{ ml: 1.5, fontSize: 10 }}
                  disabled={loading
                    // !formik.values.assignedExam ||
                    // sortBy !== 'assigned' &&
                    // (!formik.values.assignedPathologist || loading)
                  }
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <IconButton size='small' onClick={() => setOpen(!open)} sx={{ mr: 1.5 }}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </React.Fragment>
    );
  });

  // const JurisdictionSelect = () => {
  //   const [temporarySelections, setTemporarySelections] = useState(selectedJurisdictions);

  //   const sortJurisdictionsAlphabetically = (jurisdictions: Jurisdiction[]) => {
  //     return jurisdictions.sort((a, b) => {
  //       if (a.jdxName && b.jdxName) {
  //         return a.jdxName.localeCompare(b.jdxName);
  //       }
  //       return 0;
  //     });
  //   };

  //   return (
  //     <Autocomplete
  //       disableCloseOnSelect={true}
  //       multiple={true}
  //       options={sortJurisdictionsAlphabetically(user?.jdxAccessList ?? [])}
  //       size='small'
  //       value={temporarySelections}
  //       limitTags={4}
  //       fullWidth
  //       onChange={(e, selected) => setTemporarySelections(selected)}
  //       onBlur={e => setSelectedJurisdictions(temporarySelections)}
  //       onClose={e => setSelectedJurisdictions(temporarySelections)}
  //       isOptionEqualToValue={(option, value) => option.jdxSeq === value.jdxSeq}
  //       getOptionLabel={option => String(option.jdxName)}
  //       renderInput={params => (
  //         <TextField
  //           {...params}
  //           label={
  //             selectedJurisdictions.length === user?.jdxAccessList.length
  //               ? ' All Jurisdictions'
  //               : 'Jurisdictions'
  //           }
  //         />
  //       )}
  //       sx={{
  //         minWidth: { xs: 0, lg: '12rem' },
  //         width: { xs: '100%', lg: 'max-content' },
  //       }}
  //     />
  //   );
  // };

  // const instance = DailyExamListPDF({ cases });

  return (
    <React.Fragment>
      {/* {!instance.loading && (
        <PDFViewer width='100%' height='auto'>
          <DailyExamListDocument cases={cases} />
        </PDFViewer>
      )} */}
      <Box mb={2} component='div'>
        {casesError && <DataLoadingError message={casesError} />}
        {pathologistOptionsError && <DataLoadingError message={pathologistOptionsError} />}
        {morgueTechOptionsError && <DataLoadingError message={morgueTechOptionsError} />}
        {examOptionsError && <DataLoadingError message={examOptionsError} />}
      </Box>

      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 100,
          boxSizing: 'border-box',
          margin: 0,
          pt: 2,
          pb: {
            xs: 2,
            lg: 0,
          },
          px: 2,
          backgroundColor: theme.palette.background.default,
          boxShadow: {
            lg: 'none',
            xs: '0px 2px 4px -1px rgba(0,0,0,0.2)',
          },
        }}
        component='div'
      >
        <Filters
          searchedCaseId={searchedCaseId}
          setSearchedCaseId={setSearchedCaseId}
          selectedJurisdictions={selectedJurisdictions}
          setSelectedJurisdictions={setSelectedJurisdictions}
          loading={loading}
          setSortBy={setSortBy}
          sortBy={sortBy}
        />
      </Box>

      <Box sx={{ margin: 2 }} component='div'>
        {!loading && cases.length === 0 && <Typography variant='body2'>No cases found</Typography>}
        {loading ? (
          <Box component={'div'} sx={{ display: { xs: 'none', lg: 'block' } }}>
            <TableContainer sx={{ display: { xs: 'none', lg: 'block' } }} component={Paper}>
              <Table>
                <TableBody>
                  <DailyCaseRowSkeleton />
                </TableBody>
              </Table>
            </TableContainer>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <TailSpin color='#00BFFF' height={20} width={20} />
              <i style={{ color: '#00BFFF', paddingLeft: 10 }}>
                Loading Daily Exam List, Please wait....
              </i>
            </div>
          </Box>
        ) : (
          <TableContainer sx={{ display: { xs: 'none', lg: 'block' } }} component={Paper}>
            <Table>
              <TableBody>
                {cases.map((c, i) => (
                  <DailyCaseRow data={c} index={i} key={`daily-case-row-${c.caseSeq}`} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {loading ? (
          <Stack sx={{ display: { xs: 'block', lg: 'none' } }} spacing={2}>
            <Table>
              <TableBody>
                <DailyCaseCardSkeleton />
              </TableBody>
            </Table>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <TailSpin color='#00BFFF' height={20} width={20} />
              <i style={{ color: '#00BFFF', paddingLeft: 10 }}>
                Loading Daily Exam List, Please wait....
              </i>
            </div>
          </Stack>
        ) : (
          <Stack sx={{ display: { xs: 'block', lg: 'none' } }} spacing={2}>
            <Table>
              <TableBody>
                {cases.map(c => (
                  <DailyCaseCard data={c} key={`daily-case-card-${c.caseSeq}`} />
                ))}
              </TableBody>
            </Table>
          </Stack>
        )}
        <Box
          sx={{ my: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          component='div'
        >
          <Typography variant='caption'>{totalCases} cases</Typography>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }} component='div'>
            <Pagination
              disabled={loading}
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color='primary'
            />
          </Box>
        </Box>
        <CantFindCase />
      </Box>
    </React.Fragment>
  );
};

function DataLoadingError({ message }: { message?: string }) {
  return (
    <Alert severity='error'>
      <AlertTitle>Error loading data, please try again later</AlertTitle>
      {message}
    </Alert>
  );
}

function CantFindCaseModal() {
  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }
  return (
    <React.Fragment>
      <IconButton color='primary' onClick={() => setOpen(true)}>
        <Tooltip arrow title='More information about the Daily Exam List'>
          <HelpOutlineRounded />
        </Tooltip>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Daily Exam List</DialogTitle>

        <Stack component={Paper} p={3.1}>
          <Box ml={1} component='div'>
            <Typography variant='subtitle1'>For a case to be listed here, it must:</Typography>
            <Typography variant='subtitle1'>
              <ul>
                <li>
                  Be an <b>active</b> case
                </li>
                <li>
                  Have an <b>Accepted</b> ME Action
                </li>
                <li>
                  Not have a completed <b>Exam</b>
                </li>
              </ul>
            </Typography>
            <Stack>
              <Typography variant='subtitle1'>Chip legend:</Typography>
              <ul>
                <li>
                  <Typography variant='subtitle1'>
                    For a case to be
                    <Chip
                      label='In-House'
                      color='primary'
                      variant='outlined'
                      size='small'
                      sx={{ mx: 1 }}
                    />
                    it must have a <b>Check-In Date</b>
                  </Typography>
                </li>
                <li>
                  <Typography variant='subtitle1'>
                    For a case to be
                    <Chip
                      label='Identified'
                      color='success'
                      variant='outlined'
                      size='small'
                      sx={{ mx: 1 }}
                    />
                    it must have <b>at least 1 </b> confirmed identification. Otherwise it will be
                    <Chip
                      label='Pending'
                      color='warning'
                      variant='outlined'
                      size='small'
                      sx={{ mx: 1 }}
                    />
                  </Typography>
                </li>
                <li>
                  <Typography variant='caption'>
                    For a case to be labeled with
                    <Chip label='OBJ' color='error' size='small' sx={{ mx: 1 }} />
                    it must have <b>at least 1 objector</b>.
                  </Typography>
                </li>
                <li>
                  <Typography variant='subtitle1'>
                    For a case to appear under <b>Assigned</b>, it must have an{' '}
                    <b>Assigned Pathologist </b>
                  </Typography>
                </li>
              </ul>
            </Stack>
          </Box>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}

function CantFindCase() {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <Button variant='text' onClick={() => setOpen(!open)} sx={{ mt: 2, opacity: 0.8 }}>
        <Stack direction='row' spacing={2} alignItems='center'>
          <HelpOutlineRounded />
          <Typography variant='caption'>Why isn't a case listed here?</Typography>
        </Stack>
      </Button>
      <Box ml={1} component='div'>
        <Collapse in={open}>
          <Typography variant='caption'>For a case to be listed here, it must:</Typography>
          <Typography variant='caption'>
            <ul>
              <li>
                Be an <b>active</b> case
              </li>
              <li>
                Have an <b>Accepted</b> ME Action
              </li>
              <li>
                Not have a completed <b>Exam</b>
              </li>
            </ul>
          </Typography>
          <Stack spacing={1}>
            <Typography variant='caption'>
              For a case to be
              <Chip
                label='In-House'
                color='primary'
                variant='outlined'
                size='small'
                sx={{ mx: 1 }}
              />
              it must have a <b>Check-In Date</b>
            </Typography>
            <Typography variant='caption'>
              For a case to be
              <Chip
                label='Identified'
                color='success'
                variant='outlined'
                size='small'
                sx={{ mx: 1 }}
              />
              it must have <b>at least 1 </b> confirmed identification. Otherwise it will be
              <Chip
                label='Pending'
                color='warning'
                variant='outlined'
                size='small'
                sx={{ mx: 1 }}
              />
            </Typography>
            <Typography variant='caption'>
              For a case to be labeled with
              <Chip label='OBJ' color='error' size='small' sx={{ mx: 1 }} />
              it must have <b>at least 1 objector</b>.
            </Typography>
            <Typography variant='caption'>
              For a case to appear under <b>Assigned</b>, it must have an{' '}
              <b>Assigned Pathologist </b>
            </Typography>
          </Stack>
        </Collapse>
      </Box>
    </React.Fragment>
  );
}

const JurisdictionSelect = ({
  selectedJurisdictions = [],
  setSelectedJurisdictions,
}: {
  selectedJurisdictions: Jurisdiction[];
  setSelectedJurisdictions: React.Dispatch<React.SetStateAction<Jurisdiction[]>>;
}) => {
  const { user } = useAuth();
  const [temporarySelections, setTemporarySelections] = useState<Jurisdiction[]>([]);

  const sortJurisdictionsAlphabetically = (jurisdictions: Jurisdiction[]) => {
    return jurisdictions.sort((a, b) => {
      if (a.jdxName && b.jdxName) {
        return a.jdxName.localeCompare(b.jdxName);
      }
      return 0;
    });
  };

  const saveDCLUserJurisdictions = async (jurisdictions: Jurisdiction[]) => {
    try {
      const url = `${REACT_APP_API_URL}user/preferences/dcl/savejurisdictions`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jurisdictions),
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setTemporarySelections(selectedJurisdictions);
  }, [selectedJurisdictions]);

  return (
    <Autocomplete
      disableCloseOnSelect={true}
      multiple={true}
      options={sortJurisdictionsAlphabetically(user?.jdxAccessList ?? [])}
      size='small'
      value={temporarySelections}
      limitTags={4}
      fullWidth
      onChange={(e, selected) => {
        setTemporarySelections(selected);
        saveDCLUserJurisdictions(selected);
      }}
      onBlur={e => setSelectedJurisdictions(temporarySelections)}
      onClose={e => setSelectedJurisdictions(temporarySelections)}
      isOptionEqualToValue={(option, value) => option.jdxSeq === value.jdxSeq}
      getOptionLabel={option => String(option.jdxName)}
      renderInput={params => <TextField {...params} label={null} />}
      renderTags={(v, getTagProps, ownerState) => {
        return v.map((option, index) => {
          return (
            <Chip
              {...getTagProps({ index })}
              sx={{
                height: 'auto',
                '& .MuiChip-label': {
                  display: 'flex',
                  alignItems: 'center',
                },
                '& .MuiChip-deleteIcon': {
                  fontSize: '16px',
                },
              }}
              label={option.jdxName}
              deleteIcon={<CancelRounded />}
            />
          );
        });
      }}
      renderOption={(props, option, state, ownerState) => {
        return (
          <li {...props}>
            <Checkbox
              icon={<MdCheckBoxOutlineBlank />}
              checkedIcon={<MdOutlineCheckBox />}
              style={{ marginRight: 8 }}
              checked={state.selected}
              onChange={event => event.stopPropagation()}
            />
            {String(option?.jdxName)}
          </li>
        );
      }}
    />
  );
};

const Filters = ({
  selectedJurisdictions = [],
  setSelectedJurisdictions,
  searchedCaseId,
  setSearchedCaseId,
  sortBy,
  setSortBy,
  loading = true,
}: {
  selectedJurisdictions: Jurisdiction[];
  setSelectedJurisdictions: React.Dispatch<React.SetStateAction<Jurisdiction[]>>;
  searchedCaseId;
  setSearchedCaseId;
  sortBy;
  setSortBy;
  loading: boolean;
}) => {
  return (
    <Stack spacing={1}>
      <Stack
        direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
        spacing={2}
        alignContent='center'
      >
        <JurisdictionSelect
          selectedJurisdictions={selectedJurisdictions}
          setSelectedJurisdictions={setSelectedJurisdictions}
        />
        <Stack direction='row' spacing={1} alignItems='center' sx={{ flexGrow: 1, gap: 0 }}>
          <CaseIdSearch searchedCaseId={searchedCaseId} setSearchedCaseId={setSearchedCaseId} />
          <PrintButton jdxList={selectedJurisdictions} sortBy={sortBy} />
          <CantFindCaseModal />
        </Stack>
      </Stack>
      <SortByAssignmentTabs sortBy={sortBy} setSortBy={setSortBy} loading={loading} />
    </Stack>
  );
};

const CaseIdSearch = ({ searchedCaseId, setSearchedCaseId }) => {
  const [tempSearchedCaseId, setTempSearchedCaseId] = useState(searchedCaseId);

  const handleSearchChange = (inputValue: string) => {
    setTempSearchedCaseId(inputValue);
  };

  const handleBlur = () => {
    setSearchedCaseId(tempSearchedCaseId);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setSearchedCaseId(tempSearchedCaseId);
    }
  };

  return (
    <TextField
      label='Search Case ID'
      name='searchCaseID'
      type='text'
      value={tempSearchedCaseId}
      onChange={e => handleSearchChange(e.target.value)}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      sx={{
        minWidth: { xs: 0, lg: '10rem' },
        width: {
          xs: '100%',
          lg: 'auto',
        },
      }}
    />
  );
};

const PrintButton = ({
  isSmallScreen = false,
  casesLoading = true,
  jdxList = [],
  sortBy = 'assigned',
}) => {
  const { user } = useAuth();
  const allAssignedCases = useRef<DailyCase[]>([]);
  const [allAssignedCasesLoading, setAllAssignedCasesLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [instance, updateInstance] = usePDF({
    document: (
      <DailyExamListDocument
        cases={allAssignedCases.current}
        requestingUser={user}
        jdxList={jdxList}
        sortBy={sortBy}
      />
    ),
  });

  const printStatusRef = useRef({ readyToPrint: false, printed: false });

  const fetchDailyExamList_AssignedOnly = async (pageNumber = 1) => {
    try {
      setError(null);
      setAllAssignedCasesLoading(true);
      const response = await fetch(REACT_APP_API_URL + `dailyexamlist/all`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user?.token,
        },
        body: JSON.stringify({
          sortBy: sortBy,
          userSeq: user?.userSeq,
          jdx: jdxList.map(jdx => jdx.jdxSeq),
          pageNumber: pageNumber,
          pageSize: 500,
        }),
      });

      const data: PaginatedDailyExamListResponse = await response.json();
      allAssignedCases.current = data.cases;
    } catch (error) {
      setError(`${error}`);
    } finally {
      setAllAssignedCasesLoading(false);
    }
  };

  const handleButtonClick = async () => {
    await fetchDailyExamList_AssignedOnly();
    updateInstance(
      <DailyExamListDocument
        cases={allAssignedCases.current}
        requestingUser={user}
        jdxList={jdxList}
        sortBy={sortBy}
      />
    );
    // Mark as ready to print but not printed yet
    printStatusRef.current = { readyToPrint: true, printed: false };
  };

  // Effect to handle printing after instance is updated
  useEffect(() => {
    if (
      printStatusRef.current.readyToPrint &&
      !printStatusRef.current.printed &&
      !instance.loading &&
      instance.blob
    ) {
      handlePrint();
      // Mark as printed to avoid duplicate printing
      printStatusRef.current.printed = true;
    }
  }, [instance]); // Depend on instance to re-run when it updates

  const handlePrint = () => {
    // Use the caseSummary.case_number to create a file name
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = currentDate.getFullYear();

    const fileName = `Daily-Case-Sheet-${user?.userName}-${day}-${month}-${year}-${sortBy}.pdf`;

    if (instance.blob) {
      // Create a blob URL from the PDF blob
      const blobUrl = URL.createObjectURL(instance.blob);

      // Create a temporary link to trigger the download
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click(); // Simulate a click to trigger the download
      document.body.removeChild(link);

      // Now that the file is downloaded, open the print dialog
      let iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);

      iframe.onload = () => {
        try {
          if (iframe.contentWindow) {
            iframe.contentWindow.print();
          }
        } catch (e) {
          console.error("Couldn't print the PDF", e);
        }
      };

      iframe.onclose = () => {
        document.body.removeChild(iframe);
        URL.revokeObjectURL(blobUrl); // Free up memory
        printStatusRef.current = { readyToPrint: false, printed: false };
      };
    }

    printStatusRef.current = { readyToPrint: false, printed: false };
  };

  return (
    <React.Fragment>
      {isSmallScreen ? (
        <Button
          disabled={allAssignedCasesLoading}
          onClick={handleButtonClick}
          variant='text'
          sx={{ px: 3 }}
        >
          {allAssignedCasesLoading ? <CircularProgress size={16} color='primary' /> : <PrintIcon />}
        </Button>
      ) : (
        <Button
          disabled={allAssignedCasesLoading}
          onClick={handleButtonClick}
          variant='text'
          startIcon={
            allAssignedCasesLoading ? <CircularProgress size={16} color='primary' /> : <PrintIcon />
          }
          sx={{ px: 3 }}
        >
          {allAssignedCasesLoading ? 'Loading..' : 'Print'}
        </Button>
      )}
    </React.Fragment>
  );
};

const SortByAssignmentTabs = React.memo(
  ({ sortBy, setSortBy, loading = true }: { sortBy; setSortBy; loading: boolean }) => {
    return (
      <Tabs
        value={sortBy}
        variant='standard'
        onChange={(e, selected) => {
          setSortBy(selected ?? 'assigned');
        }}
      >
        <Tab value='unassigned' label='Unassigned' disabled={loading} />
        <Tab value='assigned' label='Assigned' disabled={loading} />
      </Tabs>
    );
  }
);
