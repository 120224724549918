export type MedexResponse = {
    readonly data?: null | any,
    readonly message?: string | null,
    readonly status: ResponseStatus
    readonly errorCode?: null | number
}

export enum ResponseStatus {
    SUCCESS = 0,
    FAIL = 1
}
