import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation, useNavigate } from 'react-router';
import { ErrorFallback_View } from '../components/ErrorFallback_View';
import { hasRole, roleCheck } from '../functions/roleCheck';
import { useAuth } from './AuthService';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredRoles?: string[];
  checkRolesWithServer?: boolean;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  requiredRoles = [],
  checkRolesWithServer = false,
}) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isNavigated, setIsNavigated] = useState(false);
  const [hasServerRole, setHasRole] = useState(false);
  const [checkingRoles, setCheckingRoles] = useState(true);
  const [roleCache, setRoleCache] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (!auth.ready) {
      return;
    }

    if (
      !auth.isAuthenticated &&
      !auth.authState.linking_phone &&
      !auth.two_factor.is_verifying_code
    ) {
      navigate('/login');
    } else if (
      auth.isAuthenticated &&
      !auth.two_factor.passed &&
      !auth.authState.linking_phone &&
      auth.two_factor.is_verifying_code
    ) {
      navigate('/login/2fa');
    } else if (
      !auth.isAuthenticated &&
      !auth.two_factor.passed &&
      auth.authState.linking_phone &&
      !auth.two_factor.passed
    ) {
      navigate('/login/addphone');
    } else {
      setIsNavigated(true);
    }
  }, [
    auth.ready,
    auth.isAuthenticated,
    auth.two_factor.passed,
    navigate,
    auth.authState.linking_phone,
    auth.two_factor.is_verifying_code,
  ]);

  useEffect(() => {
    if (isNavigated && auth.isAuthenticated && checkRolesWithServer && checkingRoles) {
      // Check the cache first.
      const cachedResult = roleCache[auth.user!.userSeq];
      if (cachedResult !== undefined) {
        setHasRole(cachedResult);
        setCheckingRoles(false);
        return;
      }

      let isAuthorized = false;

      const roleChecks = requiredRoles.map(async role => {
        const isAllowed = await hasRole(auth.user!, role);
        if (isAllowed) {
          isAuthorized = true;
        }
      });

      Promise.all(roleChecks).then(() => {
        // Cache the result.
        setRoleCache(prev => ({
          ...prev,
          [auth.user!.userSeq]: isAuthorized,
        }));
        setHasRole(isAuthorized);
        setCheckingRoles(false);
      });
    }
  }, [
    auth.user,
    checkRolesWithServer,
    checkingRoles,
    requiredRoles,
    auth.isAuthenticated,
    isNavigated,
    roleCache,
  ]);

  if (!auth.ready || !isNavigated) {
    return null;
  }

  /** Only runs when auth is mounted, user is logged in and passed 2fa */
  if (requiredRoles && requiredRoles.length > 0 && !checkRolesWithServer) {
    for (let role of requiredRoles) {
      if (roleCheck(auth.user?.roleAccessList, role)) {
        return (
          <ErrorBoundary FallbackComponent={ErrorFallback_View} key={location.pathname}>
            {children}
          </ErrorBoundary>
        );
      }
    }

    navigate('/home');
    return null;
  }

  if (checkRolesWithServer && requiredRoles && requiredRoles.length > 0) {
    // If still checking roles with the server null
    if (checkingRoles) {
      return null;
    }

    // If finished checking with server and user has a required role
    if (hasServerRole) {
      return (
        <ErrorBoundary FallbackComponent={ErrorFallback_View} key={location.pathname}>
          {children}
        </ErrorBoundary>
      );
    } else {
      navigate('/home');
      return null;
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback_View} key={location.pathname}>
      {children}
    </ErrorBoundary>
  );
};
