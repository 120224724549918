import { Box } from '@mui/material';
import DesktopHomeView from './DesktopHomeView';
import MobileHomeView from './MobileHomeView';

export default function HomeView() {
  return (
    <Box sx={{ p: 3, height: '100%' }}>
      <DesktopHomeView />
      <MobileHomeView />
    </Box>
  );
}
