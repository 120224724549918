import { useEffect } from 'react';
import { useAuth } from '../../../utils/auth/AuthService';

export function useTwoFactorFailsafe(method: string) {
  const auth = useAuth();

  useEffect(() => {
    /** Failsafe to ensure a user doens't try and verify using a method they didn't select or one that isn't available */
    if (auth.ready && auth.two_factor.selected_method !== method) {
      auth.logout();
    }
  }, [auth, auth.ready, auth.two_factor.selected_method, method]);
}
