import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  TextareaAutosize,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { useAuth } from '../../../utils/auth/AuthService';
import { FormikTextField } from '../../../utils/components/FormikTextField';
import CaseViewDateField from '../../../components/CaseView/CaseViewDateField';
import { useUnsavedChanges } from '../../../Context/UnsavedChangesContext';

export default function CertificationView() {
  const [meOptions, setMEOptions] = useState([]);
  const [voidCaseReasonOptions, setVoidCaseReasonOptions] = useState([]);
  const { user } = useAuth();
  const authorizedToEdit = user.roleCheck([
    'caef1f91-c392-4b53-a466-a8f418d8d25a', // Medical Examiner
    'd1582600-5f86-49dd-bab7-6f7205bfeffd', // Sys admin
    'e0556a35-b626-485d-9090-0d1a23abf38b', // Investigator
    '18c92818-6969-466b-a82b-c4817fdfacf4', // Data entry (HISTORIC-DATAENTRYONLY)
    '7bea8708-b1c8-4bf5-8fda-bc023453f072', // Admin assistant
  ]);
  const systemsAdmin = user.roleCheck(['d1582600-5f86-49dd-bab7-6f7205bfeffd']);

  const { REACT_APP_API_URL } = process.env;
  const [caseSignedDateError, setCaseSignedDateError] = useState(null);

  const formik = useFormikContext();
  const { setUnsavedChanges } = useUnsavedChanges();

  const fetchMEs = async () => {
    const queryParams = new URLSearchParams();
    if (formik.values?.caseSummary?.jdxSeq) {
      queryParams.append('jdxList', formik.values?.caseSummary?.jdxSeq);
    }

    await fetch(`${REACT_APP_API_URL}get-pathologist-options?${queryParams.toString()}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setMEOptions(data);
      })
      .catch(e => {
        alert(e);
      });
  };

  const fetchVoidCaseReasons = async () => {
    await fetch(REACT_APP_API_URL + 'getvoidcasereason', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setVoidCaseReasonOptions(data);
      })
      .catch(e => {
        alert(e);
      });
  };

  useEffect(() => {
    fetchMEs();
  }, []);

  useEffect(() => {
    if (formik.values?.caseCertification?.caseStatus === 'V') {
      fetchVoidCaseReasons();
    }
  }, [formik.values?.caseCertification?.caseStatus]);

  return (
    <form>
      {/* <Typography 
  variant='subtitle1' color='gray' 
  sx={{ fontSize: '.775rem' }}  // Adjust the font size as needed
>  <strong>CERTIFICATION</strong>
</Typography> */}

      {/* <Grid item xs={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 0, mb: 0, pb: 0 }} />
      </Grid> */}

      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={3}>
          <FormControl component='fieldset'>
                  <Typography
        variant='subtitle1'
        color='gray'
        sx={{ fontSize: '.775rem' }} // Adjust the font size as needed
      >
        {' '}
        <strong>Case File Status</strong>
      </Typography>
          
            <RadioGroup
              id='caseCertification.caseStatus'
              name='caseCertification.caseStatus'
              value={formik.values != null ? formik.values?.caseCertification?.caseStatus : ''}
              onChange={formik.handleChange}
              row
            >
              <FormControlLabel
                disabled={!formik.status.editing || !authorizedToEdit}
                value='O'
                control={<Radio />}
                label='Open'
              />
              <FormControlLabel
                disabled={!formik.status.editing || !authorizedToEdit}
                value='C'
                control={<Radio />}
                label='Closed'
              />
             {systemsAdmin && (
          <FormControlLabel
            disabled={!formik.status.editing}
            value='V'
            control={<Radio />}
            label='Void'
          />
        )}
            </RadioGroup>
          </FormControl>
        </Grid> */}

        {formik.values?.caseCertification?.caseStatus &&
          formik.values?.caseCertification?.caseStatus === 'V' && (
            <>
              {/* <Grid item xs={12}></Grid> */}
              <Grid item xs={12} md={3}>
                <Autocomplete
                  disabled={!formik.status.editing || !authorizedToEdit}
                  id='caseCertification.voidCaseReasonSeq'
                  name='caseCertification.voidCaseReasonSeq'
                  options={voidCaseReasonOptions}
                  value={
                    formik.values?.caseCertification != null
                      ? voidCaseReasonOptions?.find(
                          option =>
                            option.optionSeq === formik.values?.caseCertification?.voidCaseReasonSeq
                        ) || null
                      : null
                  }
                  onChange={(e, value) => {
                    formik.setFieldValue('caseCertification.voidCaseReasonSeq', value?.optionSeq);
                    setUnsavedChanges();
                  }}
                  error={
                    formik.touched.voidCaseReasonSeq && Boolean(formik.errors.voidCaseReasonSeq)
                  }
                  helperText={formik.touched.voidCaseReasonSeq && formik.errors.voidCaseReasonSeq}
                  getOptionLabel={option => option?.optionName}
                  renderInput={params => <TextField {...params} label='Void Case Reason' />}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!formik.status.editing || !authorizedToEdit}
                  label='Void Case Note'
                  name='caseCertification.voidCaseNote'
                  type='text'
                  multiline
                  value={
                    formik.values?.caseCertification != null
                      ? formik.values?.caseCertification?.voidCaseNote
                      : null
                  }
                  onChange={e => {
                    formik.handleChange(e);  
                    setUnsavedChanges();
                  }}
                  fullWidth
                  InputProps={{
                    inputComponent: TextareaAutosize,
                    inputProps: {
                      minRows: 1,
                      maxRows: 5,
                    },
                  }}
                />
              </Grid>
            </>
          )}
        <Grid item xs={12}></Grid>
        {/* <Grid item xs={12} sm={3}>
          <TextField
            disabled={!formik.status.editing || !authorizedToEdit}
            id='caseCertification.signedAndFiledReceivedDate'
            name='caseCertification.signedAndFiledReceivedDate'
            label='Case Signed & Filed'
            type='date'
            value={
              formik.values?.caseCertification?.signedAndFiledReceivedDate != null
                ? formik.values?.caseCertification?.signedAndFiledReceivedDate.substring(0, 10)
                : null
            }
            // onChange={event => {
            //   formik.handleChange(event);
            // }}
            onChange={event => {
              formik.handleChange(event);
              const { value } = event.target;
              if (value) {
                const inputDate = new Date(value);
                const currentDate = new Date();
                if (inputDate > currentDate) {
                  setCaseSignedDateError('Invalid date');
                  formik.setFieldValue('caseCertification.signedAndFiledReceivedDate', '');
                } else {
                  setCaseSignedDateError(null);
                  formik.setFieldValue('caseCertification.signedAndFiledReceivedDate', value);
                }
              }
            }}
            onBlur={() => {
              setCaseSignedDateError(null);
            }}
            error={Boolean(caseSignedDateError)}
            helperText={caseSignedDateError}
            // error={
            //   (formik.touched?.caseCertification?.signedAndFiledReceivedDate ?? false) &&
            //   Boolean(formik.errors?.caseCertification?.signedAndFiledReceivedDate ?? false)
            // }
            // helperText={
            //   (formik.touched?.caseCertification?.signedAndFiledReceivedDate ?? false) &&
            //   (formik.errors?.caseCertification?.signedAndFiledReceivedDate ?? false)
            // }
            style={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid> */}

        <CaseViewDateField
          xs={12}
          md={3}
          authorizedToEdit={authorizedToEdit}
          fieldName='caseCertification.signedAndFiledReceivedDate'
          label='Forensic Pathology Rpt Signed'
        />

        <Grid item xs={12} md={3}>
          <Autocomplete
            disabled={!formik.status.editing || !authorizedToEdit}
            id='caseCertification.signedAndFiledReceivedUser'
            name='caseCertification.signedAndFiledReceivedUser'
            options={meOptions}
            value={
              meOptions.find(
                option =>
                  option.optionSeq === formik.values?.caseCertification?.signedAndFiledReceivedUser
              ) || null
            }
            onChange={(e, value) => {
              formik.setFieldValue(
                'caseCertification.signedAndFiledReceivedUser',
                value?.optionSeq || null
              );
              setUnsavedChanges();
            }}
            error={
              (formik.touched?.caseCertification?.signedAndFiledReceivedUser ?? false) &&
              Boolean(formik.errors?.caseCertification?.signedAndFiledReceivedUser ?? false)
            }
            helperText={
              (formik.touched?.caseCertification?.signedAndFiledReceivedUser ?? false) &&
              (formik.errors?.caseCertification?.signedAndFiledReceivedUser ?? false)
            }
            getOptionLabel={option => option.optionName}
            renderInput={params => <TextField {...params} label='Pathologist' />}
          />
        </Grid>

        <Grid item xs={12}></Grid>
        {/* <Grid item xs={12} sm={3}>
          <TextField
            disabled={!formik.status.editing || !authorizedToEdit}
            id='caseCertification.deathCertificateFiledDate'
            name='caseCertification.deathCertificateFiledDate'
            label='DC Filed Date'
            type='date'
            value={
              formik.values?.caseCertification?.deathCertificateFiledDate != null
                ? formik.values?.caseCertification?.deathCertificateFiledDate.substring(0, 10)
                : null
            }
            onChange={event => {
              formik.handleChange(event);
            }}
            error={
              (formik.touched?.caseCertification?.deathCertificateFiledDate ?? false) &&
              Boolean(formik.errors?.caseCertification?.deathCertificateFiledDate ?? false)
            }
            helperText={
              (formik.touched?.caseCertification?.deathCertificateFiledDate ?? false) &&
              (formik.errors?.caseCertification?.deathCertificateFiledDate ?? false)
            }
            style={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid> */}
        <CaseViewDateField
          xs={12}
          md={3}
          authorizedToEdit={authorizedToEdit}
          fieldName='caseCertification.deathCertificateFiledDate'
          label='Death Certificate Signed'
        />
        <Grid item xs={12} md={3}>
          <Autocomplete
            disabled={!formik.status.editing || !authorizedToEdit}
            id='caseCertification.deathCertificateFiledUser'
            name='caseCertification.deathCertificateFiledUser'
            options={meOptions}
            value={
              meOptions.find(
                option =>
                  option.optionSeq === formik.values?.caseCertification?.deathCertificateFiledUser
              ) || null
            }
            onChange={(e, value) => {
              formik.setFieldValue(
                'caseCertification.deathCertificateFiledUser',
                value?.optionSeq || null
              );
              setUnsavedChanges();
            }}
            error={
              (formik.touched?.caseCertification?.deathCertificateFiledUser ?? false) &&
              Boolean(formik.errors?.caseCertification?.deathCertificateFiledUser ?? false)
            }
            helperText={
              (formik.touched?.caseCertification?.deathCertificateFiledUser ?? false) &&
              (formik.errors?.caseCertification?.deathCertificateFiledUser ?? false)
            }
            getOptionLabel={option => option.optionName}
            renderInput={params => <TextField {...params} label='Pathologist' />}
          />
        </Grid>
        <Grid item xs={12}></Grid>
        {/* <Grid item xs={12} sm={3}>
          <TextField
            disabled={!formik.status.editing || !authorizedToEdit}
            id='caseCertification.deathCertificateAmendedDate'
            name='caseCertification.deathCertificateAmendedDate'
            label='DC Amended Date'
            type='date'
            value={
              formik.values?.caseCertification.deathCertificateAmendedDate != null
                ? formik.values?.caseCertification.deathCertificateAmendedDate.substring(0, 10)
                : null
            }
            onChange={event => {
              formik.handleChange(event);
            }}
            error={
              (formik.touched?.caseCertification?.deathCertificateAmendedDate ?? false) &&
              Boolean(formik.errors?.caseCertification?.deathCertificateAmendedDate ?? false)
            }
            helperText={
              (formik.touched?.caseCertification?.deathCertificateAmendedDate ?? false) &&
              (formik.errors?.caseCertification?.deathCertificateAmendedDate ?? false)
            }
            style={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid> */}
        <CaseViewDateField
          xs={12}
          md={3}
          authorizedToEdit={authorizedToEdit}
          fieldName='caseCertification.deathCertificateAmendedDate'
          label='Death Certificated Amended'
        />
        <Grid item xs={12} md={3}>
          <Autocomplete
            disabled={!formik.status.editing || !authorizedToEdit}
            id='caseCertification.deathCertificateAmendedUser'
            name='caseCertification.deathCertificateAmendedUser'
            options={meOptions}
            value={
              meOptions.find(
                option =>
                  option.optionSeq === formik.values?.caseCertification.deathCertificateAmendedUser
              ) || null
            }
            onChange={(e, value) => {
              formik.setFieldValue(
                'caseCertification.deathCertificateAmendedUser',
                value?.optionSeq || null
              );
              setUnsavedChanges();
            }}
            error={
              (formik.touched?.caseCertification?.deathCertificateAmendedUser ?? false) &&
              Boolean(formik.errors?.caseCertification?.deathCertificateAmendedUser ?? false)
            }
            helperText={
              (formik.touched?.caseCertification?.deathCertificateAmendedUser ?? false) &&
              (formik.errors?.caseCertification?.deathCertificateAmendedUser ?? false)
            }
            getOptionLabel={option => option.optionName}
            renderInput={params => <TextField {...params} label='Pathologist' />}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <FormikTextField
          gridProps={{ xs: 12, md: 3 }}
          authorizedToEdit={authorizedToEdit}
          formikField='caseCertification.edrsNumber'
          label='Death Certificate No.'
          inputProps={{
            maxLength: 50,
          }}
        />

        <FormikTextField
          gridProps={{ xs: 12, md: 3 }}
          authorizedToEdit={authorizedToEdit}
          formikField='caseCertification.labNumber'
          label='Lab Number'
          inputProps={{
            maxLength: 50,
          }}
        />

        <FormikTextField
          gridProps={{ xs: 12, md: 3 }}
          authorizedToEdit={authorizedToEdit}
          formikField='caseInvestigations.deathInvestigationAdditionalInformation.namUsID'
          label='NamUs ID'
          inputProps={{
            maxLength: 15,
          }}
        />
      </Grid>
    </form>
  );
}
