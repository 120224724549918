import { Grid, Box, Button } from '@mui/material';
import {
  DataGridPremium,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import React from 'react';
import { useEffect, useState } from 'react';
import MainCard from '../../../../components/AdministrationModules/MainCard';
import Filter from './Filter';
import AddEdit from './AddEdit';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';
import ApiNotification from '../../../../components/DialogBox';
import DeleteApiNotification from '../../../../components/AdministrationModules/DeleteApiNotification';
import {
  activeData,
  deleteData,
  getAdminData,
  getAllCounty,
} from '../../../../services/utility-api';
import Loader from '../../../../components/AdministrationModules/Loader';
import AccessDenied from '../../../../components/AdministrationModules/AccessDenied';

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = gridPageSelector(apiRef);
  const pageCount = gridPageCountSelector(apiRef);

  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => (
        <PaginationItem
          {...props2}
          sx={{ fontFamily: 'DataGrid, sans-serif' }}
          disableRipple
        />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{ mr: 3 }}
    />
  );
}

const LocationList = () => {
  const referenceMasterTableName = 'location';
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const locationListPermissions = permissions?.['Location List'];
  const [isAddEditOn, setIsAddEditOn] = useState();
  const [editObj, setEditObj] = useState({});
  const [maxOrder, setMaxOrder] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsToShow, setRowsToShow] = useState([]);
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  const [openActiveInActiveDialogBox, setOpenActiveInActiveDialogBox] =
    useState(false);
  const [activeInactiveClickData, setActiveInactiveClickData] = useState({});
  const [statusFilter, setStatusFilter] = useState('Active');
  const [nameFilter, setNameFilter] = useState('');
  const [countyFilter, setCountyFilter] = useState('');

  const [columns, setColumns] = useState([
    {
      field: 'locationName',
      headerName: 'Location Name',
      // headerClassName: 'super-app-theme--header',
      minWidth: 400,
      align: 'left',
      flex: 20,
      renderCell: (cellValues) => {
        return <div>{cellValues?.row?.locationName || ''}</div>;
      },
    },
  ]);
  const [county, setCounty] = useState([]);

  const changeActiveInActiveClick = async () => {
    try {
      if (activeInactiveClickData.status === 'Active') {
        const responseMessage = await deleteData({
          seq: activeInactiveClickData.seq,
          tableName: referenceMasterTableName,
        });
        setDialogContents(responseMessage);
      } else {
        const responseMessage = await activeData({
          seq: activeInactiveClickData.seq,
          tableName: referenceMasterTableName,
        });
        setDialogContents(responseMessage);
      }
      setOpenActiveInActiveDialogBox(false);
      setOpenApiDialogBox(true);
      await getAllData();
    } catch (error) {
      console.log('error', error);
    }
  };

  const getAllCountyData = async () => {
    const countyResponse = await getAllCounty();
    setCounty(countyResponse);
  };

  useEffect(() => {
    if (Object.keys(county).length) {
      let tempColumns = [
        {
          field: 'locationName',
          headerName: 'Location Name',
          // headerClassName: 'super-app-theme--header',
          minWidth: 400,
          align: 'left',
          flex: 20,
          renderCell: (cellValues) => {
            return (
              <div
                style={{
                  width: '400px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {cellValues?.row?.locationName || ''}
              </div>
            );
          },
        },
      ];
      tempColumns.push({
        field: 'status',
        headerName: 'Status',
        width: 100,
        // headerClassName: 'super-app-theme--header',
        renderCell: (cellValues) => {
          const handleClick = async (row) => {
            setOpenActiveInActiveDialogBox(true);
            const newIconData = row.status === 'Active' ? 'locked' : 'done';
            setActiveInactiveClickData({
              icon: newIconData,
              seq: row[
                referenceMasterTableName[0].toLowerCase() +
                  referenceMasterTableName.slice(1) +
                  'Seq'
              ],
              status: row.status,
            });
          };

          return Number(cellValues.row.order) >= 90000 ? (
            <></>
          ) : (
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleClick(cellValues.row);
              }}
            >
              {cellValues.row['status'] === 'Active' ? (
                <LockIcon />
              ) : (
                <DoneIcon />
              )}
            </Button>
          );
        },
      });
      setColumns(tempColumns);
    }
  }, [county]);

  const [isLoading, setIsLoading] = useState(true);
  const getAllData = async () => {
    setIsLoading(true);
    const response = await getAdminData('location');

    const data = response.data.data.map((row) => {
      const newObj = {};
      Object.keys(row).map((key) => {
        newObj[key] = row[key];
      });
      newObj.locationOnCountySeqs = newObj.locationOnCounty.map((county) => {
        return county.countySeq;
      });
      newObj.id = newObj['locationSeq'];
      return newObj;
    });
    setRows(data);
    let newRows = data;
    if (statusFilter.toLowerCase() != 'all') {
      newRows = newRows.filter(
        (row) => row['status'].toLowerCase() == statusFilter.toLowerCase()
      );
    }
    if (nameFilter) {
      newRows = newRows = newRows.filter((row) =>
        row['locationName'].toLowerCase().includes(nameFilter.toLowerCase())
      );
    }
    if (countyFilter) {
      newRows = newRows.filter((row) =>
        row.locationOnCountySeqs.includes(countyFilter)
      );
    }
    setRowsToShow(newRows);
    setIsLoading(false);
  };
  const editRow = (params) => {
    if (locationListPermissions?.edit) {
      if (params?.row?.status.toLowerCase() === 'active') {
        setIsAddEditOn(false);
        setTimeout(() => {
          setEditObj(params.row);
          setIsAddEditOn(true);
        }, 200);
      }
    }
  };
  useEffect(() => {
    getAllCountyData();
    getAllData();
  }, []);

  if (isLoading) {
    return <Loader />;
  } else {
    if (!locationListPermissions?.view) {
      return (
        <>
          <AccessDenied />
        </>
      );
    } else {
      return (
        <Grid container spacing={3} style={{ width: '100%' }}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                {/* <MainCard> */}
                  <Box style={{ tr: 'hover', cursor: 'pointer' }}>
                    <Filter
                      rows={rows}
                      setRowsToShow={setRowsToShow}
                      county={county}
                      statusFilter={statusFilter}
                      setStatusFilter={setStatusFilter}
                      setNameFilter={setNameFilter}
                      setCountyFilter={setCountyFilter}
                    />

                    <DataGridPremium
                      columnHeaderHeight={50}
                      scrollbarSize={5}
                      pagination
                      slots={{
                        pagination: CustomPagination,
                      }}
                      // sx={{
                      //   width: '100%',
                      //   height: 450,
                      //   borderRadius: '6px',
                      //   backgroundColor: 'white',
                      //   // boxShadow: '0px 0px 10px #ddd',
                      // }}
                      initialState={{
                        sorting: {
                          sortModel: [
                            {
                              field: 'locationName',
                              sort: 'asc',
                            },
                          ],
                        },
                      }}
                      rowHeight={35}
                      rows={rowsToShow}
                      columns={columns}
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      disableSelectionOnClick={false}
                      onRowClick={editRow}
                      loading={isLoading}
                    />
                  </Box>
                  <Box mb={2}>
                    <Button
                      variant='contained'
                      sx={{ marginTop: '7px' }}
                      onClick={() => {
                        setIsAddEditOn(false);
                        setTimeout(() => {
                          setEditObj({});
                          setIsAddEditOn(true);
                        }, 200);
                      }}
                      disabled={!locationListPermissions?.add}
                    >
                      Add Location
                    </Button>
                  </Box>
                  {isAddEditOn && editObj && (
                    <AddEdit
                      editObj={editObj}
                      getAllData={getAllData}
                      allRow={rows}
                      county={county}
                      setIsAddEditOn={setIsAddEditOn}
                      locationListPermissions={locationListPermissions}
                    />
                  )}
                  <DeleteApiNotification
                    openActiveInActiveDialogBox={openActiveInActiveDialogBox}
                    setOpenActiveInActiveDialogBox={
                      setOpenActiveInActiveDialogBox
                    }
                    referenceMasterTableName={referenceMasterTableName}
                    changeActiveInActiveClick={changeActiveInActiveClick}
                    activeInactiveClickData={activeInactiveClickData}
                  />
                  <ApiNotification
                    openApiDialogBox={openApiDialogBox}
                    setOpenApiDialogBox={setOpenApiDialogBox}
                    dialogContents={dialogContents}
                    closeDialogBox={() => {
                      setOpenApiDialogBox(false);
                    }}
                  />
                {/* </MainCard> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  }
};
export default LocationList;
