import { Autocomplete, TextField } from '@mui/material';
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import React, { useEffect, useRef, useState } from 'react';

function MultiAutocompleteEditCell({ options, title, ...otherProps }) {
  const { id, value, field, hasFocus, colDef } = otherProps;
  const apiRef = useGridApiContext();
  const ref = useRef();
  const [localOptions, setLocalOptions] = useState([]);

  useEffect(() => {
    // Update localOptions when options change
    setLocalOptions(options);
  }, [options]);

  const handleChange = (event, newValue) => {
    if (!apiRef.current) return;

    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  useEnhancedEffect(() => {
    if (!apiRef.current) return;
    if (hasFocus && ref.current) {
      const input = ref.current.querySelector(`input[value="${value}"]`);
      input?.focus();
    }
  }, [hasFocus, value]);

  return (
    <Autocomplete
      multiple
      limitTags={2}
      disableCloseOnSelect
      options={localOptions}
      value={value ?? []}
      getOptionLabel={option => option.optionName}
      fullWidth
      onChange={handleChange}
      renderInput={params => (
        <TextField {...params} variant='standard' placeholder={colDef.headerName} />
      )}
    />
  );
}

export const renderMultiAutocompleteEditCell = params => {
  const { colDef } = params;
  const options = colDef.valueOptions ?? [];

  return <MultiAutocompleteEditCell {...params} options={options} />;
};
