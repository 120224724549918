import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { DataGridPremium as DataGrid, GridColDef } from '@mui/x-data-grid-premium';

export default function GlossaryDataLoadingGridSkeleton({
  pageSize = null,
}: {
  pageSize?: number | null;
}) {
  const columns: GridColDef[] = [
    {
      field: 'term',
      headerName: 'Term',
      flex: 2,
      renderHeader: () => <Skeleton variant='text' width='80%' />,
      renderCell: () => <Skeleton variant='text' width='70%' />,
    },
    {
      field: 'definition',
      headerName: 'Definition',
      flex: 5,
      renderHeader: () => <Skeleton variant='text' width='80%' />,
      renderCell: () => (
        <>
          <Skeleton variant='text' width='90%' />
          <Skeleton variant='text' width='80%' />
          <Skeleton variant='text' width='70%' />
        </>
      ),
    },
  ];

  const rows = Array.from({ length: pageSize || 3 }, (_, index) => ({
    id: index,
    term: '',
    definition: '',
  }));

  return (
    <Box component='div'>
      <Stack>
        <Typography variant='h6'>
          <Skeleton variant='text' width={120} />
        </Typography>
        <Typography variant='subtitle1'>
          <Skeleton variant='text' width={200} />
        </Typography>
      </Stack>
      <DataGrid
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
          mt: 1,
        }}
        rows={rows}
        columns={columns}
        getRowHeight={() => 'auto'}
        autoHeight
        disableColumnMenu
        hideFooter
      />
    </Box>
  );
}
