import { User } from '../../../../../types/User';

export interface Option {
  optionName: string;
  optionSeq: string;
  isActive?: boolean;
}

export interface Extremity {
  extremitySeq: string;
  extremityName: string;
  isUpper: boolean;
  isLower: boolean;
}

export interface SubExtremity {
  subExtremitySeq: string;
  subExtremityName: string;
}

const { REACT_APP_API_URL } = process.env;

export async function fetchAllPersonsInCurrentUserJdx(accessToken: string) {
  const url = `${REACT_APP_API_URL}user/getAllUsersInCurrentUserJdx`;
  return await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export interface GetMEPhysicianResponse {
  personLastName: string;
  personFirstName: string;
  userName: string;
  primaryEmail: string | null;
  primaryMobileNo: string | null;
  userSeq: string;
  personSeq: string;
}

export async function fetchAllMedicalExaminers(accessToken: string) {
  const url = `${REACT_APP_API_URL}user/getmephysicians`;
  return await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export async function fetchXrayBodyTypes(accessToken: string) {
  const url = `${REACT_APP_API_URL}xray/getBodyTypes`;
  return await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export interface DecedentType {
  decedentTypeSeq: string;
  decedentTypeName: string;
}

export async function getDecedentTypes(accessToken: string) {
  const url = `${REACT_APP_API_URL}xray/getDecedentTypes`;
  return await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export async function fetchXrayBodyCategoryTypes(accessToken: string) {
  const url = `${REACT_APP_API_URL}xray/getBodyCategories`;
  return await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export async function fetchExtremityTypes(accessToken: string) {
  const url = `${REACT_APP_API_URL}xray/getExtremityTypes`;
  return await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export async function fetchSubExtremities(accessToken: string) {
  const url = `${REACT_APP_API_URL}xray/getSubExtremities`;
  return await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export async function fetchXrayTypeOfCase(accessToken: string) {
  const url = `${REACT_APP_API_URL}xray/getTypeOfCase`;
  return await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export async function fetchPathologistOptions() {
  const url = `${REACT_APP_API_URL}getpathologistoptions`;
  return await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json ',
    },
  });
}

export async function fetchAllXrayRequestsForCase(accessToken: string, caseid: string) {
  const url = `${REACT_APP_API_URL}xray/getAllXrayRequestsForCase/${caseid}`;
  return await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export async function getExtremityTypes(accessToken: string) {
  const url = `${REACT_APP_API_URL}xray/getExtremityOptions`;
  return await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export async function updateXRayRequest(accessToken: string, request: UpdateXrayRequestDto) {
  const url = `${REACT_APP_API_URL}xray/updateXrayRequest`;
  console.log('Sending request:', request);
  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(request),
  });
}

export async function addXrayRequest(accessToken: string, request: CreateXrayRequestDto) {
  const url = `${REACT_APP_API_URL}xray/createXrayRequest`;
  console.log('Sending request:', request);
  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(request),
  });
}

export async function deleteXrayRequest(accessToken: string, request: DeleteXrayRequest) {
  const url = `${REACT_APP_API_URL}xray/deleteXrayRequest`;
  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(request),
  });
}

export interface XRayRequestBodyCategory {
  xRayRequestedBodyCategorySeq: string | null;
  xRayRequestedBodyCategoryName: string | null;
  isChecked: boolean;
  subCategories: XRayRequestBodySubCategory[];
}

export interface XRayRequestBodySubCategory {
  xRayRequestedBodySubCategorySeq: string | null;
  xRayRequestedBodySubCategoryName: string | null;
}

export interface XRayRequestCaseType {
  typeOfCaseSeq?: string | null;
  isChecked: boolean;
}

export interface XrayRequestExtremity {
  extremitySeq: string | null;
  extremityName: string | null;
  subExtremities: SubExtremityTypeDto[];
  isChecked: boolean;
}

export interface XrayRequestDto {
  xRayRequestSeq: string;
  requedstedByUser: XrayRequestUserDto;
  assignedMedicalExaminerUser: XrayRequestUserDto;
  completedByUser: XrayRequestUserDto | null;
  requestedOn: string | null;
  completedOn: string | null;
  createdOn: string;
  createdByUser: XrayRequestUserDto;
  lastUpdatedOn: string | null;
  lastUpdatedByUser: XrayRequestUserDto | null;
  specialProcedures: string | null;
  caseTypes: TypeOfCaseDto[];
  bodyCategories: BodyCategoryDto[];
  extremitiesRequested: ExtremityRequestDto[];
  decedentType: DecedentType | null;
}

export interface CreateXrayRequestDto {
  caseId: string | null;
  requestedByUserSeq: string | null;
  assignedMedicalExaminerUserSeq: string | null;
  requestedOn: string | null;
  specialProcedures: string | null;
  caseTypes: TypeOfCaseDto[];
  bodyCategories: BodyCategoryDto[];
  extremitiesRequested: ExtremityTypeDto[];
  decedentTypeSeq: string | null;
  completedBy: Option;
  completedOn: string | null;
}

interface XrayRequestUserDto {
  userSeq: string;
  userName: string;
  personFirstName: string;
  personLastName: string;
  personSeq: string;
}

export interface TypeOfCaseDto {
  typeOfCaseOnRequestSeq?: string;
  typeOfCaseSeq: string;
  isChecked?: boolean;
  typeOfCaseName: string;
}

export interface ExtremityRequestDto {
  extremityOnRequestSeq: string;
  extremitySeq: string;
  isChecked: boolean;
  extremityName: string;
  isUpper: boolean;
  isLower: boolean;
  subExtremities: SubExtremityDto[];
}

interface SubExtremityDto {
  subExtremitySeq: string;
  subExtremityName: string;
}

export interface ExtremityTypeDto {
  extremitySeq: string;
  extremityName: string;
  extremityOrder: number;
  isUpper: boolean;
  isLower: boolean;
  subExtremityTypes: SubExtremityTypeDto[];
  isChecked?: boolean;
}

export interface SubExtremityTypeDto {
  subExtremitySeq: string;
  subExtremityName: string;
  subExtremityOrder: number;
  isChecked?: boolean;
}

export interface BodyCategoryDto {
  bodyCategorySeq?: string;
  bodyCategoryName?: string;
  bodyCategoryOnRequestSeq?: string | null;
  subCategories?: BodySubCategoryDto[];
  isChecked?: boolean;
}

export interface BodySubCategoryDto {
  bodySubCategorySeq?: string;
  bodySubCategoryName?: string;
  isChecked?: boolean;
}

export interface UpdateXrayRequestDto {
  requestSeq: string;
  requestedByUserSeq: string | null;
  assignedMedicalExaminerUserSeq: string | null;
  requestedOn: string | null;
  completedOn: string | null;
  specialProcedures: string | null;
  caseTypes: UpdateXrayRequest_UpdateCaseType[] | null;
  bodyCategories: BodyCategoryDto[] | null;
  extremitiesRequested: UpdateXrayRequest_UpdateExtremity[] | null;
  decedentTypeSeq: string | null;
  completedBy: Partial<User> | null;
}

export interface UpdateXrayRequest_UpdateExtremity {
  extremitySeq: string;
  isChecked: boolean;
  subExtremitySeqs: string[];
}

export interface UpdateXrayRequest_UpdateBodyCategory {
  bodyCategorySeq: string;
  isChecked: boolean;
  subAreaSeqs: string[];
}

export interface UpdateXrayRequest_UpdateCaseType {
  caseTypeSeq: string;
  isChecked: boolean;
}

export interface DeleteXrayRequest {
  requestSeq: string;
}
