import React from 'react';
import CMSAdminHeader from '../../../components/CMSAdmin/CMSAdminHeader';
import {DailyExamListNew} from '../../../components/CMSAdmin/DailyExamList/DailyExamList';

const DailyExamListModule = () => {
  return (
    <React.Fragment>
      <CMSAdminHeader
        title='Daily Exam List'
        subtitle1='View, assign and print the case list'
      />
      <DailyExamListNew />
    </React.Fragment>
  );
};

export default DailyExamListModule;
