import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';

const CommonAlert = ({ severity, message, autoHideDuration = 5000, resetKey }) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    // Set a timer to automatically hide the alert after a duration
    let timer;
    if (isOpen && autoHideDuration && severity !== 'error') {
      timer = setTimeout(() => {
        setIsOpen(false);
      }, autoHideDuration);
    }

    return () => {
      // Clear the timer on component unmount or when isOpen changes
      clearTimeout(timer);
    };
  }, [isOpen, autoHideDuration]);

  const handleCloseAlert = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(true);
  }, [resetKey]);

  return (
    <div style={{ padding: '10px 20px 0 20px' }}>
      {isOpen && (
        <Alert variant='standard' severity={severity} onClose={handleCloseAlert}>
          {message}
        </Alert>
      )}
    </div>
  );
};

export default CommonAlert;
