// import { Autocomplete, Checkbox, Paper, TextField } from '@mui/material';
// import { DataGridPremium } from '@mui/x-data-grid-premium';
// import React, { useEffect, useState } from 'react';
// import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
// import { useNavigate } from 'react-router';
// import {
//   Alert,
//   Button,
//   Card,
//   CardBody,
//   CardHeader,
//   Col,
//   Collapse,
//   NavLink,
//   Row,
// } from 'reactstrap';
// import '../../../App.css';
// import { useAuth } from '../../../utils/auth/AuthService';
// import { BreadcrumbReportNav } from '../../../utils/components/BreadcrumbReportNav';
// import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  Paper,
  Slide,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useNavigate } from 'react-router';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function OpioidDeathView() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [countResults, setCountResults] = useState([]);
  const [listResults, setListResults] = useState([]);
  const [jdxs, setJdxs] = useState([]);
  const [MENotifiedFrom, setMENotifiedFrom] = useState('');
  const [MENotifiedTo, setMENotifiedTo] = useState('');
  const [year, setYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [requiredAlertVisible, setRequiredAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const navigate = useNavigate();
  const { REACT_APP_API_URL } = process.env;
  const theme = useTheme();

  const handleJdxChange = (event, data) => {
    setJdxs(data);
  };

  const handleYearChange = (event, data) => {
    setYear(data);
  };

  const handleMENotifiedFromChange = event => {
    if (event.target.value != '') {
      setMENotifiedFrom(event.target.value);
    } else {
      setMENotifiedFrom('');
    }
  };

  const handleMENotifiedToChange = event => {
    if (event.target.value != '') {
      setMENotifiedTo(event.target.value);
    } else {
      setMENotifiedTo('');
    }
  };

  const dismissDateAlert = () => {
    setRequiredAlertVisible(false);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const listColumns = [
    {
      field: 'caseID',
      headerName: 'Case ID',
      width: 125,
      editable: false,
      headerAlign: 'center',
      renderCell: params => {
        return (
          <NavLink
            //variant="contained"
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
            }}
          >
            {params.formattedValue}
          </NavLink>
        );
      },
    },
    {
      field: 'decedentName',
      headerName: 'Decedent Name',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'jurisdictionName',
      headerName: 'Jurisdiction',
      width: 125,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'causeOfDeath',
      headerName: 'Cause of Death',
      width: 210,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'meNotified',
      headerName: 'Call Taken',
      width: 125,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'caseStatus',
      headerName: 'Status',
      width: 125,
      editable: false,
      headerAlign: 'left',
    },
  ];

  //gather search params from form and FETCH/POST to API
  const handleSubmit = async event => {
    event.preventDefault();

    if (year == null && MENotifiedFrom == '' && MENotifiedTo == '') {
      setRequiredAlertVisible(true);
      return;
    } else if (MENotifiedFrom != '' && MENotifiedTo != '') {
      let startDateArr = MENotifiedFrom.split('-');
      let endDateArr = MENotifiedTo.split('-');

      let startDateValue = new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]);
      let endDateValue = new Date(endDateArr[0], endDateArr[1] - 1, endDateArr[2]);

      if (endDateValue - startDateValue > 31536000000) {
        setRequiredAlertVisible(true);
        return;
      }
    }
    if (jdxs.length == 0) {
      setJdxAlertVisible(true);
      return;
    }

    let formData = new FormData();

    setLoading(true);
    setResultsVisible(true);

    formData.append('JdxList', JSON.stringify(jdxs));
    formData.append('Year', year);
    formData.append('MENotifiedStartDate', MENotifiedFrom);
    formData.append('MENotifiedEndDate', MENotifiedTo);

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'GetOpioidDeathCount', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setCountResults(data);
        })
        .catch(e => {
          alert(e);
        });

      await fetch(REACT_APP_API_URL + 'GetOpioidDeathList', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setListResults(data);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    await setLoading(false);
  };

  const clearAll = () => {
    setMENotifiedFrom('');
    setMENotifiedTo('');
    setYear(null);
    setJdxs([]);
    setResultsVisible(false);
  };

  const CountContent = () => {
    var jdxs = [];
    var counts = [{ id: 0 }];

    for (var i of countResults) {
      jdxs.push({
        field: i.jurisdictionName,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'count-header-theme',
        minWidth: 120,
      });
      counts[0][i.jurisdictionName] = i.count + ' Cases';
    }

    return (
      <Paper
        elevation={3}
        sx={{
          p: '10px',
          px: '20px',
          borderRadius: '20px',
          width: '100%',
        }}
      >
        <p style={{ textAlign: 'center' }}>
          I have run your calculations and generated the following table:
        </p>
        <div style={{ textAlign: 'center' }}>
          <DataGridPremium
            columns={jdxs}
            rows={counts}
            columnHeaderHeight={35}
            rowHeight={35}
            sx={{
              width: '100%',
              '& .MuiDataGrid-cell': {
                borderRight: '1px solid #ddd',
              },
              '& .MuiDataGrid-cell:last-child': {
                borderRight: 'none',
              },
              '& .count-header-theme': {
                borderRight: '1px solid #ddd',
              },
              '& .count-header-theme:last-child': {
                borderRight: 'none',
              },
              '& .MuiDataGrid-iconSeparator': {
                display: 'none',
              },
              fontFamily: 'DataGrid, sans-serif',
            }}
            autoHeight
            hideFooter
            disableSelectionOnClick
          />
        </div>
      </Paper>
    );
  };

  useEffect(() => {
    if (user === null) return;

    const fetchYear = async () => {
      try {
        const currentYear = new Date().getFullYear();
        setYear(currentYear);
        setYearOptions([...Array(16).keys()].map(i => i + (currentYear - 15)).reverse());
      } catch (e) {
        alert(e);
      }
    };

    fetchYear();
    setAuthorizedJdxs(user.jdxAccessList);
    setJdxs(user.jdxAccessList);
  }, [user]);

  return (
    <Card
      raised
      sx={{
        my: 3,
        mx: 'auto',
        width: { xs: '95%', lg: '75%' },
      }}
    >
      <CardHeader
        title='Opioid Death Report'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
      />

      <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

      <CardContent>
        <Slide
          direction='right'
          in={requiredAlertVisible}
          mountOnEnter
          unmountOnExit
          sx={{ mb: 2 }}
        >
          <Alert severity='warning' onClose={dismissDateAlert}>
            Call taken range must be within 1 year or year dropdown must be set.
          </Alert>
        </Slide>

        <Slide direction='right' in={jdxAlertVisible} mountOnEnter unmountOnExit sx={{ mb: 2 }}>
          <Alert severity='warning' onClose={dismissJdxAlert}>
            Please select at least 1 jurisdiction.
          </Alert>
        </Slide>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id='jdxs'
              options={authorizedJdxs}
              value={jdxs}
              disableCloseOnSelect
              limitTags={4}
              fullWidth
              size='small'
              getOptionLabel={option => option.jdxName}
              onChange={(event, data) => handleJdxChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.jdxName}
                </li>
              )}
              renderInput={params => (
                <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
              )}
              ListboxComponent={'ul'}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Autocomplete
              id='year'
              options={yearOptions}
              value={year}
              size='small'
              getOptionLabel={option => option.toString()}
              onChange={(event, data) => handleYearChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.toString()}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => <TextField {...params} label='Year' placeholder='Year' />}
              ListboxComponent={'ul'}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id='searchfromdate'
              name='searchfromdate'
              label='Exam Start From'
              type='date'
              size='small'
              value={MENotifiedFrom}
              onChange={handleMENotifiedFromChange}
              disabled={year}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id='searchtodate'
              name='searchtodate'
              label='Exam Start To'
              type='date'
              size='small'
              value={MENotifiedTo}
              onChange={handleMENotifiedToChange}
              disabled={year}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 2, opacity: 1 }} />

        <Stack
          direction='row'
          sx={{
            width: '100%',
            justifyContent: 'right',
          }}
          spacing={2}
        >
          <Button type='submit' variant='contained' color='primary' onClick={handleSubmit}>
            Calculate
          </Button>

          <Button color='secondary' variant='outlined' onClick={clearAll}>
            Clear
          </Button>
        </Stack>

        <Collapse in={resultsVisible} sx={{ mt: resultsVisible ? 4 : 0 }}>
          <ReportDataGrid
            loading={loading}
            listresults={listResults}
            idcolumn='caseID'
            columnsInput={listColumns}
            title='Records Found'
            casecount
            chatcontent={<CountContent />}
          />
        </Collapse>
      </CardContent>
    </Card>

    // <div style={{ padding: 20 }}>
    //   <BreadcrumbReportNav currentPage='Opioid Report' />

    //   <Card style={{ marginBottom: '1rem' }} className='card-main'>
    //     <CardHeader className='header-txt'>
    //       <strong>Opioid Death Report</strong>
    //     </CardHeader>
    //     <br />

    //     <Alert
    //       color='info'
    //       isOpen={requiredAlertVisible}
    //       toggle={dismissDateAlert}
    //     >
    //       Call taken range must be within 1 year or year dropdown must be set.
    //     </Alert>

    //     <Alert
    //       color='info'
    //       isOpen={jdxAlertVisible}
    //       toggle={dismissJdxAlert}
    //     >
    //       Please select at least 1 jdx.
    //     </Alert>

    //     <div>
    //       <CardBody>
    //         <Row>
    //           <Col className='me-col' xs='12'>
    //             <Autocomplete
    //               multiple
    //               id='jdxs'
    //               size='small'
    //               options={authorizedJdxs}
    //               value={jdxs}
    //               disableCloseOnSelect
    //               limitTags={4}
    //               getOptionLabel={(option) => option.jdxName}
    //               onChange={(event, data) => handleJdxChange(event, data)}
    //               renderOption={(props, option, { selected }) => (
    //                 <li {...props}>
    //                   <Checkbox
    //                     icon={<MdCheckBoxOutlineBlank />}
    //                     checkedIcon={<MdOutlineCheckBox />}
    //                     style={{ marginRight: 8 }}
    //                     checked={selected}
    //                   />
    //                   {option.jdxName}
    //                 </li>
    //               )}
    //               style={{ width: '100%' }}
    //               renderInput={(params) => (
    //                 <TextField
    //                   {...params}
    //                   label='Jurisdiction'
    //                   placeholder='Jurisdiction'
    //                 />
    //               )}
    //             />
    //           </Col>
    //         </Row>

    //         <Row>
    //           <Col className='me-col' xs='12' md='4'>
    //             <Autocomplete
    //               id='year'
    //               size='small'
    //               getOptionLabel={(option) => option.toString()}
    //               options={yearOptions}
    //               value={year}
    //               onChange={(event, data) => handleYearChange(event, data)}
    //               renderOption={(props, option, { selected }) => (
    //                 <li {...props}>
    //                   <Checkbox
    //                     icon={<MdCheckBoxOutlineBlank />}
    //                     checkedIcon={<MdOutlineCheckBox />}
    //                     style={{ marginRight: 8 }}
    //                     checked={selected}
    //                   />
    //                   {option}
    //                 </li>
    //               )}
    //               style={{ width: '100%', marginTop: 15 }}
    //               renderInput={(params) => (
    //                 <TextField {...params} label='Year' placeholder='Year' />
    //               )}
    //             />
    //           </Col>

    //           <Col className='me-col' xs='12' md='4'>
    //             <TextField
    //               id='calltakenfrom'
    //               name='calltakenfrom'
    //               label='Call Taken From'
    //               size='small'
    //               type='date'
    //               onChange={handleMENotifiedFromChange}
    //               style={{ width: '100%', marginTop: 15 }}
    //               InputLabelProps={{
    //                 shrink: true,
    //               }}
    //               value={MENotifiedFrom}
    //             />
    //           </Col>

    //           <Col className='me-col' xs='12' md='4'>
    //             <TextField
    //               id='calltakento'
    //               name='calltakento'
    //               label='Call Taken To'
    //               type='date'
    //               size='small'
    //               onChange={handleMENotifiedToChange}
    //               style={{ width: '100%', marginTop: 15 }}
    //               InputLabelProps={{
    //                 shrink: true,
    //               }}
    //               value={MENotifiedTo}
    //             />
    //           </Col>
    //         </Row>
    //       </CardBody>
    //       <br />

    //       <Button
    //         type='submit'
    //         color='primary'
    //         style={{ width: 150, marginLeft: 18, float: 'right' }}
    //         onClick={handleSubmit}
    //       >
    //         Generate Report
    //       </Button>

    //       <Button
    //         color='secondary'
    //         style={{ width: 120, marginLeft: 18, float: 'right' }}
    //         onClick={clearAll}
    //       >
    //         Clear
    //       </Button>
    //       <hr />
    //       <br />

    //       <Collapse isOpen={resultsVisible}>
    // <ReportDataGrid
    //   loading={loading}
    //   listresults={listResults}
    //   idcolumn='caseID'
    //   columnsInput={listColumns}
    //   title='Records Found'
    //   casecount
    //   chatcontent={<CountContent />}
    // />
    //       </Collapse>
    //     </div>
    //   </Card>
    // </div>
  );
}
