import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, FormHelperText, TextField } from '@mui/material';

import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import FormControl from '@mui/material/FormControl';
import { saveAdminData } from '../../../../services/utility-api';
import AddressData from '../../../../components/AdministrationModules/AddressData/AddressView';
import ContactData from '../../../../components/AdministrationModules/ContactData/ContactView';
import ApiNotification from '../../../../components/DialogBox';

const AddEdit = ({ editObj, isCode, isCounty, referenceMasterTableName, maxOrder, setIsAddEditOn, getAllData, countyNames, reportHeaderDetailsPermissions }) => {
    const [dialogContents, setDialogContents] = useState('');
    const [openApiDialogBox, setOpenApiDialogBox] = useState(false);

    const closeDialogBox = () => {
        setOpenApiDialogBox(false);
    }

    const onSave = async (values) => {
        const id = '00000000-0000-0000-0000-000000000000';
        let contacts = [];
        if (values?.contactItem)
            values?.contactItem.forEach((item, index) => {
                if (item.contactItemDetails) {
                    contacts.push(item);
                }
            });
        if (editObj?.contactItem)
            editObj.contactItem.map((contact) => {
                if (contact.contactItemSeq != id && contacts.filter((item) => item.contactItemSeq == contact.contactItemSeq).length == 0) {
                    contacts.push({
                        ...contact,
                        isActive: false,
                        status: 'InActive'
                    });
                }
            });
        try {
            values.address = values?.address.map(data => ({ ...data, countySeq: data.countySeq !== '' ? data.countySeq : id }));
            const response = await
                saveAdminData({
                    values: {
                        ...values,
                        [referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq']:
                            values[referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq'] || id,
                        contactItem: contacts
                    },
                    tableName: referenceMasterTableName
                })
            setDialogContents(response?.message);
            setOpenApiDialogBox(true);
            getAllData();
        } catch (error) {
            console.log('error', error);
        }
    };
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={10} mb={2} mt={2}>
                    <Typography variant="h5">
                        <strong>
                            {Object.keys(editObj) ? 'Modify ' : 'Enter New '}{' '}
                            {referenceMasterTableName
                                .split('')
                                .map((alpha) => {
                                    if (alpha <= 'Z') return ' ' + alpha;
                                    return alpha;
                                })
                                .join('')
                                .trim()}
                        </strong>
                    </Typography>
                </Grid>
            </Grid>
            <Formik
                initialValues={{
                    ...editObj,
                    reportHeaderMasterName: editObj['reportHeaderMasterName'],
                    reportHeaderDetailsOrder: editObj?.reportHeaderDetailsOrder ? Number(editObj.reportHeaderDetailsOrder) : maxOrder + 10
                }}
                validationSchema={Yup.object().shape({
                    reportHeaderMasterName: Yup.string().required(`Report Header Details Name is required`).nullable(),
                    reportHeaderName: Yup.string().required(`Report Header Name is required`).nullable(),
                    meOfficeName: Yup.string().required(`ME Office Name is required`).nullable(),
                    reportHeaderDetailsOrder: Yup.number().required(`Report Header Detail Order is required`),
                    moneyOrderPayableTo: Yup.string().nullable(),
                    federalId: Yup.string().nullable(),
                    address: Yup.array()
                        .of(
                            Yup.object().shape({
                                addressLine1: Yup.string().required('Address line 1 is required'),
                                city: Yup.string().required('City is required')
                            })
                        )
                        .min(1, 'Address is a required field'),
                    contactItem: Yup.array()
                        .of(
                            Yup.object().shape({
                                contactItemTypeSeq: Yup.string().required('Contact type is required'),
                                contactItemDetails: Yup.string().required('Contact Details is required')
                            })
                        )
                        .min(1, 'Contact is a required field'),
                    reportHeaderDetailsOrder: Yup.number().required('List Order is Required').nullable()
                })}
                onSubmit={onSave}
            >
                {({ errors, handleBlur, handleSubmit, isSubmitting, touched, values, handleChange }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item sm={6} xs={12} md={6} lg={6}>
                                    <FormControl fullWidth error required component="fieldset" variant="standard">
                                        <TextField
                                            required
                                            focused
                                            id="outlined-basic"
                                            defaultValue={''}
                                            label={
                                                referenceMasterTableName
                                                    .split('')
                                                    .map((alpha) => {
                                                        if (alpha <= 'Z') return ' ' + alpha;
                                                        return alpha;
                                                    })
                                                    .join('')
                                                    .trim() + ' Name'
                                            }
                                            variant="outlined"
                                            name="reportHeaderMasterName"
                                            value={values['reportHeaderMasterName']}
                                            onChange={handleChange}
                                        />
                                        <ErrorMessage
                                            name="reportHeaderMasterName"
                                            render={(msg) => <FormHelperText error>{msg}</FormHelperText>}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth error required component="fieldset" variant="standard">
                                        <TextField
                                            required
                                            focused
                                            id="outlined-basic"
                                            defaultValue={''}
                                            label="Report Header Name"
                                            variant="outlined"
                                            name="reportHeaderName"
                                            value={values['reportHeaderName']}
                                            onChange={handleChange}
                                        />
                                        <ErrorMessage
                                            name="reportHeaderName"
                                            render={(msg) => <FormHelperText error>{msg}</FormHelperText>}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6} xs={12} md={6} lg={6}>
                                    <FormControl fullWidth error required component="fieldset" variant="standard">
                                        <TextField
                                            required
                                            focused
                                            id="outlined-basic"
                                            defaultValue={''}
                                            label="ME Office Name"
                                            variant="outlined"
                                            name="meOfficeName"
                                            value={values['meOfficeName']}
                                            onChange={handleChange}
                                        />
                                        <ErrorMessage name="meOfficeName" render={(msg) => <FormHelperText error>{msg}</FormHelperText>} />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6} xs={12} md={6} lg={6}>
                                    <FormControl fullWidth component="fieldset" variant="standard">
                                        <TextField
                                            focused
                                            id="outlined-basic"
                                            defaultValue={''}
                                            label="Money Order Payable To"
                                            variant="outlined"
                                            name="moneyOrderPayableTo"
                                            value={values['moneyOrderPayableTo']}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6} xs={12} md={6} lg={6}>
                                    <FormControl fullWidth component="fieldset" variant="standard">
                                        <TextField
                                            focused
                                            id="outlined-basic"
                                            defaultValue={''}
                                            label="Federal ID#"
                                            variant="outlined"
                                            name="federalId"
                                            value={values['federalId']}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6} xs={12} md={6} lg={6}>
                                    <FormControl fullWidth error required component="fieldset" variant="standard">
                                        <TextField
                                            required
                                            focused
                                            id="outlined-basic"
                                            defaultValue={''}
                                            label="List Order"
                                            variant="outlined"
                                            name="reportHeaderDetailsOrder"
                                            type="number"
                                            value={values['reportHeaderDetailsOrder']}
                                            onChange={handleChange}
                                        />
                                        <ErrorMessage
                                            name="reportHeaderDetailsOrder"
                                            render={(msg) => <FormHelperText error>{msg}</FormHelperText>}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={12} xs={12} md={12} lg={12}>
                                    <FormControl error required component="fieldset" variant="standard" sx={{ marginTop: '10px' }}>
                                        <AddressData
                                            addresses={values.address}
                                            nursingHomeSeq={values.nursingHomeSeq || ''}
                                            funeralHomeSeq={values.funeralHomeSeq || ''}
                                            lawEnforcementSeq={values.lawEnforcementSeq || ''}
                                            contactInfoSeq={values.contactInfoSeq || ''}
                                            isAddressOn={false}
                                            handleChange={(data) =>
                                                handleChange({
                                                    target: {
                                                        value: data,
                                                        name: 'address'
                                                    }
                                                })
                                            }
                                        />
                                        {typeof errors.address == 'string' && (
                                            <ErrorMessage name="address" render={(msg) => <FormHelperText error>{msg}</FormHelperText>} />
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item sm={12} xs={12} md={12} lg={12}>
                                    <FormControl
                                        fullWidth
                                        error
                                        required
                                        component="fieldset"
                                        variant="standard"
                                        sx={{ paddingTop: '0px' }}
                                    >
                                        <ContactData
                                            contactItem={values.contactItem}
                                            handleChange={(data) =>
                                                handleChange({
                                                    target: {
                                                        value: data,
                                                        name: 'contactItem'
                                                    }
                                                })
                                            }
                                        />
                                        {typeof errors.contactItem == 'string' && (
                                            <ErrorMessage
                                                name="contactItem"
                                                render={(msg) => <FormHelperText error>{msg}</FormHelperText>}
                                            />
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        <Button
                                            variant="contained"
                                            sx={{ marginTop: '7px' }}
                                            onClick={handleSubmit}
                                            disabled={
                                                !reportHeaderDetailsPermissions?.edit && !reportHeaderDetailsPermissions?.add
                                            }
                                        >
                                            Update
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    );
                }}
            </Formik>

            <ApiNotification
                openApiDialogBox={openApiDialogBox}
                closeDialogBox={closeDialogBox}
                setOpenApiDialogBox={setOpenApiDialogBox}
                dialogContents={dialogContents}
            />
        </>
    );
};

export default AddEdit;
