import {
  ChevronLeftRounded,
  ChevronRightRounded,
  EmailRounded,
  NotificationsRounded,
} from '@mui/icons-material';
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../utils/auth/AuthService';
import { CheckboxIconButton } from '../../../../utils/components/CheckboxIconButton';
import {
  NotificationDeliveryMethods,
  NotificationTypeWithUserPreference,
  getUserNotificationPreferences,
  updateUserSelectedDeliveryMethodStatusForNotificationType,
} from './notificationpreferences.api';

export function Notifications() {
  return (
    <Stack spacing={1}>
      <Typography variant='h6' fontWeight='bold' sx={{ mb: 1 }}>
        Notifications
      </Typography>
      <NotificationsList />
    </Stack>
  );
}

function NotificationsList() {
  const auth = useAuth();
  const [preferences, setPreferences] = useState<NotificationTypeWithUserPreference[]>([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<NotificationTypeWithUserPreference | null>(null);

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const preferencesResponse = await getUserNotificationPreferences(
          auth.user?.accessToken!,
          auth.user?.userSeq!
        );
        setPreferences(preferencesResponse);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPreferences();
  }, [auth.user?.accessToken, auth.user?.userSeq]);

  const handleNotificationClick = (n: NotificationTypeWithUserPreference) => {
    setSelected(n);
  };

  const handleGoBack = () => {
    setSelected(null);
  };

  return (
    <>
      {loading && (
        <Stack spacing={0.2}>
          <Skeleton variant='rounded' height={60} />
        </Stack>
      )}

      {!loading && preferences.length === 0 && (
        <p>No configurable preferences found. Please try again later.</p>
      )}
      {selected ? (
        <ExpandedNotification
          preference={
            preferences.find(p => p.notificationTypeSeq === selected.notificationTypeSeq)!
          }
          goBack={handleGoBack}
          onDeliveryMethodToggle={async (
            notificationTypeSeq: string,
            deliveryMethod: NotificationDeliveryMethods,
            enabled: boolean
          ) => {}}
        />
      ) : (
        <List
          dense={true}
          disablePadding={true}
          sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 1 }}
        >
          {preferences.map(n => (
            <React.Fragment key={n.notificationTypeSeq}>
              <ListItem
                disablePadding={true}
                secondaryAction={
                  <IconButton disabled>
                    <ChevronRightRounded />
                  </IconButton>
                }
              >
                <ListItemButton onClick={() => handleNotificationClick(n)}>
                  <ListItemText
                    primary={n.notificationTypeName}
                    secondary={getEnabledMethodsText(n)}
                  />
                </ListItemButton>
              </ListItem>
              <Divider variant='fullWidth' component='li' />
            </React.Fragment>
          ))}
        </List>
      )}
    </>
  );
}

function ExpandedNotification({
  preference,
  onDeliveryMethodToggle,
  goBack,
}: {
  preference: NotificationTypeWithUserPreference;
  onDeliveryMethodToggle: (
    notificationTypeSeq: string,
    deliveryMethod: NotificationDeliveryMethods,
    enabled: boolean
  ) => Promise<void>;
  goBack: () => void;
}) {
  const auth = useAuth();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [loadingDeliveryMethod, setLoadingDeliveryMethod] = useState<{
    [key in NotificationDeliveryMethods]: boolean;
  }>({
    'in-app': false,
    email: false,
    'text message': false,
  });

  const handleDeliveryMethodToggle = async (
    notificationTypeSeq: string,
    deliveryMethod: NotificationDeliveryMethods,
    enabled: boolean
  ) => {
    setLoadingDeliveryMethod(prevState => ({
      ...prevState,
      [deliveryMethod]: true,
    }));

    try {
      const updatedPreference = await updateUserSelectedDeliveryMethodStatusForNotificationType(
        auth.user?.accessToken!,
        auth.user?.userSeq!,
        notificationTypeSeq,
        deliveryMethod,
        enabled
      );

      // Update the local state with the updated preference
      preference.inApp = updatedPreference.inApp;
      preference.email = updatedPreference.email;
      preference.textMessage = updatedPreference.textMessage;
    } catch (error) {
      console.error('Failed to update notification preference:', error);
      // Handle the error, show an error message, or revert the UI state
    } finally {
      setLoadingDeliveryMethod(prevState => ({
        ...prevState,
        [deliveryMethod]: false,
      }));
    }
  };

  return (
    <Stack maxWidth={600}>
      <Stack direction='row' alignItems='center' sx={{ mb: 2 }}>
        <Button
          variant='text'
          startIcon={<ChevronLeftRounded />}
          onClick={e => {
            e.stopPropagation();
            goBack();
          }}
        >
          Back
        </Button>
        <Typography
          variant='subtitle2'
          sx={{ flexGrow: 1, textAlign: 'center', fontWeight: 'bold' }}
        >
          {preference.notificationTypeName}
        </Typography>
      </Stack>

      <Stack sx={{ ml: theme.spacing(3) }} spacing={2}>
        <Typography
          variant='subtitle2'
          color={theme.palette.grey[500]}
          sx={{ ml: theme.spacing(4) }}
        >
          {preference.notificationTypeDescription}
        </Typography>

        <Paper elevation={0} square={false}>
          <Stack direction='row' spacing={1} py={3} px={2} alignContent='space-evenly'>
            <CheckboxIconButton
              icon={
                <NotificationsRounded
                  fontSize='large'
                  sx={{
                    fontSize: 48,
                    color: preference.inApp ? theme.palette.primary.main : theme.palette.grey[500],
                  }}
                />
              }
              label='In-App'
              checked={preference.inApp}
              disabled={loadingDeliveryMethod['in-app']}
              loading={loadingDeliveryMethod['in-app']}
              onClick={e => {
                e.stopPropagation();
                handleDeliveryMethodToggle(
                  preference.notificationTypeSeq,
                  'in-app',
                  !preference.inApp
                );
              }}
              sx={{
                borderRadius: 2,
              }}
            />
            <CheckboxIconButton
              icon={
                <EmailRounded
                  fontSize='large'
                  sx={{
                    fontSize: 48,
                    color: preference.email ? theme.palette.primary.main : theme.palette.grey[500],
                  }}
                />
              }
              label='Email'
              checked={preference.email}
              disabled={loadingDeliveryMethod['email']}
              loading={loadingDeliveryMethod['email']}
              onClick={e => {
                e.stopPropagation();
                handleDeliveryMethodToggle(
                  preference.notificationTypeSeq,
                  'email',
                  !preference.email
                );
              }}
              sx={{
                borderRadius: 2,
              }}
            />
            {/* <CheckboxIconButton
              icon={
                <PhoneRounded
                  fontSize='large'
                  sx={{
                    fontSize: 48,
                    color: preference.textMessage
                      ? theme.palette.primary.main
                      : theme.palette.grey[500],
                  }}
                />
              }
              label='SMS'
              checked={preference.textMessage}
              disabled={loadingDeliveryMethod['text message']}
              loading={loadingDeliveryMethod['text message']}
              onClick={e => {
                e.stopPropagation();
                handleDeliveryMethodToggle(
                  preference.notificationTypeSeq,
                  'text message',
                  !preference.textMessage
                );
              }}
              sx={{
                borderRadius: 2,
              }}
            /> */}
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  );
}

const getEnabledMethodsText = (deliveryMethods: NotificationTypeWithUserPreference) => {
  const enabled: string[] = [];
  if (deliveryMethods.inApp) enabled.push('In-App');
  if (deliveryMethods.email) enabled.push('Email');
  if (deliveryMethods.textMessage) enabled.push('SMS');

  if (enabled.length === 0) return 'None';

  return enabled.join(', ');
};
