import { Button } from '@mui/material';
import 'leaflet/dist/leaflet.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useRef } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useMap } from 'react-leaflet/hooks';

const njCoordinates = { lat: 45.0583, lng: -70.4057 };
const zoomLevel = 13;

// AWS LOCATION SERVICES CONFIGURATION
const AWS = require('aws-sdk');

AWS.config.region = 'us-east-1';

AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'us-east-1:269924ac-7862-42d3-9a9c-4bd1c1861f39',
});

await AWS.config.credentials.get();

const locationOptions = {
  region: AWS.config.region,
  credentials: AWS.config.credentials,
};

// AWS LOCATION SERVICES OBJECT
const location = new AWS.Location(locationOptions);

function MapButton() {
  const map = useMap();

  const newLocation = async () => {
    var getPlaceParams = {
      IndexName: 'MedexLabPlaceIndex',
      PlaceId:
        'AQABAKAAhb_DLGDpar6O6PD1GuX8riPsS_uG4WQHun1IEADiJJpU0AKdwLwqSaXHonwl4KRqGelmi-KCyXkdcJ1g2jCVD0QHt1de5t86Fjtbjt8mycp7Y9ATT7K37InCTC82xCMrcbjCHxcTjACLJ1gkMBg-hFkNIQWeEIP09mUtZJyoqNcNrw_wMHHhX8ZcLwVgchCFpWWfIux5veCXD8TQwdJTY89MFFtGavoh909lLsvw13B4mBbdpdVwqiOZUMeZLx4i',
    };

    await location.getPlace(getPlaceParams, function (err, results) {
      const position = [results?.Place?.Geometry?.Point[1], results?.Place?.Geometry?.Point[0]];
      console.log(position);
      map.flyTo(position, 16);
    });
  };

  return (
    <Button sx={{ zIndex: 1000000 }} onClick={newLocation}>
      Test
    </Button>
  );
}

export default function MapTestView() {
  const mapRef = useRef();

  return (
    <div style={{ padding: 20 }}>
      <MapContainer
        style={{ width: '100%', height: 700, border: '2px solid blue' }}
        center={njCoordinates}
        zoom={zoomLevel}
        ref={mapRef}
      >
        <TileLayer
          url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'}
          attribution={
            'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
          }
        />

        <MapButton />
      </MapContainer>
    </div>
  );
}
