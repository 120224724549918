import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../../utils/auth/AuthService';

interface SimilarCaseData {
  caseID?: string | null;
  decedentFirstName?: string | null;
  decedentLastName?: string | null;
  decedentRace?: string | null;
  decedentGender?: string | null;
  decedentAge?: string | null;
  decedentAgeUnit?: string | null;
  decedentDateOfBirth?: string | null;
  decedentDateOfDeath?: string | null;
  bodyLocationAddress?: string | null;
  caseLoadedDateTime?: string | null;
  jurisdiction?: string | null;
  investigatorFirstName?: string | null;
  investigatorLastName?: string | null;
  meAction?: string | null;
}

interface SimilarCaseDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSubmitAnyway: React.Dispatch<React.SetStateAction<boolean>>;
  caseData: SimilarCaseData[];
}

export default function SimilarCaseDialog({
  open,
  setOpen,
  setSubmitAnyway,
  caseData,
}: SimilarCaseDialogProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { user } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleCaseClick = caseID => {
    navigate('/caseview', {
      state: { cmscaseid: caseID },
    });
  };

  const getAgeLabel = (data: SimilarCaseData): string => {
    var label: string = '';
    if (
      data.decedentDateOfBirth == null &&
      data.decedentDateOfDeath == null &&
      data.decedentAge == null
    )
      return '';

    if (data.decedentDateOfBirth == null) label += 'Unknown';
    else label += new Date(data.decedentDateOfBirth).toLocaleDateString();

    label += ' - ';

    if (data.decedentDateOfDeath == null) label += 'Unknown';
    else label += new Date(data.decedentDateOfDeath).toLocaleDateString();

    label += ' (';

    if (data.decedentAge == null) label += 'Age Unknown';
    else if (data.decedentAgeUnit == null || data.decedentAgeUnit == 'Unknown')
      label += data.decedentAge;
    else label += data.decedentAge + ' ' + data.decedentAgeUnit;

    label += ')';

    return label;
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        style: {
          minWidth: isMobile ? '95%' : '70%',
          margin: '0 auto',
          minHeight: '400px',
        },
      }}
    >
      <DialogTitle>Similar Cases Found</DialogTitle>
      <DialogContent>
        <DialogContentText>
          These recent cases have the same decedent name, is your case one of these instead?
        </DialogContentText>
        <br />
        {caseData.map(data => (
          <Accordion key={data.caseID}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Stack
                direction='row'
                spacing={2}
                sx={{ justifyContent: 'space-between', alignItems: 'center', width: '100%', pr: 1 }}
              >
                <Stack direction='row' spacing={2} sx={{ alignItems: 'center' }}>
                  <Button onClick={() => handleCaseClick(data?.caseID)} sx={{ width: 65 }}>
                    {data.caseID}
                  </Button>
                  <Typography>
                    <b>
                      {data.decedentFirstName} {data.decedentLastName}
                    </b>
                  </Typography>
                </Stack>
                <Typography>
                  Created On: {new Date(data?.caseLoadedDateTime).toLocaleString()}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction='column' spacing={1} sx={{ height: 1 }}>
                <Typography variant='h6'>{`Case #: ${data.caseID}`}</Typography>
                <Grid container>
                  <Grid md>
                    <Typography variant='body2' color='textSecondary'>
                      Decedent information
                    </Typography>
                    <Typography variant='body1'>
                      {data.decedentFirstName} {data.decedentLastName}
                    </Typography>
                    <Typography variant='body1'>{getAgeLabel(data)}</Typography>
                    <Typography variant='body1'>{data.decedentRace}</Typography>
                    <Typography variant='body1'>{data.decedentGender}</Typography>
                  </Grid>
                  <Grid md>
                    <Typography variant='body2' color='textSecondary'>
                      Place of Death
                    </Typography>
                    <Typography variant='body1'>
                      {data.bodyLocationAddress == '' ? '--' : data.bodyLocationAddress}
                    </Typography>
                    <Typography variant='body2' color='textSecondary'>
                      Jurisdiction
                    </Typography>
                    <Typography variant='body1'>{data.jurisdiction ?? '--'}</Typography>
                  </Grid>
                  {user.jdxAccessList.find(jdx => jdx.jdxName == data.jurisdiction) && (
                    <Grid md>
                      <Typography variant='body2' color='textSecondary' align='right'>
                        ME Action
                      </Typography>
                      <Typography variant='body1' align='right'>
                        {data.meAction ?? '--'}
                      </Typography>
                      <Typography variant='body2' color='textSecondary' align='right'>
                        Investigator
                      </Typography>
                      <Typography variant='body1' align='right'>
                        {data.investigatorFirstName ?? '--'} {data.investigatorLastName}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color='error'>
          Close
        </Button>
        <Button
          onClick={() => {
            setSubmitAnyway(true);
            setOpen(false);
          }}
        >
          No, create my new case
        </Button>
      </DialogActions>
    </Dialog>
  );
}
