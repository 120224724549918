import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import County from '../../../../components/AdministrationModules/County';
import * as Yup from 'yup';
import Roles from '../../../../components/AdministrationModules/Roles';
import UserForm from '../../../../components/AdministrationModules/UserForm';
import PhysicianCertification from '../../../../components/AdministrationModules/PhysicianCertification';
import InvestigatorCertification from '../../../../components/AdministrationModules/InvestigatorCertification';
import AddressData from '../../../../components/AdministrationModules/AddressData/AddressView';
import ContactTab from '../../../../components/AdministrationModules/ContactComponents/ContactTab';
import {
  OCSME_CERTIFICATION_OBJECT,
  PHYSICIAN_CERTIFICATION_PATHOLOGY_OBJECT,
  SPECIAL_PRIVILEGE_OBJECT,
  USERS_MEDICAL_CERTIFICATION,
} from '../../../CMSAdmin/Menu/constants';
import axios from 'axios';
import { GlobalContext } from '../../../../Context/GlobalContext';
import { useNavigate } from 'react-router';
import { Box, Button, Divider, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import Chips from '../../../../components/AdministrationModules/Chips';
import dayjs from 'dayjs';
import { fetchFormat, fetchFormattedDate } from '../../../../services/utility-api';
import authHeader from '../../../../services/auth-header';
import ApiNotification from '../../../../components/DialogBox';
import UserHeaderContainer from './UserHeaderContainer';
import Backbutton from '../../../../components/AdministrationModules/Backbutton';
import ContactData from '../../../../components/AdministrationModules/ContactData/ContactView';
import { useAuth } from '../../../../utils/auth/AuthService';
import LoginHistory from './LoginHistory';
import Documents from './Documents';
import Notes from './Notes';
import { TabContext, TabList, TabPanel } from '@mui/lab';

const AddUser = () => {
  const { profileInformation, signature, photo, sendUserProfile, sendPhotoToViewModal } =
    useContext(GlobalContext);
  console.log('signature', signature, photo);
  const navigate = useNavigate();
  const [openApiDialogBox, setOpenApiDialogBox] = React.useState(false);
  const [dialogContents, setDialogContents] = React.useState('');
  const [contactsErrorMessage, setContactsErrorMessage] = useState('');
  const { user } = useAuth();
  const [selectedTab, setSelectedTab] = useState('1');

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const {
    userTitle,
    userName,
    userLastName,
    userFirstName,
    userMiddleName,
    userSuffix,
    primaryEmail,
    primaryMobileNo,
    operationMode,
    organizationseq,
    mdLicenseNo,
    mdLicenseExpiryDate,
    departmentSeq,
    boardcertificatefellowNo,
    barcode,
    abmdiCertificateNO,
    abmdiCertificateExpiryDate,
    userContacts,
    userAddress,
    initials,
    roleseq,
    countySeqandDashboard,
    userSeq,
    specialPrivilege,
  } = profileInformation ?? {};
  console.log('userAddress', profileInformation);
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));
  const userPermissions = permissions?.['Users'];
  const [mobileNumber, setMobileNumber] = useState(primaryMobileNo ?? null);
  const [contactsError, setContactsError] = useState({});

  const convertBooleanToSequence = (moduleSequence, selected) => {
    return (
      moduleSequence?.map(sequence => {
        if (selected === 'Dashboard') {
          if (sequence.split(' || ')?.[2] === 'True') {
            return sequence.split(' || ')?.[0].toLowerCase();
          }
        } else {
          return sequence.split(' || ')?.[0].toLowerCase();
        }
      }) ?? []
    );
  };

  const checkPhysicianAndInvestigation = certificates => {
    return (
      Object.keys(certificates)?.map(certificate => {
        if (profileInformation?.[certificate]) {
          return certificate;
        }
      }) ?? []
    );
  };

  const fetchSpecialPriveleges = () => {
    return Object.keys(SPECIAL_PRIVILEGE_OBJECT).filter(key => !!profileInformation?.[key]);
  };

  const initialValues = {
    countySeqandDashboard: window.location.pathname.includes('edit-user')
      ? convertBooleanToSequence(countySeqandDashboard, 'Users')
      : [],
    dashboardPermissions: window.location.pathname.includes('edit-user')
      ? convertBooleanToSequence(countySeqandDashboard, 'Dashboard')
      : [],
    roleseq: window.location.pathname.includes('edit-user')
      ? convertBooleanToSequence(roleseq, 'Roles')
      : [],
    userTitle: userTitle ?? '',
    userName: userName ?? '',
    userFirstName: userFirstName ?? '',
    userLastName: userLastName ?? '',
    userSuffix: userSuffix ?? '',
    userMiddleName: userMiddleName ?? '',
    primaryEmail: primaryEmail ?? '',
    primaryMobileNo: primaryMobileNo ?? '',
    initials: initials ?? '',
    departmentSeq: departmentSeq ?? '',
    barcode: barcode ?? '',
    specialPrivilege: fetchSpecialPriveleges() ?? [],
    pathology: checkPhysicianAndInvestigation(PHYSICIAN_CERTIFICATION_PATHOLOGY_OBJECT) ?? [],
    mdLicenseExpiryDate:
      fetchFormat(profileInformation?.mdLicenseExpiryDate?.substr(0, 10)) ?? null,
    mdLicenseNo: mdLicenseNo ?? null,
    operationMode: operationMode ?? null,
    organizationseq: organizationseq ?? '',
    ocsmeCertification: checkPhysicianAndInvestigation(OCSME_CERTIFICATION_OBJECT) ?? [],
    ocsmeCertificateExpiryDate:
      fetchFormat(profileInformation?.ocsmeCertificateExpiryDate?.substr(0, 10)) ?? null,
    boardcertificatefellowNo: boardcertificatefellowNo ?? '',
    abmdiCertificateNO: abmdiCertificateNO ?? '',
    abmdiCertificateExpiryDate:
      fetchFormat(profileInformation?.abmdiCertificateExpiryDate?.substr(0, 10)) ?? null,
    address: userAddress ?? [],
    contactItem: userContacts ?? [],
  };

  const user_access_token = localStorage.getItem('access_token');
  const updateCertifications = values => {
    let updatedPathologyOCSME = {};

    const combinedPathologyAndOCSME = [...values?.pathology, ...values?.ocsmeCertification];

    Object.entries(USERS_MEDICAL_CERTIFICATION)?.map(([key, value], index) => {
      if (combinedPathologyAndOCSME?.includes(key)) {
        updatedPathologyOCSME = { ...updatedPathologyOCSME, [key]: true };
      } else {
        updatedPathologyOCSME = { ...updatedPathologyOCSME, [key]: false };
      }
      return;
    });
    return updatedPathologyOCSME;
  };
  console.log('initialValues', initialValues);
  const fetchCountyAndDashboard = (counties, dashboards) => {
    let countyAndDashboard = [];
    counties?.forEach(county => {
      if (dashboards?.includes(county)) {
        countyAndDashboard?.push(`${county} || True || True`);
      } else {
        countyAndDashboard?.push(`${county} || True || False`);
      }
    });
    return countyAndDashboard;
  };

  const formatRoleSequence = roles => {
    const exisitingRoles = convertBooleanToSequence(profileInformation?.roleseq);
    let rolesToMap = [...roles, ...exisitingRoles];
    let removedDuplicates = rolesToMap.filter((item, index) => rolesToMap.indexOf(item) === index);
    return removedDuplicates?.map(role => {
      if (!roles?.includes(role)) {
        return `${role} || False`;
      } else {
        return `${role} || True`;
      }
    });
  };

  const closeDialogBox = () => {
    setOpenApiDialogBox(false);
  };

  const [errorMsg, setErrorMsg] = useState('');
  const [box, setBox] = useState(false);
  const [contactsErrorModal, setContactsErrorModal] = useState(false);

  const checkForAddressAndContactValidation = errors => {
    if (errors?.address && Object.keys(errors)?.length === 1) {
      setErrorMsg('Address field is required');
      setBox(true);
    }
  };

  const updateSpecialPrivileges = values => {
    let privilegesObject = {};

    Object.entries(SPECIAL_PRIVILEGE_OBJECT)?.map(([key, value]) => {
      if (values?.specialPrivilege.includes(key)) {
        privilegesObject = { ...privilegesObject, [key]: true };
      }
      return;
    });

    return privilegesObject;
  };

  const sendContactsError = () => {
    if (
      (contactsError.name && !contactsError?.value) ||
      (!contactsError.name && contactsError?.value)
    ) {
      setContactsErrorModal(true);
      setContactsErrorMessage('Contact Field is required');
      return true;
    } else if (
      contactsError?.name?.toLowerCase() === 'email' &&
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        contactsError?.value
      )
      // !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(contactsError?.value)
    ) {
      setContactsErrorModal(true);
      setContactsErrorMessage('Please enter valid email address');
      return true;
    }
    return false;
  };

  const updateStatusOfArrayFields = values => {
    const valueContactSeq = values?.contactItem?.map(item => item.contactItemSeq);

    profileInformation?.userContacts?.filter(item => {
      if (!valueContactSeq?.includes(item.contactItemSeq)) {
        values?.contactItem?.push({ ...item, isActive: false });
        return { ...item, isActive: false };
      }
    });

    return values?.contactItem;
  };

  console.log('address values', profileInformation);
  const updateStatusOfAddressFields = values => {
    const valuesAddressSeq = values?.address?.map(item => item.addressSeq);

    profileInformation?.userAddress?.filter(item => {
      if (!valuesAddressSeq?.includes(item.addressSeq)) {
        values?.address?.push({ ...item, status: 'false' });
        return { ...item, status: 'false' };
      }
    });
    return values?.address;
  };

  const onSubmit = async (values, errors) => {
    const checkContactValidation = sendContactsError();
    const updateArrayFields = updateStatusOfArrayFields(values);
    const updateAdrressFields = updateStatusOfAddressFields(values);
    if (!checkContactValidation) {
      const guid = '00000000-0000-0000-0000-000000000000';
      const reports = updateCertifications(values);
      const privileges = updateSpecialPrivileges(values);
      const payload = {
        abmdiCertificateExpiryDate: values?.abmdiCertificateExpiryDate,
        abmdiCertificateNO: values?.abmdiCertificateNO,
        barcode: values?.barcode,
        boardcertificatefellowNo: values?.boardcertificatefellowNo,
        countySeqandDashboard: fetchCountyAndDashboard(
          values?.countySeqandDashboard,
          values?.dashboardPermissions
        ),
        departmentSeq: values?.departmentSeq,
        eSignature: null,
        enable2FA: false,
        initials: values?.initials,
        mdLicenseExpiryDate: values?.mdLicenseExpiryDate,
        mdLicenseNo: values?.mdLicenseNo,
        ocsmeCertificateExpiryDate: values?.ocsmeCertificateExpiryDate,
        operationMode: values?.operationMode ? values?.operationMode : null ?? null,
        organizationseq: values?.organizationseq,
        primaryEmail: values?.primaryEmail,
        // primaryMobileNo: mobileNumber,
        primaryMobileNo: values?.primaryMobileNo ? values?.primaryMobileNo : null,
        roleseq: formatRoleSequence(values?.roleseq),
        securityQuestion: null,
        userAddress: updateAdrressFields,
        userContacts: updateArrayFields,
        userFirstName: values?.userFirstName,
        userLastName: values?.userLastName,
        userMiddleName: values?.userMiddleName,
        userName: values?.userName,
        userSeq: userSeq ?? guid,
        userSuffix: values?.userSuffix,
        userTitle: values?.userTitle,
        IsWithinServiceArea: true,
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}SaveUpdateUser?LogInuserSeq=${user?.userSeq}&isIncludeSecurityQuestion=false`,
          { ...payload, ...reports, ...privileges },
          { headers: authHeader() }
        );
        setOpenApiDialogBox(true);
        if (response.data.message.includes('UNIQUE KEY')) {
          setDialogContents(
            'Invalid data : This User Name already exists in the database. Please enter another User Name.'
          );
        } else {
          setDialogContents(response.data.message);
        }
        if ((response?.data?.status === 0 || response?.data?.status === 1) && signature) {
          let formData = new FormData();
          formData.append('photo', signature);
          formData.append('userSeq', userSeq ?? '');
          // formData.append('logInUserSeq', user?.userSeq ?? '');
          formData.append('logInUserSeq', user?.userSeq ?? '');
          formData.append('caseSeq', '');
          formData.append(
            'fileName',
            userName + Math.floor(Math.random() * 90000) + 10000 + `${signature.name}`
          );
          formData.append('entityName', '');
          formData.append('photoInfo', '');
          formData.append('photoSeq', '00000000-0000-0000-0000-000000000000');
          formData.append('uploadType', '');
          formData.append('isResourceFile', 'false');
          formData.append('isUsercertificate', 'false');
          formData.append('recoverySeal', '');
          formData.append('photoTypeSeq', '');
          formData.append('certificateTypeSeq', '');
          formData.append('photoLabel', '');
          formData.append('resourceTypeSeq', '');
          formData.append('photoMimeType', '');
          formData.append('filePath', '');
          formData.append('isEsignature', 'true');
          await axios.post(`${process.env.REACT_APP_API_URL}SaveEsignature`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + user_access_token,
            },
          });
        }

        if (photo) {
          if (typeof photo === 'object') {
            const files = photo;
            let count = 0;
            for (let i = 0; i < files.length; i++) {
              const file = files[i];
              const reader = new FileReader();
              reader.onload = async () => {
                const base64String = btoa(
                  new Uint8Array(reader.result).reduce(function (data, byte) {
                    return data + String.fromCharCode(byte);
                  }, '')
                );
                await axios.post(
                  `${process.env.REACT_APP_API_URL}SavePicture`,
                  {
                    photo: base64String ?? '',
                    userSeq: profileInformation?.userSeq ?? guid,
                    // logInUserSeq: user?.userSeq ?? '',
                    logInUserSeq: userSeq ?? '',
                    caseSeq: '',
                    fileName: Math.floor(Math.random() * 90000) + 10000 + file.name,
                    entityName: '',
                    photoInfo: '',
                    photoSeq: '00000000-0000-0000-0000-000000000000',
                    uploadType: '',
                    isResourceFile: 'false',
                    isUsercertificate: 'false',
                    recoverySeal: '',
                    photoTypeSeq: '',
                    certificateTypeSeq: '',
                    photoLabel: '',
                    resourceTypeSeq: '',
                    photoMimeType: '',
                    filePath: '',
                    isEsignature: 'false',
                  },
                  { headers: authHeader() }
                );
              };
              reader.readAsArrayBuffer(file);
            }
          } else {
            const updatedPhoto = photo?.split(',');
            await axios.post(
              `${process.env.REACT_APP_API_URL}SavePicture`,
              {
                photo: updatedPhoto?.[updatedPhoto.length - 1] ?? '',
                // userSeq: user?.userSeq,
                // logInUserSeq: user?.userSeq ?? '',
                userSeq: userSeq,
                logInUserSeq: userSeq ?? '',
                caseSeq: '',
                fileName: Math.floor(Math.random() * 90000) + 10000 + userName + '_filename.jpg',
                entityName: '',
                photoInfo: '',
                photoSeq: '00000000-0000-0000-0000-000000000000',
                uploadType: '',
                isResourceFile: 'false',
                isUsercertificate: 'false',
                recoverySeal: '',
                photoTypeSeq: '',
                certificateTypeSeq: '',
                photoLabel: '',
                resourceTypeSeq: '',
                photoMimeType: '',
                filePath: '',
                isEsignature: 'false',
              },
              { headers: authHeader() }
            );
          }
        }
        console.log('response.data.data', response.data.length > 0);
        if (response?.data?.status === 0) sendUserProfile(response.data.data);
        if (!response.data.message.includes('UNIQUE KEY')) navigate('/edit-user');
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const validationSchema = Yup.object({
    countySeqandDashboard: Yup.array().min(1, 'Atleast one jurisdiction is required'),
    roleseq: Yup.array().min(1, 'Atleast one Role is required'),
    userName: Yup.string().required('User Name is required'),
    userFirstName: Yup.string().required('First Name is required'),
    userLastName: Yup.string().required('Last Name is required'),
    initials: Yup.string().required('Initals are required'),
    departmentSeq: Yup.string().required('Department are required'),
    organizationseq: Yup.string().required('Organization is required'),
    primaryEmail: Yup.string().email('Invalid email').required('Primary email is required'),
    address: Yup.array()
      .of(
        Yup.object().shape({
          addressTypeSeq: Yup.string().nullable(),

          addressLine1: Yup.string().when(['addressTypeSeq'], addressTypeSeq => {
            if (
              addressTypeSeq != 'f8362705-4d9d-4fc9-afe9-486051fbc3cc' &&
              addressTypeSeq != 'eea37e74-0a50-419e-9de9-372d21d1f890'
            ) {
              return Yup.string().required('Address line 1 is required');
            }
          }),
          city: Yup.string().when(['addressTypeSeq'], addressTypeSeq => {
            if (
              addressTypeSeq != 'f8362705-4d9d-4fc9-afe9-486051fbc3cc' &&
              addressTypeSeq != 'eea37e74-0a50-419e-9de9-372d21d1f890'
            ) {
              return Yup.string().required('City is required');
            }
          }),
          contactItem: Yup.array()
            .of(
              Yup.object().shape({
                contactItemTypeSeq: Yup.string().required('Contact type is required'),
                contactItemDetails: Yup.string().required('Contact Details is required'),
              })
            )
            .min(1, 'Contact is a required field'),
        })
      )

      .min(1, 'Address is a required field')

      .nullable(),
    primaryMobileNo: Yup.string("Enter the caller's phone number")
      .nullable(false)
      .matches(
        '^(\\+\\d{1,2}\\s?)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$',
        'Please enter a valid phone number'
      ),
    // .required('Caller number is required'),
    // mdLicenseExpiryDate: Yup.mixed().when(['pathology'], (pathology) => {
    //   const updatedPathology = pathology?.[0]?.filter(
    //     (data) => data !== undefined
    //   );
    //   if (updatedPathology?.length > 0) {
    //     return Yup.string().required('Expiry Date is required');
    //   }
    // }),
    // ocsmeCertificateExpiryDate: Yup.mixed().when(
    //   ['ocsmeCertification'],
    //   (ocsmeCertification) => {
    //     const updatedOcsme = ocsmeCertification?.[0]?.filter(
    //       (ocsme) => ocsme !== undefined
    //     );
    //     if (updatedOcsme?.length > 0) {
    //       return Yup.string().required('Expiry Date is required');
    //     }
    //   }
    // ),
  });

  useEffect(() => {
    if (window.location.pathname.includes('edit-user') && profileInformation?.length === 0) {
      navigate('/sysadmin');
    }
    sendPhotoToViewModal(null);
  }, []);

  const pathname = window.location.pathname;
  const path = window.location.pathname.includes('add-user');
  return (
    <div className='container-fluid my-2 py-2'>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {formik => {
          return (
            <Grid>
              <UserHeaderContainer />
              <TabContext value={selectedTab}>
                <TabList value={selectedTab} onChange={handleTabChange}>
                  <Tab label='User Profile' value='1' />
                  <Tab
                    label='Login History'
                    value='2'
                    disabled={path}
                    sx={{
                      opacity: path ? 0.5 : null, 
                      color: path ? 'rgba(0, 0, 0, 0.5)' : null, 
                      pointerEvents: path ? 'none' : null,
                    }}
                  />
                  <Tab
                    label='Documents'
                    value='3'
                    disabled={path}
                    sx={{
                      opacity: path ? 0.5 : null, 
                      color: path ? 'rgba(0, 0, 0, 0.5)' : null, 
                      pointerEvents: path ? 'none' : null,
                    }}
                  />
                  <Tab
                    label='Notes'
                    value='4'
                    disabled={path}
                    sx={{
                      opacity: path ? 0.5 : null, 
                      color: path ? 'rgba(0, 0, 0, 0.5)' : null, 
                      pointerEvents: path ? 'none' : null,
                    }}
                  />
                  {/* <Tab label='CASE NOTES' /> */}
                </TabList>
                <TabPanel value='1' paddingTop={3}>
                  <>
                    <Form className='AddUser'>
                      <UserForm
                        title='userTitle'
                        userName='userName'
                        firstName='userFirstName'
                        lastName='userLastName'
                        suffix='userSuffix'
                        primaryEmail='primaryEmail'
                        initials='initials'
                        middleName='userMiddleName'
                        department='departmentSeq'
                        operationMode='operationMode'
                        organization='organizationseq'
                        userBarCode='barcode'
                        specialPrivilege='specialPrivilege'
                        primaryMobileNo='primaryMobileNo'
                        userid={profileInformation?.userName ?? ''}
                        // primaryCellularNumber={mobileNumber}
                        // handlePrimaryMobileNumber={(mobileNumber) =>
                        //   setMobileNumber(mobileNumber)
                        // }
                      />
                      <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                      <County
                        countyPermissions='countySeqandDashboard'
                        dashboardPermissions='dashboardPermissions'
                        countyPermissionsArray={formik?.values?.countySeqandDashboard}
                      />
                      <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                      <Roles name='roleseq' />
                      <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                      <AddressData
                        isAddressOn={true}
                        handleChange={data =>
                          formik?.handleChange({
                            target: {
                              value: data,
                              name: 'address',
                            },
                          })
                        }
                        addresses={formik?.values?.address}
                      />
                      <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                      <ContactTab
                        contactItem={formik?.values?.contactItem || []}
                        handleChange={data =>
                          formik?.handleChange({
                            target: {
                              value: data,
                              name: 'contactItem',
                            },
                          })
                        }
                        setContactDetailsError={err => setContactsError(err)}
                      />
                      <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                      {/* <ContactData
                        contactItem={formik?.values.contactItem || []}
                        handleChange={(data) =>
                          formik?.handleChange({
                            target: {
                              value: data,
                              name: 'contactItem',
                            },
                          })
                        }
                      /> */}
                      <PhysicianCertification
                        pathology='pathology'
                        mdLicenseNumber='mdLicenseNo'
                        ocsmeCertification='ocsmeCertification'
                        ocsmeExpiryDate='ocsmeCertificateExpiryDate'
                        expiryDate='mdLicenseExpiryDate'
                      />
                      <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                      <InvestigatorCertification
                        abmdiCertification='abmdiCertificateNO'
                        abmdiCertificationExpiryDate='abmdiCertificateExpiryDate'
                        boardCertifiedFellow='boardcertificatefellowNo'
                      />
                      <Button
                        variant='contained'
                        style={{ float: 'right', margin: '20px 0px' }}
                        onClick={() =>
                          Object.keys(formik.errors ?? {})?.length === 0 &&
                          formik.values.userName.length > 0
                            ? onSubmit(formik?.values, formik?.errors)
                            : checkForAddressAndContactValidation(formik?.errors)
                        }
                        disabled={!userPermissions?.edit}
                      >
                        {window.location.pathname.includes('edit-user') ? 'Update' : 'Save'}
                      </Button>
                      {openApiDialogBox ? (
                        <ApiNotification
                          openApiDialogBox={openApiDialogBox}
                          closeDialogBox={closeDialogBox}
                          dialogContents={dialogContents || ''}
                        />
                      ) : null}

                      {box ? (
                        <ApiNotification
                          openApiDialogBox={box}
                          closeDialogBox={() => setBox(false)}
                          dialogContents={errorMsg || ''}
                        />
                      ) : null}
                      {contactsError ? (
                        <ApiNotification
                          openApiDialogBox={contactsErrorModal}
                          closeDialogBox={() => setContactsErrorModal(false)}
                          dialogContents={contactsErrorMessage}
                        />
                      ) : null}
                    </Form>
                  </>
                </TabPanel>
                <TabPanel value='2'>
                  <LoginHistory userSeq={profileInformation?.userSeq} />
                </TabPanel>
                <TabPanel value='3'>
                  <Documents userSeq={profileInformation?.userSeq} />
                </TabPanel>
                <TabPanel value='4'>
                  <Notes userSeq={profileInformation?.userSeq} />
                </TabPanel>
              </TabContext>
            </Grid>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddUser;
