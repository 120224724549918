import { Box, Button, Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

export default function CaseViewAccessRestricted({ caseID }: { caseID: string }) {
  const navigate = useNavigate();

  return (
    <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
      <Box
        sx={{
          p: 3,
          pb: 1,
          width: '100%',
          position: 'sticky',
          top: 0,
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            display: { xs: 'block', sm: 'flex' },
            textAlign: { xs: 'center', sm: 'left' },
          }}
        >
          {/* CaseID */}
          <Typography variant='h5' component='h1'>
            <b>****** ****** ({caseID})</b>
          </Typography>
        </Box>

        <Divider sx={{ borderColor: '#555', m: 0, mt: 3 }} />
      </Box>

      <Box sx={{ p: 3, width: '100%' }}>
        <Typography variant='h3' component='h2' sx={{ m: 2 }}>
          Case Access Denied
        </Typography>
        <br />

        <Typography variant='body1' sx={{ m: 2 }}>
          <i>You do not have permission to view this case.</i>
        </Typography>
        <br />

        <Button
          variant='outlined'
          sx={{ m: 2, mt: 4 }}
          size='large'
          onClick={() => {
            navigate('/home');
          }}
        >
          Return To Home
        </Button>
      </Box>
    </Box>
  );
}
