import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Divider,
  Hidden,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';

export default function LMSCaseViewHeader({ handleMenuClick }) {
  const theme = useTheme();
  const formik = useFormikContext();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        p: 3,
        pb: 0,
        backgroundColor: theme.palette.background.default,
        width: '100%',
        position: 'sticky',
        top: 0,
        zIndex: 20,
      }}
    >
      <Box
        sx={{
          display: { xs: 'block', sm: 'flex' },
          textAlign: { xs: 'center', sm: 'left' },
        }}
      >
        <Stack>
          <Typography variant='h5' component='h1'>
            <b>{formik.values?.casesummary?.elabcasenumber ?? ''}</b> (
            {formik.values?.casesummary?.donorlastname},{' '}
            {formik.values?.casesummary?.donorfirstname})
          </Typography>
          <Typography variant='subtitle1' color='gray'>
            CMS: {formik.values?.casesummary?.mdxlabcaseid}
          </Typography>
        </Stack>

        <Box sx={{ ml: 'auto', mr: 2, mt: 1 }}>
          <Hidden mdUp>
            <Tooltip title='Show Case Menu'>
              <IconButton
                size={isMediumScreen ? 'medium' : 'large'}
                sx={{ width: 'max-content', height: 'max-content' }}
                onClick={handleMenuClick}
              >
                <MenuIcon fontSize={isMediumScreen ? 'medium' : 'large'} />
              </IconButton>
            </Tooltip>
          </Hidden>
        </Box>
      </Box>
      <Divider sx={{ borderColor: '#555', my: 3, pb: 0 }} />
    </Box>
  );
}
