import {
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  UniqueIdentifier,
  pointerWithin,
} from '@dnd-kit/core';
import { arraySwap } from '@dnd-kit/sortable';
import { Add, ChangeCircle, RemoveCircle } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Box as MUIBox,
  Grid as MUIGrid,
  Paper,
  Stack,
  Tooltip,
} from '@mui/material';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useDashboard } from './DashboardContext';
import { DashboardSilosLoadingSkeleton } from './DashboardSiloSkeleton';
import { DraggableSiloContainer } from './Grid/DraggableSiloContainer';

const AnimatedGrid = motion(MUIGrid);
const AnimatedBox = motion(MUIBox);
const AnimatedStack = motion(Stack);
const AnimatedTooltip = motion(Tooltip);
const AnimatedIconButton = motion(IconButton);
const buttonAnimationVariants = {
  rest: { scale: 1 },
  hover: { scale: 1.1 },
  pressed: { scale: 0.95 },
};

export function DashboardDataGrids() {
  const [activeRow, setActiveRow] = useState(null);

  function handleDragStart(event: any) {
    setActiveRow(event.active.id);
  }

  const {
    preferencesLoading,
    silosLoading,
    siloResponses,
    gridRows,
    isEditing,
    selectSilo,
    toggleAddSiloDrawer,
    setGridRows,
    toggleSwapSiloDrawer,
    handleRemoveSilo,
  } = useDashboard();

  function handleDragEnd({ activatorEvent, active, collisions, delta, over }: DragEndEvent) {
    if (!over) {
      setActiveRow(null);
      setOverId(null);
      return;
    }

    const activeId = active.id;
    const overId = over.id;

    const activeType: null | 'row' | 'item' = active.data?.current?.type ?? null;
    const overAccepts: null | 'row' | 'item' = over.data?.current?.accepts ?? null;

    if (activeType === null || overAccepts === null) {
      setActiveRow(null);
      setOverId(null);
      return;
    }

    if (
      (activeType === 'item' && overAccepts === 'row') ||
      (activeType === 'row' && overAccepts === 'item')
    ) {
      // Do a row swap
      const activeRowIndex = active.data?.current?.rowIndex ?? -1;
      const targetRowTarget = over.data?.current?.rowIndex ?? -1;
      if (activeRowIndex < 0 || targetRowTarget < 0) {
        setActiveRow(null);
        setOverId(null);
        return;
      }
      const swappedRows = arraySwap(gridRows, activeRowIndex, targetRowTarget);
      setGridRows(swappedRows);
      setActiveRow(null);
      setOverId(null);
      return;
    }

    if (activeType === 'item' && overAccepts === 'item') {
      // Do an item to item swap
      const activeRowIndex = active.data?.current?.rowIndex ?? -1;
      const targetRowIndex = over.data?.current?.rowIndex ?? -1;

      const activeRow = gridRows[activeRowIndex] ?? null;
      const targetRow = gridRows[targetRowIndex] ?? null;

      const activeRowActiveItemIndex = activeRow?.items.findIndex(acr => acr.key === activeId);
      const targetRowTargetItemIndex = targetRow?.items.findIndex(acr => acr.key === overId);

      if (activeType === 'item' && overAccepts === 'item') {
        // Early exit if indices are invalid
        if (activeRowIndex < 0 || targetRowIndex < 0) {
          setActiveRow(null);
          setOverId(null);
          return;
        }

        // Directly swap items within the same row to avoid unnecessary operations
        if (activeRowIndex === targetRowIndex) {
          const newRowItems = [...gridRows[activeRowIndex].items];
          [newRowItems[activeRowActiveItemIndex], newRowItems[targetRowTargetItemIndex]] = [
            newRowItems[targetRowTargetItemIndex],
            newRowItems[activeRowActiveItemIndex],
          ];

          const newGridRows = [...gridRows];
          newGridRows[activeRowIndex] = { ...newGridRows[activeRowIndex], items: newRowItems };
          setGridRows(newGridRows);
        } else {
          // For different rows, only modify affected rows
          const newGridRows = [...gridRows];
          const fromItem = newGridRows[activeRowIndex].items[activeRowActiveItemIndex];
          const toItem = newGridRows[targetRowIndex].items[targetRowTargetItemIndex];

          // Swap items between rows
          newGridRows[activeRowIndex].items[activeRowActiveItemIndex] = toItem;
          newGridRows[targetRowIndex].items[targetRowTargetItemIndex] = fromItem;
          setGridRows(newGridRows);
        }

        setActiveRow(null);
        setOverId(null);
        return;
      }
    }
  }

  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  function handleDragMove({ over }: DragMoveEvent) {
    setOverId(over?.id ?? null);
  }

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
      collisionDetection={pointerWithin}
    >
      {silosLoading || preferencesLoading ? (
        <DashboardSilosLoadingSkeleton />
      ) : (
        <React.Fragment>
          <Box component='div' p={2}>
            <LayoutGroup>
              <AnimatedGrid layout spacing={2} container>
                <AnimatePresence>
                  {gridRows.map((row, rowIdx) =>
                    row.items.map((rowLayout, colIdx) => {
                      const response = siloResponses.find(
                        s => s.dashboardSiloSeq === rowLayout.key
                      );
                      const { data, loading } = response ?? { data: [], loading: false };
                      const s = rowLayout.silo;

                      if (s === null) return <ErrorLoadingSilo />;

                      const isOverThisSilo = overId === s.id && activeRow !== s.id;

                      return (
                        <AnimatedGrid
                          whileDrag={{ scale: 0.8, opacity: 0.7, zIndex: 9999 }}
                          dragConstraints={{ left: 0, top: 0, right: 0, bottom: 0 }}
                          drag={isEditing}
                          dragElastic={1}
                          dragSnapToOrigin={true}
                          dragPropagation={false}
                          draggable={isEditing}
                          layout
                          item
                          container
                          lg={s.defaultSize}
                          key={s.dashboardSiloSeq}
                        >
                          <DraggableSiloContainer
                            size={s.defaultSize}
                            id={s.dashboardSiloSeq}
                            rowIndex={rowIdx}
                            itemIndex={colIdx}
                          >
                            <AnimatedBox
                              component='div'
                              boxShadow={
                                isOverThisSilo
                                  ? '0 0 0 5px rgba(137, 207, 240, 1), 0 0 8px 2px rgba(0, 0, 0, 0.5)' // Light blue border
                                  : 'none'
                              }
                              borderRadius='10px'
                            >
                              <s.Component data={data ?? []} isLoading={loading ?? false} />
                              {isEditing && (
                                <AnimatedStack
                                  layout
                                  direction='row'
                                  sx={{
                                    bottom: -5,
                                    cursor: 'pointer', // changes cursor type on hover
                                    position: 'absolute',
                                  }}
                                >
                                  <AnimatedIconButton
                                    layout
                                    variants={buttonAnimationVariants}
                                    initial='rest'
                                    whileHover='hover'
                                    whileTap='pressed'
                                    onTap={e => {
                                      e.stopPropagation();
                                      selectSilo(s);
                                      toggleSwapSiloDrawer({ open: true, fromSilo: s });
                                    }}
                                  >
                                    <AnimatedTooltip layout title='Click to change silo' arrow>
                                      <ChangeCircle sx={{ fontSize: 44 }} />
                                    </AnimatedTooltip>
                                  </AnimatedIconButton>
                                  <AnimatedIconButton
                                    layout
                                    variants={buttonAnimationVariants}
                                    initial='rest'
                                    whileHover='hover'
                                    whileTap='pressed'
                                    onTap={e => {
                                      e.stopPropagation();
                                      handleRemoveSilo(s);
                                    }}
                                  >
                                    <AnimatedTooltip layout title='Click to remove silo' arrow>
                                      <RemoveCircle sx={{ fontSize: 44 }} />
                                    </AnimatedTooltip>
                                  </AnimatedIconButton>
                                </AnimatedStack>
                              )}
                            </AnimatedBox>
                          </DraggableSiloContainer>
                        </AnimatedGrid>
                      );
                    })
                  )}
                  {isEditing && (
                    <AnimatedGrid layout item xs={12} sm={12} md={12} lg={6}>
                      <Paper
                        variant='elevation'
                        elevation={3}
                        sx={{
                          height: 236,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Button
                          size='large'
                          variant='text'
                          onClick={e => {
                            e.stopPropagation();
                            toggleAddSiloDrawer(true);
                          }}
                          startIcon={<Add fontSize='large' />}
                        >
                          Add Silo
                        </Button>
                      </Paper>
                    </AnimatedGrid>
                  )}
                </AnimatePresence>
              </AnimatedGrid>
            </LayoutGroup>
          </Box>
        </React.Fragment>
      )}
    </DndContext>
  );
}

function ErrorLoadingSilo() {
  return (
    <AnimatedGrid
      whileDrag={{ scale: 0.8, opacity: 0.7, zIndex: 9999 }}
      dragConstraints={{ left: 0, top: 0, right: 0, bottom: 0 }}
      dragElastic={1}
      dragSnapToOrigin={true}
      dragPropagation={true}
      layout
      item
      container
    >
      <AnimatedBox borderRadius='10px'>
        <p>Error loading silo</p>
      </AnimatedBox>
    </AnimatedGrid>
  );
}
