import { Grid, Skeleton, Stack } from '@mui/material';
import { motion } from 'framer-motion';

const AnimatedGrid = motion(Grid);

export function DashboardSiloSkeleton({ gridheight }: { gridheight: number }) {
  return (
    <Stack spacing={0}>
      <Skeleton
        variant='rounded'
        animation='wave'
        height={30}
        sx={{ borderRadius: '10px 10px 3px 3px' }}
      />
      <Skeleton variant='text' animation='wave' sx={{ fontSize: '2rem' }} />
      <Skeleton
        variant='rounded'
        animation='wave'
        height={gridheight - 40}
        sx={{ borderRadius: '3px 3px 10px 10px' }}
      />
    </Stack>
  );
}

export const DashboardSilosLoadingSkeleton = () => {
  return (
    <AnimatedGrid layout spacing={2} p={2}>
      <AnimatedGrid
        item
        pb={1}
        xs={1}
        md={1} // Ensure silos with size 2 span the entire row
        key={'loading-1'}
        id={'loading-1'}
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{
          transition: 'outline 0.2s ease', // Optional: smooth transition for the outline,
        }}
      >
        <DashboardSiloSkeleton gridheight={120} key='loading-1' />
      </AnimatedGrid>
      <AnimatedGrid
        item
        py={1}
        xs={1}
        md={1} // Ensure silos with size 2 span the entire row
        key={'loading-2'}
        id={'loading-2'}
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{
          transition: 'outline 0.2s ease', // Optional: smooth transition for the outline,
        }}
      >
        <DashboardSiloSkeleton gridheight={120} key='loading-2' />
      </AnimatedGrid>
      <AnimatedGrid
        item
        py={1}
        xs={1}
        md={2} // Ensure silos with size 2 span the entire row
        key={'loading-3'}
        id={'loading-3'}
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{
          transition: 'outline 0.2s ease', // Optional: smooth transition for the outline,
        }}
      >
        <DashboardSiloSkeleton gridheight={120} key='loading-3' />
      </AnimatedGrid>
    </AnimatedGrid>
  );
};
