import { CancelRounded } from '@mui/icons-material';
import { Autocomplete, Box, Checkbox, Chip, Grid, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';

function getValueByNestedKey(object, key) {
  const keys = key.split('.');
  return keys.reduce((obj, k) => obj?.[k], object);
}

const sortOptionsAlphabetically = options => {
  return options.sort((a, b) => {
    if (a.optionName && b.optionName) {
      return a.optionName.localeCompare(b.optionName);
    }
    return 0;
  });
};

export default function CaseViewAutocompleteCheckbox({
  xs,
  sm,
  md,
  lg,
  xl,
  options,
  authorizedToEdit = true,
  fieldName,
  label,
  multiple = false,
}) {
  const formik = useFormikContext();
  // const fieldVisibilityStatus =
  //   formik?.status?.fieldVisibilityStatus &&
  //   formik?.status?.fieldVisibilityStatus[fieldName.split('.')[0]]?.[fieldName.split('.')[1]];
  // const moduleVisibilityStatus =
  //   formik?.status?.moduleVisibilityStatus &&
  //   formik?.status?.moduleVisibilityStatus[fieldName.split('.')[0]];
  const fieldValue = formik?.values && getValueByNestedKey(formik?.values, fieldName);
  const fieldTouched = formik?.touched && getValueByNestedKey(formik?.touched, fieldName);
  const fieldErrors = formik?.errors && getValueByNestedKey(formik?.errors, fieldName);
  // const displayStyle = fieldVisibilityStatus?.visible ? 'block' : 'none';

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} /* sx={{ display: displayStyle }} */>
      <Box
        sx={{
          position: 'relative',
          display: 'inline-block',
          width: '100%',
        }}
      >
        <Autocomplete
          disableCloseOnSelect={true}
          multiple={multiple}
          disabled={
            !formik.status.editing || !authorizedToEdit //||
            // !fieldVisibilityStatus?.enabled ||
            // !moduleVisibilityStatus?.enabled
          }
          fullWidth
          id={fieldName}
          name={fieldName}
          options={sortOptionsAlphabetically(options)}
          value={multiple ? fieldValue ?? [] : fieldValue}
          onChange={(e, value) => formik.setFieldValue(fieldName, value)}
          isOptionEqualToValue={(option, value) =>
            option?.optionSeq?.toUpperCase() === value?.optionSeq?.toUpperCase()
          }
          getOptionLabel={option => option?.optionName}
          renderInput={params => <TextField {...params} label={null} />}
          renderTags={(v, getTagProps, ownerState) => {
            return v.map((option, index) => {
              return (
                <Chip
                  {...getTagProps({ index })}
                  sx={{
                    height: 'auto',
                    '& .MuiChip-label': {
                      display: 'flex',
                      alignItems: 'center',
                    },
                    '& .MuiChip-deleteIcon': {
                      fontSize: '16px',
                    },
                  }}
                  label={option.optionName}
                  deleteIcon={<CancelRounded />}
                />
              );
            });
          }}
          renderOption={(props, option, state, ownerState) => {
            return (
              <li {...props}>
                <Checkbox
                  icon={<MdCheckBoxOutlineBlank />}
                  checkedIcon={<MdOutlineCheckBox />}
                  style={{ marginRight: 8 }}
                  checked={state.selected}
                  onChange={event => event.stopPropagation()}
                />
                {String(option?.optionName)}
              </li>
            );
          }}
          renderInput={params => <TextField {...params} label={label} />}
          error={fieldTouched && Boolean(fieldErrors)}
          helperText={fieldTouched ? fieldErrors?.toString() : ''}
        />
        {(!formik?.status?.editing || !authorizedToEdit) && (
          <Box
            onClick={() =>
              formik.setStatus({
                ...formik.status,
                disabledClickCount: formik.status?.disabledClickCount + 1,
              })
            }
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              cursor: 'pointer',
            }}
          />
        )}
      </Box>
    </Grid>
  );
}
