import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import InboxIcon from '@mui/icons-material/Inbox';
import ListAltIcon from '@mui/icons-material/ListAlt';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ScienceIcon from '@mui/icons-material/Science';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import CustomDrawer from '../../utils/components/CustomDrawer';
import CustomHeader from '../../utils/components/CustomHeader';
import CustomTabPanel from '../../utils/components/CustomTabPanel';
import BulkFileUpload from '../../views/CMSAdmin/CaseAdminModules/BulkFileUploadModule';
import ELabPMBatchesView from './LMSModules/eLabModules/ELabPMBatchesView';
import ELabPMCaseListView from './LMSModules/eLabModules/ELabPMCaseListView';
import TreeDrawer from '../../utils/components/TreeDrawer';
import TreeTabPanel from '../../utils/components/TreeTabPanel';
import ELabLEDTCasesView from './LMSModules/eLabModules/ELabLEDTCasesView';
import BeastLEDTCasesView from './LMSModules/BeastModules/BeastLEDTCasesView';
import BeastInvoicingView from './LMSModules/InvoicingModules/BeastInvoicingView';
import ELabLEDTInvoicingView from './LMSModules/InvoicingModules/ELabLEDTInvoicingView';

export default function LMSView() {
  const [currentTab, setCurrentTab] = useState('elab_pm_cases');
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  // commented out items that are under construction
  const drawerContents = [
    {
      id: 'elab',
      label: 'ELAB',
      children: [
        { id: 'elab_pm_cases', label: 'PM CASES' },
        // { id: 'elab_ledt_cases', label: 'LEDT CASES' },
        { id: 'elab_batches', label: 'ELAB BATCHES' },
      ],
    },
    // {
    //   id: 'beast',
    //   label: 'BEAST',
    //   children: [{ id: 'beast_ledt_cases', label: 'LEDT CASES' }],
    // },
    // {
    //   id: 'invoicing',
    //   label: 'INVOICING',
    //   children: [
    //     { id: 'elab_ledt_invoicing', label: 'ELAB LEDT INVOICING' },
    //     { id: 'beast_invoicing', label: 'BEAST INVOICING' },
    //   ],
    // },
    {
      id: 'bulk_uploads',
      label: 'BULK UPLOAD',
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <TreeDrawer
        drawerContents={drawerContents}
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />

      <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <TreeTabPanel value={currentTab} index='elab_pm_cases' sx={{ margin: 0 }}>
          <CustomHeader
            title='eLab PM Case List'
            description='View all current eLab post-mortem cases'
            handleMenuClick={handleMenuClick}
          />
          <ELabPMCaseListView />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='elab_ledt_cases' sx={{ margin: 0 }}>
          <CustomHeader
            title='eLab LEDT Case List'
            description='View all current eLab LEDT cases'
            handleMenuClick={handleMenuClick}
          />
          <ELabLEDTCasesView />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='elab_batches' sx={{ margin: 0 }}>
          <CustomHeader
            title='eLab PM Specimen Batches'
            description='View all batches of eLab specimes for testing'
            handleMenuClick={handleMenuClick}
          />
          <ELabPMBatchesView />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='beast_ledt_cases' sx={{ margin: 0 }}>
          <CustomHeader
            title='BEAST LEDT Cases'
            description='View and manage all BEAST LEDT cases'
            handleMenuClick={handleMenuClick}
          />
          <BeastLEDTCasesView />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='beast_invoicing' sx={{ margin: 0 }}>
          <CustomHeader
            title='BEAST Invoicing'
            description='Manage invoicing for BEAST'
            handleMenuClick={handleMenuClick}
          />
          <BeastInvoicingView />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='elab_ledt_invoicing' sx={{ margin: 0 }}>
          <CustomHeader
            title='eLab LEDT Invoicing'
            description='Manage invoicing for eLab LEDT'
            handleMenuClick={handleMenuClick}
          />
          <ELabLEDTInvoicingView />
        </TreeTabPanel>

        <TreeTabPanel value={currentTab} index='bulk_uploads' sx={{ margin: 0 }}>
          <CustomHeader
            title='Bulk Upload Tox to CMS'
            description='Upload multiple final tox reports (PDF) to CMS at once'
            handleMenuClick={handleMenuClick}
          />
          <BulkFileUpload />
        </TreeTabPanel>
      </Box>
    </Box>
  );
}
