import { Button, Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import * as React from 'react';
import { EffectCoverflow, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDashboard } from './DashboardContext';
import { PreviewSilo } from './PreviewSilo';
import { defaultSiloMap } from './dashboard.constants';

export function SwapSiloDrawer() {
  const {
    silos,
    swapSiloDrawerOpen,
    toggleSwapSiloDrawer,
    handleSwapSilo,
    selectedSilo,
    siloResponses,
  } = useDashboard();

  const handleOnClose = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    const isKeyDownEvent = event.type === 'keydown';
    const isTabEvent = (event as React.KeyboardEvent).key === 'Tab';
    const isShiftEvent = (event as React.KeyboardEvent).key === 'Shift';

    if (isKeyDownEvent && (isTabEvent || isShiftEvent)) {
      return;
    }

    toggleSwapSiloDrawer({ open });
  };

  const silosRemaining = defaultSiloMap.filter(
    defaultSilo => !silos.find(s => s.dashboardSiloSeq === defaultSilo.dashboardSiloSeq)
  );

  return (
    <React.Fragment key={'bottom'}>
      <Drawer anchor={'bottom'} open={swapSiloDrawerOpen} onClose={handleOnClose(false)}>
        <Box
          component='div'
          sx={{ height: '500px' }}
          role='presentation'
          onKeyDown={handleOnClose(false)}
        >
          <Typography variant='h6' fontWeight='bold' px={2} pt={2}>
            {`Swap ${selectedSilo?.dashboardSiloName ?? 'Unknown silo'} `}
          </Typography>

          {silosRemaining.length > 0 ? (
            <Swiper
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={false}
              slidesPerView={1.5}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 0,
                modifier: 2,
                slideShadows: false,
              }}
              keyboard
              pagination={true}
              modules={[EffectCoverflow, Pagination, Scrollbar]}
              breakpoints={{
                640: {
                  slidesPerView: 2.5, // Show 3 slides for larger screens
                },
              }}
              style={{
                padding: 2,
              }}
            >
              {silosRemaining.map(silo => {
                const response =
                  siloResponses.find(s => s.dashboardSiloSeq === silo.dashboardSiloSeq) || null;

                return (
                  <SwiperSlide>
                    <PreviewSilo
                      title={silo.dashboardSiloName}
                      key={silo.dashboardSiloSeq}
                      onClick={e => {
                        e.stopPropagation();
                        console.log('Attempting swap');
                        if (selectedSilo) {
                          handleSwapSilo({ from: selectedSilo, to: silo });
                          toggleSwapSiloDrawer({ open: false });
                        }
                      }}
                    >
                      <silo.Component
                        data={response ? response.data : []}
                        isLoading={response ? response.loading : false}
                      />
                    </PreviewSilo>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <React.Fragment>
              <Typography variant='body1' p={2}>
                You've already added every silo to your dashboard, there's nothing to swap{' '}
                {selectedSilo?.dashboardSiloName} with.
                <br />
                Try rearranging them instead.
              </Typography>
              <Divider component='hr' variant='middle' />
              <Button
                variant='text'
                sx={{ p: 2, mx: 2 }}
                onClick={e => {
                  e.stopPropagation();
                  toggleSwapSiloDrawer({ open: false });
                }}
              >
                Close
              </Button>
            </React.Fragment>
          )}
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
