import {
  AddCircleOutlineOutlined,
  CancelRounded,
  CheckRounded,
  DeleteRounded,
  EditRounded,
  SaveRounded,
} from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputBase,
  InputBaseProps,
  Paper,
  Popper,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { randomId } from '@mui/x-data-grid-generator';
import {
  DataGridPremium as DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridRenderEditCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowOrderChangeParams,
  GridRowParams,
  GridRowsProp,
  GridToolbarContainer,
  MuiEvent,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import React, { useState } from 'react';
import { useAuth } from '../utils/auth/AuthService';
import CustomPagination from '../utils/components/CustomPagination.jsx';
import {
  GlossaryAPI,
  UpdateGlossaryItemDto,
} from '../views/Resources/ResourcesModules/Glossary/GlossaryAPI';

function EditTextarea(props: GridRenderEditCellParams<any, string>) {
  const { id, field, value, colDef, hasFocus } = props;
  const [valueState, setValueState] = React.useState(value);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();
  const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null);
  const apiRef = useGridApiContext();

  React.useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [hasFocus, inputRef]);

  const handleRef = React.useCallback((el: HTMLElement | null) => {
    setAnchorEl(el);
  }, []);

  const handleChange = React.useCallback<NonNullable<InputBaseProps['onChange']>>(
    event => {
      const newValue = event.target.value;
      setValueState(newValue);
      apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 }, event);
    },
    [apiRef, field, id]
  );

  return (
    <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement='bottom-start'>
          <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
            <InputBase
              multiline
              value={valueState}
              sx={{ textarea: { resize: 'both' }, width: '100%' }}
              onChange={handleChange}
              inputRef={ref => setInputRef(ref)}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
}

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setModifiedRows: React.Dispatch<React.SetStateAction<UpdateGlossaryItemDto[]>>;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  glossaryCategorySeq: string;
  editing: boolean;
  saving: boolean;
  handleStartEditing: () => {};
  handleSave: () => {};
}

function EditToolbar(props: EditToolbarProps) {
  const {
    setRows,
    setRowModesModel,
    setModifiedRows,
    glossaryCategorySeq,
    editing,
    saving,
    handleStartEditing,
    handleSave,
  } = props;

  const handleClick = () => {
    const id = randomId();
    setRows(oldRows => {
      const newRow: UpdateGlossaryItemDto = {
        itemSeq: id,
        term: '',
        definition: '',
        isNew: true,
        glossaryCategorySeq,
        isDeleted: false,
        isUpdated: false,
        order: oldRows.length + 1,
      };

      const updatedRows = [...oldRows, newRow];
      setModifiedRows(prevModifiedRows => [...prevModifiedRows, newRow]);

      return updatedRows;
    });

    setRowModesModel(oldModel => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'term' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Stack direction='row' justifyContent='space-between' width='100%'>
        {editing && (
          <Button
            color='primary'
            startIcon={<AddCircleOutlineOutlined />}
            onClick={handleClick}
            disabled={saving}
          >
            Add Definition
          </Button>
        )}

        {editing ? (
          <Button
            color='primary'
            variant='text'
            startIcon={saving ? <CircularProgress size={24} /> : <SaveRounded />}
            sx={{ marginLeft: 'auto' }}
            onClick={handleSave}
          >
            {saving ? `Saving..` : `Save Changes`}
          </Button>
        ) : (
          <Button
            color='primary'
            variant='text'
            startIcon={saving ? <CircularProgress size={24} /> : <EditRounded />}
            sx={{ marginLeft: 'auto' }}
            onClick={handleStartEditing}
          >
            {saving ? `Saving..` : `Edit`}
          </Button>
        )}
      </Stack>
    </GridToolbarContainer>
  );
}

type GlossaryItemRow = UpdateGlossaryItemDto;

export default function GlossaryDataGrid({
  initialRows,
  glossaryCategorySeq = '',
  title = '',
  description = '',
  pageSize = null,
}: {
  initialRows: GlossaryItemRow[];
  glossaryCategorySeq: string;
  title: string;
  description: string;
  pageSize?: number | null;
}) {
  const auth = useAuth();

  const [titleState, setTitleState] = useState(title);
  const [descriptionState, setDescriptionState] = useState(description);

  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const [modifiedRows, setModifiedRows] = useState<UpdateGlossaryItemDto[]>([]);

  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [rows, setRows] = useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const [paginationModel, setPaginationModel] = useState({
    pageSize: pageSize != null ? pageSize : 8,
    page: 0,
  });

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [deleteRowId, setDeleteRowId] = React.useState<GridRowId | null>(null);

  const handleStartEditing = () => {
    setEditing(true);
  };

  const handleSave = async () => {
    setSaving(true);
    setErrorMessage('');
    setShowErrorSnackbar(false);

    try {
      const savedCategory = await GlossaryAPI.updateCategory(auth.user?.accessToken!, {
        glossaryCategorySeq,
        name: titleState,
        description: descriptionState,
        items: modifiedRows.filter(item => !(item.isNew && item.isDeleted)),
      });
      setRows(
        savedCategory.items
          .map(item => ({
            ...item,
            isDeleted: false,
            isNew: false,
            isUpdated: false,
          }))
          .sort((a, b) => a.order - b.order)
      );
      setTitleState(savedCategory.name);
      setDescriptionState(savedCategory.description);
      setModifiedRows([]);
      setShowSuccessSnackbar(true);
    } catch (error) {
      console.log(error);
      setErrorMessage(`${error}`);
      setShowErrorSnackbar(true);
    } finally {
      setEditing(false);
      setSaving(false);
    }
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDeleteRowId(id);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmation = () => {
    if (deleteRowId) {
      const deletedRow = rows.find(row => row.itemSeq === deleteRowId) ?? null;
      if (deletedRow) {
        const deletedRowOrder = deletedRow.order;
        setRows(prevRows => {
          const updatedRows = prevRows.filter(row => row.itemSeq !== deleteRowId);
          return updatedRows.map(row => {
            if (row.order > deletedRowOrder) {
              return { ...row, order: row.order - 1 };
            }
            return row;
          });
        });

        setModifiedRows(prevModifiedRows => [
          ...prevModifiedRows,
          { ...deletedRow, isDeleted: true, order: -1 },
        ]);
      }
      setDeleteConfirmationOpen(false);
      setDeleteRowId(null);
    }
  };

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    if (!editing || !saving) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find(row => row.itemSeq === id);
    if (editedRow!.isNew) {
      setRows(rows.filter(row => row.itemSeq !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    // @ts-ignore
    setRows(rows.map(row => (row.itemSeq === newRow.itemSeq ? newRow : row)));

    setModifiedRows(prevModifiedRows => {
      const existingRowIndex = prevModifiedRows.findIndex(row => row.itemSeq === newRow.itemSeq);
      if (existingRowIndex !== -1) {
        return prevModifiedRows.map((row, index) =>
          index === existingRowIndex
            ? {
                ...row,
                ...newRow,
                isUpdated: true,
                isNew: newRow.isNew,
                glossaryCategorySeq: row.glossaryCategorySeq,
                itemSeq: row.itemSeq,
                term: newRow.term,
                definition: newRow.definition,
                order: row.order,
                isDeleted: row.isDeleted,
              }
            : row
        );
      }
      return [
        ...prevModifiedRows,
        {
          ...newRow,
          isUpdated: true,
          isNew: newRow.isNew,
          glossaryCategorySeq: newRow.glossaryCategorySeq,
          itemSeq: newRow.itemSeq,
          term: newRow.term,
          definition: newRow.definition,
          order: newRow.order,
          isDeleted: false,
        },
      ];
    });

    return newRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowOrderChange = (params: GridRowOrderChangeParams) => {
    const { targetIndex, oldIndex } = params;

    setRows(prevRows => {
      const rowToMove = prevRows[oldIndex];
      const updatedRows = [...prevRows];
      updatedRows.splice(oldIndex, 1);
      updatedRows.splice(targetIndex, 0, rowToMove);

      const reorderedRows = updatedRows.map((row, index) => ({
        ...row,
        order: index + 1,
      }));

      setModifiedRows(prevModifiedRows => {
        const updatedModifiedRows = reorderedRows.map(row => {
          const existingRow = prevModifiedRows.find(
            modifiedRow => modifiedRow.itemSeq === row.itemSeq
          );
          if (existingRow) {
            return {
              ...existingRow,
              order: row.order,
              isUpdated: true,
            };
          }
          return {
            ...row,
            isUpdated: true,
          };
        });

        return updatedModifiedRows;
      });

      return reorderedRows;
    });
  };

  const handleCellDoubleClick = (params: GridCellParams) => {
    if (editing) {
      const { id, field } = params;
      const currentlyEditingRow = Object.keys(rowModesModel).find(
        rowId => rowModesModel[rowId].mode === GridRowModes.Edit
      );

      if (currentlyEditingRow) {
        // If a row is currently being edited, save the changes first
        setRowModesModel(prevModel => ({
          ...prevModel,
          [currentlyEditingRow]: { mode: GridRowModes.View },
        }));
      }

      setRowModesModel(prevModel => ({
        ...prevModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: field },
      }));
    }
  };

  const handleCellKeyDown = (params: GridCellParams, event: MuiEvent<React.KeyboardEvent>) => {
    if (editing && event.key === 'Enter') {
      const { id } = params;
      setRowModesModel(prevModel => ({
        ...prevModel,
        [id]: { mode: GridRowModes.View },
      }));
    }
  };

  const editModeColumns: GridColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title='Finish editing'>
                  <CheckRounded />
                </Tooltip>
              }
              label='Save'
              className='textPrimary'
              onClick={handleSaveClick(id)}
              disabled={saving}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title='Discard changes'>
                  <CancelRounded />
                </Tooltip>
              }
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id)}
              color='inherit'
              disabled={saving}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <Tooltip title='Edit term'>
                <EditRounded />
              </Tooltip>
            }
            label='Edit'
            className='textPrimary'
            onClick={handleEditClick(id)}
            color='inherit'
            disabled={saving}
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title='Delete term'>
                <DeleteRounded />
              </Tooltip>
            }
            label='Delete'
            onClick={handleDeleteClick(id)}
            color='inherit'
            disabled={saving}
          />,
        ];
      },
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'term',
      headerName: 'Term',
      flex: 2,
      editable: !saving,
      renderHeader: () => <strong>Term</strong>,
    },
    {
      field: 'definition',
      headerName: 'Definition',
      flex: 5,
      editable: !saving,
      renderHeader: () => <strong>Definition</strong>,
      renderEditCell: params => <EditTextarea {...params} />,
    },
    ...(editing ? editModeColumns : []),
  ];

  return (
    <Box
      component='div'
      sx={{
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <Stack>
        {editing ? (
          <>
            <TextField
              variant='standard'
              value={titleState}
              onChange={e => setTitleState(e.target.value)}
              sx={{ fontWeight: 'bold', mb: 1 }}
              component='h6'
            />
            <TextField
              variant='standard'
              value={descriptionState}
              onChange={e => setDescriptionState(e.target.value)}
              sx={{ mb: 1 }}
            />
          </>
        ) : (
          <>
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
              {titleState}
            </Typography>
            <Typography variant='subtitle1'>{descriptionState}</Typography>
          </>
        )}
      </Stack>
      <DataGrid
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
          mt: 1,
        }}
        rows={rows}
        rowReordering={editing}
        columns={columns}
        getRowId={row => row.itemSeq}
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 400}
        autoHeight
        editMode='row'
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowOrderChange={handleRowOrderChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        onCellDoubleClick={handleCellDoubleClick}
        onCellKeyDown={handleCellKeyDown}
        processRowUpdate={processRowUpdate}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 20]}
        pagination={!editing}
        slots={{
          pagination: CustomPagination,
          toolbar: props =>
            auth.user?.roleCheck(['d1582600-5f86-49dd-bab7-6f7205bfeffd']) && (
              <EditToolbar
                setRows={() => {}}
                setRowModesModel={() => {}}
                glossaryCategorySeq=''
                setModifiedRows={() => {}}
                editing={false}
                saving={false}
                handleStartEditing={() => null}
                handleSave={() => null}
                {...props}
              />
            ),
        }}
        slotProps={{
          toolbar: {
            setRows,
            setRowModesModel,
            setModifiedRows,
            glossaryCategorySeq,
            editing,
            saving,
            handleStartEditing,
            handleSave,
          },
        }}
      />
      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this definition? This cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button fullWidth onClick={() => setDeleteConfirmationOpen(false)} color='primary'>
            Cancel
          </Button>
          <Button variant='outlined' fullWidth onClick={handleDeleteConfirmation} color='error'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSuccessSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setShowSuccessSnackbar(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          <strong>{titleState} </strong> succesfully saved.
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={5000}
        onClose={() => setShowErrorSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowErrorSnackbar(false)} severity='error' sx={{ width: '100%' }}>
          <AlertTitle>
            Error saving <strong>{title}</strong>:
          </AlertTitle>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
