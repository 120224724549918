import { Stack } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-premium';
import { PrintFileButton } from '../../utils/functions/triggerPrintDialog';

export function DownloadFileCell(
  params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
) {
  return (
    <Stack spacing={1} alignItems='left' justifyContent='left' whiteSpace='normal'>
      {Array.isArray(params.value) &&
        params.value?.length > 0 &&
        params.value?.map((file: any, index: any) => (
          <PrintFileButton
            fileSeq={file.fileseq}
            filename={file.filename}
            caseid={params.row.caseid}
            buttonSx={{
              width: '100%',
              maxWidth: params.colDef.width,
              whiteSpace: 'normal',
              '& .MuiButton-root': {
                whiteSpace: 'normal',
                height: 'auto',
                padding: '6px',
              },
            }}
          />
        ))}
    </Stack>
  );
}
