import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../../utils/auth/AuthService';
import SiloDataGrid from '../../utils/components/SiloDataGrid';
import { DashboardDataGridProps } from './useDashboardDataGridAPI.hook';
// @ts-ignore
import SearchCase from '../../utils/components/SearchCase.jsx';
import { useDashboard } from '../../views/Dashboard/DashboardContext';
import { DecedentNameCell } from './DecedentNameCell';

export function TransferredCasesDataGrid({ data, isLoading }: DashboardDataGridProps) {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState<SelectedRowType | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [caseUpdatedInfo, setCaseUpdatedInfo] = useState<UpdatedInfo | null>(null);
  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [transferCaseInfo, setTransferCaseInfo] = useState<string | null>(null);
  const [transferCaseError, setTransferCaseError] = useState<string | null>(null);
  const [rejectReasonOptions, setRejectReasonOptions] = useState<Options[]>([]);
  const [rejectReason, setRejectReason] = useState<Options | null>(null);
  const [otherRejectReason, setOtherRejectReason] = useState<string | null>(null);
  const theme = useTheme();
  const { REACT_APP_API_URL } = process.env;
  const { user } = useAuth();
  const isXSScreen = useMediaQuery(theme.breakpoints.only('xs'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.only('sm'));
  const [linkedCase, setLinkedCase] = useState({ caseid: null, caseSeq: null });
  const [isTransferInProgress, setIsTransferInProgress] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const { reloadSiloData } = useDashboard();

  const handleCaseClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
  ) => {
    event.stopPropagation();
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  interface Options {
    optionName: string;
    optionSeq: string;
  }

  interface SelectedRowType {
    caseid: string;
    caseseq: string;
    decedentname: string;
    meAction: string;
    suspectedDrugToxicity: boolean;
    suspectedInfectiousDisease: boolean;
    suspectedOpioid: boolean;
    jurisdictionFrom: string;
    jurisdictionTo: Options;
    transferReason: string;
    transferNotes: string;
  }

  interface UpdatedInfo {
    createdOn: string;
    createdBy: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
  }

  const handleLinkedCaseIdChange = (
    newLinkedCase: React.SetStateAction<{ caseid: null; caseSeq: null }>
  ) => {
    setLinkedCase(newLinkedCase);
  };

  const handleCloseSnackbar = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
    setTransferCaseInfo(null);
  };

  const handleRowClick = (params: SelectedRowType) => {
    fetchCaseUpdatedInfo(params.caseseq);
    setSelectedRow(params);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedAction(null);
    setDialogOpen(false);
    setTransferCaseError(null);
    setShowErrorAlert(false);
  };

  const fetchCaseUpdatedInfo = async (caseSeq: string) => {
    await fetch(REACT_APP_API_URL + 'getcaseupdatedinfo?CaseSeq=' + caseSeq, {
      method: 'GET',
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        if (data.message != null) {
          alert(data.message);
        } else {
          setCaseUpdatedInfo(data);
        }
      })
      .catch(e => {
        alert(e);
      });
  };

  const handleSubmit = async () => {
    //validate before save
    if (!selectedAction) {
      setTransferCaseError('Please select transfer action before submit');
      setShowErrorAlert(true);
      return;
    }

    if (selectedAction === 'Link' && !linkedCase?.caseSeq) {
      setTransferCaseError('Please select existing case to link');
      setShowErrorAlert(true);
      return;
    }

    let formData = new FormData();
    formData.append('caseID', selectedRow?.caseid || '');
    formData.append('caseSeq', selectedRow?.caseseq || '');
    formData.append('userSeq', user?.userSeq || '');
    formData.append('transferAction', selectedAction || '');
    formData.append('intermediateJurisdictionSeq', selectedRow?.jurisdictionTo?.optionSeq || '');
    formData.append('rejectReasonSeq', rejectReason?.optionSeq || '');
    formData.append('otherRejectReason', otherRejectReason || '');
    formData.append('linkedCaseSeq', linkedCase?.caseSeq || '');
    formData.append('linkedCaseID', linkedCase?.caseid || '');

    setIsTransferInProgress(true);
    await fetch(REACT_APP_API_URL + 'transferjurisdictionactions', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          return res.json().then(errorData => {
            throw new Error(errorData?.message || 'An unexpected error occurred');
          });
        }
        return res.json();
      })
      .then(data => {
        setIsTransferInProgress(false);
        setTransferCaseInfo(data.message);
        setOpenSnackbar(true);
        handleDialogClose();
        reloadSiloData('TRANSFERRED_CASES');
        reloadSiloData('OPEN_CASES');
      })
      .catch(data => {
        setIsTransferInProgress(false);
        setTransferCaseError(data.message);
        setShowErrorAlert(true);
      });
  };

  const fetchRejectReasonOptions = async () => {
    fetch(REACT_APP_API_URL + 'gettransferrejectreasonoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setRejectReasonOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  useEffect(() => {
    if (selectedAction === 'Reject') {
      fetchRejectReasonOptions();
    }
  }, [selectedAction]);

  const columns: GridColDef[] = [
    {
      field: 'caseid',
      headerName: 'CaseID',
      width: 100,
      editable: false,
      align: 'left',
      headerAlign: 'left',

      renderCell: params => {
        return (
          <Button
            color='primary'
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              handleCaseClick(event, params);
            }}
          >
            <b> {params.formattedValue} </b>
          </Button>
        );
      },
    },
    {
      field: 'decedentname',
      headerName: 'Decedent',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: params => <DecedentNameCell {...params} />,
    },
    {
      field: 'investigator',
      headerName: 'MLDI',
      width: 140,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'jurisdictionFrom',
      headerName: 'From',
      width: 120,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'jurisdictionTo',
      headerName: 'To',
      width: 120,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      valueFormatter: (value: Option) => {
        return value ? value.optionName : '';
      },
    },
  ];

  return (
    <>
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={event => handleCloseSnackbar(event, 'close')}
          severity='info'
          sx={{ width: '100%', fontSize: '1.2rem' }}
        >
          {transferCaseInfo}
        </Alert>
      </Snackbar>

      <SiloDataGrid
        loading={isLoading}
        listresults={data}
        columnsInput={columns}
        idcolumn='caseid'
        gridheight={200}
        gridcolor='#2ecc71'
        title='Pending Transfer Cases'
        tooltip='Cases that have been submitted by external jurisdictions for transfer, and are awaiting acceptance'
        casecount
        rowsperpage={50}
        filterModel={undefined}
        columnsVisibility={undefined}
        onRowClick={(params: any) => handleRowClick(params.row)}
        onCaseIDClick={() => {}}
      />

      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        PaperProps={{
          style: {
            minWidth: '40%',
            maxWidth: isXSScreen ? '90%' : isSmallScreen ? '80%' : '50%',
            margin: '0 auto',
          },
        }}
        fullWidth
      >
        <DialogContent>
          <DialogContent>
            {showErrorAlert && (
              <div style={{ paddingBottom: '10px' }}>
                <Alert
                  onClose={() => {
                    setShowErrorAlert(false);
                  }}
                  severity='error'
                  sx={{ width: '100%' }}
                >
                  {transferCaseError}
                </Alert>
              </div>
            )}
            <Card>
              <CardContent sx={{ mb: 0.5 }}>
                <Table sx={{ '& tbody > tr > td, & thead > tr > th': { py: 1 } }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ fontSize: '1.35rem', pb: 1, color: theme.palette.primary.light }}
                      >
                        CASE INFO
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Case No</TableCell>
                      <TableCell>{selectedRow?.caseid}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Decedent Name:</TableCell>
                      <TableCell>{selectedRow?.decedentname}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>ME Action:</TableCell>
                      <TableCell>{selectedRow?.meAction}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>From Jurisdiction:</TableCell>
                      <TableCell>{selectedRow?.jurisdictionFrom}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>To Jurisdiction:</TableCell>
                      <TableCell>{selectedRow?.jurisdictionTo?.optionName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Reason:</TableCell>
                      <TableCell>{selectedRow?.transferReason}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Transfer Notes:</TableCell>
                      <TableCell>{selectedRow?.transferNotes}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>

            <Grid container spacing={2} style={{ marginTop: '1rem' }}>
              <Grid item xs={12}>
                <FormControl component='fieldset'>
                  {/* <FormLabel component='legend'>Action</FormLabel> */}
                  <RadioGroup
                    name='action'
                    value={selectedAction}
                    onChange={e => setSelectedAction(e.target.value)}
                    row
                  >
                    <FormControlLabel value={'Accept'} control={<Radio />} label='Accept' />
                    <FormControlLabel value={'Reject'} control={<Radio />} label='Reject' />
                    <FormControlLabel
                      value={'Link'}
                      control={<Radio />}
                      label='Associate With Existing Case'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {selectedAction === 'Reject' && (
                <>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id='rejectedReason'
                      options={rejectReasonOptions}
                      value={rejectReason || null}
                      onChange={(e, value) => setRejectReason(value)}
                      getOptionLabel={option => option.optionName}
                      renderInput={params => <TextField {...params} label='Reason For Reject' />}
                    />
                  </Grid>

                  {rejectReason !== null &&
                    rejectReason?.optionSeq === 'BB375FDC-C1D7-4DED-8D25-A932ADAEFA01' && (
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          id='otherRejectedReason'
                          name='otherRejectedReason'
                          label='Other Reason'
                          variant='outlined'
                          value={otherRejectReason || null}
                          onChange={e => {
                            setOtherRejectReason(e.target.value);
                          }}
                        />
                      </Grid>
                    )}
                </>
              )}

              {selectedAction === 'Link' && (
                <>
                  <Grid item xs={12}>
                    <SearchCase onLinkedCaseIdChange={handleLinkedCaseIdChange} />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: isXSScreen ? 'column' : 'row',
                    gap: isXSScreen ? 0 : '1rem',
                  }}
                >
                  <p>Created: {caseUpdatedInfo?.createdOn}</p>
                  <p>By: {caseUpdatedInfo?.createdBy}</p>
                  <p>Updated: {caseUpdatedInfo?.lastUpdatedOn}</p>
                  <p>By: {caseUpdatedInfo?.lastUpdatedBy}</p>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </DialogContent>

        <DialogActions style={{ margin: '0 1rem 1rem' }}>
          {isTransferInProgress ? (
            <CircularProgress size={20} />
          ) : (
            <Button
              variant='contained'
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </Button>
          )}
          <Button
            color='error'
            onClick={() => {
              handleDialogClose();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
