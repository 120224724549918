import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

const ExportCheckboxGroup = ({
  options,
  selectedCheckboxes,
  setSelectedCheckboxes,
  files,
}) => {
  const handleAllCheckboxChange = (event) => {
    const newSelectedCheckboxes = {};
    if (!options) return;
    if (event.target.checked) {
      options?.forEach((option) => {
        const count = files.filter(
          (row) => row.userCertificateTypeSeq == option.photoTypeSeq
        ).length;
        if (!count) {
          newSelectedCheckboxes[option.photoTypeSeq] = false;
        } else {
          newSelectedCheckboxes[option.photoTypeSeq] = true;
        }
      });
    } else {
      options?.forEach((option) => {
        newSelectedCheckboxes[option.photoTypeSeq] = false;
      });
    }

    setSelectedCheckboxes(newSelectedCheckboxes);
  };

  const handleCheckboxChange = (event) => {
    const newSelectedCheckboxes = { ...selectedCheckboxes };
    newSelectedCheckboxes[event.target.name] = event.target.checked;

    setSelectedCheckboxes(newSelectedCheckboxes);
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleAllCheckboxChange}
            checked={
              Object.values(selectedCheckboxes).length &&
              Object.values(selectedCheckboxes).filter((val) => val == true)
                .length ==
                options.filter((option) => {
                  const count = files.filter(
                    (row) => row.userCertificateTypeSeq == option.photoTypeSeq
                  ).length;
                  if (!count) return false;
                  return true;
                }).length
            }
            name='--All--'
          />
        }
        label='--All--'
      />
      {options
        ? options?.map((option) => {
            console.log('option123', option);
            const count = files.filter(
              (row) => row.userCertificateTypeSeq == option.photoTypeSeq
            ).length;
            // if (!count) return;
            return (
              <div>
                {count !== 0 ? (
                  <FormControlLabel
                    key={option.photoTypeSeq}
                    control={
                      <Checkbox
                        onChange={handleCheckboxChange}
                        checked={
                          selectedCheckboxes[option.photoTypeSeq] || false
                        }
                        name={option.photoTypeSeq}
                      />
                    }
                    label={option.photoTypeName + ` ( ${count} )`}
                  />
                ) : null}
              </div>
            );
          })
        : null}
    </>
  );
};

export default ExportCheckboxGroup;
