import { Box } from '@mui/material';
import DesktopAppBar from './DesktopAppBar';
import MobileAppBar from './MobileAppBar';

export default function MLAppBar() {
  return (
    <Box>
      <DesktopAppBar />
      <MobileAppBar />
    </Box>
  );
}
