import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  IconButton,
  FormHelperText,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import { ErrorMessage } from 'formik';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';

const Contact = ({
  removeContactTab,
  handleAddContact,
  handleContactSelectionChange,
  panel,
  panels,
  index,
  contactTypeNames,
  setContactDetailsError,
  isErrormsg,
  editing,
}) => {
  const id = '00000000-0000-0000-0000-000000000000';
  const digits =
    panel?.contactItemDetails?.includes('@') || panel?.contactItemDetails?.includes('.')
      ? panel?.contactItemDetails
      : panel?.contactItemDetails.replace(/\D/g, '');

  const [phoneNumber, setPhoneNumber] = useState(
    panel?.contactItemDetails?.includes('@') || panel?.contactItemDetails?.includes('.')
      ? panel?.contactItemDetails
      : panel?.contactItemDetails?.length > 0
      ? `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`
      : ''
  );

  const handlePhoneNumberChange = (value, index, contactItemTypeSeq) => {
    const input = value;
    let formattedNumber;
    if (
      contactTypeNames?.[contactItemTypeSeq]?.contactItemTypeName == 'Home' ||
      contactTypeNames?.[contactItemTypeSeq]?.contactItemTypeName == 'Cellular' ||
      contactTypeNames?.[contactItemTypeSeq]?.contactItemTypeName == 'Office' ||
      contactTypeNames?.[contactItemTypeSeq]?.contactItemTypeName == 'Pager' ||
      contactTypeNames?.[contactItemTypeSeq]?.contactItemTypeName == 'Fax' ||
      contactTypeNames?.[contactItemTypeSeq]?.contactItemTypeName == 'Direct Connect Number'
    ) {
      const digitsOnly = input.replace(/\D/g, '');
      if (digitsOnly.length == 0) formattedNumber = '';
      else if (digitsOnly.length < 4)
        formattedNumber = `(${digitsOnly.slice(0, digitsOnly.length)}`;
      else if (digitsOnly.length < 7)
        formattedNumber = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, digitsOnly.length)}`;
      else
        formattedNumber = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(
          6,
          10
        )}`;
      setPhoneNumber(formattedNumber);
    } else {
      setPhoneNumber(input);
    }
    handleContactSelectionChange(
      {
        target: {
          name: 'contactItemDetails',
          value: formattedNumber || input,
        },
      },
      index
    );
  };

  const shareContactDetails = () => {
    setContactDetailsError({
      name: contactTypeNames?.[panel?.contactItemTypeSeq]?.contactItemTypeName,
      value: phoneNumber,
    });
  };

  React.useEffect(() => {
    shareContactDetails();
  }, [contactTypeNames?.[panel?.contactItemTypeSeq]?.contactItemTypeName, phoneNumber]);

  return (   
        <Grid container spacing={2} key={index} style={{ marginTop: '0px' }}>     
          <Grid item lg={6} xs={12} md={12} sm={12}>
            <FormControl fullWidth required>
              <InputLabel htmlFor='contactItemTypeSeq' id='contactItemTypeSeq'>
                Contact Type
              </InputLabel>
              <Select
                disabled={!editing}
                onChange={e => handleContactSelectionChange(e, index)}
                variant='outlined'
                size='small'
                //focused
                label='Contact Type'
                displayEmpty
                name={`contactItemTypeSeq`}
                value={panel?.contactItemTypeSeq?.toLowerCase() || id}
              >
                <MenuItem key='select-option' value= {id}>
                    --Select One--
                  </MenuItem>
                {Object.keys(contactTypeNames).map((item, z) => {
                  return (
                    <MenuItem
                      key={contactTypeNames[item].contactItemTypeSeq}
                      value={contactTypeNames[item]?.contactItemTypeSeq?.toLowerCase()}
                    >
                      {contactTypeNames?.[item]?.contactItemTypeName}
                    </MenuItem>
                  );
                })}
              </Select>
              {isErrormsg && (
                <ErrorMessage
                  name={`contactItem.${index}.contactItemTypeSeq`}
                  render={msg => <FormHelperText error>{msg}</FormHelperText>}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item lg={5} xs={12} md={12} sm={10}>
            <FormControl fullWidth error required component='fieldset' variant='standard'>
              <TextField
                required
                //focused
                name={`contactItemDetails`}
                label='Contact Details'
                type={
                  contactTypeNames?.[panel?.contactItemDetails]?.contactItemTypeName == 'Email'
                    ? 'email'
                    : 'text'
                }
                disabled={!panel?.contactItemTypeSeq || !editing}
                onChange={e =>
                  handlePhoneNumberChange(e.target.value, index, panel?.contactItemTypeSeq)
                }
                value={phoneNumber}
                variant='outlined'
              />
              {contactTypeNames?.[panel?.contactItemTypeSeq]?.contactItemTypeName == 'Email' &&
              phoneNumber &&
              !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                phoneNumber
              ) ? (
                // !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(phoneNumber) ? (
                <FormHelperText error>Please enter a valid email address</FormHelperText>
              ) : null}
            </FormControl>
            {isErrormsg && (
              <ErrorMessage
                name={`contactItem.${index}.contactItemDetails`}
                render={msg => <FormHelperText error>{msg}</FormHelperText>}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={2} md={1}>
            {panels?.length > 1 && (
              <Tooltip title='Delete Contact'>
                <IconButton disabled={!editing} onClick={() => removeContactTab(index)}>
                  <RemoveCircleOutline />
                </IconButton>
              </Tooltip>
            )}
            {index === panels?.length - 1 && (
              <Tooltip title='Add New Contact'>
                <IconButton disabled={!editing} onClick={handleAddContact}>
                  <AddCircleOutline />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
  );
};

export default Contact;
