import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  IconButton,
  FormHelperText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import { ErrorMessage } from 'formik';

const Contact = ({
  removeContactTab,
  handleContactSelectionChange,
  panel,
  panels,
  index,
  contactTypeNames,
  errorFieldValue,
}) => {
  
  const digits = (panel?.contactItemDetails?.includes('@') || panel?.contactItemDetails?.includes('.'))
    ? panel?.contactItemDetails
    : panel?.contactItemDetails.replace(/\D/g, '');

  const [phoneNumber, setPhoneNumber] = useState(
    (panel?.contactItemDetails?.includes('@') || panel?.contactItemDetails?.includes('.'))
      ? panel?.contactItemDetails
      : panel?.contactItemDetails?.length > 0
        ? `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`
        : ''
  );

  const handlePhoneNumberChange = (e, index, contactItemTypeSeq) => {
    const input = e.target.value;

    let formattedNumber;
    if (
      contactTypeNames?.[contactItemTypeSeq]?.contactItemTypeName == 'Home' ||
      contactTypeNames?.[contactItemTypeSeq]?.contactItemTypeName ==
        'Cellular' ||
      contactTypeNames?.[contactItemTypeSeq]?.contactItemTypeName == 'Office' ||
      contactTypeNames?.[contactItemTypeSeq]?.contactItemTypeName == 'Pager' ||
      contactTypeNames?.[contactItemTypeSeq]?.contactItemTypeName == 'Fax' ||
      contactTypeNames?.[contactItemTypeSeq]?.contactItemTypeName ==
        'Direct Connect Number'
    ) {
      const digitsOnly = input.replace(/\D/g, '');
      if (digitsOnly.length == 0) formattedNumber = '';
      else if (digitsOnly.length < 4)
        formattedNumber = `(${digitsOnly.slice(0, digitsOnly.length)}`;
      else if (digitsOnly.length < 7)
        formattedNumber = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(
          3,
          digitsOnly.length
        )}`;
      else
        formattedNumber = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(
          3,
          6
        )}-${digitsOnly.slice(6, 10)}`;
      setPhoneNumber(formattedNumber);
    } else {
      setPhoneNumber(input);
    }
    handleContactSelectionChange(
      {
        target: {
          name: 'contactItemDetails',
          value: formattedNumber || input,
        },
      },
      index
    );
  };

  return (
    <fieldset style={{ marginBottom: '0px', padding: '16px' }}>
      <legend>
        <Typography
          variant='h3'
          gutterBottom
          sx={{
            mt: 0,
            mb: 0,
            py: 0,
            color: '#888888',
            fontSize: '1rem',
            fontWeight: 'bold',
          }}
        >
          Contact {panel?.index}
        </Typography>
      </legend>
      <Box sx={{ flexGrow: 1 }}>
        <Grid Container spacing={2}>
          <Grid
            item
            lg={12}
            xs={12}
            md={12}
            sm={12}
            display='flex'
            justifyContent='flex-end'
          >
            {panels.length > 0 && (
              <IconButton onClick={() => removeContactTab(index)}>
                <DeleteIcon sx={{ color: 'rgb(2, 136, 209)' }} />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12} md={12} sm={12}>
            <FormControl
              fullWidth
              required
              focused
              variant='outlined'
              sx={{ minWidth: '100%' }}
            >
              <InputLabel htmlFor='contactItemTypeSeq' id='contactItemTypeSeq'>
                Contact Type
              </InputLabel>
              <Select
                onChange={(e) => handleContactSelectionChange(e, index)}
                variant='outlined'
                focused
                label='Contact Type'
                displayEmpty
                size='large'
                name={`contactItemTypeSeq`}
                value={panel?.contactItemTypeSeq || ''}
              >
                {Object.keys(contactTypeNames).map((item, z) => {
                  return (
                    <MenuItem
                      key={contactTypeNames[item].contactItemTypeSeq}
                      value={contactTypeNames[item].contactItemTypeSeq}
                    >
                      {contactTypeNames?.[item]?.contactItemTypeName}
                    </MenuItem>
                  );
                })}
              </Select>
              <ErrorMessage
                name={`contactItem.${index}.contactItemTypeSeq`}
                render={(msg) => {
                  return <FormHelperText error>{msg}</FormHelperText>;
                }}
              />
            </FormControl>
          </Grid>
          <Grid item lg={6} xs={12} md={12} sm={12}>
            <FormControl
              fullWidth
              error
              required
              component='fieldset'
              variant='standard'
            >
              <TextField
                required
                focused
                name={`contactItemDetails`}
                label='Contact Details'
                type={
                  contactTypeNames?.[panel?.contactItemDetails]
                    ?.contactItemTypeName == 'Email'
                    ? 'email'
                    : 'text'
                }
                disabled={!panel?.contactItemTypeSeq}
                onChange={(e) =>
                  handlePhoneNumberChange(e, index, panel?.contactItemTypeSeq)
                }
                value={phoneNumber}
                variant='outlined'
                size='large'
              />
              {contactTypeNames?.[panel?.contactItemTypeSeq]
                ?.contactItemTypeName == 'Email' &&
              phoneNumber &&
              !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(phoneNumber) ? (
                // !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(phoneNumber) ? (
                <FormHelperText error>
                  Please enter a valid email address
                </FormHelperText>
              ) : null}
            </FormControl>
            <ErrorMessage
              name={`contactItem.${index}.contactItemDetails`}
              render={(msg) => <FormHelperText error>{msg}</FormHelperText>}
            />
          </Grid>
        </Grid>
      </Box>
    </fieldset>
  );
};

export default Contact;
