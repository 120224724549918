import { Box, Button, Grid, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GiMicroscope, GiSecretBook } from 'react-icons/gi';
import { GrEmergency } from 'react-icons/gr';
import { MdAccessTime, MdBiotech, MdOutlineHealthAndSafety } from 'react-icons/md';
import { AiOutlineFileSearch } from "react-icons/ai";
import { useNavigate } from 'react-router';
import { Navigate } from 'react-router-dom';
import '../../App.css';
import { useAuth } from '../../utils/auth/AuthService';

export default function GeneralReports() {
  const { user } = useAuth();
  const [redirect, setredirect] = useState(null);
  const [userReady, setuserReady] = useState(false);
  const [isaccessViolentDeathReport, setaccessViolentDeathReport] = useState(false);
  const navigate = useNavigate();

  const OpenAvgTurnaroundTime = () => {
    navigate('/reports/turnaroundtimereport', { state: { name: 'messageFromServer' } });
  };

  const OpenMETurnaroundTime = () => {
    navigate('/reports/meturnaroundtimereport', {
      state: { name: 'messageFromServer' },
    });
  };

  const OpenViolent = () => {
    navigate('/reports/violentdeath', { state: { name: 'messageFromServer' } });
  };

  const OpenOpioid = () => {
    navigate('/reports/opioiddeath', { state: { name: 'messageFromServer' } });
  };

  const OpenCaseResponseTime = () => {
    navigate('/reports/caseresponsetime', { state: { name: 'messageFromServer' } });
  };

  const OpenDrugToxicity = () => {
    navigate('/reports/drugtoxicity', { state: { name: 'messageFromServer' } });
  };

  const OpenSuicide = () => {
    navigate('/reports/suicide', { state: { name: 'messageFromServer' } });
  };
  const OpenHomicide = () => {
    navigate('/reports/homicide', { state: { name: 'messageFromServer' } });
  };

  const OpenNewSuicide = () => {
    navigate('/reports/newsuicide', { state: { name: 'messageFromServer' } });
  };
  const OpenNewHomicide = () => {
    navigate('/reports/newhomicide', { state: { name: 'messageFromServer' } });
  };

  const OpenMyCases = () => {
    navigate('/reports/mycases', { state: { name: 'messageFromServer' } });
  };

  const OpenInvestigatorByScene = () => {
    navigate('/reports/sceneexaminedby', { state: { name: 'messageFromServer' } });
  };

  const OpenExamsReportSummary = () => {
    navigate('/reports/examssummaryreport', { state: { name: 'messageFromServer' } });
  };

  const OpenExamPhysicianActivityReport = () => {
    navigate('/reports/examphysicianactivityreport', {
      state: { name: 'messageFromServer' },
    });
  };

  const OpenPhysicianCasesView = () => {
    navigate('/reports/openphysiciancases', { state: { name: 'messageFromServer' } });
  };

  const OpenInvestigatorCaseActivityReport = () => {
    navigate('/reports/investigatoractivityreport', {
      state: { name: 'messageFromServer' },
    });
  };

  const OpenMissingCaseDocumentsView = () => {
    navigate('/reports/missingcasedocuments', { state: { name: 'messageFromServer' } });
  };

  const OpenMEActionActivityReport = () => {
    navigate('/reports/meactionactivityreport', { state: { name: 'messageFromServer' } });
  };

  const OpenMTCaseActivityExtraction = () => {
    navigate('/reports/morguetechcaseactivity', {
      state: { name: 'messageFromServer' },
    });
  };

  const OpenDataEntryResponseTimeReport = () => {
    navigate('/reports/dataentryresponsetimereport', {
      state: { name: 'messageFromServer' },
    });
  };

  const OpenDrugToxAudit = () => {
    navigate('/reports/drugtoxaudit', { state: { name: 'messageFromServer' } });
  };

  const OpenUnclaimedCases = () => {
    navigate('/reports/unclaimedcases', { state: { name: 'messageFromServer' } });
  };

  const OpenAnalyteDetected = () => {
    navigate('/reports/analyteDetected', { state: { name: 'messageFromServer' } });
  };

  const OpenMonthlySuspectedDrugs = () => {
    navigate('/reports/monthlysuspecteddrugs', { state: { name: 'messageFromServer' } });
  };

  const OpenCasesNoNOK = () => {
    navigate('/reports/casesnoNOK', { state: { name: 'messageFromServer' } });
  };

  const OpenPIICaseSearch = () => {
    navigate('/reports/PIICaseSearch', { state: { name: 'messageFromServer' } });
  }

  useEffect(() => {
    if (user === null) return;
    setuserReady(true);
    //Checking permission for Violent death report. Report list contained comma (,) seperated value of report list seqs
    // 5045F461-B62C-4240-B193-24B184A44A87  Violent Death report seq
    const violentDeathReportSeq = '5045F461-B62C-4240-B193-24B184A44A87';
    const reportList = user.reportAccessList.toString().toLowerCase();

    var hasAccessViolentDeathReport = reportList.indexOf(violentDeathReportSeq.toLowerCase()) != -1;
    if (hasAccessViolentDeathReport) setaccessViolentDeathReport(true);
    else setaccessViolentDeathReport(true); //set to true always while testing
  }, [user]);

  return redirect ? (
    <Navigate to={redirect} />
  ) : (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenAvgTurnaroundTime();
            }}
            startIcon={<MdAccessTime />}
            //disabled
          >
            CASE TURN AROUND TIME
          </Button>
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenCaseResponseTime();
            }}
            startIcon={<MdAccessTime />}
            // disabled
          >
            CASE RESPONSE TIME
          </Button>
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenExamsReportSummary();
            }}
            startIcon={<MdBiotech />}
            // disabled
          >
            Exams Report Summary
          </Button>
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenMEActionActivityReport();
            }}
            startIcon={<GiSecretBook />}
            // disabled
          >
            CASE ACTIVITY
          </Button>
        </Grid>
      </Grid>

      <hr />

      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenDrugToxicity();
            }}
            startIcon={<MdBiotech />}
          >
            Drug Toxicity
          </Button>
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenOpioid();
            }}
            startIcon={<GiMicroscope />}
            disabled
          >
            OPIOID REPORT
          </Button>
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenSuicide();
            }}
            startIcon={<MdOutlineHealthAndSafety />}
            // disabled
          >
            SUICIDES CHART
          </Button>
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenHomicide();
            }}
            startIcon={<MdOutlineHealthAndSafety />}
            // disabled
          >
            HOMICIDES CHART
          </Button>
        </Grid>
      </Grid>

      <hr />

      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenViolent();
            }}
            startIcon={<GrEmergency />}
          >
            VIOLENT REPORT
          </Button>
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenUnclaimedCases();
            }}
            startIcon={<MdBiotech />}
          >
            Unclaimed Cases
          </Button>
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenMonthlySuspectedDrugs();
            }}
            startIcon={<MdBiotech />}
          >
            Monthly Suspected Drugs
          </Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenAnalyteDetected();
            }}
            startIcon={<MdBiotech />}
          >
            Substance/Analyte Detected
          </Button>
        </Grid>
      </Grid>

      <hr />

      <Grid container spacing={2}>
        <Tooltip title='Coming soon' arrow>
          <Grid item xs={12} md={3}>
            <Button
              variant='contained'
              style={{ width: '100%', minHeight: '72px' }}
              onClick={event => {}}
              disabled={true}
              startIcon={<MdBiotech />}
            >
              Lab Numbers by MOD
            </Button>
          </Grid>
        </Tooltip>
        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenCasesNoNOK();
            }}
            startIcon={<GiSecretBook />}
          >
            Cases with no NOK
          </Button>
        </Grid>
  
        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenPIICaseSearch();
            }}
            startIcon={<AiOutlineFileSearch />}
          >
            PII Case Search
          </Button>
        </Grid>
  
      </Grid>
    </Box>
  );
}
