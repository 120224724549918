import { Edit, SaveRounded } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { useDashboard } from '../../views/Dashboard/DashboardContext';

export function ArrangeSilosButton() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    isEditing,
    toggleEditing,
    saveDashboard,
    preferencesError,
    preferencesSaving,
    preferencesLoading,
  } = useDashboard();

  async function handleOnClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();
    if (isEditing) {
      await saveDashboard();
      toggleEditing(false);
    } else {
      toggleEditing(true);
    }
  }

  const getIcon = () => {
    if (preferencesSaving) {
      return <CircularProgress size={24} />; // Spinner icon when saving
    } else if (isEditing) {
      return <SaveRounded />; // Save icon when in editing mode
    } else {
      return <Edit />; // Edit icon when not in editing mode
    }
  };

  const getButtonText = () => {
    if (preferencesSaving) {
      return 'Saving silos..';
    } else if (isEditing) {
      return 'Save silos';
    } else {
      return 'Arrange silos';
    }
  };

  return isMobile ? (
    <IconButton onClick={handleOnClick} disabled={preferencesLoading || preferencesSaving}>
      {getIcon()}
    </IconButton>
  ) : (
    <Button
      onClick={handleOnClick}
      disabled={preferencesLoading || preferencesSaving}
      sx={{
        borderRadius: '20px',
        // color: grey[700],
        // borderColor: grey[700],
        py: 0.8,
        width: 'max-content',
      }}
      variant='text'
      startIcon={getIcon()}
    >
      {getButtonText()}
    </Button>
  );
}
