// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
// import { IconButton, InputAdornment, Stack, TextField, TextFieldProps } from '@mui/material';
// import React, { useEffect, useState } from 'react';

// export interface NumericTextFieldProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
//   value: string | number;
//   onChange: (value: string) => void;
//   step?: number;
// }

// const NumericTextField: React.FC<NumericTextFieldProps> = ({
//   value,
//   onChange,
//   step = 1,
//   ...props
// }) => {
//   const [internalValue, setInternalValue] = useState(value.toString());

//   useEffect(() => {
//     setInternalValue(value.toString());
//   }, [value]);

//   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const newValue = event.target.value;
//     const filteredValue = newValue.replace(/[^0-9]/g, '');

//     setInternalValue(filteredValue);
//     onChange(filteredValue);
//   };

//   const handleIncrement = () => {
//     const newValue = (parseInt(internalValue, 10) || 0) + step;
//     setInternalValue(newValue.toString());
//     onChange(newValue.toString());
//   };

//   const handleDecrement = () => {
//     const newValue = Math.max((parseInt(internalValue, 10) || 0) - step, 0); // Prevents negative values
//     setInternalValue(newValue.toString());
//     onChange(newValue.toString());
//   };

//   return (
//     <TextField
//       {...props}
//       value={internalValue ?? 0}
//       onChange={handleChange}
//       inputMode='numeric'
//       InputProps={{
//         endAdornment: (
//           <InputAdornment position='end'>
//             <Stack>
//               <IconButton
//                 size='small'
//                 onClick={handleIncrement}
//                 edge='end'
//                 sx={{
//                   p: 0,
//                   m: 0,
//                   width: 'min-content',
//                   height: 'min-content',
//                 }}
//               >
//                 <ArrowDropUpIcon fontSize='small' />
//               </IconButton>
//               <IconButton
//                 size='small'
//                 onClick={handleDecrement}
//                 edge='end'
//                 sx={{ p: 0, width: 'min-content', height: 'min-content' }}
//               >
//                 <ArrowDropDownIcon fontSize='small' />
//               </IconButton>
//             </Stack>
//           </InputAdornment>
//         ),
//       }}
//     />
//   );
// };

// export default NumericTextField;

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { IconButton, InputAdornment, Stack, TextField, TextFieldProps } from '@mui/material';
import React, { useEffect, useState } from 'react';

export interface NumericTextFieldProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
  value: string | number | undefined;
  onChange: (value: string) => void;
  step?: number;
}

const NumericTextField: React.FC<NumericTextFieldProps> = ({
  value,
  onChange,
  step = 1,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState(value?.toString());

  useEffect(() => {
    setInternalValue(value?.toString());
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const filteredValue = newValue.replace(/[^0-9.]/g, '');

    setInternalValue(filteredValue);
    onChange(filteredValue);
  };

  const handleIncrement = () => {
    const newValue = (parseInt(internalValue, 10) || 0) + step;
    setInternalValue(newValue?.toString());
    onChange(newValue?.toString());
  };

  const handleDecrement = () => {
    const newValue = Math.max((parseInt(internalValue, 10) || 0) - step, 0); // Prevents negative values
    setInternalValue(newValue?.toString());
    onChange(newValue?.toString());
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      handleIncrement();
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      handleDecrement();
    }
  };

  return (
    <TextField
      {...props}
      value={internalValue ?? 0}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      inputMode='numeric'
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <Stack>
              <IconButton
                size='small'
                onClick={handleIncrement}
                edge='end'
                tabIndex={-1}
                sx={{
                  p: 0,
                  m: 0,
                  width: 'min-content',
                  height: 'min-content',
                }}
              >
                <ArrowDropUpIcon fontSize='small' />
              </IconButton>
              <IconButton
                size='small'
                onClick={handleDecrement}
                edge='end'
                tabIndex={-1}
                sx={{ p: 0, width: 'min-content', height: 'min-content' }}
              >
                <ArrowDropDownIcon fontSize='small' />
              </IconButton>
            </Stack>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default NumericTextField;
