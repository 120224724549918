import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Skeleton,
  TextField,
} from '@mui/material';
import { ErrorMessage } from 'formik';
import React, { useEffect, useState } from 'react';

const SecurityQuestion = ({
  handleSecurityQuestionSelectionChange,
  panel,
  index,
  securityQuestions,
  setSecurityQuestionError,
}) => {
  const [securityQuestionAns, setSecurityQuestionAns] = useState(panel?.securityQuestionAns || '');
  const [isQuestionFocused, setIsQuestionFocused] = useState(false);
  const [isAnswerFocused, setIsAnswerFocused] = useState(false);

  const handleSecurityQuestionAnsChange = (e, index, securityQuestionSeq) => {
    const input = e.target.value;
    setSecurityQuestionAns(input);
    handleSecurityQuestionSelectionChange(
      {
        target: {
          name: 'securityQuestionAns',
          value: input,
        },
      },
      index
    );
  };

  const shareSecurityQuestions = () => {
    setSecurityQuestionError({
      name: securityQuestions?.[panel?.securityQuestionSeq]?.securityQuestionTypeName,
      value: securityQuestionAns,
    });
  };

  useEffect(() => {
    shareSecurityQuestions();
  }, [
    securityQuestions?.[panel?.securityQuestionSeq]?.securityQuestionTypeName,
    securityQuestionAns,
  ]);

  useEffect(() => {
    setSecurityQuestionAns(panel?.securityQuestionAns);
  }, [panel?.securityQuestionAns]);

  const renderMaskedValue = value => {
    return value ? '*'.repeat(8) : '';
  };

  const renderQuestionValue = questionText => {
    return isQuestionFocused ? questionText : renderMaskedValue(questionText);
  };

  const renderAnswerValue = () => {
    return isAnswerFocused ? securityQuestionAns : renderMaskedValue(securityQuestionAns);
  };

  return (
    <Box sx={{ flexGrow: 1, mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12} md={12} sm={12}>
          <FormControl fullWidth required focused variant='outlined' sx={{ minWidth: '100%' }}>
            <Autocomplete
              options={securityQuestions ? Object.values(securityQuestions) : []}
              getOptionLabel={option => option.securityQuestionDetails || ''}
              value={
                panel?.securityQuestionSeq ? securityQuestions[panel.securityQuestionSeq] : null
              }
              onChange={(event, newValue) =>
                handleSecurityQuestionSelectionChange(
                  { target: { name: 'securityQuestionSeq', value: newValue?.securityQuestionSeq } },
                  index
                )
              }
              renderInput={params => {
                const questionText = params.inputProps.value?.securityQuestionDetails || '';
                return (
                  <TextField
                    {...params}
                    label={`Question ${index + 1}`}
                    variant='outlined'
                    size='large'
                    required
                    type='text'
                    value={renderQuestionValue(questionText)}
                    onFocus={() => setIsQuestionFocused(true)}
                    onBlur={() => setIsQuestionFocused(false)}
                  />
                );
              }}
            />
            <ErrorMessage
              name={`securityQuestion.${index}.securityQuestionSeq`}
              render={msg => <FormHelperText error>{msg}</FormHelperText>}
            />
          </FormControl>
        </Grid>
        <Grid item lg={6} xs={12} md={12} sm={12}>
          <FormControl fullWidth error required component='fieldset' variant='standard'>
            <TextField
              required
              name={`securityQuestionAns`}
              //   label='Answer'
              type='text'
              disabled={!panel?.securityQuestionSeq}
              onChange={e => handleSecurityQuestionAnsChange(e, index, panel?.securityQuestionSeq)}
              value={renderAnswerValue()}
              variant='outlined'
              size='large'
              onFocus={() => setIsAnswerFocused(true)}
              onBlur={() => setIsAnswerFocused(false)}
            />
          </FormControl>
          <ErrorMessage
            name={`securityQuestion.${index}.securityQuestionAns`}
            render={msg => <FormHelperText error>{msg}</FormHelperText>}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export const SecurityQuestionSkeleton = () => {
  return (
    <Box sx={{ flexGrow: 1, mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12} md={12} sm={12}>
          <Skeleton variant='rounded' width='100%' height={40} />
        </Grid>
        <Grid item lg={6} xs={12} md={12} sm={12}>
          <Skeleton variant='rounded' width='100%' height={40} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SecurityQuestion;
