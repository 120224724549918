import { Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';
import { useAuth } from '../../../utils/auth/AuthService';
import { TwoFactorMethods } from '../../../utils/auth/reducers/authReducer';
import { CodeExpirationCountdown } from './CodeExpirationCountdown';
import { DidntReceiveCodeHelp } from './DidntReceiveCodeHelp';
import { TwoFactorError } from './TwoFactorError';
import { useTwoFactorFailsafe } from './useTwoFactorFailsafe.hook';

const validationSchema = yup.object().shape({
  code: yup
    .string()
    .length(6, 'Code must be exactly 6 digits.')
    .matches(/^[0-9]*$/, 'Code must be exactly 6 digits.')
    .required('Code must be exactly 6 digits.'),
});

export function TwoFactorCodeForm({ method }: { method: TwoFactorMethods }) {
  useTwoFactorFailsafe(method);

  const auth = useAuth();
  const [loading, setLoading] = useState(false);

  const form = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      if (!values.code) return;
      setLoading(true);

      if (method === 'email') {
        await auth.two_factor.verifyEmail({
          code: values.code,
        });
      } else if (method === 'sms') {
        await auth.two_factor.verifySMS({
          code: values.code,
        });
      }

      setLoading(false);
    },
  });

  form.handleChange = (event: { target: { name?: any; value?: any } }) => {
    const { value } = event.target;
    if (value.length <= 6 && /^[0-9]*$/.test(value)) {
      form.setFieldValue(event.target.name, value);
    }
  };

  function getLabelMessage() {
    if (form.values.code.length === 0) {
      return 'Enter your 6 digit code';
    } else if (form.values.code.length === 5) {
      return `Enter ${6 - form.values.code.length} more digit`;
    } else if (form.values.code.length === 6) {
      return null;
    } else {
      return `Enter ${6 - form.values.code.length} more digits`;
    }
  }

  return (
    <>
      <Stack display='flex' flexDirection='column' alignItems='center' py={2}>
        <Typography variant='h5' fontWeight='bold'>
          Code sent
        </Typography>
        <Typography variant='body2' sx={{ opacity: '0.6' }}>
          {method === 'email' && (
            <>
              A temporary code was sent to <b>{auth.user?.primaryEmail}</b>. It will expire in
              <b>
                <CodeExpirationCountdown isCounting={!loading} />
              </b>
              .
            </>
          )}
          {method === 'sms' && (
            <>
              A temporary code was sent to <b>{auth.user?.primaryMobileNo}</b>. It will expire in
              <b>
                <CodeExpirationCountdown isCounting={!loading} />
              </b>
              .
            </>
          )}
        </Typography>
        <TwoFactorError />
      </Stack>
      <form onSubmit={form.handleSubmit}>
        <div className='form-group login-input'>
          <TextField
            fullWidth
            sx={{ mt: 1.5 }}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            type='number'
            autoComplete='off'
            id='code'
            name='code'
            size='small'
            label={getLabelMessage()}
            color={form.values.code.length === 6 ? 'primary' : 'info'}
            value={form.values.code || ''}
            onChange={form.handleChange}
          />
        </div>

        <div className='form-group login-input'>
          <Button
            disabled={loading || form.values.code.length < 6}
            startIcon={loading && <CircularProgress sx={{ color: 'grey.200' }} size={20} />}
            sx={{
              bgcolor: 'black',
              color: 'white',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.8)', // slightly darker shade on hover
              },
            }}
            variant='contained'
            fullWidth
            type='submit'
          >
            {loading ? 'Verifying' : 'Verify code'}
          </Button>
        </div>
      </form>
      <DidntReceiveCodeHelp />
    </>
  );
}
