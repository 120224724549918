import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  Paper,
  Slide,
  Stack,
  Tab,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { GiBookshelf, GiMicroscope, GiSecretBook } from 'react-icons/gi';
import { BsClipboard2Check, BsFillKanbanFill } from 'react-icons/bs';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useNavigate } from 'react-router';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function YourReportsView() {
  const OpenYourReleasesView = () => {
    navigate('/reports/your-releases', { state: { name: 'messageFromServer' } });
  };
  const { user } = useAuth();

  const [redirect, setredirect] = useState(null);
  const [userReady, setuserReady] = useState(false);
  const [numberOfCasesFound, setNumberOfCasesFound] = useState('');
  const [loading, setloading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [year, setYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [MEOptions, setMEOptions] = useState([]);
  const [currentUser, setcurrentUser] = useState(false);
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [selectedJdxs, setSelectedJdxs] = useState([]);
  const [requiredAlertVisible, setRequiredAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [caseStatusFilter, setCaseStatusFilter] = useState('releases');
  const navigate = useNavigate();
  const theme = useTheme();

  const { REACT_APP_API_URL } = process.env;

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };


  useEffect(() => {
    if (user === null) return;

    const fetchYear = async () => {
      try {
        const currentYear = new Date().getFullYear();
        setYear(null);
        setYearOptions([...Array(16).keys()].map(i => i + (currentYear - 15)).reverse());
      } catch (e) {
        alert(e);
      }
    };

    fetchYear();
    setAuthorizedJdxs(user.jdxAccessList);
    setSelectedJdxs(user.jdxAccessList);
  }, [user]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <Button
          variant='contained'
          style={{ width: '100%', minHeight: '72px' }}
          onClick={event => {
            OpenYourReleasesView();
          }}
          startIcon={<BsClipboard2Check />}
        >
          Your Releases
        </Button>
      </Grid>
      {/* <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {

            }}
            startIcon={<GiSecretBook />}
            // disabled
          >
            Other Report
          </Button>
        </Grid> */}
    </Grid>
  );
}
