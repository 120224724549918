export const type2 = [
    'OrganDonorOrganization',
    'Origin', // Organization
    'MentalCondition',
    'USArmyAgency',
    'AnthropologyAgeRangeSource',
    'XRayDentalType'
];

export const nameMappingReferenceMaterTable = {
    Origin: 'Organization',
    PronouncerAgency: 'TelemetryLocation',
    AssignmentType: 'Department',
    CaseClassification: 'HighPriority',
    Point: 'DestinationPoint',
    SampleType: 'DNASampleType',
    AuthorityRecordType: 'MedicalAndOtherRecordType',
    Hcf: 'MedicalFacility',
    PublicAdministratorOffice: 'SurrogateCourt',
    Relship: 'Relationship',
    CaseLocation: 'Details',
    ColorCode: 'County andDepartment wiseColorCode',
    ConsultantOnProcedure: 'ProcedureWiseConsultant',
    Denomination: 'CurrencyDenomination',
    AuthorityType: 'MedicalAndOtherEntity',
    PersonalType: 'PersonalItemType',
    PronouncerLocation: 'PronouncementLocation',
    PhotoType: 'Photo/DocumentType',
    XRayDentalType: 'DentalType',
    AgeEstimate: 'AgeEstimateType'
};

export const type3 = [
    'Country',
    'State',
    'PronouncerAgency', // TelemetryLocation
    'AutopsyTable'
];

export const type1 = [
    'BodyPart',
    'CaseEvent',
    'Color',
    'AssignmentType', // Department
    'EmploymentStatus',
    'JewelryType',
    'Occupation',
    'Priority',
    'PronouncerLocation',
    'Race',
    'ReasonForNoPronouncement',
    'ReasonType',
    'RequestedDocument',
    'RequestedProcedureList',
    'SceneCondition',
    'SecurityQuestion',
    'SupplementalCaseNoteType',
    'VoidCaseReason',
    'CaseClassification', // HighPriority
    'CosmeticSurgeryLocation',
    'CountyBurialItem',
    'Point', // DestinationPoint
    'SampleType', // DNASampleType
    'EyeColor',
    'EyeStatus',
    'FacialHairColor',
    'FacialHairType',
    'GovernmentalOrganization',
    'HairAccessory',
    'HairStyle',
    'HealthStatus',
    'AuthorityRecordType', // MedicalAndOtherRecordType
    'MedicalConditionType',
    'MedicationType',
    'NailLength',
    'NailType',
    'NotificationMode',
    'NotificationStatus',
    'ObjectInBodyType',
    'PiercingLocation',
    'PlaceOfIdentification',
    'ProstheticType',
    'RequestStatus',
    'ResponseStatus',
    'SkinType',
    'SurgeryType',
    'VehicleMake',
    'CurrencyType',
    'DocumentType',
    'EvidenceType',
    'PersonalType',
    'AgeEstimate',
    'Drug',
    'PaymentMode',
    // integrated in type1 components
    ...type2,
    ...type3
];

export const type4 = [
    'FuneralHome',
    'LawEnforcement',
    'Hcf', // MedicalFacility
    'NursingHome',
    'ChargeableRequestAgency',
    'PublicAdministratorOffice', // SurrogateCourt
    'OutOfStateFuneralHome'
];
