import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

export interface GlossaryCategory {
  glossaryCategorySeq: string;
  name: string;
  description: string;
  items: GlossaryItem[];
}

export interface GlossaryItem {
  glossaryCategorySeq: string;
  itemSeq: string;
  term: string;
  definition: string;
  order: number;
}

export interface UpdateGlossaryCategoryDto {
  glossaryCategorySeq: string;
  name: string;
  description: string;
  items: UpdateGlossaryItemDto[];
}

export interface UpdateGlossaryItemDto {
  glossaryCategorySeq: string;
  itemSeq: string;
  term: string;
  definition: string;
  order: number;
  isNew: boolean;
  isUpdated: boolean;
  isDeleted: boolean;
}

function getConfig(token: string) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return config;
}

async function getCategories(accessToken: string) {
  const url = `${REACT_APP_API_URL}glossary`;
  const response = await axios.get<GlossaryCategory[]>(url, getConfig(accessToken));
  return response.data;
}

async function createCategory(accessToken: string, c: Partial<GlossaryCategory>) {
  const url = `${REACT_APP_API_URL}glossary`;
  const response = await axios.post<GlossaryCategory>(url, c, getConfig(accessToken));
  return response.data;
}

async function updateCategory(
  accessToken: string,
  c: UpdateGlossaryCategoryDto
): Promise<GlossaryCategory> {
  const url = `${REACT_APP_API_URL}glossary/${c.glossaryCategorySeq}`;
  const response = await axios.put(url, c, getConfig(accessToken));
  return response.data;
}

async function updateCategories(
  accessToken: string,
  c: UpdateGlossaryCategoryDto[]
): Promise<GlossaryCategory[]> {
  const url = `${REACT_APP_API_URL}glossary/updateCategories`;
  return await axios.put(url, c, getConfig(accessToken));
}

async function deleteCategory(accessToken: string, categorySeq: string) {
  const url = `${REACT_APP_API_URL}glossary/${categorySeq}`;
  return await axios.delete(url, getConfig(accessToken));
}

async function addItemToCategory(accessToken: string, glossaryItem: Partial<GlossaryItem>) {
  const response = await axios.post<GlossaryItem>(
    `${REACT_APP_API_URL}glossary/${glossaryItem.glossaryCategorySeq}/items`,
    glossaryItem,
    getConfig(accessToken)
  );

  return response.data;
}

async function updateItem(accessToken: string, glossaryItem: Partial<GlossaryItem>) {
  const response = await axios.put<GlossaryItem>(
    `${REACT_APP_API_URL}glossary/updateItem`,
    glossaryItem,
    getConfig(accessToken)
  );

  return response.data;
}

async function deleteItem(accessToken: string, glossaryCategoryItemSeq: string) {
  const url = `${REACT_APP_API_URL}glossary/deleteItem/${glossaryCategoryItemSeq}`;
  return await axios.delete(url, getConfig(accessToken));
}

export const GlossaryAPI = {
  getCategories,
  createCategory,
  updateCategory,
  updateCategories,
  deleteCategory,
  addItemToCategory,
  updateItem,
  deleteItem,
};
