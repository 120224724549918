import { MoreVert, QuestionMarkRounded } from '@mui/icons-material';
import { Card, CardHeader, CardMedia, IconButton, Menu, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';

const useStyles = makeStyles({
  buttonBase: {
    textAlign: 'left',
    borderRadius: 4,
    transition: 'opacity 0.3s ease',
    opacity: 1,
    '&:hover': {
      opacity: 0.5,
    },
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    cursor: 'pointer',
  },
  card: {
    width: 250,
    position: 'relative',
    background: 'transparent',
    cursor: 'pointer',
  },
  cardContent: {
    flexWrap: 'wrap',
    background: 'transparent',
    padding: 0,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    width: 250,
    cursor: 'pointer',
  },
  title: {
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    maxWidth: 250,
    cursor: 'pointer',
  },
  wrapper: {
    margin: '8px',
  },
});

interface ResourceCardProps {
  resource: Resource;
  onResourceSelect: (resource: Resource) => void;
  showMore?: boolean;
  fallbackThumbnail?: React.ReactNode;
}

interface Resource {
  id?: string;
  title?: string;
  subtitle?: string;
  mimeType?: string;
  originalFileName?: string;
  author?: string;
  createdAt?: string;
  entityName?: string;
  thumbnailString?: string;
  thumbnailAsBase64?: boolean;
  contentURL?: string;
  extraContent?: string | React.ReactNode;
  menuItems: React.ReactNode[];
}
export function ResourceCard({
  resource,
  onResourceSelect,
  showMore = false,
  fallbackThumbnail = (
    <QuestionMarkRounded style={{ fontSize: 120, cursor: 'pointer' }}></QuestionMarkRounded>
  ),
}: ResourceCardProps) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.wrapper}>
      <Stack style={{ display: 'flex', flexWrap: 'wrap' }} spacing={1}>
        <Card className={classes.card} onClick={() => {}}>
          {resource.thumbnailString ? (
            <CardMedia
              className={classes.buttonBase}
              sx={{ height: 140 }}
              image={
                resource.thumbnailAsBase64
                  ? `data:image/jpeg;base64,${resource.thumbnailString}`
                  : resource.thumbnailString
              }
              onClick={event => {
                event.stopPropagation();
                onResourceSelect(resource);
              }}
            />
          ) : (
            <div
              style={{
                height: 140,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {fallbackThumbnail}
            </div>
          )}
        </Card>
        <div className={classes.cardContent}>
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography
                className={classes.title}
                onClick={() => onResourceSelect(resource)}
                gutterBottom
              >
                {resource.title}
              </Typography>
            }
            subheader={
              <Typography
                onClick={() => onResourceSelect(resource)}
                variant='body2'
                color='text.secondary'
              >
                {resource.author}
              </Typography>
            }
            action={
              showMore && (
                <IconButton
                  aria-label='more'
                  aria-controls={open ? 'fade-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <MoreVert />
                </IconButton>
              )
            }
          />

          <Typography
            onClick={() => onResourceSelect(resource)}
            variant='body2'
            color='text.secondary'
          >
            {resource.extraContent}
          </Typography>

          <Typography
            onClick={() => onResourceSelect(resource)}
            variant='body2'
            color='text.secondary'
          >
            {resource.createdAt ? new Date(resource.createdAt).toLocaleDateString() : ''}
          </Typography>
        </div>
      </Stack>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // TransitionComponent={Fade}
      >
        {resource.menuItems}
      </Menu>
    </div>
  );
}
