import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  Paper,
  Slide,
  Stack,
  TextField,
  Tooltip,
  useTheme,
  InputBase,
  IconButton,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';
import { Tag } from 'reactstrap';
import { useFormik } from 'formik';
import { payeeNameOptions } from '../../../utils/constants/constants';

export default function CasePaymentsReportView() {
  const { user } = useAuth();
  const [loading, setloading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [tag, setTag] = useState(null);
  const [year, setYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [requiredAlertVisible, setRequiredAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [authorizedJdxs, setAuthorizedJdxs] = useState([]);
  const [selectedJdxs, setSelectedJdxs] = useState([]);
  const [paymentModeOptions, setPaymentModeOptions] = useState([]);
  const [meOptions, setMEOptions] = useState([]);
  const [inputCase, setInputCase] = useState('');
  const [filteredCaseOptions, setFilteredCaseOptions] = useState([]);
  const [caseList, setCaseList] = useState([]);
  const { REACT_APP_API_URL } = process.env;
  const theme = useTheme();
  

  const formik = useFormik({
    initialValues: {
      jurisdictions: [],
      inputCase: null,
      pathologist: '',
      contactPerson: '',
      serviceType: '',
      payeeName: '',
      paymentMethod: '',
      voucherNumber: '',
      paymentDate: '',
      paymentRateType: '',
      comment: '',    
    },
    enableReinitialize: true,
    onSubmit: () => {
      handleSubmit();
    },
  });

  const columns = [
    {
      field: 'caseid',
      headerName: 'Case ID',
      width: 100,
      editable: false,
      headerAlign: 'center',
      renderCell: params => {
        return (
          <NavLink
            //variant="contained"
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
            }}
          >
            {params.formattedValue}
          </NavLink>
        );
      },
    },
    {
      field: 'jurisdictionName',
      headerName: 'Jurisdiction',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'pathologist',
      headerName: 'Pathologist',
      width: 100,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'contactPerson',
      headerName: 'ContactPerson',
      width: 100,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'serviceType',
      headerName: 'Service Type',
      width: 120,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'payeeName',
      headerName: 'Payee Name',
      width: 100,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'paymentMethod',
      headerName: 'Payment Method',
      width: 100,
      editable: false,
      headerAlign: 'left',
    },
    {
        field: 'voucherNumber',
        headerName: 'Voucher Number',
        width: 100,
        editable: false,
        headerAlign: 'left',
      },
      {
        field: 'paymentDate',
        headerName: 'Payment Date',
        width: 100,
        editable: false,
        headerAlign: 'left',
      },
      {
        field: 'paymentRateType',
        headerName: 'Payment RateType',
        width: 100,
        editable: false,
        headerAlign: 'left',
      },
      {
        field: 'comment',
        headerName: 'Comment',
        width: 100,
        editable: false,
        headerAlign: 'left',
      },
  ];

  const handleJdxChange = (event, data) => {
    // setSelectedJdxs(data);
    formik.setFieldValue('jurisdictions', data);
  };

  const handleStartDateChange = event => {
    if (event.target.value != '') {
      setStartDate(event.target.value);
    } else {
      setStartDate('');
    }
  };

  const handleEndDateChange = event => {
    if (event.target.value != '') {
      setEndDate(event.target.value);
    } else {
      setEndDate('');
    }
  };

  const handleYearChange = (event, data) => {
    setYear(data);
  };

  const handleTagChange = (event, data) => {
    setTag(data);
  };

  const dismissDateAlert = () => {
    setRequiredAlertVisible(false);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  const clearAll = () => {
    setStartDate('');
    setEndDate('');
    setYear(null);
    setSelectedJdxs([]);
    setResultsVisible(false);
  };


  const handleInputChange = (event, newInputValue) => {
    formik.setFieldValue('inputCase', newInputValue);
    filterOptions(newInputValue);
  };

  const handleCaseSelect = (selectedCase) => {
    formik.setFieldValue('inputCase', selectedCase);
  };

  const filterOptions = (inputValue) => {
    const filteredCases = caseList?.filter(
      (option) =>
        option.caseID.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredCaseOptions(filteredCases);
  };

  //gather search params from form and FETCH/POST to API
  const handleSubmit = async event => {
    event.preventDefault();


    //   if (startDate == '' || endDate == '') {
    //     setRequiredAlertVisible(true);
    //     return;
    //   }

    //   let startDateArr = startDate.split('-');
    //   let endDateArr = endDate.split('-');

    //   let startDateValue = new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]);
    //   let endDateValue = new Date(endDateArr[0], endDateArr[1] - 1, endDateArr[2]);

    //   if (endDateValue - startDateValue > 31536000000) {
    //     setRequiredAlertVisible(true);
    //     return;
    //   }


    if (selectedJdxs.length == 0) {
      setJdxAlertVisible(true);
      return;
    }

    let formData = new FormData();

    setloading(true);
    setResultsVisible(true);

    formData.append('SELECTEDJDXLIST', JSON.stringify(selectedJdxs));
    // formData.append('TAG', JSON.stringify(tag));

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'getcasepayments', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setlistresults(data);
          setloading(false);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    await setloading(false);
  };


  const GetPaymentModeOptions = async () => {
  fetch(REACT_APP_API_URL + 'getpaymentmodeoptions', {
    method: 'GET',
  })
    .then(res => {
      return res.json();
    })
    .then(data => {
      setPaymentModeOptions(data);
    })
    .catch(e => {
      //alert(e);
    });
}

const fetchMEs = async () => {
    await fetch(REACT_APP_API_URL + 'getpathologistoptions', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setMEOptions(data);
      })
      .catch(e => {
        alert(e);
      });
  };
  

  const fetchCases = async () => {
    let formData = new FormData();
    formData.append('SELECTEDJDXLIST', JSON.stringify(selectedJdxs));
    await fetch(REACT_APP_API_URL + 'getcaseids', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setCaseList(data);
      })
      .catch(e => {
        alert(e);
      });
  };
  

    useEffect(() => {
        fetchCases();
        GetPaymentModeOptions();
        fetchMEs();
      }, []);

  useEffect(() => {
    if (user === null) return;

    const fetchYear = async () => {
      try {
        const currentYear = new Date().getFullYear();
        setYear(currentYear);
        setYearOptions([...Array(16).keys()].map(i => i + (currentYear - 15)).reverse());
      } catch (e) {
        alert(e);
      }
    };

    fetchYear();
    setAuthorizedJdxs(user.jdxAccessList);
    setSelectedJdxs(user.jdxAccessList);
  }, [user]);

  
  return (
    <Card
      raised
      sx={{
        my: 3,
        mx: 'auto',
        width: { xs: '95%', lg: '85%' },
      }}
    >
      <CardHeader
        title='Case Payments Report'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
        action={
          <Tooltip
            sx={{ color: theme.palette.text.primary }}
            title='Case payments tracking.'
            placement='right-end'
          >
            <InfoIcon />
          </Tooltip>
        }
      />

      <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

      <CardContent>
        <Slide
          direction='right'
          in={requiredAlertVisible}
          mountOnEnter
          unmountOnExit
          sx={{ mb: 2 }}
        >
          <Alert severity='warning' onClose={dismissDateAlert}>
            Call taken range must be set within 1 year.
          </Alert>
        </Slide>

        <Slide direction='right' in={jdxAlertVisible} mountOnEnter unmountOnExit sx={{ mb: 2 }}>
          <Alert severity='warning' onClose={dismissJdxAlert}>
            Please select at least 1 Jurisdiction.
          </Alert>
        </Slide>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id='selectedJdxs'
              options={authorizedJdxs}
              value={selectedJdxs}
              disableCloseOnSelect
              limitTags={4}
              fullWidth
              size='small'
              getOptionLabel={option => option.jdxName}
              onChange={(event, data) => handleJdxChange(event, data)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank />}
                    checkedIcon={<MdOutlineCheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.jdxName}
                </li>
              )}
              renderInput={params => (
                <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
              )}
              ListboxComponent={'ul'}
            />
          </Grid>

          <Grid item xs={12} md={3}>
          <Autocomplete
        id='caseSearch'
        size='small'
        options={filteredCaseOptions || []}
        value={formik.values?.inputCase || ''}
        onInputChange={(event, data) => handleInputChange(event, data)}
        getOptionLabel={(option) => option?.caseID || ''}
        renderInput={params => {
            const { InputLabelProps, InputProps, ...rest } = params;
            return (
                <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', maxWidth: 400,  zIndex: 2 }}>
                <InputBase
                  {...params.InputProps}
                  {...params.InputLabelProps}
                  {...rest}
                  slotProps={{ input: { style: { padding: 0 } } }}
                  sx={{
                    ml: 1,
                    flex: 1,
                    textOverflow: 'ellipsis',
                    padding: 0,
                  }}
                  placeholder={ 'Search Case'}
                  onBlur={formik.handleBlur}
                />
                <IconButton type='button' sx={{ p: '10px' }}>
                  <SearchIcon />
                </IconButton>
              </Paper>
            );
          }}
      />
      <ul>
        {filteredCaseOptions?.map((caseItem) => (
          <li key={caseItem?.id} onClick={() => handleCaseSelect(caseItem)}>
            {caseItem?.caseID}
          </li>
        ))}
      </ul>
        </Grid>

        <Grid item xs={12} md={3}>
          <Autocomplete
            id='pathologist'
            name='pathologist'
            size='small'
            options={meOptions}
            value={
              meOptions.find(
                option =>
                  option.optionSeq === formik.values?.pathologist
              ) || null
            }
            onChange={formik.handleChange}         
            getOptionLabel={option => option.optionName}
            renderInput={params => <TextField {...params} label='Pathologist' />}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Autocomplete
            id='contactPerson'
            name='contactPerson'
            size='small'
            options={meOptions}
            value={
              meOptions.find(
                option =>
                  option.optionSeq === formik.values?.pathologist
              ) || null
            }
            onChange={formik.handleChange}         
            getOptionLabel={option => option.optionName}
            renderInput={params => <TextField {...params} label='Contact Person' />}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Autocomplete
            id='paymentMethod'
            name='paymentMethod'
            size='small'
            options={paymentModeOptions}
            value={
                paymentModeOptions.find(
                option =>
                  option.optionSeq === formik.values?.paymentMethod
              ) || null
            }
            onChange={formik.handleChange}         
            getOptionLabel={option => option.optionName}
            renderInput={params => <TextField {...params} label='Payment Method' />}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Autocomplete
            id='payeeName'
            name='payeeName'
            size='small'
            options={payeeNameOptions}
            value={
                payeeNameOptions.find(
                option =>
                  option.optionSeq === formik.values?.paymentMethod
              ) || null
            }
            onChange={formik.handleChange}         
            getOptionLabel={option => option.optionName}
            renderInput={params => <TextField {...params} label='Payee Name' />}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Autocomplete
            id='serviceType'
            name='serviceType'
            size='small'
            options={payeeNameOptions}
            value={
                payeeNameOptions.find(
                option =>
                  option.optionSeq === formik.values?.paymentMethod
              ) || null
            }
            onChange={formik.handleChange}         
            getOptionLabel={option => option.optionName}
            renderInput={params => <TextField {...params} label='Service Type' />}
          />
        </Grid>

        <Grid item xs={12} md={3}>
            <TextField
              id='voucherNumber'
              name='voucherNumber'
              placeholder='Voucher Number'
              label='Voucher Number'
              size='small'
              type='text'
              fullWidth
              value={
                formik.values?.voucherNumber != null ? formik.values?.voucherNumber : null
              }
              onChange={formik.handleChange}             
            />
          </Grid>

          <Grid item xs={12} md={3}>
          <Autocomplete
            id='paymentRateType'
            name='paymentRateType'
            size='small'
            options={payeeNameOptions}
            value={
                payeeNameOptions.find(
                option =>
                  option.optionSeq === formik.values?.paymentMethod
              ) || null
            }
            onChange={formik.handleChange}         
            getOptionLabel={option => option.optionName}
            renderInput={params => <TextField {...params} label='payment RateType' />}
          />
        </Grid>

        <Grid item xs={12} md={3}>
            <TextField
              id='paymentDate'
              name='paymentDate'
              label='Payment Date'
              type='date'
              size='small'
              value={endDate}
              //onChange={handleEndDateChange}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

        <Grid item xs={12} md={6}>
            <TextField
              id='comment'
              name='comment'
              placeholder='Comment'
              label='Comment'
              size='small'
              type='text'
              fullWidth
              value={
                formik.values?.voucherNumber != null ? formik.values?.voucherNumber : null
              }
              onChange={formik.handleChange}             
            />
          </Grid>

        </Grid>

        <Divider sx={{ my: 2, opacity: 1 }} />

        <Stack
          direction='row'
          sx={{
            width: '100%',
            justifyContent: 'right',
          }}
          spacing={2}
        >
          <Button type='submit' variant='contained' color='primary' onClick={handleSubmit}>
            Calculate
          </Button>

          <Button color='secondary' variant='outlined' onClick={clearAll}>
            Clear
          </Button>
        </Stack>

        <Collapse in={resultsVisible} sx={{ mt: resultsVisible ? 4 : 0 }}>
          <ReportDataGrid
            loading={loading}
            listresults={listresults}
            idcolumn='caseid'
            columnsInput={columns}
            title=' Tag Results By Jurisdiction'
            chatcontent={
              <Paper
                elevation={3}
                sx={{
                  p: '10px',
                  px: '20px',
                  borderRadius: '20px',
                  width: 'max-content',
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  I've listed all the cases using your Tag selection in the date-range / year selected. These
                  cases are reported within the date-range and have been signed and filed, ordered by the Jurisdictions.
                </div>
              </Paper>
            }
          />
        </Collapse>
      </CardContent>
    </Card>
  );
}
