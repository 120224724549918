import React, { useState, useEffect } from 'react';
import MainCard from '../../../components/AdministrationModules/MainCard';
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
  Stack,

  Divider,
  Modal,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WorkstationEmail from '../../../components/workstationEmail';
import Takephoto from '../../../components/AdministrationModules/TakePhotoModules/Takephoto';
import Viewphoto from '../../../components/AdministrationModules/TakePhotoModules/ViewPhoto';
// import AccessDenied from 'ui-component/AccessDenied';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

const WorkStationSetupView = () => {
  const permissions = JSON.parse(localStorage.getItem('accessPermissions'));

  const [open, setOpen] = React.useState(false);
  const [viewModal, setViewModal] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openModal, setOpenModal] = useState(false);
  // const [openModalview, setOpenModalview] = useState(false);
  const [photoModal, setPhotoModal] = useState(false);
  // const [photoAlert, setPhotoAlert] = useState(false);
  // const dispatch = useDispatch();
  const handleTakephto = () => {
    setPhotoModal(true);
  };

  // const handleViewphoto = () => {
  //     setOpenModalview(true);
  // };

  // const [photo, setPhoto] = useState('');

  // const setCapturedPhoto = (capturedPhoto) => {
  //     setPhoto(typeof capturedPhoto === 'object' ? URL.createObjectURL(capturedPhoto?.[0]) : capturedPhoto);
  // };

  const closeTakePhoto = () => {
    setPhotoModal(false);
  };

  // const handleUpload = (imageSrc) => {
  //     dispatch(sendPhotoForByte(imageSrc));
  //     dispatch(sendPhoto(imageSrc));
  //     setOpenModal(false);
  //     setPhotoAlert(true);
  //     setTimeout(() => {
  //         setPhotoAlert(false);
  //     }, [3000]);
  // };

  return (
    <div>
    <Stack>
    <Typography variant='h5' component='h1'>
      <b>Worktation Setup</b> 
    </Typography>
    <Typography variant='subtitle1' color='gray'>
    Verify printer, camera and email functionality
            </Typography>
  </Stack>
  <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
      <Box sx={{ flexGrow: 1 }}>
        
        <Grid container spacing={2}>
          <Grid item lg={12} xs={12} md={12} sm={12}>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
            >
              <Box sx={style}>
                <div
                  style={{
                    backgroundColor: '#2196f3',
                    borderRadius: '6px',
                    marginBottom: '16px',
                    padding: '20px 20px',
                  }}
                >
                  <div>
                    <Typography variant='h5' sx={{ color: '#fff' }}>
                      Workstation Setup Mail
                    </Typography>
                  </div>
                  <div>
                    <Button
                      onClick={handleClose}
                      sx={{
                        float: 'right',
                        color: '#fff',
                        position: 'relative',
                        bottom: '34px',
                      }}
                    >
                      <CloseIcon />
                    </Button>
                  </div>
                </div>
                <WorkstationEmail />
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item lg={2} xs={12} md={12} sm={12}>
                      <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={() => setOpen(false)}
                        sx={{ transform: 'translate(300px, 1px)' }}
                      >
                        Close
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </Box>
  
        
        <br />
        <Box sx={{ flexGrow: 1 }}>
        
          <br />
          <Grid container spacing={2}>
            <Grid item lg={1} xs={12} md={12} sm={12}>
              <FormControl sx={{ color: '#2196f3' }}> Printer: </FormControl>
            </Grid>

            <Grid item lg={2} xs={12} md={12} sm={12}>
              <FormControl>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  onClick={() => window.print()}
                  disabled={!permissions?.['Workstation Setup']?.edit}
                >
                  Print Test Page
                </Button>
              </FormControl>
            </Grid>
            <Grid item lg={9} xs={12} md={12} sm={12}>
              <FormControl>
                <Typography variant='caption' display='block' gutterBottom>
                  Print a US letter size test page to verify that the printer,
                  connected to the workstation, is setup properly.
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <br />
       
        <br />
        <Box sx={{ flexGrow: 1 }}>
         
          <Grid container spacing={2}>
            <Grid item lg={1} xs={12} md={12} sm={12}>
              <FormControl sx={{ color: '#2196f3' }}> Camera: </FormControl>
            </Grid>

            <Grid item lg={2} xs={12} md={12} sm={12}>
              <FormControl>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  onClick={handleTakephto}
                  disabled={!permissions?.['Workstation Setup']?.edit}
                >
                  Take Test Photo
                </Button>
                {photoModal && (
                  <Takephoto
                    open={photoModal}
                    setModal={setPhotoModal}
                    handleClose={closeTakePhoto}
                  />
                )}
                {/* {photoAlert ? <SuccessAlert message="Successfully Uploaded" /> : null} */}
              </FormControl>
            </Grid>
            <Grid item lg={9} xs={12} md={12} sm={12}>
              <FormControl>
                <Typography variant='caption' display='block' gutterBottom>
                  Test that the camera is working correctly.
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <br />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={1} xs={12} md={12} sm={12}>
              <FormControl> </FormControl>
            </Grid>

            <Grid item lg={2} xs={12} md={12} sm={12}>
              <FormControl>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  onClick={() => setViewModal(true)}
                  disabled={!permissions?.['Workstation Setup']?.edit}
                >
                  View Test Photo
                </Button>
                {/* {openModalview && (
                                        <Viewphoto
                                            module="workstation"
                                            capturedPhoto={photo}
                                            availablePhoto={photo}
                                            openModalview={openModalview}
                                            setOpenModalview={setOpenModalview}
                                        />
                                    )} */}
              </FormControl>
              {viewModal ? (
                <Viewphoto
                  open={viewModal}
                  closeModal={() => setViewModal(false)}
                />
              ) : null}
            </Grid>
            <Grid item lg={9} xs={12} md={12} sm={12}>
              <FormControl>
                <Typography variant='caption' display='block' gutterBottom>
                  After taking the test photo you can trying viewing it, you can zoom in and out with minimal web traffic. Especially useful for high resolution photography.
                  
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <br />

       
        <Box sx={{ flexGrow: 1 }}>
        
          <br />
          <Grid container spacing={2}>
            <Grid item lg={1} xs={12} md={12} sm={12}>
              <FormControl sx={{ color: '#2196f3' }}>E-mail:</FormControl>
            </Grid>

            <Grid item lg={2} xs={12} md={12} sm={12}>
              <FormControl>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  onClick={handleOpen}
                  disabled={!permissions?.['Workstation Setup']?.edit}
                >
                  Send Test Email
                </Button>
              </FormControl>
            </Grid>
            <Grid item lg={9} xs={12} md={12} sm={12}>
              <FormControl>
                <Typography variant='caption' display='block' gutterBottom>
                  Verify that email can be sent successfully
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <br />
   
    </div>
  );
};

export default WorkStationSetupView;
