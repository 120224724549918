import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-premium';
import React from 'react';
import { useNavigate } from 'react-router';
import SiloDataGrid from '../../utils/components/SiloDataGrid';
import { DecedentNameCell } from './DecedentNameCell';
import { DashboardDataGridProps } from './useDashboardDataGridAPI.hook';
import { format } from 'date-fns';

export function OpenRecordReviewsDataGrid({ data, isLoading }: DashboardDataGridProps) {
  const navigate = useNavigate();

  const handleCaseClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
  ) => {
    event.stopPropagation();
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const columns: GridColDef[] = [
    {
      field: 'caseid',
      headerName: 'CaseID',
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'decedentname',
      headerName: 'Decedent',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: params => <DecedentNameCell {...params} />,
    },
    {
      field: 'pathologist',
      headerName: 'Pathologist',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'investigator',
      headerName: 'MLDI',
      width: 140,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'reported',
      headerName: 'Reported On',
      width: 140,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      valueGetter: value => {
        return value != null ? new Date(value) : null;
      },
      valueFormatter: value => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
      },
    },
  ];

  return (
    <SiloDataGrid
      loading={isLoading}
      listresults={data}
      columnsInput={columns}
      idcolumn='caseid'
      gridheight={200}
      gridcolor='#000080'
      title='Open Record Reviews'
      tooltip="These are 'Record Review' cases that are either missing a Confirmed ID or there are documents to be uploaded... to drop off the silo, make sure the ID Modality is confirmed and accepted, and that an Investigative Data Sheet, Medical Records, AND a 'Record-Review Report' are all uploaded to the case"
      casecount
      rowsperpage={50}
      filterModel={undefined}
      columnsVisibility={undefined}
    />
  );
}
