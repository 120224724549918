export default (state, action) => {
  if (action.type === 'USER_PROFILE_INFORMATION') {
    console.log('action.payload', action.payload);
    return { ...state, profileInformation: action.payload };
  }
  if (action.type === 'UPLOAD_SIGNATURE') {
    return { ...state, signature: action.payload };
  }
  if (action.type === 'UPLOAD_PHOTO') {
    return { ...state, photo: action.payload };
  }
  if (action.type === 'UPLOAD_PHOTO_TO_VIEW') {
    return { ...state, viewPhoto: action.payload };
  }
  if (action.type === 'SEND_ROLES') {
    return { ...state, roleDetails: action.payload };
  }
  if (action.type === 'NOTIFICATION_DETAILS') {
    return { ...state, notificationDetails: action.payload };
  }
  if (action.type === 'PHOTO_TYPE_OBJECT') {
    return { ...state, photoTypeObject: action.payload };
  }
  if (action.type === 'DOCUMENT_TYPE_OBJECT') {
    return { ...state, documentTypeObject: action.payload };
  }
  if (action.type === 'REPORT_TYPE_OBJECT') {
    return { ...state, reportTypeObject: action.payload };
  }
  if (action.type === 'COPY_ROLE_OBJECT') {
    return { ...state, copyRoleObject: action.payload };
  }
  return state;
};
