import * as url from '../URL_HELPERS';

const { REACT_APP_API_URL } = process.env;

export async function getMaritalStatusOptionsApi() {
  return fetch(REACT_APP_API_URL + url.GET_MAITIAL_STATUS_OPTIONS, {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      console.log(e);
    });
}
