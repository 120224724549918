import React from 'react';
import { Box, Stack, Typography, Divider } from '@mui/material';
// @ts-ignore
import { DailyExamListNew } from '../../components/CMSAdmin/DailyExamList/DailyExamList';

export default function DailyCaseListView() {
  return (
    <Box component='div' sx={{ margin: 0 }}>
      <Stack sx={{ margin: 2 }}>
        <Typography variant='h5' component='h1'>
          <b>Daily Exam List</b>
        </Typography>
        <Typography variant='subtitle1' color='gray'>
          View and Assign Autopsies & External Exams
        </Typography>
        <Divider sx={{ borderColor: '#555', mx: 0, mt: 1 }} />
      </Stack>
      <DailyExamListNew />
    </Box>
  );
}
