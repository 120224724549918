import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
  FormHelperText,
  TextField,
  ClickAwayListener,
  Popper,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/system';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import * as Yup from 'yup';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import FormControl from '@mui/material/FormControl';
import { saveAdminData } from '../../../../services/utility-api';
import ApiNotification from '../../../../components/DialogBox';

const PopperStyledComponent = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
    }`,
}));
const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const AddEdit = ({ editObj, county, getAllData, setIsAddEditOn, allRow, locationListPermissions }) => {
  const id = '00000000-0000-0000-0000-000000000000';
  const [dropdownopen, setDropdownopen] = useState(false);
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);
  useEffect(() => { }, [editObj]);

  const handleClickAway = (e) => {
    setDropdownopen(false);
  };

  const initialValues =
    editObj && Object.keys(editObj)
      ? {
        ...editObj,
        countys: editObj?.locationOnCounty || [],
      }
      : {
        countys: [],
        locationOnCounty: [],
        locationName: '',
        locationDesc: '',
      };
  const validationSchema = Yup.object({
    locationName: Yup.string()
      .required('Location name is required')
      .nullable('Location name is required'),
    locationOnCounty: Yup.array().min(1, 'Jurisdiction is required').nullable(),
  });

  const [countyNameMapping, setCountyNameMapping] = useState({});

  useEffect(() => {
    const tempCountyNameMapping = {};
    Object.keys(county).map((countySeq) => {
      tempCountyNameMapping[county[countySeq]['countyName']] =
        county[countySeq];
    });
    setCountyNameMapping(tempCountyNameMapping);
  }, [county]);

  const onSave = async (values) => {
    const newLiveryCounty = [];

    editObj?.locationOnCounty?.map((coun) => {
      let foundMatchingCounty = false;

      values?.locationOnCounty?.map((val) => {
        if (coun.countySeq === countyNameMapping[val]['countySeq']) {
          foundMatchingCounty = true;
        }
      });
      if (foundMatchingCounty) {
        newLiveryCounty.push({
          locationSeq: values?.locationSeq,
          countySeq: coun.countySeq,
          status: 'Active',
        });
      } else {
        newLiveryCounty.push({
          locationSeq: values?.locationSeq,
          countySeq: coun.countySeq,
          status: 'InActive',
        });
      }
    });

    values?.locationOnCounty?.map((val) => {
      let foundMatchingCounty = false;

      editObj?.locationOnCounty?.map((coun) => {
        if (coun.countySeq === countyNameMapping[val]['countySeq']) {
          foundMatchingCounty = true;
        }
      });
      if (!foundMatchingCounty) {
        newLiveryCounty.push({
          locationSeq: id,
          countySeq: countyNameMapping[val]['countySeq'],
          status: 'Active',
        });
      }
    });

    const postObj = {
      locationSeq:
        Object.keys(editObj).length === 0 ? id : editObj?.locationSeq,
      morgueDetailSeq: values?.morgueDetailSeq || id,
      locationTypeSeq: values?.locationTypeSeq || id,
      locationSubTypeSeq: values?.locationSubTypeSeq || id,
      locationName: values?.locationName,
      locationDesc: values?.locationDesc,
      contactInfoSeq: values?.contactInfoSeq || id,
      parentLocationSeq: values?.parentLocationSeq || id,
      isUsed: values?.isUsed || false,
      status: values?.status || 'Active',
      daveFacilityCode: values?.daveFacilityCode || '',
      countySeq: values?.countySeq || id,
      locationOnCounty: newLiveryCounty,

      address: [],
      contactItem: null,
    };
    try {
      if (allRow
        ?.filter(data => data?.locationSeq !== values?.locationSeq)
        ?.findIndex(data => data?.locationName.toLowerCase() === postObj?.locationName.toLowerCase()) !== -1) {
        setDialogContents("Duplicate Location Name!");
        setOpenApiDialogBox(true);
      } else {
        const response = await saveAdminData({
          values: postObj,
          tableName: 'location',
        });
        if (response.status == 0) {
          setTimeout(() => {
            setIsAddEditOn(false);
            getAllData();
          }, 1700);
        }
        setDialogContents(response.message);
        setOpenApiDialogBox(true);
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  const [checkAll, setCheckAll] = useState(false);

  const checkAllChange = (event, handleChange) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      let newdata = [
        ...Object.keys(county).map(
          (countySeq) => county[countySeq]['countyName']
        ),
      ];
      delete newdata[0];
      handleChange({ target: { value: newdata, name: 'locationOnCounty' } });
    } else {
      handleChange({ target: { value: [], name: 'locationOnCounty' } });
    }
  };

  return (
    <>
      {' '}
      {/* <Paper elevation={3} spacing={2} sx={{ padding: '16px' }}> */}
      <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
        <Grid container spacing={2}>
          <Grid item xs={10} mb={2} mt={2}>
            <Typography variant='h5'>
              <strong>
                {Object.keys(editObj).length ? 'Modify' : 'Enter New'} Location
              </strong>
            </Typography>
          </Grid>
        </Grid>
        <Formik
          initialValues={{
            ...initialValues,
            locationOnCounty: initialValues.countys.map(
              (location) =>
                county &&
                Object.keys(county) &&
                county[location.countySeq] &&
                county[location.countySeq]['countyName']
            ),
            locationName: initialValues?.locationName
              ? initialValues?.locationName
              : '',
          }}
          validationSchema={validationSchema}
          onSubmit={onSave}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            handleChange,
          }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Box>
                        <Autocomplete
                          multiple
                          disableCloseOnSelect
                          limitTags={9}
                          id='checkboxes-tags-demo'
                          options={
                            Object.keys(county).map(
                              (countySeq) => county[countySeq]['countyName']
                            ) || []
                          }
                          value={values.locationOnCounty}
                          open={dropdownopen}
                          name='locationOnCounty'
                          onChange={(event, newValue, reason) => {
                            if (reason === 'selectOption') {
                              handleChange({
                                target: {
                                  value: newValue,
                                  name: 'locationOnCounty',
                                },
                              });
                            } else if (reason === 'removeOption') {
                              setCheckAll(false);
                              handleChange({
                                target: {
                                  value: newValue,
                                  name: 'locationOnCounty',
                                },
                              });
                            } else if (reason === 'clear') {
                              handleChange({
                                target: { value: [], name: 'locationOnCounty' },
                              });
                              setCheckAll(false);
                            }
                          }}
                          onClose={(e, reason) => {
                            if (reason === 'escape') {
                              setDropdownopen(false);
                            }
                          }}
                          onOpen={() => {
                            setDropdownopen(true);
                          }}
                          PopperComponent={(param) => (
                            <PopperStyledComponent {...param}>
                              <Box {...param} />
                              <Divider />
                            </PopperStyledComponent>
                          )}
                          getOptionLabel={(option) => {
                            return option ? option?.toString() : [];
                          }}
                          renderOption={(props, option, { selected }) => (
                            <>
                              <li
                                {...props}
                                style={{
                                  marginLeft:
                                    option == 'New Jersey' ||
                                      option == 'NJ State Case' ||
                                      option == 'MAiD Case'
                                      ? ''
                                      : '15px',
                                }}
                              >
                                {option === 'Select All' ? (
                                  <Checkbox
                                    checked={checkAll}
                                    onChange={(e) =>
                                      checkAllChange(e, handleChange)
                                    }
                                    id='check-all'
                                    sx={{ marginRight: '8px' }}
                                    onMouseDown={(e) => e.preventDefault()}
                                  />
                                ) : (
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected || checkAll}
                                  />
                                )}
                                {option}
                              </li>
                            </>
                          )}
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label='Jurisdiction'
                              placeholder='Select Jurisdiction'
                            />
                          )}
                        />
                      </Box>
                    </ClickAwayListener>
                    {
                      <ErrorMessage
                        name={`locationOnCounty`}
                        render={(msg) => (
                          <FormHelperText error>{msg}</FormHelperText>
                        )}
                      />
                    }
                  </Grid>
                </Grid>
                <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
                <Grid container spacing={2}>
                  <Grid item xs={12} mb={0} mt={2} spacing={2}>
                    <Typography variant='h6'>
                      <strong>Location</strong>
                    </Typography>
                  </Grid>

                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <FormControl fullWidth error variant='standard'>
                      <TextField
                        focused
                        required
                        id='locationName'
                        label='Location Name'
                        variant='outlined'
                        name='locationName'
                        defaultValue=''
                        margin='normal'
                        size='large'
                        value={values.locationName}
                        onChange={handleChange}
                        onInput={(e) => {
                          e.target.value = e.target.value.slice(0, 500);
                        }}
                      />
                    </FormControl>
                    <ErrorMessage
                      name='locationName'
                      render={(msg) => (
                        <FormHelperText error>{msg}</FormHelperText>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12} mSd={6} lg={6}>
                    <FormControl fullWidth sx={{ marginTop: '20px' }}>
                      <TextField
                        focused
                        name='locationDesc'
                        id='locationDesc'
                        label='Location Description'
                        variant='outlined'
                        size='small'
                        fullWidth
                        multiline
                        rows={5}
                        rowsMax={5}
                        value={values.locationDesc}
                        onChange={handleChange}
                        onInput={(e) => {
                          e.target.value = e.target.value.slice(0, 500);
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Box>
                      <Button
                        onClick={handleSubmit}
                        type='submit'
                        variant='contained'
                        sx={{ minWidth: '125px', marginTop: '7px' }}
                        disabled={
                          !locationListPermissions?.edit && !locationListPermissions?.add
                        }
                      >
                        {Object.keys(editObj) > 1 ? 'Update' : 'Save'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      {/* </Paper> */}
      <ApiNotification
        openApiDialogBox={openApiDialogBox}
        setOpenApiDialogBox={setOpenApiDialogBox}
        dialogContents={dialogContents}
        closeDialogBox={() => { setOpenApiDialogBox(false) }}
      />
    </>
  );
};
export default AddEdit;
