import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox, InputLabel, Select, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LockIcon from '@mui/icons-material/Lock';

import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  FormHelperText,
  TextField,
  IconButton,
} from '@mui/material';
import { Container } from '@mui/system';
import * as Yup from 'yup';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router';
import { saveAdminData } from '../../../../../../services/utility-api';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import { referenceTypeFieldLengthChecks } from '../../referenceTypeFieldLengthChecks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const AddEdit = ({
  editObj,
  referenceMasterTableName,
  ClothingType,
  maxOrder,
  setIsAddEditOn,
  getAllData,
  referencePermissions,
}) => {
  const [dialogContents, setDialogContents] = useState('');
  const [openApiDialogBox, setOpenApiDialogBox] = useState(false);

  const [medicalChecked, setMedicalChecked] = useState(
    editObj?.authorityTypeIsMedical === 'False' || editObj?.authorityTypeIsMedical === undefined
      ? false
      : true
  );
  const initialValues = {
    ...editObj,
    authorityTypeOrder: editObj?.authorityTypeOrder ?? '',
    status: editObj?.status ?? 'Active',
    [referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Order']:
      editObj[
        referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Order'
      ]
        ? Number(
            editObj[
              referenceMasterTableName[0].toLowerCase() +
                referenceMasterTableName.slice(1) +
                'Order'
            ]
          )
        : maxOrder + 10,
  };

  const validationSchema = Yup.object().shape({
    authorityTypeName: Yup.string()
      .required('Medical and other entity name is required')
      .max(
        referenceTypeFieldLengthChecks[`authorityTypeName`],
        `Medical Entity name can not be more than ${referenceTypeFieldLengthChecks['authorityTypeName']}`
      ),
    authorityTypeOrder: Yup.string()
      .required('List order is required')
      .max(99999, 'Medical Entity Order can not be more than 99999'),
  });

  const onSave = async values => {
    const id = '00000000-0000-0000-0000-000000000000';

    // make api call to save add data
    try {
      const response = await saveAdminData({
        values: {
          ...values,
          authorityTypeIsMedical: medicalChecked,
          [referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq']:
            values[
              referenceMasterTableName[0].toLowerCase() + referenceMasterTableName.slice(1) + 'Seq'
            ] || id,
        },
        tableName: referenceMasterTableName,
      });
      if (response.message.includes('UNIQUE KEY')) {
        setDialogContents('Duplicate values are not allowed!');
      } else {
        setDialogContents(response.message);
      }
      if (response.status == 0) {
        setTimeout(() => {
          setIsAddEditOn(false);
          getAllData();
        }, 1700);
      }
      setOpenApiDialogBox(true);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <Grid elevation={0} spacing={2} sx={{ padding: '16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={10} mb={2} mt={2}>
            <Typography variant='h5'>
              <strong>
                {Object.keys(editObj).length ? 'Modify ' : 'Enter New '} Medical and Other Entity
              </strong>
            </Typography>
          </Grid>
        </Grid>
        <Formik initialValues={initialValues} onSubmit={onSave} validationSchema={validationSchema}>
          {({ handleSubmit, values, handleChange }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12} md={6} lg={6}>
                        <FormControl fullWidth error required variant='standard'>
                          <TextField
                            required
                            focused
                            id='outlined-basic'
                            label='Medical and other entity'
                            variant='outlined'
                            name={'authorityTypeName'}
                            onInput={e => {
                              e.target.value = e.target.value.slice(
                                0,
                                referenceTypeFieldLengthChecks['authorityTypeName']
                              );
                            }}
                            disabled={
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Other' ||
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Unknown'
                            }
                            value={values?.['authorityTypeName']}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name={'authorityTypeName'}
                            render={msg => <FormHelperText error>{msg}</FormHelperText>}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={6} xs={12} md={6} lg={6}>
                        <FormControl
                          error
                          fullWidth
                          variant='standard'
                          sx={{ position: 'relative', bottom: '16px' }}
                        >
                          <TextField
                            focused
                            required
                            type='number'
                            id='authorityTypeOrder'
                            label='List Order'
                            variant='outlined'
                            name='authorityTypeOrder'
                            margin='normal'
                            size='large'
                            value={values?.authorityTypeOrder}
                            onChange={handleChange}
                            disabled={
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Other' ||
                              values[
                                referenceMasterTableName[0].toLowerCase() +
                                  referenceMasterTableName.slice(1) +
                                  'Name'
                              ] == 'Unknown'
                            }
                          />
                          <ErrorMessage
                            name={'authorityTypeOrder'}
                            render={msg => <FormHelperText error>{msg}</FormHelperText>}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item sm={1} xs={1} md={1} lg={1}>
                        <FormControl fullWidth error required variant='standard'>
                          <FormControlLabel
                            value='start'
                            control={
                              <Checkbox
                                checked={medicalChecked}
                                onChange={e => setMedicalChecked(e.target.checked)}
                                disabled={
                                  values[
                                    referenceMasterTableName[0].toLowerCase() +
                                      referenceMasterTableName.slice(1) +
                                      'Name'
                                  ] == 'Other' ||
                                  values[
                                    referenceMasterTableName[0].toLowerCase() +
                                      referenceMasterTableName.slice(1) +
                                      'Name'
                                  ] == 'Unknown'
                                }
                              />
                            }
                            label='Medical'
                            labelPlacement='top'
                          />

                          <ErrorMessage
                            name={'medicalAndOtherEntity'}
                            render={msg => <FormHelperText error>{msg}</FormHelperText>}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Button
                            type='submit'
                            variant='contained'
                            sx={{ minWidth: '125px', marginTop: '7px' }}
                            disabled={!referencePermissions?.add}
                          >
                            Save
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>

        <div className='modal'>
          <Dialog
            open={openApiDialogBox}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenApiDialogBox(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                {dialogContents}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <IconButton onClick={() => setOpenApiDialogBox(false)}>
                <CloseIcon />
              </IconButton>
            </DialogActions>
          </Dialog>
        </div>
      </Grid>
    </>
  );
};
export default AddEdit;
