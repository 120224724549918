import { DashboardPrefences } from "../DashboardContext";
import { DashboardSilo } from "../dashboard.constants";
import { GridRow } from "./grid.constants";
import { v4 as uuid } from 'uuid'

export function constructGridRows(
    dashboardLayout: DashboardPrefences["dashboardLayout"], 
    silos: DashboardSilo[],
  ): GridRow[] {
    const newRows: GridRow[] = [];
    let currentRow: GridRow = {
        key: uuid(),
        items: [],
    }
  
    dashboardLayout.forEach((item, index) => {
      const silo = silos.find(s => s.dashboardSiloSeq === item.dashboardSiloSeq);
  
      // Skip the item if no corresponding silo is found
      if (!silo) return;
  
      const rowItem = {
        name: item.dashboardSiloName,
        key: item.dashboardSiloSeq,
        silo: silo,
        size: silo.defaultSize,
      };

      if (rowItem.size === 12) {
            currentRow.items.push(rowItem)
            newRows.push(currentRow)
            currentRow = {
                key: uuid(),
                items: []
            }
      } else {
        if (currentRow.items.length < 2) {
            currentRow.items.push(rowItem)

            if (currentRow.items.length === 2) {
                newRows.push(currentRow);
                currentRow = {
                    key: uuid(),
                    items: [],
                }
            }
        } else {
            currentRow.items.push(rowItem);
        }
      }
  
    })

    if (currentRow.items.length > 0) {
        newRows.push(currentRow)
    }
  
    return newRows;
  }
  