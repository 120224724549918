import { Autocomplete, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { renderAutocompleteCellDisplay } from '../../../../../utils/components/CrudDataGrid/AutocompleteCellWithTextField';
import { renderAutocompleteEditCellWithTextField } from '../../../../../utils/components/CrudDataGrid/AutocompleteEditCellWithTextField';
import {
  handleAutocompleteChange,
  handleCheckboxChange,
  handleNumericTextFieldChange,
  handleTextFieldChange,
} from '../../../../../utils/components/DialogCrudDataGrid';
import NumericTextField from '../../../../../utils/components/NumericTextField';
import { PropertyInventoryDataGrid } from './PropertyInventoryDataGrid';

export function OtherPIDataGrid() {
  const [personalItemOptions, setPersonalItemOptions] = useState([]);
  const [currentCellValue, setCurrentCellValue] = useState(null);
  const { user } = useAuth();
  const { REACT_APP_API_URL } = process.env;
  const formik = useFormikContext<CMSCase>();

  useEffect(() => {
    fetchPersonalItemOptions();
  }, []);

  // need to create the route
  const fetchPersonalItemOptions = () => {
    fetch(REACT_APP_API_URL + 'getpersonalitemoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setPersonalItemOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const propertyOtherPIColumns = [
    {
      flex: 1,
      minWidth: 150,
      field: 'personalItem',
      headerName: 'Personal Item',

      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: value => {
        if (!value) return value;
        return value.optionName;
      },
      valueOptions: personalItemOptions,
      renderEditCell: params => {
        // Directly access the string value from the current row
        // console.log('what is the row here: ', params?.row);
        const textFieldText = params.row?.otherPropInventory;
        return renderAutocompleteEditCellWithTextField({
          ...params,
          textFieldText,
          rowField: 'otherPropInventory',
        });
      },
      renderCell: params => {
        const textFieldText = params.row.otherPropInventory;
        return renderAutocompleteCellDisplay({
          ...params,
          textFieldText,
        });
      },
      toggleTextfieldoption: 'Other',
    },
    {
      flex: 0.4,
      minWidth: 100,
      field: 'number',
      headerName: 'No.',
      headerAlign: 'left',
      align: 'left',
      type: 'number',
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'description',
      headerName: 'Description',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
    },
    {
      flex: 0.3,
      minWidth: 80,
      field: 'funeralHome',
      headerName: 'FH',
      type: 'boolean',

      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 0.3,
      minWidth: 80,
      field: 'releasedtoLE',
      headerName: 'LE',
      type: 'boolean',

      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 0.3,
      minWidth: 80,
      field: 'retained',
      headerName: 'RET',
      type: 'boolean',

      renderCell: cell => <Checkbox size='small' disabled checked={cell.value} />,
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'note',
      headerName: 'Notes',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
    },
  ];

  const dialogContent = ({ mode, data, onChange }) => {
    const [localData, setLocalData] = useState(null);

    useEffect(() => {
      setLocalData(data);
    }, [data]);

    return (
      <Grid container spacing={1} sx={{ alignItems: 'center' }}>
        <Grid item xs={12} md={6} xl={3}>
          <Autocomplete
            options={personalItemOptions || []}
            getOptionLabel={option => option.optionName || ''}
            value={localData?.personalItem || null}
            onChange={(event, value) =>
              handleAutocompleteChange(
                event,
                value,
                'personalItem',
                localData,
                setLocalData,
                onChange
              )
            }
            renderInput={params => (
              <TextField {...params} label='Personal Item' margin='dense' fullWidth />
            )}
          />
        </Grid>

        {localData?.personalItem?.optionName === 'Other' && (
          <Grid item xs={12} md={6} xl={3}>
            <TextField
              value={localData?.otherPropInventory ?? ''}
              onChange={event =>
                handleTextFieldChange(
                  event,
                  'otherPropInventory',
                  localData,
                  setLocalData,
                  onChange
                )
              }
              label='Other Name'
              fullWidth
              margin='dense'
            />
          </Grid>
        )}

        <Grid item xs={12} md={6} xl={3}>
          <NumericTextField
            value={localData?.number ?? 0}
            onChange={value =>
              handleNumericTextFieldChange(value, 'number', localData, setLocalData, onChange)
            }
            margin='dense'
            fullWidth
            label='Number'
          />
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          <TextField
            value={localData?.description ?? ''}
            onChange={event =>
              handleTextFieldChange(event, 'description', localData, setLocalData, onChange)
            }
            label='Description'
            fullWidth
            margin='dense'
          />
        </Grid>

        <Grid item xs='auto'>
          <FormControlLabel
            label='Funeral Home'
            control={
              <Checkbox
                checked={localData?.funeralHome ?? false}
                onChange={event =>
                  handleCheckboxChange(event, 'funeralHome', localData, setLocalData, onChange)
                }
              />
            }
          />
        </Grid>

        <Grid item xs='auto'>
          <FormControlLabel
            label='Law Enforcement'
            control={
              <Checkbox
                checked={localData?.releasedtoLE ?? false}
                onChange={event =>
                  handleCheckboxChange(event, 'releasedtoLE', localData, setLocalData, onChange)
                }
              />
            }
          />
        </Grid>

        <Grid item xs='auto'>
          <FormControlLabel
            label='Retained'
            control={
              <Checkbox
                checked={localData?.retained ?? false}
                onChange={event =>
                  handleCheckboxChange(event, 'retained', localData, setLocalData, onChange)
                }
              />
            }
            sx={{ pr: 2 }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={localData?.note ?? ''}
            onChange={event =>
              handleTextFieldChange(event, 'note', localData, setLocalData, onChange)
            }
            label='Notes'
            fullWidth
            margin='dense'
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <PropertyInventoryDataGrid
      rows={formik.values?.caseMortuary?.propertyInventory?.otherPI ?? []}
      columns={propertyOtherPIColumns}
      idColumn='otherPISeq'
      getApi='getpropertyotherpi'
      createApi='createpropertyotherpi'
      updateApi='updatepropertyotherpi'
      deleteApi='deletepropertyotherpi'
      title='Other PI'
      fieldPath='otherPI'
      dialogContent={dialogContent}
    />
  );
}
