// import { countyCheck } from '../../utils/functions/countyCheck';  //globalizing to jdxCheck   "jdx acronym for jurisdiction"
import { jurisdictionCheck } from '../../utils/functions/jurisdictionCheck';

export function userHasAccess(currentUser, cmscaseid) {
  if (
    currentUser != null &&
    jurisdictionCheck(currentUser.jdxAccessList, cmscaseid.substring(0, 2))
  ) {
    return true;
  }

  return false;
}
