import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Checkbox,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { Option } from '../../views/Case/CaseViewModules/MortuaryModules/XrayRequestForm/xrayrequestform.constants';

interface MultiSelectAutocompleteProps<T extends Option>
  extends Omit<
    AutocompleteProps<T, true, false, true>,
    'renderInput' | 'renderOption' | 'renderTags' | 'onChange'
  > {
  options: T[];
  loading: boolean;
  label: string;
  disabled: boolean;
  selectedOptions: T[];
  onSelectedOptionsChange: (selected: T[]) => void;
}

function sortOptions(options: Option[], selectedValues: Option[]) {
  // Ensure stable sorting by avoiding changing the original array
  const sortedOptions = [...options];
  sortedOptions.sort((a, b) => {
    const aSelected = selectedValues.some(
      val => val.optionSeq.toLowerCase() === a.optionSeq.toLowerCase()
    );
    const bSelected = selectedValues.some(
      val => val.optionSeq.toLowerCase() === b.optionSeq.toLowerCase()
    );

    return (bSelected as any) - (aSelected as any) || options.indexOf(a) - options.indexOf(b);
  });
  return sortedOptions;
}

export const AutocompleteMultiCheckbox = <T extends Option>({
  options,
  loading,
  label,
  disabled,
  selectedOptions,
  onSelectedOptionsChange,
  ...rest
}: MultiSelectAutocompleteProps<T>) => {
  const sortedOptions = useMemo(() => {
    return sortOptions(options, selectedOptions);
  }, [selectedOptions, options]);

  return (
    <Autocomplete<T, true, false, true>
      suppressHydrationWarning
      {...rest}
      // @ts-ignore
      options={sortedOptions}
      multiple={true}
      loading={loading}
      // @ts-ignore
      getOptionLabel={o => o.optionName}
      isOptionEqualToValue={(option, value) =>
        option.optionSeq.toLowerCase() === value.optionSeq.toLowerCase()
      }
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          label={label}
          variant='outlined'
          fullWidth
        />
      )}
      disabled={loading || disabled}
      disableCloseOnSelect
      value={selectedOptions}
      onChange={(event, value) => {
        onSelectedOptionsChange(value as T[]);
      }}
      limitTags={2}
      // renderTags={(value, getTagProps) => {
      //   const selectedOptions = value as T[];
      //   if (selectedOptions.length > 0) {
      //     const displayText =
      //       selectedOptions.length > 2
      //         ? `${selectedOptions[0].optionName}, ${selectedOptions[1].optionName}, ...`
      //         : selectedOptions.map(option => option.optionName).join(', ');
      //     return [<Typography sx={{ ml: 1 }}>{displayText}</Typography>];
      //   }
      //   return [];
      // }}
      // @ts-ignore
      getOptionKey={o => o.optionSeq}
      renderOption={(props, option, { selected }) => {
        return (
          <Box {...props} component='li'>
            <Box component='div' sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                size='small'
                icon={<CheckBoxOutlineBlank />}
                checkedIcon={<CheckBoxOutlined />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              <Typography>{option.optionName}</Typography>
            </Box>
          </Box>
        );
      }}
    />
  );
};
