import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ChatIcon from '@mui/icons-material/Chat';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import ScienceIcon from '@mui/icons-material/Science';
import { Box, Drawer, IconButton, List, Tooltip, useTheme } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { useAuth } from '../../utils/auth/AuthService';
import dimensions from '../../utils/dimensions';
import { AccountFormDialog } from '../AccountFormDialog';
import { FeedbackDialog } from '../FeedbackDialog';
import { UserAvatar } from './UserAvatar';
import UserMenuDrawerItem from './UserMenuDrawerItem';

// @ts-ignore
const { REACT_APP_API_URL } = process.env;

export const UserMenuDrawer = ({
  open = false,
  handleCloseUserMenu,
  handleOpenUserMenu,
}: {
  open: boolean;
  handleCloseUserMenu: () => void;
  handleOpenUserMenu: () => void;
}) => {
  const auth = useAuth();
  const theme = useTheme();

  const [messagesOpen, setMessagesOpen] = useState(false);
  const [accountFormOpen, setAccountFormOpen] = useState(false);
  const closeMessages = () => {
    setMessagesOpen(false);
  };

  const userLoggedOut = async () => {
    try {
      await axios.post(REACT_APP_API_URL + `user/userloggedout?UserSeq=${auth?.user?.userSeq}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const userLogOut = () => {
    userLoggedOut();
    auth.logout();
  };

  return (
    <Box component='div'>
      <Tooltip title='Account Settings'>
        <IconButton onClick={handleOpenUserMenu}>
          <UserAvatar
            userseq={auth.user?.userSeq ?? ''}
            initials={`${auth?.user?.userFirstName?.charAt(0)}${auth.user?.userLastName?.charAt(
              0
            )}`}
            sx={{
              backgroundColor: theme.palette.primary.light,
              color: '#ffffff',
              height: dimensions.avatar_size,
              width: dimensions.avatar_size,
            }}
            alt={auth.user?.userFirstName}
          />
        </IconButton>
      </Tooltip>

      <Drawer
        variant='temporary'
        open={open}
        anchor='right'
        onClose={handleCloseUserMenu}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'md' },
          zIndex: theme => theme.zIndex.drawer + 500,
        }}
      >
        {auth.isAuthenticated ? (
          <List>
            <UserMenuDrawerItem
              userAvatar
              primaryText={'Hi, ' + auth.user?.userFirstName}
              secondaryText={auth.user?.userName}
              handleCloseUserMenu={handleCloseUserMenu}
              closeButton
            />

            <UserMenuDrawerItem
              path='/yourprofile'
              avatarIcon={<PersonIcon />}
              primaryText='Your Profile'
              handleCloseUserMenu={handleCloseUserMenu}
              divider
            />

            <UserMenuDrawerItem
              // path='/messages'
              stillButton
              avatarIcon={<ChatIcon />}
              primaryText='Messages'
              handleCloseUserMenu={handleCloseUserMenu}
              onClick={() => {
                handleCloseUserMenu();
                setMessagesOpen(true);
              }}
            />

            <UserMenuDrawerItem
              path='/dailyexamlist'
              authorizedRoles={['DAILY-EXAM-LIST']}
              avatarIcon={<AdminPanelSettingsIcon />}
              primaryText='Daily Exam List'
              handleCloseUserMenu={handleCloseUserMenu}
            />

            <UserMenuDrawerItem
              path='/resources'
              avatarIcon={<AdminPanelSettingsIcon />}
              primaryText='Resources'
              handleCloseUserMenu={handleCloseUserMenu}
            />

            <UserMenuDrawerItem
              path='/lms'
              authorizedRoles={['eeacd7ee-fd33-46a7-95b3-26a58b20a5a2']}
              avatarIcon={<ScienceIcon />}
              primaryText='LMS'
              handleCloseUserMenu={handleCloseUserMenu}
              divider
            />

            {/* // data loss bugs in admin module reference data...commenting out pending admin module re-write */}
            {/* <UserMenuDrawerItem
              path='/cmsadmin'
              authorizedRoles={['d1582600-5f86-49dd-bab7-6f7205bfeffd']}
              avatarIcon={<AdminPanelSettingsIcon />}
              primaryText='CMS Admin'
              handleCloseUserMenu={handleCloseUserMenu}
            /> */}

            <UserMenuDrawerItem
              path='/sysadmin'
              authorizedRoles={['d1582600-5f86-49dd-bab7-6f7205bfeffd']}
              avatarIcon={<AdminPanelSettingsIcon />}
              primaryText='Systems Admin'
              handleCloseUserMenu={handleCloseUserMenu}
            />

            <UserMenuDrawerItem
              stillButton
              avatarIcon={<AssignmentIndIcon />}
              primaryText='New Account Form'
              handleCloseUserMenu={handleCloseUserMenu}
              onClick={() => {
                handleCloseUserMenu();
                setAccountFormOpen(true);
              }}
            />

            <UserMenuDrawerItem
              avatarIcon={<LogoutIcon />}
              primaryText='Sign Out'
              handleCloseUserMenu={handleCloseUserMenu}
              handleUserLogOut={userLogOut}
              stillButton
              divider
            />
          </List>
        ) : (
          <List>
            <UserMenuDrawerItem
              path='/login'
              avatarIcon={<LoginIcon />}
              primaryText='Sign In'
              handleCloseUserMenu={handleCloseUserMenu}
            />
          </List>
        )}
      </Drawer>

      <FeedbackDialog open={messagesOpen} onClose={closeMessages} />
      <AccountFormDialog open={accountFormOpen} onClose={() => setAccountFormOpen(false)} />
    </Box>
  );
};
