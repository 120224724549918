import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../utils/auth/AuthService';
import { Form, FormikProvider, useFormik, useFormikContext } from 'formik';
import ReportDataGrid from '../../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function ELabPMTestResultsView() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const { REACT_APP_API_URL } = process.env;
  const formik = useFormikContext();

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'samplenumber',
      headerName: 'Sample Number',
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'testmethod',
      headerName: 'Test Method',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'specimen',
      headerName: 'Sample Type',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },

    {
      field: 'analyte',
      headerName: 'Analyte',
      flex: 1,
      minWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'interpretation',
      headerName: 'INTERPRETATION',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'quantitativeresult',
      headerName: 'RESULT',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'quantitativelimit',
      headerName: 'Limit',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'unitofmeasurement',
      headerName: 'Units',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
  ];

  useEffect(() => {
    if (formik.values != null) {
      setlistresults(formik.values.labtestresultlist);
    }
    console.log(formik.values);
  }, [formik.values]);

  return (
    <ReportDataGrid
      loading={loading}
      idcolumn='id'
      listresults={listresults ?? []}
      columnsInput={columns}
      title='TEST RESULTS'
    />
  );
}
